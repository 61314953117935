import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Button, Form, Input, message, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/actions/auth.actions";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles({
    password: {
        "& input": {
            borderBottom: "none !important",
            border: "none !important",
        },
    },
});

const Login = ({ setVisibleForm }) => {
    const [loading, setLoading] = useState(false);
    const styles = useStyles();
    const { motion: motionParams, auth, app } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        if (auth.token) {
            history.push("/home_menu");
        }
        motionParams.variants.in.x = 0;
        motionParams.variants.in.y = 0;
        motionParams.variants.out.x = 0;
        motionParams.variants.out.y = "-20px";
    }, [motionParams]);

    const login = () => {
        setLoading(true);
        form.validateFields()
            .then((values) => {
                if (values.username === "") {
                    message.error("Ingresa un usuario válido");
                    setLoading(false);
                } else if (values.password === "") {
                    message.error("Ingresa tu contraseña");
                } else {
                    axios
                        .post(`${app.apiHost}/login`, values)
                        .then((response) => {
                            let { access_token, ...user } = response.data;
                            if (user.tiu_id === 2) {
                                window.location.href = `http://137.184.190.186/check/${access_token}`;
                                // window.location.href = `http://192.168.100.14:3002/check/${access_token}`;
                                // window.location.href = `http://localhost:3002/check/${access_token}`;
                            } else {
                                dispatch(
                                    loginAction({
                                        token: access_token,
                                        user,
                                    })
                                );
                                history.push("/home_menu");
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log(err)
                            if (err.request.status === 401) {
                                message.error("Usuario y/o contraseña incorrectos");
                            } else {
                                message.error("No se pudo iniciar sesión");
                            }
                            setLoading(false);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleInputKeyPress = (e) => {
        if (e.code === "Enter") {
            login();
        }
    };

    const handleClickRegistro = () => setVisibleForm("registro");

    const handleClickRecuperarContrasena = () => setVisibleForm("recupera_pass");

    return (
        <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
            <Row style={{ marginBottom: "10px", justifyContent: "center" }}>
                <Typography.Title level={4}>Iniciar Sesión</Typography.Title>
            </Row>
            <Form layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} autoComplete="off" form={form}>
                <Form.Item label="Correo electrónico" name="username" rules={[{ required: true, message: "por favor ingresa tu correo!" }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[{ required: true, message: "por favor ingresa una contraseña válida!" }]}
                    onKeyPress={handleInputKeyPress}
                >
                    <Input.Password className={styles.password} />
                </Form.Item>
            </Form>
            <Button type="primary" style={{ width: "100%", marginBottom: 10 }} onClick={login} loading={loading}>
                Entrar
            </Button>
            <Button type="secondary" style={{ width: "100%" }} onClick={handleClickRegistro}>
                Registrarme
            </Button>
            <div style={{ marginTop: 5 }}>
                <Button type="link" style={{ textAlign: "center", width: "100%" }} onClick={handleClickRecuperarContrasena}>
                    Olvidé mi contraseña
                </Button>
            </div>
        </motion.div>
    );
};

export default Login;
