import { Calendar } from "antd";
import { createUseStyles } from "react-jss";
import { DATE_FORMATS } from "../../../helpers/dates.helper";

const useStyles = createUseStyles(theme => ({
    container: {
        padding: "30px 40px 20px",
        "& .ant-picker-calendar": {
            // height: "100vh"
        }
    }
}));

const VentasCalendario = () => {
    const styles = useStyles();

    const onPanelChange = (value, mode) => {
        console.log(value.format(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR), mode);
    };

    return (
        <div className={styles.container}>
            <Calendar onPanelChange={onPanelChange} />
        </div>
    );
}

export default VentasCalendario;