import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useApiConversaciones = (options, params) => {
    const api = useApi();
    return useQuery(
        ["conversaciones"],
        async () => {
            let query = "";
            if (params?.query) {
                for (let param of Object.keys(params.query)) {
                    query += `${param}=${params.query[param]}&`;
                }
                query = query.slice(0, -1)
            }
            const { data } = await api.get(`/conversaciones${query !== "" ? `?${query}` : ""}`);
            return data;
        },
        options
    );
};

export const useApiConversacionesMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post("/conversaciones", params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     delete params.cacu_fecha;
        //     let id = params.cacu_id;
        //     delete params.cacu_id;
        //     const { data } = await api.put(`/acuerdos-servicio/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/acuerdos-servicio/${params.cacu_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateConversacion,
        isLoading: isLoadingConversacionMutation,
        error: errorConversacionMutation,
        reset: resetConversacionMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["conversaciones"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateConversacion,
        isLoadingConversacionMutation,
        errorConversacionMutation,
        resetConversacionMutation,
    };
};
