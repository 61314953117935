import React from 'react';
import PrivateRoute from './PrivateRoute';
import { createUseStyles } from 'react-jss';
import { Breadcrumb, Row, Typography } from 'antd';
import { Switch, Link } from 'react-router-dom';
import Metricas from '../views/Metricas/metricas';

const useStyles = createUseStyles(theme => ({
    ventasModuleContainer: {
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px 0",
    },
    moduleHeader: {
        padding: "0px 10px 10px 0px",
        display: "flex"
    },
    moduleActions: {
        display: "flex",
        alignItems: "end",
        gap: 10,
        marginLeft: 20
    },
    btnNavigation: {
        boxShadow: "rgba(0, 0, 0, 0.10) 0px 3px 10px 0px",
        minWidth: 80,
        border: "none",
        transition: "all 0.2s ease-in-out",
        fontWeight: 500,
        padding: "5px 10px",
        textAlign: "center",
        cursor: 'pointer',
        borderRadius: 20,
        "&:hover": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        },
        "&.active": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        }
    }
}));

const MetricasRouter = () => {
    const styles = useStyles();

    return (
        <div className={styles.ventasModuleContainer}>
            <Row style={{ padding: "0px 10px 10px", display: "flex", flexDirection: "column" }}>
                <div style={{marginBottom: 10}}>
                    <Breadcrumb >
                        <Breadcrumb.Item >Home</Breadcrumb.Item>
                        <Breadcrumb.Item >Métricas</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className={styles.moduleHeader}>
                    <Typography.Title level={2} style={{ margin: 0 }}>Métricas</Typography.Title>
                </div>
                
            </Row>
            <Row style={{ width: "100%" }}>
                <Switch>
                    <PrivateRoute exact path={"/metricas"} key="/metricas">
                        <Metricas />
                    </PrivateRoute>
                </Switch>
            </Row>
        </div>
    );
}

export default MetricasRouter;