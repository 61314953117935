import { Switch } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PrivateRoute from './PrivateRoute';
import { AnimatePresence, motion } from "framer-motion"
import VerTickets from '../views/Tickets/VerTickets';
import TicketPage from '../views/Tickets/TicketPage';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppRegexRegisteredRoutes } from './registered_routes';
import { setConfig } from '../redux/actions/config.actions';
import { TipoPosicionAreaDepartamento } from '../@enums/AreasDepartamentos.enums';


const TicketsRouter = () => {
    const dispatch = useDispatch();
    const motionParams = useSelector((state) => state.motion);
    const { configuracion } = useSelector((state) => state);
    const location = useLocation();
    const [notFoundPage, setNotFoundPage] = useState(false);

    useEffect(() => {
        let existsRoute = AppRegexRegisteredRoutes.find(route => route.test(location.pathname));
        if (existsRoute) {
            setNotFoundPage(false);
            dispatch(setConfig({
                sideBar: {
                    ...configuracion.sideBar,
                    visible: true
                }
            }));
        } else {
            setNotFoundPage(true);
        }
    }, [location])

    return (
        <Switch>
            <AnimatePresence key="tickets-router-animated">
                <PrivateRoute exact path={"/tickets"} key="/tickets">
                    <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                        <VerTickets tipoPosicion={TipoPosicionAreaDepartamento.SOPORTE} />
                    </motion.div>
                </PrivateRoute>
                <PrivateRoute exact path={"/tickets/:id"} key="/tickets/:id">
                    <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                        <TicketPage />
                    </motion.div>
                </PrivateRoute>
            </AnimatePresence>
        </Switch>
    );
}

export default TicketsRouter;