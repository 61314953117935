import React from "react";
import { createUseStyles } from "react-jss";

const elevationLevels = [
    "rgba(149, 157, 165, 0.3) 0px 0px 24px",
    "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
];

const useStyles = createUseStyles({
    elevation: {
        background: "#fff",
    },
});

/**
 * Contenedor que envuelve otro componente para simular una elevacion a base de sombras
 * @param {Object} props
 * @param {Object} props.children - componente que se visualizara con elebvacion 
 * @param {Object} [props.style] - estilo personalizado del componente
 * @param {String} [props.className] -  clases extras para el componente
 * @param {Number} [props.elevationLevel=1] - tipo de sombra para el component (simula la elevacion) valores [1,2]
 * @param {React.MouseEventHandler<HTMLDivElement>} [props.onClick] - callback que es ejecutado cuando se ha hecho click en el componente
 */
const Elevation = ({ children, style, className, elevationLevel, onClick }) => {
    const styles = useStyles();

    const styleTemp = {
        boxShadow: elevationLevel ? elevationLevels[elevationLevel - 1] : elevationLevels[0],
        ...(style ? style : {}),
    };
    return (
        <div style={styleTemp} className={`${styles.elevation} ${className ? className : ""}`} onClick={onClick}>
            {children}
        </div>
    );
};

export default Elevation;
