import React,{useEffect, useState} from "react";
import { EditUsuarioActividad } from "../../../../hooks/api/EditUsuarioActividad";
import { NewUsuarioActividad } from "../../../../hooks/api/newUsuarioActividad";
import { Row, Col, Button, Form, Input, Select, DatePicker, TimePicker, message, Collapse} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import './styles.css'
import { GetCompanyContact } from "../../../../hooks/api/getCompanyWhitContact";
import moment from "moment";
import { DateTime } from "luxon";

const { Panel } = Collapse;


const ControlEvent = ({form, data, setData, selectContacts, listAct, listCompanies, isEdit, setIsEdit, getData, setContacts, segundos, setSegundos, corriendo, setCorriendo}) => {
    
    const {getCompanyContact} = GetCompanyContact();
    const {editUsuariosActividadMutation} = EditUsuarioActividad();
    const {newUsuariosActividadMutation} = NewUsuarioActividad();
    const listPrioridades  = [{value:0, label:'Baja'}, {value:1, label:'Media'}, {value:2, label:'Alta'}]
    const dateFormat = 'DD/MM/YYYY';
    const [isNew, setIsNew] = useState(false)
    const [empresaSelected, setEmpresaSelected] = useState(false);
    const [showExtraInput, setShowExtraInput] = useState(false);
    

    useEffect(() => {
        let intervalId;
    
        if (corriendo) {
            intervalId = setInterval(() => {
                setSegundos((segundos) => segundos + 1);
            }, 1000);
            } else {
            clearInterval(intervalId);
        }
    
        return () => {
            clearInterval(intervalId);
        };
    }, [corriendo]);


    const onChange = () => {
        console.log(8)
    }
    const onFinish = (values) => {
        console.log(values)
    }
    const openNew = () => {
        form.resetFields();
        setIsEdit(false)
        setIsNew(true)
    }
    const cancelNew = () => {
        setIsNew(false);
        setIsEdit(true);
        setData(null)
        setEmpresaSelected(false)
    }
    const changeCompany = (value) => {
        getCompanyContact({},{
            onSuccess: (data) => {
                const company = data.find(emp => emp.emp_id === value);
                const contacts = company.usuarios_empresas;
                if(contacts.length > 0){
                    const dataSelectContact = contacts.map(contact => {
                        return { value: contact.usuario.usu_id, label:`${capitalizeFirstLetter(contact.usuario.usu_nombres)} ${capitalizeFirstLetter(contact.usuario.usu_apaterno)} ` }
                    })
                    setContacts(dataSelectContact)
                }else{
                    setContacts([])
                }
                setEmpresaSelected(true)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }
    const onEdit = () => {
        const dataForm = form.getFieldsValue();
        console.log(dataForm)
        let fechaInit = dataForm.fechaInit !== undefined ? dataForm.fechaInit.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        let horaInit = dataForm.horaInit !== undefined ? dataForm.horaInit.subtract(6, 'hours').format('HH:mm:ss') : moment().subtract(6, 'hours').add(10, 'minutes').format('HH:mm:ss');
        const dataUpdate = {
            usac_fechaprogramada: `${fechaInit}T${horaInit}.000Z`,
            usac_descripcion : dataForm.descripcion !== undefined ? dataForm.descripcion : null,
            usac_observaciones : dataForm.observaciones !== undefined && dataForm.acti_id === 0 ? dataForm.observaciones : null,
            acti_id: dataForm.acti_id.value !== undefined ? dataForm.acti_id.value !== 0 ? dataForm.acti_id.value: null : dataForm.acti_id !== 0 ? dataForm.acti_id : null,
            usu_contactoid: dataForm.contacto === null ? null : dataForm.contacto.value !== undefined ? dataForm.contacto.value : dataForm.contacto,
            emp_id: dataForm.company === null ? null : dataForm.company.value !== undefined ? dataForm.company.value : dataForm.company ,
            usac_prioridad: dataForm.prioridad === null ? null : dataForm.prioridad.value !== undefined ? dataForm.prioridad.value: dataForm.prioridad
        }
        const params = {
            id: data.id,
            data: dataUpdate
        }
        editUsuariosActividadMutation(params,{
            onSuccess:(data) => {
                if(data.success === true) message.success(data.mensaje)
                getData();
                setIsEdit(!isEdit)
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }
    const onNewSave = () => {
        let dataForm = form.getFieldsValue();
        if((dataForm.acti_id !== undefined && dataForm.acti_id !== null &&  dataForm.acti_id !== 0) || (dataForm.acti_id !== undefined && dataForm.acti_id === 0 && dataForm.observaciones !== undefined)){
            let fechaInit = dataForm.fechaInit !== undefined ? dataForm.fechaInit.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            let horaInit = dataForm.horaInit !== undefined ? dataForm.horaInit.format('HH:mm:ss') : DateTime.utc().minus({hours:6}).plus({ minutes: 2 }).toFormat("HH:mm:ss");
            let storage = JSON.parse(sessionStorage.getItem('persist:root'))
            let user = JSON.parse(storage.auth)
            const dataNew = {
                    usac_fecha: moment().format('YYYY-MM-DDTHH:mm:ss.000[Z]'),
                    usac_fechaprogramada: `${fechaInit}T${horaInit}.000Z`,
                    usac_fechaprogramadafinal: null,
                    usac_status: 0,
                    usac_segundosactiva : 0,
                    usac_descripcion : dataForm.descripcion !== undefined ? dataForm.descripcion : null,
                    usac_observaciones : dataForm.observaciones !== undefined && dataForm.acti_id === 0 ? dataForm.observaciones : null,
                    usu_id: user.user.usu_id,
                    acti_id: dataForm.acti_id !== 0 ? dataForm.acti_id : null,
                    usu_contactoid: dataForm.contacto !== undefined ? dataForm.contacto : null,
                    emp_id: dataForm.company !== undefined ? dataForm.company : null,
                    usac_prioridad: dataForm.prioridad !== undefined ? dataForm.prioridad : null
    
            }
            newUsuariosActividadMutation(dataNew, {
                onSuccess:(data) => {
                    if(data){
                        message.success(data.mensaje);
                        getData();
                        setIsNew(false);
                        setIsEdit(true);
                        setData(null)
                        setEmpresaSelected(false);
                        form.resetFields();
                    }
                },
                onError: (error) => {
                    console.log(error)
                }
            })
        }else{
            message.error('Campos sin llenar restantes')
            form.submit();
        }
        
        
    }
    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    const onChangeAct = (value) => {
        value === 0 ? setShowExtraInput(true)
                    : setShowExtraInput(false);
    }

    //CRONOMETRO
    const segundosFormateados = (segundos) => {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const segundosRestantes = segundos % 60;
    
        return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundosRestantes.toString().padStart(2, '0')}`;
    };
    const pauseAndUpdate = () => {
        if(corriendo){
            const dataNew = {
                usac_status: 1,
                usac_segundosactiva : segundos,
            }
            const params = {
                id: data.id,
                data: dataNew
            }
            editUsuariosActividadMutation(params,{
                onSuccess:(data) => {
                    getData();
                }, 
                onError: (error) => {
                    console.log(error)
                }
            })
        }else{
            const dataNew = {
                usac_status: 1,
            }
            const params = {
                id: data.id,
                data: dataNew
            }
            editUsuariosActividadMutation(params,{
                onSuccess:(data) => {
                    getData();
                }, 
                onError: (error) => {
                    console.log(error)
                }
            })
        }
        setCorriendo(!corriendo)
    }
    const finishActivitie = () => {
        setCorriendo(false)
        const dataNew = {
            usac_status: 2,
            usac_segundosactiva : segundos,
        }
        const params = {
            id: data.id,
            data: dataNew
        }
        editUsuariosActividadMutation(params,{
            onSuccess:(data) => {
                getData();
                setData(null);
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }


    return(
        <>
            <Form 
            form={form}
            onChange={onChange}
            disabled={isEdit}
            onFinish={onFinish}
            >
                <Row>
                    <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center', padding:10}}>
                        <span className="timeAct">{segundosFormateados(segundos)}</span>
                    </Col>
                </Row>
                <Row style={{border:'1px solid #dcdcdd', backgroundColor:'#f2f2f2', padding:'5px 15px'}}>
                    <Col span={24}>
                        <Row>
                            <Col span={3} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                <Button type="primary" shape="circle" icon={<PlusOutlined />}  disabled={false} onClick={openNew} />
                            </Col>
                            <Col span={3} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                {data !== null && isNew === false ? (<Button type="primary" shape="circle" icon={<EditOutlined />} disabled={false} onClick={()=> setIsEdit(false)} />): null}
                            </Col>
                            <Col span={14} offset={4} >
                                <Form.Item label="Prioridad" name="prioridad">
                                    <Select options={listPrioridades}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginTop:15}}>
                            <Col span={24}>
                                <Form.Item label="Actividad" name="acti_id" rules={[{required: true, message: 'Seleccione una actividad'}]}>
                                    <Select options={listAct} onChange={onChangeAct}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {showExtraInput || data?.acti_id.value === 0 ? (
                            <Row style={{marginTop:2}}>
                                <Col span={24} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                    <span>Observaciones</span>
                                </Col>
                                <Col span={24} style={{marginTop:5}}>
                                    <Form.Item  name="observaciones" rules={[{required: true, message: 'Las observaciones son obligatorias'}]}>
                                        <Input.TextArea  placeholder="Descripción el motivo de selecionar otro" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ): null}
                        <Row style={{marginTop:10}}>

                            <Col span={24}>
                                <Form.Item label="Empresa:" name="company">
                                    <Select options={listCompanies} onChange={changeCompany}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginTop:10}}>
                            <Col span={24}>
                                <Form.Item label="Contacto" name="contacto">
                                    <Select options={selectContacts} disabled={isNew && !empresaSelected || isEdit ? true : false}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginTop:2}}>
                            <Col span={24} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                <span>Descripción</span>
                            </Col>
                            <Col span={24} style={{marginTop:5}}>
                                <Form.Item  name="descripcion">
                                    <Input.TextArea  placeholder="Descripción de la actividad" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginTop:10}}>
                            <Col span={24}>
                                <Collapse bordered={false} collapsible={isEdit ? 'disabled': 'icon'} style={{width: '100%'}}>
                                    <Panel header="Fecha programada" key="1">
                                        <Row>
                                        <Col span={14} style={{paddingRight: 8}}>
                                            <Row>
                                                <Col span={24}>
                                                    <span>Fecha inicio</span>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="fechaInit">
                                                        <DatePicker format={dateFormat} defaultValue={moment()} style={{width:'100%'}}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={10}>
                                            <Row>
                                                <Col span={24}>
                                                    <span>Hora inicial</span>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="horaInit" >
                                                        <TimePicker format="HH:mm" defaultValue={moment()}  style={{width:'100%'}}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>;
                            </Col>
                        </Row>
                        {!isEdit && !isNew? (
                            <Row style={{marginTop:30, marginBottom:15}}>
                                <Col span={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingRight:5}}>
                                    <Button type="primary" className="btnCancelAct" onClick={()=>setIsEdit(!isEdit)}>Cancelar</Button>
                                </Col>
                                <Col span={12} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:5}}>
                                    <Button type="primary" className="btnSaveAct" onClick={onEdit}>Guardar</Button>
                                </Col>
                            </Row>
                        ): null}
                        {isNew? (
                            <Row style={{marginTop:30, marginBottom:15}}>
                                <Col span={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingRight:5}}>
                                    <Button type="primary" className="btnCancelAct" onClick={cancelNew}>Cancelar</Button>
                                </Col>
                                <Col span={12} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:5}}>
                                    <Button type="primary" className="btnSaveAct" onClick={onNewSave}>Guardar</Button>
                                </Col>
                            </Row>
                        ): null}
                        {data !== null && isEdit && !isNew &&( data.status < 2 || data.status === 3) ? (
                            <Row style={{marginTop:30, marginBottom:15}}>
                                <Col span={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingRight:5}}>
                                    <Button type="primary"onClick={pauseAndUpdate} disabled={data!== null && data.status !== 2  ? false: true} >{corriendo ? 'Pausar': 'Iniciar'}</Button>
                                </Col>
                                <Col span={12} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:5}}>
                                    <Button type="primary" className="btnCancelAct" onClick={finishActivitie} disabled={data!== null && data.status !== 2 ? false: true}>Terminar</Button>
                                </Col>
                            </Row>
                        ):null}
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ControlEvent;