import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Cotizador from "./Cotizador";

const Cotizaciones = () => {
    return (<PageWrapper title="Cotizaciones">
        <Elevation style={{padding: 20}}>
            <Cotizador />
        </Elevation>
    </PageWrapper>);
}

export default Cotizaciones;