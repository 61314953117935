import React from "react";
import { motion } from "framer-motion";
import { Row, Col } from "antd";
import './styles.css'

const CardAnimatedLicences = ({title, headers, data, idData, total}) => {

    const sizeCol = 24 / headers.length;

    return(
        <motion.div
            className="cardDataMetrica"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]}}
        >
            <Row>
                <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <span className="titleCardMetrica">{title}</span>
                </Col>
            </Row>
            <Row>
                {headers.map((head, index) => (
                    <>
                        <Col span={index === 0 ? sizeCol +4 : sizeCol-2} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <span style={{fontSize:14, fontWeight: '600'}}>{head}</span>
                        </Col>
                    </>
                ))}
            </Row>
            {data.map((arr) => (
                <>
                    <Row>
                        {arr.map((data, index) => (
                            <>
                                <Col span={index === 0 ? sizeCol +4 : sizeCol-2} style={{display:'flex', justifyContent:index === 0  ? 'flex-start' : 'center', alignItems:'center'}}>
                                    {data}
                                </Col>
                            </> 
                        ))}
                    </Row>
                </>
            ))}

            <Row style={{marginTop:15}}>
                <Col span={20}>
                    <span className="totalTitle">Total</span>
                </Col>
                <Col span={4}>
                    <span>{total}</span>
                </Col>
            </Row>
        </motion.div>
    )

}


export default CardAnimatedLicences;