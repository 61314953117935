export const AppRegexRegisteredRoutes = [
    // "/",
    /\/registro\/genera_pass\b/,
    /\/cambia_pass\b/,
    /\/facturas\b/,
    /\/tickets\b/,
    /\/tickets\b/,
    /\/home_menu\b/,
    /\/sistemas\b/,
    // /\/sistemas\b\/(?=.*\d).+/, 
    /\/acuerdos_servicio\b/,
    /\/puestos\b/,
    /\/usuarios\b/,
    /\/usuarios\b\/(?=.*\d).+/,
    /\/areas_departamentos\b/,
    /\/empresas\b/,
    /\/empresas\b\/(?=.*\d).+/,
    /\/contactos\b/,
    /\/contactos\b\/(?=.*\d).+/,
    /\/ventas\/cotizaciones\b/,
    /\/perfil\b/,
    /\/ventas\b/,
    /\/ventas\/tickets\b/,
    /\/ventas\/tickets\b/,
    /\/ventas\/cotizaciones\b/,
    /\/ventas\/licencias\b/,
    /\/ventas\/actividades\b/,
    /\/administracion\b/,
    /\/administracion\/marketing\/campañas\b/,
    /\/administracion\/marketing\/campañas\b\/(?=.*\d).+\/anuncios/,
    /\/administracion\/productos-servicios\b/,
    /\/actividades_catalogo\b/,
    /\/actividades_metricas\b/,
    /\/anuncios\/crear\b/,
    /\/know-base\b/,
    /\/know-base\b\/(?=.*\d).+/,
    /\/metricas\b/,
    /\/actividades\b/,
    /\/codigos_sesion\b/,
    /\/desarrollo\b/,
    /\/desarrollo\/tickets\b/,
    /\/desarrollo\/tickets\b/,
];