import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useApiUsuarios } from "../../hooks/api/usuarios";
import CrearUsuario from "./CrearUsuario";
import DataTable from "../../components/DataTable";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    table: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
});

const Usuarios = () => {
    const styles = useStyles();
    const [filteredRecords, setFilteredRecords] = useState([]);
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const { isLoading: isLoadingUsuarios, data: usuarios } = useApiUsuarios({ initialData: [] });
    const closeModal = () => setShowModal(false);
    const handleClickShowModal = () => setShowModal(true);
    const [search, setSearch] = useState(false)

    const handleSelecRow = (usuario) => {
        history.push(`/usuarios/${usuario.usu_id}`)
    }

    const columns = [
        { index: 'usu_id', title: 'ID', width: 70 },
        { index: 'usu_nombres', title: 'Nombre', width: 200 },
        { index: 'usu_apaterno', title: 'Apellido Paterno', width: 200 },
        { index: 'usu_amaterno', title: 'Apellido Materno', width: 250, },
        { index: 'usu_mail', title: 'Correo', width: 250, },
        { title: 'Perfil', width: 150, render: usuario => usuario.tipo_usuario.tiu_nombre }
    ];

    return (
        <PageWrapper title="Usuarios">
            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando usuarios ..." spinning={isLoadingUsuarios}>
                            <DataTableHeader
                                key={'table-users'}
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: handleClickShowModal
                                    }
                                ]}
                                dataToFilter={usuarios}
                                setFilteredData={setFilteredRecords}
                                counter={search ? filteredRecords?.length : usuarios?.length}
                                setSearch={setSearch}
                            />
                            <DataTable
                                columns={columns}
                                data={search ? filteredRecords : usuarios}
                                onRowClick={handleSelecRow}
                                className={styles.table}
                                paginationParams={{
                                    defaultPageSize: 10,
                                    total: usuarios.length,
                                    showSizeChanger: true,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    position: ["topRight"],
                                }}
                            />
                        </Spin>
                    </Elevation>
                </Col>
            </Row>

            <Modal title="Crear usuario" open={showModal} footer={null} onCancel={closeModal} destroyOnClose={true} width="400px">
                <CrearUsuario closeModal={closeModal} />
            </Modal>
        </PageWrapper>
    );
};

export default Usuarios;
