/**
 * indica los tipos de contacto de marcacion
 * @readonly
 * @enum {number}
 */
export const TipoContactoMarcacion = Object.freeze({
    CELULAR: 1,
    FIJO: 2
});

/**
 * Se consulta el nombre del tupo de contacto de mercacion en base a su valor entero del area o 
 * @param {Number} value - valor del tipo de contacto de marcacion que se requiere extraer la llave   
 * @returns {String} Nombre del tipo de contacto de marcacion
 */
export const getKeyTipoContactoMarcacion = (value) =>
    Object.keys(TipoContactoMarcacion).find(key => TipoContactoMarcacion[key] == value);
