import React from 'react';
import {useDrag} from 'react-dnd';


const DraggableColumnHeader = ({index, title, onDropColumnHeader, children}) => {
    const itemTypes = {
        COLUMNHEADER: "columnHeader",
    };

    const [, dragRef] = useDrag({
        type: itemTypes.COLUMNHEADER,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => ({index: index, title: title}),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            console.log(item)
            if(item && dropResult)
                onDropColumnHeader(item);
        }
    });

    return (
        <div ref={dragRef}>
            {children}
        </div>
    );
}
 
export default DraggableColumnHeader;