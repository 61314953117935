import { Button, Form, Modal, Row, Select, Table, Typography, message, Switch, Checkbox } from "antd";
import Elevation from "../../../components/Elevation";
import { CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { createUseStyles, useTheme } from "react-jss";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useApi, { AvailableRequestContentTypes } from "../../../hooks/api/useApiRequest";
import { useModal } from "../../../hooks/useModal";
import { useApiEmpresas } from "../../../hooks/api/empresas";

const useStyles = createUseStyles({
    contactoEmpresas: {
        width: "100%",
        padding: 10
    },
    formItemSwitch: {
        display: "flex",
        alignItems: "center",
        "& .ant-form-item": {
            width: 50,
            flex: "none",
            margin: 0
        },
    }
})

const ContactoEmpresas = ({ contacto, refetchContacto }) => {
    const theme = useTheme();
    const styles = useStyles();
    const history = useHistory();
    const api = useApi();
    const [form] = Form.useForm();
    const [visible, showModal, closeModal] = useModal();
    const { data: empresas, refetch: refetchEmpresas } = useApiEmpresas({
        initialData: [],
        params: {
            only_contact_not_relationated: true,
            usu_id: contacto?.usu_id
        }
    })

    const goToEmpresa = (emp_id) => {
        history.push(`/empresas/${emp_id}`);
    }

    const eliminaRelacion = (emp_id) => {
        if (contacto?.empresas.length <= 1) {
            message.error("El contacto debe tener al menos 1 empresa");
            return;
        }

        api.del(`/contactos/${contacto?.usu_id}/empresas/${emp_id}`).then(({ data }) => {
            if (data?.success) {
                message.success(data.mensaje);
            } else {
                message.error(data.mensaje);
            }
            if (refetchContacto)
                refetchContacto();
        }).catch(err => {
            message.error("Error al borrar la relacion con la empresa");
        })
    }

    const agregarEmpresa = () => {
        form.validateFields().then(({ emp_id, use_principal }) => {
            console.log(contacto);
            api.post(`/contactos/${contacto?.usu_id}/empresas`, { emp_id, use_principal }, { dataType: "json" }).then(({ data }) => {
                if (data?.success) {
                    message.success(data.mensaje);
                    closeModal();
                    if (refetchContacto)
                        refetchContacto();
                } else {
                    message.error(data.mensaje);
                }
            }).catch(err => {
                message.error("Error al agregar la relacion con la empresa");
            })
        }).catch(err => {
            message.error("Error al agregar la relacion con la empresa");
        })
    }

    const handleChangeEmpresaPrincipal = (use, checked) => {
        if (use)
            updateUsuarioEmpresa(use.use_id, use.emp_id, { use_id: use.use_id, use_principal: checked });
    }

    const updateUsuarioEmpresa = (use_id, emp_id, data) => {
        api.put(`/contactos/${contacto?.usu_id}/empresas/${emp_id}`, data, { dataType: AvailableRequestContentTypes.JSON })
            .then(({ data }) => {
                if (data?.success) {
                    message.success(data.mensaje);
                    if (refetchContacto)
                        refetchContacto();
                } else {
                    message.error(data.mensaje);
                }
            }).catch(err => {
                message.error("Error al cambiar la empresa principal");
            })
    }

    const renderIsEmpresaPrincipal = (_, empresa) => {
        let relacionEmpresa = empresa?.usuarios_empresas?.find(use => use.emp_id === empresa.emp_id && use.usu_id === contacto.usu_id);
        return <Checkbox checked={relacionEmpresa?.use_principal} onClick={(e) => handleChangeEmpresaPrincipal(relacionEmpresa, e.target.checked)} />
        // return empresaPrincipal ?
        //     (
        //         <div style={{ width: "100%", textAlign: "center" }}>
        //             <CheckOutlined style={{ fontSize: 14, color: theme.secondaryColor }} />
        //         </div>
        //     )
        //     : null;
    }

    useEffect(() => {
        refetchEmpresas();
        form.resetFields();
    }, [contacto])

    let columns = [
        {
            dataIndex: "emp_rfc",
            title: "RFC",
            render: (rfc, empresa) => <Button type="link" onClick={() => goToEmpresa(empresa?.emp_id)}>{rfc}</Button>
        },
        {
            dataIndex: "emp_razonsocial",
            title: "Razón social"
        },
        {
            dataIndex: "emp_nombrecomercial",
            title: "Nombre comercial",
        },
        {
            title: "Principal",
            render: renderIsEmpresaPrincipal
        },
        {
            title: "Acciones",
            render: (_, empresa) => {
                return <div style={{ display: "flex", gap: 15 }}>
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => eliminaRelacion(empresa.emp_id)} />
                </div>
            }
        }
    ];

    return (
        <Elevation className={styles.contactoEmpresas}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10, width: "100%" }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Empresas</Typography.Text>
                <div className="section-buttons">
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={showModal} />
                </div>
            </Row>
            <Row>
                <Table dataSource={contacto?.empresas || []} columns={columns} style={{ width: "100%" }} />
            </Row>

            {/* modal para agregar la relacion de la empresa */}
            <Modal title="Agregar empresa al contacto" open={visible} footer={null} onCancel={closeModal} destroyOnClose={true} width="350px">
                <div>
                    <Form layout='vertical' form={form} >
                        <Form.Item name="emp_id" label="Empresa" rules={[{ required: true, message: "La empresa es requerida" }]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.join("").includes(input)}
                            >
                                {
                                    (empresas?.length > 0 ? empresas : []).map((emp) => <Select.Option value={emp.emp_id} key={`empresa_${emp.emp_id}`}>{emp.emp_rfc} - {emp.emp_razonsocial}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <div className={styles.formItemSwitch}>
                            <Form.Item
                                name="use_principal"
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                            <div style={{ flex: 1 }}>¿Es la empresa principal del contacto?</div>
                        </div>
                    </Form>
                    <Row style={{ justifyContent: "end", marginTop: 15 }}>
                        <Button type="primary" onClick={agregarEmpresa}>Agregar empresa</Button>
                    </Row>
                </div>
            </Modal>
        </Elevation>
    );
}

export default ContactoEmpresas;