import React, {useState} from "react";
import { Spin, Input, Col, Row, Table, Button, Switch, message} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Elevation from "../../../../components/Elevation";
import { ETipoProductoServicio, EProductoServicioActivo, EProductoServicioCliente } from "../../../../@enums/Producto-Servicio";
import { UpdateProductosServicios } from "../../../../hooks/api/productos-servicios";



const Tabla = ({openNew, setOpenNew, dataSource, dataFiltered, setDataFiltered, form, setIsEdit, updateTable}) => {

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'pser_nombre',
            key: 'pser_nombre',
            sorter: (a, b) => a.pser_nombre.localeCompare(b.pser_nombre),
        },
        {
            title: 'Tipo',
            dataIndex: 'pser_tipo',
            key: 'pser_tipo',
            render: (tipo) => tipo === ETipoProductoServicio.PRODUCTO ? <p>Producto</p> : <p>Servicio</p>,
            sorter: (a, b) => a.pser_tipo.localeCompare(b.pser_tipo),
        },
        {
            title: 'Precio Unitario',
            dataIndex: 'pser_preciounitario',
            key: 'pser_preciounitario',
            render: (monto) => (
                <span>{`$${monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</span>
            ),
            sorter: (a, b) => a.pser_preciounitario - b.pser_preciounitario,
        },
        {
            title: 'Activo',
            dataIndex: 'pser_activo',
            key: 'pser_activo',
            render: (pser_activo, record) => (
                <Switch
                    checked={pser_activo === EProductoServicioActivo.ACTIVO}
                    onChange={(checked) => switchProductoServicio(checked, record.pser_id)}
                />
            ),
        },
        {
            title: 'Activo al Cliente',
            dataIndex: 'pser_muestreotiendacliente',
            key: 'pser_muestreotiendacliente',
            render: (pser_muestreotiendacliente, record) => (
                <Switch
                    checked={pser_muestreotiendacliente === EProductoServicioCliente.ACTIVO}
                    onChange={(checked) => switchProductoServicioCliente(checked, record.pser_id)}
                />
            ),
        },
    ]

    const {updateProductosServiciosMutation} = UpdateProductosServicios();

    const [searchText, setSearchText] = useState('');

    //SWITCHES

    const switchProductoServicio = (checked, recordId) => {
        updateProductosServiciosMutation({id: recordId, data: {pser_activo: checked ? true : false}}, {
            onSuccess: (data) => {
                if(data.success){
                    updateTable()
                }else{
                    message.error('Hubo un error al cambiar de estado, intenta nuevamente')
                }
            },
            onError: (error) => {
                message.error('Hubo un error al cambiar de estado, intenta nuevamente')
                updateTable()
                console.log(error)
            }
        })
    };
    const switchProductoServicioCliente = (checked, recordId) => {
        updateProductosServiciosMutation({id: recordId, data: {pser_muestreotiendacliente: checked ? true : false}}, {
            onSuccess: (data) => {
                if(data.success){
                    updateTable()
                }else{
                    message.error('Hubo un error al cambiar de estado, intenta nuevamente')
                }
            },
            onError: (error) => {
                console.log(error)
                message.error('Hubo un error al cambiar de estado, intenta nuevamente')
                updateTable()
            }
        })
    };


    //BUSQUEDAD

    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = dataSource.filter(item =>
            Object.values(item).some(val => val.toString().toLowerCase().includes(value.toLowerCase())
            )
        );
        setDataFiltered(filtered);
    };

    //SELECCION ROW PARA UPDATE

    const handleRowClick = (record, rowIndex, columnIndex) => {
        if (['pser_nombre', 'pser_tipo', 'pser_preciounitario'].includes(columns[columnIndex]?.dataIndex)) {
            const producto_servicio = dataSource.find(prodser => prodser.pser_id === record.pser_id);
            let params = {
                pser_id: producto_servicio?.pser_id,
                pser_nombre: producto_servicio?.pser_nombre,
                pser_tipo: producto_servicio?.pser_tipo,
                pser_preciounitario : producto_servicio?.pser_preciounitario,
                pser_clave: producto_servicio?.pser_clave,
                pser_descripcion: producto_servicio?.pser_descripcion,
                pser_clavesat: producto_servicio?.pser_clavesat,
                pser_comercialcodigo: producto_servicio?.pser_comercialcodigo,
                consideraciones: producto_servicio?.pser_consideraciones.split('/').map(consideracionText => ({consideracion: consideracionText})),
            }
            form.setFieldsValue(params);
            setIsEdit(true);
            setOpenNew(true);
        }
    };
    
    const rowProps = (record, rowIndex) => {
    return {
        onClick: (e) => handleRowClick(record, rowIndex, e.target.cellIndex),
    };
    };

    return(
        <Elevation style={{padding:10}}>
            <Spin tip="Cargando Productos y Servicios ..." spinning={dataFiltered !== null && dataFiltered !== undefined ? false : true}>
                {dataFiltered !== null && dataFiltered !== undefined ? (
                    <>
                            <Row style={{marginBottom:18}}>
                                <Col span={2}>
                                    <Button type="primary" shape="circle" onClick={()=>setOpenNew(!openNew)} icon={<PlusOutlined  />}></Button>
                                </Col>
                                <Col span={3} offset={14}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <span className="textRegisters">{dataFiltered?.length} Registro(s)</span>
                                </Col>
                                <Col span={5}>
                                    <Input  placeholder="Buscar"  className="search_product" onChange={e => handleSearch(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                <Table 
                                    dataSource={dataFiltered} 
                                    columns={columns}  
                                    scroll={{ x: 'max-content' }} 
                                    pagination={{pageSize:100, showSizeChanger:true, pageSizeOptions:[20,50,100,200], position: ["topRight"]}}  
                                    className="table_productos"
                                    onRow={rowProps}
                            />
                                </Col>
                            </Row>
                    </>
                ):(<span>No existen datos</span>)}
            </Spin>
        </Elevation>
    )
}

export default Tabla;