import { useState } from "react";

/**
 * 
 * @returns {[Boolean, Function, Function]}
 */
export const usePopover = () => {
    const [show, setShow] = useState(false);

    const closePopover = () => {
        setShow(false);
    };
   
    const showPopover = () => {
        setShow(true);
    };

    return [show, showPopover, closePopover];
};
