import { PuestoDto } from "../../@types/Puestos.types";
import useApi from "./useApiRequest";
import { Mutation, useMutation, useQuery, useQueryClient } from "react-query";


export const useApiPuestos = (options) => {
    const api = useApi();
    return useQuery(
        ["puestos"],
        /**
         * 
         * @returns {Promise<PuestoDto[]>} Arreglo de puestos 
        */
        async () => {
            const { data } = await api.get(`/puestos`);
            return data;
        },
        options
    );
};

/**
 * 
 * @typedef MutatingPuesto
 * @property {String} mutationType -  tipo de accion que se va a realizar
 * @property {PuestoDto} puesto - objeto del puesto 
 */
export const usePuestosMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    /**
     * 
     * @param {MutatingPuesto} params el tipo {@link MutatingPuesto} se utilizará para las acciones create y update
     * returns 
    */
    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/puestos`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.puesto.pues_id;
            delete params.puesto.pues_id;
            const { data } = await api.put(`/puestos/${id}`, params.puesto, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/puestos/${params.cacu_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutatePuesto,
        isLoading: isLoadingPuestoMutation,
        error: errorPuestoMutation,
        reset: resetPuestoMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["puestos"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutatePuesto,
        isLoadingPuestoMutation,
        errorPuestoMutation,
        resetPuestoMutation,
    };
};

/**
 * 
 * @typedef DeletingPuesto
 * @property {Number} pues_id - id del puesto que se va a eliminar  
 * @property {Number} pues_nuevoid - id del nuevo puesto por el que se va a reemplazar el que se va a eliminar  
 */

export const useDeletePuesto = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingPuesto} deleteParams - datos necesarios para eliminar el puesto
     */
    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/puestos/${deleteParams.pues_id}?new=${deleteParams.pues_nuevoid}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deletePuesto: mutation.mutate,
        isDeletingPuesto: mutation.isLoading,
        isDeletedPuesto: mutation.isSuccess,
        isDeletingPuestoError: mutation.isError,
    };
};

/****************** servicios para la relacion de puestos con areas y departamentos *********************************/

/**
 * 
 * @typedef AddingAreaDepartamentoPuesto
 * @property {Number} pues_id - id del puesto al que se le va a agregar el area o departamento   
 * @property {Number} ade_id - id del area o departamento
 */

export const useAddAreaDepartamentoPuesto = () => {
    const api = useApi();

    /**
     * 
     * @param {AddingAreaDepartamentoPuesto} addingParams - datos necesarios para agregar el area o departamento al puesto
     */
    const addResource = async (addingParams) => {
        const response = await api.post(`/puestos/${addingParams.pues_id}/areas-departamentos`, addingParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        addAreaDepartamentoPuesto: mutation.mutate,
        isAddingAreaDepartamentoPuesto: mutation.isLoading,
        isAddedAreaDepartamentoPuesto: mutation.isSuccess,
        isAddingAreaDepartamentoPuestoError: mutation.isError,
    };
};

/**
 * 
 * @typedef DeletingAreaDepartamentoPuesto
 * @property {Number} pues_id - id del puesto al que se le va a quitar el area o departamento   
 * @property {Number} ade_id - id del area o departamento
 */

export const useDeleteAreaDepartamentoPuesto = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingAreaDepartamentoPuesto} deleteParams - datos necesarios para quitar el area o departamento   
     */
    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/puestos/${deleteParams.pues_id}/areas-departamentos/${deleteParams.ade_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteAreaDepartamentoPuesto: mutation.mutate,
        isDeletingAreaDepartamentoPuesto: mutation.isLoading,
        isDeletedAreaDepartamentoPuesto: mutation.isSuccess,
        isDeletingAreaDepartamentoPuestoError: mutation.isError,
    };
};

export const GetAllPuestos = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/puestos`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getAllPuestosMutation: mutation.mutate,
    };
}