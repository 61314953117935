import React, { useEffect } from "react";
import { Avatar, Row, Spin, Timeline, Typography, Col } from "antd";
import { createUseStyles } from "react-jss";
import { useApiTicketsNotasRapidas } from "../../../hooks/api/tickets_incidencias";
import { DateTime } from "luxon";
import UsuarioPopoverCard from "../../Usuarios/UsuarioPopoverCard";

const useStyles = createUseStyles((theme) => ({
    notasTicketsWrapper: {
        // borderBottom: "1px solid #eee",
        padding: "20px 20px",
    },
    timeLinePerson: {
        "& .ant-avatar": {
            backgroundColor: theme.primaryColor,
            verticalAlign: "middle",
        },
    },
}));

const Nota = ({ nota }) => {
    const styles = useStyles();
    return (
        <div style={{width:'100%'}}>
            <Row>
                <UsuarioPopoverCard avatarSize={24} popoverPlacement="bottomRight" usu_id={nota?.creador?.usu_id} />
                <Typography.Paragraph style={{ margin: 0 }}>{nota?.tici_observaciones}</Typography.Paragraph>
            </Row>
            <Row style={{ justifyContent: "end", alignItems: "end", width:'100%'}} className={styles.timeLinePerson}>
                <Typography.Text type="secondary">{DateTime.fromISO(nota?.tici_fecha).toFormat("dd/MM/yyyy hh:mm a")}</Typography.Text>
            </Row>
        </div>
    );
};

const NotasTicket = ({ tickId }) => {
    const styles = useStyles();
    const { data, isLoading, refetch } = useApiTicketsNotasRapidas({
        id: tickId,
    });

    useEffect(() => {
        setTimeout(() => {
            if (tickId) {
                refetch();
            }
        }, 500)
    }, [tickId])

    return (
        <Spin spinning={isLoading} tip="Cargando Notas ...">
            <div className={styles.notasTicketsWrapper}>
                <Row>
                    <Typography.Title level={5}>Notas</Typography.Title>
                </Row>
                <Row style={{ marginTop: 10, width:'100%' }}>
                    {data && data?.length > 0 ? null : "No hay notas para mostrar"}
                    <Col span={24}>
                        <Timeline>
                            {(data || []).map((nota) => (
                                <Timeline.Item key={`${nota.tici_id}_${nota.tick_id}`}>
                                    <Nota nota={nota} />
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

export default NotasTicket;
