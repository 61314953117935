import { Avatar, Badge, Popover, Rate, Space, Spin } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { BiMessageError } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { TbHandStop } from "react-icons/tb";
// import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { useTimer } from "use-timer";
import { useApiTicketsIncidenciaApoyo } from "../../../../hooks/api/tickets_incidencias";
// import styles from "../styles";
import DataTable from "../../../../components/DataTable";
import UsuarioPopoverCard from "../../../Usuarios/UsuarioPopoverCard";
import { EtipoUsuario } from "../../../../@enums/Usuarios.enum";
import ClientesPopoverCard from "../../../Contactos/ClientesPopoverCard";
import { getCreadorTicket, getObservadorTicket, getResponsableActivo } from "../../../../helpers/agentes.helper";

// const useStyles = createUseStyles(styles);

const TicketsFabricante = ({ tickets, isLoadingTickets }) => {
    const history = useHistory();

    const handleSelectedRow = (ticket) => {
        history.push(`/tickets/${ticket.tick_id}`);
    };

    const renderResponsable = (_, ticket) => {
        let responsable = getResponsableActivo(ticket?.agentes);
        return responsable && (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={responsable?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const renderCreador = (_, ticket) => {
        let creador = getCreadorTicket(ticket?.agentes);
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {
                    creador?.agente?.tiu_id === EtipoUsuario.CLIENTE ?
                        <ClientesPopoverCard con_id={creador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" /> :
                        <UsuarioPopoverCard usu_id={creador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
                }
            </div>
        );
    };

    const renderObservador = (_, ticket) => {
        let observador = getObservadorTicket(ticket?.agentes);
        return !observador ? null : (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={observador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const getActionsButtonTemplate = (ticket) => {
        let initialDate = DateTime.fromISO(ticket.tick_fecharegistro);
        let initialSecondsTime = DateTime.now().toSeconds() - initialDate.toSeconds();
        return <TableNotifications initialSecondsTime={initialSecondsTime} ticket={ticket} />;
    };

    const getRankingTemplate = () => <Rate defaultValue={5} disabled />;

    const formatDate = (ticket) => {
        return DateTime.fromISO(ticket.tick_fecharegistro).toFormat("dd/MM/yyyy hh:mm a");
    }

    const columns = [
        { width: 110, render: getActionsButtonTemplate },
        { title: "Ranking", width: 100, render: getRankingTemplate },
        { index: "tick_clave", title: "Clave", width: 150 },
        { title: "Empresa", width: 250, render: ticket => ticket?.empresa?.emp_razonsocial },
        { title: "Sistema", width: 200, render: ticket => ticket.licencia?.sistema?.sis_nombre },
        { title: "Fecha", width: 90, render: formatDate },
        { title: "Creador", width: 60, render: renderCreador },
        { title: "Responsable", width: 60, render: renderResponsable },
        { title: "Observadores", width: 60, render: renderObservador },
    ]

    return (
        <Spin tip="Cargando tickets en fabricante ..." spinning={isLoadingTickets}>
            <DataTable
                columns={columns}
                data={tickets}
                onRowClick={handleSelectedRow}
                scroll={{x: "100%"}}
                showPagination={true}
            />
        </Spin>
    );
};


const TableNotifications = ({ initialSecondsTime, ticket }) => {
    const { time, start } = useTimer({
        initialTime: initialSecondsTime || 0,
    });
    const [color, setColor] = useState(null);
    const [handBadgeColor, setHandBadgeColor] = useState(null);
    const { data: incidenciasApoyo } = useApiTicketsIncidenciaApoyo({ initiaData: [] }, ticket?.tick_id)
    const [popoverParams, setPopoverParams] = useState({
        title: "",
        content: null,

    });

    useEffect(() => {
        start();
    }, []);

    useEffect(() => {
        if (time <= 60 * 10 && color !== "#00A757") {
            setColor("#00A757");
        } else if (time > 60 * 10 && time < 60 * 20 && color !== "#e0bf00") {
            setColor("#e0bf00");
        } else if (time > 60 * 20 && color !== "#e00000") {
            setColor("#e00000");
        }
    }, [color, time, setColor]);

    useEffect(() => {
        if (incidenciasApoyo?.length > 0) {
            setHandBadgeColor("#fc6731")
            setPopoverParams({
                title: "Solicitando apoyo",
                content: (<div>{incidenciasApoyo[0].tici_observaciones}</div>)
            });
        }
    }, [incidenciasApoyo])

    return (
        <Space size={12} style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
            {/* <Badge dot={true} color="#0f0"> */}
            <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CgFileDocument style={{ width: "20px", height: "20px" }} />
            </div>
            {/* </Badge> */}
            <Popover
                {...popoverParams}
                placement="bottomRight">
                <Badge dot={!!handBadgeColor} color={handBadgeColor} >
                    <div style={{ fontSize: 0, color: handBadgeColor ? handBadgeColor : "#999", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <TbHandStop style={{ width: "20px", height: "20px" }} />
                    </div>
                </Badge>
            </Popover>
            {/* <Badge dot={true}> */}
            <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <BiMessageError style={{ width: "20px", height: "20px", color: color }} />
            </div>
            {/* </Badge> */}
        </Space>
    );
};

export default TicketsFabricante;
