import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { AnimatePresence, motion } from "framer-motion";

const useStyles = createUseStyles({
	chatMessage: {
		width: "100%",
		display: "flex",
		marginBottom: 10,
		flexDirection: "column",
		userSelect: "none",
		"& .message-content": {
			padding: 10,
			maxWidth: "50%",
			wordBreak: "break-word",
			"&.sent": {
				borderRadius: "10px 10px 0 10px",
				// background: theme.primaryColor,
				background: "#0081fa",
				color: "white",
			},
			"&.received": {
				border: "1px solid #ddd",
				borderRadius: "10px 10px 10px 0",
				background: "#fff",
			},
		},
		"& .message-meta": {
			color: "#bbb",
		},
	},
});

const ChatMessage = ({ type, text, date, chatId, user }) => {
	const styles = useStyles();
	const [showDate, setShowDate] = useState(false);

	const handleClickMessage = () => {
		setShowDate(!showDate);
	};

	return (
		<motion.div
			// layout
			className={`${styles.chatMessage} chatmessage-${chatId}`}
			style={{
				alignItems: type === "received" ? "start" : "end",
			}}
			onClick={handleClickMessage}
		>
			<div className={`message-content ${type}`}>{text}</div>
			{user && <div className="message-meta">{user?.usu_nombres}</div>}
			{showDate && <div className={`message-meta`}>{date}</div>}
		</motion.div>
	);
};
    
export default ChatMessage;
