import useApi, { AvailableRequestContentTypes } from "./useApiRequest";
import { useMutation } from "react-query";

// export const useApi2Recuross = (options) => {
//     const api = useApi();
//     return useQuery(
//         ["acuerdos-servicio"],
//         async () => {
//             const { data } = await api.get(`/acuerdos-servicio`);
//             return data;
//         },
//         options
//     );
// };

export const useGuardaRecursos = () => {
    const api = useApi();

    /**
     * 
     * @param {FormData} datosRecursos 
     * @returns 
     */
    const guardaRecursos = async (datosRecursos) => {
        const response = await api.post(`/recursos`, datosRecursos, { dataType: AvailableRequestContentTypes.MULTIPART, hasFormData: true });
        return response?.data;
    };

    const mutation = useMutation(guardaRecursos);

    return {
        saveRecursos: mutation.mutate,
        isSavingRecursos: mutation.isLoading,
        isSavedRecursos: mutation.isSuccess,
        isSavingRecursosError: mutation.isError,
    };
};


/**
 * 
 * @typedef DeletingRecursoDto
 * @property {Number} recu_id - id del recurso   
 */


export const useDeleteRecurso = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingRecursoDto} deleteParams - datos necesarios para eliminar el recurso   
     */
    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/recursos/${deleteParams.recu_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteRecurso: mutation.mutate,
        isDeletingRecurso: mutation.isLoading,
        isDeletedRecurso: mutation.isSuccess,
        isDeletingRecursoError: mutation.isError,
    };
};