import React from 'react';
import PrivateRoute from './PrivateRoute';
import VentasHome from '../views/Ventas';
import VentasTickets from '../views/Ventas/VentasTickets';
import Cotizaciones from '../views/Cotizaciones';
import MetricasLicencias from '../views/Licencias/metricasLicencias';
import CarteraClientes from '../views/CarteraClientes/carteraClientes';
import Actividades from '../views/Actividades/actividades';
import { createUseStyles, useTheme } from 'react-jss';
import { Button, Row, Typography } from 'antd';
import { Switch, Link } from 'react-router-dom';
import VentasCalendario from '../views/Ventas/VentasCalendario';

const useStyles = createUseStyles(theme => ({
    ventasModuleContainer: {
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px 0",
    },
    moduleHeader: {
        padding: "0px 10px 10px",
    },
    moduleActions: {
        display: "flex",
        alignItems: "end",
        gap: 10,
        marginLeft: 20
    },
    btnNavigation: {
        boxShadow: "rgba(0, 0, 0, 0.10) 0px 3px 10px 0px",
        minWidth: 80,
        border: "none",
        transition: "all 0.2s ease-in-out",
        fontWeight: 500,
        padding: "5px 10px",
        textAlign: "center",
        cursor: 'pointer',
        borderRadius: 20,
        "&:hover": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"    
        },
        "&.active": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        }
    }
}));

const VentasRouter = () => {
    const styles = useStyles();

    return (
        <div className={styles.ventasModuleContainer}>
            <Row className={styles.moduleHeader}>
                <Typography.Title level={2} style={{margin: 0}}>Ventas</Typography.Title>
            </Row>
            <Row style={{width: "100%"}}>
                <Switch>
                    <PrivateRoute exact path={["/ventas", "/ventas/tickets"]} key="/ventas">
                        <VentasHome />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/ventas/calendario"} key="/ventas/cotizaciones">
                        <VentasCalendario />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/ventas/cotizaciones"} key="/ventas/cotizaciones">
                        <Cotizaciones />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/ventas/licencias"} key="/ventas/licencias">
                        <MetricasLicencias />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/ventas/cartera_clientes"} key="/ventas/cartera_clientes">
                        <CarteraClientes />
                    </PrivateRoute>
                    
                </Switch>
            </Row>
        </div>
    );
}

export default VentasRouter;