import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const DeleteCertifiacion = () => {
    const api = useApi();

    const mutate = async (params) => {
        console.log(params)
        const data  = await api.del(`/usuarios/${params.usu_id}/certificaciones/${params.id_cert}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        deleteCertificacionMutation: mutation.mutate,
    };
};
// export const UpdateCertifiacion = () => {
//     const api = useApi();

//     const mutate = async (params) => {
//         console.log(params)
//         const data  = await api.put(`/usuarios/${params.usu_id}/certificaciones/${params.id_cert}`, params.data, {
//             dataType: "multipart",
//             hasFormData: true,
//             hasCustomUrl: false,
//         });
//         return data.data;
//     };

//     const mutation = useMutation(mutate);

//     return {
//         updateCertificacionMutation: mutation.mutate,
//     };
// };