import { Button, Form, Input, Row, Spin, message } from "antd";
import { useApiUsuarios } from "../../../hooks/api/usuarios";
import { useEffect } from "react";
import { useTicketsIncidenciasMutation } from "../../../hooks/api/tickets_incidencias";
import { objectToFormData } from "../../../helpers/form.helper";
import { useQueryUtils } from "../../../hooks/api/utils";
import { TicketTipoComunicacion } from "../../../@enums/TicketsIncidencias.enums";

/**
 * @param {Object} props
 * @param {TicketDto} props.ticket - ticket que se va a reasignar 
 * @param {Function}  [props.closeDatosFabricante] - metodo para cerrar la reasignacion de ticket
 * @param {Function}  [props.refetchTicket] - metodo para volver a consultar el ticket
 */
const CapturaDatosFabricante = ({ closeDatosFabricante, ticket, refetchTicket }) => {
    const [form] = Form.useForm();
    const { invalidateQuery } = useQueryUtils();
    const { isLoadingTicketsIncidenciasMutation, mutateTicketsIncidencias, resetTicketsIncidenciasMutation } = useTicketsIncidenciasMutation({ id: ticket?.tick_id });

    const guardaIncidenciaDatosFabricante = (values) => {
        form.validateFields().then(formValues => {
            let params = {
                mutationType: "create",
                tick_id: ticket?.tick_id,
                incidencias: {
                    tick_id: ticket?.tick_id,
                    tipi_id: 3, // comunicacion
                    tici_observaciones: `${formValues.claveTicketFabricante}\r\n${formValues.observaciones}`, // observaciones para fabricante y clave de ticket
                    tici_comunicacion: TicketTipoComunicacion.NOTA,
                    tici_nombre: "Asignación de ticket por fabricante",
                    tici_importante: 0,
                    tici_visiblecliente: 0,
                    tici_ticketdividido: 0,
                    stat_id: 7, // estatus de tipo en espera del fabricante
                }
            }
            params.incidencias = objectToFormData(params.incidencias);
            mutateTicketsIncidencias(
                params,
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            message.success("Se agregaron los datos de fabricante.", 2);
                            resetTicketsIncidenciasMutation();
                            invalidateQuery(["tickets", ticket?.tick_id])
                            // if(refetchTicket) refetchTicket();
                            if(closeDatosFabricante) closeDatosFabricante();
                        } else {
                            message.error(res.mensaje);
                        }
                    },
                    onError: (err) => {
                        message.error("Ocurrio un problema al guardar los datos de fabricante");
                        console.log(err);
                    },
                }
            );
        }).catch(() => message.error("Faltan campos obligatorios"))
    }

    useEffect(() => {
        // se selecciona el ultimo usuario responsable
        if (ticket) {
            // se busca el agente responsable
            const responsable = ticket.agentes.find((agente) => agente.tiag_id === 3 && agente.tica_usumodificaticket);
            form.setFieldsValue({
                usuario: responsable?.usu_id
            })
        }
    }, [ticket, form])

    return (
        <div>
            <Spin spinning={isLoadingTicketsIncidenciasMutation} tip="Cargando ..." >
                <Form form={form} layout="vertical">
                    <Form.Item name="claveTicketFabricante" label="Clave de ticket asignado por fabricante" rules={[{ required: true, message: "La clave de ticket es requerida" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="observaciones" label="Observaciones">
                        <Input.TextArea showCount maxLength={255} />
                    </Form.Item>
                </Form>
                <Row style={{ justifyContent: "end", gap: 10 }}>
                    {/* <Button type="secondary" onClick={closeReasignarTicket} >Cancelar</Button> */}
                    <Button type="primary" onClick={guardaIncidenciaDatosFabricante}>Guardar</Button>
                </Row>
            </Spin>
        </div>
    );
}

export default CapturaDatosFabricante;