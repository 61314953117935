import { createUseStyles, useTheme } from "react-jss";
import Elevation from '../../../components/Elevation';
import { Button, Divider, Form, Rate, Row, Tag, Typography } from 'antd';
import { useEffect, useState } from "react";
import { getRandomTagColor } from "../../../helpers/colors/Tags.colors";
import { VscVerifiedFilled } from "react-icons/vsc";
import { FcProcess } from "react-icons/fc";
import { DATE_FORMATS, convertToSystemDateTimeZone } from "../../../helpers/dates.helper";
import { useHistory } from "react-router-dom";
import { MdDateRange } from "react-icons/md";
import { EditOutlined } from "@ant-design/icons";

const useStyles = createUseStyles(theme => ({
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
        borderRadius: 8,
        minWidth: "100%",
        flex: 1,
        transition: "all 0.2s ease",
        border: "1px solid #fff",
        // userSelect: "none",
        // cursor: "pointer",
        // "&:hover": {
        //     borderColor: theme.colors.secondary,
        // }
    },
    itemCategories: {
        flexDirection: "column",
        justifyContent: 'center',
        marginTop: 5,
        // alignSelf: "end"
    },
    itemFooter: {
        display: "flex", justifyContent: "flex-start", flexDirection: 'row', alignItems: "center"
    },
    category: {
        borderRadius: 15,
        padding: "1px 8px"
    },
    selected: {
        borderColor: `${theme.colors.secondary} !important`,
    },
    containerHeader: {
        flex: 1,
        minWidth: 250,
    },
}));

/**
 * 
 * @param {Object} props
 * @param {Object} props.datos - datos del item
 * @param {React.CSSProperties} [props.style] - estilos del componente
 * @param {Boolean} [props.redirectToItem] - redireccionar a la vista del item
 * @param {Function} [props.onSelectItem] - callback al seleccionar el item
 * @param {Boolean} [props.selected] - si el item esta seleccionado
 * @param {Function} [props.setIsEdditing] - callback para cambiar el estado de edditing
 * @param {Boolean} [props.justReading] - callback para cambiar el estado de edditing
 */
const KnowledgeBaseItem = ({ datos: KnowBaseItem, style, redirectToItem, onSelectItem, selected, toggleEditMode, justReading }) => {
    const theme = useTheme();
    const styles = useStyles();
    const history = useHistory();
    const [categories, setCategories] = useState([]);

    const gotoItem = () => {
        if (redirectToItem)
            history.push(`/know-base/${KnowBaseItem?.kbit_id}`)
        if (onSelectItem && !redirectToItem)
            onSelectItem(KnowBaseItem)
    }

    useEffect(() => {
        if (KnowBaseItem.categories && KnowBaseItem.categories.length > 0) {
            setCategories(KnowBaseItem.categories.map(cat => ({
                label: cat.kbca_nombre,
                color: getRandomTagColor()
            })))
        }
    }, [KnowBaseItem])

    return (
        <>
            {!justReading && (
                <Row style={{ marginBottom: 10, justifyContent: "end" }}>
                    <Button
                        shape="circle"
                        style={{ marginRight: 10 }}
                        icon={<EditOutlined />}
                        onClick={toggleEditMode}
                    />
                </Row>
            )}
            <Row>

                <Elevation className={`${styles.itemContainer} ${selected && styles.selected}`} style={{ ...style }} onClick={gotoItem}>
                    <Row style={{ alignItems: "center", gap: 15, marginBottom: 15, justifyContent: "space-between" }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                        }}>
                            {
                                KnowBaseItem.kbit_aprobado ? < VscVerifiedFilled style={{ fontSize: 20, color: theme.colors.secondary }} /> : <FcProcess style={{ fontSize: 20, color: theme.colors.secondary }} />
                            }
                            <Typography.Title level={4} style={{ margin: 0, }}>{KnowBaseItem.kbit_id} - {KnowBaseItem.kbit_titulo ? KnowBaseItem.kbit_titulo : 'Sin Titulo'}  </Typography.Title>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 30,
                        }}>
                            <Rate value={0} disabled />
                            <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                                <MdDateRange style={{ fontSize: 20, color: theme.colors.secondary }} />
                                <Typography.Title level={5} style={{ margin: 0 }}>{convertToSystemDateTimeZone(KnowBaseItem?.kbit_fecha)?.toFormat(DATE_FORMATS.DATE_LUXON_FORMAT_SLASH_TO_STR)}</Typography.Title>
                            </div>
                        </div>
                    </Row>
                    <Row style={{
                        alignItems: "center",
                        gap: 20,
                        marginBottom: 5,
                        padding: "10px 0",
                        flexWrap: "wrap",
                    }}>
                        <div className={styles.containerHeader}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                            }}>
                                {/* <CgDanger style={{ fontSize: 20, color: theme.colors.secondary }} /> */}
                                <Typography.Title level={5} style={{ margin: 0 }}>Sintomas:</Typography.Title>
                            </div>
                            <div>
                                <Typography.Paragraph style={{ margin: 0, wordBreak: "break-word", whiteSpace: 'pre-line', }} >{KnowBaseItem.kbit_sintomas}</Typography.Paragraph>
                            </div>
                        </div>
                        <div className={styles.containerHeader}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                            }}>
                                {/* <MdReportProblem style={{ fontSize: 20, color: theme.colors.secondary }} /> */}
                                <Typography.Title level={5} style={{ margin: 0 }}>Problematica:</Typography.Title>
                            </div>
                            <div>
                                <Typography.Paragraph style={{ margin: 0, whiteSpace: 'pre-line', wordBreak: "break-word" }} >{KnowBaseItem.kbit_problematica}</Typography.Paragraph>
                            </div>
                        </div>
                    </Row>
                    <Divider dashed style={{ margin: 0 }} />
                    <Row style={{ alignItems: "start", justifyContent: "center", gap: 5, padding: "10px 0", flexDirection: "column" }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                        }}>
                            <Typography.Title level={5} style={{ margin: 0, }}>Solución:</Typography.Title>
                        </div>
                        <div>
                            <Row>
                                <Typography.Paragraph style={{ whiteSpace: 'pre-line', wordBreak: "break-word" }}>
                                    {KnowBaseItem.kbit_solucion}
                                </Typography.Paragraph>
                            </Row>
                        </div>
                    </Row>
                    <Divider dashed style={{ margin: 0 }} />
                    <Row
                        className={styles.itemCategories}
                    >
                        <Typography.Text style={{ color: "#aaa" }}>Categorías</Typography.Text>
                        <div style={{ padding: "10px 0" }}>
                            {
                                categories.map((category, index) => (
                                    <Tag
                                        color={category.color}
                                        key={index}
                                        className={styles.category}
                                    >
                                        {category.label}
                                    </Tag>
                                ))
                            }
                        </div>
                    </Row>
                </Elevation>
            </Row>
        </>
    );
}

export default KnowledgeBaseItem;