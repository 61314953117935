import { Col, Row, Spin, Timeline, Typography } from "antd";
import { DateTime } from "luxon";
import React, {useEffect, useState} from "react";
import { createUseStyles } from "react-jss";
import UsuarioPopoverCard from "../../Usuarios/UsuarioPopoverCard";
import { TipoTicketAgente } from "../../../@enums/TicketsAgentes.enums";
import { useApiUsuario } from "../../../hooks/api/usuarios";

const useStyles = createUseStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "15px 10px",
    },
    timeLinePerson: {
        "& .ant-avatar": {
            backgroundColor: theme.primaryColor,
            verticalAlign: "middle",
        },
    },
    timeline: {
        marginTop: 20,
        "& .ant-timeline-item": {
            padding:'0px !important',
            paddingBottom: '0px !important'
        },
    },
    titles: {
        marginLeft: 5,
        color: `${theme.secondaryColor}`,
        fontWeight: 500,
        fontSize: 14
    }
}));

const Nota = ({ agente, fecha, tipo, actual }) => {
    const styles = useStyles();

    return (
        <div style={{width:'100%'}}>
            <Row>
                <UsuarioPopoverCard avatarSize={24} popoverPlacement="bottomRight" usu_id={agente?.usu_id} />
                <Typography.Paragraph style={{ margin: 0 }}>{agente?.usu_nombres?.toUpperCase()} {agente?.usu_apaterno?.toUpperCase()}</Typography.Paragraph>
            </Row>
            <Row style={{ justifyContent: "end", alignItems: "end", width:'100%'}}>
                {
                    actual ? (
                        <Typography.Paragraph style={{ margin: 0 }}>{tipo?.tiag_nombre} actual</Typography.Paragraph>
                    ) : (
                        <Typography.Paragraph style={{ margin: 0 }}>{tipo?.tiag_nombre}</Typography.Paragraph>
                    )
                }
                
            </Row>
            <Row style={{ justifyContent: "end", alignItems: "end", width:'100%'}} className={styles.timeLinePerson}>
                <Typography.Text type="secondary">{DateTime.fromISO(fecha).minus({ hours: 6 }).toFormat("dd/MM/yyyy hh:mm a")}</Typography.Text>
            </Row>
        </div>
    );
};

const Agentes = ({agentes, idVendedor}) => {
    const styles = useStyles();
    const [agentesData, setAgentesData] = useState(null);
    const [agentesSecundarios, setAgentesSecundarios] = useState(null);
    const { data: vendedor, refetch: refetchVendedor} = useApiUsuario({ initialData: {} }, idVendedor)

    useEffect(() => {
        if(vendedor){
            let agente_vendedor = {
                agente: vendedor,
                tiag_id: 6,
                tica_id: 'vendor_data',
                tica_fecha: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ"),
                tipoAgente: {
                    tiag_descripcion:"Agente vendedor de la empresa",
                    tiag_id:6,
                    tiag_nombre:"Vendedor"
                },
                tica_usumodificaticket: false
            }
            const agentes_filtrados = agentes.reduce((acumulador, objeto) => {
                const existe = acumulador.some(item => item.agente.usu_id === objeto.agente.usu_id && item.tiag_id === objeto.tiag_id);
                if (!existe) {
                    acumulador.push(objeto);
                }
                return acumulador;
            }, []);
            let main_agents = agentes_filtrados.filter(agente =>  (agente?.tipoAgente?.tiag_id === TipoTicketAgente.RESPONSABLE && agente?.tica_usumodificaticket) ||
            agente?.tipoAgente?.tiag_id === TipoTicketAgente.CONTACTO).reverse();
            let secondary_agents = agentes_filtrados.filter(agente =>     (agente?.tipoAgente?.tiag_id === TipoTicketAgente.RESPONSABLE && !agente?.tica_usumodificaticket) ||
                                agente?.tipoAgente?.tiag_id === TipoTicketAgente.AYUDANTE ||
                                agente?.tipoAgente?.tiag_id === TipoTicketAgente.CAPTURISTA ||
                                agente?.tipoAgente?.tiag_id === TipoTicketAgente.OBSERVADOR).reverse();

            main_agents.push(agente_vendedor);
            setAgentesData(main_agents);
            setAgentesSecundarios(secondary_agents);
        }
        
    }, [agentes, vendedor])

    return(
        <div className={styles.container}>
            <Spin tip="Cargando actividades ..." spinning={agentesData && agentesData?.length > 0 && agentesSecundarios && agentesSecundarios?.length > 0 ? false : true}>
                <Row style={{ marginTop: 10, width:'100%' }}>
                        {agentesData && agentesData?.length > 0 ? null : "No hay notas para mostrar"}
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <span className={styles.titles}>Agentes Principales</span>
                                </Col>
                            </Row>
                            <Timeline className={styles.timeline}>
                                {(agentesData || []).map((agente) => (
                                    <Timeline.Item key={`${agente?.tica_id}_${agente.tick_id}`}>
                                        <Nota key={agente?.tica_id} agente={agente?.agente} fecha={agente?.tica_fecha} tipo={agente?.tipoAgente} actual={agente?.tica_usumodificaticket}/>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Col>
                        {agentesSecundarios && agentesSecundarios?.length > 0 ? null : "No hay notas para mostrar"}
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <span className={styles.titles}>Otros Agentes</span>
                                </Col>
                            </Row>
                            <Timeline className={styles.timeline}>
                                {(agentesSecundarios || []).map((agente) => (
                                    <Timeline.Item key={`${agente?.tica_id}_${agente.tick_id}`}>
                                        <Nota key={agente?.tica_id} agente={agente?.agente} fecha={agente?.tica_fecha} tipo={agente?.tipoAgente} actual={agente?.tica_usumodificaticket}/>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Col>
                </Row>
            </Spin>
            
        </div>
    )

}

export default Agentes;