import { LOADING, LOADED } from "../action_types/loading.action.types";

const loadingInitialState = {
    show: false,
    message: ""
};
const LoadingReducer = (state = loadingInitialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                show: true,
                message: action.payload?.message ? action.payload.message : "" 
            };
        }
        case LOADED: {
            return {
                show: false,
                message: ""
            };
        }
        default:
            return state;
    }
};

export default LoadingReducer;
