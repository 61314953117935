import { SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { BiStore } from "react-icons/bi";
import { BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from "react-icons/bs";
import { MdOutlineFacebook } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import { useEmpresasMutation } from "../../../hooks/api/empresas";

const useStyles = createUseStyles({
    redes: {
        flex: 1,
        gap: 5,
        width: "100%",
        padding: 15,
        flexDirection: "column",
        height: "100%",
        "& .ant-form-item": {
            marginBottom: 12,
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    inputCustomAddon: {
        display: "flex",
        gap: 6,
        fontSize: 12,
        background: "#1DA1F2",
        color: "white",
        height: 30,
        padding: "0 5px",
        alignItems: "center",
        justifyContent: "center",
    },
    inputWrapper: {
        "& .ant-input-group-addon": {
            padding: 0,
            border: "none",
        },
        "& input": {
            borderLeft: "none",
            border: "1px solid #d9d9d9",
            padding: "4px 11px",
            position: "relative",
        },
    },
});

const EmpresaRedes = ({ empresa }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { mutateEmpresa, isLoadingEmpresaMutation } = useEmpresasMutation();

    const handleClickGuardarRedes = () => {
        form.validateFields()
            .then((values) => {
                mutateEmpresa(
                    { ...values, mutationType: "update", emp_id: empresa?.emp_id },
                    {
                        onSuccess: (res) => {
                            if (res?.success) {
                                message.success(res.mensaje);
                            } else {
                                message.error("hubo un problema al guardar las redes");
                            }
                        },
                        onError: () => {
                            message.error("hubo un problema al guardar las redes");
                        },
                    }
                );
            })
            .catch(() => {
                message.error("hubo un problema al guardar las redes");
            });
    };

    useEffect(() => {
        form.setFieldsValue({
            emp_facebook: empresa?.emp_facebook,
            emp_twitter: empresa?.emp_twitter,
            emp_youtube: empresa?.emp_youtube,
            emp_instagram: empresa?.emp_instagram,
            emp_linkedin: empresa?.emp_linkedin,
            emp_paginaweb: empresa?.emp_paginaweb,
            emp_tiendaweb: empresa?.emp_tiendaweb,
        });
    }, [empresa]);

    return (
        <Elevation className={styles.redes}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Redes</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    <Button type="primary" shape="circle" size="small" icon={<SaveOutlined />} onClick={handleClickGuardarRedes} loading={isLoadingEmpresaMutation} />
                </div>
            </Row>
            <Row>
                <Form layout="vertical" style={{ width: "100%" }} form={form}>
                    <Form.Item style={{ width: "100%" }} name="emp_facebook">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "#1877F2",
                                        color: "white",
                                    }}
                                >
                                    <MdOutlineFacebook style={{ fontSize: 18 }} />
                                    <Typography.Text style={{ color: "white" }}>Facebook</Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: "100%" }} name="emp_twitter">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "#1DA1F2",
                                        color: "white",
                                    }}
                                >
                                    <BsTwitter style={{ fontSize: 18 }} />
                                    <Typography.Text style={{ color: "white" }}>Twitter</Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: "100%" }} name="emp_youtube">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "#FF0000",
                                        color: "white",
                                    }}
                                >
                                    <BsYoutube style={{ fontSize: 18 }} />
                                    <Typography.Text style={{ color: "white" }}>Youtube</Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: "100%" }} name="emp_instagram">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)",
                                        color: "white",
                                    }}
                                >
                                    <BsInstagram style={{ fontSize: 18 }} />
                                    <Typography.Text
                                        style={{
                                            color: "white",
                                        }}
                                    >
                                        Instagram
                                    </Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: "100%" }} name="emp_linkedin">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "#0073B2",
                                        color: "white",
                                    }}
                                >
                                    <BsLinkedin style={{ fontSize: 18 }} />
                                    <Typography.Text
                                        style={{
                                            color: "white",
                                        }}
                                    >
                                        Linkedin
                                    </Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: "100%" }} name="emp_paginaweb">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "#fff",
                                        color: "#0073B2",
                                        border: "1px solid #d9d9d9",
                                    }}
                                >
                                    <TbWorld style={{ fontSize: 18 }} />
                                    <Typography.Text>Sitio web</Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                    <Form.Item style={{ width: "100%" }} name="emp_tiendaweb">
                        <Input
                            addonBefore={
                                <div
                                    className={styles.inputCustomAddon}
                                    style={{
                                        background: "#ff7a05",
                                        color: "white",
                                    }}
                                >
                                    <BiStore style={{ fontSize: 18 }} />
                                    <Typography.Text style={{ color: "white" }}>Tienda web</Typography.Text>
                                </div>
                            }
                            className={styles.inputWrapper}
                        />
                    </Form.Item>
                </Form>
            </Row>
        </Elevation>
    );
};

export default EmpresaRedes;
