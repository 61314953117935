/**
 * @typedef {Object} TicketTipoComunicacion
 */
export const TicketTipoComunicacion = Object.freeze({
    NOTA: 1,
    CORREO_ENTRANTE: 2,
    CORREO_SALIENTE: 3,
    LLAMADA_ENTRANTE: 4,
    LLAMADA_SALIENTE: 5,
});

/**
 * @typedef {Object} TipoIncidencia
 */
export const TipoIncidencia = Object.freeze({
    NOTA_RAPIDA: 1,
    TICKET_TOMADO: 2,
    SEGUIMIENTO: 3,
    PIDIENDO_APOYO: 4,
    PIDIENDO_APOYO_A_TODOS: 5,
    CERRANDO_TICKET: 6,
    TICKET_CERRADO: 7,
    ENCUESTA_CONTESTADA: 8,
    TIEMPO_EXCEDIDO: 9,
    REASIGNACION_DE_TICKET: 10
});


