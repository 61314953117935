import { Image, Modal, Typography } from "antd";
import { useModal } from "../../hooks/useModal";
import { SiMicrosoftexcel } from "react-icons/si";
import { AiOutlineFile, AiOutlineFilePdf } from "react-icons/ai";
import { Viewer } from "@react-pdf-viewer/core";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
    fileItem: {
        flex: 1,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #eee",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.03)",
            border: `1px solid ${theme.secondaryColor}`,
        }
    }
}));

const FileItem = ({ type, filename, src, style }) => {
    const styles = useStyles();
    const [show, showModal, closeModal] = useModal();

    const getIco = () => {
        if (type === "image/png" || type === "image/jpeg") {
            return <Image
                width={150}
                src={src}
            />
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return <SiMicrosoftexcel fontSize={32} color="#218450" />
        } else if (type === "application/pdf") {
            return <AiOutlineFilePdf fontSize={32} color="#c20a0a" />
        } else {
            return <AiOutlineFile fontSize={32} color="#888" />
        }
    }

    const getPreviewComponent = () => {
        if (type === "image/png" || type === "image/jpeg") {
            return <Image
                width={150}
                src={src}
            />
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return <div>Previsualizador no disponible para este tipo de archivo</div>
        } else if (type === "application/pdf") {
            return <Viewer fileUrl={src} />
        } else {
            return <div>Previsualizador no disponible para este tipo de archivo</div>
        }
    }

    const handleClickVisualizaRecurso = () => {
        if (type !== "image/png" && type !== "image/jpeg")
            showModal();
    }

    return <div>
        <div className={styles.fileItem} style={style} onClick={handleClickVisualizaRecurso}>
            {getIco()}
            <Typography.Title level={5} style={{ margin: 0 }}>{filename}</Typography.Title>
        </div>
        <Modal footer={null} title={filename} visible={show} onOk={closeModal} onCancel={closeModal}>
            <div
                style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "750px",
                }}
            >
                {
                    getPreviewComponent()
                }
            </div>
        </Modal>
    </div>
}

export default FileItem;