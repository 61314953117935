import React, {useState, useEffect} from "react";
import { PostActividad } from "../../../hooks/api/newAactividad";
import { NewPuestoActividad } from "../../../hooks/api/newpuesto_actividad";
import { DeletePuestoActividad } from "../../../hooks/api/deletepuesto-actividad";
import { PutActividad } from "../../../hooks/api/updateactividad";
import { useApiPuestos } from "../../../hooks/api/puestos";
import { useDeleteRecurso } from "../../../hooks/api/recursos";
import { Modal, Form, Input, Row, Col, Checkbox, Button, Upload, message} from "antd";
import { InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;
import './styles.css';
import moment from "moment";

const NewActividad = ({openNew, setOpenNew, form, updateTable, fileList, setFileList, isEdit, setIsEdit}) => {

    const {postActividadMutation} = PostActividad();
    const {newPuestoActividadMutation} = NewPuestoActividad();
    const {deletePuestoActividadMutation} = DeletePuestoActividad();
    const {putActividadMutation} = PutActividad();
    const { data: puestos } = useApiPuestos();
    const [filesDelete, setFilesDelete] = useState([])
    const { deleteRecurso, isDeletingRecurso } = useDeleteRecurso();


    const onFinish = (values) => {
        let dataNew = {
            acti_descripcion: values.acti_descripcion,
            acti_nombre: values.acti_nombre,
            files: values.files !== undefined ? values.files : []
        }
        postActividadMutation(dataNew, {
            onSuccess: (data) => {
                console.log(data)
                values.pues_id.map(puesto => {
                    let pues_act_data = {
                        puac_fecha: moment().format('YYYY-MM-DDTHH:mm:ss.000[Z]'),
                        pues_id: puesto,
                        acti_id: data.data
                    }
                    newPuestoActividadMutation(pues_act_data, {
                        onSuccess: (dataPuestos) => {
                            console.log(dataPuestos)
                            updateTable();
                            form.resetFields();
                            setFileList([])
                            setFilesDelete([])
                            setOpenNew(false)
                        }, 
                        onError: (error) => {
                            console.log(error)
                        }
                    })
                })
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const onFinishEdit = (values) => {
        console.log(values)
        const files = {
            fileList : []
        }
        if(values.files !== undefined){
            files.fileList =  values.files.fileList.filter(file => file.type !== undefined)
        }
        let params = {
            acti_id: values.acti_id,
            acti_nombre: values.acti_nombre,
            acti_descripcion: values.acti_descripcion,
            files: files
        }
        deletePuestoActividadMutation(values.acti_id, {
            onSuccess: (data) => {
                console.log(data)
                values.pues_id.map(puesto => {
                    let pues_act_data = {
                        puac_fecha: moment().format('YYYY-MM-DDTHH:mm:ss.000[Z]'),
                        pues_id: puesto,
                        acti_id: values.acti_id
                    }
                    newPuestoActividadMutation(pues_act_data, {
                        onSuccess: (dataPuestos) => {
                            console.log(dataPuestos)
                        }, 
                        onError: (error) => {
                            console.log(error)
                        }
                    })
                })
            },
            onError: (error) => {
                console.log(error)
            }
        })
        filesDelete.map(fileDelete => {
            deleteRecurso({ recu_id: fileDelete}, {
                onSuccess: (res) => {
                    console.log(res)
                },
                onError: (error) => {
                    console.log(error)
                }
            })
        })
        putActividadMutation(params,{
            onSuccess: (data) => {
                message.success(data.mensaje)
                updateTable();
                form.resetFields();
                setFileList([])
                setFilesDelete([])
                setOpenNew(false);
                setIsEdit(false)
            },
            onError: (error) => {
                message.error('Error al actualizar, intente nuevamente')
                console.log(error)
                form.resetFields();
                setFileList([])
                setFilesDelete([])
                setOpenNew(false);
                setIsEdit(false)
            }
        })


    }
    
    const onCancel = () => {
        form.resetFields();
        setFileList([])
        setFilesDelete([])
        setOpenNew(false)
        setIsEdit(false)
    }


    //FILES CONTROL
    const allowedFileTypes = [
        "application/vnd.ms-excel", // xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
        "application/msword", // doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
        "application/pdf", // pdf
        "image/jpeg", // jpeg
        "image/png", // png
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    const handleFileUpload = (file) => {
        const isFileTypeAllowed = allowedFileTypes.includes(file.type);
        const isFileSizeValid = file.size <= maxFileSize;
    
        if (!isFileTypeAllowed) {
            message.error("Solo se permiten archivos de tipo: xlsx, xls, doc, docx, pdf, jpeg, jpg, png");
            return false;
        }
    
        if (!isFileSizeValid) {
            message.error('El archivo es demasiado grande, el tamaño máximo permitido es de 5MB.');
            return false;
        }
    
        // Agregar el archivo a la lista de archivos seleccionados
            setFileList([...fileList, file]);
    
        return false;
    };

    


    return(
        <Modal
        title="Crear nueva actividad"
        open={openNew}
        onCancel={onCancel}
        destroyOnClose
        footer={null}
        width={600}
        >
            {puestos !== undefined ? (
                <Form form={form} onFinish={isEdit ? onFinishEdit : onFinish} layout="vertical">
                    <Row>
                        <Col span={17} style={{padding:5}}>
                            <Form.Item style={{ flex: 1 }} name="acti_id" label="Nombre de la actividad" hidden>
                                <Input/>
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} name="acti_nombre" label="Nombre de la actividad" rules={[{ required: true, message: "El nombre es requerido." }]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={7}  style={{padding:'5px 15px'}}>
                            <Form.Item style={{ flex: 1 }} name="pues_id" label="Puesto designado" rules={[{ required: true, message: "El nombre es requerido." }]}>
                                <Checkbox.Group options={puestos.map(puesto => {return {value: puesto.pues_id, label: puesto.pues_nombre}})} defaultValue={[]} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item style={{ flex: 1 }} name="acti_descripcion" label="Descripción de la actividad" rules={[{ required: true, message: "El nombre es requerido." }]}>
                                <Input.TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                        <Form.Item name='files'>
                                <Dragger
                                    name="file"
                                    multiple={false}
                                    beforeUpload={handleFileUpload}
                                    fileList={fileList}
                                    onRemove={(file) => {
                                        setFilesDelete([...filesDelete, file.uid ])
                                        const updatedList = fileList.filter((item) => item.uid !== file.uid);
                                        setFileList(updatedList);
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Haz clic o arrastra el archivo a esta área para subirlo</p>
                                    <p className="ant-upload-hint">Solo se permiten archivos de tipo: xlsx, xls, doc, docx, pdf, jpeg, jpg, png y con un tamaño máximo de 5MB.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', justifyContent:'flex-end'}}>
                        <Col span={4}>
                            <Button type="secondary"  onClick={onCancel}>
                                Cancelar
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            ):null}
    </Modal>
    )

}

export default NewActividad;