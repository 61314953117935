import { Button, Col, Input, Modal, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import { useKnowBaseItems } from "../../../hooks/api/knowBase";
import { createUseStyles } from "react-jss";
import DataTable from "../../../components/DataTable";
import { Link } from "react-router-dom";
import KnowledgeBaseItemPage from "../../KMS/KnowledgeBaseItemPage";
import { CloseOutlined } from "@ant-design/icons";

const useStyles = createUseStyles(theme => ({
    knowledgeBaseItems: {
        display: "flex",
        marginTop: 20,
        gap: 10,
        marginBottom: 20,
        overflowY: "auto",
        flexDirection: "column",
        flex: 1
    },
    inputSearchWrapper: {
        display: "flex",
        gap: 10,
    },
    itemsWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    }
}));


/**
 * 
 * @param {Object} props
 * @param {Boolean} [props.redirectToItemPage] - redireccionar a la vista del item
 * @param {Function} [props.onSelectKnowBaseItem] - callback al seleccionar el item
 * @returns 
 */

const TicketsKnowBaseTab = () => {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageItems, setPageItems] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [idModal, setIdModal] = useState(null);
    const { data: KnowBaseItems, refetch: refetchKnowBaseItems, isLoading } = useKnowBaseItems({ initialData: [] }, { criteria: searchCriteria });

    const getPageItems = () => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setPageItems(KnowBaseItems.slice(indexOfFirstItem, indexOfLastItem));
    }

    const onSearch = (value) => {
        setSearchCriteria(value);
        setTimeout(() => {
            refetchKnowBaseItems();
        }, 200);
    }

    const handleKeyUpSearch = (e) => {
        if (e.key === "Backspace" && e.target.value.length === 0 && searchCriteria !== "") {
            onSearch(e.target.value);
        }
    }

    const closeModal = () => {
        Modal.destroyAll();
        setIsModalOpen(false);
        setIdModal(null);
    }

    const openModal = (id) => {
        setIdModal(id);
        setIsModalOpen(true);
    }

    useEffect(() => {
        getPageItems();
    }, [KnowBaseItems]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            flex: 1,
            width: "100%",
            overflowX: "auto"
        }}>
            <Row style={{ marginBottom: 20 }}>
                <Input.Search placeholder="Buscar en la cimiento" onSearch={onSearch} enterButton onKeyUp={handleKeyUpSearch} />
            </Row>
            <Row justify={"end"} style={{overflowX: "auto"}}>
                <DataTable
                    columns={[
                        {
                            title: "Folio",
                            index: "kbit_id",
                        },
                        {
                            title: "Título",
                            width: 100,
                            index: "kbit_titulo",
                            render: (text, record) => (
                                <Button key={record?.kbit_id} type="link" onClick={()=>openModal(record?.kbit_id)}>
                                    {text}
                                </Button>
                            )
                        },
                        {
                            title: "Problemática",
                            width: 150,
                            index: "kbit_problematica",
                        },
                        {
                            title: "Solución",
                            width: 150,
                            index: "kbit_solucion",
                        },
                        {
                            title: "Categorías",
                            render: (_, record) => (
                                <div style={{ display: "flex", flexDirection: "column", gap: 5, flexWrap: "wrap", margin: "5px 0 5px 0" }}>
                                    {
                                        record?.categories?.slice(0, 2).map((categoria, index) => (
                                            <Tag key={index} style={{
                                                width: "fit-content",
                                                wordBreak: "break-word",
                                            }}>
                                                {categoria?.kbca_nombre}
                                            </Tag>
                                        ))
                                    }
                                </div>
                            ),
                            width: 150
                        }
                    ]}
                    data={pageItems}
                    scroll={{ x: "100%" }}
                    size={"small"}
                    style={{ width: "100%" }}
                />
            </Row>
            {idModal !== null && (
                <Modal key={idModal} open={isModalOpen} footer={false} closable={false} style={{paddingBottom:0}} centered={true} bodyStyle={{marginTop:10, marginBottom:-15, paddingBottom:-10, minHeight:420}}>
                    <Row style={{marginBottom:10}}>
                        <Col span={24} style={{display:'flex', justifyContent:'flex-end'}}>
                            <CloseOutlined  style={{color:'black'}} onClick={()=>closeModal()}/>
                        </Col>
                    </Row>
                    <KnowledgeBaseItemPage key={idModal} id_modal={idModal}/>
                </Modal>
            )}
        </div >
    );
}

export default TicketsKnowBaseTab;