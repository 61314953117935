import "antd/dist/antd.css";
import "./App.css";
import "animate.css";
import 'react-chat-elements/dist/main.css'
import React, { useEffect, useState } from "react";
import { Layout, Affix } from "antd";
import SideBar from "./app/views/partials/SideBar";
import Routes from "./app/router";
import { createUseStyles } from "react-jss";
import NavBar from "./app/views/partials/NavBar";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import TicketsRouter from "./app/router/TicketsRoutes";
import TicketsChats from "./app/views/Tickets/TicketsChats";
import { DateTime } from "luxon";
import { setConfig } from "./app/redux/actions/config.actions";
import { AppRegexRegisteredRoutes } from "./app/router/registered_routes";
import PerfilRoutes from "./app/router/PerfilRoutes";
import VentasRouter from "./app/router/VentasRouter";

const ticketsChatAvailableInRoutes = [/\/tickets/, /\/tickets\/[0-9]+/]

const { Content } = Layout;
const useStyles = createUseStyles((theme) => ({
    appContainer: {
        width: "100% !important",
        // backgroundImage: 'linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url("https://scontent.fclq1-1.fna.fbcdn.net/v/t39.30808-6/217944838_2317975071668629_8338091755689585328_n.png?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeEoegaF7KdZ1nhXC8sXGvUdZSX6-ABitfBlJfr4AGK18N9X08IPReHw8j0Vu8AT779FwvCBt7MR5jGM0hgfa4ju&_nc_ohc=KRVXibKL0-4AX8IgPLI&_nc_ht=scontent.fclq1-1.fna&oh=00_AT8u8KKfL1eo3ctYfrs1Up6nuT4hRbXwpvgHnuANq7HJXA&oe=61DE752E")',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        // backgroundColor: "#F8F9FE !important",
        backgroundColor: "#f8f8f8 !important",
        // backgroundColor: "#fff !important",
        // paddingRight: 30,
        paddingTop: "0 !important",
        // paddingLeft: 30,
    },
    appFooter: {
        textAlign: "center",
        // backgroundColor: "#fff !important",
        borderTop: "1px solid rgba(200,200,200, 0.3)",
    },
    activeBreadcrum: {
        color: theme.primaryColor,
    },
    loading: {
        zIndex: 10000,
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100vw !important",
        height: "100vh !important",
        backgroundColor: "rgba(255,255,255,1)",
        fontSize: 12,
        "& .loading-message": {
            marginTop: 20,
            fontSize: "16px",
        },
    },

}));


function App() {
    const styles = useStyles({});
    const location = useLocation();
    const dispatch = useDispatch();
    const [layoutSpaces, setLayoutSpaces] = useState({ paddingLeft: 0 });
    const { loading, configuracion } = useSelector((state) => state);
    const [showTicketsChats, setShowTicketsChats] = useState(true)
    const [notFound, setNotFound] = useState(false);


    const pageIsPublicLocation = () => {
        const publicRoutes = ["/", "/login", "/home_menu"];
        // const isPublicRoute = publicRoutes.find(route => location?.pathname.includes(route))
        const isPublicRoute = publicRoutes.find(route => route.includes(location?.pathname))
        return !!isPublicRoute;
    };

    useEffect(() => {
        // let existsRoute = AppRegisteredRoutes.find(route => location.pathname.includes(route));
        let existsRoute = AppRegexRegisteredRoutes.find(route => route.test(location.pathname));
        setNotFound(existsRoute ? false : true)
        setLayoutSpaces({
            paddingLeft: pageIsPublicLocation() || !configuracion.sideBar.visible || !existsRoute ? 0 : 30,
        });
    }, [location, configuracion])

    useEffect(() => {

        // se valida el valor de la fecha en la que fue la ultima vez que se mostraron las notificaciones
        let now = DateTime.now();
        let ultimaFechaNotificaciones = localStorage.getItem("ufnm");
        if (!ultimaFechaNotificaciones) {
            localStorage.setItem("ufnm", now.toString());
        } else {
            // validar si se tiene que renovar la fecha de las notificaciones para volver a mostrar las que se tengan que mostrar ese dia
            let ultimaFecha = DateTime.fromISO(ultimaFechaNotificaciones);
            if (now.day > ultimaFecha.day) {
                localStorage.setItem("nm", "[]");
                localStorage.setItem("ufnm", now.toString());
            }
        }

        // se valida el objeto temporal en localstorage para las notificaciones mostradas
        let notificacionesMostradas = localStorage.getItem("nm");
        if (!notificacionesMostradas) {
            localStorage.setItem("nm", "[]");
        }

        if (location?.pathname) {
            let isInTicketsRoute = ticketsChatAvailableInRoutes.find(routeRegex => routeRegex.test(location.pathname))
            setShowTicketsChats(!!isInTicketsRoute)
        }
    }, []);

    const handleClickAppContainer = () => {
        dispatch(setConfig({
            notificaciones: { visible: false }
        }))
    }

    return (
        <div onClick={handleClickAppContainer}>
            {!loading.show ? null : (
                <div className={styles.loading} id="loading-wrapper">
                    <LoadingOutlined style={{ fontSize: 48 }} spin />
                    <div className="loading-message">{loading.message}</div>
                </div>
            )}
            <Layout>
                <Affix offsetTop={0}>
                    {
                        configuracion.header.visible &&
                        <NavBar />
                    }
                </Affix>
                <Layout
                    className="flow-transition"
                    style={{
                        paddingLeft: configuracion.sideBar.collapsed ? layoutSpaces.paddingLeft : 200,
                        flexDirection: "column",
                        minHeight: "calc(100vh - 164px)",
                    }}
                >
                    {
                        configuracion.sideBar.visible &&
                        <SideBar />
                    }
                    <Content
                        className={styles.appContainer}
                        style={{ paddingTop: pageIsPublicLocation() || notFound ? 0 : 10, paddingLeft: pageIsPublicLocation() || notFound ? 0 : 30 }}
                    >
                        {/* <Breadcrumb /> */}
                        <TicketsRouter />
                        <PerfilRoutes />
                        <Routes />
                    </Content>
                    {/* <Footer className={styles.appFooter}>Kronox & Kairós S. de R.L. de C.V</Footer> */}
                </Layout>
                {
                    showTicketsChats && <TicketsChats />
                }
            </Layout>
        </div>
    );
}

export default App;
