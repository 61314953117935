import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Row, Select, Table, Tooltip, Typography, Switch, Checkbox, Popconfirm, Divider, Alert, Tabs } from 'antd';
import React, { useEffect, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Elevation from '../../../components/Elevation';
import { useModal } from '../../../hooks/useModal';
import { useHistory } from "react-router-dom"
import { useAddEmpresaContacto, useDeleteEmpresaContacto } from '../../../hooks/api/empresas';
import { useApiContactos } from '../../../hooks/api/contactos';
import useApi, { AvailableRequestContentTypes } from '../../../hooks/api/useApiRequest';
import { usePopover } from '../../../hooks/usePopover';
import NuevoContacto from '../../Contactos/NuevoContacto';

const useStyles = createUseStyles({
    container: {
        padding: 15,
        flex: 1,
        height: "100%",
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    newContactModal: {
        "& .ant-modal-body": {
            padding: 10,
        }, 
        "& .ant-tabs-nav": {
            maginBottom: "20px !important"
        }
    },
    modalContainer: {
        "& .ant-form-item": {
            flex: 1
        }
    },
    formItemSwitch: {
        display: "flex",
        alignItems: "center",
        "& .ant-form-item": {
            width: 50,
            flex: "none",
            margin: 0
        },
    }
});

/**
 * 
 * @param {Object} props 
 * @param {Object} props.empresa - objeto de la empresa 
 * @param {Object} [props.refetchEmpresa] - metodo para volver a consultar la info de la empresa
 * @returns 
 */
const EmpresaContactos = ({ empresa, refetchEmpresa }) => {
    const theme = useTheme();
    const api = useApi();
    const [replaceContactConfirmationVisible, openReplaceContactConfirmation, closeReplaceContactConfirmation] = usePopover();
    const styles = useStyles();
    /**
     * @type {[Boolean, Function, Function]}
     */
    const [visible, showModal, closeModal] = useModal();
    const history = useHistory();
    const { data: contactos, refetch: refetchContactosEmpresa } = useApiContactos({ initialData: [] }, { rwc: 0, emp_id: empresa?.emp_id });
    const { addEmpresaContacto } = useAddEmpresaContacto();
    const { deleteEmpresaContacto } = useDeleteEmpresaContacto();
    const settingMainContact = useRef(null);
    const [form] = Form.useForm();

    /**
     * 
     * @param {Number} usu_id - id del contacto
     * @param {Boolean} use_principal - si es el contacto principal de la empresa 
     */
    const addNewContactOrganizationRelation = (usu_id, use_principal) => {
        addEmpresaContacto(
            { usu_id, use_principal, emp_id: empresa?.emp_id },
            {
                onSuccess: (res) => {
                    if (res?.success) {
                        message.success(res.mensaje);
                        closeModal();
                        refetchContactosEmpresa();
                        refetchEmpresa();
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: (error) => {
                    message.error("hubo un problema al relacionar el contacto");
                    closeModal()
                },
            }
        );
    }

    const agregarContacto = () => {
        form.validateFields()
            .then(({ usu_id, use_principal }) => {
                addNewContactOrganizationRelation(usu_id, use_principal);
            })
            .catch(() => {
                message.error("Faltan campos requeridos");
            });
    }

    const onSaveNewContact = (contactData) => {
        // se crea la relacion del contacto con la empresa
        addNewContactOrganizationRelation(contactData.usu_id, false);
    }

    /**
     * se elimina la relacion del usuario con la empresa
     * @param {Number} usu_id - id del usuario 
     */
    const quitarContacto = (usu_id) => {
        // se valida que no se eliminen todos los contactos de la empresa
        if (empresa.contactos.length > 1) {
            // se quita el contacto de la empresa
            deleteEmpresaContacto({ usu_id, emp_id: empresa?.emp_id }, {
                onSuccess(data) {
                    if (data.success) {
                        message.success(data.mensaje);
                        refetchContactosEmpresa();
                        refetchEmpresa();
                    } else message.error(data.mensaje);
                }, onError() {
                    message.error("Hubo un problema al quitar el contacto");
                }
            })
        } else {
            message.error("La empresa debe de contar con al menos un contacto");
        }
    }

    const goToContacto = (id) => {
        history.push(`/contactos/${id}`);
    }

    const handleChangeContactoPrincipal = (use, checked) => {
        if (use)
            updateUsuarioEmpresa(use.emp_id, { use_id: use.use_id, usu_id: use.usu_id, use_principal: checked });
    }

    const updateUsuarioEmpresa = (emp_id, data) => {
        api.put(`/empresas/${emp_id}/contactos/${data.usu_id}`, data, { dataType: AvailableRequestContentTypes.JSON })
            .then(({ data: res }) => {
                if (res?.success) {
                    message.success(res.mensaje);
                    if (refetchEmpresa)
                        refetchEmpresa();
                    closeReplaceContactConfirmation();
                    settingMainContact.current = null;
                } else {
                    // se valida si el error generado por la api es por que el contacto ya tiene una empresa principal
                    if (res.error_code == 2) {
                        // se guarda el contacto que se esta intentando cambiar
                        settingMainContact.current = data;
                        // se muestra el popover
                        openReplaceContactConfirmation();
                    } else {
                        message.error(data.mensaje);
                    }
                }
            }).catch(err => {
                message.error("Error al cambiar el contacto principal");
            })
    }

    const handleConfirmChangeContactoPrincipal = () => {
        updateUsuarioEmpresa(empresa.emp_id, {
            use_id: settingMainContact.current.use_id,
            usu_id: settingMainContact.current.usu_id,
            use_principal: true,
            replace_contact: true
        });
    }

    const handleCancelChangeContactoPrincipal = () => {
        settingMainContact.current = null;
        closeReplaceContactConfirmation();
    }

    const renderIsContactoPrincipal = (_, contacto) => {
        let relacionEmpresa = contacto?.usuarios_empresas?.find(use => use.emp_id === empresa.emp_id && use.usu_id === contacto.usu_id);
        return (
            <Popconfirm
                placement='bottomRight'
                title={
                    <div>
                        <Alert type='warning' message="La empresa o el contacto ya tiene un regstro principal." style={{ marginBottom: 5 }} />
                        ¿Quieres reemplazar el contacto principal de esta empresa?
                    </div>
                }
                okText="Si"
                cancelText="No"
                onCancel={handleCancelChangeContactoPrincipal}
                onConfirm={handleConfirmChangeContactoPrincipal}
                open={settingMainContact.current?.usu_id === contacto?.usu_id && replaceContactConfirmationVisible}
            >
                <Checkbox checked={relacionEmpresa?.use_principal} onClick={(e) => handleChangeContactoPrincipal(relacionEmpresa, e.target.checked)} />
            </Popconfirm>
        )
    }

    useEffect(() => {
        if (empresa?.emp_id) {
            refetchContactosEmpresa();
        }
    }, [empresa])

    let columns = [
        {
            title: "Nombre",
            render: (_, contacto) =>
                <Button type="link" onClick={() => goToContacto(contacto?.usu_id)}>{contacto?.usu_nombres} {contacto?.usu_apaterno} {contacto?.usu_amaterno}</Button>
        },
        {
            dataIndex: "usu_mail",
            title: "Correo electrónico"
        },
        {
            dataIndex: "usu_tel",
            title: "Teléfono"
        },
        {
            title: "Principal",
            render: renderIsContactoPrincipal
        },
        {
            title: "Acciones",
            render: (_, contacto) => {
                return <div style={{ display: "flex", gap: 15 }}>
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => quitarContacto(contacto?.usu_id)} />
                </div>
            }
        }
    ];

    const agregarContactoTabs = [
        {
            label: 'Contacto existente',
            key: 'existing_contact',
            children: (
                <>
                    <Form layout='vertical' form={form} >
                        <Form.Item name="usu_id" label="Contacto" rules={[{ required: true, message: "El contacto es requerido" }]}>
                            <Select
                                showSearch
                                optionFilterProp='children'
                                filterOption={(input, option) => option.children.join(" ").toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    contactos.map((cont) => <Select.Option value={cont.usu_id} key={`contacto_${cont.usu_id}`}>
                                        {cont?.usu_nombres} {cont?.usu_apaterno} {cont?.usu_amaterno} - {cont.usu_mail}
                                    </Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <div className={styles.formItemSwitch}>
                            <Form.Item
                                name="use_principal"
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                            <div style={{ flex: 1 }}>¿Es un contacto principal de la empresa?</div>
                        </div>
                    </Form>
                    <Row style={{ justifyContent: "end", marginTop: 15 }}>
                        <Button type="primary" onClick={agregarContacto}>Agregar Contacto</Button>
                    </Row>
                </>
            )
        },
        {
            label: 'Nuevo contacto',
            key: 'new_contact',
            children: (
                <NuevoContacto newContactSaved={onSaveNewContact} />
            )
        }
    ]

    return (
        <>
            <Elevation className={styles.container}>
                <Row style={{ justifyContent: "space-between" }}>
                    <Typography.Title level={5}>Contactos de la empresa</Typography.Title>
                    <div className="section-buttons" style={{ width: "auto" }}>
                        <Tooltip title="Agregar contacto" placement='left' >
                            <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={() => showModal()} />
                        </Tooltip>
                    </div>
                </Row>
                <Row>
                    <Table dataSource={empresa?.contactos || []} columns={columns} style={{ width: "100%" }} />
                </Row>

                {/* modal para agregar el contacto a la empresa */}
                <Modal
                    title="Agregar contacto"
                    open={visible}
                    footer={null}
                    onCancel={() => closeModal()}
                    destroyOnClose={true} width="400px"
                    className={styles.newContactModal}
                >
                    <div className={styles.modalContainer}>
                        <Tabs items={agregarContactoTabs} type='card' />
                    </div>
                </Modal>
            </Elevation>
        </>
    );
}

export default EmpresaContactos;