import { Button, Row, Typography, message } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import { BiBuildings } from "react-icons/bi";
import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import { TbLicense } from "react-icons/tb";
import { PiSealCheckFill } from "react-icons/pi";
import { useAprobarContacto } from "../../../hooks/api/contactos";
import { useQueryClient } from "react-query";
import KronAlert from "../../../components/CustomAlerts/KronAlert";
import { useEffect, useState } from "react";

const useStyles = createUseStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "0 10px 20px",
    },
    infoSection: {
        display: "flex",
        alignItems: "start",
        gap: 10,
        padding: 10,
        // borderRadius: 5,
        borderBottom: "1px dashed #cccccc",
        "& svg": {
            fontSize: 30,
            margin: 0,
            color: theme.colors.secondary,
        },
        "& .section-title": {
            fontSize: 12,
            margin: 0,
            color: theme.colors.secondary,
            fontWeight: 600,
        },
        "& p": {
            margin: 0,
            color: theme.colors.secondary,
        },
    },
    btn: {
        borderRadius: 30,
        marginTop: 5,
    }
}));

const NuevoContactoResumen = ({ contacto, empresa, licencias, allowApprove }) => {
    const styles = useStyles();
    const theme = useTheme();
    const { approveContacto, isApprovingContacto } = useAprobarContacto();
    const queryClient = useQueryClient();

    const handleClickAprobarRegistro = () => {
        if (allowApprove) {
            approveContacto({ emp_id: empresa?.emp_id, usu_id: contacto?.usu_id }, {
                onSuccess: (res) => {
                    if (res?.success) {
                        message.success("Se aprobó el registro correctamente");
                        // se refresca los datos del contacto
                        queryClient.refetchQueries(["contacto", contacto?.usu_id]);
                        // se refresca los datos de la empresa
                        queryClient.refetchQueries(["empresa"]);
                    } else {
                        message.error(res?.message || "Ocurrió un error al aprobar el registro");
                    }
                },
                onError: (err) => {
                    message.error(err?.message || "Ocurrió un error al aprobar el registro");
                }
            });
        }
    }

    return (
        <div
            className={styles.container}
        >
            <div>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>Resumen</Typography.Title>
            </div>
            <div
                className={styles.infoSection}
            >
                <div>
                    <UserOutlined />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography.Text className="section-title">{contacto?.usu_nombres} {contacto?.usu_apaterno} {contacto?.usu_amaterno}</Typography.Text>
                    <Typography.Text>{contacto?.usu_mail}</Typography.Text>
                </div>
            </div>
            <div
                className={styles.infoSection}
            >
                <div>
                    <BiBuildings />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3
                        }}
                    >
                        <Typography.Text className="section-title">{empresa?.emp_razonsocial}</Typography.Text>
                        {
                            empresa?.emp_aprobada && (
                                <PiSealCheckFill style={{ fontSize: 14 }} />
                            )
                        }
                    </div>
                    <Typography.Text>{empresa?.emp_rfc}</Typography.Text>
                </div>
            </div>
            <div
                className={styles.infoSection}
                style={{
                    borderBottom: "none",
                }}
            >
                <div>
                    <TbLicense />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Row>
                        <Typography.Text className="section-title">Licencias</Typography.Text>
                    </Row>
                    {
                        licencias?.map((licencia) => (
                            <Typography.Text
                                key={`licencia_${licencia?.usli_id}`}
                            >
                                {licencia?.sistema?.sis_nombre}
                            </Typography.Text>
                        ))
                    }
                </div>
            </div>
            {
                empresa?.emp_aprobada && contacto?.usu_aprobado && (
                    <KronAlert
                        text="Proceso de aprobación completado"
                        type="success"
                        style={{ width: "100%" }}
                        rightContent={null}
                        icon={<CheckOutlined />}
                    />
                )
            }
            {
                (!empresa?.emp_aprobada || !contacto?.usu_aprobado) && (
                    <Button
                        className={styles.btn}
                        type="primary"
                        onClick={handleClickAprobarRegistro}
                        disabled={empresa?.emp_aprobada && contacto?.usu_aprobado}
                        icon={<CheckOutlined />}
                        loading={isApprovingContacto}
                    >
                        Aprobar registro
                    </Button>
                )
            }
        </div>
    );
}

export default NuevoContactoResumen;