import { Button, Typography } from "antd";
import { BsClockHistory } from "react-icons/bs";
import { createUseStyles, useTheme } from "react-jss";
import { FcInfo } from "react-icons/fc";
import { TipoNotificacion } from "../../@enums/UsuariosNotificaciones.enums";

const useStyles = createUseStyles({
    notificationCard: {
        flex: 1,
        borderBottom: "1px solid #ddd",
        padding: "15px 20px",
        display: "flex",
        // cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            background: "rgba(200,200,200, 0.15)"
        }
    }
});

/**
 * 
 * @param {Object} props 
 * @param {Number} [props.type] - tipo de notificacion
 * @param {String} [props.className] - string del nombre de la clase que se le va a añadir al contenedor de la notificacion
 * @param {Object[]} [props.buttons] - botones que se le van a agregar a la notificacion
 * @param {Object} [props.notificacion] - Objeto de la notificacion
 * @param {Object} [props.children] - Contenido de la notificacion
 * @param {Function} [props.onClick] - callback que se ejecuta cuando se le da click a la notificacion
 * 
 */
export const NotificationItem = ({ type, className, buttons, notificacion, children, onClick }) => {
    const theme = useTheme();
    const styles = useStyles();

    const getNotificationIco = () => {
        if (!type) {
            return null;
        } else if (type === TipoNotificacion.RECORDATORIO) {
            return <BsClockHistory fontSize={24} color={theme.terciaryColor} />
        } else if (type === TipoNotificacion.INFORMACION) {
            return <FcInfo fontSize={24} color={theme.terciaryColor} />
        }
    }

    const handleClickNotificacion = (e) => {
        if (onClick) onClick(e)
    }

    return (
        <div
            className={`${styles.notificationCard} ${className || ""}`}
            style={{ cursor: !onClick ? "default" : "pointer" }}
            onClick={handleClickNotificacion}
        >
            <div style={{ display: "flex", flex: 0.10 }}>
                {getNotificationIco()}
            </div>
            <div style={{ display: "flex", flex: 0.9, flexDirection: "column" }}>
                <Typography.Title level={5}>{notificacion?.noti_titulo}</Typography.Title>
                <div>{children}</div>
                <div style={{ display: "flex", width: "100%", justifyContent: "end", marginTop: 5, gap: 10 }}>
                    {buttons?.map((btn, iBtn) => <Button type={btn?.type} onClick={btn.onClick} key={`${iBtn}-${btn.text}`} style={btn?.style} >{btn.text}</Button>)}
                </div>
            </div>
        </div>
    )
}
