import { Avatar, Badge, message, Popover, Rate, Space, Spin, Tag } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { BiMessageError } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { TbHandStop } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import { useTimer } from "use-timer";
import Timer from "../../../../components/Timer";
import { calculaTiempoAcuerdoTranscurrido, calculaTiempoTranscurrido } from "../../../../helpers/tickets.helper";
import { useApiTicketsIncidenciaApoyo } from "../../../../hooks/api/tickets_incidencias";
import useApi, { AvailableRequestContentTypes } from "../../../../hooks/api/useApiRequest";
import DataTable from "../../../../components/DataTable";
import { TicketTipoComunicacion } from "../../../../@enums/TicketsIncidencias.enums";
import { TicketStatus } from "../../../../@enums/Tickets.enums";
import { useTheme } from "react-jss";
import { getCreadorTicket, getObservadorTicket, getResponsableActivo } from "../../../../helpers/agentes.helper";
import UsuarioPopoverCard from "../../../Usuarios/UsuarioPopoverCard";
import { EtipoUsuario } from "../../../../@enums/Usuarios.enum";
import ClientesPopoverCard from "../../../Contactos/ClientesPopoverCard";

const TicketsSeguimiento = ({ tickets, isLoadingTickets, refetchTickets, refetchNumTicketsSeguimiento, refetchNumTicketsCaducados }) => {
    const [timerStatusColor, setTimerStatusColor] = useState(null);
    const history = useHistory();
    const api = useApi();

    const caducarTicket = (tick_id) => {
        api.put(`/tickets/${tick_id}/caducar`, {}, { dataType: AvailableRequestContentTypes.JSON }).then((res) => {
            refetchTickets();
            refetchNumTicketsCaducados();
            refetchNumTicketsSeguimiento();
            if (res.data.success) message.info({ content: "Ha caducado un ticket", key: "messageTicketCaducado" });
            else message.error({ content: "Hubo un problema al caducar el ticket", key: "messageTicketCaducado" });
        }).catch((err) => {
            console.log(err)
        });
    }

    const handleTicketExceededTime = (ticket) => {
        if (ticket && ticket?.stat_id !== 9)
            caducarTicket(ticket.tick_id);
    }

    const getAcuerdoActivo = (ticket) => {
        return ticket?.acuerdos_servicio.find(tias => tias.tias_activo)?.acuerdo_servicio;
    }

    const getTiempoTemplate = (ticket) => {
        if (ticket.stat_id === 2) {
            // let { segundosTranscurridos } = calculaTiempoTranscurrido(ticket.incidencias)
            let { segundosTranscurridos,  } = calculaTiempoAcuerdoTranscurrido(ticket.incidencias)
            // se epecifica a cuantos minutos faltantes debe parpadear el timer antes de que se caduque el ticket
            let acuerdoActivo = getAcuerdoActivo(ticket)
            let blinkMinutesTime = acuerdoActivo?.cacu_minutos ? acuerdoActivo?.cacu_minutos - 5 : null;
            let timeLimit = acuerdoActivo?.cacu_minutos ? acuerdoActivo?.cacu_minutos * 60 : Infinity;
            return <Timer
                changeColor
                initialSecondsTime={segundosTranscurridos}
                blinkMinutesTime={blinkMinutesTime}
                timeLimit={timeLimit}
                onTimeLimitExceeded={() => handleTicketExceededTime(ticket)}
            />;
        } else { // si la ultima incidencia encontrada detiene el tiempo del ticket entonces se muestra el status del ticket
            return <div>
                <Tag color="magenta">En espera del cliente</Tag>
            </div>
        }
    };

    const handleSelectedRow = (ticket) => {
        history.push(`/tickets/${ticket.tick_id}`);
    };

    const getActionsButtonTemplate = (ticket) => {
        let initialDate = DateTime.fromISO(ticket.tick_fecharegistro);
        let initialSecondsTime = DateTime.now().toSeconds() - initialDate.toSeconds();
        return <TableNotifications initialSecondsTime={initialSecondsTime} ticket={ticket} />;
    };

    const getRankingTemplate = () => <Rate defaultValue={5} disabled />;

    const renderResponsable = (_, ticket) => {
        let responsable = getResponsableActivo(ticket?.agentes);
        return responsable && (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={responsable?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const renderCreador = (_, ticket) => {
        let creador = getCreadorTicket(ticket?.agentes);
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {
                    creador?.agente?.tiu_id === EtipoUsuario.CLIENTE ?
                        <ClientesPopoverCard con_id={creador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" /> :
                        <UsuarioPopoverCard usu_id={creador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
                }
            </div>
        );
    };

    const renderObservador = (_, ticket) => {
        let observador = getObservadorTicket(ticket?.agentes);
        return !observador ? null : (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={observador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const formatDate = (ticket) => {
        return DateTime.fromISO(ticket.tick_fecharegistro).toFormat("dd/MM/yyyy hh:mm a");
    }

    const columns = [
        { width: 110, render: getActionsButtonTemplate },
        { title: "Ranking", width: 100, render: getRankingTemplate },
        { index: "tick_clave", title: "Clave", width: 150 },
        { title: "Tiempo", width: 135, render: getTiempoTemplate },
        { title: "Empresa", width: 250, render: ticket => ticket?.empresa?.emp_razonsocial },
        { title: "Sistema", width: 200, render: ticket => ticket.licencia?.sistema?.sis_nombre },
        { title: "Fecha", width: 90, render: formatDate },
        { title: "Creador", width: 60, render: renderCreador },
        { title: "Responsable", width: 60, render: renderResponsable },
        { title: "Observadores", width: 60, render: renderObservador },
    ];

    return (
        <Spin tip="Cargando tickets libres ..." spinning={isLoadingTickets}>
            <DataTable
                data={tickets}
                columns={columns}
                onRowClick={handleSelectedRow}
                scroll={{ x: "100%" }}
                showPagination={true}
            />
        </Spin>
    );
};

const TableNotifications = ({ initialSecondsTime, ticket }) => {
    const { time, start } = useTimer({
        initialTime: initialSecondsTime || 0,
    });
    const theme = useTheme();
    const [color, setColor] = useState(null);
    const [handBadgeColor, setHandBadgeColor] = useState(null);
    const [ticketTieneEventoCliente, setTicketTieneEventoCliente] = useState(false);
    const { data: incidenciasApoyo } = useApiTicketsIncidenciaApoyo({ initiaData: [] }, ticket?.tick_id)
    const [popoverParams, setPopoverParams] = useState({
        title: "",
        content: null,
    });

    useEffect(() => {
        start();
    }, []);

    useEffect(() => {
        let incidenciaMensajeCliente = ticket.incidencias.find(
            incidencia =>
                incidencia.stat_id == TicketStatus.TICKET_SEGUIMIENTO &&
                incidencia.tici_comunicacion == TicketTipoComunicacion.NOTA &&
                incidencia.tici_visto == 0 &&
                incidencia.tick_id == ticket.tick_id
        )
        if (incidenciaMensajeCliente) {
            setTicketTieneEventoCliente(true);
            setHandBadgeColor(theme.terciaryColor)
        }
    }, [ticket])

    useEffect(() => {
        if (time <= 60 * 10 && color !== "#00A757") {
            setColor("#00A757");
        } else if (time > 60 * 10 && time < 60 * 20 && color !== "#e0bf00") {
            setColor("#e0bf00");
        } else if (time > 60 * 20 && color !== "#e00000") {
            setColor("#e00000");
        }
    }, [color, time, setColor]);

    useEffect(() => {
        if (incidenciasApoyo?.length > 0) {
            setHandBadgeColor("#fc6731")
            setTicketTieneEventoCliente(true);
            setPopoverParams({
                title: "Solicitando apoyo",
                content: (<div>{incidenciasApoyo[0].tici_observaciones}</div>)
            });
        }
    }, [incidenciasApoyo])

    return (
        <Space size={12} style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
            {/* <Badge dot={true} color="#0f0"> */}
            <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CgFileDocument style={{ width: "20px", height: "20px" }} />
            </div>
            {/* </Badge> */}
            <Popover
                {...popoverParams}
                placement="bottomRight">
                <Badge dot={ticketTieneEventoCliente} color={handBadgeColor} >
                    <div
                        style={{ fontSize: 0, color: ticketTieneEventoCliente ? handBadgeColor : "#999", display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <TbHandStop style={{ width: "20px", height: "20px" }} />
                    </div>
                </Badge>
            </Popover>
            {/* <Badge dot={true}> */}
            <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <BiMessageError style={{ width: "20px", height: "20px", color: color }} />
            </div>
            {/* </Badge> */}
        </Space>
    );
};

export default TicketsSeguimiento;
