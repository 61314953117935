import { Button, Form, Radio, Row, Select, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import CrearEmpresa from "../../Empresas/CrearEmpresa";
import { useAddEmpresaContacto, useApiEmpresa, useApiEmpresas } from "../../../hooks/api/empresas";
import { BiBuildings } from "react-icons/bi";
import { PiSealCheckFill } from "react-icons/pi";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles((theme) => ({
    empresaContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    empresaSeleccionada: {
        padding: 20,
        margin: "15px 20px",
        borderRadius: 10,
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 0px 29px 0px",
        display: "flex",
        "& .ico": {
            background: theme.secondaryColor,
            borderRadius: "50%",
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            marginLeft: -35,
            marginTop: -35,
        },
        "& .field-label": {
            color: theme.secondaryColor,
        }
    }
}));

const NuevoClienteSeleccionEmpresa = ({ onCaptureEmpresa, contactoId, empresaSeleccionada, refetchContacto }) => {
    const styles = useStyles();
    const theme = useTheme();
    const [formEmpresaExistente] = Form.useForm();
    const [tipoSeleccionEmpresa, setTiposeleccionEmpresa] = useState(1);

    const { addEmpresaContacto } = useAddEmpresaContacto();
    const { data: empresas, refetch: refetchEmpresas } = useApiEmpresas({
        initialData: [],
        params: {
            only_contact_not_relationated: true,
            usu_id: contactoId || null
        }
    })

    const handleChangeTipoSeleccionEmpresa = (e) => {
        setTiposeleccionEmpresa(e.target.value);
    }

    const creaRelacionEmpresaContacto = (idEmpresa, esContactoPrincipal) => {
        addEmpresaContacto(
            { usu_id: contactoId, use_contactoprincipal: esContactoPrincipal, emp_id: idEmpresa },
            {
                onSuccess: (res) => {
                    if (!res?.success) {
                        refetchContacto();
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("hubo un problema al relacionar el contacto");
                },
            }
        );
    }

    const handleCreateEmpresa = (empresaId) => {
        onCaptureEmpresa({ emp_id: empresaId });
        setTimeout(() => {
            refetchContacto();
        }, 200);
        // si es que se crea la empresa se agrega la relacion del contacto con la empresa
        creaRelacionEmpresaContacto(empresaId, true);
    }

    const handleClickGuardarRelacionEmpresaExistente = (emp_id) => {

        formEmpresaExistente.validateFields()
            .then((values) => {
                const { emp_id } = values;
                onCaptureEmpresa({ emp_id });
                creaRelacionEmpresaContacto(emp_id, false);
                setTimeout(() => {
                    refetchContacto();
                }, 200);
            })
            .catch((err) => {
                message.error("Selecciona una empresa");
            })
    }

    useEffect(() => {
        if (empresaSeleccionada?.emp_id) {
            onCaptureEmpresa(empresaSeleccionada);
        } else {
            onCaptureEmpresa(null);
        }
    }, [empresaSeleccionada]);



    if (!empresaSeleccionada) {
        return (
            <div className={styles.empresaContainer}>
                <Row style={{ gap: 20, marginTop: 10 }}>
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>Empresa</Typography.Title>
                    <Radio.Group defaultValue={1} onChange={handleChangeTipoSeleccionEmpresa}>
                        <Radio value={1}>Nueva</Radio>
                        <Radio value={2}>Existente</Radio>
                    </Radio.Group>
                </Row>
                {
                    tipoSeleccionEmpresa === 1 && (
                        <div>
                            <CrearEmpresa onCreateEmpresa={handleCreateEmpresa} redirectToEmpresa={false} />
                        </div>
                    )
                }
                {
                    tipoSeleccionEmpresa === 2 && (
                        <div style={{ margin: "7px 0", display: "flex", flexDirection: "column", gap: 20 }}>
                            <Form
                                form={formEmpresaExistente}
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Empresa"
                                    name="emp_id"
                                    rules={[{ required: true, message: "Selecciona una empresa" }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Selecciona una empresa"
                                        optionFilterProp="children"
                                        showSearch={true}
                                        filterOption={(input, option) =>
                                            option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            empresas?.map((emp) => <Select.Option value={emp.emp_id} key={`empresa_${emp.emp_id}`}>{emp.emp_rfc} - {emp.emp_razonsocial}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                            <Button
                                type="primary"
                                onClick={handleClickGuardarRelacionEmpresaExistente}
                            >
                                Relacionar empresa
                            </Button>
                        </div>
                    )
                }
            </div >
        );
    }
    else {
        return (
            <div className={styles.empresaContainer}>
                <Row>
                    <Typography.Title level={5}>Empresa</Typography.Title>
                </Row>
                <div className={styles.empresaSeleccionada}>
                    <div>
                        <div
                            className="ico"
                        >
                            <BiBuildings size={25} />
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px 10px"
                    }}>
                        <Typography.Title level={4} style={{
                            color: theme.secondaryColor,
                            marginBottom: 3,
                            display: "flex",
                            alignItems: "center",
                            gap: 5
                        }}>
                            {empresaSeleccionada?.emp_razonsocial}
                            {
                                empresaSeleccionada?.emp_aprobada && (
                                    <PiSealCheckFill />
                                )
                            }
                        </Typography.Title>
                        <Typography.Text>{empresaSeleccionada?.emp_rfc}</Typography.Text>
                        <Typography.Text style={{ color: "#8c8c8c" }}>{empresaSeleccionada?.emp_codigo}</Typography.Text>
                        {/* <div style={{ marginBottom: 0 }}>Código</div>
                        <div style={{ marginBottom: 0 }}>Empresa</div>
                        <div style={{ marginBottom: 0 }}>RFC</div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default NuevoClienteSeleccionEmpresa;