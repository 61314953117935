import { Badge, Button, DatePicker, Dropdown, Form, Input, Row, Select, Typography } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import Elevation from "../../../../../components/Elevation";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { DATE_FORMATS } from "../../../../../helpers/dates.helper";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        "& .anuncio-datos": {
            // background: "#f00",
            flex: 1,
            height: 200,
            minWidth: 300,
            margin: "0 10px 0 20px",
        },
        "& .anuncio-sectionts": {
            minWidth: 300,
            // background: "#00f",
            flex: 0.5,
            // height: 200,
            padding: "20px 10px",
            margin: "0 10px",
            "& > div": {
                borderRadius: 10,
                width: "100%",
                height: "100%",
                background: "#FFF"
            }
        }
    },
    form: {
        "&  .ant-form-item": {
            margin: 0
        }
    },
    select: {
        width: "100%",
        margin: 0,
        "& .ant-select-selector": {
            margin: 0,
        }
    },
    vistaSeccion: {
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            background: `${theme.secondaryColor} !important`,
            transform: "scale(1.02)"
        },
        "&.active": {
            background: `${theme.secondaryColor} !important`,
            transform: "scale(1.02) !important"
        }
    },
    seccionItemList: {
        width: "100%",
        padding: "5px 15px",
        borderRadius: 20,
        background: "rgba(200,200,200, 0.13)",
        display: "flex",
        justifyContent: "space-between"
    }
}));

const CrearAnuncio = () => {
    const theme = useTheme();
    const styles = useStyles();
    const [form] = Form.useForm();
    const [reglasSeleccionadas, setReglasSeleccionadas] = useState([]);
    const [showedTargetRules, setShowedTargetRules] = useState([]);
    const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);
    const targetRules = [
        {
            key: "rango_fechas",
            label: <div>Rango de fechas</div>
        },
        {
            key: "empresas",
            label: <div>Clientes</div>
        },
        {
            key: "empresas_sectores",
            label: <div>Empresas - sectores</div>
        },
        {
            key: "empresas_giros",
            label: <div>Empresas - giros</div>
        },
        {
            key: "empresas_campos_extra",
            label: <div>Empresas - campos extra</div>
        },
        {
            key: "contactos",
            label: <div>Contactos</div>
        },
        {
            key: "contactos_campos_extra",
            label: <div>Contactos - campos extra</div>
        },
        {
            key: "sistemas",
            label: <div>Sistemas</div>
        },
        {
            key: "sistemas_versiones",
            label: <div>Sistemas - versiones</div>
        },
        {
            key: "sistemas_subclasificacion",
            label: <div>Sistemas - sub clasificaciones</div>
        },
        {
            key: "sistemas_tipo",
            label: <div>Sistemas - tipo</div>
        },
        {
            key: "licencia_vigencia",
            label: <div>Vencimiento de licencia</div>
        },
        {
            key: "puestos",
            label: <div>Puestos</div>
        },
        {
            key: "departamentos",
            label: <div>Departamentos</div>
        },
    ];

    const anunciosVistaSecciones = [
        {
            vise_id: 1,
            style: {
                alignSelf: "center",
                marginTop: 15,
                width: "80%",
                height: 20,
                borderRadius: 5
            }
        },
        {
            vise_id: 2,
            style: {
                alignSelf: "end",
                marginTop: 15,
                width: 40,
                marginRight: 30,
                height: 70,
                borderRadius: 5
            }
        }
    ]

    const agregaRegla = ({ key: regla }) => {
        let r = targetRules.find(rule => rule.key === regla)
        let tReglas = [...reglasSeleccionadas, r];
        setReglasSeleccionadas(tReglas);
        setShowedTargetRules(targetRules.filter(rule => !tReglas.find(rt => rt.key === rule.key)));
    }

    const handleSelectSeccion = (vise_id) => {
        setSeccionSeleccionada(vise_id)
    }

    return (
        <div className={styles.container}>
            <div className="anuncio-datos">
                <Row style={{ marginBottom: 10 }}>
                    <Typography.Title level={3}>Crear anuncio o mensaje</Typography.Title>
                </Row>
                <Row>
                    <Elevation style={{ width: "100%", borderRadius: 10, padding: "20px 15px 35px 15px", }}>
                        <Row>
                            <Typography.Title level={5}>Datos generales</Typography.Title>
                        </Row>
                        <Row>
                            <Form
                                layout="vertical"
                                form={form}
                                style={{ width: "100%" }}
                                className={styles.form}
                            >
                                <Row style={{ width: "100%", gap: 15, marginBottom: 10 }}>
                                    <Form.Item
                                        name="anun_nombre"
                                        label="Nombre o titulo del anuncio"
                                        rules={[{ required: true, message: "El nombre es requerido " }]}
                                        style={{ flex: 1, margin: 0 }}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Fecha de vencimiento"
                                        name="anun_fechavencimiento"
                                        rules={[{ required: true, message: "La fecha de vencimiento es requerida" }]}
                                        style={{ flex: 1, margin: 0 }}
                                    >
                                        <DatePicker style={{ width: "100%" }} format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Tipo de anuncio"
                                        name="anun_tipo"
                                        rules={[{ required: true, message: "El tipo es requerido" }]}
                                        style={{ flex: 1, margin: 0 }}
                                    >
                                        <DatePicker style={{ width: "100%" }} format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} />
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    name="anun_descripcion"
                                    label="Descripción corta"
                                    rules={[{ required: true, message: "La descripción es requerida" }]}
                                    style={{ marginBottom: 10 }}
                                >
                                    <Input.TextArea showCount maxLength={350} autoSize />
                                </Form.Item>
                                <Form.Item label="Detalle del anuncio" name="anun_detalle" >
                                    <Input.TextArea showCount maxLength={Infinity} autoSize />
                                </Form.Item>
                            </Form>
                        </Row>
                    </Elevation>
                </Row>
                <Row style={{ marginTop: 30, justifyContent: "space-between" }}>
                    <Typography.Title level={5}>Targets</Typography.Title>
                    <Dropdown menu={{ items: showedTargetRules?.length > 0 ? showedTargetRules : targetRules, onClick: agregaRegla }} placement="bottomRight">
                        <Button shape="round" type="primary" icon={<PlusOutlined />} >Agregar</Button>
                    </Dropdown>
                </Row>
                <Row style={{ marginTop: 20, gap: 20 }}>
                    {
                        reglasSeleccionadas.map(rule => (
                            <Elevation style={{ width: "100%", borderRadius: 10, padding: "20px 15px 35px 15px", }} key={rule.key}>
                                <Row>
                                    <Typography.Title level={5}>{rule.label}</Typography.Title>
                                </Row>
                                <Row>
                                    <Form
                                        layout="vertical"
                                        form={form}
                                        style={{ width: "100%" }}
                                        className={styles.form}
                                    >
                                        <Row style={{ width: "100%", gap: 15, marginBottom: 10 }}>
                                            <Form.Item
                                                name="anun_nombre"
                                                label="Nombre o titulo del anuncio"
                                                rules={[{ required: true, message: "El nombre es requerido " }]}
                                                style={{ flex: 1, margin: 0 }}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Fecha de vencimiento"
                                                name="anun_fechavencimiento"
                                                rules={[{ required: true, message: "La fecha de vencimiento es requerida" }]}
                                                style={{ flex: 1, margin: 0 }}
                                            >
                                                <DatePicker style={{ width: "100%" }} format="DD/MM/yyyy" />
                                            </Form.Item>
                                        </Row>
                                        <Form.Item
                                            name="anun_descripcion"
                                            label="Descripción corta"
                                            rules={[{ required: true, message: "La descripción es requerida" }]}
                                            style={{ marginBottom: 10 }}
                                        >
                                            <Input.TextArea showCount maxLength={350} autoSize />
                                        </Form.Item>
                                        <Form.Item label="Detalle del anuncio" name="anun_detalle" >
                                            <Input.TextArea showCount maxLength={Infinity} autoSize />
                                        </Form.Item>
                                    </Form>
                                </Row>
                            </Elevation>
                        ))
                    }
                </Row>
            </div>
            <div className="anuncio-sectionts">
                <Elevation style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ height: 28, width: 80, background: "#eee", display: "flex", alignItems: "center", padding: 10, fontWeight: 500 }}>Vistas</div>
                        <div style={{ flex: 1 }}>
                            <Select className={styles.select}>
                                <Select.Option value={1}>Inicio (<span style={{color: theme.terciaryColor}}>/home</span>)</Select.Option>
                                <Select.Option value={2}>Tickets - Tablero (<span style={{color: theme.terciaryColor}}>/tickets</span>)</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: 200, padding: 20, marginTop: 30 }}>
                        <div style={{
                            width: 288,
                            height: 162,
                            background: "#FFF",
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 8px 29px 0px",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <div style={{ width: "100%", height: 15, background: theme.primaryColor }}></div>
                            {
                                anunciosVistaSecciones.map((seccion, iSeccion) => (
                                    <div
                                        style={{ ...seccion.style, background: "#eee", cursor: "pointer" }}
                                        className={`${styles.vistaSeccion} ${(seccion.vise_id === seccionSeleccionada) ? "active" : ""}`}
                                        key={iSeccion}
                                        onClick={() => handleSelectSeccion(seccion.vise_id)}
                                    ></div>
                                ))
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            justifyContent: "start",
                            alignItems: "center",
                            maxHeight: 300,
                            padding: "10px 30px",
                            overflowY: "auto",
                            marginTop: 30,
                            marginBottom: 20
                        }}
                        className="hide-scrollbar"
                    >
                        <div className={styles.seccionItemList}>
                            <Badge color={theme.terciaryColor} text="path/a/la/vista" />
                            <Button size="small" shape="circle" type="primary" icon={<DeleteOutlined />} />
                        </div>
                    </div>
                </Elevation>
            </div>
        </div >
    );
}

export default CrearAnuncio;