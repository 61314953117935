import React, {useState, useEffect} from "react";
import { Row, Col, Form } from "antd";
import TablaCatalogo from "./tablaCatalago/tablaCatalogo";
import NewActividad from "./newActividad/newActividad";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { GetActivitiesCatalogo } from "../../hooks/api/getActivitiesCatalogos";

const ActividadesCatalogo = () => {

    const {getActivitiesCatalogoMutation} = GetActivitiesCatalogo();

    const positions = [
        {value: 1, label: 'Soporte'},
        {value: 2, label: 'Ventas'},
    ]
    

    const [openNew, setOpenNew] = useState(false)
    const [listPositions, setListPositions] = useState(positions)
    const [dataSource, setDataSource] = useState(null)
    const [dataFiltered, setDataFiltered] = useState(dataSource)
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false)


    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        getActivitiesCatalogoMutation({}, {
            onSuccess: (data) => {
                setDataSource(data);
                setDataFiltered(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }
    

    return (
        <div className="container" >
            <PageWrapper title="Actividades">
                <Row style={{padding:'10px 0px'}}>
                    <Col span={24}>
                        <TablaCatalogo  key={1} openNew={openNew} setOpenNew={setOpenNew} dataSource={dataSource} 
                                        dataFiltered={dataFiltered} setDataFiltered={setDataFiltered}
                                        setFileList={setFileList} form={form} setIsEdit={setIsEdit}/>
                    </Col>
                </Row>
            </PageWrapper>
            <NewActividad   key={2} openNew={openNew} setOpenNew={setOpenNew} 
                            form={form} updateTable={getData}
                            fileList={fileList} setFileList={setFileList}
                            isEdit={isEdit} setIsEdit={setIsEdit}/>
        </div>
    )
}

export default ActividadesCatalogo;