import React, { useEffect, useState } from "react";
import { CheckOutlined, PoweroffOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Row, Space, Tooltip, Typography, Switch, message, Select, Modal, Popover } from "antd";
import { createUseStyles } from "react-jss";
import { AiFillWarning } from "react-icons/ai";
import { RiFileList3Line } from "react-icons/ri";
import { TbHandStop } from "react-icons/tb";
import { useLiberarTicket, useTicketUpdateMutation, useTomarTicketMutation } from "../../../hooks/api/tickets";
import { useHistory } from "react-router-dom";
import { useApiTicketsIncidenciaApoyo } from "../../../hooks/api/tickets_incidencias";
import { useSelector } from "react-redux";
import { useModal } from "../../../hooks/useModal";
import { useApiUsuarios } from "../../../hooks/api/usuarios";
import CalificaAsesores from "./CalificaAsesores";
import PedirApoyo from "./PedirApoyo";
import { TicketPrioridades, TicketStatus } from "../../../@enums/Tickets.enums";
import CambiaTicketAcuerdoServicio from "./CambiaTicketAcuerdoServicio";
import { TipoTicketAgente } from "../../../@enums/TicketsAgentes.enums";

const useStyles = createUseStyles(theme => ({
    problematicaWrapper: {
        width: "100%",
        borderBottom: "1px solid #eee",
        padding: "10px 10px 30px",
        "& .problematica-header": {
            justifyContent: "space-between",
            alignItems: "center",
            "& h5": {
                margin: 0,
            },
        },
        "& .ant-space .ant-space-item": {
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    text: {
        width: "100%",
        marginTop: 5,
        height: "120px",
        overflowY: "auto",
    },
    btnDisabled: {
        background: "#fff",
    },
    userCardCalification: {
        minWidth: "100%",
        width: "100%",
        border: `1px solid #eee`,
        transition: "all 0.2s ease-in-out",
        userSelect: "none",
        "& .user-card-inner": {
            padding: "20px 10px",
            display: "flex",
            gap: 10,
            // flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            height: "100%"
        },
    },
    modalCalificarCompañeros: {
        "& .ant-modal-body": {
            padding: 0
        },
        "& .modal-overflow-container": {
            padding: 20,
            maxHeight: 700,
            overflowY: "auto",
        }
    },
    modalPedirApoyo: {
        "& .ant-modal-body": {
            padding: 0
        },
    },
    listaCamposExtra: {
        margin: "-8px -12px",
        flexDirection: "column",
        display: "flex",
        "& .campo-extra": {
            display: "flex",
            alignItems: "start",
            borderBottom: "1px solid #eee",
            padding: 12,
            "& .campo-extra-label": {
                color: theme.secondaryColor,
                marginRight: 5,
                fontWeight: 500
            },
            "& .campo-extra-ico": {
                width: 20
            }
        }
    }
}));




const Observaciones = ({ ticket }) => {
    const styles = useStyles();
    const [isEditingPrioridad, setIsEditingPrioridad] = useState(false);
    const [prioridad, setPrioridad] = useState(ticket?.tick_prioridad);
    const [cambioAcuerdoVisible, showCambioAcuerdo, closeCambioAcuerdo] = useModal();
    const { mutateTicketUpdate, isLoadingTicketUpdateMutation } = useTicketUpdateMutation();
    const { mutateTomarTicket, isLoadingTomarTicketMutation } = useTomarTicketMutation();
    const { liberarTicket, isLoadingLiberarTicket } = useLiberarTicket();
    const [color, setColor] = useState("red");
    const [btnCamposExtraColor, setBtnCamposExtraColor] = useState("#bbb");
    const [responsable, setResponsable] = useState(null);
    const history = useHistory();
    const user = useSelector(state => state.auth.user);
    const [show, showModal, closeModal] = useModal()
    const [showCalificarCompaneros, showModalCalificarCompaneros, closeModalCalificarCompaneros] = useModal()
    const [acuerdoServicioActivo, setAcuerdoServicioActivo] = useState(null);
    const [btnPedirApoyoColor, setBtnPedirApoyoColor] = useState("#aaa")
    const { data: incidenciasApoyo, isLoading: isLoadingIncidenciasApoyo, refetch: refetchIncidenciasApoyo } = useApiTicketsIncidenciaApoyo({ initiaData: [] }, ticket?.tick_id)
    const { data: apiUsuarios } = useApiUsuarios({ initialData: [] });

    const handleClickTomarTicket = () => {
        if (ticket?.stat_id === TicketStatus.TICKET_LIBRE) {
            mutateTomarTicket(ticket?.tick_id, {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success("Tomaste el ticket");
                        setColor("#00A757");
                        history.push(`/tickets/${ticket.tick_id}`);
                    } else {
                        message.error("Hubo un problema al tomar el ticket");
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al tomar el ticket");
                },
            });
        } else {

            // se valida si el usuario que esta realizando la accion es el responsable activo
            if (!responsable) {
                message.error("Acción no permitida");
                return;
            }

            liberarTicket(ticket?.tick_id, {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success(res.mensaje);
                        history.push(`/tickets`);
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("Hubo un error al liberar el ticket")
                }
            })
        }
    };

    const handleClickShowPedirApoyo = () => {
        showModal();
    };

    const handleClickGuardarPrioridad = () => {
        mutateTicketUpdate(
            { tick_id: ticket?.tick_id, tick_prioridad: prioridad },
            {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success("Prioridad actualizada");
                        setIsEditingPrioridad(false)
                    } else {
                        message.error("Hubo un problema al actualizar la prioridad");
                        setIsEditingPrioridad(false)
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al actualizar la prioridad");
                    setIsEditingPrioridad(false)
                },
            }
        );
    }

    const handleDoubleClickPrioridad = () => {
        if (!ticket?.tick_bloqueado || responsable && responsable?.usu_id === user?.usu_id)
            setIsEditingPrioridad(true)
        else
            message.error("El ticket está bloqueado")
    }

    const handleChangePrioridad = (value) => {
        setPrioridad(value)
    }

    const handleClickCambiarAcuerdo = () => {
        if (!ticket?.tick_bloqueado || responsable && responsable?.usu_id === user?.usu_id)
            showCambioAcuerdo();
        else
            message.error("El ticket está bloqueado")
    }

    const handleChangeBloqueado = (checked) => {
        mutateTicketUpdate(
            { tick_id: ticket?.tick_id, tick_bloqueado: checked },
            {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success(checked ? "Ticket bloqueado" : "Ticket Desbloqueado");
                    } else {
                        message.error("Hubo un problema al bloquear el ticket");
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al bloquear el ticket");
                },
            }
        );
    };

    const handleClickShowModalCalificarCompañeros = () => {
        closeModal();
        showModalCalificarCompaneros();
    }

    const renderCamposExtra = () => {
        const ordenaCamposExtra = (a, b) => {
            if (a.came_importante && !b.came_importante)
                return -1
            else if (b.came_importante && !a.came_importante)
                return 1
            else return 0
        }

        return ticket?.empresa?.campos_extras?.
            sort(ordenaCamposExtra)?.
            map(campo_extra => <div className="campo-extra" key={`campo-extra-${campo_extra.came_id}`}>
                <div className="campo-extra-ico" >
                    {campo_extra.came_importante &&
                        <StarOutlined style={{ marginRight: 5, color: "gold" }} />
                    }
                </div>
                <div className="campo-extra-label">{campo_extra.came_nombre}:</div>
                <div>{campo_extra.came_valor}</div>
            </div>)
    }

    useEffect(() => {

        // se busca el acuerdo de servicio activo
        if (ticket?.acuerdos_servicio) {
            let acuerdoActivo = ticket.acuerdos_servicio.find(acu => acu.tias_activo)
            setAcuerdoServicioActivo(acuerdoActivo || null);
        }

        if (ticket?.stat_id > 1) {
            setColor("#00A757");
        } else {
            setColor("red");
        }

        if (ticket?.empresa?.campos_extras?.length > 0) {
            for (let campoExtra of ticket.empresa.campos_extras)
                if (campoExtra.came_importante) {
                    setBtnCamposExtraColor("gold");
                    break;
                } else if (btnCamposExtraColor !== "#bbb" && !campoExtra.came_importante) {
                    setBtnCamposExtraColor("#bbb");
                }
        } else {
            setBtnCamposExtraColor("#bbb");
        }

        if (ticket?.tick_prioridad) setPrioridad(ticket.tick_prioridad);

        if (ticket?.agentes) {
            let tResponsable = ticket?.agentes?.find(tAgente =>
                tAgente.usu_id === user.usu_id
                && tAgente.tiag_id === TipoTicketAgente.RESPONSABLE
                && tAgente.tica_usumodificaticket)
            setResponsable(tResponsable?.agente);
        }

    }, [ticket, btnCamposExtraColor, user.usu_id]);

    useEffect(() => {
        if (incidenciasApoyo?.length > 0) {
            setBtnPedirApoyoColor("#fc6731");
        } else {
            setBtnPedirApoyoColor("#aaa");
        }
    }, [incidenciasApoyo])

    return (
        <div className={styles.problematicaWrapper}>
            <Row style={{ marginBottom: 10 }}>
                {
                    !isEditingPrioridad &&
                    <Space style={{ width: "100%" }}>
                        <div style={{ display: "flex", gap: 10, justifyContent: "start", width: "100%" }}>
                            <Tooltip title="Prioridad" placement="bottom" >
                                <div onDoubleClick={handleDoubleClickPrioridad} style={{ cursor: "pointer" }}>
                                    <Typography.Text style={{ color: "#1890ff", padding: "3px 10px", border: "1px dashed #eee" }} strong>
                                        {ticket?.tick_prioridad === 1 ? "ALTA" : ticket?.tick_prioridad === 2 ? "MEDIA" : "BAJA"}
                                    </Typography.Text>
                                </div>
                            </Tooltip>
                            <Tooltip title="Acuerdo de servicio" placement="bottom">
                                <div onClick={handleClickCambiarAcuerdo} style={{ cursor: "pointer" }}>
                                    <Typography.Text style={{ color: "#1890ff", padding: "3px 10px", border: "1px dashed #eee" }} strong>
                                        {acuerdoServicioActivo?.acuerdo_servicio?.cacu_nombre}
                                    </Typography.Text>
                                </div>
                            </Tooltip>
                        </div>
                        <div style={{ display: "flex", gap: 20 }}>
                            <Popover
                                overlayInnerStyle={{ maxHeight: 300, overflowY: "auto" }}
                                content={<Row className={styles.listaCamposExtra}>
                                    {renderCamposExtra()}
                                </Row>}
                                title="Campos extra"
                                trigger="click"
                                placement="left"
                            >
                                <Tooltip title="Campos extra" placement="bottomRight">
                                    <Button
                                        type="dashed"
                                        shape="circle"
                                        style={{ borderColor: btnCamposExtraColor }}
                                        icon={<AiFillWarning style={{ color: btnCamposExtraColor }} />}
                                    />
                                </Tooltip>
                            </Popover>
                            <Tooltip title="Pólizas" placement="bottomRight">
                                <Button type="dashed" shape="circle" icon={<RiFileList3Line style={{ color: "#bbb" }} />} />
                            </Tooltip>
                            <Tooltip title={color === "#00A757" ? "Liberar ticket" : "Tomar ticket"} placement="bottomRight">
                                <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<PoweroffOutlined style={{ color }} />}
                                    onClick={handleClickTomarTicket}
                                    style={{ backgroundColor: "#fff", borderColor: color }}
                                    loading={isLoadingTomarTicketMutation || isLoadingLiberarTicket}
                                />
                            </Tooltip>
                            <Tooltip title="Pedir apoyo" placement="bottomRight">
                                <Button
                                    loading={isLoadingIncidenciasApoyo}
                                    type="dashed"
                                    shape="circle"
                                    icon={<TbHandStop style={{ color: btnPedirApoyoColor }} />}
                                    style={{ borderColor: btnPedirApoyoColor }}
                                    onClick={handleClickShowPedirApoyo}
                                    disabled={responsable?.usu_id !== user.usu_id || ticket?.stat_id === 1}
                                />
                            </Tooltip>
                        </div>
                    </Space>
                }

                {
                    isEditingPrioridad && (
                        <div style={{ display: "flex", width: "100%", alignItems: "center", gap: 16 }} >
                            <Select style={{ flex: 1 }} onChange={handleChangePrioridad} value={prioridad}>
                                {
                                    Object.keys(TicketPrioridades).map(prioridad => <Select.Option value={TicketPrioridades[prioridad]} key={`prioridad_${prioridad}`}>{prioridad}</Select.Option>)

                                }
                            </Select>
                            <Button size="small" onClick={handleClickGuardarPrioridad} shape="circle" icon={<CheckOutlined />} type="primary" />
                        </div>
                    )
                }
            </Row >
            <Row className="problematica-header">
                <Typography.Title level={5}>Obervaciones</Typography.Title>
            </Row>
            <Row>
                <div dangerouslySetInnerHTML={{ __html: ticket?.empresa?.emp_observaciones }} className={styles.text} />
            </Row>
            <Row style={{ justifyContent: "end", alignItems: "center" }}>
                <Switch
                    size="small"
                    onChange={handleChangeBloqueado}
                    loading={isLoadingTicketUpdateMutation}
                    disabled={responsable?.usu_id !== user.usu_id || ticket?.stat_id === 1}
                    checked={ticket?.tick_bloqueado}
                />
                <Typography.Text style={{ marginLeft: 5, marginRight: 10 }}>Bloqueado</Typography.Text>
            </Row>

            <Modal width={800} title="Pedir apoyo" open={show} onCancel={closeModal} footer={null} className={styles.modalPedirApoyo}>
                <PedirApoyo
                    incidenciasApoyo={incidenciasApoyo}
                    handleClickShowModalCalificarCompañeros={handleClickShowModalCalificarCompañeros}
                    ticket={ticket}
                    closeModal={closeModal}
                    refetchIncidenciasApoyo={refetchIncidenciasApoyo}
                    apiUsuarios={apiUsuarios}
                />
            </Modal>

            <Modal
                className={styles.modalCalificarCompañeros}
                width={700}
                title="Calificar compañeros"
                open={showCalificarCompaneros}
                onCancel={closeModalCalificarCompaneros}
                footer={null}
            >
                
                <div className="modal-overflow-container">
                    <CalificaAsesores clear={showCalificarCompaneros} ticket={ticket} closeModal={closeModalCalificarCompaneros} incidenciasApoyo={incidenciasApoyo} />
                </div>
            </Modal>

            <Modal
                className={styles.modalCalificarCompañeros}
                title="Cambiar acuerdo de servicio"
                open={cambioAcuerdoVisible}
                onCancel={closeCambioAcuerdo}
                footer={null}
                destroyOnClose
            >
                <div className="modal-overflow-container">
                    <CambiaTicketAcuerdoServicio ticket={ticket} closeParent={closeCambioAcuerdo} />
                </div>
            </Modal>
        </div >
    );
};

export default Observaciones;
