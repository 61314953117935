import React, { useEffect, useState } from "react";
import { Row, Form, Space, Divider, Select, Affix, Button, Input, Tooltip, message, Spin, Alert } from "antd";
import ReactResizeDetector from "react-resize-detector";
import { createUseStyles } from "react-jss";
import styles from "./styles";
import { useApiContactos } from "../../../hooks/api/contactos";
import { useApiAcuerdosServicio } from "../../../hooks/api/acuerdos_servicio";
import { useApiAreasDepartamentos } from "../../../hooks/api/areas_departamentos";
import { AiOutlineArrowDown } from "react-icons/ai";
import { setScrollToBottom, setScrollToTop, scrollToSelector } from "../../../helpers/scroll";
import { objectToFormData } from "../../../helpers/form.helper";
import { useTicketMutation } from "../../../hooks/api/tickets";
import { useApiSistemasVersiones } from "../../../hooks/api/sistemas_versiones";
import { useApiEmpresas, useApiEmpresasLicencias } from "../../../hooks/api/empresas";
import { TipoAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";
import FileManager from "../../Recursos/FileManager";
import useApi from "../../../hooks/api/useApiRequest";
import { useQueryClient } from "react-query";
import { TicketTipoProceso } from "../../../@enums/Tickets.enums";

const useStyles = createUseStyles(styles);

const initialValues = {
    acuerdoservicio: null,
    comunicacion: null,
    contacto: null,
    empresa: null,
    licencia: null,
    mesaservicio: null,
    minutosp: null,
    observaciones: null,
    poliza: null,
    prioridad: null,
    problematica: null,
    version: null,
    tipoproceso : TicketTipoProceso.SIN_PROCESO
}

const CrearTicket = ({ closeModal }) => {
    const styles = useStyles();
    const api = useApi();
    const [scrollBottom, setScrollBottom] = useState(false);
    const [container, setContainer] = useState(null);
    const [files, setFiles] = useState([]);
    const [form] = Form.useForm();
    const [contactosEmpresa, setContactosEmpresa] = useState([]);
    const [empresasContacto, setEmpresasContacto] = useState([]);
    const queryClient = useQueryClient();
    const [alert, setAlert] = useState({
        message: null,
        type: "warning",
    });
    const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null); // if the selected company
    const [spinParams, setSpinParams] = useState({
        tip: "",
        spinning: false,
    });
    const { mutateTicket } = useTicketMutation();
    const { isLoading: isLoadingContactos, data: contactos, refetch: refetchContactos } = useApiContactos({
        initialData: [],
        refetchOnWindowFocus: false,
    });
    const { data: empresas, refetch: refetchEmpresas, isLoading: isLoadingEmpresas } = useApiEmpresas({
        options: {
            initialData: [],
        },
        params: {}
    });
    const { data: licenciasEmpresa, refetch: refetchLicenciasEmpresa, isLoading: isLoadingLicenciasEmpresas } = useApiEmpresasLicencias(
        { initialData: [], id: empresaSeleccionada }
    );
    const { isLoading: isLoadingVersiones, data: versiones, refetch: refetchVersiones } = useApiSistemasVersiones(
        {
            refetchOnWindowFocus: false,
        },
        licenciasEmpresa?.find((lic) => lic.usli_id === form?.getFieldValue("licencia"))
    );
    const { isLoading: isLoadingAcuerdosServicio, data: acuerdosServicio } = useApiAcuerdosServicio({
        refetchOnWindowFocus: false,
    });
    const { isLoading: isLoadingAreasDepartamentos, data: areasDepartamentos } = useApiAreasDepartamentos({ refetchOnWindowFocus: false });

    const getApiEmpresasContacto = async (usu_id) => {
        try {
            return (await api.get(`/contactos/${usu_id}/empresas`))?.data || [];
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    const getApiContactosEmpresas = async (emp_id) => {
        try {
            return (await api.get(`/contactos?emp_id=${emp_id}&rwc=1`))?.data || [];
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    const handleChangeContacto = async () => {
        setContactosEmpresa([]);
        // get the company of the contact
        let empresas = await getApiEmpresasContacto(form.getFieldValue("contacto"));
        setEmpresasContacto(empresas);
        //find the main company of the contact
        let empresaPrincipal = empresas.find(empresa => empresa.usuarios_empresas.find(use => use.use_principal))
        // validate if the contact has a main company
        if (empresaPrincipal) {
            // set the main company of the contact
            form.setFieldsValue({
                empresa: empresaPrincipal.emp_id,
                licencia: null,
                version: null
            })
            setEmpresaSeleccionada(empresaPrincipal.emp_id);
        } else {
            form.setFieldsValue({
                empresa: null,
                licencia: null,
                version: null
            })
            setEmpresaSeleccionada(null);
        }
    };

    const handleChangeEmpresa = async (emp_id) => {
        form.setFieldsValue({
            licencia: null,
            version: null
        })
        setEmpresaSeleccionada(emp_id);
        setTimeout(() => {
            refetchLicenciasEmpresa();
        }, 200);
        // se consulta los contactos de la empresa
        let contactos = await getApiContactosEmpresas(emp_id);
        setContactosEmpresa(contactos);
    }

    const validaVersionErrores = (csiv_id) => {
        if (csiv_id && versiones) {
            let version = versiones?.find(ver => ver?.csiv_id === csiv_id);
            if (version && version?.csiv_errores) {
                setAlert({
                    ...alert,
                    message: "La versión tiene errores"
                })
            } else setAlert({
                ...alert,
                message: null
            })
        } else {
            setAlert({
                ...alert,
                message: null
            })
        }
    }

    const handleChangeSistema = (usli_id) => {
        if (licenciasEmpresa && licenciasEmpresa?.length > 0) {
            // se busca la licencia que se ha seleccionado
            let licenciaSeleccionada = licenciasEmpresa.find(lic => lic.usli_id === usli_id);
            // si la licencia tiene registrada una version se selecciona
            if (licenciaSeleccionada && licenciaSeleccionada?.csiv_id) {
                form.setFieldValue("version", licenciaSeleccionada.csiv_id);
            } else {
                form.setFieldValue("version", null);
            }
        }
        refetchVersiones();
    };

    const handleChangeVersion = (csiv_id) => {
        validaVersionErrores(csiv_id)
    }

    const handleChangeAcuerdos = (val) => {
        let acuerdo = acuerdosServicio.find((cacu) => cacu.cacu_id === val);
        if (acuerdo) {
            form.setFieldsValue({
                minutosp: acuerdo.cacu_minutos,
            });
        } else {
            form.setFieldsValue({
                minutosp: null,
            });
        }
    };

    const handleClickMoveScroll = () => {
        if (scrollBottom) {
            setScrollToTop("#container");
        } else {
            setScrollToBottom("#container");
        }
    };

    const handleClickCrearTicket = () => {
        setSpinParams({
            tip: "Creando ticket ...",
            spinning: true,
        });
        form.validateFields()
            .then((values) => {
                values.tipoproceso = TicketTipoProceso.SIN_PROCESO;
                let fd = objectToFormData(values);
                let tFiles = [...files];
                for (let file of tFiles) {
                    fd.append("files[]", file);
                }
                mutateTicket(fd, {

                    onSuccess: res => {
                        setSpinParams({
                            tip: "",
                            spinning: false,
                        });
                        message.success(`Ticket ${res.data.tick_clave} creado`, 5);
                        queryClient.invalidateQueries("tickets");
                        closeModal();
                    },
                    onError: err => {
                        console.log(err);
                        if (err?.response?.status && err?.response?.status === 413) {
                            message.error("Límite de tamaño de solicitud excedido");
                        } else if (err?.response?.status && err?.response?.status === 400) {
                            message.error(err.response.data.mensaje);
                        } else {
                            message.error("Ocurrió un problema al crear el ticket");
                        }
                        setSpinParams({
                            tip: "",
                            spinning: false,
                        });
                    },
                });
            })
            .catch((err) => {
                if (err?.errorFields) {
                    scrollToSelector(`#${err.errorFields[0]?.name[0]}`);
                    message.error("Aún faltan campos requeridos");
                } else {
                    message.error("Ocurrió un problema al crear el ticket");
                }
                setSpinParams({
                    tip: "",
                    spinning: false,
                });
            });
    };

    const handleChangeFilesProblematica = (fileList) => {
        setFiles(fileList);
    };

    const onClearEmpresa = () => {
        setEmpresaSeleccionada(null);
    }

    const onClearEmpresaContacto = () => {
        setContactosEmpresa([]);
        if (form.getFieldValue("contacto") !== null)
            setEmpresasContacto([]);
    }

    useEffect(() => {
        // initial values
        form.setFieldsValue({
            ...initialValues,
            poliza: 0,
            comunicacion: 1,
            prioridad: 3,
        });
        refetchContactos();
        validaVersionErrores(null);
    }, []);

    useEffect(() => {
        if (versiones) {
            let csiv_id = form.getFieldValue("version");
            if (csiv_id) {
                validaVersionErrores(csiv_id);
            }
        }
    }, [form, versiones])

    useEffect(() => {
        document.querySelector("#container").addEventListener(
            "scroll",
            (e) => {
                if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
                    setScrollBottom(true);
                } else {
                    if (scrollBottom) setScrollBottom(false);
                }
            },
            { passive: true }
        );
    }, [container, scrollBottom]);

    useEffect(() => {
        refetchLicenciasEmpresa();
    }, [empresaSeleccionada]);

    useEffect(() => {
        if (empresas && empresas.length > 0) {
            let empresaPrincipal = empresas?.find(empresa => empresa.usuarios_empresas?.find(use => use.use_principal))
            // se valida si el contacto tiene una empresa principal
            if (empresaPrincipal) {
                //se setea la empresa principal
                setEmpresaSeleccionada(empresaPrincipal.emp_id);
                form.setFieldValue("empresa", empresaPrincipal.emp_id)
                setTimeout(() => {
                    refetchLicenciasEmpresa();
                }, 500);
            }
        }
    }, [empresas, form]);

    return (
        <Spin {...spinParams}>
            <ReactResizeDetector>
                {({ width }) => (
                    <div key="crear-ticket" id="container" style={{ maxHeight: 700, overflowY: "auto", padding: "10px 20px" }} ref={setContainer}>
                        {
                            alert?.message &&
                            <Alert message={alert.message} type="warning" showIcon className="fade-down" />
                        }
                        <Divider orientation="left">Datos generales del ticket</Divider>
                        <Row>
                            <Form layout="vertical" style={{ width: "100%" }} form={form}>
                                <Space size={20} className={styles.newTicketFormSpace}>
                                    <Form.Item name="contacto" label="Contacto" rules={[{ required: true, message: "Contacto requerido!" }]}>
                                        <Select
                                            showSearch
                                            allowClear
                                            loading={isLoadingContactos}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            onClear={onClearEmpresaContacto}
                                            onChange={handleChangeContacto}
                                        >
                                            {!isLoadingContactos &&
                                                (contactosEmpresa?.length ? contactosEmpresa : contactos)?.map((contacto) => (
                                                    <Select.Option
                                                        key={`contacto-${contacto.usu_id}`}
                                                        value={contacto.usu_id}
                                                    >{`${contacto.usu_nombres} ${contacto.usu_apaterno} ${contacto.usu_amaterno}`}</Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="empresa" label="Empresa" rules={[{ required: true, message: "Empresa requerida" }]}>
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            loading={isLoadingEmpresas}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            onChange={handleChangeEmpresa}
                                            onClear={onClearEmpresa}
                                        >
                                            {!isLoadingEmpresas &&
                                                (empresasContacto.length ? empresasContacto : empresas)?.map((empresa) => (
                                                    <Select.Option key={`empresa-${empresa.emp_id}`} value={empresa.emp_id}>
                                                        {empresa.emp_razonsocial}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Space>
                                <Space size={20} className={styles.newTicketFormSpace}>
                                    <Form.Item name="licencia" label="Sistema" rules={[{ required: true, message: "Sistema requerido!" }]}>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            onChange={handleChangeSistema}
                                            loading={isLoadingLicenciasEmpresas}
                                        >
                                            {!isLoadingLicenciasEmpresas &&
                                                (licenciasEmpresa || []).map((lic) => (
                                                    <Select.Option key={`sis-${lic.usli_id}`} value={lic.usli_id}>
                                                        {lic.sistema.sis_nombre}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="version" label="Versión">
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            loading={isLoadingVersiones}
                                            onChange={handleChangeVersion}
                                        >
                                            {!isLoadingVersiones &&
                                                (versiones || []).map((ver) => (
                                                    <Select.Option key={`sis-ver-${ver.csiv_id}`} value={ver.csiv_id}>
                                                        {ver.csiv_version}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="poliza" label="Póliza">
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Select.Option value={0}>No</Select.Option>
                                            <Select.Option value={1}>Si</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="comunicacion"
                                        label="Comunicación"
                                        rules={[{ required: true, message: "Comunicación requerida!" }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Select.Option value={1}>Correo Entrante</Select.Option>
                                            <Select.Option value={2}>Correo Saliente</Select.Option>
                                            <Select.Option value={3}>Llamada Entrante</Select.Option>
                                            <Select.Option value={4}>Llamada Saliente</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Space>
                                <Row>
                                    <Divider orientation="left">Datos extra</Divider>
                                    <Space size={20} style={{ marginTop: 20 }} className={styles.newTicketFormSpace}>
                                        <Form.Item
                                            name="mesaservicio"
                                            label="Mesa de servicio"
                                            rules={[{ required: true, message: "Mesa de servicio requerida!" }]}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                loading={isLoadingAreasDepartamentos}
                                            >
                                                {!isLoadingAreasDepartamentos &&
                                                    (areasDepartamentos || []).filter(ade => ade.ade_tipo === TipoAreaDepartamento.DEPARTAMENTO).map((ade) => (
                                                        <Select.Option key={`areasdepartamentos-${ade.ade_id}`} value={ade.ade_id}>
                                                            {ade.ade_nombre}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="acuerdoservicio" label="Acuerdo de servicio" rules={[{ required: true, message: "Prioridad requerido!" }]}>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                onChange={handleChangeAcuerdos}
                                                loading={isLoadingAcuerdosServicio}
                                            >
                                                {!isLoadingAcuerdosServicio &&
                                                    (acuerdosServicio || []).map((acu) => (
                                                        <Select.Option key={`acuerdosservicio-${acu.cacu_id}`} value={acu.cacu_id}>
                                                            {acu.cacu_nombre}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="minutosp" label="Minutos presupuestados">
                                            <Input readOnly />
                                        </Form.Item>
                                        <Form.Item name="prioridad" label="Prioridad" rules={[{ required: true, message: "Prioridad requerido!" }]}>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                <Select.Option value={1}>Alta</Select.Option>
                                                <Select.Option value={2}>Media</Select.Option>
                                                <Select.Option value={3}>Baja</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Space>
                                    <Space size={20} style={{ marginTop: 20 }} className={styles.newTicketFormSpace}></Space>
                                </Row>
                                <Row>
                                    <Divider orientation="left">Problemática y Observaciones</Divider>
                                    <Space size={20} className={styles.newTicketFormSpace}>
                                        <Form.Item name="sintomas" label="Problemática" rules={[{ required: true, message: "La problemática es requerida" }]}>
                                            <Input.TextArea showCount autoSize />
                                        </Form.Item>
                                        <Form.Item name="observarciones" label="Observaciones" >
                                            <Input.TextArea showCount autoSize />
                                        </Form.Item>
                                        {/* <div className={styles.textEditorWrapper}>
                                            <div id="problematica" className="rich-textbox-title">
                                                <span style={{ color: "red" }}>*</span> Problemática
                                            </div>
                                            <RichTextEditor
                                                minHeight="150px"
                                                enableUploadFiles={true}
                                                onEditorChange={handleChangeProblematica}
                                                onFilesChange={handleChangeFilesProblematica}
                                                allowedExtensions={["xlsx", "xls", "doc", "docx", "pdf", "jpeg", "jpg", "png",]}
                                            />
                                        </div> */}
                                        {/* <div className={styles.textEditorWrapper}>
                                            <div className="rich-textbox-title">Observaciones</div>
                                            <RichTextEditor
                                                minHeight="150px"
                                                height="100%"
                                                onEditorChange={handleChangeObservaciones}
                                                allowedExtensions={["xlsx", "xls", "doc", "docx", "pdf", "jpeg", "jpg", "png"]}
                                            />
                                        </div> */}
                                    </Space>
                                </Row>
                            </Form>
                        </Row>
                        <Row>
                            <FileManager
                                onChangeFiles={handleChangeFilesProblematica}
                                allowedExtensions={["xlsx", "xls", "doc", "docx", "pdf", "jpeg", "jpg", "png",]}
                                limit={5}
                                maxFileMBSize={10}
                            />
                        </Row>
                        <Row>
                            <Affix offsetBottom={0} target={() => container} style={{ width }}>
                                <div style={{ width: "100%" }}>
                                    <Row style={{ justifyContent: "space-between" }} className={styles.modalFooter}>
                                        <Tooltip placement="right" title={scrollBottom ? "Ir arriba" : "Ir abajo"}>
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                style={{
                                                    backgroundColor: "#1890ff",
                                                    fontSize: 0,
                                                    border: "none",
                                                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px !important",
                                                    transition: "all 0.15s ease-in-out",
                                                    transform: scrollBottom ? "rotate(180deg)" : "rotate(0deg)",
                                                }}
                                                icon={<AiOutlineArrowDown />}
                                                onClick={handleClickMoveScroll}
                                            />
                                        </Tooltip>
                                        <Space>
                                            <Button type="secondary" onClick={closeModal}>
                                                Cancelar
                                            </Button>
                                            <Button type="primary" onClick={handleClickCrearTicket}>
                                                Crear Ticket
                                            </Button>
                                        </Space>
                                    </Row>
                                </div>
                            </Affix>
                        </Row>
                    </div>
                )}
            </ReactResizeDetector>
        </Spin>
    );
};

export default CrearTicket;
