import { Avatar, Badge, Rate, Space, Spin } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { BiMessageError } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { TbHandStop } from "react-icons/tb";
import { useTimer } from "use-timer";
import DataTable from "../../../../components/DataTable";
import { getCreadorTicket, getObservadorTicket, getResponsableActivo } from "../../../../helpers/agentes.helper";
import UsuarioPopoverCard from "../../../Usuarios/UsuarioPopoverCard";
import ClientesPopoverCard from "../../../Contactos/ClientesPopoverCard";
import { EtipoUsuario } from "../../../../@enums/Usuarios.enum";

const TicketsLibres = ({ setUsandoTicket, setShowDatosTickets, tickets, isLoadingTickets }) => {
    const [lastSelectedTicket, setLastSelectedTicket] = useState(null);

    const formatDate = (ticket) => {
        return DateTime.fromISO(ticket.tick_fecharegistro).toFormat("dd/MM/yyyy hh:mm a");
    }

    const handleSelectedRow = (ticket) => {
        setUsandoTicket(ticket);
        setLastSelectedTicket(ticket)
        setShowDatosTickets(true);
    };

    const getActionsButtonTemplate = (ticket) => {
        try {
            let initialDate = DateTime.fromISO(ticket.tick_fecharegistro);
            let initialSecondsTime = DateTime.now().toSeconds() - initialDate.toSeconds();
            return <TableNotifications initialSecondsTime={initialSecondsTime} />;
        } catch (error) {
            return null;
        }
    };
    const getRankingTemplate = () => <Rate defaultValue={5} disabled />;

    const renderResponsable = (_, ticket) => {
        let responsable = getResponsableActivo(ticket?.agentes);
        return responsable && (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={responsable?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const renderCreador = (_, ticket) => {
        let creador = getCreadorTicket(ticket?.agentes);
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {
                    creador?.agente?.tiu_id === EtipoUsuario.CLIENTE || creador?.tiu_id === EtipoUsuario.CLIENTE ?
                        <ClientesPopoverCard con_id={creador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" /> :
                        <UsuarioPopoverCard usu_id={creador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
                }
            </div>
        );
    };

    const renderObservador = (_, ticket) => {
        let observador = getObservadorTicket(ticket?.agentes);
        return !observador ? null : (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={observador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const columns = [
        {
            title: "Acciones",
            width: 80,
            render: getActionsButtonTemplate,
        },
        {
            index: "tick_clave",
            width: 110,
            title: "Clave",
        },
        {
            title: "Ranking",
            width: 100,
            render: getRankingTemplate
        },
        {
            title: "Empresa",
            width: 250,
            render: (ticket) => ticket?.empresa?.emp_razonsocial !== null ? ticket?.empresa?.emp_razonsocial : '' 
        },
        {
            title: "Sistema",
            width: 200,
            render: (ticket) => ticket.licencia?.sistema?.sis_nombre
        },
        {
            title: "Fecha",
            width: 90,
            render: formatDate
        },
        {
            title: "Creador",
            width: 50,
            render: renderCreador
        },
        {
            title: "Responsable",
            width: 50,
            render: renderResponsable
        },
        {
            title: "Observadores",
            width: 50,
            render: renderObservador
        },
        // {
        //     title: "Celula",
        //     render: formatAreaDepartamento
        // },
        // {
        //     title: "ID",
        //     visible: false,
        //     field: "tick_id",
        // },
        // {
        //     visible: false,
        //     index: "creador.tick_nombre",
        //     title: "Creador",
        //     render: formatCreador
        // },
        // {
        //     visible: false,
        //     index: "tick_sintomas",
        //     title: "Problemática",
        //     textAlign: "Left",
        //     render: formatHtmlToText
        // },
        // {
        //     visible: false,
        //     index: "tick_prioridad",
        //     title: "Prioridad",
        //     textAlign: "Left",
        //     render: formatPrioridad
        // },
        // {
        //     "visible": false,
        //     index: "tick_poliza",
        //     title: "Poliza",
        //     width: "100",
        //     textAlign: "Left",
        //     render: formatPoliza
        // },
        // {
        //     "visible": false,
        //     field: "tick_observaciones",
        //     title: "Observaciones",
        //     width: "200",
        //     textAlign: "Left",
        //     render: formatHtmlToText
        // }
    ];

    useEffect(() => {
        if (lastSelectedTicket) {
            let ticket = tickets?.find(tick => tick.tick_id === lastSelectedTicket.tick_id)
            if (ticket)
                setUsandoTicket(ticket);
        }
    }, [tickets, lastSelectedTicket, setUsandoTicket]);

    return (
        <Spin tip="Cargando tickets libres ..." spinning={isLoadingTickets}>
            <DataTable
                data={tickets || []}
                columns={columns}
                onRowClick={handleSelectedRow}
                scroll={{
                    x: "100%"
                }}
                showPagination={true}
            />
        </Spin >
    );
};

const TableNotifications = ({ initialSecondsTime }) => {
    const { time, start } = useTimer({
        initialTime: initialSecondsTime || 0,
    });
    const [color, setColor] = useState(null);

    useEffect(() => {
        start();
    }, []);


    useEffect(() => {
        if (time <= 60 * 10 && color !== "#00A757") {
            setColor("#00A757");
        } else if (time > 60 * 10 && time < 60 * 20 && color !== "#e0bf00") {
            setColor("#e0bf00");
        } else if (time > 60 * 20 && color !== "#e00000") {
            setColor("#e00000");
        }
    }, [color, time, setColor]);

    return (
        <Space size={12} style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
            {/* <Badge dot={true} color="#0f0"> */}
            <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CgFileDocument style={{ width: "20px", height: "20px" }} />
            </div>
            {/* </Badge> */}
            {/* <Popover content={"nexesito ayuda con comercial"} title="Title"> */}
            <Badge dot={true} color="#00f">
                <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TbHandStop style={{ width: "20px", height: "20px" }} />
                </div>
            </Badge>
            {/* </Popover> */}
            {/* <Badge dot={true}> */}
            <div style={{ fontSize: 0, color: "#329EFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <BiMessageError style={{ width: "20px", height: "20px", color: color }} />
            </div>
            {/* </Badge> */}
        </Space>
    );
};

export default TicketsLibres;
