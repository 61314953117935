import { SistemaClasificacion, SistemaSubClasificacion, SistemaTipoLicenciamiento } from "../../@enums/Sistemas.enum"


export class SistemaDto {
  /**
 * @property {Number} sis_id - id del sistema
 * @type {Number}
 */
  sis_id = null
  /**
   * @property {String} sis_nombre - nombre del sistema
   * @type {String}
   */
  sis_nombre = null
  /**
    * @property {String} [sis_descripcion] - descripcion del sistema (length: 255)
    * @type {String}
    */
  sis_descripcion = null
  /**
    * @property {String} [sis_observaciones] - observaciones del sistema (length: 255)
    * @type {String}
    */
  sis_observaciones = null
  /**
    * @property {String} [sis_consideraciones] - consideraciones del sistema (length: 255)
    * @type {String}
    */
  sis_consideraciones = null
  /**
    * @property {SistemaClasificacion} sis_clasificacion - clasificacion del sistema
    * @type {SistemaClasificacion}
    */
  sis_clasificacion = null
  /**
    * @property {SistemaSubClasificacion} sis_subclasificacion - subclasificacion del sistema
    * @type {SistemaSubClasificacion}
    */
  sis_subclasificacion = null
  /**
    * @property {SistemaTipoLicenciamiento} [sis_tipo] - tipo de licenciamiento del sistema
    * @type {SistemaTipoLicenciamiento}
    */
  sis_tipo = null
  /**
    * @property {Number} [sis_precio] - precio del sistema
    * @type {Number}
    */
  sis_precio = 0
  /**
    * @property {Number} [sis_costo] - costo del sistema
    * @type {Number}
    */
  sis_costo = 0
  /**
    * @property {Number} [sis_precioadicional] - precio adicional del sistema
    * @type {Number}
    */
  sis_precioadicional = 0
  /**
    * @property {Number} [sis_costoadicional] - costo adicional del sistema
    * @type {Number}
    */
  sis_costoadicional = 0
  /**
    * @property {String} [sis_serie] - serie que maneja el sistema , campo para funcionalidad de tickets
    * @type {String}
    */
  sis_serie = null
  /**
    * @property {String} sis_fecha - fecha en la que fue creado el sistema
    * @type {String}
    */
  sis_fecha = null
  /**
    * @property {Array<Object>} [recursos] - arreglo de recursos o archivos del sistema
    * @type {Array<Object>}
    */
  recursos = []
}