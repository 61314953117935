import { createUseStyles } from "react-jss";
import { PuestoDto } from "../../../@types/Puestos.types";
import { Button, Checkbox, Divider, Form, Input, Modal, Row, Select, Table, Tooltip, Typography, message } from "antd";
import { DeleteOutlined, DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useApiAreasDepartamentos } from "../../../hooks/api/areas_departamentos";
import { TipoAreaDepartamento, getKeyTipoAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";
import { useDeleteAreaDepartamentoPuesto, usePuestosMutation } from "../../../hooks/api/puestos";
import { useModal } from "../../../hooks/useModal";
import EliminarPuesto from "../EliminarPuesto";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles({
    container: {
        width: "100%"
    }
})

/**
 * 
 * @param {Object} props 
 * @param {PuestoDto} props.puesto - objeto con los datos del puesto
 * @param {Function} [props.closeVerPuesto] - funcion para cerrar el componente de ver puesto
 */
const VerPuesto = ({ puesto, closeVerPuesto }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { data: areasDepartamentos } = useApiAreasDepartamentos();
    const [areas, setAreas] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const { mutatePuesto } = usePuestosMutation();

    const [eliminarPuestoVisible, showEliminarPuesto, closeEliminarPuesto] = useModal();

    const editarPuesto = () => {
        form.validateFields().then(values => {
            mutatePuesto(
                { puesto: { pues_id: puesto.pues_id, ...values }, mutationType: "update" },
                {
                    onSuccess: ({ success, mensaje }) => {
                        if (success) {
                            message.success(mensaje);
                            closeVerPuesto()
                        } else {
                            message.error(mensaje);
                            closeVerPuesto()
                        }
                    },
                    onError: (err) => {
                        console.log(err);
                        message.error("Hubo un problema al editar el puesto");
                        closeVerPuesto()
                    },
                }
            );
        }).catch(() => {
            message.error("Faltan campos requeridos");
        })
    }

    const handleChangeDepartamento = (ade_id) => {
        // se busca el departamento para saber si  tiene un area relacionada
        let depto = departamentos.find(ade => ade.ade_id === ade_id);
        if (depto && depto?.ade_padreid) {
            form.setFieldValue("area", depto?.ade_padreid);
        } else {
            form.setFieldValue("area", null);
        }
    }

    useEffect(() => {
        if (areasDepartamentos) {
            // se separan las areas y los departamentos
            const { areas, departamentos } = areasDepartamentos.reduce((values, ade) => {
                if (ade.ade_tipo === TipoAreaDepartamento.AREA) {
                    values.areas.push(ade);
                } else if (ade.ade_tipo === TipoAreaDepartamento.DEPARTAMENTO) {
                    values.departamentos.push(ade);
                } else {
                    values.otro.push(ade);
                }
                return values
            }, { areas: [], departamentos: [], otro: [] });
            setAreas(areas)
            setDepartamentos(departamentos)
        }
    }, [areasDepartamentos, puesto]);

    useEffect(() => {
        if (puesto) {
            //se busca el area
            let area = puesto?.areas_departamentos.find(ade => ade.ade_tipo === TipoAreaDepartamento.AREA)
            //se busca el departamento
            let depto = puesto?.areas_departamentos.find(ade => ade.ade_tipo === TipoAreaDepartamento.DEPARTAMENTO)
            form.setFieldsValue({
                pues_nombre: puesto.pues_nombre,
                pues_clave: puesto.pues_clave,
                pues_descripcion: puesto.pues_descripcion,
                pues_objetivo: puesto.pues_objetivo,
                departamento: depto?.ade_id || null,
                area: area?.ade_id || null
            })
        }
    }, [puesto]);

    const onDeletePuesto = () => {
        closeVerPuesto();
    }

    return (
        <>
            <Modal
                title="Ver Puesto"
                open={eliminarPuestoVisible}
                onCancel={() => closeEliminarPuesto()}
                footer={null}
                width={600}
                centered
                destroyOnClose
            >
                <EliminarPuesto pues_id={puesto?.pues_id} closeEliminarPuesto={closeEliminarPuesto} onDeletePuesto={onDeletePuesto} />
            </Modal>

            <div className={styles.container}>
                <Row style={{ justifyContent: "space-between", alignItems: "center", margin: 0 }}>
                    <Typography.Title level={4}></Typography.Title>
                    <Tooltip placement="left" title="Eliminar puesto">
                        <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => { showEliminarPuesto() }} />
                    </Tooltip>
                </Row>
                <Divider style={{ margin: "10px 0" }} />
                <Row>
                    <div style={{ width: "100%" }}>
                        <Row>
                            <Typography.Title level={5}>Datos del puesto</Typography.Title>
                        </Row>
                        <Row>
                            <Form form={form} layout="vertical" style={{ width: "100%" }}>
                                <Row style={{ gap: 10 }}>
                                    <Form.Item style={{ flex: 1 }} name="pues_nombre" label="Nombre" rules={[{ required: true, message: "El nombre es requerido." }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="pues_clave" label="Clave" >
                                        <Input />
                                    </Form.Item>
                                </Row>
                                <Form.Item name="pues_descripcion" label="Descripción" >
                                    <Input.TextArea showCount maxLength={255} autoSize />
                                </Form.Item>
                                <Form.Item name="pues_objetivo" label="Objetivo">
                                    <Input.TextArea showCount maxLength={255} autoSize />
                                </Form.Item>
                                <Row style={{ gap: 10 }}>
                                    <Form.Item name="departamento" label="Departamento" style={{ flex: 1 }} rules={[{ required: true, message: "El departamento es requerido" }]}>
                                        <Select
                                            notFoundContent="No hay departamentos para mostrar"
                                            onChange={handleChangeDepartamento}
                                        >
                                            {
                                                departamentos.map((departamento, index) => (
                                                    <Select.Option key={index} value={departamento.ade_id}>{departamento.ade_nombre}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="area" label="Área" style={{ flex: 1 }}>
                                        <Select
                                            notFoundContent="No hay áreas para mostrar"
                                            disabled={true}
                                        >
                                            {
                                                areas.map((area, index) => (
                                                    <Select.Option key={index} value={area.ade_id}>{area.ade_nombre}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Row>
                    </div>
                </Row>
                <Row style={{ justifyContent: "end", marginTop: 15 }}>
                    <Button type="primary" onClick={editarPuesto}>Guardar</Button>
                </Row>
            </div>
        </>
    );
}

export default VerPuesto;