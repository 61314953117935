import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
    urlItem: {
        border: "1px solid #eee",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        padding: "5px 5px",
        "&:hover": {
            transform: "scale(1.02)",
            border: `1px solid ${theme.secondaryColor}`,
        }
    },
    urlData: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        gap: 10,
    }
}));

/**
 * @typedef {Object} UrlItemProps
 * @property {Object} recurso - recurso a mostrar
 * @property {Boolean} [allowDeleteFile]  - permite eliminar el recurso
 * @property {Function} [onDeleteUrl] - funcion para eliminar el recurso
 
 */

/**
 * 
 * @param {UrlItemProps} props 
 * @returns 
 */
const UrlItem = ({
    recurso,
    allowDeleteFile = true,
    onDeleteUrl
}) => {
    const styles = useStyles();

    const handleClickEliminarRecurso = (e) => {
        e.preventDefault();
        if (typeof recurso?.recu_id === "number" && onDeleteUrl) onDeleteUrl(recurso);
    }

    return (
        <a href={recurso.recu_ruta} target="_blank" rel="noreferrer" className={styles.urlItem}>

            <div className={styles.urlData} style={{ border: "none" }}>
                <div style={{ flex: 1, display: "flex", gap: 10, alignItems: "center" }}>
                    <LinkOutlined style={{ fontSize: 20 }} />
                    <Typography.Text style={{ margin: 0 }}>{recurso?.recu_nombre || recurso?.recu_ruta}</Typography.Text>
                </div>
                <div style={{ textAlign: "right", minHeight: 10, padding: "3px 10px" }}>
                    {
                        allowDeleteFile &&
                        <Button size={"small"} shape="circle" type="primary" icon={<CloseOutlined />} style={{ marginTop: 5 }} onClick={handleClickEliminarRecurso} />
                    }
                </div>
            </div>

        </a>
    );
}

export default UrlItem;