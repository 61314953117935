import { Avatar, Spin, Tag } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../../../components/DataTable";
import UsuarioPopoverCard from "../../../Usuarios/UsuarioPopoverCard";
import ClientesPopoverCard from "../../../Contactos/ClientesPopoverCard";
import { getContactoTicket, getCreadorTicket, getObservadorTicket, getResponsableActivo } from "../../../../helpers/agentes.helper";
import { EtipoUsuario } from "../../../../@enums/Usuarios.enum";

const Tablero = ({ setUsandoTicket, setShowDatosTickets, tickets, isLoadingTickets, page, pageSize, changePageSize, changePage, totalTickets }) => {
    const history = useHistory();
    const [lastSelectedTicket, setLastSelectedTicket] = useState(null);

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat("dd/MM/yyyy hh:mm a");
    };

    const formatHtmlToText = (column, ticket) => {
        let subStr = ticket[column === "tick_sintomas" ? "tick_sintomas" : "tick_observaciones"]
            .replace(/<\/?[^>]+(>|$)/g, "")
            .substring(0, 100);
        return `${subStr}`;
    };

    const handleSelectedRow = (ticket) => {
        if (ticket.stat_id === 1) {
            setUsandoTicket(ticket);
            setLastSelectedTicket(ticket)
            setShowDatosTickets(true);
        } else {
            history.push(`/tickets/${ticket.tick_id}`);
        }
    };

    const renderResponsable = (_, ticket) => {
        let responsable = getResponsableActivo(ticket?.agentes);
        return responsable && (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={responsable?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };


    const renderContacto = (_, ticket) => {
        let contacto = getContactoTicket(ticket?.agentes);
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {
                    contacto?.agente?.tiu_id === EtipoUsuario.CLIENTE ?
                        <ClientesPopoverCard con_id={contacto?.usu_id} avatarSize={26} popoverPlacement="bottomRight" /> :
                        <UsuarioPopoverCard usu_id={contacto?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
                }
            </div>
        );
    };

    const renderObservador = (_, ticket) => {
        let observador = getObservadorTicket(ticket?.agentes);
        return !observador ? null : (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <UsuarioPopoverCard usu_id={observador?.usu_id} avatarSize={26} popoverPlacement="bottomRight" />
            </div>
        );
    };

    const typeTemplate = (_, ticket) => {
        let params = {
            color: null,
            text: null
        }
        if (ticket.stat_id === 1) {
            params = {
                color: "#00a757",
                text: "Libre"
            }
        } else if (ticket.stat_id === 2) {
            params = {
                color: "#329eff",
                text: "En Seguimiento"
            }
        } else if (ticket.stat_id === 3) {
            params = {
                color: "#c41d7f",
                text: "En Espera"
            }
        } else if (ticket.stat_id === 7) {
            params = {
                color: "#fc6731",
                text: "Fabricante"
            }
        } else if (ticket.stat_id === 9) {
            params = {
                color: "#ff0000",
                text: "Caducado"
            }
        }

        if (ticket.status.stat_ticketcerrado) {
            params = {
                color: "#737373",
                text: ticket.status.stat_nombre
            }
        }
        return <Tag color={params.color} style={{ color: params.color, background: `${params.color}22`, border: `1px solid ${params.color}44`, textAlign: "center" }}>
            {params.text}
        </Tag>
    }

    const fechaCierreTicketTemplate = (ticket) => {
        let incidenciaCierre = ticket.incidencias.find(incidencia => incidencia?.status?.stat_ticketcerrado)
        if (incidenciaCierre) {
            return DateTime.fromISO(incidenciaCierre.tici_fecha).toFormat("dd/MM/yyyy hh:mm a");
        } else {
            return "";
        }
    }

    const columns = [
        { index: "tick_clave", title: "Clave", width: 180 },
        { index: "stat_id", title: "Status", width: 120, render: typeTemplate },
        { title: "Empresa", width: 250, render: (ticket) => ticket?.empresa?.emp_razonsocial !== null ? ticket?.empresa?.emp_razonsocial : '' },
        { title: "Sistema", width: 200, render: (ticket) => ticket.licencia?.sistema?.sis_nombre },
        { title: "Problemática", width: 300, render: (_,ticket) =>  formatHtmlToText("tick_sintomas", ticket) },
        { index: "tick_fecharegistro", title: "Fecha", width: 170, render: formatDate },
        { title: "Fecha Cierre", width: 140, render: fechaCierreTicketTemplate },
        { title: "Contacto", width: 60, render: renderContacto },
        { title: "Responsable", width: 60, render: renderResponsable },
        { title: "Observadores", width: 60, render: renderObservador },
    ];

    useEffect(() => {
        if (lastSelectedTicket) {
            let ticket = tickets?.find(tick => tick.tick_id === lastSelectedTicket.tick_id)
            if (ticket)
                setUsandoTicket(ticket);
        }
    }, [tickets]);

    return (
        <Spin tip="Cargando tickets ..." spinning={isLoadingTickets}>
            <DataTable
                columns={columns}
                data={tickets.sort((t1, t2) => t2.tick_id - t1.tick_id)}
                onRowClick={handleSelectedRow}
                showPagination={false}
                changePage={changePage}
                changePageSize={changePageSize}
                page={page}
                pageSize={pageSize}
                totalTickets={totalTickets}
            />
        </Spin>
    );
};

export default Tablero;
