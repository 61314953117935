// socketSlice.js
const initialState = {
    socket: null,
  };
  
  // Acciones
  const SET_SOCKET = 'socket/SET_SOCKET';
  
  // Acción creadora
  export const setSocket = (socket) => ({
    type: SET_SOCKET,
    payload: socket,
  });
  
  // Reductor
  const socketReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SOCKET:
        return {
          ...state,
          socket: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default socketReducer;