import React, { useEffect, useState } from 'react';
import Chats from '../../../components/Chats';
import { useApiConversaciones } from '../../../hooks/api/conversaciones';
import { useApiIncidenciasApoyo } from '../../../hooks/api/tickets_incidencias';


let mockChats = [
    {
        url: "chat url",
        chatId: "123456",
        extraData: {
            ticket: {
                tick_clave: "123456",
                agentes: [
                    {
                        tiag_id: 2,
                        agente: {
                            usu_nombres: "francisco manuel"
                        },
                    }
                ]
            }
        },
    },
    {
        url: "chat url",
        chatId: "abc",
        extraData: {
            ticket: {
                tick_clave: "abc",
                agentes: [
                    {
                        tiag_id: 2,
                        agente: {
                            usu_nombres: "francisco manuel"
                        },
                    }
                ]
            }
        },
    },
]

const TicketsChats = () => {
    const { data: conversacionesTickets, isLoading: isLoadingconversacionesTickets } = useApiConversaciones({
        initialData: [],
    },
    {query: {conv_tipo: 2, conv_activa: true}});
    const [chats, setChats] = useState([]);

    const getMessage = (incidencia) => {
        switch (incidencia.tipi_id) {
            case 4: return {
                text: "Alguien esta solicitando ayuda",
                type: "info"
            }
            default: return { text: null, type: null }
        }
    }

    useEffect(() => {
        setChats(conversacionesTickets.map(conversacion => ({
            namespace: "/",
            url: "chat url",
            chatId: conversacion.conv_id,
            chatTitle: "Ticket",
            chatSubTitle: conversacion?.ticket?.tick_clave,
            message: getMessage(conversacion),
            extraData: {
                ticket: conversacion.ticket
            },
        })))
    }, [conversacionesTickets])

    return chats.length > 0 ? (<div>
        <Chats
            chats={chats}
            listChatTitle={"Seguimiento de tickets"}
        // renderChatListItem={}
        // renderChatConversations={}
        />
    </div>) : null;
}

export default TicketsChats;