import { Avatar, Button, Checkbox, Input, List, Radio, Row, Spin, Typography, message } from "antd";
import { useApiUsuarios } from "../../../hooks/api/usuarios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { IMAGES } from "../../../../images";
import { NewTicketAgenteDto } from "../../../@types/TicketsAgentes.types";
import { TipoTicketAgente } from "../../../@enums/TicketsAgentes.enums";
import { useAgregarTicketAgente } from "../../../hooks/api/tickets";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles(theme => ({
    userList: {
        width: "100%",
        border: "1px solid #eee",
        borderRadius: 10
    },
    userListItem: {
        cursor: "pointer",
        padding: 10,
        transition: "all 0.2s ease-in-out",
        "&:first-child": {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
        },
        "&:last-child": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10
        },
        "&:hover": {
            // backgroundColor: `${theme.terciaryColor}22`
            backgroundColor: `${theme.terciaryColor}22`
        },
        "& .ant-list-item-meta": {
            alignItems: "center"
        },
        "& .ant-list-item-meta-title": {
            margin: 0
        }
    },
    observador: {
        padding: "15px 0",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 5
    }
}))

/**
 * 
 * @param {Object} props 
 * @param {Object} props.ticket - objeto del ticket para poder manejar los observadores 
 * @param {Function} [props.closeAgregarObservador] - funcion para poder cerrar el contenedor donde se esta utilizando el componente 
 */
const AgregarObservador = ({ ticket, closeAgregarObservador }) => {
    const styles = useStyles();
    const { data: apiUsuarios } = useApiUsuarios({ initialData: [] });
    const user = useSelector(state => state.auth.user);
    const [usuarios, setUsuarios] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [observador, setObservador] = useState(null);
    const { agregarTicketAgente, isLoadingAgregarTicketAgente } = useAgregarTicketAgente();
    const queryClient = useQueryClient();

    const handleClickAgregarObservador = () => {
        // se valida si ya se ha seleccionado un observador
        if (selectedUser) {
            // se agrega el agente como observador
            let agente = new NewTicketAgenteDto();
            agente.tiag_id = TipoTicketAgente.OBSERVADOR;
            agente.tick_id = ticket?.tick_id;
            agente.usu_id = selectedUser?.usu_id;
            // se manda la solicitud para agregar el agente 
            agregarTicketAgente(agente, {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje);
                        // se invalidan los datos en react query para el ticket para volver a ser consultado
                        queryClient.invalidateQueries(["tickets", `${ticket.tick_id}`]);
                        if (closeAgregarObservador) closeAgregarObservador();
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("Hubo un error al agregar al observador")
                }
            })
        } else {
            message.error("No se ha seleccionado ningun usuario");
        }
    }

    const handleSelectUsuario = (usuario) => {
        setSelectedUser(usuario)
    }

    const handleChangeSearchUsuario = (e) => {
        setUsuarios(
            apiUsuarios.filter(
                usr => usr.usu_id !== user.usu_id && `${usr.usu_nombres} ${usr.usu_apaterno} ${usr.usu_amaterno}`.includes(e.target.value.toLowerCase())
            )
        )
    }

    const renderListUsuarioItem = (usuario) => {
        return <List.Item className={styles.userListItem} onClick={() => handleSelectUsuario(usuario)}>
            <div style={{ marginRight: 10 }}>
                <Radio checked={usuario.usu_id === selectedUser?.usu_id} />
            </div>
            <List.Item.Meta
                avatar={<Avatar src={IMAGES.GENERIC_USER} />}
                title={`${usuario.usu_nombres} ${usuario.usu_apaterno} ${usuario.usu_amaterno}`}
            />
        </List.Item>
    }

    useEffect(() => {
        if (apiUsuarios?.length > 0) {
            setUsuarios(apiUsuarios.filter(usr => usr.usu_id !== user.usu_id))
        }
    }, [selectedUser, apiUsuarios])

    useEffect(() => {
        if (ticket) {
            // se busca si es que el ticket ya tiene un agente observador
            let observador = ticket?.agentes.find(agente => agente.tiag_id === TipoTicketAgente.OBSERVADOR);
            if (observador) setObservador(observador);
        }
    }, [ticket]);

    useEffect(() => {
        setSelectedUser(null);
    }, []);

    return observador ? (
        <div className={styles.observador} >
            <div>
                <Avatar src={IMAGES.GENERIC_USER} size={100} style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }} />
            </div>
            <div>
                <Typography.Title level={4} style={{ margin: 0 }}>
                    {`${observador.agente.usu_nombres} ${observador.agente.usu_apaterno} ${observador.agente.usu_amaterno}`}
                </Typography.Title>
            </div>
            <div>
                <Typography.Title level={5} style={{ color: "#b8b8b8" }}>
                    Actualmente como observador
                </Typography.Title>
            </div>
        </div>
    ) : (
        <Spin spinning={isLoadingAgregarTicketAgente}>
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                <Row>
                    <Input.Search placeholder="Buscar usuario" onChange={handleChangeSearchUsuario} />
                </Row>
                <Row>
                    <List
                        itemLayout="horizontal"
                        dataSource={usuarios}
                        renderItem={renderListUsuarioItem}
                        className={styles.userList}
                    />
                </Row>
                <Row style={{ justifyContent: "end", gap: 10 }}>
                    <Button type="secondary" onClick={closeAgregarObservador} >Cancelar</Button>
                    <Button type="primary" onClick={handleClickAgregarObservador}>Guardar</Button>
                </Row>
            </div>
        </Spin>
    );
}

export default AgregarObservador;