import { createUseStyles, useTheme } from "react-jss";
import { knowBaseAdministrationStyles } from "../../Administracion/KnowBase/styles";
import Elevation from "../../../components/Elevation";
import {
	Button,
	Divider,
	Form,
	Input,
	Rate,
	Row,
	Typography,
	message,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useUpdateKnowBaseItem } from "../../../hooks/api/knowBase";

const useStyles = createUseStyles(knowBaseAdministrationStyles);

/**
 * @typedef {Object} UpdateKnowledgeBaseItemProps
 * @property {Object} data - Data of the item to update
 * @property {Function} onCancel - Callback to cancel the update
 * @property {Function} onSave - Callback to save the update
 */

/**
 *
 * @param {UpdateKnowledgeBaseItemProps} props
 */
const UpdateKnowledgeBaseItem = ({ data: knowBaseItem, onCancel, onSave }) => {
	const theme = useTheme();
	const styles = useStyles();
	const [form] = Form.useForm();
	const { updateKnowBaseItem, isUpdatingKnowBaseItem } =
		useUpdateKnowBaseItem();

	const handleClickActualizar = () => {
		form.validateFields().then((values) => {
			let updatingKnowBaseItem = {
				kbit_id: knowBaseItem.kbit_id,
				...values,
			};
			updateKnowBaseItem(updatingKnowBaseItem, {
				onSuccess: (res) => {
					if (res.success) {
						message.success("Registro actualizado");
						onSave && onSave(updatingKnowBaseItem);
					} else {
						message.error(res.mensaje);
					}
				},
				onError: () => {
					message.error("No se pudo actualizar el registro");
				},
			});
		});
	};

	useEffect(() => {
		form.setFieldsValue({
			kbit_titulo: knowBaseItem.kbit_titulo,
			kbit_sintomas: knowBaseItem.kbit_sintomas,
			kbit_problematica: knowBaseItem.kbit_problematica,
			kbit_solucion: knowBaseItem.kbit_solucion,
		});
	}, [knowBaseItem]);

	return (
		<>
			<Row style={{ marginBottom: 10, justifyContent: "end" }}>
				<Button
					type="primary"
					shape="circle"
					style={{ marginRight: 10 }}
					icon={<CheckOutlined />}
					onClick={handleClickActualizar}
					loading={isUpdatingKnowBaseItem}
				/>
				<Button
					shape="circle"
					style={{ marginRight: 10 }}
					icon={<CloseOutlined />}
					onClick={onCancel}
				/>
			</Row>
			<Row>
				<Form form={form} layout="vertical" style={{ flex: 1 }}>
					<Elevation className={`${styles.itemContainer}`}>
						<Row
							style={{
								alignItems: "center",
								gap: 15,
								marginBottom: 15,
								justifyContent: "space-between",
							}}
						>
							<Form.Item
								name="kbit_titulo"
								label="Titulo"
								style={{ flex: 1 }}
								rules={[
									{
										required: true,
										message: "El titulo es requerido",
									},
								]}
							>
								<Input placeholder="Titulo" />
							</Form.Item>
						</Row>
						<Row
							style={{
								alignItems: "center",
								gap: 20,
								marginBottom: 5,
								padding: "10px 0",
								flexWrap: "wrap",
							}}
						>
							<Form.Item
								name="kbit_sintomas"
								label="Síntomas"
								style={{ flex: 1 }}
								rules={[
									{
										required: true,
										message: "Los síntomas son requeridos",
									},
								]}
							>
								<Input.TextArea autoSize />
							</Form.Item>
							<Form.Item
								name="kbit_problematica"
								label="Problemática"
								style={{ flex: 1 }}
								rules={[
									{
										required: true,
										message: "La problemática es requerida",
									},
								]}
							>
								<Input.TextArea autoSize />
							</Form.Item>
						</Row>
						<Divider dashed style={{ margin: 0 }} />
						<Row
							style={{
								alignItems: "start",
								justifyContent: "center",
								gap: 5,
								padding: "10px 0",
							}}
						>
							<Form.Item
								name="kbit_solucion"
								label="Solución"
								style={{ flex: 1 }}
								rules={[
									{
										required: true,
										message: "La solución es requerida",
									},
								]}
							>
								<Input.TextArea autoSize />
							</Form.Item>
						</Row>
						<Divider dashed style={{ margin: 0 }} />
						<Row className={styles.itemCategories}>
							{/* <Typography.Text style={{ color: "#aaa" }}>
            Categorías
          </Typography.Text> */}
							{/* <div style={{ padding: "10px 0" }}>
                    {
                        categories.map((category, index) => (
                            <Tag
                                color={category.color}
                                key={index}
                                className={styles.category}
                            >
                                {category.label}
                            </Tag>
                        ))  
                    }
                </div> */}
						</Row>
					</Elevation>
				</Form>
			</Row>
		</>
	);
};

export default UpdateKnowledgeBaseItem;
