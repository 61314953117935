import { Button, Col, Divider, Rate, Row, Tag, Typography, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { CgDanger } from "react-icons/cg";
import { MdDateRange, MdReportProblem } from "react-icons/md";
import { VscVerifiedFilled } from "react-icons/vsc";
import { createUseStyles, useTheme } from "react-jss";
import { getRandomTagColor } from "../../../helpers/colors/Tags.colors";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useKnowBaseItem } from "../../../hooks/api/knowBase";
import { BiBrain } from "react-icons/bi";
import KnowledgeBaseItem from "./KnowledgeBaseItem";
import { isEditable } from "@testing-library/user-event/dist/utils";
import UpdateKnowledgeBaseItem from "./UpdateKnowledgeBaseItem";
import { useQueryClient } from "react-query";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

const useStyles = createUseStyles((theme) => ({
	container: {
		padding: 20,
		minHeight: "calc(100vh - 140px)",
		gap: 10,
		paddingBottom:0,
	},
	itemContainer: {
		borderRadius: 8,
		padding: 20,
	},
	containerTittle: {
		backgroundColor: "red",
		justifyContent: "center",
		alignItems: "center",
	},

	itemCategories: {
		// marginLeft: 20,
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		gap: 10,
		padding: "10px 0",
	},
	category: {
		borderRadius: 15,
		padding: "1px 8px",
	},
}));

const KnowledgeBaseItemPage = ({id_modal}) => {
	const styles = useStyles();
	const theme = useTheme();
	const { id } = useParams();
	const queryClient = useQueryClient();
	const { data: KnowBaseItem, isLoading: isLoadingKnowBaseItem, refetch } =
		useKnowBaseItem({ initialData: [] }, id_modal ? id_modal : id);
	const [isEdditing, setIsEdditing] = useState(false);


	useEffect(() => {
		console.log(id_modal)
		// Realizar la consulta nuevamente cuando id_modal cambie
		refetch(id_modal ? id_modal : id);
	}, [id_modal, refetch]);

	const toggleEditMode = () => {
		setIsEdditing(!isEdditing);
	};

	const handleUpdate = (data) => {
		console.log(data);
		// get react-query cache
		const cache = queryClient.getQueryData([
			"know-base-item",
			`${data.kbit_id}`,
		]);
		// update cache
		queryClient.setQueryData(["know-base-item", `${data.kbit_id}`], {
			...cache,
			...data,
		});
		// close edit mode
		toggleEditMode();
	};

	const handleCancel = () => {
		// close edit mode
		toggleEditMode();
	};

	return (
		<div className={styles.container}>
			{!id_modal && (
				<Row style={{ paddingTop: 20, marginBottom: 10 }}>
					<Col span={24}>
						<Row style={{ gap: 10 }}>
							<BiBrain style={{ fontSize: 30, color: theme.colors.secondary }} />
							<Typography.Title level={3}>Base de conocimiento</Typography.Title>
						</Row>
					</Col>
				</Row>
			)}
			{!isEdditing && (
				<KnowledgeBaseItem
					datos={KnowBaseItem}
					toggleEditMode={toggleEditMode}
					justReading={id_modal ? true : false}
				/>
			)}
			{isEdditing && (
				<UpdateKnowledgeBaseItem
					key={id_modal ? id_modal : id}
					data={KnowBaseItem}
					onSave={handleUpdate}
					onCancel={handleCancel}
					
				/>
			)}
		</div>
	);
};

export default KnowledgeBaseItemPage;
