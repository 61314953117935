import { Button, Form, Input, Radio, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { styles } from "./styles";
import { useApiAreasDepartamentos, useAreasDepartamentosMutation } from "../../../hooks/api/areas_departamentos";
import { TipoAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";
import { AreaDepartamentoDto } from "../../../@types/AreasDepartamentos.types";

const useStyles = createUseStyles(styles);
/**
 * 
 * @param {Object} props
 * @param {AreaDepartamentoDto} props.areaDepartamento - Objeto del area o departamento que se va a editar   
 * @param {Function} [props.closeModal] - funcion para cerrar el contenedor donde se encuentre el componente 
 */
const ActualizarAreaDepartamento = ({ closeModal, areaDepartamento }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { isLoadingAreasDepartamentosMutation, mutateAreasDepartamentos } = useAreasDepartamentosMutation();
    const [areasDeptos, setAreasDeptos] = useState([]);
    const [disableRelationOtherArea, setDisableRelationOtherArea] = useState(false);
    const { isLoading: isLoadingAreasDepartamentos, data: areasDepartamentos } = useApiAreasDepartamentos({
        initialData: [],
        enable: false,
    });

    const handleEditarAreaDepartamento = () => {
        form.validateFields().then((values) => {
            console.log(values)
            delete values.ade_id;
            mutateAreasDepartamentos(
                { mutationType: "update", idAreaDepartamento: areaDepartamento.ade_id, areaDepartamento: values },
                {
                    onSuccess: ({ success, mensaje }) => {
                        if (success) {
                            message.success(mensaje);
                            form.resetFields();
                            closeModal();
                        } else {
                            message.error(mensaje);
                            form.resetFields();
                            closeModal();
                        }
                    },
                    onError: (err) => {
                        console.log(err);
                        message.error("Hubo un problema al editar el  area o departamento");
                        form.resetFields();
                        closeModal();
                    },
                }
            );
        }).catch(() => message.error("Error de formulario"));
    }

    const validaTipo = (tipo) => {
        if (tipo === TipoAreaDepartamento.AREA) {
            setDisableRelationOtherArea(true);
            form.setFieldValue("ade_padreid", null);
        } else {
            setDisableRelationOtherArea(false);
        }
    }

    const handleChangeTipo = (tipo) => {
        validaTipo(tipo);
    }

    useEffect(() => {
        if (areaDepartamento) {
            form.setFieldsValue(areaDepartamento);
            validaTipo(areaDepartamento.ade_tipo);
        }
    }, [areaDepartamento])

    useEffect(() => {
        if (areasDepartamentos?.length > 0) {
            setAreasDeptos(
                areasDepartamentos.filter(ade => ade.ade_id !== areaDepartamento?.ade_id && ade.ade_tipo === TipoAreaDepartamento.AREA)
            )
        }
    }, [areasDepartamentos, areaDepartamento])

    return (
        <Space className={styles.formWrapper} size={10}>
            <Row>
                <Form form={form} layout="vertical">
                    <Form.Item name="ade_nombre" label="Nombre" rules={[{ required: true, message: "El nombre es requerido." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="ade_descripcion" label="Descripción" >
                        <Input.TextArea showCount maxLength={255} autoSize />
                    </Form.Item>
                    <Form.Item name="ade_tipo" label="Tipo" rules={[{ required: true, message: "El tipo es requerido." }]}>
                        <Select
                            onChange={handleChangeTipo}
                        >
                            {
                                Object.keys(TipoAreaDepartamento).map(
                                    areaDepto => <Select.Option value={TipoAreaDepartamento[areaDepto]} key={`areaDepto_${areaDepto}`}>{areaDepto}</Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    {!disableRelationOtherArea && (
                        <Form.Item name="ade_tipoposicion" label="Tipo de Posición" rules={[{ required: true, message: "El tipo de posición es requerido." }]}>
                            <Radio.Group style={{display:'flex', justifyContent:'space-evenly'}}>
                                <Radio value={1}>Soporte</Radio>
                                <Radio value={2}>Ventas</Radio>
                                <Radio value={3}>Desarrollo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    <Form.Item name="ade_padreid" label="Área al que pertenece" >
                        <Select
                            loading={isLoadingAreasDepartamentos}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.join(" ").includes(input)}
                            disabled={disableRelationOtherArea}
                        >
                            {
                                areasDeptos.map(
                                    areaDepto => <Select.Option value={areaDepto.ade_id} key={`areaDepto_${areaDepto.nombre}`}>{areaDepto.ade_nombre}</Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Row>
            <Row style={{ justifyContent: "end", gap: 10 }}>
                <Button type="secondary" onClick={closeModal}>
                    Cancelar
                </Button>
                <Button type="primary" onClick={handleEditarAreaDepartamento} loading={isLoadingAreasDepartamentosMutation}>
                    Guardar
                </Button>
            </Row>
        </Space>
    );
};

export default ActualizarAreaDepartamento;
