import useApi, { AvailableRequestContentTypes } from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatUrlQuery } from "./utils";
import { ContribuyenteConstanciaDto } from "../../@types/Empresas.types";

/**
 * @typedef {Object} ApiEmpresasQueryParams
 * @property {boolean} [only_corporativo_available] - Si se envía como true, solo se devuelven las empresas que no están relacionadas a un corporativo.
 * @property {boolean} [only_contact_not_relationated] - Si se envía como true, solo se devuelven las empresas que no están relacionadas a un contacto. [usu_id] es requerido en este caso.
 * @property {string} [usu_id] - Si se envía el ID de un usuario, se devuelven las empresas que no están relacionadas a ese usuario.
 * @property {boolean} [whit_contact] - Se incluye el objeto de los datos del contacto de la empresa.
 * @property {boolean} [include_seller_data] - Si es true, se incluyen los datos del vendedor asignado a la empresa.
 */

/**
 * @param {Object} hookParams
 * @param {ApiEmpresasQueryParams} hookParams.params 
 * @param {Object} hookParams.options
 * @returns 
 */
export const useApiEmpresas = ({ params, ...options }) => {
    const api = useApi();
    return useQuery(
        ["empresas"],
        async () => {
            if (params) {
                const { data: resultData } = await api.get(`/empresas?${formatUrlQuery(params, true)}`);
                return resultData || [];
            } else {
                const { data } = await api.get(`/empresas`);
                return data || [];
            }
        },
        options
    );
};

export const useApiCorporativos = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "corporativos"],
        async () => {
            const { data } = await api.get(`/empresas/tipo/2`);
            return data || [];
        },
        options
    );
};


export const useApiEmpresa = (options) => {
    const api = useApi();
    return useQuery(
        ["empresa"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}`);
            return data;
        },
        options
    );
};

export const useEmpresasMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/empresas`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.emp_id;
            delete params.emp_id;
            const { data } = await api.put(`/empresas/${id}`, params, { dataType: "json" });
            return data;
        }
    };

    const {
        mutate: mutateEmpresa,
        isLoading: isLoadingEmpresaMutation,
        error: errorEmpresaMutation,
        reset: resetEmpresaMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresas"], { exact: true });
            queryClient.invalidateQueries(["empresa"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresa,
        isLoadingEmpresaMutation,
        errorEmpresaMutation,
        resetEmpresaMutation,
    };
};


export const useUpdateEmpresa = () => {
    const api = useApi();

    const updateEmpresa = async (empresa) => {
        const response = await api.put(`/empresas/${empresa.emp_id}`, empresa, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(updateEmpresa);

    return {
        updateEmpresa: mutation.mutate,
        isUpdatingEmpresa: mutation.isLoading,
        isUpdatedEmpresa: mutation.isSuccess,
        isUpdatingEmpresaError: mutation.isError,
    };
};


export const useGuardaDatosConstanciaEmpresa = () => {
    const api = useApi();

    /**
     * @param {ContribuyenteConstanciaDto} datosConstancia - datos de la constancia fiscal 
     */
    const guardaDatosConstanciaEmpresa = async (datosConstancia) => {
        const response = await api.post(`/empresas/${datosConstancia.emp_id}/datos-constancia`, datosConstancia, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(guardaDatosConstanciaEmpresa);

    return {
        saveDatosConstanciaEmpresa: mutation.mutate,
        isSavingGuardaDatosConstanciaEmpresa: mutation.isLoading,
        isSavedDatosConstanciaEmpresa: mutation.isSuccess,
        isSavingDatosConstanciaEmpresaError: mutation.isError,
    };
};

/************************* servicios para constancias fiscales de las empresas ***********************/

export const useGetPdfConstanciaEmpresaInfo = () => {
    const api = useApi();

    /**
     * 
     * @param {FormData} fd - form data que incluye el archivo de la constancia 
     * @returns 
     */
    const getDatosConstancia = async (fd) => {
        const response = await api.post(
            `/empresas/extrae-datos-constancia`,
            fd,
            { dataType: AvailableRequestContentTypes.MULTIPART, hasFormData: true }
        );
        return response?.data;
    };

    const mutation = useMutation(getDatosConstancia);

    return {
        getConstanciaEmpresaInfo: mutation.mutate,
        isGettingConstanciaEmpresaInfo: mutation.isLoading,
        isGettedConstanciaEmpresaInfo: mutation.isSuccess,
        isGettingEmpresaInfoError: mutation.isError,
    };
};

// -------------- hooks de campos extra de la empresa ----------------

export const useApiEmpresaCamposExtra = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "campos_extra"],
        async () => {
            if (options?.id) {
                const { data } = await api.get(`/empresas/${options?.id}/campos_extra`);
                return data;
            } else return []
        },
        options
    );
};

export const useEmpresaCamposExtraMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/empresas/${params.emp_id}/campos_extra`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     let id = params.pues_id;
        //     delete params.pues_id;
        //     const { data } = await api.put(`/empresas/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/empresas/${params.emp_id}/campos_extra/${params.came_id}`);
            return data;
        }
    };

    const {
        mutate: mutateEmpresaCamposExtra,
        isLoading: isLoadingEmpresaCamposExtraMutation,
        error: errorEmpresaCamposExtraMutation,
        reset: resetEmpresaCamposExtraMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresas", "campos_extra"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresaCamposExtra,
        isLoadingEmpresaCamposExtraMutation,
        errorEmpresaCamposExtraMutation,
        resetEmpresaCamposExtraMutation,
    };
};



// -------------------- domicilios de la empresa ---------------------
export const useApiEmpresaDomicilios = (options) => {
    const api = useApi();
    return useQuery(
        ["empresa", "domicilios"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/domicilios`);
            return data;
        },
        options
    );
};

export const useEmpresaDomiciliosMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/empresas/${params.emp_id}/domicilios`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.dom_id;
            delete params.dom_id;
            const { data } = await api.put(`/empresas/${params?.emp_id}/domicilios/${id}`, params, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/empresas/${params.emp_id}/campos_extra/${params.came_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateEmpresaDomicilios,
        isLoading: isLoadingEmpresaDomiciliosMutation,
        error: errorEmpresaDomiciliosMutation,
        reset: resetEmpresaDomiciliosMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresa", "domicilios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresaDomicilios,
        isLoadingEmpresaDomiciliosMutation,
        errorEmpresaDomiciliosMutation,
        resetEmpresaDomiciliosMutation,
    };
};

// --------------------- licencias de la empresa --------------------------

export const useApiEmpresasLicencias = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "licencias"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/licencias`);
            return data;
        },
        options
    );
};

export const useEmpresaLicenciasMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            const { data } = await api.post(`/empresas/${params.emp_id}/licencias`, params.data, { hasFormData: true });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            const { data } = await api.put(`/empresas/${params?.emp_id}/licencias/${params.usli_id}`, params.data, { dataType: "json" });
            return data;
        }
        if (params.mutationType === "delete") {
            const res = await api.del(`/empresas/${params.emp_id}/licencias/${params.usli_id}`);
            return res?.data || false;
        }
    };

    const {
        mutate: mutateEmpresaLicencias,
        isLoading: isLoadingEmpresaLicenciasMutation,
        error: errorEmpresaLicenciasMutation,
        reset: resetEmpresaLicenciasMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresas", "licencias"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresaLicencias,
        isLoadingEmpresaLicenciasMutation,
        errorEmpresaLicenciasMutation,
        resetEmpresaLicenciasMutation,
    };
};

// -------------- hooks de contactos de la empresa ----------------


/**
 * 
 * @typedef AddingContactoEmpresa
 * @property {Number} emp_id - id de la empresa a la que se le va a agregar el contacto   
 * @property {Number} usu_id - id del usuario (contacto) que se va a agregar a la empresa
 * @property {Boolean} use_principal - indica si el contacto es el principal de la empresa
 */

export const useAddEmpresaContacto = () => {
    const api = useApi();

    /**
     * 
     * @param {AddingContactoEmpresa} addingParams - datos necesarios para agregar el contacto a la empresa
     */
    const addResource = async (addingParams) => {
        const response = await api.post(`/empresas/${addingParams.emp_id}/contactos`, addingParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        addEmpresaContacto: mutation.mutate,
        isAddingEmpresaContacto: mutation.isLoading,
        isAddedEmpresaContacto: mutation.isSuccess,
        isAddingEmpresaContactoError: mutation.isError,
    };
};

/**
 * 
 * @typedef DeletingContactoEmpresa
 * @property {Number} emp_id - id de la empresa de la que se va a quitar el contacto   
 * @property {Number} usu_id - id del usuario (contacto) que se va a quitar   
 */

export const useDeleteEmpresaContacto = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingContactoEmpresa} deleteParams - datos necesarios para quitar el contacto de la empresa
     */
    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/empresas/${deleteParams.emp_id}/contactos/${deleteParams.usu_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteEmpresaContacto: mutation.mutate,
        isDeletingEmpresaContacto: mutation.isLoading,
        isDeletedEmpresaContacto: mutation.isSuccess,
        isDeletingEmpresaContactoError: mutation.isError,
    };
};

/*********************************** hooks de giros de la empresa ******************************************/

export const useApiEmpresaGiros = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "giros"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/giros`);
            return data;
        },
        options
    );
};

/**
 * 
 * @typedef AddingGiroEmpresa
 * @property {Number} emp_id - id de la empresa   
 * @property {Number} giro_nombre - nombre o descripcion del giro
 * @property {Number} giro_porcentaje - porcentaje del giro
 */

export const useCrearGiroEmpresa = () => {
    const api = useApi();

    /**
     * 
     * @param {AddingGiroEmpresa} addingParams - datos necesarios para agregar el giro a la empresa
     */
    const addResource = async (addingParams) => {
        const response = await api.post(`/empresas/${addingParams.emp_id}/giros`, addingParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        saveGiroEmpresa: mutation.mutate,
        isSavingGiroEmpresa: mutation.isLoading,
        isSavedGiroEmpresa: mutation.isSuccess,
        isSavingGiroEmpresaError: mutation.isError,
    };
};

/**
 * 
 * @typedef UpdattingGiroEmpresa
 * @property {Number} emp_id - id de la empresa   
 * @property {Number} giro_id - id de la empresa   
 * @property {Number} giro_nombre - nombre o descripcion del giro
 * @property {Number} giro_porcentaje - porcentaje del giro
 */

export const useUpdateGiroEmpresa = () => {
    const api = useApi();

    /**
     * 
     * @param {UpdattingGiroEmpresa} updateParams - datos necesarios para actualizar el giro a la empresa
     */
    const updateResource = async (updateParams) => {
        const response = await api.put(`/empresas/${updateParams.emp_id}/giros/${updateParams.giro_id}`, updateParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(updateResource);

    return {
        updateGiroEmpresa: mutation.mutate,
        isUpdatingGiroEmpresa: mutation.isLoading,
        isUpdatedGiroEmpresa: mutation.isSuccess,
        isUpdatingGiroEmpresaError: mutation.isError,
    };
};




/**
 * 
 * @typedef DeletingGiroEmpresa
 * @property {Number} emp_id - id de la empresa   
 * @property {Number} giro_id - id del giro que se va a eliminar
 */

export const useEliminarGiroEmpresa = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingGiroEmpresa} deleteParams - datos necesarios para eliminar el giro de la empresa
     */
    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/empresas/${deleteParams.emp_id}/giros/${deleteParams.giro_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteGiroEmpresa: mutation.mutate,
        isDeletingGiroEmpresa: mutation.isLoading,
        isDeletedGiroEmpresa: mutation.isSuccess,
        isDeletingGiroEmpresaError: mutation.isError,
    };
};


/*********************************** hooks de regimenes fiscales de la empresa ******************************************/

export const useApiEmpresaRegimenes = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "regimenes"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/regimenes`);
            return data;
        },
        options
    );
};


/**
 * 
 * @typedef AddingRegimenEmpresa
 * @property {Number} emp_id - id de la empresa   
 * @property {Number} refi_id - id del regimen fiscal
 */


export const useCrearEmpresaregimen = () => {
    const api = useApi();

    /**
     * 
     * @param {AddingRegimenEmpresa} addingParams - datos necesarios para agregar el regimen de la empresa
     */
    const addResource = async (addingParams) => {
        const response = await api.post(`/empresas/${addingParams.emp_id}/regimenes-fiscales`, addingParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        saveEmpresaRegimen: mutation.mutate,
        isSavingEmpresaRegimen: mutation.isLoading,
        isSavedEmpresaRegimen: mutation.isSuccess,
        isSavingEmpresaRegimenError: mutation.isError,
    };
};


/**
 * 
 * @typedef DeletingRegimenEmpresa
 * @property {Number} emp_id - id de la empresa   
 * @property {Number} refi_id - id del regimen
 */


export const useEliminarEmpresaregimen = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingRegimenEmpresa} deletingParams - datos necesarios para agregar el regimen de la empresa
     */
    const addResource = async (deletingParams) => {
        const response = await api.del(`/empresas/${deletingParams.emp_id}/regimenes-fiscales/${deletingParams.refi_id}`);
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        deleteEmpresaRegimen: mutation.mutate,
        isDeletingEmpresaRegimen: mutation.isLoading,
        isDeletedEmpresaRegimen: mutation.isSuccess,
        isDeletingEmpresaRegimenError: mutation.isError,
    };
};