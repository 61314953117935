import { PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Input, Spin, Typography } from 'antd';
import { LayoutGroup } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { MdOutlineClose } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ChatMessage from './ChatMessage';
import { Manager } from "socket.io-client";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import { setScrollToBottom, setScrollTop } from "../../helpers/scroll";

const useStyles = createUseStyles(theme => ({
    chatConversationContainer: {
        opacity: 0,
        width: 300,
        height: 450,
        display: "flex",
        flexDirection: "column",
        marginTop: -360,
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        "& .conversation-header": {
            // borderTopRightRadius: 10,
            // borderTopLeftRadius: 10,
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 10px",
            height: 60,
            borderBottom: "1px solid #eee",
            // background: theme.primaryColor,
            "& .ant-btn": {
                background: "white",
                color: "#aaa",
                border: "none",
            }
        },
        "& .conversation-container": {
            flex: 1,
            background: "#fff",
            padding: 10,
            overflowY: "auto",
            scrollbarWidth: 10,
            "&::-webkit-scrollbar": {
                width: 7
            }
        },
        "& .conversation-input-wrapper": {
            background: theme.primaryColor,
            height: 50,
            display: "flex",
            alignItems: "center",
            padding: "0 5px 0 10px",
            gap: 5,
            "& .ant-btn": {
                paddingTop: 5,
                background: theme.primaryColor,
                color: "white",
                border: "none",
            },
            "& .ant-input": {
                background: theme.primaryColor,
                color: "white"
            }
        }
    },
}));


const Chat = ({ chat, onCloseChatConversation, style }) => {
    const styles = useStyles();
    const { chatHost, sToken, user } = useSelector((state) => ({
        chatHost: state.app.chatHost,
        sToken: state.auth.token,
        user: state.auth.user
    }));
    const [ioManager] = useState(new Manager(`${chatHost}/socket.io/socket.io.js`, {
        extraHeaders: {
            authorization: `Bearer ${sToken}`
        },
    }))
    const [socket] = useState(ioManager.socket(chat.namespace));
    const [messages, setMessages] = useState([])
    const [inputValue, setInputValue] = useState("");
    const loader = useRef(null);
    const [page, setPage] = useState(null);
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false)
    const [firstPageHeight, setFirstPageHeight] = useState(0);
    const [sendingOne, setSendingOne] = useState(false)


    const sendMessage = (message) => {
        socket.emit("send-message", { message, token: sToken, room: chat.chatId });
        setInputValue("")
        setSendingOne(true)
    }

    const handleClickSendMessage = () => {
        if (inputValue !== "") {
            sendMessage(inputValue)
        }

    }

    const handleKeyDownMessage = (e) => {
        if (e.code === "Enter" && inputValue !== "") {
            sendMessage(inputValue)
        }
    }

    const handleChangeInput = (e) => {
        setInputValue(e.target.value)
    }

    const initInfiniteScrolling = () => {
        const loadNewMessagesObserver = new IntersectionObserver((entries) => {
            const lastMessage = entries[0];
            if (lastMessage.isIntersecting) {
                setPage((prev) => prev + 1);
            }
        }, { threshold: 1 });
        loadNewMessagesObserver.observe(loader.current)
    }

    useEffect(() => {
        if (page > 1) {
            setLoadingMoreMessages(true)
            socket.emit("get-messages-page", { room: chat.chatId, page: page })
        }
    }, [page])


    useEffect(() => {
        let pageMessagesSize = 10
        if (messages.length <= pageMessagesSize && messages.length > 0 && !!document.querySelector(`.chatmessage-${chat.chatId}:last-child`)) {
            const lastMessageObserver = new IntersectionObserver((entries) => {
                const lastMessage = entries[0];
                if (lastMessage.isIntersecting) return;
                // socket.emit("get-messages-page", { room: chat.chatId, page: page })
                lastMessageObserver.unobserve(lastMessage.target)
                lastMessageObserver.disconnect()
                initInfiniteScrolling();
            }, { threshold: 1 });
            lastMessageObserver.observe(document.querySelector(`.chatmessage-${chat.chatId}:last-child`))
        }
        if (!sendingOne) {
            setScrollTop(`#chat-${chat.chatId}-${chat.namespace.replace("/", "")}`, 480, false)
        }

        // let lastChatScrollTop = document.querySelector(`#chat-${chat.chatId}-${chat.namespace.replace("/", "")}`)?.scrollTop

    }, [messages])

    useEffect(() => {
        if (socket) {
            socket.emit("init-room", { room: chat.chatId, token: sToken })
            setLoadingMoreMessages(true)
            socket.emit("get-messages-page", { room: chat.chatId, page: 1 })
            socket.on("message-sended", receivingMessage => {
                console.log(receivingMessage)
                let message;
                if (receivingMessage.usu_id === user.usu_id) {
                    message = {
                        type: "sended", // received | sended
                    }
                } else {
                    message = {
                        type: "received", // received | sended
                        user: receivingMessage.usuario
                    }
                }
                message = {
                    ...message,
                    text: receivingMessage.mens_detalle,
                    date: receivingMessage.mens_fecha,
                }
                setMessages(messages => [...messages, message])
                setTimeout(() => {
                    setScrollToBottom(`#chat-${chat.chatId}-${chat.namespace.replace("/", "")}`)
                    if (sendingOne)
                        setSendingOne(false)
                }, 140)
            });
            socket.on("receiving-messages-page", data => {
                if (sendingOne)
                    setSendingOne(false)
                data = data?.map(receivingMessage => {
                    let message;
                    if (receivingMessage.usu_id === user.usu_id) {
                        message = {
                            type: "sended", // received | sended
                        }
                    } else {
                        message = {
                            type: "received", // received | sended
                            user: receivingMessage.usuario
                        }
                    }
                    message = {
                        ...message,
                        text: `${receivingMessage.mens_detalle}`,
                        date: receivingMessage.mens_fecha,
                    }
                    return message
                })

                data = data?.reverse()
                if (data && data?.length > 0) {
                    setMessages(messages => [...data, ...messages])
                }
                setLoadingMoreMessages(false)
            })
        }
        setTimeout(() => {
            setPage(1)
            setScrollToBottom(`#chat-${chat.chatId}-${chat.namespace.replace("/", "")}`)
            let chatContainer = document.getElementById(`chat-${chat.chatId}-${chat.namespace.replace("/", "")}`)
            setFirstPageHeight(chatContainer?.scrollHeight || 0)
        }, 200)
    }, []);

    return (
        <motion.div
            className={styles.chatConversationContainer}
            layout
            transition={{ duration: 0.1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={style}
        >
            <div className='conversation-header'>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    {/* <Avatar icon={<UserOutlined />} /> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text style={{ fontWeight: 600 }} >{chat.chatTitle}</Typography.Text>
                        <Typography.Text style={{ color: "#1890ff", fontWeight: 600 }} >{chat.chatSubTitle}</Typography.Text>
                    </div>
                    {/* <Typography.Text style={{ color: "white" }} >francisco castellanos</Typography.Text> */}
                </div>
                <div style={{ display: "flex", gap: 5 }}>
                    <Button shape='circle' style={{ display: "flex", alignItems: "center", justifyContent: "center" }} icon={<PhoneOutlined style={{ fontSize: 16 }} />} />
                    <Button shape='circle' icon={<MdOutlineClose style={{ fontSize: 24 }} />} onClick={() => onCloseChatConversation(chat)} />
                </div>
            </div>
            <div>
                {
                    chat?.message?.text && chat?.message?.type &&
                    <Alert message={
                        <Marquee pauseOnHover gradient={false}>
                            {chat?.message?.text}
                        </Marquee>
                    }
                        type={chat?.message?.type}
                        showIcon
                        closable
                    />
                }
            </div>
            <div
                className='conversation-container'
                id={`chat-${chat.chatId}-${chat.namespace.replace("/", "")}`}
            >
                <div ref={loader} style={{ width: "100%", height: 30, display: "flex", justifyContent: "center", padding: "30 0px" }} >
                    {
                        loadingMoreMessages && <Spin />
                    }
                </div>
                <LayoutGroup>
                    {messages.map((message, iMessage) => <ChatMessage {...message} key={`${message.type}_${iMessage}_${message.date}`} chatId={chat.chatId} user={message.user} />)}
                </LayoutGroup>
            </div>
            {/* <div style={{ width: "100%", height: 30, background: "#00f", color: "white" }} /> */}
            <div className='conversation-input-wrapper'>
                <Input placeholder='mensaje' onChange={handleChangeInput} onKeyDown={handleKeyDownMessage} value={inputValue} autoFocus />
                <Button shape='circle' icon={<FiSend style={{ fontSize: 16 }} />} onClick={handleClickSendMessage} />
            </div>
        </motion.div>
    );
}

export default Chat;