import { DateTime } from "luxon";

// modificaciones faltantes 
// recalcular el tiempo del ticket tomando en cuenta la ULTIMA incidencia de reasignacion,  ignorando las incidencias anteriores para resetear el de tiempo de servicio

export const calculaTiempoTranscurrido = (incidencias) => {
    // se extrae la posicion de la ultima incidencia de reasignacion si es que se ha reasignado el ticket
    // let iUltimaIncidenciaReasignacion = 0;
    // incidencias.forEach((incidencia, index) => {
    //     if (incidencia.tipi_id === 10) { // incidencia de tipo reasignacion de ticket
    //         iUltimaIncidenciaReasignacion = index
    //     }
    // });

    // // se valida si es que se encontro incidencias de reasignacion
    // if (iUltimaIncidenciaReasignacion) {
    //     // se quitan las incidencias anteriores a la ultima de reasignacion encontrada
    //     incidencias = incidencias.slice(iUltimaIncidenciaReasignacion);
    // }

    // let statusTiempoDetenido = [1, 3, 4, 5, 6, 7, 9]; // estatus del ticket que detienen el tiempo
    let statusTiempoDetenido = [1, 3, 4, 5, 6, 7]; // estatus del ticket que detienen el tiempo
    let iIncidenciaActual = 0;
    let ultimaIncidenciaAfectaTiempo;
    // el tiempo se mide en segundos
    let segundosTranscurridos = 0;

    let incidenciaActual = incidencias[iIncidenciaActual];
    // se iteran las incidencias para empezar a calcular el tiempo
    while (incidenciaActual) { // se valida que la incidencia a la que se está accediendo existe
        /*
            -> se busca una incidencia que permita  contar el tiempo
            -> las incidencias que permiten contar el tiempo son aquellas que
                1. no contienen un status nulo
                2. que contengan un status 
                    [2] = Ticket en Seguimiento
        */
        if (incidenciaActual.stat_id === 2) { // se valida que la incidencia tenga un status 2
            // se busca la siguiente incidencia que detenga el tiempo
            let iIncidenciaSiguiente = iIncidenciaActual + 1;
            let incidenciaSiguiente;
            do {
                incidenciaSiguiente = incidencias[iIncidenciaSiguiente];
                iIncidenciaSiguiente++;
            } while (incidenciaSiguiente && !statusTiempoDetenido.includes(incidenciaSiguiente.stat_id));

            let fechaIncidenciaActual = DateTime.fromISO(incidenciaActual.tici_fecha, { zone: "utc" });
            if (incidenciaSiguiente) { //se valida si se encuentra una incidencia siguiente válida
                // se calcula cuanto tiempo ha transcurrido entre incidencias
                let fechaIncidenciaSiguiente = DateTime.fromISO(incidenciaSiguiente.tici_fecha, { zone: "utc" });
                segundosTranscurridos += fechaIncidenciaSiguiente.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();

                ultimaIncidenciaAfectaTiempo = incidenciaSiguiente
                // se setea la incidencia actual en la posicion de la ultima encontrada
                iIncidenciaActual = iIncidenciaSiguiente - 1;
            } else {
                //si no se encontró una incidencia siguiente , se toma el rango del tiempo en base a la fecha y hora actual
                let fechaActual = DateTime.now().toUTC();
                segundosTranscurridos += fechaActual.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();
                ultimaIncidenciaAfectaTiempo = incidenciaActual
                break;
            }
        } else {
            if (incidenciaActual?.stat_id) {
                ultimaIncidenciaAfectaTiempo = incidenciaActual
            }
        }
        iIncidenciaActual++;
        incidenciaActual = incidencias[iIncidenciaActual];
    }

    return { segundosTranscurridos, ultimaIncidenciaAfectaTiempo };
}

export const calculaTiempoAcuerdoTranscurrido = (incidencias) => {
    // se extrae la posicion de la ultima incidencia de cambio de acuerdo de servicio si es que se ha cambiado el acuerdo
    let iUltimoCambioAcuerdo = 0;
    incidencias.forEach((incidencia, index) => {
        if (incidencia.tipi_id === 11) { // incidencia de tipo reasignacion de ticket
            iUltimoCambioAcuerdo = index
        }
    });
    
    // se valida si es que se encontro incidencias de cambio de acuerdo
    if (iUltimoCambioAcuerdo) {
        // se quitan las incidencias anteriores a la ultima de reasignacion encontrada
        incidencias = incidencias.slice(iUltimoCambioAcuerdo);
    }

    // let statusTiempoDetenido = [1, 3, 4, 5, 6, 7, 9]; // estatus del ticket que detienen el tiempo
    let statusTiempoDetenido = [1, 3, 4, 5, 6, 7]; // estatus del ticket que detienen el tiempo
    let iIncidenciaActual = 0;
    let ultimaIncidenciaAfectaTiempo;
    // el tiempo se mide en segundos
    let segundosTranscurridos = 0;

    let incidenciaActual = incidencias[iIncidenciaActual];
    // se iteran las incidencias para empezar a calcular el tiempo
    while (incidenciaActual) { // se valida que la incidencia a la que se está accediendo existe
        /*
            -> se busca una incidencia que permita  contar el tiempo
            -> las incidencias que permiten contar el tiempo son aquellas que
                1. no contienen un status nulo
                2. que contengan un status 
                    [2] = Ticket en Seguimiento
        */
        if (incidenciaActual.stat_id === 2) { // se valida que la incidencia tenga un status 2
            // se busca la siguiente incidencia que detenga el tiempo
            let iIncidenciaSiguiente = iIncidenciaActual + 1;
            let incidenciaSiguiente;
            do {
                incidenciaSiguiente = incidencias[iIncidenciaSiguiente];
                iIncidenciaSiguiente++;
            } while (incidenciaSiguiente && !statusTiempoDetenido.includes(incidenciaSiguiente.stat_id));

            let fechaIncidenciaActual = DateTime.fromISO(incidenciaActual.tici_fecha, { zone: "utc" });
            if (incidenciaSiguiente) { //se valida si se encuentra una incidencia siguiente válida
                // se calcula cuanto tiempo ha transcurrido entre incidencias
                let fechaIncidenciaSiguiente = DateTime.fromISO(incidenciaSiguiente.tici_fecha, { zone: "utc" });
                segundosTranscurridos += fechaIncidenciaSiguiente.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();

                ultimaIncidenciaAfectaTiempo = incidenciaSiguiente
                // se setea la incidencia actual en la posicion de la ultima encontrada
                iIncidenciaActual = iIncidenciaSiguiente - 1;
            } else {
                //si no se encontró una incidencia siguiente , se toma el rango del tiempo en base a la fecha y hora actual
                let fechaActual = DateTime.now().toUTC();
                segundosTranscurridos += fechaActual.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();
                ultimaIncidenciaAfectaTiempo = incidenciaActual
                break;
            }
        } else {
            if (incidenciaActual?.stat_id) {
                ultimaIncidenciaAfectaTiempo = incidenciaActual
            }
        }
        iIncidenciaActual++;
        incidenciaActual = incidencias[iIncidenciaActual];
    }

    return { segundosTranscurridos, ultimaIncidenciaAfectaTiempo };
}

export const calculaTiempoTranscurridoServicio = (incidencias) => {
    // se extrae la posicion de la ultima incidencia de reasignacion si es que se ha reasignado el ticket
    // let iUltimaIncidenciaReasignacion = 0;
    // incidencias.forEach((incidencia, index) => {
    //     if (incidencia.tipi_id === 10) { // incidencia de tipo reasignacion de ticket
    //         iUltimaIncidenciaReasignacion = index
    //     }
    // });

    // // se valida si es que se encontro incidencias de reasignacion
    // if (iUltimaIncidenciaReasignacion) {
    //     // se quitan las incidencias anteriores a la ultima de reasignacion encontrada
    //     incidencias = incidencias.slice(iUltimaIncidenciaReasignacion);
    // }

    // let statusTiempoDetenido = [1, 3, 4, 5, 6, 7, 9]; // estatus del ticket que detienen el tiempo
    let statusTiempoDetenido = [1, 3, 4, 5, 6, 7]; // estatus del ticket que detienen el tiempo
    let iIncidenciaActual = 0;
    let ultimaIncidenciaAfectaTiempo;
    // el tiempo se mide en segundos
    let segundosTranscurridos = 0;

    let incidenciaActual = incidencias[iIncidenciaActual];
    // se iteran las incidencias para empezar a calcular el tiempo
    while (incidenciaActual) { // se valida que la incidencia a la que se está accediendo existe
        /*
            -> se busca una incidencia que permita  contar el tiempo
            -> las incidencias que permiten contar el tiempo son aquellas que
                1. no contienen un status nulo
                2. que contengan un status 
                    [2] = Ticket en Seguimiento
        */
        if (incidenciaActual.stat_id === 2) { // se valida que la incidencia tenga un status 2
            // se busca la siguiente incidencia que detenga el tiempo
            let iIncidenciaSiguiente = iIncidenciaActual + 1;
            let incidenciaSiguiente;
            do {
                incidenciaSiguiente = incidencias[iIncidenciaSiguiente];
                iIncidenciaSiguiente++;
            } while (incidenciaSiguiente && !statusTiempoDetenido.includes(incidenciaSiguiente.stat_id));

            let fechaIncidenciaActual = DateTime.fromISO(incidenciaActual.tici_fecha, { zone: "utc" });
            if (incidenciaSiguiente) { //se valida si se encuentra una incidencia siguiente válida
                // se calcula cuanto tiempo ha transcurrido entre incidencias
                let fechaIncidenciaSiguiente = DateTime.fromISO(incidenciaSiguiente.tici_fecha, { zone: "utc" });
                segundosTranscurridos += fechaIncidenciaSiguiente.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();

                ultimaIncidenciaAfectaTiempo = incidenciaSiguiente
                // se setea la incidencia actual en la posicion de la ultima encontrada
                iIncidenciaActual = iIncidenciaSiguiente - 1;
            } else {
                //si no se encontró una incidencia siguiente , se toma el rango del tiempo en base a la fecha y hora actual
                let fechaActual = DateTime.now().toUTC();
                segundosTranscurridos += fechaActual.toUnixInteger() - fechaIncidenciaActual.toUnixInteger();
                ultimaIncidenciaAfectaTiempo = incidenciaActual
                break;
            }
        } else {
            if (incidenciaActual?.stat_id) {
                ultimaIncidenciaAfectaTiempo = incidenciaActual
            }
        }
        iIncidenciaActual++;
        incidenciaActual = incidencias[iIncidenciaActual];
    }

    return {
        segundosTranscurridosServicio: segundosTranscurridos,
        ultimaIncidenciaAfectaTiempoServicio: ultimaIncidenciaAfectaTiempo
    };
}