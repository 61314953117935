import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, message, Rate, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Elevation from '../../../components/Elevation';
import { useTicketsIncidenciasMutation } from '../../../hooks/api/tickets_incidencias';
import { useUsuariosIncidenciasMutation } from '../../../hooks/api/usuarios_incidecias';
import { useQueryUtils } from '../../../hooks/api/utils';

const useStyles = createUseStyles({
    userCardCalification: {
        minWidth: "100%",
        width: "100%",
        border: `1px solid #eee`,
        transition: "all 0.2s ease-in-out",
        userSelect: "none",
        "& .user-card-inner": {
            padding: "20px 10px",
            display: "flex",
            gap: 10,
            // flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            height: "100%"
        },
    },
    modalCalificarCompañeros: {
        "& .ant-modal-body": {
            padding: 0
        },
        "& .modal-overflow-container": {
            padding: 20,
            maxHeight: 700,
            overflowY: "auto",
        }
    }
});

const initialUserCalification = {
    usin_tipo: 0,
    usin_titulo: "",
    usin_detalle: "",
    usu_id: 0,
    tick_id: 0,
    usin_calificacion: 0
}

const UserCardCalification = ({ agente, onChangeCalificacion, clear }) => {
    const styles = useStyles();
    const [calificacion, setCalificacion] = useState(initialUserCalification);
    const { mutateUsuariosIncidencias, isLoadingUsuariosIncidenciasMutation } = useUsuariosIncidenciasMutation();
    const [usuarioAgente, setUsuarioAgente] = useState(null)

    const handleChangeField = (key, value) => {
        setCalificacion({
            ...calificacion,
            [key]: value
        })
    }

    useEffect(() => {
        if (onChangeCalificacion)
            onChangeCalificacion(agente, calificacion);
    }, [calificacion])

    useEffect(() => {
        if (clear) {
            setCalificacion(initialUserCalification)
        }
    }, [clear])

    useEffect(() => {
        if (agente?.agente) {
            setUsuarioAgente(agente?.agente)
        }
    }, [agente])

    return (
        <div className={`${styles.userCardCalification}`} style={{ width: "100%", flex: 1 }}>
            <Elevation className="user-card-inner" style={{ width: "100%", flex: 1, gap: 20 }}>
                <div style={{ flex: 0.1 }}>
                    <Avatar size={64} icon={<UserOutlined />} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", flex: 0.9 }}>
                    <Typography.Title level={4}>{usuarioAgente?.usu_nombres} {usuarioAgente?.usu_apaterno} {usuarioAgente?.usu_amaterno}</Typography.Title>
                    <Form layout="vertical" style={{ width: "100%" }}>
                        <Form.Item label="Calificaión" style={{ marginBottom: 10 }}>
                            <Rate onChange={(value) => handleChangeField("usin_calificacion", value)} value={calificacion.usin_calificacion} />
                        </Form.Item>
                        <Form.Item label="Comentario" style={{ marginBottom: 10 }}>
                            <Input.TextArea showCount style={{ width: "100%" }} rows={3} onChange={(e) => handleChangeField("usin_detalle", e.target.value)} value={calificacion.usin_detalle} />
                        </Form.Item>
                    </Form>
                </div>
            </Elevation>
        </div>
    );
}


const CalificaAsesores = ({ clear, ticket, closeModal, incidenciasApoyo }) => {
    const [tclear, setClear] = useState(clear);
    const [agentesAyudantes, setAgentesAyudantes] = useState([])
    const [agentesCalificaciones, setAgentesCalificaciones] = useState([]);
    const { mutateUsuariosIncidencias, isLoadingUsuariosIncidenciasMutation } = useUsuariosIncidenciasMutation();
    const { mutateTicketsIncidencias, isLoadingTicketsIncidenciasMutation } = useTicketsIncidenciasMutation();

    const onChangeAsesorCalificacion = (agente, calificacion) => {
        let tAgentes = [...agentesAyudantes];
        tAgentes = tAgentes.reduce((acumulados) => {
            let iAgente = null;
            let agenteCalificacion = acumulados.find((tAge, iTAge) => {
                if (tAge.usu_id === agente.usu_id) {
                    iAgente = iTAge;
                    return true;
                }
            });

            if (agenteCalificacion) {
                agenteCalificacion = {
                    ...calificacion,
                    usu_asignadoid: agenteCalificacion.usu_id,
                    tick_id: ticket.tick_id,
                    usin_tipo: 1,
                    usin_titulo: "Calificación de apoyo en ticket"
                }
                acumulados[iAgente] = agenteCalificacion
            } else {
                agenteCalificacion = {
                    ...calificacion,
                    usu_asignadoid: agente.usu_id,
                    tick_id: ticket.tick_id,
                    usin_tipo: 1,
                    usin_titulo: "Calificación de apoyo en ticket"
                }
                acumulados = [...acumulados, agenteCalificacion]
            }

            return acumulados;
        }, [])

        setAgentesCalificaciones(tAgentes)
    }

    const handleClickGuardarCalificaciones = () => {
        mutateUsuariosIncidencias({
            mutationType: "create",
            incidencias: agentesCalificaciones
        }, {
            onSuccess: (res) => {
                if (res.success) {
                    // se marca la incidencia de apoyo de los tickets
                    let tIncidenciasApoyo = incidenciasApoyo.map(inc => ({ tici_id: inc.tici_id, tici_visto: 1, tipi_id: inc.tipi_id, ticket: inc.ticket }))
                    mutateTicketsIncidencias({
                        mutationType: "update",
                        incidencias: tIncidenciasApoyo
                    }, {
                        onSuccess: (tickIncResp) => {
                            if (tickIncResp?.success) {
                                closeModal();
                                message.success("Has calificado a los compañeros");
                            } else {
                                message.error("Hubo un problema al calificar a los compañeros");
                            }
                        },
                        onError: () => {
                            message.error("Hubo un problema al calificar a los compañeros");
                        }
                    })
                } else {
                    message.error("Hubo un problema al calificar a los compañeros");
                }
            },
            onError: () => {
                message.error("Hubo un problema al calificar a los compañeros");
            }
        })
    }

    useEffect(() => {
        setClear(clear)
    }, [clear]);

    useEffect(() => {
        let incidencias_ticket = ticket.incidencias.reverse();
        let incidencia_apoyo = incidencias_ticket.find(tick => (tick?.tipi_id === 4 || tick?.tipi_id === 5) && tick?.tici_visto === 0);
        if (ticket?.agentes?.length > 0) {
            setAgentesAyudantes(
                ticket?.agentes?.filter(agente => agente?.tiag_id === 5 && agente?.tica_fecha === incidencia_apoyo?.tici_fecha)
            )
        }
    }, [ticket])

    return (
        <Spin tip="Cargando asesores ..." spinning={agentesAyudantes.length > 0 ? false : true}>
            <div style={{ display: "flex", gap: 25, flexWrap: "wrap", maxHeight: 700, overflowY: "auto" }}>
                {
                    agentesAyudantes.map((agente, iAgente) => (<UserCardCalification clear={tclear} agente={agente} onChangeCalificacion={onChangeAsesorCalificacion} key={`agente-calificacion-${iAgente}-${agente.usu_id}`} />))
                }
                <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                    <Button type="primary" onClick={handleClickGuardarCalificaciones} size="large">Guardar y finalizar solicitud de apoyo</Button>
                </div>
            </div>
        </Spin>
        
    );
}

export default CalificaAsesores;