import React, { useState, useEffect } from "react";
import { Modal, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import CustomTransfer from "./CustomTransfer";

const useStyles = createUseStyles({
    configSection: {
        marginBottom: 20,
        width: "100%",
        flexDirection: "column",
    },
});


const TableConfigModal = ({ columnas, showConfig, closeTableConfig, mappedColumns, setCustomColumns }) => {
    const styles = useStyles();
    const [newMappedColumns, setNewMappedColumns] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);

    const prepareTransferMappedColumns = () => {
        let targetKeys = [];
        let newMappedColumns = mappedColumns.map((column) => {
            let anadida = false;
            for (let addedColumn of columnas) {
                if (addedColumn.dataIndex === column.dataIndex) anadida = true;
            }
            if (anadida) targetKeys.push(column);
            return { ...column, key: column.dataIndex, description: column.title };
        });
        setNewMappedColumns(newMappedColumns);
        setTargetKeys(targetKeys);
    };

    useEffect(() => {
        if (showConfig) prepareTransferMappedColumns();
    }, [showConfig, columnas]);

    const onChangeTransfer = (nextTargetKeys) => {
        setCustomColumns(nextTargetKeys, "dataIndex");
    };

    return (
        <Modal centered title="Configuración de tabla" open={showConfig} footer={null} onCancel={closeTableConfig}>
            {/* <Row className={styles.configSection}>
                <Row>
                    <Typography.Title level={5} style={{ marginBottom: 20 }}>
                        Ordenar columnas de tabla
                    </Typography.Title>
                </Row>
                <Row>
                    <OrdenadorColumnas columnas={columnas} setColumns={setColumns} />
                </Row>
            </Row> */}
            <Row className={styles.configSection}>
                <Row>
                    <Typography.Title level={5} style={{ marginBottom: 20 }}>
                        Modificar columnas
                    </Typography.Title>
                </Row>
                <Row justify="center">
                    {/* <Transfer
                        dataSource={newMappedColumns}
                        titles={["Disponibles", "Añadidas"]}
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={onChangeTransfer}
                        onSelectChange={onSelectChangeTransfer}
                        onScroll={onScrollTransfer}
                        render={(item) => item.title}
                        style={{ width: "100%" }}
                        listStyle={{
                            width: "100%",
                            height: 200,
                        }}
                    /> */}
                    <CustomTransfer onChangeTransfer={onChangeTransfer} dataSource={newMappedColumns} selectedItems={targetKeys} render={(item) => item.title} />
                </Row>
            </Row>
        </Modal>
    );
};

export default TableConfigModal;
