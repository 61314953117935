import { useQueryClient } from "react-query";

export const useQueryUtils = () => {
    const queryClient = useQueryClient();

    const invalidateQuery = (query, exact = true) => {
        queryClient.invalidateQueries(query, { exact });
    }

    return {
        invalidateQuery
    }
}


export const formatUrlQuery = (object, validateNull = false) => {
    const params = new URLSearchParams();
    Object.keys(object).forEach(key => {
        if (validateNull) {
            if (object[key] !== null && object[key] !== undefined) {
                params.append(key, object[key]);
            }
        }
        else {
            params.append(key, object[key]);
        }
    });
    return params.toString();
    // return encodeURIComponent(JSON.stringify(object))
}