// @ts-nocheck
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetCompanyContact = ()  => {
    const api = useApi();

    /**
     * 
     * @param {MutatingCompany} params el tipo {@link MutatingCompany} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async () => {
        const data  = await api.get(`/empresas?whit_contact=true`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getCompanyContact: mutation.mutate,
    };
}