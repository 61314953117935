/**
 * indica los tipo de zonas horarias disponibles
 * @readonly
 * @enum {String}
 */
export const ZonasHorarias = Object.freeze({
    ZONA_SURESTE: "UTC-5",
    ZONA_CENTRO: "UTC-6",
    ZONA_PACIFICO: "UTC-7",
    ZONA_NOROESTE: "UTC-8"
});

/**
 * Se consulta el nombre del tupo de contacto de mercacion en base a su valor entero del area o 
 * @param {String} value - valor de la zona horaria que se requiere extraer la llave   
 * @returns {String} Nombre de la zona horaria
 */
export const getKeyZonaHoraria = (value) =>
    Object.keys(ZonasHorarias).find(key => ZonasHorarias[key] == value);
