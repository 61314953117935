
export const EConversationType = Object.freeze({
    CHAT_GENERAL: 1,
    SEGUIMIENTO_APOYO_TICKET: 2,
    GRUPO: 3,
});

export const EMessageStatus = Object.freeze({
    ENVIADO: 1,
    LEIDO_VISTO: 2
})

export const EMessageType = Object.freeze({
    RECEIVED: "received",
    SENT: "sent"
})