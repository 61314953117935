import { useState } from "react";
import FileDropper from "../../components/FileDropper";

const FileManager = ({
    allowedExtensions,
    limit,
    maxFileMBSize,
    onChangeFiles
}) => {

    const handleChangeFiles = (files) => {
        if (onChangeFiles) onChangeFiles(files);
    }


    return (
        <FileDropper onChange={handleChangeFiles} allowedExtensions={allowedExtensions} limit={limit} maxFileSize={maxFileMBSize} />
    );
}

export default FileManager;