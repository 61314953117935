import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { reducers } from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage/session'


let persistKey = "root";
const persistConfig = {
    key: persistKey,
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
let persistor;

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== "production") {
        const { composeWithDevTools } = require("redux-devtools-extension");
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
    const store = createStore(persistedReducer, initialState, bindMiddleware([thunk]));
    persistor = persistStore(store);
    return { store, persistor };
}

export default configureStore();
