import { Button, Form, Input, InputNumber, Row, Space, Typography, message } from "antd";
import { AcuerdoServicioDto, UpdateAcuerdoServicioDto } from "../../../@types/AcuerdosServicio.types";
import { useUpdateAcuerdoServicio } from "../../../hooks/api/acuerdos_servicio";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

/**
 * @param {Object} props
 * @param {AcuerdoServicioDto} props.acuerdoServicio - Acuerdo de servicio que se va a editar
 * @param {Function} [props.closeAcuerdoServicio] - funcion para cerrar el contenedor padre del componente
 */
const ActualizarAcuerdoServicio = ({ acuerdoServicio, closeAcuerdoServicio }) => {
    const [form] = Form.useForm();
    const { isUpdatingAcuerdoServicio, updateAcuerdoServicio } = useUpdateAcuerdoServicio();
    const queryClient = useQueryClient();

    const handleGuardarAcuerdoServicio = () => {
        form.validateFields().then(
            values => {
                let acuerdo = new UpdateAcuerdoServicioDto();
                acuerdo.cacu_descripcion = values.cacu_descripcion;
                acuerdo.cacu_id = acuerdoServicio.cacu_id;
                acuerdo.cacu_minutos = values.cacu_minutos;
                acuerdo.cacu_nombre = values.cacu_nombre;
                updateAcuerdoServicio(acuerdo, {
                    onSuccess: res => {
                        if (res.success) {
                            message.success(res.mensaje);
                            queryClient.invalidateQueries("acuerdos-servicio")
                        } else {
                            message.error(res.mensaje);
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al actualizar el acuerdo de servicio")
                    }
                })
            }).catch(() => message.error("Error en el formulario"))
    }

    useEffect(() => {
        form.setFieldsValue(acuerdoServicio);
    }, [acuerdoServicio])

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 10 }}>
            <Row>
                <Typography.Title level={4}>Editar acuerdo de servicio</Typography.Title>
            </Row>
            <Row>
                <Form form={form} layout="vertical" style={{ width: "100%" }}>
                    <Form.Item name="cacu_nombre" label="Nombre del acuerdo" rules={[{ required: true, message: "El nombre es requerido." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="cacu_minutos" label="Minutos" rules={[{ required: true, message: "Los minutos son requeridos." }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="cacu_descripcion" label="Descripción">
                        <Input.TextArea showCount maxLength={255} autoSize />
                    </Form.Item>
                </Form>
            </Row>
            <Row style={{ justifyContent: "end", gap: 10 }}>
                <Button type="secondary" onClick={closeAcuerdoServicio}>
                    Cancelar
                </Button>
                <Button type="primary" onClick={handleGuardarAcuerdoServicio} loading={isUpdatingAcuerdoServicio}>
                    Guardar
                </Button>
            </Row>
        </div>
    );
}

export default ActualizarAcuerdoServicio;