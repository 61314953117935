import React from "react";
import { Table } from "antd";


const Tabla = ({dataSource}) => {

    const columns = [
        {
            title: 'Actividad',
            dataIndex: 'actividad',
            key: 'actividad',
            sorter: (a, b) => a.actividad.localeCompare(b.actividad),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: 'Tiempo',
            dataIndex: 'tiempo',
            key: 'tiempo',
            sorter: (a, b) => a.tiempo.localeCompare(b.tiempo),
        },
    ]

    return(
        <Table 
            dataSource={dataSource} 
            columns={columns}    
            className="tableData"
            pagination={false}
            style={{width:'100%'}}/>
    )
}

export default Tabla