import Lottie from "lottie-web";
import { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { LOTTIE_ANIMATIONS } from "../../../../../animations/lottie";
import { useRouteMatch } from "react-router-dom";
import { Row, Typography } from "antd";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& .ventas-home-animation": {
            width: "100%",
            maxWidth: 400
        }
    },
    moduleHeader: {
        padding: "0px 10px 10px",
    },
    moduleActions: {
        display: "flex",
        alignItems: "end",
        gap: 10,
        marginLeft: 20
    },
}))

const Anuncios = () => {
    const styles = useStyles();
    const { path } = useRouteMatch();

    useEffect(() => {
       
    }, []);

    return (
        <div className={`${styles.container}`}>
            <Row className={styles.moduleHeader}>
                <Typography.Title level={2} style={{ margin: 0 }}>Anuncios y mensajes</Typography.Title>
            </Row>
            <div className="animate__animated animate__fadeInDown" style={{ animationDuration: "0.3s" }}></div>
        </div>
    );
}

export default Anuncios;