import { Badge } from "antd";
import React, { createRef, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    tabsButtons: {
        "& ul li": {
            display: "inline",
            textalign: "center",
            "& a": {
                display: "inline-block",
                margin: "0 10px",
                padding: ".3rem 0",
                textDecoration: "none",
                color: "#333",
                textAlign: "center",
            },
        },
        "& hr": {
            height: ".2rem",
            width: "160px",
            margin: 0,
            background: "#00A757",
            border: "none",
            transition: ".3s ease-in-out",
        },
    },
});

const CustomTabsButtons = ({ tabsButtons, activeKey: tempActiveKey, onChangeTab }) => {
    const styles = useStyles();
    const [elRefs, setElRefs] = useState([]);
    const [activeKey, setActiveKey] = useState("");
    const [bottomBorderParams, setBottomBorderParams] = useState({
        background: "#00A757",
        width: 0,
        marginLeft: 0,
    });
    const [prevBottomBorderParams, setPrevBottomBorderParams] = useState({
        background: "#00A757",
        width: 0,
        marginLeft: 0,
    });

    const getBorderBottomOffsetLeft = (iButton) => {
        let offsetLeft = 0;
        elRefs.forEach((elRef, iEl) => {
            if (iEl < iButton) {
                offsetLeft += elRef.current.offsetWidth;
            }
        });
        return offsetLeft;
    };

    useEffect(() => {
        setElRefs((elRefs) =>
            Array(tabsButtons.length)
                .fill()
                .map((_, i) => elRefs[i] || createRef())
        );
        setActiveKey(tempActiveKey);
        tabsButtons.forEach((button, iButton) => {
            if (button.key === activeKey) {
                let params = {
                    background: button.borderBottomColor,
                    width: elRefs[iButton].current.offsetWidth,
                    marginLeft: getBorderBottomOffsetLeft(iButton) + (iButton + 1) * 10,
                };
                setBorderParams(iButton, params);
            }
        });
    }, [tabsButtons, tempActiveKey, activeKey]);

    const setBorderParams = (iButton, params) => {
        if (iButton === 0) {
            params.marginLeft = 10;
        }
        setBottomBorderParams(params);
        setPrevBottomBorderParams(params);
    };

    return (
        <div className={styles.tabsButtons}>
            <ul style={{ paddingInlineStart: "10px" }}>
                {tabsButtons.map((button, iButton) => {
                    return (
                        <Badge count={button?.badgeCount || 0} size="small" color={button.borderBottomColor} style={{ fontSize: "11px" }} key={`${button.key}_custom-tab-btn`}>
                            <li
                                key={button.key}
                                ref={elRefs[iButton]}
                                onMouseOver={(e) => {
                                    setBottomBorderParams({
                                        background: button.borderBottomColor,
                                        width: e.target.offsetWidth + 21,
                                        marginLeft: getBorderBottomOffsetLeft(iButton) + (iButton + 1) * 10,
                                    });
                                }}
                                onMouseOut={() => {
                                    setBottomBorderParams(prevBottomBorderParams);
                                }}
                                onClick={(e) => {
                                    onChangeTab(button.key);
                                    setBorderParams(iButton, {
                                        background: button.borderBottomColor,
                                        width: e.target.offsetWidth + 21,
                                        marginLeft: getBorderBottomOffsetLeft(iButton) + (iButton + 1) * 10,
                                    });
                                }}
                                style={{ marginLeft: 10 }}
                            >
                                <a onClick={(e) => e.preventDefault()}>{button.text}</a>
                            </li>
                        </Badge>
                    );
                })}
                <hr style={bottomBorderParams} />
            </ul>
        </div>
    );
};

export default CustomTabsButtons;
