import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Descriptions, Form, Input, message, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import { useApiEmpresaDomicilios, useEmpresaDomiciliosMutation } from "../../../hooks/api/empresas";

const useStyles = createUseStyles({
    domicilio: {
        minHeight: "100%",
        width: "100%",
        padding: 15,
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
    },
});

const EmpresaDomicilio = ({ emp_id }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const [domicilioPrincipal, setDomicilioPrincipal] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const { data: domicilios, refetch: refetchDomicilios } = useApiEmpresaDomicilios({
        id: emp_id,
    });
    const { mutateEmpresaDomicilios, resetEmpresaDomiciliosMutation } = useEmpresaDomiciliosMutation();

    const handleClickModificaDomicilio = () => {
        setIsEditing(true);
    };

    const handleClickGuardarDomicilio = () => {
        form.validateFields()
            .then((values) => {
                delete values.dom_principal;
                delete values.emp_id;
                if (domicilioPrincipal) {
                    values.dom_id = domicilioPrincipal.dom_id;
                }
                mutateEmpresaDomicilios(
                    { ...values, mutationType: domicilioPrincipal ? "update" : "create", emp_id, dom_principal: true },
                    {
                        onSuccess: (res) => {
                            if (res?.success) {
                                resetEmpresaDomiciliosMutation();
                                setIsEditing(false);
                                message.success(res.mensaje);
                            } else {
                                message.error("Hubo un problema al guardar el domicilio");
                            }
                        },
                        onError: () => {
                            message.error("Hubo un problema al guardar el domicilio");
                        },
                    }
                );
            })
            .catch(() => {
                message.error("Hubo un problema al guardar el domicilio");
            });
    };

    useEffect(() => {
        let domPrincipal = domicilios?.find((dom) => dom.dom_principal);
        setDomicilioPrincipal(domicilios?.find((dom) => dom.dom_principal) || null);
        if (domPrincipal) {
            form.setFieldsValue(domPrincipal);
        } else {
            form.setFieldsValue({
                dom_calle: null,
                dom_exterior: null,
                dom_interior: null,
                dom_cp: null,
                dom_localidad: null,
                dom_municipio: null,
                dom_estado: null,
                dom_pais: "México",
            });
        }
    }, [domicilios]);

    useEffect(() => {
        refetchDomicilios();
    }, [emp_id, refetchDomicilios])

    return (
        <Elevation className={styles.domicilio}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Domicilio</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    {!isEditing ? (
                        <Button type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={handleClickModificaDomicilio} />
                    ) : (
                        <Button type="primary" shape="circle" size="small" icon={<SaveOutlined />} onClick={handleClickGuardarDomicilio} />
                    )}
                </div>
            </Row>

            {!domicilioPrincipal && !isEditing && (
                <Alert message="Sin domicilio" description="Esta empresa aún no tiene un domicilio capturado" type="info" showIcon />
            )}

            {!isEditing && domicilioPrincipal ? (
                <Descriptions bordered>
                    <Descriptions.Item label="Calle">{domicilioPrincipal?.dom_calle}</Descriptions.Item>
                    <Descriptions.Item label="No. Exterior">{domicilioPrincipal?.dom_exterior}</Descriptions.Item>
                    <Descriptions.Item label="No. Interior">{domicilioPrincipal?.dom_interior}</Descriptions.Item>
                    <Descriptions.Item label="Colonia">{domicilioPrincipal?.dom_colonia}</Descriptions.Item>
                    <Descriptions.Item label="CP">{domicilioPrincipal?.dom_cp}</Descriptions.Item>
                    <Descriptions.Item label="Localidad">{domicilioPrincipal?.dom_localidad}</Descriptions.Item>
                    <Descriptions.Item label="Municipio">{domicilioPrincipal?.dom_municipio}</Descriptions.Item>
                    <Descriptions.Item label="Estado">{domicilioPrincipal?.dom_estado}</Descriptions.Item>
                    <Descriptions.Item label="Pais">{domicilioPrincipal?.dom_pais}</Descriptions.Item>
                </Descriptions>
            ) : null}

            {isEditing ? (
                <Form form={form}>
                    <Descriptions bordered>
                        <Descriptions.Item label="* Calle">
                            <Form.Item name="dom_calle" rules={[{ required: true, message: "La calle es requerida" }]}>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="* No. Exterior">
                            <Form.Item name="dom_exterior" rules={[{ required: true, message: "El no. exterior es requerido" }]}>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="No. Interior">
                            <Form.Item name="dom_interior">
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="* Colonia">
                            <Form.Item name="dom_colonia" rules={[{ required: true, message: "La colonia es requerida" }]}>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="* CP">
                            <Form.Item name="dom_cp" rules={[{ required: true, message: "El CP es requeridao" }]}>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Localidad">
                            <Form.Item name="dom_localidad">
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="* Municipio">
                            <Form.Item name="dom_municipio" rules={[{ required: true, message: "El municipio es requerido" }]}>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="* Estado">
                            <Form.Item name="dom_estado" rules={[{ required: true, message: "El estado es requerido" }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Select.Option value="Aguascalientes">Aguascalientes</Select.Option>
                                    <Select.Option value="Baja California">Baja California</Select.Option>
                                    <Select.Option value="Baja California Sur">Baja California Sur</Select.Option>
                                    <Select.Option value="Campeche">Campeche</Select.Option>
                                    <Select.Option value="Chiapas">Chiapas</Select.Option>
                                    <Select.Option value="Chihuahua">Chihuahua</Select.Option>
                                    <Select.Option value="CDMX">Ciudad de México</Select.Option>
                                    <Select.Option value="Coahuila">Coahuila</Select.Option>
                                    <Select.Option value="Colima">Colima</Select.Option>
                                    <Select.Option value="Durango">Durango</Select.Option>
                                    <Select.Option value="Estado de México">Estado de México</Select.Option>
                                    <Select.Option value="Guanajuato">Guanajuato</Select.Option>
                                    <Select.Option value="Guerrero">Guerrero</Select.Option>
                                    <Select.Option value="Hidalgo">Hidalgo</Select.Option>
                                    <Select.Option value="Jalisco">Jalisco</Select.Option>
                                    <Select.Option value="Michoacán">Michoacán</Select.Option>
                                    <Select.Option value="Morelos">Morelos</Select.Option>
                                    <Select.Option value="Nayarit">Nayarit</Select.Option>
                                    <Select.Option value="Nuevo León">Nuevo León</Select.Option>
                                    <Select.Option value="Oaxaca">Oaxaca</Select.Option>
                                    <Select.Option value="Puebla">Puebla</Select.Option>
                                    <Select.Option value="Querétaro">Querétaro</Select.Option>
                                    <Select.Option value="Quintana Roo">Quintana Roo</Select.Option>
                                    <Select.Option value="San Luis Potosí">San Luis Potosí</Select.Option>
                                    <Select.Option value="Sinaloa">Sinaloa</Select.Option>
                                    <Select.Option value="Sonora">Sonora</Select.Option>
                                    <Select.Option value="Tabasco">Tabasco</Select.Option>
                                    <Select.Option value="Tamaulipas">Tamaulipas</Select.Option>
                                    <Select.Option value="Tlaxcala">Tlaxcala</Select.Option>
                                    <Select.Option value="Veracruz">Veracruz</Select.Option>
                                    <Select.Option value="Yucatán">Yucatán</Select.Option>
                                    <Select.Option value="Zacatecas">Zacatecas</Select.Option>
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Pais">
                            <Form.Item name="dom_pais">
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            ) : null}
            {isEditing ? (
                <Row style={{marginTop: 10, justifyContent: "end"}}>
                    <Button type="primary" onClick={handleClickGuardarDomicilio}>
                        Guardar
                    </Button>
                </Row>
            ) : null}
        </Elevation>
    );
};

export default EmpresaDomicilio;
