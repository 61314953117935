import { Button, Form, Row, Select, Switch, message } from "antd";
import { createUseStyles } from "react-jss";
import { TipoAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";
import { useApiAreasDepartamentos } from "../../../hooks/api/areas_departamentos";
import { useEffect } from "react";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%"
    }
}))
const DividirTicketParams = ({
    closeParent,
    onDataCached,
    defaultParams
}) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { isLoading: isLoadingAreasDepartamentos, data: areasDepartamentos } = useApiAreasDepartamentos({ enabled: true, refetchOnWindowFocus: false });

    const handleClickAceptar = () => {
        form.validateFields().then(values => {
            onDataCached(values)
            closeParent();
        }).catch(() => message.error("Error de formulario"))
    }

    useEffect(() => {
        if (defaultParams)
            form.setFieldsValue(defaultParams)
    }, [defaultParams])

    return (
        <div className={styles.container}>
            <Form layout="vertical" form={form}>
                <Form.Item label="Mesa de servicio" name="mesaservicio" rules={[{ required: true, message: "La mesa de servicio es requerida !" }]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        loading={isLoadingAreasDepartamentos}
                    >
                        {!isLoadingAreasDepartamentos &&
                            (areasDepartamentos || []).filter(ade => ade.ade_tipo === TipoAreaDepartamento.DEPARTAMENTO).map((ade) => (
                                <Select.Option key={`areasdepartamentos-${ade.ade_id}`} value={ade.ade_id}>
                                    {ade.ade_nombre}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Tipo de movimiento" name="tick_tipo" rules={[{ required: true, message: "El tipo de movimiento es requerido !" }]}>
                    <Select>
                        <Select.Option value={1}>Ticket</Select.Option>
                        <Select.Option value={2}>Canalización</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Añadirme como observador" name="aniadirobservador" valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Form>
            <Row justify="end">
                <Button type="primary" onClick={handleClickAceptar}>Aceptar</Button>
            </Row>
        </div>
    );
}

export default DividirTicketParams;