import { Button, Form, Input, Row, Select, Spin, message } from "antd";
import { createUseStyles } from "react-jss";
import { useApiAcuerdosServicio } from "../../../hooks/api/acuerdos_servicio";
import { useGuardaNuevoUsoAcuerdoServicio } from "../../../hooks/api/tickets_acuerdos_servicio";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles(() => ({

}));


const CambiaTicketAcuerdoServicio = ({ ticket, closeParent }) => {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const { data: acuerdos } = useApiAcuerdosServicio({ initialData: [] });
    const { saveNewUsoAcuerdoServicio, isSavingUsoAcuerdoServicio } = useGuardaNuevoUsoAcuerdoServicio();

    const handleClickCancelar = () => {
        if (closeParent) closeParent();
    }

    const handleClickGuardarUsoAcuerdo = () => {
        form.validateFields().then(values => {
            //se mandan los datos al servidor
            saveNewUsoAcuerdoServicio({ ...values, tick_id: ticket?.tick_id }, {
                onSuccess: res => {
                    if (res.success) {
                        // queryClient.invalidateQueries(["tickets", `${ticket?.tick_id}`])
                        queryClient.refetchQueries(["tickets", `${ticket?.tick_id}`])
                        // queryClient.resetQueries(["tickets", `${ticket?.tick_id}`])

                        message.success(res.mensaje)
                        if (closeParent) closeParent();
                    } else {
                        message.error(res.mensaje)
                    }
                },  
                onError: () => {
                    message.error("Hubo un problema al agregar el nuevo uso de acuerdo.")
                }
            })
        }).catch(() => {
            message.error("Faltan campos requeridos")
        })
    }

    return (
        <div>
            <Spin spinning={isSavingUsoAcuerdoServicio}>
                <Form layout="vertical" form={form}>
                    <Form.Item label="Nuevo acuerdo de servicio" name="cacu_id" rules={[{ required: true, message: "El acuerdo es requerido" }]} >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children?.toLowerCase()?.includes(input.toLowerCase())}
                        >
                            {
                                acuerdos.map(acu => <Select.Option value={acu.cacu_id} key={`acuerdo_servicio${acu.cacu_id}}`}>{acu.cacu_nombre}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Motivo" name="tias_motivo" rules={[{ required: true, message: "El motivo es requerido" }]} >
                        <Input.TextArea showCount maxLength={300} />
                    </Form.Item>
                </Form>
                <Row style={{ justifyContent: "end", gap: 10, paddingTop: 5 }}>
                    <Button onClick={handleClickCancelar}>Cancelar</Button>
                    <Button type="primary" onClick={handleClickGuardarUsoAcuerdo}>Confirmar cambio</Button>
                </Row>
            </Spin>
        </div>
    );
}

export default CambiaTicketAcuerdoServicio;