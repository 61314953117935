import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import './styles.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const Grafica = ({data, title}) => {

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
            borderWidth: 1,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'right',
            },
            title: {
            display: true,
            text: `Registro por actividad ${title}`
            },
        },
    };

    return(
        <Bar data={data} options={options} />
    )

}

export default Grafica;