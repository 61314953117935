import { TipoPosicionAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";
import VerTickets from "../../Tickets/VerTickets";

const VentasTickets = () => {

    return (
        <VerTickets key={'ventas'} tipoPosicion={TipoPosicionAreaDepartamento.VENTAS}/>
    );
}

export default VentasTickets;