// @ts-nocheck
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetTicketsGraficaGeneral = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/tickets/grafica`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsGraficaGeneralMutation: mutation.mutate,
    };
}

export const GetTicketsGraficaUser = ()  => {
    const api = useApi();

    const mutate = async (id) => {
        const data  = await api.get(`/tickets/grafica?asesor=${id.id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsGraficaUserMutation: mutation.mutate,
    };
}
export const GetTicketsGraficaAreaDep = ()  => {
    const api = useApi();

    const mutate = async (id) => {
        const data  = await api.get(`/tickets/grafica?area_dep=${id.id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsGraficaAreaDepMutation: mutation.mutate,
    };
}

export const GetTicketsGraficaDate = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/tickets/grafica?fecha_init=${params.start}&fecha_final=${params.end}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsGraficaDateMutation: mutation.mutate,
    };
}
export const GetTicketsGraficaDateUser = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/tickets/grafica?asesor=${params.id}&fecha_init=${params.start}&fecha_final=${params.end}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsGraficaDateUserMutation: mutation.mutate,
    };
}
export const GetTicketsGraficaDateAreaDep = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/tickets/grafica?area_dep=${params.id}&fecha_init=${params.start}&fecha_final=${params.end}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsGraficaDateAreaDepMutation: mutation.mutate,
    };
}