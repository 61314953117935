import React, {useState} from "react";
import { Modal, Form, Input, Row, Col, Checkbox, Button, Upload, message, Space, Radio} from "antd";
import { ETipoProductoServicio } from "../../../../@enums/Producto-Servicio";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PostProductosServicios, UpdateProductosServicios } from "../../../../hooks/api/productos-servicios";
import moment from "moment";

const NewProductoServicio = ({openNew, setOpenNew, form, updateTable, isEdit, setIsEdit}) => {

    const {postProductosServiciosMutation} = PostProductosServicios();
    const {updateProductosServiciosMutation} = UpdateProductosServicios();

    const [valueTipo, setValueTipo] = useState(null);

    const onFinish = (values) => {
        const consideraciones = values.consideraciones.map(item => item.consideracion).join('/');
        let params = {
            pser_nombre: values.pser_nombre,
            pser_tipo: values.pser_tipo,
            pser_preciounitario : values.pser_preciounitario,
            pser_clave: values.pser_clave,
            pser_descripcion: values.pser_descripcion,
            pser_clavesat: values.pser_clavesat,
            pser_comercialcodigo: values.pser_comercialcodigo,
            pser_activo: 1,
            pser_consideraciones: consideraciones,
            pser_fecha: moment().format('YYYY/MM/DD'),
            pser_fechaactualizacion: moment().format('YYYY/MM/DD'),
            pser_muestreotiendacliente: 0
        }
        postProductosServiciosMutation(params, {
            onSuccess: (data) => {
                console.log(data)
                if(data.success){
                    message.success('Producto/servicio creado con éxito')
                    updateTable();
                    form.resetFields();
                    setOpenNew(false)
                }else{
                    message.error('No se ha podido guardar la información')
                    console.log(data)
                }
            },
            onError: (error) => {
                message.error('No se ha podido guardar la información')
                console.log(error)
            }
        })
    }

    const onFinishEdit = (values) => {
        const consideraciones = values.consideraciones.map(item => item.consideracion).join('/');
        let params = {
            pser_nombre: values.pser_nombre,
            pser_tipo: values.pser_tipo,
            pser_preciounitario : values.pser_preciounitario,
            pser_clave: values.pser_clave,
            pser_descripcion: values.pser_descripcion,
            pser_clavesat: values.pser_clavesat,
            pser_comercialcodigo: values.pser_comercialcodigo,
            pser_consideraciones: consideraciones,
            pser_fechaactualizacion: moment().format('YYYY/MM/DD'),
        }
        updateProductosServiciosMutation({id: values.pser_id, data: params}, {
            onSuccess: (data) => {
                if(data.success){
                    message.success('Producto/servicio actualizado con éxito')
                    updateTable();
                    form.resetFields();
                    setOpenNew(false);
                    setIsEdit(false)
                }else{
                    message.error('Error al actualizar, intente nuevamente')
                    form.resetFields();
                    setOpenNew(false);
                    setIsEdit(false);
                }
            },
            onError: (error) => {
                message.error('Error al actualizar, intente nuevamente')
                    form.resetFields();
                    setOpenNew(false);
                    setIsEdit(false);
            }
        })
    }

    const onCancel = () => {
        form.resetFields();
        setOpenNew(false)
        setIsEdit(false)
    }

    const onChangeTipo = (e) => {
        setValueTipo(e.target.value);
    };

    //FORMATOS INPUT

    const formPrice = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^0-9.]/g, '');
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };
    const formTextUpper = (fieldName, e) => {
        let newValue = e.target.value;
        newValue = newValue.toUpperCase();
    
        // Usar el nombre del campo dinámicamente
        const updatedValues = { [fieldName]: newValue };
        form.setFieldsValue(updatedValues);
    };

    return(
        <Modal
        title="Crear nuevo producto/servicio"
        open={openNew}
        onCancel={onCancel}
        destroyOnClose
        footer={null}
        width={600}
        >
            <Form form={form} onFinish={isEdit ? onFinishEdit : onFinish} layout="vertical">
                        <Row>
                            <Col span={17} style={{padding:5}}>
                                <Form.Item style={{ flex: 1 }} name="pser_id" label="Nombre de la actividad" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item style={{ flex: 1 }} name="pser_nombre" label="Nombre del producto/servicio" rules={[{ required: true, message: "El nombre es requerido." }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={7}  style={{padding:'5px 15px'}}>
                                <Form.Item style={{ flex: 1 }} name="pser_tipo" label="Tipo" rules={[{ required: true, message: "Seleccione un tipo" }]}>
                                    <Radio.Group onChange={onChangeTipo} value={valueTipo}>
                                        <Radio value={ETipoProductoServicio.PRODUCTO}>Producto</Radio>
                                        <Radio value={ETipoProductoServicio.SERVICIO}>Servicio</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} style={{padding:5}}>
                                <Form.Item style={{ flex: 1 }} name="pser_clave" label="Clave" rules={[{ required: true, message: "La clave es requerida." }]}>
                                    <Input onChange={(e) => formTextUpper('pser_clave', e)}/>
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{padding:5}}>
                                <Form.Item style={{ flex: 1 }} name="pser_clavesat" label="Clave SAT" rules={[{ required: true, message: "La clave SAT es requerida." }]}>
                                    <Input onChange={(e) => formTextUpper('pser_clavesat', e)}/>
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{padding:5}}>
                                <Form.Item style={{ flex: 1 }} name="pser_comercialcodigo" label="Código comercial" rules={[{ required: true, message: "El código es requerido." }]}>
                                    <Input onChange={(e) => formTextUpper('pser_comercialcodigo', e)}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16} style={{padding:5}}>
                                <Form.Item style={{ flex: 1 }} name="pser_descripcion" label="Descripción del producto/servicio" rules={[{ required: true, message: "La descripción es requerida." }]}>
                                    <Input.TextArea rows={1} />
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{padding:5}}>
                                <Form.Item style={{ flex: 1 }} name="pser_preciounitario" label="Precio unitario" rules={[{ required: true, message: "El precio es requerido" }]}>
                                    <Input onChange={(e) => formPrice('pser_preciounitario', e)}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.List name="consideraciones">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Row key={key} style={{marginTop:-10}}>
                                                        <Col span={22} style={{padding:5}}>
                                                            <Form.Item
                                                            {...restField}
                                                            name={[name, 'consideracion']}
                                                            rules={[{ required: true, message: 'Ingrese la consideración'}]}>
                                                                <Input placeholder="Consideración" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={2} style={{padding:5}}>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Col>
                                                    </Row>
                                                </>
                                                // <Space
                                                //     key={key}
                                                //     style={{
                                                //         display: 'flex',
                                                //         marginBottom: 8,
                                                //         width: '100%'
                                                //     }}
                                                //     align="baseline"
                                                // >
                                                //     <Form.Item
                                                //         {...restField}
                                                //         name={[name, 'consideracion']}
                                                //         rules={[{ required: true, message: 'Ingrese la consideración'}]}>
                                                //             <Input placeholder="Consideración" />
                                                //     </Form.Item>

                                                //     <MinusCircleOutlined onClick={() => remove(name)} />
                                                // </Space>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Agregar consideración
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>

                        <Row style={{display: 'flex', justifyContent:'flex-end'}}>
                            <Col span={4}>
                                <Button type="secondary"  onClick={onCancel}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col span={4}>
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
            </Form>
        </Modal>
    )

}

export default NewProductoServicio;