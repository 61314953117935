import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router";
import { setUICaption } from "../../redux/actions/app.actions";
import { BlockOutlined, QuestionCircleOutlined, BarChartOutlined, CalendarOutlined } from "@ant-design/icons";
import { IoTicketOutline } from "react-icons/io5";
import { setConfig } from "../../redux/actions/config.actions";
import { BiBrain } from "react-icons/bi";
import { BsDatabaseCheck } from "react-icons/bs";

const useStyles = createUseStyles((theme) => ({
    sider: {
        borderRight: "1px solid rgba(200,200,200, 0.3)",
        backgroundColor: "#fff",
        overflow: "auto",
        height: "calc(100vh - 64px)",
        position: "fixed",
        left: 0,
        "& .ant-layout-sider-children": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        "& .ant-menu": {
            fontSize: 14,
        },
        "& .ant-layout-sider-trigger": {
            backgroundColor: theme.primaryColorAcent,
        },
        "& .ant-menu-item-selected": {
            backgroundColor: "rgba(255,255,255,0.1) !important",
        },
        "& .ant-menu-item": {
            padding: 0,
            margin: 0,
            marginBottom: "0 !important",
        },
        "& .ant-menu-submenu-title": {
            margin: 0,
        },
        "& .ant-menu-vertical": {
            border: "none",
        }
    },
    popupSider: {
        "& ul": {
            padding: 0,
        },
        "&  li": {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    siderLogo: {
        width: "100%",
        padding: "20px 10px 10px 10px",
        margin: "0px 0 20px 0",
        display: "flex",
        alignItems: "center",
        "& .logo-nombre": {
            height: "18",
            width: "140px",
        },
        "& .logo": {
            height: "34px",
            width: "34px",
        },
    },
    collapsedMenuHeader: {
        backgroundColor: theme.primaryColor,
        color: "#fff",
        display: "flex",
        alignItems: "center",
        padding: 18,
    },
}));

const { Sider } = Layout;

const SideBar = () => {
    const styles = useStyles();
    const reactRouterLocation = useLocation();
    const { token } = useSelector((state) => state.auth);
    const { configuracion } = useSelector((state) => state);
    const [renderSider, setRenderSider] = useState(false);
    const [location, setLocation] = useState("");
    const dispatch = useDispatch();
    const [route, setRoute] = useState(null)

    useEffect(() => {
        setRenderSider(!!token);
        if (reactRouterLocation.pathname === "/home_menu") setRenderSider(false);
        setRoute(reactRouterLocation.pathname)
    }, [token, reactRouterLocation.pathname]);

    useEffect(() => {
        setLocation(reactRouterLocation.pathname.split("/").filter((dir) => dir !== ""));
    }, [reactRouterLocation.pathname]);

    const onCollapse = (collapsed) => {
        dispatch(setConfig({
            sideBar: {
                ...configuracion.sideBar,
                collapsed: collapsed
            }
        }));
    };

    const setNavbarTitleOnNavigate = (moduleTitle) => {
        dispatch(
            setUICaption({
                navbarTitle: moduleTitle,
            })
        );
    };

    return !renderSider ? null : (
        <Sider className={styles.sider} collapsible collapsed={configuracion?.sideBar.collapsed} onCollapse={onCollapse} collapsedWidth={60}>
            <div style={{ paddingTop: 10 }}>
                <Menu
                    defaultSelectedKeys={[location[location.length - 1]]}
                    defaultOpenKeys={[location.length > 1 ? location[0] : null]}
                    mode="inline"
                    theme="light"
                >
                    <Menu.Item key="tickets" icon={<IoTicketOutline />} onClick={() => setNavbarTitleOnNavigate("Tickets")}>
                        {
                            route.startsWith('/ventas') ?
                                <Link to="/ventas/tickets">Tickets</Link> :
                                <Link to="/tickets">Tickets</Link>
                        }
                    </Menu.Item>
                    <Menu.Item key="know-base" icon={<BiBrain style={{ fontSize: 15 }} />} >
                        {/* <Link to="/faqs">InfoHub</Link> */}
                        <Link to="/know-base">KnowBase</Link>
                    </Menu.Item>
                    {route.startsWith('/tickets') || route.startsWith('/ventas') || route ==='/actividades' ? (
                        <Menu.Item key="actividades" icon={<CalendarOutlined style={{ fontSize: 15 }} />} >
                            <Link to="/actividades">Calendario de Actividades</Link>
                        </Menu.Item>
                    ): null}
                    {/* <Menu.Item key="facturas" icon={<IoDocumentTextOutline />} onClick={() => setNavbarTitleOnNavigate("Facturas")}>
                        <Link to="/facturas">Facturas</Link>
                    </Menu.Item>
                    <Menu.Item key="pagos" icon={<MdPayment />} onClick={() => setNavbarTitleOnNavigate("Pagos")}>
                        <Link to="/pagos">Pagos</Link>
                    </Menu.Item>
                    <Menu.Item key="cotizaciones" icon={<AiFillDollarCircle />} onClick={() => setNavbarTitleOnNavigate("Cotizaciones")}>
                        <Link to="/cotizaciones">Cotizaciones</Link>
                    </Menu.Item> */}
                    <Menu.SubMenu key="catalogos" icon={<BlockOutlined />} title="Catálogos">
                        <Menu.Item key="14">
                            <Link to="/empresas">Empresas</Link>
                        </Menu.Item>
                        <Menu.Item key="15">
                            <Link to="/contactos">Contactos</Link>
                        </Menu.Item>
                        <Menu.Item key="/codigos_sesion">
                            <Link to="/codigos_sesion">Codigos de Sesión</Link>
                        </Menu.Item>
                        <Menu.Item key="9">
                            <Link to="/sistemas">Sistemas</Link>
                        </Menu.Item>
                        <Menu.Item key="10">
                            <Link to="/areas_departamentos">Areas y Deptos.</Link>
                        </Menu.Item>
                        <Menu.Item key="11">
                            <Link to="/acuerdos_servicio">Acuerdos de Servicio</Link>
                        </Menu.Item>
                        <Menu.Item key="12">
                            <Link to="/puestos">Puestos</Link>
                        </Menu.Item>
                        <Menu.Item key="13">
                            <Link to="/usuarios">Usuarios</Link>
                        </Menu.Item>
                        <Menu.Item key="18">
                            <Link to="/actividades_catalogo">Actividades</Link>
                        </Menu.Item>
                        <Menu.Item key="productos-servicios">
                            <Link to="administracion/productos-servicios">Productos y Servicios</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    {route.startsWith('/tickets')  || route.startsWith('/metricas')? (
                        <Menu.SubMenu key='metricas' icon={<BarChartOutlined />} title='Metricas'>
                            <Menu.Item key="20">
                                <Link to="/metricas">Metricas de Usuario</Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    ) : route.startsWith('/ventas') ? (
                        <Menu.SubMenu key='ventas' icon={<BarChartOutlined />} title='Ventas'>
                            <Menu.Item key="20">
                                <Link to="/ventas/licencias">Ventas de Licencias</Link>
                            </Menu.Item>
                            <Menu.Item key="21">
                                <Link to="/ventas/cartera_clientes">Cartera de Clientes</Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    ) : null}
                    {route.startsWith('/administracion') ? (
                        <Menu.SubMenu key="graficas" icon={<BarChartOutlined style={{ fontSize: 15 }} />} >
                            <Menu.Item key="/actividades_metricas">
                                <Link to="/actividades_metricas">Registro de Actividades</Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    ): null}
                    

                    {/* <Menu.SubMenu key="anuncios" icon={<NotificationOutlined />} title="Anuncios">
                        <Menu.Item key="anuncios">
                            <Link to="/anuncios">Anuncios</Link>
                        </Menu.Item>
                        <Menu.Item key="anuncios/crear">
                            <Link to="/anuncios/crear">Crear anuncio</Link>
                        </Menu.Item>
                    </Menu.SubMenu> */}
                </Menu>
            </div>
            <Menu style={{ with: "100%" }} mode="inline" selectable={false}>
                {/* <Menu.Item key="Soporte" icon={<BiSupport />}>
                    Soporte
                </Menu.Item> */}
            </Menu>
        </Sider >
    );
};

export default SideBar;
