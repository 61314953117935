import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Row, Space, Typography, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Elevation from '../../../components/Elevation';
import { useUsuariosMutation } from '../../../hooks/api/usuarios';

const useStyles = createUseStyles({
    container: {
        width: "100%",
        padding: 15,
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
        "& .ant-select-disabled": {
            "& .ant-select-selector": {
                background: "#fff !important"
            }
        },
        "& .ant-input-disabled": {
            backgroundColor: "#fff",
            color: "#777"
        }
    }
})




const EnCasoAccidenteForm = ({ usu, refetchUsuario }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm()
    const styles = useStyles();
    const { mutateUsuario, isLoadingUsuarioMutation, resetUsuarioMutation } = useUsuariosMutation();

    const handleClickModificaDomicilio = () => setIsEditing(true)

    const handleClickGuardarDomicilio = () => {
        form.validateFields()
            .then((values) => {
                mutateUsuario({
                    ...values,
                    usu_id: usu.usu_id,
                    mutationType: "update"
                }, {
                    onSuccess: (resp) => {
                        if (resp.success) {
                            message.success("Se actualizó el usuario")
                            // form.setFieldsValue({
                            //     usu_accidentenombre: usu?.usu_accidentenombre,
                            //     usu_accidentetelefono: usu?.usu_accidentetelefono,
                            //     usu_accidenteparentesco: usu?.usu_accidenteparentesco
                            // })
                            setIsEditing(false)
                            resetUsuarioMutation();
                        } else {
                            message.error("Hubo un problema al actualizar el usuario")
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al actualizar el usuario")
                    }
                })
            })
            .catch(() => {
                message.error("Hubo un problema al los datos");
            });
    };

    useEffect(() => {
        if (usu) {
            form.setFieldsValue({
                usu_accidentenombre: usu?.usu_accidentenombre,
                usu_accidentetelefono: usu?.usu_accidentetelefono,
                usu_accidenteparentesco: usu?.usu_accidenteparentesco
            })
        }
    }, [usu])


    return (
        <Elevation className={styles.container}>
            <Row style={{ marginBottom: 10, gap: 20, justifyContent: "space-between" }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>En caso de accidente</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    {!isEditing ? (
                        <Button type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={handleClickModificaDomicilio} />
                    ) : (
                        <Button type="primary" shape="circle" size="small" icon={<SaveOutlined />} onClick={handleClickGuardarDomicilio} />
                    )}
                </div>
            </Row>
            <Row>
                <Form layout='vertical' form={form} style={{ width: "100%" }} disabled={!isEditing}>
                    <Row >
                        <Form.Item label="Nombre" name="usu_accidentenombre" style={{ flex: 1 }}>
                            <Input />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Space style={{ flex: 1 }} size={16}>
                            <Form.Item label="Teléfono" name="usu_accidentetelefono" style={{ flex: 1 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Parentesco" style={{ flex: 1 }} name="usu_accidenteparentesco">
                                <Select>
                                    <Select.Option value={1}>Cuñado(a)</Select.Option>
                                    <Select.Option value={2}>Esposo(a)</Select.Option>
                                    <Select.Option value={3}>Hermano(a)</Select.Option>
                                    <Select.Option value={4}>Hijo(a)</Select.Option>
                                    <Select.Option value={5}>Madre</Select.Option>
                                    <Select.Option value={6}>Novio(a)</Select.Option>
                                    <Select.Option value={7}>Otro</Select.Option>
                                    <Select.Option value={8}>Padre</Select.Option>
                                    <Select.Option value={9}>Pareja</Select.Option>
                                    <Select.Option value={10}>Primo(a)</Select.Option>
                                    <Select.Option value={11}>Sobrino(a)</Select.Option>
                                    <Select.Option value={12}>Suegro(a)</Select.Option>
                                    <Select.Option value={13}>Tío(a)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Space>
                    </Row>
                </Form>
            </Row>
        </Elevation>
    );
}

export default EnCasoAccidenteForm;