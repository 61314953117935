import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import { Button, Modal, Row, Spin, Table, Tooltip, Typography, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from "../../../hooks/useModal";
import { useApiEmpresaGiros, useApiEmpresaRegimenes, useEliminarEmpresaregimen } from "../../../hooks/api/empresas";
import { useEffect } from "react";
import CrearEmpresaRegimen from "../RegimenesFiscales/CrearEmpresaRegimen";

const useStyles = createUseStyles(() => ({
    container: {
        padding: 15,
        flex: 1,
        height: "100%",
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    modalContainer: {

    }
}));

const EmpresaRegimenes = ({ empresa }) => {
    const styles = useStyles();
    const [modalCrearVisible, showCrear, closeCrear] = useModal();
    const { data: regimenes, isLoading: isLoadingRegimenes, refetch: refetchRegimenes } = useApiEmpresaRegimenes({ initialData: [], id: empresa?.emp_id })
    const { deleteEmpresaRegimen, isDeletingEmpresaRegimen } = useEliminarEmpresaregimen();

    const eliminarRegimen = (regimen) => {
        if (!empresa)
            throw Error("No se puede eliminar el regimen por que faltan datos de la empresa");

        deleteEmpresaRegimen({
            emp_id: empresa.emp_id,
            refi_id: regimen.refi_id
        },
            {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje)
                        refetchRegimenes();
                    } else {
                        message.error(res.mensaje)
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al eliminar el régimen fiscal")
                }
            }
        )
    }

    const renderAccionesTabla = (_, regimen) => (
        <div style={{ width: "100%", textAlign: "center" }}>
            <Tooltip title={"Eliminar régimen"}>
                <Button
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => eliminarRegimen(regimen)}
                />
            </Tooltip>
        </div>
    )

    useEffect(() => {
        if (empresa?.emp_id) {
            refetchRegimenes();
        }
    }, [empresa])

    const columns = [
        {
            title: "Regimen fiscal",
            render: (_, empresaRegimen) => empresaRegimen?.regimen?.refi_nombre
        },
        {
            title: "Clave SAT",
            render: (_, empresaRegimen) => empresaRegimen?.regimen?.refi_clave
        },
        {
            title: "Acciones",
            render: renderAccionesTabla
        }
    ];

    return (
        <Elevation className={styles.container}>
            <Spin spinning={isLoadingRegimenes}>
                <Row style={{ justifyContent: "space-between" }}>
                    <Typography.Title level={5}>Regimenes fiscales de la empresa</Typography.Title>
                    <div className="section-buttons" style={{ width: "auto" }}>
                        <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={showCrear} />
                    </div>
                </Row>
                <Row>
                    <Table dataSource={regimenes} columns={columns} style={{ width: "100%" }} />
                </Row>

                {/* modal para crear la licencia de la empresa */}
                <Modal title="Agregar Regimen fiscal" open={modalCrearVisible} footer={null} onCancel={closeCrear} destroyOnClose={true} width="700px">
                    <div className={styles.modalContainer}>
                        <CrearEmpresaRegimen
                            closeModal={closeCrear}
                            empresa={empresa}
                            empresaRegimenes={regimenes}
                        />
                    </div>
                </Modal>
            </Spin>

        </Elevation>
    );
}

export default EmpresaRegimenes;