export const styles = (theme) => ({
    formWrapper: {
        background: "#fff",
        // padding: 15,
        // border: "1px solid #eee",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .ant-space-item": {
            width: "100%",
        },
        "& form": {
            width: "100%",
        },
    },
});
