import { Button, Divider, Form, Input, Row, Select, Switch, message } from "antd";
import { checkMail } from "../../../helpers/form.helper";
import { useEffect, useState } from "react";
import { MaskedInput } from "antd-mask-input";
import { useContactoTelefonoMutation, useCreateNewContact } from "../../../hooks/api/contactos";
import { useHistory } from "react-router-dom";
import { TipoContactoMarcacion } from "../../../@enums/Contactos.enums";
import ContactoHorarios from "../DatosContacto/ContactoHorarios";
import { useQueryClient } from "react-query";
// @ts-ignore
import LADAS_INTERNACIONALES from "../../../@static_data/ladas-internacionales.json";


/**
 * @typedef INuevoContactoProps
 * @property {Function} [newContactSaved] - new contact saved callback
 */

/**
 * 
 * @param {INuevoContactoProps} props
 * @returns 
 */
const NuevoContacto = ({ newContactSaved }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [tipoTelefono, setTipoTelefono] = useState(1);
    const [horarios, setHorarios] = useState([]);
    const queryClient = useQueryClient();
    const [mailError, setMailError] = useState({
        message: null,
        status: null,
    });
    const {
        createContact,
        isCreatingContact
    } = useCreateNewContact();

    const validateMail = async () => {
        const mail = form.getFieldValue("usu_mail");
        let mailExists = await checkMail(mail);
        setMailError({
            message: mailExists ? "El correo ya existe" : null,
            status: mailExists ? "error" : null,
        });
        return mailExists;
    }

    const handleClickGuardarContacto = () => {
        // take the data from the form
        form.validateFields()
            .then(async data => {
                let mailExists = await checkMail();

                if (mailExists) {
                    message.error("El correo ya existe");
                    return;
                }

                // add the phone data to the contact object
                let telefono = {
                    usc_numero: data.usu_tel,
                    usc_tipo: data.usc_tipo,
                    usc_extension: data.usc_extension,
                    usc_lada: data.usc_lada,
                    usc_usawhatsapp: data.usc_usawhatsapp
                }
                data.telefono = telefono;
                // clear not needed field from horarios and format the data
                data.horarios = horarios.map(h => {
                    delete h.usho_id;
                    h.usho_horainicial = h.usho_horainicial.format("HH:mm:ss");
                    h.usho_horafinal = h.usho_horafinal.format("HH:mm:ss");
                    return h;
                });
                // add the horarios to the contact object
                data.horarios = horarios;

                // clear not needed data
                delete data.usc_extension;
                delete data.usc_usawhatsapp;
                delete data.usc_tipo;

                // se envian los datos al api
                createContact(data, {
                    onSuccess: async res => {
                        if (res.success) {
                            // se limpia el query de horarios del contacto
                            setHorarios([]);
                            await queryClient.invalidateQueries(["contacto", "horarios"]);
                            // se limpia el formulario
                            form.resetFields();

                            message.success("Contacto creado correctamente");
                            //se valida si se recibe el callback de contacto creado
                            if (newContactSaved) {
                                //se agrega el id del contacto al objeto data
                                data = {
                                    ...data,
                                    usu_id: res.data
                                }
                                // se ejecuta el callback de contacto creado
                                newContactSaved(data);
                            } else {
                                // se redirecciona a la pantalla del contacto creado
                                history.push(`/contactos/${res.data}`);
                            }
                        } else
                            message.error(res.mensaje);
                    },
                    onError: () => {
                        message.error("Error al crear el contacto");
                    }
                })

            }).catch(error => {
                message.error("Error al obtener los datos del formulario");
            });
    }

    const handleBlurMail = async () => {
        await validateMail();
    }

    const handleChangeTipoTelefono = (value) => {
        setTipoTelefono(value);
    }

    const onCreateContactoHorario = (horario) => {
        setHorarios([...horarios, horario]);
    }

    const handleDeleteContactoHorario = (horario) => {
        setHorarios(horarios.filter(h => h.usho_id !== horario.usho_id));
    }

    useEffect(() => {
        form.setFieldValue("usc_tipo", tipoTelefono);
        form.setFieldValue("usc_lada", 142);
    }, [isCreatingContact]);

    useEffect(() => {
        queryClient.setQueryData(["contacto", "horarios"], []);
    }, []);

    return (
        <div>
            <Row>
                <Form
                    form={form}
                    layout="vertical"
                    style={{
                        width: "100%"
                    }}
                >
                    <Form.Item label="Nombre" name="usu_nombres" rules={[{ required: true, message: "El nombre es requerido" }]} >
                        <Input />
                    </Form.Item>
                    <Row style={{
                        gap: 20,
                    }}>
                        <Form.Item
                            label="Apellido paterno"
                            name="usu_apaterno"
                            rules={[{ required: true, message: "El apellido paterno es requerido" }]}
                            style={{
                                flex: 1
                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Apellido materno"
                            name="usu_amaterno"
                            rules={[{ required: true, message: "El apellido materno es requerido" }]}
                            style={{
                                flex: 1
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Row>
                    <Form.Item
                        label="Correo"
                        name="usu_mail"
                        hasFeedback={true}
                        validateStatus={mailError.status}
                        help={mailError.message}
                        rules={[
                            { required: true, message: "El correo es requerido" },
                            { type: "email", message: "El correo no es válido" }
                        ]}
                    >
                        <Input
                            type="email"
                            onBlur={handleBlurMail}
                        />
                    </Form.Item>
                    {/* <Form.Item label="Teléfono" name="usu_tel" rules={[{ required: true, message: "El teléfono es requerido" }]} >
                        <Input />
                    </Form.Item> */}
                    <Divider orientation="left" >Teléfono</Divider>
                    <Row style={{
                        gap: 20,
                        width: "100%"
                    }}>
                        <Form.Item
                            name="usc_tipo"
                            label="Tipo"
                            rules={[{ required: true, message: "El tipo es requerido" }]}
                            style={{
                                flex: 0.5
                            }}
                        >
                            <Select onChange={handleChangeTipoTelefono}>
                                {
                                    Object.keys(TipoContactoMarcacion).map(tipo => <Select.Option value={TipoContactoMarcacion[tipo]} key={`tipoContactoMarcacion_${tipo}`}>{tipo}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Lada"
                            name="usc_lada"
                            rules={[{ required: true, message: "La lada es requerida" }]}
                            style={{
                                flex: 0.5
                            }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.join(" ").toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    LADAS_INTERNACIONALES.map(lada => <Select.Option value={lada.id} key={`lada_${lada.id}`}>{lada.name} (+{lada.lada})</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Teléfono"
                            name="usu_tel"
                            rules={[{ required: true, message: "por favor ingresa tu teléfono!" }]}
                            style={{
                                flex: 1
                            }}
                        >
                            <MaskedInput mask={"(000)000-0000"} />
                        </Form.Item>
                    </Row>
                    {tipoTelefono === 2 ? (
                        <Form.Item
                            name="usc_extension"
                            label="Extensión"
                        >
                            <Input />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="usc_usawhatsapp"
                            label="¿Usa Whatsapp?"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    )}
                </Form>
                <ContactoHorarios
                    style={{ boxShadow: "none" }}
                    useApi={false}
                    onCreateContactoHorario={onCreateContactoHorario}
                    onDeleteHorario={handleDeleteContactoHorario}
                />
            </Row>
            <Row justify={"end"}>
                <Button
                    type="primary"
                    onClick={handleClickGuardarContacto}
                >Guardar</Button>
            </Row>
        </div>
    );
}

export default NuevoContacto;