import { Button, Form, Input, Row, Select, Space, Typography, message } from "antd";
import { createUseStyles } from "react-jss";
import { useApiUsuarios } from "../../../hooks/api/usuarios";
import { useApiEmpresas, useEmpresasMutation, useGetPdfConstanciaEmpresaInfo } from "../../../hooks/api/empresas";
import { useHistory } from "react-router-dom";
import { CloseCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { useState } from "react";
import RecursosManager from "../../Recursos/RecursosManager";
import VisorConstanciaFiscal from "../VisorConstanciaFiscal";
import { TipoPosicionAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";

const useStyles = createUseStyles(() => ({
    container: {
        padding: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .ant-form-item": {
            flex: 1,
        },
    }
}));

/**
 * 
 * @param {Object} props 
 * @param {Function} [props.closeParent] - funcion para cerrar el contenedor donde se esta mostrando el componente
 * @param {Function} [props.onCreateEmpresa] - callback que se ejecuta solo cuando la empresa fue creada exitosamente
 * @param {Boolean} [props.redirectToEmpresa] - bandera que indica si se redireccionara a la empresa creada
 * @returns 
 */
const CrearEmpresa = ({ closeParent, onCreateEmpresa, redirectToEmpresa }) => {
    const history = useHistory();
    const styles = useStyles();
    const [form] = Form.useForm();
    const { data: usuarios } = useApiUsuarios({ initialData: [] }, { puestoId: TipoPosicionAreaDepartamento.VENTAS });
    const { mutateEmpresa, resetEmpresaMutation } = useEmpresasMutation();
    const { data: empresas } = useApiEmpresas({ initialData: [], params: { include_seller_data: true }});
    const [subirContancia, setSubirConstancia] = useState(false);
    const [constanciaInfo, setConstanciaInfo] = useState(null);
    const { getConstanciaEmpresaInfo, isGettingConstanciaEmpresaInfo } = useGetPdfConstanciaEmpresaInfo();

    const handleGuardarEmpresa = () => {
        form
            .validateFields()
            .then((values) => {
                values.emp_rfc = values.emp_rfc.toUpperCase();
                mutateEmpresa(
                    {
                        mutationType: "create",
                        ...values,  
                        emp_tipo: 1,
                    },
                    {
                        onSuccess: (res) => {
                            if (res.success) {
                                form.resetFields();
                                resetEmpresaMutation();
                                if (closeParent)
                                    closeParent();
                                if (onCreateEmpresa)
                                    onCreateEmpresa(res.data)
                                message.success(res.mensaje);
                                if (redirectToEmpresa)
                                    history.push(`/empresas/${res.data}`);
                            } else {
                                message.error(res.mensaje);
                            }
                        },
                        onError: () => {
                            message.error("Hubo un  problema al guardar la empresa");
                        },
                    }
                );
            })
            .catch(err => {
                console.log(err);
                message.error("Aún hay campos faltantes");
            });
    };

    const rfcValidator = rfc => {
        rfc = rfc.toUpperCase();
        return new Promise((resolve, reject) => {
            try {
                // if (/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(rfc))
                if (/[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:[A-Z\d]{3})/.test(rfc) && (rfc.length >= 12 && rfc.length <= 13))
                    resolve(true);
                else reject(false);
            } catch (error) {
                reject(error)
            }
        });
    }

    const handleChangeFiles = (files) => {
        if (files.length > 0) {
            let fd = new FormData();
            fd.append("files[]", files[0], files[0].fileName);
            getConstanciaEmpresaInfo(fd, {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje);
                        setConstanciaInfo(res.data);
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("Hubo un error al extraer los datos de la constancia");
                }
            })
        }
    }

    const handleClickSubirConstancia = () => {
        setSubirConstancia(!subirContancia);
    }

    const handleSaveDatosConstancia = () => {
        // se valida si es que ya hay informacion de la constancia para enviar al servidor
        if (constanciaInfo) {
            // se crea la empresa con los datos de la constancia fiscal
            let values = {
                emp_razonsocial: constanciaInfo?.razon_social || null,
                emp_nombrecomercial: constanciaInfo?.nombre_comercial || null,
                emp_rfc: constanciaInfo?.rfc || null,
                emp_codigo: constanciaInfo?.rfc || null,
                constancia: constanciaInfo
            }
            values.emp_rfc = values.emp_rfc.toUpperCase();
            mutateEmpresa(
                {
                    mutationType: "create",
                    ...values,
                    emp_tipo: 1,
                },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            form.resetFields();
                            resetEmpresaMutation();
                            if (closeParent)
                                closeParent();
                            if (onCreateEmpresa)
                                onCreateEmpresa(res.data);
                            message.success(res.mensaje);
                            if (redirectToEmpresa)
                                history.push(`/empresas/${res.data}`);
                        } else {
                            message.error(res.mensaje);
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al guardar la empresa");
                    },
                }
            );
        } else {
            throw new Error("No se ha encontrado informacion de la constancia fiscal");
        }
    }

    return (
        <div className={styles.container}>
            {
                !constanciaInfo &&
                <Row style={{
                    paddingTop: constanciaInfo ? 0 : 10,
                    marginBottom: constanciaInfo ? 0 : 10,
                    justifyContent: "end",
                    borderBottom: "1px solid #eee",
                    paddingBottom: constanciaInfo ? 0 : 10,
                    paddingRight: 10,
                }}
                >
                    <Button
                        type={"primary"}
                        icon={subirContancia ? <CloseCircleOutlined /> : <FileAddOutlined />}
                        onClick={handleClickSubirConstancia}
                        size="small"
                        shape="round"
                        style={{ height: 25 }}
                    >
                        {subirContancia ? "Cancelar" : "Subir constancia"}
                    </Button>
                </Row>
            }
            {
                subirContancia ?
                    (!constanciaInfo ?
                        <div style={{ margin: 20 }}>
                            <RecursosManager
                                allowDeleteFile
                                allowAddFiles
                                onChangeFiles={handleChangeFiles}
                                limit={1}
                                allowedExtensions={["pdf"]}

                            />
                        </div>
                        :
                        <VisorConstanciaFiscal constanciaInfo={constanciaInfo} onSaveDatosConstancia={handleSaveDatosConstancia} />
                    ) :
                    <div style={{ padding: "20px 20px 10px 20px" }}>
                        <Form layout="vertical" form={form}>
                            <Row>
                                <Space style={{ flexDirection: "column", width: "100%" }}>
                                    <div style={{ width: "100%", display: "flex", gap: 16 }}>
                                        <Form.Item
                                            name="emp_codigo"
                                            label="Código"
                                            rules={[
                                                { required: true, message: "El código es requerido" },
                                                { max: 15, message: "Máximo 15 caracteres" },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Razon Social"
                                            name="emp_razonsocial"
                                            rules={[{ required: true, message: "La razón social es requerida" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", gap: 16 }}>
                                        <Form.Item label="Vendedor" name="emp_vendedor">
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {usuarios?.map((usuario, iUsuario) => (
                                                    <Select.Option value={usuario.usu_id} key={`${usuario.usu_nombres}-${iUsuario}`}>
                                                        {usuario.usu_nombres} {usuario.usu_apaterno} {usuario.usu_amaterno}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Nombre comercial" name="emp_nombrecomercial">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", gap: 16 }}>
                                        <Form.Item label="Alias" name="emp_alias">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Corporativo" name="emp_padreid">
                                            {empresas && (
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {empresas.map((empresa, iEmpresa) => (
                                                        <Select.Option value={empresa?.emp_id} key={`${empresa?.emp_razonsocial}-${iEmpresa}`}>
                                                            {empresa?.emp_razonsocial}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <Form.Item label="RFC" name="emp_rfc" rules={[
                                        {
                                            required: true, message: "El rfc es requerido!"
                                        },
                                        {
                                            message: "El rfc es invalido", validator: (_, value) => rfcValidator(value)
                                        }
                                    ]}>
                                        <Input style={{ textTransform: "uppercase" }} />
                                    </Form.Item>
                                </Space>
                            </Row>
                        </Form>
                        <Row style={{ justifyContent: "end" }}>
                            <Button
                                type="primary"
                                onClick={handleGuardarEmpresa}
                            // style={{ marginBottom: 20 }}
                            >
                                Guardar empresa
                            </Button>
                        </Row>
                    </div>
            }

        </div>
    );
}

export default CrearEmpresa;