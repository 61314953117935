import React from 'react';
import { Row, Col, Typography } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    pageWrapper: {
        paddingTop: 10,
        paddingRight: 20,
        paddingLeft: 20,
    },
});

const PageWrapper = ({ title, children }) => {
    const styles = useStyles();
    return (
        <Row className={styles.pageWrapper} style={{ paddingTop: 20, paddingBottom: 40 }}>
            <Col span={24}>
                <Row>
                    <Typography.Title level={3}>{title}</Typography.Title>
                </Row>
                {children}
            </Col>
        </Row>
    );
};

export default PageWrapper;
