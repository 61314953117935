import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Manager, Socket } from "socket.io-client";
import { ChatMessageDto } from "../@types/Chat.types";
import { EMessageType } from "../@enums/Conversaciones.enum";

export const useChat = () => {
    const chatNamespace = useRef("/");
    const { chatHost, sToken, user } = useSelector((state) => ({
        chatHost: state.app.chatHost,
        sToken: state.auth.token,
        user: state.auth.user
    }));
    const messagesPage = useRef(1);
    /**
     * @type {[Manager, React.Dispatch<React.SetStateAction<Manager>>]}
     */
    const [ioManager, setIOManager] = useState(null);
    /**
     * @type {[Socket, React.Dispatch<React.SetStateAction<Socket>>]}
     */
    const [socket, setSocket] = useState(null);
    /**
     * @type {[Number, React.Dispatch<React.SetStateAction<Number>>]}
     */
    const [conversation, setConversation] = useState(null);
    /**
     * @type {[ChatMessageDto[], React.Dispatch<React.SetStateAction<ChatMessageDto[]>>]}
     */
    const [messages, setMessages] = useState([]); // [ChatMessageDto]

    const initManager = () => {
        const manager = new Manager(`${chatHost}/socket.io/socket.io.js`, {
            extraHeaders: {
                authorization: `Bearer ${sToken}`
            },
        })
        setIOManager(manager);
        return manager;
    }

    /**
     * 
     * @param {Number} conv_id - id of the conversation in the database
     */
    const setChat = (conv_id, cb) => {
        // if the manager is not initialized, initialize it
        let manager = ioManager;
        if (!manager) {
            manager = initManager();
        }

        // set the conversation id
        setConversation(conv_id);
        // close previous socket connection
        if (socket) {
            socket.removeAllListeners();
            socket.close();
        }
        // reset the page number
        messagesPage.current = 1;
        // reset the messages
        setMessages([]);
        // create a new socket connection
        const newSocket = manager.socket(chatNamespace.current);
        !newSocket.connected && newSocket.connect();
        // init the chat room
        newSocket.emit("init-room", { room: conv_id });
        // listen for new messages
        newSocket.on("new-message", (message) => {
            // validate if the message is from the current user
            if (message.usu_id === user.usu_id) return;
            setMessages((messages) => [...messages, {
                ...message,
                type: message.usu_id === user.usu_id ? EMessageType.SENT : EMessageType.RECEIVED
            }]);
        });
        // set the socket connection
        setSocket(newSocket);
        setTimeout(() => {
            // get first page of messages
            newSocket.emit("get-messages-page", { page: 1, room: conv_id }, (response) => {
                setMessages(response.map((message) => ({
                    ...message,
                    type: message.usu_id === user.usu_id ? EMessageType.SENT : EMessageType.RECEIVED
                })).reverse());
                cb && cb();
            });
        }, 300);
    }

    /**
     * 
     * @param {String} message - message to send 
     * @returns {Promise<Boolean>}
     */
    const sendMessage = (message) => {
        return new Promise((resolve, reject) => {
            if (!socket) reject("Chat no conectado");
            socket.emit("send-message", { message, token: sToken, room: conversation }, (response) => {
                if (response.error) reject(response.error);
                else resolve(true);
                // add the message to the messages list
                setMessages((messages) => [...messages, {
                    ...response,
                    type: response.usu_id === user.usu_id ? EMessageType.SENT : EMessageType.RECEIVED
                }]);
            });
        });
    }

    /**
     * 
     * @param {Number} page - page number 
     * @returns {Promise<ChatMessageDto[]>}
     */
    const getMessagesPage = (page) => {
        return new Promise((resolve, reject) => {
            if (socket === null) reject("Chat no conectado");
            socket.emit("get-messages-page", { page, room: conversation }, (response) => {
                if (response.error) reject(response.error);
                else {
                    const newMessages = response.map((message) => ({
                        ...message,
                        type: message.usu_id === user.usu_id ? EMessageType.SENT : EMessageType.RECEIVED
                    })).reverse();
                    setMessages([...newMessages, ...messages]);
                    messagesPage.current = page;
                    resolve(newMessages);
                }
            });
        });
    }

    return {
        setChat,
        sendMessage,
        getMessagesPage,
        messages,
        messagesPage: messagesPage.current,
        chatNamespace: chatNamespace.current
    };
}