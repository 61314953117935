import { Divider, Row, Space } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { IoTicketOutline, IoFunnelOutline, IoBarChart} from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
// import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { GrDocumentTime } from "react-icons/gr";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import MenuOptionCard from "./MenuOptionCard";
import { createUseStyles } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import {Manager} from 'socket.io-client'
import { setSocket } from "../../redux/reducers/socket.users.reducer";
import './styles.css'
import { CodeOutlined } from "@ant-design/icons";

const useStyles = createUseStyles(theme => ({
    optionCard: {
        "& .animated": {
            "& path": {
                fill: "#000"
            },
            "& circle": {
                fill: "#000"
            }
        },
        "&:hover": {
            "& .animate-fade": {
                "& path": {
                    transition: "all 0.2s ease-in-out",
                    fill: `${theme.secondaryColor} !important`,
                    stroke: `${theme.secondaryColor} !important`
                },
                "& circle": {
                    transition: "all 0.2s ease-in-out",
                    fill: `${theme.secondaryColor} !important`,
                    stroke: `${theme.secondaryColor} !important`
                }
            },
            "& .animate": {
                "& path": {
                    transition: "all 0.2s ease-in-out",
                    stroke: `${theme.secondaryColor} !important`
                },
                "& circle": {
                    transition: "all 0.2s ease-in-out",
                    stroke: `${theme.secondaryColor} !important`
                }
            },
            "& .animateMetricas": {
                "& path": {
                    transition: "all 0.2s ease-in-out",
                    stroke: `${theme.secondaryColor} !important`,
                    fill: `${theme.secondaryColor} !important`
                },
                "& circle": {
                    transition: "all 0.2s ease-in-out",
                    stroke: `${theme.secondaryColor} !important`
                }
            }
        }
    },
    operacionesIcon: {
        "& path": {
            strokeWidth: 1.5
        }
    }
}));

const Home = () => {
    const styles = useStyles();
    const [animate, setAnimate] = useState(false);
    const ioManager = useRef(null);
    const socketQueue = useRef(null)
    // const socketUser = useSelector(() => store.store.getState().socketUser.socket);
    const { socketUser, socketUserApi, sToken } = useSelector((state) => ({
        socketUser: state.socketUser.socket,
        socketUserApi: state.app.usersQueue,
        sToken: state.auth.token,
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 200);
    }, []);

    useEffect(() => {
        if (!socketUser && socketUserApi) {
            ioManager.current = new Manager(`${socketUserApi}/socket.io/socket.io.js`, {
                extraHeaders: {
                    authorization: `Bearer ${sToken}`,
                },
            });
            socketQueue.current = ioManager.current.socket("/");
            console.log(socketQueue.current)

            socketQueue.current.on("connected-users", (data) => {
                sessionStorage.setItem('userConnected', data)
            });
            dispatch(setSocket(socketQueue.current));
        }
    }, [socketUser, dispatch, socketUserApi]);

    return (
        <PageWrapper title="Inicio">
            <Space size={30} style={{ display: "grid", width: "100%" }}>
                {/* <Row>
                    <Divider orientation="left">Soporte</Divider>
                </Row> */}
                <Row>
                    <Space
                        size={30}
                        style={{
                            width: "100%",
                            // flexWrap: 'wrap',
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
                        }}
                    >
                        <MenuOptionCard
                            data={{
                                title: "Tickets",
                                description: "Modulo de alta y seguimiento de tickets de soporte o servicio.",
                                icon: <IoTicketOutline fontSize={60} className={`animated ${animate ? "animate" : ""}`} />,
                                redirectTo: "/tickets",
                            }}
                        />
                        <MenuOptionCard
                            className={styles.optionCard}
                            data={{
                                title: "Administración",
                                description: "Modulo de configuraciones y aspectos generales del sistema.",
                                icon: <BiScatterChart fontSize={60} className={`animated ${animate ? "animate-fade" : ""}`} />,
                                redirectTo: "/administracion",
                            }}
                        />
                        <MenuOptionCard
                            className={styles.optionCard}
                            data={{
                                title: "Ventas",
                                description: "Módulo de herramientas de control de ventas y seguimiento de prospectos.",
                                // icon: <HiOutlineCurrencyDollar fontSize={60} className={`animated ${animate ? "animate" : ""}`} />,
                                icon: <IoFunnelOutline fontSize={60} className={`animated ${animate ? "animate" : ""}`} />,
                                redirectTo: "/ventas", 
                            }}
                        />
                        <MenuOptionCard
                            className={styles.optionCard}
                            data={{
                                title: "Operaciones",
                                description: "Módulo de operaciones y control de pólizas.",
                                icon: <GrDocumentTime fontSize={60} className={`animated ${styles.operacionesIcon} ${animate ? "animate" : ""}`}  />,
                                // redirectTo: "/tickets",
                            }}
                        />
                        <MenuOptionCard
                            className={styles.optionCard}
                            data={{
                                title: "Desarrollo",
                                description: "Modulo de alta y seguimiento de tickets del área de desarrollo.",
                                icon: <CodeOutlined className={`animated ${styles.operacionesIcon} ${animate ? "animate" : ""}`} style={{fontSize:45}} />,
                                redirectTo: "/desarrollo",
                            }}
                        />
                    </Space>
                </Row>
                {/* <Row>
                    <Divider orientation="left">Ventas</Divider>
                </Row>
                <Row>
                    <Space
                        size={30}
                        style={{
                            width: "100%",
                            // flexWrap: 'wrap',
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                        }}
                    >
                        <MenuOptionCard
                            data={{
                                title: "Otro modulo",
                                description: "modulo de prueba.",
                                icon: <TbUserSearch fontSize={60} />,
                                redirectTo: "#",
                            }}
                        />
                    </Space>
                </Row> */}
            </Space>
        </PageWrapper>
    );
};

export default Home;
