
export class NewTicketAgenteDto {
    /**
    * @property {Number} usu_id - id del usuario que va a ser tomado como agente
    * @type {Number}
    */
    usu_id = null;
    /**
    * @property {Number} tiag_id - id del tipo de agente
    * @type {Number}
    */
    tiag_id = null;
    /**
    * @property {Number} tick_id - id del ticket al que va a ser agregado el agente
    * @type {Number}
    */
    tick_id = null;
    /**
    * @property {Boolean} tica_usumodificaticket - indica si el agente tiene control sobre el ticket
    * @type {Boolean}
    */
    tica_usumodificaticket = false;
}