import { Rate } from "antd"


const redirect = (id, columnID) => {
    window.location.href=`/tickets?idUser=${id}&status=${columnID}`
}

const colums = [
    {
        title: 'Clave',
        dataIndex: 'clave',
        key: 'clave',
        sorter: (a, b) => a.clave.localeCompare(b.clave),
    },
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
        title: 'Ticket Actual',
        dataIndex: 'actual',
        key: 'actual',
        sorter: (a, b) => a.actual.localeCompare(b.actual),
    },
    {
        title: 'Calificacion',
        dataIndex: 'grade',
        key: 'grade',
        render: (grade) => (
            <div className="custom-rating">
              <Rate
                allowHalf
                disabled
                value={grade}
                style={{ color: 'blue', borderColor: 'blue' }}
              />
            </div>
          ),
          sorter: (a, b) => a.grade - b.grade,
    },
    {
        title: 'Tickets Cerrados',
        dataIndex: 'ticketsClose',
        key: 'ticketsClose',
        sorter: (a, b) => a.ticketsClose - b.ticketsClose,
    },
    {
        title: 'Tickets Cerrados con Éxito',
        dataIndex: 'ticketsCloseExit',
        key: 'ticketsCloseExit',
        sorter: (a, b) => a.ticketsCloseExit - b.ticketsCloseExit,
        onCell: (record) => ({
        onClick: () => {redirect(record.id, 4)},
        }),
    },
    {
        title: 'Tickets Cerrados sin Éxito',
        dataIndex: 'ticketsCloseNoExit',
        key: 'ticketsCloseNoExit',
        sorter: (a, b) => a.ticketsCloseNoExit - b.ticketsCloseNoExit,
        onCell: (record) => ({
            onClick: () => {redirect(record.id, 6)},
        }),
        
    },
    {
        title: 'Tickets Caducados',
        dataIndex: 'ticketsCaducados',
        key: 'ticketsCaducados',
        sorter: (a, b) => a.ticketsCaducados - b.ticketsCaducados,
        onCell: (record) => ({
            onClick: () => {redirect(record.id, 9)},
        }),
    },
    {
        title: 'Tiempo Promedio de Cierre',
        dataIndex: 'timeClose',
        key: 'timeClose',
        sorter: (a, b) => a.timeClose.localeCompare(b.timeClose),
    },
    {
        title: 'Canalizaciones',
        dataIndex: 'canalizaciones',
        key: 'canalizaciones',
        sorter: (a, b) => a.canalizaciones - b.canalizaciones,
    },
    {
        title: 'Canalizaciones Éxitosas',
        dataIndex: 'canalizacionesExitosas',
        key: 'canalizacionesExitosas',
        sorter: (a, b) => a.canalizacionesExitosas - b.canalizacionesExitosas,
    },
    {
        title: 'Canalizaciones sin Éxito',
        dataIndex: 'canalizacionesNoExito',
        key: 'canalizacionesNoExito',
        sorter: (a, b) => a.canalizacionesNoExito - b.canalizacionesNoExito,
    },
    {
        title: 'Colaboraciones',
        dataIndex: 'colabs',
        key: 'colabs',
        sorter: (a, b) => a.colabs - b.colabs,
    },
]
const dataSource = [
    {
        clave: 'CMFD',
        nombre: 'Nombre Asesor 1',
        status: 'Conectado',
        actual: 'TICK20230803062614586',
        time: 1,
        grade: 4,
        ticketsClose: 6,
        ticketsCloseExit: 5,
        ticketsCloseNoExit: 0,
        ticketsCaducados: 1,
        timeClose: '16 minutos',
        canalizaciones: 2,
        canalizacionesExitosas: 2,
        canalizacionesNoExito: 0,
        colabs: 1
    },    
    {
        clave: 'CFSD',
        nombre: 'Nombre Asesor 2',
        status: 'Desconectado',
        actual: 'TICK20230803062698586',
        time: 1,
        grade: 3,
        ticketsClose: 3,
        ticketsCloseExit: 2,
        ticketsCloseNoExit: 1,
        ticketsCaducados: 0,
        timeClose: '19 minutos',
        canalizaciones: 4,
        canalizacionesExitosas: 2,
        canalizacionesNoExito: 2,
        colabs: 0
    },    
    {
        clave: 'CLED',
        nombre: 'Nombre Asesor 3',
        status: 'Conectado',
        actual: 'TICK2023080306261696',
        time: 1,
        grade: 5,
        ticketsClose: 10,
        ticketsCloseExit: 9,
        ticketsCloseNoExit: 0,
        ticketsCaducados: 1,
        timeClose: '13 minutos',
        canalizaciones: 1,
        canalizacionesExitosas: 1,
        canalizacionesNoExito: 0,
        colabs: 4
    },    
    {
        clave: 'CFSW',
        nombre: 'Nombre Asesor 4',
        status: 'Desconectado',
        actual: 'TICK20230803062698586',
        time: 1,
        grade: 3,
        ticketsClose: 3,
        ticketsCloseExit: 2,
        ticketsCloseNoExit: 1,
        ticketsCaducados: 0,
        timeClose: '19 minutos',
        canalizaciones: 4,
        canalizacionesExitosas: 2,
        canalizacionesNoExito: 2,
        colabs: 0
    },   
    {
        clave: 'CFKB',
        nombre: 'Nombre Asesor 5',
        status: 'Desconectado',
        actual: 'TICK20230803065d58586',
        time: 1,
        grade: 3,
        ticketsClose: 3,
        ticketsCloseExit: 2,
        ticketsCloseNoExit: 1,
        ticketsCaducados: 0,
        timeClose: '19 minutos',
        canalizaciones: 4,
        canalizacionesExitosas: 2,
        canalizacionesNoExito: 2,
        colabs: 0
    },   
]
const hardData = [
    {
        title: 'Tiempos',
        data: [
            {
                value:1,
                title: 'Promedio de Primer Contacto del Mes',
                data: '12 minutos'
            },
            {
                value:2,
                title: 'Promedio de Primer Contacto del Día',
                data: '15 minutos'
            },
            {
                value:2,
                title: 'Promedio de Resolución',
                data: '40 minutos'
            }
        ]
    },
    {
        title: 'Procesos de Tickets',
        data: [
            {
                value:1,
                title: 'Del día',
                data: 12
            },
            {
                value:2,
                title: 'Promedio del Mes',
                data: 40
            },
            {
                value:3,
                title: 'En Revisión',
                data: 5
            },
            {
                value:4,
                title: 'En espera',
                data: 5
            },
        ]
    },
    {
        title: 'Cierres de Tickets',
        data: [
            {
                value:5,
                title: 'Totales',
                data: 10
            },
            {
                value:6,
                title: 'Cerrados con Éxito',
                data: 8
            },
            {
                value:7,
                title: 'Cerrados sin Éxito',
                data: 0
            },
            {
                value:8,
                title: 'Caducados',
                data: 2
            }
        ]
    }
]
const buttonsDate = [
    {
        id: 0,
        text: 'Hoy'
    },
    {
        id: 1,
        text: 'Semanal'
    },
    {
        id: 2,
        text: 'Mensual'
    },
]

const fixtures = () => {
    let fixture = {};
   
    fixture = {
        hardData,
        colums,
        dataSource,
        buttonsDate
    };
  
    return fixture;
  };
  
  export default fixtures;