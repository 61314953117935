/**
 * indica los tipos de recursos que se pueden guardar
 * @readonly
 * @enum {Number}
 */
export const EstatusUsuarioActividades = Object.freeze({
    EN_ESPERA: 0,
    EN_PROCESO: 1,
    TERMINADO: 2,
    CADUCADO: 3
});
export const PrioridadUsuarioActividades = Object.freeze({
    BAJA: 0,
    MEDIA: 1,
    ALTA: 2,
});
