import React, {useState, useEffect} from "react";
import './styles.css'
import Elevation from "../../../components/Elevation";
import { Spin, Input, Col, Row, Table, Button} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { motion } from "framer-motion";


const TablaCatalogo = ({openNew, setOpenNew, dataSource, dataFiltered, setDataFiltered, setFileList, form, setIsEdit}) => {

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'acti_nombre',
            key: 'acti_nombre',
            sorter: (a, b) => a.acti_nombre.localeCompare(b.acti_nombre),
        },
        {
            title: 'Fecha de creación',
            dataIndex: 'acti_fecha',
            key: 'acti_fecha',
            render: (text) => moment(text).format('DD-MM-YYYY'),
            sorter: (a, b) => moment(a.acti_fecha).unix() - moment(b.acti_fecha).unix(),
        },
        {
            title: 'Descripcion',
            dataIndex: 'acti_descripcion',
            key: 'acti_descripcion',
            sorter: (a, b) => a.acti_descripcion.localeCompare(b.acti_descripcion),
        },
    ]

    const [searchText, setSearchText] = useState('');

    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = dataSource.filter(item =>
          Object.values(item).some(val => val.toString().toLowerCase().includes(value.toLowerCase())
          )
        );
        setDataFiltered(filtered);
    };

    const onRowClick = (record) => {
        const acti = dataSource.find(act => act.acti_id === record.acti_id);
        let puestos = acti.puestos.length > 0 ? acti.puestos.map(pues => {return pues.pues_id}): []
        let filesExis = acti.recursos.length > 0 ? acti.recursos.map(recu => {
            return{
                uid: recu.recu_id,
                name: recu.recu_nombre,
                status: 'done',
                url: `http://192.168.100.22:3001/api/static/${recu.ruta}`,
            }
        }): [];
        setFileList(filesExis)

        let dataForm = {
            acti_id: acti.acti_id,
            acti_nombre: acti.acti_nombre,
            acti_descripcion: acti.acti_descripcion,
            pues_id: puestos
        }
        form.setFieldsValue(dataForm);
        setIsEdit(true);
        setOpenNew(true);
    };

    return(
        <Elevation style={{padding:10}}>
            <Spin tip="Cargando actividades ..." spinning={dataFiltered !== null && dataFiltered !== undefined ? false : true}>
                {dataFiltered !== null && dataFiltered !== undefined ? (
                    <>
                        <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]}}
                        style={{marginTop:10}}
                        >
                            <Row style={{marginBottom:18}}>
                                <Col span={2}>
                                    <Button type="primary" shape="circle" onClick={()=>setOpenNew(!openNew)} icon={<PlusOutlined  />}></Button>
                                </Col>
                                <Col span={3} offset={14}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <span className="textRegisters">{dataFiltered?.length} Registro(s)</span>
                                </Col>
                                <Col span={5}>
                                    <Input  placeholder="Buscar"  className="search_product" onChange={e => handleSearch(e.target.value)} />
                                </Col>
                            </Row>
                            <Table 
                            dataSource={dataFiltered} 
                            columns={columns}  
                            scroll={{ x: 'max-content' }} 
                            pagination={{pageSize:100, showSizeChanger:true, pageSizeOptions:[20,50,100,200], position: ["topRight"] }}  
                            className="table_activities"
                            onRow={(record) => ({
                                onClick: () => {
                                    onRowClick(record);
                                },
                            })}/>

                        </motion.div>
                    </>
                ):(<span>No existen datos</span>)}
            </Spin>
        </Elevation>
    )

}

export default TablaCatalogo;