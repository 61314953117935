import { Button, Menu, message, Modal, Row, Spin, Table, Typography } from "antd";
import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useParams } from "react-router-dom";
import Elevation from "../../components/Elevation";
import { useApiEmpresa, useGetPdfConstanciaEmpresaInfo, useGuardaDatosConstanciaEmpresa } from "../../hooks/api/empresas";
import EmpresaCamposExtra from "./EmpresaSections/EmpresaCamposExtras";
import EmpresaRedes from "./EmpresaSections/EmpresaRedes";
import EmpresaDomicilio from "./EmpresaSections/EmpresaDomicilio";
import EmpresaCard from "./EmpresaSections/EmpresaCard";
import EmpresaObservaciones from "./EmpresaSections/EmpresaObservaciones";
import EmpresaLicencias from "./EmpresaSections/EmpresaLicencias";
import { useEffect } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { PaperClipOutlined } from "@ant-design/icons";
import { useApiLayoutsMutation, useApiModuleLayout } from "../../hooks/layout";
import EmpresasCorporativo from "./EmpresaSections/EmpresasCorporativo";
import EmpresaContactos from "./EmpresaSections/EmpresaContactos";
import { useModal } from "../../hooks/useModal";
import FileDropper from "../../components/FileDropper";
import { useQueryClient } from "react-query";
import VisorConstanciaFiscal from "./VisorConstanciaFiscal";
import EmpresaGiros from "./EmpresaSections/EmpresaGiros";
import EmpresaRegimenes from "./EmpresaSections/EmpresaRegimenes";

const useStyles = createUseStyles((theme) => ({
    pageWrapper: {
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 40,
        width: "calc(100% - 100px) !important",
        "& .e-dashboardlayout.e-control.e-responsive": {
        },
        "& .e-panel": {
            zIndex: "0 !important",
            transition: "all 0.2s ease-in-out",
            "&:active": {
                border: "1px solid #d0d0d0 !important"
            },
            "&.editing": {
                border: `1px solid ${theme.secondaryColor}`,
                "&:hover": {
                    border: `1px solid ${theme.secondaryColor} !important`,
                    transform: "scale(1.01)",
                    cursor: "move"
                }
            }
        }
    },
    ultimosMovimientos: {
        flex: 1,
        padding: 10,
        height: "100%"
    },
    customPageHeader: {
        width: "calc(100% + 60px)",
        marginRight: "-60px",
        marginBottom: 10,
        padding: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    modalDatosConstancia: {
        "& .ant-modal-header": {
            border: "none"
        }
    }
}));


const Empresa = ({ emp_id }) => {
    let dashboardObj;
    const styles = useStyles();
    const theme = useTheme();
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [cargarConstanciaVisible, showCargarConstancia, closeCargarConstancia] = useModal();
    const [editingLayout, setIsEditingLayout] = useState(false);
    /** 
     * @type {[ContribuyenteConstanciaDto, React.Dispatch<React.SetStateAction<ContribuyenteConstanciaDto>>]} 
    */
    const [constanciaInfo, setConstanciaInfo] = useState(null);
    const { getConstanciaEmpresaInfo, isGettingConstanciaEmpresaInfo } = useGetPdfConstanciaEmpresaInfo();
    const { saveDatosConstanciaEmpresa, isSavingDatosConstanciaEmpresa } = useGuardaDatosConstanciaEmpresa();
    const [layoutConfig, setLayoutConfig] = useState({
        uico_id: null,
        cmod_id: null,
        paneles: []
    });
    const { data: empresa, refetch: refetchEmpresa, isFetched } = useApiEmpresa({
        id: emp_id || id,
        initialData: null,
    });
    const { data: layout, refetch: refetchLayout } = useApiModuleLayout({
        moduleName: "Empresa",
        initialData: layoutConfig,
    });
    const { mutateLayout, isLoadingLayoutMutation, resetLayoutMutation } = useApiLayoutsMutation();

    const movePanels = () => {
        for (let panel of layoutConfig.paneles) {
            dashboardObj.movePanel(panel.uipa_nombre, panel.uipa_posiciony, panel.uipa_posicionx);
            dashboardObj.resizePanel(panel.uipa_nombre, panel.uipa_spanx, panel.uipa_spany);
        }
    }

    const startEditLayout = () => setIsEditingLayout(true);
    const cancelEditiLayout = () => {
        movePanels();
        setIsEditingLayout(false);
    }
    const endEditLayout = () => {
        let paneles = dashboardObj.serialize().map(panel => ({
            uipa_id: layoutConfig.paneles.find(findingPanel => findingPanel.uipa_nombre === panel.id)?.uipa_id || null,
            uipa_nombre: panel.id,
            uipa_spanx: panel.sizeX,
            uipa_spany: panel.sizeY,
            uipa_posicionx: panel.col,
            uipa_posiciony: panel.row,
            uipa_visible: true
        }));
        // si ya existe un registro del layout se actualiza
        if (layoutConfig.uico_id) {
            let layout = { ...layoutConfig, paneles };
            mutateLayout(
                { ...layout, mutationType: "update" },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            setLayoutConfig({ ...layout, uico_id: res.data })
                            message.success(res.mensaje)
                        } else {
                            message.error("Ocurrió un problema al guardar el entorno")
                        }
                        resetLayoutMutation()
                    },
                    onError: () => {
                        message.error("Ocurrió un problema al guardar el entorno")
                    }
                })
        } else { // si no existe un registro del layout se crea
            let layout = {
                uico_id: null,
                cmod_nombre: "Empresa",
                paneles
            }
            mutateLayout(
                { ...layout, mutationType: "create" },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            setLayoutConfig({ ...layout, uico_id: res.data })
                            message.success(res.mensaje)
                        } else {
                            message.error("Ocurrió un problema al guardar el entorno")
                        }
                        resetLayoutMutation()
                    },
                    onError: () => {
                        message.error("Ocurrió un problema al guardar el entorno")
                    }
                })
        }
        setIsEditingLayout(false);
    }

    const handleChangeConstancia = (files) => {
        if (files.length > 0) {
            let fd = new FormData();
            fd.append("files[]", files[0], files[0].fileName);
            getConstanciaEmpresaInfo(fd, {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje);
                        setConstanciaInfo(res.data);
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("Hubo un error al extraer los datos de la constancia");
                }
            })
        }
    }

    const handleCloseDatosConstancia = () => {
        closeCargarConstancia();
        setConstanciaInfo(null);
    }

    const handleSaveDatosConstancia = () => {
        constanciaInfo.emp_id = empresa.emp_id;
        saveDatosConstanciaEmpresa(constanciaInfo, {
            onSuccess: res => {
                if (res.success) {
                    message.success(res.mensaje)
                    queryClient.invalidateQueries(["empresa"]);
                    queryClient.resetQueries(["empresa", "domicilios"], { exact: true })
                    handleCloseDatosConstancia();
                } else {
                    message.error(res.mensaje);
                }
            }, onError: () => {
                message.error("Hubo uin problema al cargar los datos de la constancia");
            }
        });
    }

    const ultimosMovimientosColumns = [
        {
            dataIndex: "fecha",
            title: "Fecha",
        },
        {
            dataIndex: "modulo",
            title: "Módulo",
        },
        {
            dataIndex: "operacion",
            title: "Operación",
        },
        {
            dataIndex: "asesor",
            title: "Asesor",
        },
    ];

    useEffect(() => {
        if (id) {
            refetchEmpresa()
        }
    }, [id])

    useEffect(() => {
        // cambiar posicion de un panel
        // dashboardObj.movePanel("id", rowToMove, ColToMove);
        // dashboardObj.resizePanel("id", sizeX, SizeY);
        movePanels();
    }, [dashboardObj, layoutConfig])

    useEffect(() => {
        if (layout) {
            delete layout.modulo;
            setLayoutConfig(layout)
        }
    }, [layout])

    return (
        <Row className={styles.pageWrapper} >
            <Modal
                title="Cargar constancia fiscal"
                open={cargarConstanciaVisible}
                footer={null}
                onCancel={handleCloseDatosConstancia}
                destroyOnClose={true}
                width={800}
                // centered
                bodyStyle={{
                    top: 30,
                    padding: 0,
                    maxHeight: 600, overflowY: "auto"
                }}
                className={styles.modalDatosConstancia}
            >
                <Spin spinning={isGettingConstanciaEmpresaInfo}>
                    {
                        constanciaInfo && <VisorConstanciaFiscal constanciaInfo={constanciaInfo} onSaveDatosConstancia={handleSaveDatosConstancia} />
                    }
                    {
                        !constanciaInfo &&
                        <div>
                            <FileDropper
                                onChange={handleChangeConstancia}
                                limit={1}
                                allowedExtensions={["pdf"]}
                            />
                        </div>
                    }
                </Spin>
            </Modal>

            <CustomPageHeader
                editingLayout={editingLayout}
                startEditLayout={startEditLayout}
                endEditLayout={endEditLayout}
                cancelEditiLayout={cancelEditiLayout}
                isLoadingLayoutMutation={isLoadingLayoutMutation}
            >
                <Button
                    type="primary"
                    style={{ display: "flex", alignItems: "center" }}
                    icon={<PaperClipOutlined style={{ fontSize: 14 }} />}
                    onClick={showCargarConstancia}
                >
                    Cargar constancia
                </Button>
            </CustomPageHeader>
            <div style={{
                display: "flex",
                width: "100%",
                gap: 20
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 400,
                    gap: 20
                }}>
                    <Elevation  >
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaCard key={emp_id} empresa={empresa} refetch={refetchEmpresa} />
                        </div>
                    </Elevation>
                    <Elevation  >
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaRedes empresa={empresa} />
                        </div>
                    </Elevation>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: 20
                }}>

                    <Elevation >
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaDomicilio emp_id={empresa?.emp_id} />
                        </div>
                    </Elevation>
                    <Elevation >
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaContactos empresa={empresa} refetchEmpresa={refetchEmpresa} />
                        </div>
                    </Elevation>
                    {
                        empresa?.emp_tipo === 2 &&
                        <Elevation >
                            <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <EmpresasCorporativo corporativo={empresa} />
                            </div>
                        </Elevation>
                    }
                    <Elevation>
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaCamposExtra emp_id={empresa?.emp_id} />
                        </div>
                    </Elevation>
                    <Elevation>
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaLicencias empresa={empresa} />
                        </div>
                    </Elevation>
                    <Row style={{ gap: 20 }}>
                        <Elevation style={{ flex: 1 }}>
                            <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <EmpresaGiros empresa={empresa} />
                            </div>
                        </Elevation>
                        <Elevation style={{ flex: 1 }}>
                            <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <EmpresaRegimenes empresa={empresa} />
                            </div>
                        </Elevation>
                    </Row>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            gap: 20,
                            width: "100%"
                        }}
                    >
                        <Elevation style={{ flex: 1 }} >
                            <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <Elevation className={styles.ultimosMovimientos}>
                                    <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                                        <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Últimos movimientos</Typography.Text>
                                    </Row>
                                    <Row>
                                        <Table dataSource={[]} columns={ultimosMovimientosColumns} style={{ width: "100%" }} />
                                    </Row>
                                </Elevation>
                            </div>
                        </Elevation>
                        <Elevation style={{ flex: 0.4 }}>
                            <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <EmpresaObservaciones empresa={empresa} />
                            </div>
                        </Elevation>
                    </div>
                </div>
            </div>
        </Row >
    );
};

const CustomPageHeader = ({ startEditLayout, children }) => {
    const styles = useStyles();

    const goBack = () => window.history.back();

    const headerMenu = (
        <Menu>
            <Menu.Item onClick={startEditLayout}>Editar entorno</Menu.Item>
        </Menu>
    );

    return (
        <div className={styles.customPageHeader}>
            <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <Button shape={"circle"} icon={<FiArrowLeft style={{ fontSize: 20, marginTop: 1 }} />} onClick={goBack} />
            </div>
            <div style={{ display: "flex", gap: 20 }}>
                <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Empresa;
