
"use strict"
import React from "react";
import DataTableDraggableHeader from "./DataTableDraggableHeader";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

/**
 * Enum for datatable component sort order
 * @readonly
 * @enum {String}
 */
export const DataTableSortOrder = Object.freeze({
    ASCEND: "ascend",
    DESCEND: "descend"
});

/**
 * @enum {String} DataTableSortOrder
 * @property {"ascend"} ASCEND
 */

/**
 * @callback DataTableColumnRender
 * @param {Object} indexValue - valor que es extraido del objeto de la fila haciendo referencia a {@link DataTableColumn.index}
 * @param {Object} rowData - Objeto de datos de la fila
 */

/**
 * @typedef DataTableColumn
 * @property {String} [index] - propiedad que se va a tomar del objeto de datos por fila
 * @property {String} title - Texto del encabezado que sera mostrado en la cabecera de la tabla
 * @property {Number} [width] - Minimo de ancho que tendra la columna
 * @property {DataTableColumnRender} [render] - callback que indica como se debe de renderizar cada celda de la columna
 * @property {DataTableSortOrder} [defaultSortOrder] - Orden por defecto de la columna
 */

/**
 * @callback onRowClick
 * @param {Object} record - objeto de datos de la fila seleccionada
 * @param {Object} rowIndex - indice de la fila seleccionada
 * @param {Event} e - evento emitido al seleccionar la fila
 */

/**
 * @typedef DataTableScrollProps
 * @property {{width: String | Number | true}} [x] - Ancho de la tabla
 * @property {String | Number} [y] - Alto de la tabla
 * @property {Boolean} [scrollToFirstRowOnChange] - Whether to scroll to the top of the table when paging, sorting, filtering changes
 */

/**
 * 
 * @param {Object} props
 * @param {DataTableColumn[]} props.columns - Arreglo de datos que se van a cargar en la tabla
 * @param {Object[]} props.data - Arreglo de datos que se van a cargar en la tabla
 * @param {onRowClick} [props.onRowClick] - callback que es ejecutado cuando se selecciona alguna fila de la tabla
 * @param {import("antd").TablePaginationConfig} [props.paginationParams]
 * @param {Boolean} [props.showPagination]
 * @param {Number} [props.page]
 * @param {Number} [props.pageSize]
 * @param {Number} [props.totalTickets]
 * @param {Object} [props.mappedColumns]
 * @param {Object} [props.config]
 * @param {Object} [props.onSearch]
 * @param {Object} [props.onClearFilters]
 * @param {Object} [props.draggableHeader]
 * @param {Object} [props.expandable]
 * @param {String} [props.className]
 * @param {DataTableScrollProps} [props.scroll]
 * @param {Object} [props.size]
 * @param {Object} [props.style]
 * @param {Function} [props.changePageSize]
 * @param {Function} [props.changePage]
 */
const DataTable = ({
    data,
    paginationParams,
    showPagination,
    page,
    pageSize,
    totalTickets,
    changePageSize,
    changePage,
    mappedColumns,
    columns,
    config,
    onSearch,
    onClearFilters,
    draggableHeader,
    expandable,
    className,
    scroll,
    size,
    style,
    onRowClick
}) => {
    return (
        <DndProvider backend={HTML5Backend} >
            <DataTableDraggableHeader
                showPagination = {showPagination}
                page = {page}
                pageSize = {pageSize}
                totalTickets = {totalTickets}
                changePage={changePage}
                changePageSize={changePageSize}
                paginationParams={paginationParams ? paginationParams : null}
                mappedColumns={mappedColumns}
                data={data}
                key="DataTableDraggableHeader"
                columns={columns}
                config={config}
                onSearch={onSearch}
                onClearFilters={onClearFilters}
                draggableHeader={draggableHeader}
                expandable={expandable}
                className={className}
                scroll={scroll}
                size={size}
                style={style}
                onRowClick={onRowClick}
            />
        </DndProvider>
    );
};

export default React.memo(DataTable);
