import React, { useEffect, useState } from "react";
import {
	MessageOutlined,
	PaperClipOutlined,
	PoweroffOutlined,
} from "@ant-design/icons";
import {
	Button,
	Divider,
	Image,
	message,
	Modal,
	Popover,
	Row,
	Space,
	Tooltip,
	Typography,
} from "antd";
import { createUseStyles, useTheme } from "react-jss";
import {
	useAgregarTicketAgente,
	useLiberarTicket,
	useTomarTicketMutation,
} from "../../../hooks/api/tickets";
import { useHistory } from "react-router-dom";
import { AiOutlineFile, AiOutlineFilePdf } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { LuUserPlus } from "react-icons/lu";
import { useSelector } from "react-redux";
import { Viewer } from "@react-pdf-viewer/core";
import { useModal } from "../../../hooks/useModal";
import { RiFileList3Line } from "react-icons/ri";
import AgregarObservador from "./AgregarObservador";
import { TipoTicketAgente } from "../../../@enums/TicketsAgentes.enums";
import { NewTicketAgenteDto } from "../../../@types/TicketsAgentes.types";
import { useQueryClient } from "react-query";
import { getResponsableActivo } from "../../../helpers/agentes.helper";
import { TicketStatus } from "../../../@enums/Tickets.enums";
import AppChat from "../../AppChat";
import { useCreateChat } from "../../../hooks/api/chats";
import { EConversationType } from "../../../@enums/Conversaciones.enum";

const useStyles = createUseStyles((theme) => ({
	problematicaWrapper: {
		borderBottom: "1px solid #eee",
		padding: "10px 10px 30px",
		"& .problematica-header": {
			justifyContent: "space-between",
			alignItems: "center",
			"& h5": {
				margin: 0,
			},
		},
	},
	text: {
		width: "100%",
		marginTop: 5,
		height: "120px",
		overflowY: "auto",
	},
	popOverArchivos: {
		"& .ant-popover-inner-content > div": {
			display: "flex",
			gap: 10,
			flexWrap: "wrap",
		},
	},
	popoverContent: {
		width: 400,
		maxWidth: 400,
		maxHeight: 400,
		overflowY: "auto",
	},
	fileItem: {
		flex: 1,
		padding: 16,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #eee",
		cursor: "pointer",
		transition: "all 0.2s ease-in-out",
		"&:hover": {
			// transform: "scale(1.03)",
			border: `1px solid ${theme.secondaryColor}`,
		},
	},
	modalChat: {
		top: 20,
		"& .ant-modal-body": {
			minHeight: "calc(98vh - 30px)",
			padding: 0,
		},
	},
}));

const ArchivoItem = ({ type, filename, src }) => {
	const styles = useStyles();
	const [show, showModal, closeModal] = useModal();

	const getIco = () => {
		if (type === "image/png" || type === "image/jpeg") {
			return <Image width={150} src={src} />;
		} else if (
			type === "application/vnd.ms-excel" ||
			type ===
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			return <SiMicrosoftexcel fontSize={32} color="#218450" />;
		} else if (type === "application/pdf") {
			return <AiOutlineFilePdf fontSize={32} color="#c20a0a" />;
		} else {
			return <AiOutlineFile fontSize={32} color="#888" />;
		}
	};

	const getPreviewComponent = () => {
		if (type === "image/png" || type === "image/jpeg") {
			return <Image width={150} src={src} />;
		} else if (
			type === "application/vnd.ms-excel" ||
			type ===
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			return <div>Previsualizador no disponible para este tipo de archivo</div>;
		} else if (type === "application/pdf") {
			return <Viewer fileUrl={src} />;
		} else {
			return <div>Previsualizador no disponible para este tipo de archivo</div>;
		}
	};

	const handleClickVisualizaRecurso = () => {
		if (type !== "image/png" && type !== "image/jpeg") showModal();
	};

	return (
		<div>
			<div className={styles.fileItem} onClick={handleClickVisualizaRecurso}>
				{getIco()}
				<Typography.Title level={5} style={{ margin: 0 }}>
					{filename}
				</Typography.Title>
			</div>
			<Modal
				footer={null}
				title={filename}
				visible={show}
				onOk={closeModal}
				onCancel={closeModal}
			>
				<div
					style={{
						border: "1px solid rgba(0, 0, 0, 0.3)",
						height: "750px",
					}}
				>
					{getPreviewComponent()}
				</div>
			</Modal>
		</div>
	);
};

const Problematica = ({ ticket, closeDatosTicket }) => {
	const theme = useTheme();
	const styles = useStyles();
	const [color, setColor] = useState("red");
	const queryClient = useQueryClient();
	const [observador, setObservador] = useState(null);
	const [disponibleObservador, setDisponibleObservador] = useState(false);
	const [responsable, setResponsable] = useState(null);
	const { mutateTomarTicket, isLoadingTomarTicketMutation } =
		useTomarTicketMutation();
	const { liberarTicket, isLoadingLiberarTicket } = useLiberarTicket();
	const [chatVisible, showChat, closeChat] = useModal();
	const [initialChat, setInitialChat] = useState(null);
	const [
		agregarObservadorVisible,
		showAgregarObservador,
		closeAgregarObservador,
	] = useModal();
	const history = useHistory();
	const user = useSelector((state) => state.auth.user);
	const { apiHost } = useSelector((state) => ({
		apiHost: state.app.apiHost,
		user: state.auth.user,
	}));
	const { agregarTicketAgente, isLoadingAgregarTicketAgente } =
		useAgregarTicketAgente();
	const { createChat, isCreatingChat } = useCreateChat();

	const handleClickTomarTicket = () => {
		if (ticket?.stat_id === TicketStatus.TICKET_LIBRE) {
			mutateTomarTicket(ticket?.tick_id, {
				onSuccess: (res) => {
					if (res.success) {
						message.success("Tomaste el ticket");
						setColor("#00A757");
						if (closeDatosTicket) closeDatosTicket();
						history.push(`/tickets/${ticket.tick_id}`);
					} else {
						message.error(res.mensaje);
					}
				},
				onError: () => {
					message.error("Hubo un problema al tomar el ticket");
				},
			});
		} else {
			// se valida si el usuario que esta realizando la accion es el responsable activo
			if (!responsable) {
				message.error("Acción no permitida");
				return;
			}

			liberarTicket(ticket?.tick_id, {
				onSuccess: (res) => {
					if (res.success) {
						message.success(res.mensaje);
						history.push(`/tickets`);
					} else {
						message.error(res.mensaje);
					}
				},
				onError: () => {
					message.error("Hubo un error al liberar el ticket");
				},
			});
		}
	};

	const agregarObservador = () => {
		// se agrega el agente como observador
		let agente = new NewTicketAgenteDto();
		agente.tiag_id = TipoTicketAgente.OBSERVADOR;
		agente.tick_id = ticket?.tick_id;
		agente.usu_id = user.usu_id;
		// se manda la solicitud para agregar el agente
		agregarTicketAgente(agente, {
			onSuccess: (res) => {
				if (res.success) {
					message.success(res.mensaje);
					// se invalidan los datos en react query para el ticket para volver a ser consultado
					queryClient.invalidateQueries(["tickets", `${ticket.tick_id}`]);
					if (closeAgregarObservador) closeAgregarObservador();
					setDisponibleObservador(false);
				} else {
					message.error(res.mensaje);
				}
			},
			onError: () => {
				message.error("Hubo un error al agregar al observador");
			},
		});
	};

	const handleClickObservador = () => {
		if (disponibleObservador) agregarObservador();
		else showAgregarObservador();
	};

	const handleClickOpenChat = () => {
		// find the contact of the ticket
		console.log(ticket.agentes)
		let contacto = ticket?.agentes?.find(
			(agente) => agente.tiag_id === TipoTicketAgente.CONTACTO
		);
		if (!contacto) {
			message.error("No se pudo encontrar el contacto del ticket");
			return;
		}
		// call the service to create the chat with the contact
		createChat(
			{
				user_list: [user.usu_id, contacto.usu_id],
				chat_type: EConversationType.CHAT_GENERAL,
			},
			{
				onSuccess: (res) => {
					if (res?.success || res?.error === "chat_exists") {
						if (res?.data) {
                            // delete the loged user from the user list
                            res.data.user_list = res.data.user_list.filter(u => u.usu_id !== user.usu_id);
                            // set the initial chat
                            setInitialChat(res.data);
                        }
						// show the chat
						showChat();
					} else {
						message.error(res?.mensaje);
					}
				},
				onError: () => {
					message.error("No se pudo inicializar el chat");
				},
			}
		);
	};

	const popOverContent = (
		<div className={styles.popoverContent}>
			<Divider orientation="left">Ticket</Divider>
			<Row style={{ margin: 0, padding: 0, gap: 12 }}>
				{ticket?.recursos?.map((recurso) => (
					<ArchivoItem
						key={`${recurso.recu_id}_recurso`}
						type={recurso.recu_mime}
						filename={recurso.recu_nombre}
						src={`${apiHost}/static/${recurso.recu_ruta}`}
					/>
				))}
			</Row>
			<Divider orientation="left">Incidencias</Divider>
			<Row style={{ margin: 0, padding: 0, gap: 12 }}>
				{ticket?.incidencias?.map((incidencia) => {
					return incidencia.recursos?.map((recurso) => (
						<ArchivoItem
							key={`${recurso.recu_id}_recurso`}
							type={recurso.recu_mime}
							filename={recurso.recu_nombre}
							src={`${apiHost}/static/${recurso.recu_ruta}`}
						/>
					));
				})}
			</Row>
		</div>
	);

	useEffect(() => {
		if (ticket?.stat_id > 1) {
			setColor("#00A757");
		} else {
			setColor("red");
		}

		if (ticket?.agentes) {
			// se busca que usuario logueado no sea el responsable del ticket
			let responsable = getResponsableActivo(ticket.agentes, user?.usu_id);
			// se busca si es que se tiene un observador
			let observador = ticket.agentes.find(
				(agente) => agente.tiag_id === TipoTicketAgente.OBSERVADOR
			);
			if (observador) setObservador(observador);

			if (!responsable && !observador) setDisponibleObservador(true);

			if (responsable) setResponsable(responsable);
		}
	}, [ticket]);

	return (
		<div className={styles.problematicaWrapper}>
			{/* modal agregar observador start */}
			<Modal
				title="Agregar observador"
				open={agregarObservadorVisible}
				onCancel={closeAgregarObservador}
				footer={null}
				width={600}
				destroyOnClose
			>
				<AgregarObservador
					closeAgregarObservador={closeAgregarObservador}
					ticket={ticket}
				/>
			</Modal>
			{/* modal reasignar ticket end */}

			{/* modal chat  */}
			<Modal
				open={chatVisible}
				footer={null}
				width={1200}
				destroyOnClose
				closable={false}
				className={styles.modalChat}
			>
				<AppChat closeParent={closeChat} conv_id={initialChat} />
			</Modal>
			{/* modal chat */}

			<Row className="problematica-header">
				<Typography.Title level={5}>Problemática</Typography.Title>
				<Space size={16}>
					<Tooltip title="Abir chat" placement="bottomRight">
						<Button
							type="dashed"
							shape="circle"
							onClick={handleClickOpenChat}
							icon={<MessageOutlined />}
							loading={isCreatingChat}
						/>
					</Tooltip>
					<Tooltip
						title={color === "#00A757" ? "Liberar ticket" : "Tomar ticket"}
						placement="bottomRight"
					>
						<Button
							type="dashed"
							shape="circle"
							icon={<PoweroffOutlined style={{ color }} />}
							onClick={handleClickTomarTicket}
							loading={isLoadingTomarTicketMutation || isLoadingLiberarTicket}
							style={{ backgroundColor: "#fff", borderColor: color }}
						/>
					</Tooltip>
					<Popover
						placement="bottomLeft"
						title={"Archivos adjuntos"}
						content={popOverContent}
						trigger="click"
						overlayClassName={styles.popOverArchivos}
					>
						<Tooltip title="Archivos adjuntos" placement="bottomRight">
							<Button
								type="dashed"
								shape="circle"
								icon={
									<PaperClipOutlined
										style={{
											color:
												ticket?.recursos?.length > 0
													? theme.secondaryColor
													: "#eee",
										}}
									/>
								}
								style={{
									borderColor:
										ticket?.recursos?.length > 0
											? theme.secondaryColor
											: "#eee",
								}}
							/>
						</Tooltip>
					</Popover>
					<Tooltip
						title={
							disponibleObservador
								? "Añadirme como observador"
								: !observador
								? "Agregar observador"
								: "ver observador"
						}
						placement="bottomRight"
					>
						<Button
							type="dashed"
							shape="circle"
							icon={<LuUserPlus style={{ color: "#000" }} />}
							onClick={handleClickObservador}
						/>
					</Tooltip>
					<Tooltip title="Pólizas" placement="bottomRight">
						<Button
							type="dashed"
							shape="circle"
							icon={<RiFileList3Line style={{ color: "#bbb" }} />}
						/>
					</Tooltip>
				</Space>
			</Row>
			<Row>
				<div
					dangerouslySetInnerHTML={{ __html: ticket?.tick_sintomas }}
					className={styles.text}
				/>
			</Row>
		</div>
	);
};

export default Problematica;
