import { SET_CONFIG } from "../action_types/config.action.types";

export const setConfig = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_CONFIG,
            payload,
        });
    };
};
