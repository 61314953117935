import { Button, Checkbox, Form, Input, message, Row, Space, Typography, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { styles } from "./styles";
import { useCreateSistemaVersion } from "../../../hooks/api/sistemas_versiones";
import RecursosManager from "../../Recursos/RecursosManager";
import { objectToFormData } from "../../../helpers/form.helper";

const useStyles = createUseStyles(styles);

const versionDefault = {
    csiv_version: "",
    csiv_descripcion: "",
}

const CrearSistemaVersion = ({ sistemaEnUso, closeModal }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const [files, setFiles] = useState([]);
    const { mutateSistemaVersion, isLoadingSistemaVersionMutation } = useCreateSistemaVersion();

    const handleGuardarSistemaVersion = () => {
        form.validateFields()
            .then((values) => {
                // se preparan los archivos
                let tSelectedFiles = [...files];

                let fd = objectToFormData(values, true)
                fd.append("sis_id", sistemaEnUso?.sis_id);
                for (let file of tSelectedFiles) {
                    fd.append("files[]", file);
                }

                // se envian los datos al servidor
                mutateSistemaVersion(
                    fd,
                    {
                        onSuccess: (response) => {
                            if (response.success) {
                                message.success("Versión del sistema registrada exitosamente");
                            } else {
                                message.error(response.mensaje);
                            }
                            closeModal();
                        },
                        onError: (error) => {
                            console.log(error);
                            message.error("Ocurrió un problema al registrar la versión del sistema");
                        },
                    }
                );
            })
            .catch((err) => {
                console.log(err);
                message.error("El nombre del sistema es requerido");
            });
    };

    const handleChangeFiles = (files) => {
        setFiles(files);
    }

    useEffect(() => {
        form.setFieldsValue(versionDefault);
    }, []);


    return (
        <Space className={styles.formWrapper} size={10}>
            <Row>
                <Typography.Title level={4}>Crear Versión</Typography.Title>
            </Row>
            <Row>
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="csiv_version"
                        label="Versión del sistema"
                        rules={[
                            { required: true, message: "La versión es requerida." },
                            { max: 12, message: "Máximo 12 caracteres" }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="csiv_descripcion" label="Descripción de la versión">
                        <Input.TextArea showCount maxLength={150} autoSize />
                    </Form.Item>
                    {/* <Form.Item name="csiv_linkdescarga" label="Link de descarga" descripcion>
                        <Input.TextArea showCount autoSize />
                    </Form.Item> */}
                    <Form.Item name="csiv_observaciones" label="Observaciones de la versión">
                        <Input.TextArea showCount maxLength={300} autoSize />
                    </Form.Item>
                    <Form.Item name="csiv_errores" label="La versión tiene errores" valuePropName="checked" className={styles.switchFormControl}>
                        <Switch />
                    </Form.Item>
                    <Form.Item name="csiv_parche" label="La versión necesita parche" valuePropName="checked" className={styles.switchFormControl}>
                        <Switch />
                    </Form.Item>
                    {/* <RecursosManager
                        // catalogoFieldId="csiv_id"
                        // catalogoId={?.usli_id}
                        recursos={[]}
                        allowDeleteFile
                        allowAddFiles
                        onChangeFiles={handleChangeFiles}
                        limit={1}
                        allowedExtensions={["pdf"]}
                        useApi={false}
                    // onSaveResources={handleSaveNewLicenciaFiles}
                    // onDeleteFile={handleDeleteLicenciaFile}
                    /> */}
                </Form>
            </Row>
            <Row style={{ justifyContent: "end", gap: 10 }}>
                <Button type="secondary" onClick={closeModal}>
                    Cancelar
                </Button>
                <Button type="primary" onClick={handleGuardarSistemaVersion} loading={isLoadingSistemaVersionMutation}>
                    Guardar versión
                </Button>
            </Row>
        </Space>
    );
};

export default CrearSistemaVersion;
