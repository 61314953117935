import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

/**
 * indica los tipos de dispostivos pequeños estan disponibles
 * @readonly
 * @enum {String}
 */
export const ESmallDeviceType = Object.freeze({
    PHONE: "phone",
    TABLET: "tablet",
    TABLET_LANDSCAPE: "tablet_landscape",
    SMALL_DESKTOP: "small_desktop"
});


/**
 * indica los tipos de dispostivos grandes estan disponibles
 * @readonly
 * @enum {String}
 */
export const ELargeDeviceType = Object.freeze({
    DESKTOP: "desktop",
    LARGE_DEVICE: "large_device"
});

/**
 * indica los tipos de dispostivos grandes estan disponibles
 * @typedef DeviceParams
 * @property {{width: number, height: number}} screen - dimensiones actuales de la pantalla
 * @property {{type: ESmallDeviceType | ELargeDeviceType, min: number, max: number}} device - detalles del dispositivo
 */

/**
 * 
 * @returns {DeviceParams}
 */
export const useDevice = () => {
    const { deviceSizes } = useSelector(state => state.app)
    const [device, setDevice] = useState(null);
    const [screen, setScreen] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleWindowResize = () => {
            setScreen({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        for (let device in deviceSizes) {
            if (deviceSizes[device].min <= screen.width && deviceSizes[device].max >= screen.width) {
                setDevice(deviceSizes[device]);
                break;
            }
        }
    }, [screen])

    return { screen, device };
};
