import { Button, Form, Input, InputNumber, message, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { styles } from "./styles";
import { useAcuerdosServicioMutation } from "../../../hooks/api/acuerdos_servicio";

const useStyles = createUseStyles(styles);
const acuerdoServicioDefault = {
    cacu_id: null,
    cacu_nombre: null,
    cacu_minutos: null,
    cacu_descripcion: null
}

const CrearAcuerdoServicio = ({ onCancel }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { isLoadingAcuerdoServicioMutation, mutateAcuerdoServicio } = useAcuerdosServicioMutation();

    const handleGuardarSistema = () => {
        form.validateFields()
            .then((values) => {
                mutateAcuerdoServicio(
                    { ...values, mutationType: "create" },
                    {
                        onSuccess: (response) => {
                            if (response.success) {
                                message.success("Acuerdo de servicio guardado exitosamente");
                            } else {
                                message.error(response.mensaje);
                            }
                            onCancel();
                        },
                        onError: (error) => {
                            console.log(error);
                            message.error("Ocurrió un problema al registrar el acuerdo de servicio");
                        },
                    }
                );
            })
            .catch((err) => {
                console.log(err);
                message.error("El nombre del sistema es requerido");
            });
    };

    useEffect(() =>  {
        form.setFieldsValue(acuerdoServicioDefault);
    }, []);

    return (
        <Space className={styles.formWrapper} size={10}>
            <Row>
                <Typography.Title level={4}>Crear acuerdo de servicio</Typography.Title>
            </Row>
            <Row>
                <Form form={form} layout="vertical">
                    <Form.Item name="cacu_nombre" label="Nombre del acuerdo" rules={[{ required: true, message: "El nombre es requerido." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="cacu_minutos" label="Minutos" rules={[{ required: true, message: "Los minutos son requeridos." }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="cacu_descripcion" label="Descripción" descripcion>
                        <Input.TextArea showCount maxLength={255} autoSize />
                    </Form.Item>
                </Form>
            </Row>
            <Row style={{ justifyContent: "end", gap: 10 }}>
                <Button type="secondary" onClick={onCancel}>
                    Cancelar
                </Button>
                <Button type="primary" onClick={handleGuardarSistema} loading={isLoadingAcuerdoServicioMutation}>
                    Guardar
                </Button>
            </Row>
        </Space>
    );
};

export default CrearAcuerdoServicio;
