export const ETipoProductoServicio = Object.freeze({
    PRODUCTO: 1,
    SERVICIO: 2
});
export const EProductoServicioActivo = Object.freeze({
    NO_ACTIVO: false,
    ACTIVO: true
});
export const EProductoServicioCliente = Object.freeze({
    NO_ACTIVO: false,
    ACTIVO: true
});
