import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_LOGOUT } from "../../redux/action_types/auth.action.types";
import { message } from "antd";
import { objectToFormData } from "../../helpers/form.helper";
import { useMemo } from "react";
import { ApiResponseDto } from "../../@types/Api.types";

/**
 * Content Types disponibles para las solicitudes al servidor
 * @readonly
 * @enum {String}
 */
export const AvailableRequestContentTypes = Object.freeze({
    MULTIPART: "multipart",
    JSON: "json"
});

/**
 * @typedef PutOptions
 * @property {AvailableRequestContentTypes} dataType - content-type que se va a enviar en la solicitud
 * @property {Boolean} [hasFormData=false] - solo si el content-type va como "multipart"
 * @property {Boolean} [hasCustomUrl=false] - solo si se requiere usar una url completa para el recurso sin utilizar la base url de la api
 */

/**
 * @typedef PostOptions
 * @property {AvailableRequestContentTypes} dataType - content-type que se va a enviar en la solicitud
 * @property {Boolean} [hasFormData] - solo si el content-type va como "multipart"
 * @property {Boolean} [hasCustomUrl] - solo si se requiere usar una url completa para el recurso sin utilizar la base url de la api
 */

const useApi = () => {
    const dispatch = useDispatch();
    const { apiHost, sToken } = useSelector((state) => ({
        apiHost: state.app.apiHost,
        sToken: state.auth.token,
    }));
    const sessionMessageKey = useMemo(() => "sessionMessage", [])

    const showSessionMessageExpired = () => {
        message.info({ content: "La sesión ha caducado", key: sessionMessageKey });
    }

    const get = async (resource) => {
        try {
            return await axios.get(`${apiHost}${resource}`, {
                headers: {
                    Authorization: `Bearer ${sToken}`,
                },
            });
        } catch (error) {
            if (error.response.status === 401) {
                showSessionMessageExpired();
                dispatch({ type: AUTH_LOGOUT });
            }
            return null;
        }
    };

    /**
     * 
     * @param {String} resource - recurso o endpoint de la api para realizar la operacion PUT 
     * @param {Object | FormData} data - datos que se van a enviar para realizar la operacion 
     * @param {PostOptions} options -  opciones para enviar la solicitud al servidor
     * @returns {Promise<import("axios").AxiosResponse<ApiResponseDto>>}
     */
    const post = async (resource, data, options) => {
        let reqOptions = null;

        options = {
            dataType: "multipart", hasFormData: false, hasCustomUrl: false,
            ...options
        }

        if (options.dataType === "multipart") {
            if (!options.hasFormData) {
                var form_data = objectToFormData(data);
            }
            reqOptions = {
                // withCredentials: true,
                headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${sToken}` },
            };
        } else if (options.dataType === "json") {
            reqOptions = {
                // withCredentials: true,
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${sToken}` },
            };
        }

        try {
            let result = await axios.post(options.hasCustomUrl ? resource : `${apiHost}${resource}`, form_data || data, reqOptions);
            return result;
        } catch (error) {
            // if (error?.response?.status === 401) {
            // showSessionMessageExpired();
            // dispatch({ type: AUTH_LOGOUT });
            // }
            return error;
        }
    };

    const noSessionPost = async (resource, data, options = { dataType: "multipart", hasFormData: false, hasCustomUrl: false }) => {
        let reqOptions = null;

        if (options.dataType === "multipart") {
            if (!options.hasFormData) {
                var form_data = objectToFormData(data);
            }
            reqOptions = {
                // withCredentials: true,
                // headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${sToken}` },
            };
        } else if (options.dataType === "json") {
            reqOptions = {
                // withCredentials: true,
                // headers: { "Content-Type": "application/json", Authorization: `Bearer ${sToken}` },
            };
        }

        try {
            let result = await axios.post(options.hasCustomUrl ? resource : `${apiHost}${resource}`, form_data || data, reqOptions);
            return result;
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                showSessionMessageExpired();
                dispatch({ type: AUTH_LOGOUT });
            }
            return null;
        }
    };

    /**
     * 
     * @param {String} resource - recurso o endpoint de la api para realizar la operacion PUT 
     * @param {Object} data - datos que se van a enviar para realizar la operacion 
     * @param {PutOptions} options -  opciones para enviar la solicitud al servidor
     * @returns {Promise<import("axios").AxiosResponse<ApiResponseDto>>}
     */
    const put = async (resource, data, options) => {
        options = {
            dataType: "multipart",
            hasFormData: false,
            hasCustomUrl: false,
            ...options,
        };

        let reqOptions = null;

        if (options.dataType === "multipart") {
            if (!options.hasFormData) {
                var form_data = new FormData();

                for (var key in data) {
                    form_data.append(key, data[key]);
                }
            }
            reqOptions = {
                headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${sToken}` },
            };
        } else if (options.dataType === "json") {
            reqOptions = {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${sToken}` },
            };
        }

        try {
            let result = await axios.put(`${apiHost}${resource}`, form_data || data, reqOptions);
            return result;
        } catch (error) {
            if (error?.response?.status === 401) {
                showSessionMessageExpired();
                dispatch({ type: AUTH_LOGOUT });
            }
            return null;
        }
    };

    /**
     * 
     * @param {String} resource - recurso o endpoint de la api para realizar la operacion PUT 
     * @param {Object} data - datos que se van a enviar para realizar la operacion 
     * @param {PutOptions} options -  opciones para enviar la solicitud al servidor
     * @returns {Promise<import("axios").AxiosResponse<ApiResponseDto>>}
     */
    const patch = async (resource, data, options) => {
        options = {
            dataType: "multipart",
            hasFormData: false,
            hasCustomUrl: false,
            ...options,
        };

        let reqOptions = null;

        if (options.dataType === "multipart") {
            if (!options.hasFormData) {
                var form_data = new FormData();

                for (var key in data) {
                    form_data.append(key, data[key]);
                }
            }
            reqOptions = {
                headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${sToken}` },
            };
        } else if (options.dataType === "json") {
            reqOptions = {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${sToken}` },
            };
        }

        try {
            let result = await axios.patch(`${apiHost}${resource}`, form_data || data, reqOptions);
            return result;
        } catch (error) {
            if (error?.response?.status === 401) {
                showSessionMessageExpired();
                dispatch({ type: AUTH_LOGOUT });
            }
            return null;
        }
    };

    const del = async (resource) => {
        try {
            let result = await axios.delete(`${apiHost}${resource}`, {
                headers: {
                    Authorization: `Bearer ${sToken}`,
                },
            });
            return result;
        } catch (error) {
            if (error?.response?.status === 401) {
                showSessionMessageExpired();
                dispatch({ type: AUTH_LOGOUT });
            }
            return null;
        }
    };

    return {
        get,
        post,
        put,
        patch,
        del,
        noSessionPost
    };
};

export default useApi;
