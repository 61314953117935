import { REACT_APP_API_HOST, REACT_APP_CHAT_HOST, REACT_APP_TICKETS_QUEUE_HOST, REACT_APP_USERS_QUEUE_HOST } from '../../../env';
import { DATE_FORMATS } from '../../helpers/dates.helper';
import { ELargeDeviceType, ESmallDeviceType } from "../../hooks/useDevice";
import { SET_UI_CAPTIONS } from "../action_types/app.action.types";

const initialState = {
    deviceSizes: {
        phone: { min: 0, max: 480, type: ESmallDeviceType.PHONE },
        tablet: { min: 481, max: 767, type: ESmallDeviceType.TABLET },
        tablet_landscape: { min: 768, max: 979, type: ESmallDeviceType.TABLET_LANDSCAPE },
        small_desktop: { min: 980, max: 1200, type: ESmallDeviceType.SMALL_DESKTOP },
        desktop: { min: 1201, max: 1920, type: ELargeDeviceType.DESKTOP },
        large_device: { min: 1921, max: Infinity, type: ELargeDeviceType.LARGE_DEVICE },
    },
    captions: {
        navbarTitle: ""
    },
    formats: {
        momentFormat: "DD/MM/yyyy",
        luxonFormat: "dd/MM/yyyy",
        momentInsertingFormat: DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR,
        luxonInsertingFormat: DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR,
    },
    apiHost: REACT_APP_API_HOST || "http://localhost:3001/api",
    chatHost: REACT_APP_CHAT_HOST || "http://localhost:5416",
    ticketsQueue: REACT_APP_TICKETS_QUEUE_HOST || "http://localhost:5417",
    usersQueue: REACT_APP_USERS_QUEUE_HOST || "http://localhost:5418",
    apiResources: `${REACT_APP_API_HOST || "http://localhost:3001/api"}/static`
};

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UI_CAPTIONS: {
            return {
                ...state,
                captions: {
                    ...state.captions,
                    ...action.payload
                }
            };
        }

        default:
            return state;
    }
};

export default AppReducer;
