import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Avatar, Popover, Rate, Row, Tabs, Typography, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useApiContacto } from "../../hooks/api/contactos";
import { useSelector } from "react-redux";
import { useApiUsuario } from "../../hooks/api/usuarios";

const useStyles = createUseStyles((theme) => ({
    cardContainer: {
        userSelect: "none",
        maxHeight: 400,
        width: 250,
        padding: 10,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "& .ant-avatar": {
            backgroundColor: theme.primaryColor,
            verticalAlign: "middle",
        },
    },
}));

/**
 * 
 * @param {Object} props
 * @param {String} [props.popoverPlacement] - posicion en la que se va a abrir la ventana emergente
 * @param {Number} [props.avatarSize] - tamaño del avatar del contacto
 * @param {Number} [props.usu_id] - id del usuario del que se van a mostrar los datos
 * @param {Object} [props.usuario] - objeto de los datos del usuario que se van a mostrar (si se usa este parametro no se usara el parametro de usu_id)
 * @param {Boolean} [props.showPopover=true] - si se muestra o no el popover 
 * @returns 
 */
const UsuarioPopoverCard = ({ popoverPlacement, avatarSize, usu_id, usuario, showPopover = true }) => {
    const styles = useStyles();
    const { data: contacto, isLoading: isLoadingContacto, refetch: refetchContacto } = useApiUsuario(
        {
            initialData: [],
            enabled: !!usu_id && !usuario
        },
        usu_id
    );
    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));
    const [color, setColor] = useState(null);
    const [inicialesAvatar, setInicialesAvatar] = useState("");
    const colors = [
        "#FAA22E",
        "#EA651A",
        "#B5421F",
        "#352040",
        "#092F5A",
        "#1F9583",
        "#8DC07E",
        "#CA373C",
        "#49875C",
        "#69677A",
        "#F06859",
        "#D9896D",
        "#538E85",
        "#AEC4B1",
        "#9F655D",
        "#CB9C0B",
        "#B79616",
        "#073D57",
        "#CE0B0B",
        "#6D634C",
        "#272A1E",
        "#B44265",
    ];

    useEffect(() => {
        if (usu_id && !usuario)
            refetchContacto();
    }, [usu_id, usuario])

    useEffect(() => {
        if (contacto || usuario) {
            let color = colors[Math.floor(Math.random() * colors.length)]
            setColor(color);
            let inicialNombre = (usuario || contacto)?.usu_nombres?.length > 0 ? (usuario || contacto).usu_nombres[0].toUpperCase() : "";
            let inicialApellido = (usuario || contacto)?.usu_apaterno?.length > 0 ? (usuario || contacto).usu_apaterno[0].toUpperCase() : "";
            setInicialesAvatar(`${inicialNombre}${inicialApellido}`);
        }
    }, [contacto, usuario])

    const content = (
        <div className={styles.cardContainer}>
            <Spin spinning={isLoadingContacto}>
                <Row style={{ justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 5, marginBottom: 10 }}>
                    {
                        (usuario || contacto)?.recursos?.length > 0 ?
                            <img
                                style={{ borderRadius: "50%", background: "white", objectFit: "cover" }}
                                width={100}
                                height={100}
                                src={`${apiHost}/static/${(usuario || contacto)?.recursos[0].recu_ruta}`}
                            />
                            :
                            <Avatar
                                gap={0}
                                size={60}
                                style={{
                                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                    background: color,
                                    width: 90,
                                    height: 90,
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                {inicialesAvatar}
                            </Avatar>
                    }

                    <Typography.Title level={5} style={{ marginBottom: 0, textAlign: "center" }}>
                        {(usuario || contacto)?.usu_nombres} {(usuario || contacto)?.usu_apaterno} {(usuario || contacto)?.usu_amaterno}
                    </Typography.Title>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <PhoneOutlined
                                // style={{ color: "#0FC244" }}
                                style={{ color: "#1890FF" }}
                            />
                            <Typography.Link
                                strong
                                // href={`tel:${contacto?.usu_tel?.replace("(", "").replace(")", "").replace("-", "") || "#"}`}
                                target="_blank"
                            >
                                {(usuario || contacto)?.usu_tel}
                            </Typography.Link>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <MailOutlined style={{ color: "#1890FF" }} />
                            <Typography.Link
                                strong
                                // href={`mailto:${contacto?.usu_mail}`}
                                target="_blank"
                            >
                                {(usuario || contacto)?.usu_mail}
                            </Typography.Link>
                        </div>
                    </div>
                    <Rate disabled defaultValue={5} />
                </Row>
                {/* <Tabs defaultActiveKey="1" centered={true} > */}
                {/* <Tabs.TabPane
                        tab={
                            <span>
                                Teléfonos
                            </span>
                        }
                        key="1"
                    >
                        <div style={{ width: "100%" }}>
                            {
                                contacto?.contacto_telefonos?.map((tel, iTel) => (
                                    <div style={{ borderBottom: "1px solid #eee", width: "100%", padding: "5px 10px" }} key={`telefono_${tel.usc_numero}_${iTel}`} >
                                        <TelefonoItem usu_tel={tel?.usc_numero} telefono={tel} key={`tel_${tel?.usc_id}`} />
                                    </div>
                                ))
                            }
                        </div>
                    </Tabs.TabPane> */}
                {/* <Tabs.TabPane
                        tab={
                            <span>
                                Horarios
                            </span>
                        }
                        key="2"
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column", gap: 10, overflowX: "hidden", padding: 10, maxHeight: "100%" }}
                        >
                            {
                                contacto?.horarios?.map((horario) => (
                                    // contacto?.horarios?.map((horario) => (
                                    <HorarioItem
                                        item={horario}
                                        key={`${horario.usho_id}_${horario.usho_horainicial}`}
                                        style={{
                                            maxWidth: 230,
                                            // transform: "scale(0.9)"
                                        }}
                                        allowDeleteHorario={false}
                                    />
                                ))
                            }
                        </div>
                    </Tabs.TabPane> */}
                {/* </Tabs> */}
            </Spin>
        </div>
    );

    return showPopover ? (
        <Popover content={content} placement={popoverPlacement || "top"}>
            <div style={{ display: "flex", alignItems: "center", width: "36px" }}>
                {
                    (usuario || contacto)?.recursos?.length > 0 ?
                        <img
                            style={{ borderRadius: "50%", background: "white", objectFit: "cover" }}
                            width={28}
                            height={28}
                            src={`${apiHost}/static/${(usuario || contacto)?.recursos[0].recu_ruta}`}
                        />
                        :
                        // <Avatar
                        //     gap={0}
                        //     size={60}
                        //     style={{
                        //         boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        //         background: color,
                        //         width: 90,
                        //         height: 90,
                        //         display: "flex",
                        //         alignItems: "center"
                        //     }}
                        // >
                        //     {inicialesAvatar}
                        // </Avatar>

                        <Avatar gap={0} size={avatarSize || 36} style={{ background: color, userSelect: "none", fontSize: avatarSize ? avatarSize * 0.55 : 20 }}>
                            {inicialesAvatar}
                        </Avatar>
                }
            </div>
        </Popover>
    ) : (
        <Avatar gap={0} size={avatarSize || 36} style={{ background: color, userSelect: "none", fontSize: avatarSize ? avatarSize * 0.55 : 20 }}>
            {inicialesAvatar}
        </Avatar>
    );
};

export default UsuarioPopoverCard;
