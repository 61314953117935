import { useEffect, useRef, useState } from "react";
import ChatMessage from "../../components/Chats/ChatMessage";
import { useChat } from "../../hooks/useChat";
import { Button, Input, Spin, message } from "antd";
import { FiSend } from "react-icons/fi";
import { createUseStyles } from "react-jss";
import { chatStyles } from "./styles";
import { setScrollToBottom } from "../../helpers/scroll";

const useStyles = createUseStyles(chatStyles);

/**
 * @typedef {Object} ChatProps
 * @property {Number} conv_id - id of the conversation
 */

/**
 *
 * @param {ChatProps} props
 * @returns
 */
const Chat = ({ conv_id }) => {
	const styles = useStyles();
	const [loadingMoreMessages] = useState(false);
	const loader = useRef(null);
	const [inputValue, setInputValue] = useState("");
	const {
		messages,
		chatNamespace,
		messagesPage,
		sendMessage,
		getMessagesPage,
		setChat,
	} = useChat();

	const handleClickSendMessage = () => {
		if (inputValue !== "") {
			sendMessage(inputValue)
				.then(() => {
					setInputValue("");
					setTimeout(() => {
						setScrollToBottom(`#chat-${conv_id}`);
					}, 100);
				})
				.catch((err) => {
					message.error(err);
					setInputValue("");
				});
		}
	};

	const handleKeyDownMessage = (e) => {
		if (e.code === "Enter" && inputValue !== "") {
			sendMessage(inputValue)
				.then(() => {
					setInputValue("");
					setTimeout(() => {
						setScrollToBottom(`#chat-${conv_id}`);
					}, 100);
				})
				.catch((err) => {
					message.error(err);
					setInputValue("");
				});
		}
	};

	const handleChangeInput = (e) => {
		setInputValue(e.target.value);
	};

	const initInfiniteScrolling = () => {
		const loadNewMessagesObserver = new IntersectionObserver(
			(entries) => {
				const lastMessage = entries[0];
				if (lastMessage.isIntersecting) {
					getMessagesPage(messagesPage + 1);
				}
			},
			{ threshold: 1 }
		);
		loadNewMessagesObserver.observe(loader.current);
	};

	useEffect(() => {
		if (conv_id) {
			setChat(conv_id, () => {
				setTimeout(() => {
					setScrollToBottom(`#chat-${conv_id}`);
				}, 100);
			});
		}
	}, [conv_id]);

	// useEffect(() => {
	//     let pageSize = 20;
	//     if (messages.length <= pageSize && messages.length > 0 && !!document.querySelector(`.chatmessage-${conv_id}:last-child`)) {
	//         const lastMessageObserver = new IntersectionObserver((entries) => {
	//             const lastMessage = entries[0];
	//             if (lastMessage.isIntersecting) return;
	//             lastMessageObserver.unobserve(lastMessage.target)
	//             lastMessageObserver.disconnect()
	//             initInfiniteScrolling();
	//         }, { threshold: 1 });
	//         lastMessageObserver.observe(document.querySelector(`.chatmessage-${conv_id}:last-child`))
	//     }
	// }, [messages]);

	return (
		<div className={styles.chat}>
			<div className="conversation-container" id={`chat-${conv_id}`}>
				<div
					ref={loader}
					style={{
						width: "100%",
						height: 30,
						display: "flex",
						justifyContent: "center",
						padding: "30 0px",
					}}
				>
					{loadingMoreMessages && <Spin />}
				</div>
				{messages.map((message, iMessage) => (
					<ChatMessage
						text={message.mens_detalle}
						key={`${iMessage}_${message.mens_fecha}`}
						chatId={conv_id}
						user={message.usu_id}
						date={message.mens_fecha}
						type={message.type}
					/>
				))}
			</div>
			<div className="conversation-input-wrapper">
				<Input
					placeholder="mensaje"
					onChange={handleChangeInput}
					onKeyDown={handleKeyDownMessage}
					value={inputValue}
					autoFocus
				/>
				<Button
					type="primary"
					shape="circle"
					icon={<FiSend style={{ fontSize: 16 }} />}
					onClick={handleClickSendMessage}
				/>
			</div>
		</div>
	);
};

export default Chat;
