import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, message } from "antd";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ETipoRecurso } from "../../@enums/Recursos.enums";
import { useGuardaRecursos } from "../../hooks/api/recursos";
import { v4 as uuidv4 } from "uuid";

const useStyles = createUseStyles(theme => ({
    urlManagerContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: 10,
        width: "100%",
    },
    btnAddUrl: {
        background: theme.colors.secondary,
        borderColor: theme.colors.secondary,
        width: 120
    },
    formItem: {
        flex: "1 !important",
        margin: "0 !important"
    },
    urlForm: {
        flex: 1,
        display: "flex",
        gap: 10,
    },
    btnSaveUrl: {
        // background: theme.colors.prima,
        // borderColor: theme.colors.secondary,
        // width: "50px !important",
    },
}));

/**
 * @typedef {Object} UrlItemRowProps
 * @property {Object} item
 * @property {Function} [setItem]
 * @property {String} catalogoFieldId
 * @property {Number} catalogoId
 * @property {Function} [onSaveUrl] - callback when save a url
 */

/**
 * 
 * @param {UrlItemRowProps} props 
 * @returns 
 */
const UrlItemRow = ({ item, setItem, catalogoFieldId, catalogoId, onSaveUrl }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { isSavingRecursos, saveRecursos } = useGuardaRecursos();


    const handleSaveUrl = () => {
        form.validateFields().then(values => {
            const fd = new FormData();

            fd.append("recu_tipo", `${ETipoRecurso.URL}`);
            fd.append(catalogoFieldId, `${catalogoId}`)
            fd.append("recu_ruta", values.recu_ruta);
            if (values.recu_nombre && values.recu_nombre !== "")
                fd.append("recu_nombre", values.recu_nombre);

            // the url save on api  and set the id
            saveRecursos(fd, {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success(res.mensaje)
                        // if the item has saved, set the id
                        setItem(values, res.data);
                        // if the callback saveUrl is defined, call it
                        if (onSaveUrl) onSaveUrl();
                    } else {
                        message.error(res.mensaje)
                    }
                },
                onError: (e) => {
                    console.log(e);
                    message.error("Hubo un problema la guardar los recursos")
                }
            })
        })
    }

    useEffect(() => {
        form.setFieldsValue(item);
    }, [item])

    return (
        <Row align="bottom" style={{ gap: 10 }}>
            <Form
                layout="vertical"
                className={styles.urlForm}
                form={form}
            >
                <Form.Item
                    name="recu_ruta"
                    label="URL"
                    className={styles.formItem}
                    rules={[{ required: true, message: "La URL es requerida." }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="recu_nombre" label="Nombre del recurso" className={styles.formItem}>
                    <Input />
                </Form.Item>
            </Form>
            {/* <Button type="primary" icon={<SaveOutlined />} className={styles.btnSaveUrl} /> */}
            <Button type="primary" icon={<CheckOutlined />} className={styles.btnSaveUrl} shape="circle" onClick={handleSaveUrl} loading={isSavingRecursos} />
        </Row>
    )
}

/**
 * @typedef {Object} UrlManagerProps
 * @property {Array} items
 * @property {String} catalogoFieldId
 * @property {Number} catalogoId
 * @property {Function} [onSaveUrl] - callback when save a url
 */

/**
 * 
 * @param {UrlManagerProps} props 
 * @returns 
 */
const UrlManager = ({ items: itemsProp, catalogoFieldId, catalogoId, onSaveUrl }) => {
    const styles = useStyles();
    const [items, setItems] = useState([]);

    const handleAddUrl = () => {
        setItems([...items, {
            recu_id: uuidv4(),
            recu_tipo: ETipoRecurso.URL,
            recu_nombre: "",
            recu_ruta: "",
        }]);
    }

    const setItem = (item, recu_id) => {
        const index = items.findIndex(i => i.recu_id === item.recu_id);
        item.recu_id = recu_id;
        const newItems = [...items];
        newItems[index] = item;
        setItems(newItems);
    }

    useEffect(() => {
        if (itemsProp?.length > 0) {
            setItems(itemsProp);
        } else {
            handleAddUrl();
        }
    }, []);

    useEffect(() => {
        console.log(items);
    }, [items])

    return (
        <div className={styles.urlManagerContainer}>
            {
                items.map((item, index) => (
                    <UrlItemRow
                        key={index}
                        item={item}
                        catalogoFieldId={catalogoFieldId}
                        catalogoId={catalogoId}
                        setItem={setItem}
                        onSaveUrl={onSaveUrl}
                    />
                ))
            }
            <Button type="primary" icon={<PlusOutlined />} className={styles.btnAddUrl} onClick={handleAddUrl}>Agregar URL</Button>
        </div>
    );
}

export default UrlManager;