import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
// import { useTimer } from "use-timer";
import { TimerStatus } from "./enum";


const useStyles = createUseStyles({
    timer: {
        alignItems: "center"
    }
});

const useTimer = ({ initialTime, onTimeUpdate }) => {
    const [time, setTime] = useState(initialTime || 0);
    const [status, setStatus] = useState(TimerStatus.STOPPED);

    const pause = () => {
        setStatus(TimerStatus.PAUSED);
    }

    const start = () => {
        setStatus(TimerStatus.RUNNING);
    }

    const reset = () => {
        setTime(initialTime + 1 || 0);
    }

    useEffect(() => {
        if (onTimeUpdate) onTimeUpdate(time);
        let timeout = setInterval(() => {
            setTime(time + 1)
        }, 1000)
        return () => clearInterval(timeout);
    }, [time])

    useEffect(() => {
        setTime(initialTime + 1)
    }, [initialTime])

    return {
        time,
        start,
        status,
        pause,
        reset
    };
}

/**
 * 
 * @param {Object} props 
 * @param {Number} props.initialSecondsTime  - segundos en los que va a empezar a contar el timer (segundos)
 * @param {Function} [props.onTimeLimitExceeded]  - se ejecuta cuando se ha excedido el numero de segundos de timeLimit (Function)
 * @param {Number} [props.blinkMinutesTime]  - tiempo en el que va a empezar a parpadear el timer (segundos)
 * @param {Number} [props.timeLimit]  - limite de tiempo para el timer (segundos)
 * @param {Function} [props.onChange]  - funcion que se ejecuta cada ves que el timer cambia (Function)
 * @param {String} [props.className]  - clase que se le va a poner al contenedor del timer
 * @param {Boolean} [props.countTimeOverlimit]  - bandera que permitira que el timer siga contando aun que haya llegado al limite de tiempo
 * @param {Boolean} [props.changeColor]  - bandera que indicará si el timer va a cambiar de color
 * @param {Object} [props.renderLeftContent]  - contenido que se va a renderizar a la izquierda del componente
 * @param {Object} [props.renderRightContent]  - contenido que se va a renderizar a la derecha del componente
 * @param {Object} [props.disableOnTimeUpdate]  - evita que se ejecute la funcion de [onTimeUpdate]
 * 
 * @param {Object} [props.timerContext]  - contexto que se desea utilizar para controlar el timer
 * 
 * @returns 
 */

const Timer = ({
    initialSecondsTime,
    onChange,
    className,
    blinkMinutesTime,
    timeLimit,
    onTimeLimitExceeded,
    countTimeOverlimit,
    changeColor,
    renderLeftContent,
    renderRightContent,
    disableOnTimeUpdate,

    timerContext
}) => {
    const context = useContext(timerContext || {})
    const styles = useStyles();
    const [blinking, setBlinking] = useState(false)
    const [sInitialSecondsTime, setSInitialSecondsTime] = useState(0);
    const [timerText, setTimerText] = useState("");
    const [onTimeLimitExceededExecuted, setOnTimeLimitExceededExecuted] = useState(false);
    // const timerOptions = useMemo(() => (), [sInitialSecondsTime, initialSecondsTime, onTimeLimitExceededExecuted, onTimeLimitExceeded, timeLimit])
    const { time, start, status, pause, reset } = useTimer({
        initialTime: sInitialSecondsTime,
        onTimeUpdate: (time) => {
            if (timeLimit && timeLimit > 0 && initialSecondsTime && !onTimeLimitExceededExecuted)
                if (time > timeLimit) {
                    onTimeLimitExceeded();
                    setOnTimeLimitExceededExecuted(true)
                }
        }
    });
    const [color, setColor] = useState(null);

    useEffect(() => {
        if (changeColor) {
            let time50 = 0, time75 = 0;
            time50 = timeLimit * 0.5;
            time75 = timeLimit * 0.75;
            if (time <= time50 && color !== "#00A757") {
                setColor("#00A757");
                if (onChange) onChange("#00A757");
            }
            if (time > time50 && time < time75 && color !== "#e0bf00") {
                setColor("#e0bf00");
                if (onChange) onChange("#e0bf00");
            } else if (time > time75 && color !== "#e00000") {
                setColor("#e00000");
                if (onChange) onChange("#e00000");
            }
        } else {
            setColor(null);
        }
    }, [color, time, setColor, onChange, changeColor, timeLimit, initialSecondsTime]);

    const secondsToHms = (seconds) => {
        seconds = Number(seconds);

        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor((seconds % (3600 * 24)) / 3600);
        var m = Math.floor((seconds % 3600) / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d == 1 ? "d, " : "d, ") : "";
        var hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? "m, " : "m, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";

        blinkMinutesTime = blinkMinutesTime * 60
        if (seconds >= blinkMinutesTime && !blinking) {
            setBlinking(true)
        }

        return dDisplay + hDisplay + mDisplay + sDisplay;
    };

    useEffect(() => {
        if (!countTimeOverlimit && status === TimerStatus.RUNNING) {
            pause();
        }

        setTimerText(secondsToHms(time))
    }, [time])

    useEffect(() => {
        if (timerContext) {
            if (timerContext?.initialSecondsTime) {
                setSInitialSecondsTime(context.initialSecondsTime);
            } else {
                setSInitialSecondsTime(context?.initialSecondsTime || initialSecondsTime);
            }
        } else {
            setSInitialSecondsTime(initialSecondsTime + 1);
        }
    }, [initialSecondsTime, reset, timerContext, context?.initialSecondsTime])

    useEffect(() => {
        start()
    }, [])

    return (
        <div style={{ display: "flex" }}>
            {
                renderLeftContent ? renderLeftContent(color) : null
            }
            <div className={`${styles.timer} ${className || ""}`} style={{ transition: "all 0.2s ease-in-out", color: color, fontWeight: 500, animation: blinking ? 'parpadear 1.5s infinite' : "none" }}>
                {timerText}
            </div>
            {
                renderRightContent ? renderRightContent(color) : null
            }
        </div>
    );
};

export default Timer;
