import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi from "../api/useApiRequest";

export const useApiModuleLayout = (options) => {
    const api = useApi();
    return useQuery(
        ["layout", options.moduleName],
        async () => {
            const { data } = await api.get(`/layouts/${options.moduleName}`);
            return data;
        },
        options
    );
};


export const useApiLayoutsMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/layouts`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            const { data } = await api.put(`/layouts/${params.uico_id}`, params, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/layouts/${params.cacu_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateLayout,
        isLoading: isLoadingLayoutMutation,
        error: errorLayoutMutation,
        reset: resetLayoutMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries("layouts");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateLayout,
        isLoadingLayoutMutation,
        errorLayoutMutation,
        resetLayoutMutation,
    };
};
