import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";

// Errors
import Error404 from "../views/Errors/404";

// Views
import Home from "../views/Home";
import Sistemas from "../views/Sistemas";
import AreasDepartamentos from "../views/AreasDepartamentos";
import AcuerdosServicio from "../views/AcuerdosServicio";
import Puestos from "../views/Puestos";
import Usuarios from "../views/Usuarios";
import Empresas from "../views/Empresas";
import Empresa from "../views/Empresas/Empresa";
import Contactos from "../views/Contactos";
import Contacto from "../views/Contactos/Contacto";
import UsuarioPage from "../views/Usuarios/UsuarioPage";
import { useLocation } from "react-router-dom";
import { AppRegexRegisteredRoutes } from "./registered_routes";
import { setConfig } from "../redux/actions/config.actions";
import VentasRouter from "./VentasRouter";
import DesarrolloRouter from "./DesarrolloRouter";
import AdministracionRouter from "./AdministracionRouter";
import CrearAnuncio from "../views/Administracion/Marketing/Anuncios/CrearAnuncio";
import KMS from "../views/KMS";
import KnowledgeBaseItemPage from "../views/KMS/KnowledgeBaseItemPage";
import MetricasRouter from "./metricasRouter";
import ActividadesCatalogo from "../views/actividadesCatalogo/actividadesCatalogo";
import GraficaActividades from "../views/graficaActividades/graficaActividades";
import Actividades from "../views/Actividades/actividades";
import CodigosSesion from "../views/CodigosSesion";

const Routes = () => {
    const dispatch = useDispatch();
    const motionParams = useSelector((state) => state.motion);
    const { configuracion } = useSelector((state) => state);
    const location = useLocation();
    const [notFoundPage, setNotFoundPage] = useState(false);

    useEffect(() => {
        // let existsRoute = AppRegexRegisteredRoutes.find(route => location?.pathname?.includes(route));
        let existsRoute = AppRegexRegisteredRoutes.find(route => route.test(location.pathname));
        if (existsRoute) {
            setNotFoundPage(false);
            dispatch(setConfig({
                sideBar: {
                    ...configuracion.sideBar,
                    visible: true
                }
            }));
        } else {
            setNotFoundPage(true);
        }
    }, [location])


    return (
        <Switch>
            <AnimatePresence key="app-router">
                {
                    !notFoundPage && <>
                        {/* modulo de tickets */}
                        <PrivateRoute exact path={"/home_menu"} key="/home_menu">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Home />
                            </motion.div>
                        </PrivateRoute>
                        {/* <PrivateRoute exact path={"/facturas"} key="/facturas">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Facturas />
                            </motion.div>
                        </PrivateRoute> */}
                        <PrivateRoute exact path={"/sistemas"} key="/sistemas">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Sistemas />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/acuerdos_servicio"} key="/acuerdos_servicio">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <AcuerdosServicio />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/puestos"} key="/puestos">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Puestos />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/usuarios"} key="/usuarios">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Usuarios />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/usuarios/:id"} key="/usuarios/:id">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <UsuarioPage />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/areas_departamentos"} key="/areas_departamentos">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <AreasDepartamentos />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/empresas"} key="/empresas">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Empresas />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/empresas/:id"} key="/empresas/:id">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Empresa />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/contactos"} key="/contactos">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Contactos />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/contactos/:id"} key="/contactos/:id">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <Contacto />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/know-base"} key="/know-base">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <KMS />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute exact path={"/know-base/:id"} key="/know-base/:id">
                            <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                                <KnowledgeBaseItemPage />
                            </motion.div>
                        </PrivateRoute>
                        <PrivateRoute path={"/ventas"} key="/ventas">
                            <VentasRouter  />
                        </PrivateRoute>
                        <PrivateRoute path={"/administracion"} key="/anuncios">
                            <AdministracionRouter   />
                        </PrivateRoute>
                        <PrivateRoute path={"/anuncios/crear"} key="/anuncios/crear">
                            <CrearAnuncio   />
                        </PrivateRoute>
                        <PrivateRoute path={"/metricas"} key="/metricas">
                            <MetricasRouter   />
                        </PrivateRoute>
                        <PrivateRoute exact path={"/actividades_catalogo"} key="/actividades_catalogo">
                            <ActividadesCatalogo    />
                         </PrivateRoute>
                        <PrivateRoute exact path={"/actividades_metricas"} key="/actividades_metricas">
                            <GraficaActividades    />
                         </PrivateRoute>
                        <PrivateRoute exact path={"/actividades"} key="/actividades">
                            <Actividades />
                        </PrivateRoute>
                        <PrivateRoute exact path={"/codigos_sesion"} key="/codigos_sesion">
                            <CodigosSesion />
                        </PrivateRoute>
                        <PrivateRoute path={"/desarrollo"} key="/desarrollo">
                            <DesarrolloRouter  />
                        </PrivateRoute>
                    </>
                }
                {
                    notFoundPage && <>
                        <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                            <Route component={Error404} />
                        </motion.div>
                    </>
                }
            </AnimatePresence>

            {/* <Route path="*" component={Error404} /> */}
        </Switch>
    );
};

export default Routes;
