import { Button, Checkbox, Form, Input, Layout, message, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import logo from "../../../images/logo_azul.png";
import logoNombre from "../../../images/logo_nombre_azul.png";
import Elevation from "../../components/Elevation";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";

const { Header } = Layout;

const useStyles = createUseStyles((theme) => ({
    navbar: {
        backgroundColor: "transparent",
        zIndex: 0,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        color: "#fff",
        fontSize: 16,
        fontWeight: 500,
        display: "flex",
        // height: 46,
        alignItems: "center",
        justifyContent: "center",

        "& .logo": {
            width: 33,
            height: 32,
            marginRight: 12,
        },
        "& .logoNombre ": {
            width: 160,
            height: 20,
            marginRight: 35,
        },
    },
    passwordFormContainer: {
        width: "100%",
        height: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    passwordValidationTitle: {
        background: theme.primaryColor,
        color: "#fff",
        width: "350px",
        padding: "15px",
        fontSize: 18,
        fontWeight: 500,
        display: "flex",
        justifyContent: "center",
        marginTop: -64,
    },
    formWrapper: {
        backgroundColor: "#fff",
        width: "350px",
        padding: 20,
        transition: "all 0.2s ease-in-out",
    },
    okButton: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginRight: 5,
        },
    },
    passRules: {
        border: "1px solid #eee",
        padding: 10,
        margin: "15px 0",
    },
    checkboxGroup: {
        display: "flex",
        flexDirection: "column",
        "& .ant-checkbox-wrapper": {
            cursor: "default",
        },
        "& .ant-checkbox:hover .ant-checkbox-inner": {
            borderColor: "#d9d9d9 !important",
        },
        "& .ant-checkbox-wrapper:hover .ant-checkbox-inner": {
            borderColor: "#d9d9d9 !important",
        },
    },
    successContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .buttons .ant-space-item": {
            flex: 1,
        },
    },
}));

const GeneraPassword = () => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const location = useLocation();
    const history = useHistory();
    const { apiHost } = useSelector((state) => state.app);
    const motionParams = useSelector((state) => state.motion);
    const [showSuccess, setShowSuccess] = useState(false);
    const [validatedRules, setValidatedRules] = useState([]);
    const [validatingHash, setValidatingHash] = useState(true);
    const [rulesCheck] = useState([
        { label: "Mínimo 8 caractéres", value: "num_caracteres" },
        { label: "Mínimo 1 letra mayuscula", value: "letra_mayuscula" },
        { label: "Mínimo 1 letra minuscula", value: "letra_minuscula" },
        { label: "Mínimo 1 caracter numérico", value: "numero" },
        { label: "Mínimo 1 caracter especial", value: "caracter_especial" },
    ]);
    const [validationPasswordError, setValidatedPasswordError] = useState({
        message: null,
        status: null,
    });

    const getHash = () => {
        let hash = location.pathname.split("/");
        return hash[hash.length - 1];
    };

    const validateHash = () => {
        axios
            .get(`${apiHost}/registro/genera_password/token_check/${getHash()}`)
            .then(({ data }) => {
                if (!data.success) {
                    history.push("/");
                }
                setValidatingHash(false);
            })
            .catch(() => {
                history.push("/");
                setValidatingHash(false);
            });
    };

    const handleChangePass = (e) => {
        let newValidatedRules = [];
        if (e.target.value.length >= 8) {
            newValidatedRules.push("num_caracteres");
        }

        if (/^(?=.*[A-Z])/.test(e.target.value)) {
            newValidatedRules.push("letra_mayuscula");
        }

        if (/^(?=.*[a-z])/.test(e.target.value)) {
            newValidatedRules.push("letra_minuscula");
        }

        if (/^(?=.*[0-9])/.test(e.target.value)) {
            newValidatedRules.push("numero");
        }

        if (/^(?=.*[.,?!@#$%^&*])/.test(e.target.value)) {
            newValidatedRules.push("caracter_especial");
        }

        setValidatedRules(newValidatedRules);
    };

    const handleChangePassConfirmation = (e) => {
        form.validateFields(["password"])
            .then((values) => {
                if (e.target.value === values.password) {
                    setValidatedPasswordError({
                        message: null,
                        status: null,
                    });
                } else {
                    setValidatedPasswordError({
                        message: "La contraseña no coincide",
                        status: "error",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleClickAceptar = () => {
        setValidatingHash(true);
        validateHash();
        form.validateFields().then((values) => {
            if (validatedRules.length !== rulesCheck.length) {
                message.error("La contraseña no cumple con el nivel de seguridad");
                setValidatingHash(false);
                return;
            }

            if (values.password !== values.passwordConfirmation) {
                message.error("Las contraseñas no coinciden.");
                setValidatingHash(false);
                return;
            }

            axios
                .put(`${apiHost}/registro/genera_password/${getHash()}`, { usu_pass: values.password })
                .then(({ data }) => {
                    if (data.success) {
                        setShowSuccess(true);
                    }
                    setValidatingHash(false);
                })
                .catch(() => {
                    setValidatingHash(false);
                    message.error("Ocurrió un problema al generar la contraseña");
                });
        });
    };

    const handleClickLogin = () => {
        history.push("/")
    }

    useEffect(() => {
        validateHash();
    }, []);

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                // backgroundColor: "#ffffff",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23001bd6' fill-opacity='0.57'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");`,

                // backgroundColor: "#000d4b",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23001bd6' fill-opacity='0.31'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
            }}
        >
            <Header className={styles.navbar}>
                <div>
                    <img src={logo} alt="logo" className="logo" />
                    <img src={logoNombre} alt="logoNombre" className="logoNombre" />
                </div>
            </Header>
            <Row className={styles.passwordFormContainer}>
                <Elevation>
                    <Row className={styles.passwordValidationTitle}>Sistema Kronox</Row>
                    <div className={styles.formWrapper}>
                        <AnimatePresence mode="wait">
                            {showSuccess ? (
                                <MessageSuccess handleClickLogin={handleClickLogin} />
                            ) : (
                                <motion.div
                                    className={styles.successContainer}
                                    initial="out"
                                    animate="in"
                                    exit="out"
                                    transition={motionParams.transition}
                                    variants={motionParams.variants}
                                >
                                    <Spin spinning={validatingHash} tip="cargando ...">
                                        <Row style={{ flexDirection: "column" }}>
                                            <Typography.Title level={4}>Crea tu contraseña</Typography.Title>
                                            <Typography.Paragraph level={4} style={{ color: "#777" }}>
                                                Tu correo electrónico se ha verificadó. Puedes proceder a crear la contraseña para acceder a tu
                                                cuenta.
                                            </Typography.Paragraph>
                                        </Row>
                                        <Row style={{ marginTop: 5, width: "100%" }}>
                                            <Form
                                                layout="vertical"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                autoComplete="off"
                                                form={form}
                                                style={{ width: "100%" }}
                                                spellCheck="false"
                                            >
                                                <Form.Item
                                                    label="Contraseña"
                                                    name="password"
                                                    rules={[{ required: true, message: "por favor ingresa una contraseña válida!" }]}
                                                    style={{ marginBottom: 0 }}
                                                    // onKeyPress={handleInputKeyPress}
                                                >
                                                    <Input.Password className={styles.password} onChange={handleChangePass} tabIndex={1}  />
                                                </Form.Item>
                                                <Row className={styles.passRules}>
                                                    <Checkbox.Group options={rulesCheck} value={validatedRules} className={styles.checkboxGroup} />
                                                </Row>
                                                <Form.Item
                                                    label="Confirma la contraseña"
                                                    name="passwordConfirmation"
                                                    rules={[{ required: true, message: "por favor ingresa una contraseña válida!" }]}
                                                    validateStatus={validationPasswordError.status}
                                                    help={validationPasswordError.message}
                                                >
                                                    <Input.Password className={styles.password} onChange={handleChangePassConfirmation} tabIndex={2} />
                                                </Form.Item>
                                            </Form>
                                        </Row>
                                        <Row style={{ justifyContent: "flex-end", width: "100%" }}>
                                            <Button type="primary" className={styles.okButton} icon={<AiOutlineCheck />} onClick={handleClickAceptar}>
                                                Aceptar
                                            </Button>
                                        </Row>
                                    </Spin>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </Elevation>
            </Row>
        </div>
    );
};

const MessageSuccess = ({ handleClickLogin }) => {
    const styles = useStyles();
    const motionParams = useSelector((state) => state.motion);

    const handleClickGoToLogin = () => handleClickLogin();

    return (
        <motion.div
            className={styles.successContainer}
            initial="out"
            animate="in"
            exit="out"
            transition={motionParams.transition}
            variants={motionParams.variants}
        >
            <BsFillPersonCheckFill fontSize={50} style={{ color: "#00A757" }} />
            <Typography.Title level={4}>¡Todo listo!</Typography.Title>
            <Typography.Paragraph style={{ textAlign: "center", fontSize: 14 }}>Te has registrado exitosamente. Bienvenido.</Typography.Paragraph>
            <Button type="primary" style={{ marginTop: 10 }} onClick={handleClickGoToLogin}>
                Listo
            </Button>
        </motion.div>
    );
};

export default GeneraPassword;
