import { Button, Col, Form, Input, InputNumber, message, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSistemaMutation } from "../../../hooks/api/sistemas";
import { styles } from "./styles";
import { SistemaDto } from "../dto";
import NumericInput from "../../../components/form/NumericInput";
import { SistemaClasificacion, SistemaSubClasificacion, SistemaTipoLicenciamiento } from "../../../@enums/Sistemas.enum";
import FileDropper from "../../../components/FileDropper";
import { objectToFormData } from "../../../helpers/form.helper";

const useStyles = createUseStyles(styles);

/**
 * 
 * @param {Object} props
 * @param {Function} props.closeCrearSistema - funcion para cerrar el contenedor donde se está mostrando el componente de crear  sistema
 * @returns 
 */
const CrearSistema = ({ closeCrearSistema }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const [archivosSistema, setArchivosSistema] = useState([]);
    const { isLoadingSistemaMutation, mutateSistema } = useSistemaMutation();

    const handleGuardarSistema = () => {
        form.validateFields()
            .then((values) => {
                // se convierte el objeto con los datos a FormData
                let fd = objectToFormData(values);
                // se preparan los archivos del sistema
                let tFiles = [...archivosSistema];
                for (let file of tFiles) {
                    fd.append("files[]", file, file.originFileObj || file.name);
                }

                mutateSistema(fd, {
                    onSuccess: (response) => {
                        if (response.success) {
                            form.resetFields();
                            message.success("Sistema registrado exitosamente");
                            if (closeCrearSistema) closeCrearSistema();
                        } else {
                            message.error(response.mensaje);
                        }
                    },
                    onError: (error) => {
                        console.log(error);
                        message.error("Ocurrió un problema al registrar el sistema");
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                message.error("El nombre del sistema es requerido");
            });
    };

    const handleChangeFiles = (fileList) => {
        setArchivosSistema([...archivosSistema, ...fileList])
    }

    useEffect(() => {
        form.setFieldsValue(new SistemaDto());
        setArchivosSistema([])
    }, [])

    return (
        <Space className={styles.formWrapper} size={10}>
            <Row>
                <Typography.Title level={4}>Crear sistema</Typography.Title>
            </Row>
            <div>
                <Row>

                    <Form form={form} layout="vertical" style={{ width: "100%" }}>
                        <Row style={{ gap: 20, width: "100%" }}>
                            <Col xs={24} sm={24} md={{ span: 24 }} xl={{ span: 13 }}>
                                <Row>
                                    <Typography.Title level={5}>Datos generales</Typography.Title>
                                </Row>
                                <Row >
                                    <Form.Item name="sis_nombre" label="Nombre del sistema" rules={[{ required: true, message: "El nombre es requerido." }]} style={{ flex: 1 }}>
                                        <Input />
                                    </Form.Item>
                                </Row>
                                <Row style={{ gap: 20 }}>
                                    <Form.Item name="sis_clasificacion"
                                        label="Clasificación"
                                        style={{ flex: 1 }}
                                        rules={[{ required: true, message: "La clasificación es requerida." }]}
                                    >
                                        <Select>
                                            {
                                                Object.keys(SistemaClasificacion).map(k => <Select.Option value={SistemaClasificacion[k]} key={k}>{k}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="sis_subclasificacion"
                                        label="SubClasificación"
                                        style={{ flex: 1 }}
                                        rules={[{ required: true, message: "La subclasificación es requerida." }]}
                                    >
                                        <Select>
                                            {
                                                Object.keys(SistemaSubClasificacion).map(k => <Select.Option value={SistemaSubClasificacion[k]} key={k}>{k}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="sis_tipo"
                                        label="Tipo licenciamiento"
                                        style={{ flex: 1 }}
                                        rules={[{ required: true, message: "El tipo de licenciamiento es requerido." }]}
                                    >
                                        <Select>
                                            {
                                                Object.keys(SistemaTipoLicenciamiento).map(k => <Select.Option value={SistemaTipoLicenciamiento[k]} key={k}>{k}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item name="sis_serie" label="Serie" style={{ flex: 1 }}>
                                        <Input />
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Typography.Title level={5}>Precios y costos</Typography.Title>
                                </Row>
                                <Row style={{ gap: 20 }}>
                                    <Form.Item name="sis_precio" label="Precio" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                    <Form.Item name="sis_costo" label="Costo" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                </Row>
                                <Row style={{ gap: 20 }}>
                                    <Form.Item name="sis_precioadicional" label="Precio adicional" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                    <Form.Item name="sis_costoadicional" label="Costo adicional" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col sm={24} xs={24} md={24} xl={{ span: 10 }} style={{}}>
                                <Row>
                                    <Typography.Title level={5}>Anotaciones</Typography.Title>
                                </Row>
                                <Row style={{ gap: 20, flexDirection: "column" }}>
                                    <Form.Item name="sis_descripcion" label="Descripción" style={{ flex: 1 }}>
                                        <Input.TextArea showCount maxLength={255} />
                                    </Form.Item>
                                    <Form.Item name="sis_observaciones" label="Observaciones" style={{ flex: 1 }}>
                                        <Input.TextArea showCount maxLength={255} />
                                    </Form.Item>
                                    <Form.Item name="sis_consideraciones" label="Consideraciones" style={{ flex: 1 }}>
                                        <Input.TextArea showCount maxLength={255} />
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row>
                    <FileDropper
                        onChange={handleChangeFiles}
                        limit={5}
                    />
                </Row>
            </div>
            <Row style={{ justifyContent: "end" }}>
                <Button type="primary" onClick={handleGuardarSistema} loading={isLoadingSistemaMutation}>
                    Guardar sistema
                </Button>
            </Row>
        </Space>
    );
};

export default CrearSistema;
