import { createUseStyles } from "react-jss";
import { knowBaseAdministrationStyles } from "./styles";
import KMS from "../../KMS";

const useStyles = createUseStyles(knowBaseAdministrationStyles);

const KnowBaseAdministration = () => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <KMS />
        </div>
    );
}

export default KnowBaseAdministration;