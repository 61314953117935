import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi from "./useApiRequest";
import { formatUrlQuery } from "./utils";

/**
 * @typedef SearchContactosParams
 * @property {Number} [rwc] - (Relationated With Client) parametro que especifica si se van a consultar contactos relacionados con una empresa  (requiere emp_id - {@link SearchContactosParams.emp_id})
 * 
 *  1 = se consultan contactos relacionados con la empresa
 * 
 *  0 = se consultan contactos no relacionados con la empresa
 * 
 * @property {Number} [emp_id] - id de la empresa (cliente) con la que se hara la busqueda (requiere rwc - {@link SearchContactosParams.rwc})
*/

// ------------------- hooks de contactos ----------------------------

/**
 * 
 * @param {import("react-query").UseQueryOptions} options - datos iniciales que se retornaran antes de que la solicitud se completes
 * @param {SearchContactosParams} [params] - url query para mandar parametros de busqueda  
 */
export const useApiContactos = (options, params) => {
    const api = useApi();

    const getContactos = async () => {
        // si es que se mandan parametros por la url se formatean y se agregan
        if (params) {
            const { data: resultData } = await api.get(`/contactos?${formatUrlQuery(params)}`);
            return resultData || [];
        }
        // sino se consultan todos los contactos
        const { data } = await api.get(`/contactos`);
        return data;
    };

    return useQuery(["contactos"], getContactos, options);
};

export const useApiContacto = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contacto", contactoId],
        async () => {
            if (!contactoId)
                return null;
            
            const { data } = await api.get(`/contactos/${contactoId}`);
            return data;
        },
        options
    );
};

/**
 * 
 * @typedef NewContactDto
 * @property {String} usu_nombre - nombre del contacto
 * @property {String} usu_apaterno - apellido paterno
 * @property {String} usu_amaterno - apellido materno
 * @property {String} usu_email - email del contacto
 * @property {String} usu_password - contraseña del contacto
 * @property {String} usu_telefono - telefono del contacto
 */

export const useCreateNewContact = () => {
    const api = useApi();

    /**
     * 
     * @param {NewContactDto} createNewContactParams - datos necesarios para crear un nuevo contacto
     */
    const addResource = async (createNewContactParams) => {
        const response = await api.post(`/contactos`, createNewContactParams, { dataType: "json", hasFormData: false });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        createContact: mutation.mutate,
        isCreatingContact: mutation.isLoading,
        isCreatedContact: mutation.isSuccess,
        isCreatingContactError: mutation.isError,
    };
}

export const useContactoMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        // if (params.mutationType === "create") {
        //     delete params.mutationType;
        //     const { data } = await api.post(`/contactos/${params.usu_id}`, params, {
        //         dataType: "json",
        //         hasFormData: false,
        //         hasCustomUrl: false,
        //     });
        //     return data;
        // }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.usu_id;
            delete params.usu_id;
            const { data } = await api.put(`/contactos/${id}`, params, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/contactos/${params.emp_id}/horarios/${params.usho_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateContacto,
        isLoading: isLoadingContactoMutation,
        error: errorContactoMutation,
        reset: resetContactoMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contacto", "horarios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContacto,
        isLoadingContactoMutation,
        errorContactoMutation,
        resetContactoMutation,
    };
}



// ------------------- hooks de empresas del contacto

export const useApiContactoEmpresas = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contactos", "empresas", contactoId],
        async () => {
            if (!contactoId) return [];
            const { data } = await api.get(`/contactos/${contactoId}/empresas`);
            return data;
        },
        options
    );
};

// ------------------- hooks de licencias del contacto

export const useApiContactoLicencias = (options, contactoId) => {
    const api = useApi();
    const [ContactoId, setContactoId] = useState(contactoId);
    return useQuery(
        ["contactos", "licencias", contactoId],
        async () => {
            setContactoId(contactoId);
            // if (!contactoId ) return [];
            if (!contactoId || contactoId === ContactoId) return [];
            const { data } = await api.get(`/contactos/${contactoId}/licencias`);
            return data;
        },
        options
    );
};

// ------- horarios de los contactos

export const useApiContactoHorarios = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contacto", "horarios"],
        async () => {
            const { data } = await api.get(`/contactos/${contactoId}/horarios`);
            return data;
        },
        options
    );
};

export const useContactoHorarioMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/contactos/${params.usu_id}/horarios`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     let id = params.dom_id;
        //     delete params.dom_id;
        //     const { data } = await api.put(`/empresas/${params?.emp_id}/domicilios/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/contactos/${params.emp_id}/horarios/${params.usho_id}`);
            return data;
        }
    };

    const {
        mutate: mutateContactoHorario,
        isLoading: isLoadingContactoHorarioMutation,
        error: errorContactoHorarioMutation,
        reset: resetContactoHorarioMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contacto", "horarios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContactoHorario,
        isLoadingContactoHorarioMutation,
        errorContactoHorarioMutation,
        resetContactoHorarioMutation,
    };
};

// ------------------- hooks de telefonos del contacto

export const useApiContactoTelefonos = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contacto", "telefonos"],
        async () => {
            const { data } = await api.get(`/contactos/${contactoId}/telefonos`);
            return data;
        },
        options
    );
};

export const useContactoTelefonoMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/contactos/${params.usu_id}/telefonos`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     let id = params.dom_id;
        //     delete params.dom_id;
        //     const { data } = await api.put(`/empresas/${params?.emp_id}/domicilios/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/contactos/${params.usu_id}/telefonos/${params.usc_id}`);
            return data;
        }
    };

    const {
        mutate: mutateContactoTelefono,
        isLoading: isLoadingContactoTelefonoMutation,
        error: errorContactoTelefonoMutation,
        reset: resetContactoTelefonoMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contacto", "telefonos"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContactoTelefono,
        isLoadingContactoTelefonoMutation,
        errorContactoTelefonoMutation,
        resetContactoTelefonoMutation,
    };
};

/**
 * 
 * @typedef ApprovingContactoDto
 * @property {Number} emp_id - id de la empresa   
 * @property {Number} usu_id - id del contacto
 */

export const useAprobarContacto = () => {
    const api = useApi();

    /**
     * 
     * @param {ApprovingContactoDto} approvingParams - datos necesarios para aprobar el contacto
     */
    const addResource = async (approvingParams) => {
        const response = await api.put(`/contactos/${approvingParams.usu_id}/aprobar`, approvingParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        approveContacto: mutation.mutate,
        isApprovingContacto: mutation.isLoading,
        isApprovedContacto: mutation.isSuccess,
        isApprovingContactoError: mutation.isError,
    };
};

// ---------------------- campos extra del contacto --------------------------

export const useApiContactoCamposExtra = (options) => {
    const api = useApi();
    return useQuery(
        ["contactos", "campos_extra"],
        async () => {
            if (options?.id) {
                const { data } = await api.get(`/contactos/${options?.id}/campos_extra`);
                return data;
            } else return []
        },
        options
    );
};

export const useContactoCamposExtraMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/contactos/${params.usu_id}/campos_extra`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     let id = params.pues_id;
        //     delete params.pues_id;
        //     const { data } = await api.put(`/contactos/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/contactos/${params.usu_id}/campos_extra/${params.came_id}`);
            return data;
        }
    };

    const {
        mutate: mutateContactoCamposExtra,
        isLoading: isLoadingContactoCamposExtraMutation,
        error: errorContactoCamposExtraMutation,
        reset: resetContactoCamposExtraMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contactos", "campos_extra"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContactoCamposExtra,
        isLoadingContactoCamposExtraMutation,
        errorContactoCamposExtraMutation,
        resetContactoCamposExtraMutation,
    };
};

export const GetContactos = ()  => {
    const api = useApi();


    const mutate = async (params) => {
        const data  = await api.get(`/contactos`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getContactosMutation: mutation.mutate,
    };
}
