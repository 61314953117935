import { Rate } from "antd"


const redirect = (id, columnID) => {
    window.location.href=`/tickets?idUser=${id}&status=${columnID}`
}

const colums = [
    {
        title: 'Asesor',
        dataIndex: 'asesor',
        key: 'asesor',
        sorter: (a, b) => a.asesor.localeCompare(b.asesor),
    },
    {
        title: 'Empresa',
        dataIndex: 'company',
        key: 'company',
        sorter: (a, b) => a.company.localeCompare(b.company),
    },
    {
        title: 'Contacto',
        dataIndex: 'contact',
        key: 'contact',
        sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
        title: 'Telefono',
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: 'Tipo de Licenciamiento',
        dataIndex: 'lictype',
        key: 'lictype',
        sorter: (a, b) => a.lictype.localeCompare(b.lictype),
    },
    {
        title: 'Empresa Registrante',
        dataIndex: 'registercomp',
        key: 'registercomp',
        sorter: (a, b) => a.registercomp.localeCompare(b.registercomp),
    },
    {
        title: 'Sistema',
        dataIndex: 'system',
        key: 'system',
        sorter: (a, b) => a.system.localeCompare(b.system),
    },
    {
        title: 'Número de Serie',
        dataIndex: 'ns',
        key: 'ns',
        sorter: (a, b) => a.ns.localeCompare(b.ns),
    },
    {
        title: 'Usuarios',
        dataIndex: 'usernum',
        key: 'usernum',
        sorter: (a, b) => a.usernum - b.usernum,
    },
    {
        title: 'Fecha de activación',
        dataIndex: 'activedate',
        key: 'activedate',
        sorter: (a, b) => a.activedate.localeCompare(b.activedate),
    },
    {
        title: 'Año de Vencimiento',
        dataIndex: 'finishyear',
        key: 'finishyear',
        sorter: (a, b) => a.finishyear - b.finishyear,
    },
    {
        title: 'Mes de Vencimiento',
        dataIndex: 'finishmonth',
        key: 'finishmonth',
        sorter: (a, b) => a.finishmonth - b.finishmonth,
    },
    {
        title: 'Fecha de Vencimiento',
        dataIndex: 'finishdate',
        key: 'finishdate',
        sorter: (a, b) => a.finishdate.localeCompare(b.finishdate),
    },
    {
        title: 'Precio ',
        dataIndex: 'price',
        key: 'price',
        sorter: (a, b) => a.price.localeCompare(b.price),
    },
]
const hardData = [
    {
        title: 'Tiempos',
        data: [
            {
                value:1,
                title: 'Promedio de Primer Contacto del Mes',
                data: '12 minutos'
            },
            {
                value:2,
                title: 'Promedio de Primer Contacto del Día',
                data: '15 minutos'
            },
            {
                value:2,
                title: 'Promedio de Resolución',
                data: '40 minutos'
            }
        ]
    },
    {
        title: 'Procesos de Tickets',
        data: [
            {
                value:1,
                title: 'Del día',
                data: 12
            },
            {
                value:2,
                title: 'Promedio del Mes',
                data: 40
            },
            {
                value:3,
                title: 'En Revisión',
                data: 5
            },
            {
                value:4,
                title: 'En espera',
                data: 5
            },
        ]
    },
    {
        title: 'Cierres de Tickets',
        data: [
            {
                value:5,
                title: 'Totales',
                data: 10
            },
            {
                value:6,
                title: 'Cerrados con Éxito',
                data: 8
            },
            {
                value:7,
                title: 'Cerrados sin Éxito',
                data: 0
            },
            {
                value:8,
                title: 'Caducados',
                data: 2
            }
        ]
    }
]
const dataCard = [
    {
        title: 'Titulo',
        headers: ['Sistemas', 'Unidades', 'Monto'],
        data:[['Prueba', 5, '$2000'], ['Prueba2', 5, '$1000']]
    }
]

const fixtures = () => {
    let fixture = {};
   
    fixture = {
        hardData,
        colums,
        dataCard
    };
  
    return fixture;
  };
  
  export default fixtures;