import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import { useApiContactoTelefonos, useContactoTelefonoMutation } from "../../../hooks/api/contactos";
import { TipoContactoMarcacion, getKeyTipoContactoMarcacion } from "../../../@enums/Contactos.enums";
// @ts-ignore
import LADAS_INTERNACIONALES from "../../../@static_data/ladas-internacionales.json";
import { MaskedInput } from "antd-mask-input";

const useStyles = createUseStyles({
    contactoTelefonos: {
        flex: 1,
        gap: 5,
        width: "100%",
        padding: 15,
        flexDirection: "column",
        "& .ant-form-item": {
            marginBottom: 12,
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    table: {
        width: "100%",
    },
    modalContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .ant-form-item": {
            flex: 1,
        },
    },
});

const formInitialValues = {
    usc_numero: null,
    usc_tipo: 1,
    usc_usawhatsapp: false,
    usc_extension: null,
    usc_lada: 142,
};

const ContactoTelefonos = ({ con_id }) => {
    const styles = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [tipoTelefono, setTipoTelefono] = useState(1);
    const [form] = Form.useForm();
    const { data: telefonos } = useApiContactoTelefonos({ initialData: [] }, con_id);
    const { mutateContactoTelefono, isLoadingContactoTelefonoMutation, resetContactoTelefonoMutation } = useContactoTelefonoMutation();

    const closeModal = () => setShowModal(false);

    const handleClickEliminarTelefono = (usc_id) => {
        mutateContactoTelefono(
            {
                mutationType: "delete",
                usu_id: con_id,
                usc_id,
            },
            {
                onSuccess: (res) => {
                    if (res?.success) {
                        message.success(res.mensaje);
                        closeModal();
                        resetContactoTelefonoMutation();
                        form.setFieldsValue(formInitialValues);
                    } else {
                        message.error("Aún faltan campos por capturar");
                    }
                },
                onError: () => {
                    message.error("Aún faltan campos por capturar");
                },
            }
        );
    };

    const columns = [
        {
            dataIndex: "usc_lada",
            title: "Lada",
            render: (value) => {
                let lada = LADAS_INTERNACIONALES.find(lada => lada.id === +value);
                return lada ? `${lada.name} (+${lada.lada})` : "";
            },
        },
        {
            dataIndex: "usc_numero",
            title: "Número",
        },
        {
            dataIndex: "usc_tipo",
            title: "Tipo",
            render: (value) => {
                switch (value) {
                    case 1:
                        return "Celular";
                    case 2:
                        return "Fijo";
                    default:
                        return "";
                }
            },
        },
        {
            dataIndex: "usc_usawhatsapp",
            title: "Usa Whatsapp",
            render: (value) => {
                return value ? "Si" : "No";
            },
        },
        {
            dataIndex: "usc_extension",
            title: "Extensión",
        },
        {
            title: "",
            render: (_, row) => {
                return (
                    <Button
                        key={`btn-delete-telefono-${row.usc_id}`}
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleClickEliminarTelefono(row.usc_id)}
                        loading={isLoadingContactoTelefonoMutation}
                    />
                );
            },
        },
    ];

    const handleClickAgregarTelefono = () => {
        setShowModal(true);
    };

    const handleGuardarTelefono = () => {
        form.validateFields()
            .then((values) => {
                mutateContactoTelefono(
                    {
                        mutationType: "create",
                        usu_id: con_id,
                        ...values,
                    },
                    {
                        onSuccess: (res) => {
                            if (res?.success) {
                                setTipoTelefono(1);
                                message.success(res.mensaje);
                                closeModal();
                                resetContactoTelefonoMutation();
                                form.setFieldsValue(formInitialValues);
                            } else {
                                message.error("Aún faltan campos por capturar");
                            }
                        },
                        onError: () => {
                            message.error("Aún faltan campos por capturar");
                        },
                    }
                );
            })
            .catch(() => {
                message.error("Aún faltan campos por capturar");
            });
    };

    const handleChangeTipoTelefono = (value) => {
        setTipoTelefono(value);
    };

    useEffect(() => {
        setTipoTelefono(1);
        form.setFieldsValue(formInitialValues);
    }, []);

    return (
        <Elevation className={styles.contactoTelefonos}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Teléfonos</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={handleClickAgregarTelefono} />
                </div>
            </Row>
            <Row>
                <Table columns={columns} dataSource={telefonos} className={styles.table} />
            </Row>
            <Modal title="Agregar Teléfono" visible={showModal} footer={null} onCancel={closeModal} destroyOnClose={true} width="300px">
                <div className={styles.modalContent}>
                    <Form layout="vertical" form={form}>
                        <Row>
                            <Space style={{ flexDirection: "column", width: "100%" }}>
                                <Form.Item
                                    label="Lada"
                                    name="usc_lada"
                                    rules={[{ required: true, message: "La lada es requerida" }]}
                                    style={{
                                        flex: 0.5
                                    }}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.join(" ").toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {
                                            LADAS_INTERNACIONALES.map(lada => <Select.Option value={lada.id} key={`lada_${lada.id}`}>{lada.name} (+{lada.lada})</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="usc_numero" label="Número" rules={[{ required: true, message: "El número es requerido" }]}>
                                    {/* <Input /> */}
                                    <MaskedInput mask={"(000)000-0000"} />
                                </Form.Item>
                                <Form.Item name="usc_tipo" label="Tipo" rules={[{ required: true, message: "El tipo es requerido" }]}>
                                    <Select onChange={handleChangeTipoTelefono}>
                                        {
                                            Object.keys(TipoContactoMarcacion).map(tipo => <Select.Option value={TipoContactoMarcacion[tipo]} key={`tipoContactoMarcacion_${tipo}`}>{tipo}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                {tipoTelefono === 2 ? (
                                    <Form.Item
                                        name="usc_extension"
                                        label="Extensión"
                                    >
                                        <Input />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        name="usc_usawhatsapp"
                                        label="¿Usa Whatsapp?"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                )}
                            </Space>
                        </Row>
                    </Form>
                    <Row style={{ justifyContent: "end" }}>
                        <Button
                            type="primary"
                            onClick={handleGuardarTelefono}
                        // loading={isLoadingContactoHorarioMutation}
                        >
                            Guardar teléfono
                        </Button>
                    </Row>
                    {/* 
                        solo agregar campos del apartado de generales 
                        y crear vista a parte para agregar y modifica los demas campos de la empresa
                    */}
                </div>
            </Modal>
        </Elevation>
    );
};

export default ContactoTelefonos;
