import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button, Checkbox, Form, Input, message, Modal, Row, Space, Typography } from "antd";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { BiArrowBack, BiMailSend } from "react-icons/bi";
import { MaskedInput } from "antd-mask-input";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import useApi from "../../hooks/api/useApiRequest";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useModal } from "../../hooks/useModal";

const useStyles = createUseStyles((theme) => ({
    container: {
        // display: "none",
        width: "100%",
        "& .ant-form-vertical .ant-form-item": {
            flexDirection: "row",
        },
        "& .ant-form-item-control-input": {
            alignItems: "end",
        },
    },
    backButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        marginRight: 8,
    },
    successContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: 10,
        "& .buttons .ant-space-item": {
            flex: 1,
        },
    },
    mailInput: {
        "& input": {
            "&:focus": {
                boxShadow: "none !important",
            },
            borderBottom: "none !important",
            border: "none !important",
        },
        "& .ant-input-affix-wrapper": {
            boxShadow: "none !important",
        },
    },
    spaceNombre: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        "& .ant-space-item": {
            width: "100%",
            flex: 1,
        },
    },
    "@media (max-width: 600px)": {
        spaceNombre: {
            "& .ant-space-item": {
                flex: "auto",
            },
        },
    },
    messageCorreo: {
        fontWeight: 500,
        color: theme.primaryColor,
    },
    nuevoCorreo: {
        marginTop: 5,
        "&  .ant-row": {
            marginBottom: 10,
        },
    },
}));

const Registro = ({ setVisibleForm }) => {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const motionParams = useSelector((state) => state.motion);
    const { apiHost } = useSelector((state) => state.app);
    const [showSuccess, setShowSuccess] = useState(false);
    const [ValidatedCaptcha, setValidatedCaptcha] = useState(false);
    const [mailError, setMailError] = useState({
        message: null,
        status: null,
    });
    const [terminosChecked, setTerminosChecked] = useState(false);
    const [resendMailData, setResendMailData] = useState(null);
    const [form] = Form.useForm();
    const [show, showModal, closeModal] = useModal();

    useEffect(() => {
        motionParams.variants.in.x = 0;
        motionParams.variants.in.y = 0;
        motionParams.variants.out.x = 0;
        motionParams.variants.out.y = "-20px";
    }, [motionParams]);

    const handleClickLogin = () => setVisibleForm("login");

    const rfcValido = (rfc, aceptarGenerico = true) => {
        const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        var validado = rfc.match(re);

        if (!validado)  //Coincide con el formato general del regex?
            return false;

        //Separar el dígito verificador del resto del RFC
        const digitoVerificador = validado.pop(),
            rfcSinDigito = validado.slice(1).join(''),
            len = rfcSinDigito.length,

            //Obtener el digito esperado
            diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
            indice = len + 1;
        var suma,
            digitoEsperado;

        if (len == 12) suma = 0
        else suma = 481; //Ajuste para persona moral

        for (var i = 0; i < len; i++)
            suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
        digitoEsperado = 11 - suma % 11;
        if (digitoEsperado == 11) digitoEsperado = 0;
        else if (digitoEsperado == 10) digitoEsperado = "A";

        //El dígito verificador coincide con el esperado?
        // o es un RFC Genérico (ventas a público general)?
        if ((digitoVerificador != digitoEsperado)
            && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
            return false;
        else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
            return false;
        return rfcSinDigito + digitoVerificador;
    }


    const handleClickRegistro = () => {
        setLoading(true);
        form.validateFields()
            .then(async (values) => {
                try {
                    let mailExists = await checkMail();

                    if (mailExists) {
                        message.error("El correo electrónico ya existe");
                        setLoading(false);
                        return;
                    }

                    if (!rfcValido(values.emp_rfc)) {
                        message.error("El RFC no tiene el formato correcto");
                        setLoading(false);
                        return;
                    }

                    axios
                        .post(`${apiHost}/registro`, values)
                        .then(({ data }) => {
                            if (data.success) {
                                setResendMailData(data.data);
                                setShowSuccess(true);
                            } else {
                                message.error("Hubo un problema al ragistrarse");
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            message.error("Hubo un problema al ragistrarse");
                            console.error(err);
                            setLoading(false);
                        });
                } catch (e) {
                    message.error("Ocurrió un problema al validar el mail.");
                    console.log(e);
                }
            })
            .catch((err) => {
                console.log(err);
                message.error("Error al hacer el registro, revisa los datos");
                setLoading(false);
            });
    };

    const checkMail = () => {
        return new Promise((resolve, reject) => {
            form.validateFields(["mail"])
                .then((values) => {
                    // validar el mail del usuario
                    axios
                        .get(`${apiHost}/registro/mailcheck/${values.mail}`)
                        .then((response) => {
                            if (response.data.data) {
                                resolve(true);
                                setMailError({
                                    message: "El correo electrónico ya existe",
                                    status: "error",
                                });
                            } else {
                                resolve(false);
                                setMailError({
                                    message: null,
                                    status: null,
                                });
                            }
                        })
                        .catch((err) => {
                            reject(err);
                            console.error(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                    console.error(err);
                });
        });
    };

    const containsSpecialChars = (str) => {
        const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        return specialChars.test(str);
    }

    const preventSpecialChars = (e) => {
        if (containsSpecialChars(e.key)) {
            e.preventDefault();
        }
    }

    const handleBlurMail = () => {
        checkMail();
    };

    const handleChangeTerminos = (e) => {
        setTerminosChecked(e.target.checked);
    };

    const handleChangeCaptcha = (value) => {
        if (!value) {
            setValidatedCaptcha(false);
            message.error("Captcha error");
        } else setValidatedCaptcha(true);
    };


    // useEffect(() => {
    //     form.setFieldsValue({
    //         nombre: "francisco manuel",
    //         apaterno: "castellanos",
    //         amaterno: ".",
    //         mail: "pacorrin_alpha@hotmail.com",
    //         tel: "4425772565",
    //         emp: "kronox",
    //         emp_rfc: "KAK1702107F2"
    //     })
    // }, [])

    return (
        <Row style={{ width: "100%" }}>
            {showSuccess ? (
                <MessageSuccess
                    handleClickLogin={handleClickLogin}
                    resendMailData={resendMailData}
                    loading={loading}
                    setLoading={setLoading}
                    setResendMailData={setResendMailData}
                />
            ) : (
                <motion.div
                    className={styles.container}
                    initial="out"
                    animate="in"
                    exit="out"
                    transition={motionParams.transition}
                    variants={motionParams.variants}
                >
                    <Row style={{ flexDirection: "column", marginBottom: 5 }}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                            <Button
                                type="primary"
                                shape="circle"
                                size="small"
                                icon={<BiArrowBack />}
                                className={styles.backButton}
                                onClick={handleClickLogin}
                            />
                            <Typography.Text>Regresar</Typography.Text>
                        </div>
                        <Typography.Title level={4}>Registrarme</Typography.Title>
                    </Row>
                    <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        form={form}
                        style={{ width: "100%" }}
                        spellCheck="false"
                    >
                        <Row>
                            <Space size={16} className={styles.spaceNombre}>
                                <Form.Item label="Nombre(s)" name="nombre" rules={[{ required: true, message: "por favor ingresa tu nombre!" }]}>
                                    <Input onKeyDown={preventSpecialChars} />
                                </Form.Item>
                                <Space style={{ width: "100%", display: "flex" }}>
                                    <Form.Item
                                        label="A. Paterno"
                                        name="apaterno"
                                        onKeyDown={preventSpecialChars}
                                        rules={[{ required: true, message: "por favor ingresa tu apellido paterno!" }]}
                                        style={{ flex: 1, width: "100%", minWidth: "100px" }}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="A. Materno"
                                        name="amaterno"
                                        rules={[{ required: true, message: "por favor ingresa tu apellido materno!" }]}
                                        style={{ flex: 1, width: "100%", minWidth: "100px" }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Space>
                            </Space>
                        </Row>
                        <Row style={{ alignItems: "end" }}>
                            <Form.Item
                                label="Correo Eletrónico"
                                name="mail"
                                hasFeedback={true}
                                validateStatus={mailError.status}
                                help={mailError.message}
                                className={styles.mailInput}
                                style={{ flex: 0.6 }}
                                rules={[
                                    { required: true, message: "por favor ingresa tu correo electrónico!" },
                                    {
                                        type: "email",
                                        message: "El correo electrónico no es válido!",
                                    },
                                ]}
                            >
                                <Input type="email" onBlur={handleBlurMail} />
                            </Form.Item>
                            <Form.Item
                                label="Teléfono"
                                name="tel"
                                rules={[{ required: true, message: "por favor ingresa tu teléfono!" }]}
                                style={{ flex: 0.4, marginLeft: 10 }}
                            >
                                <MaskedInput mask={"(000)000-0000"} />
                            </Form.Item>
                            <Form.Item
                                name="usawhatsapp"
                                style={{ flex: 0.4, marginLeft: 10 }}
                                valuePropName="checked"
                            >
                                <Checkbox>¿Usa whatsapp?</Checkbox>
                            </Form.Item>
                        </Row>
                        <Row style={{ gap: 10 }}>
                            <Form.Item label="RFC la empresa" name="emp_rfc" style={{ flex: 0.6 }} rules={[{ required: true, message: "por favor ingresa RCF!" }, { max: 13, message: "Máximo 13 caracteres" }, { min: 12, message: "Mínimo 12 caracteres" }]} >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nombre de la empresa" name="emp" rules={[{ required: true, message: "por favor ingresa empresa!" }]} style={{ flex: 1.4 }}>
                                <Input />
                            </Form.Item>
                        </Row>
                    </Form>
                    <Checkbox checked={terminosChecked} onChange={handleChangeTerminos}>
                        Aceptar terminos y condiciones{" "}
                        <Button type="link" style={{ margin: 0, padding: 0 }} onClick={showModal}>
                            (ver)
                        </Button>
                    </Checkbox>
                    <Row style={{ marginTop: 10 }}>
                        <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={handleChangeCaptcha} />
                    </Row>
                    <Button
                        type="primary"
                        style={{ width: "100%", marginBottom: 10, marginTop: 15 }}
                        onClick={handleClickRegistro}
                        loading={loading}
                        disabled={!terminosChecked || !ValidatedCaptcha}
                    >
                        Registrarme
                    </Button>
                </motion.div>
            )}
            <Modal title="Aviso de privacidad" visible={show} onOk={closeModal} onCancel={closeModal}>
                <div
                    style={{
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        height: "750px",
                    }}
                >
                    <Viewer fileUrl="public/assets/files/aviso_privacidad.pdf" />
                </div>
            </Modal>
        </Row>
    );
};

const MessageSuccess = ({ handleClickLogin, resendMailData, loading, setLoading, setResendMailData }) => {
    const styles = useStyles();
    const api = useApi();
    const [form] = Form.useForm();
    const motionParams = useSelector((state) => state.motion);
    const { apiHost } = useSelector((state) => state.app);
    const [showCambiarCorreo, setShowCambiarCorreo] = useState(false);
    const [hash, setHash] = useState(null);
    const [mailError, setMailError] = useState({
        message: null,
        status: null,
    });

    const handleClickCambiarCorreo = () => {
        setShowCambiarCorreo(true);
    };

    const handleClickRegresar = () => {
        setShowCambiarCorreo(false);
    };

    const handleClickGoToLogin = () => handleClickLogin("login");

    const handleClickReenviarCorreo = () => {
        setLoading(true);
        axios
            .post(`${apiHost}/registro/resend_mail_registro`, resendMailData)
            .then((response) => {
                if (response.data.success) {
                    message.success("Se te ha reenviado el correo");
                }
                setLoading(false);
            })
            .catch((err) => {
                message.error("Ocurrio un problema al reenviar el correo.");
                setLoading(false);
                console.log(err);
            });
    };

    const checkMail = () => {
        return new Promise((resolve, reject) => {
            form.validateFields(["mail"])
                .then((values) => {
                    // validar el mail del usuario
                    axios
                        .get(`${apiHost}/registro/mailcheck/${values.mail}`)
                        .then((response) => {
                            if (response.data.data) {
                                resolve(true);
                                setMailError({
                                    message: "El correo electrónico ya existe",
                                    status: "error",
                                });
                            } else {
                                resolve(false);
                                setMailError({
                                    message: null,
                                    status: null,
                                });
                            }
                        })
                        .catch((err) => {
                            reject(err);
                            console.error(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                    console.error(err);
                });
        });
    };

    const handleClickGuardarNuevoCorreo = () => {
        form.validateFields().then(({ mail }) => {
            api.noSessionPost(
                "/cambia_correo",
                {
                    name: resendMailData.name,
                    mail,
                    hash,
                },
                {
                    dataType: "json",
                    hasFormData: false,
                    hasCustomUrl: false,
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setResendMailData({ ...resendMailData, mail });
                        message.success(res.data.mensaje);
                        setShowCambiarCorreo(false);
                    } else {
                        message.error("Hubo un problema al cambiar el correo.");
                    }
                })
                .catch(() => {
                    message.error("Hubo un problema al cambiar el correo.");
                });
        });
    };

    const handleBlurMail = () => {
        if (checkMail) checkMail();
    };

    useEffect(() => {
        if (resendMailData) {
            let hash = resendMailData?.url?.split("genera_pass/");
            if (hash) {
                setHash(hash[1]);
            }
        }
    }, [resendMailData]);

    return !showCambiarCorreo ? (
        <motion.div
            className={styles.successContainer}
            initial="out"
            animate="in"
            exit="out"
            transition={motionParams.transition}
            variants={motionParams.variants}
        >
            <BiMailSend fontSize={50} style={{ color: "#00A757" }} />
            {/* <BsFillPersonCheckFill fontSize={50} style={{ color: "#00A757" }} /> */}
            <Typography.Title level={4}>Registro exitoso</Typography.Title>
            <Typography.Paragraph style={{ textAlign: "center", fontSize: 14 }}>
                Se te ha enviado un correo electrónico de verificación a la dirección{" "}
                <span className={styles.messageCorreo}>{resendMailData.mail}</span> , por favor revisa tu bandeja para seguir con tu registro.
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "center", fontSize: 14 }}>
                Por favor revisa en tu bandeja de spam en caso de que notes un retardo en el envio del correo.
            </Typography.Paragraph>
            <Space className="buttons" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Row style={{ gap: 16 }}>
                    <Button type="secondary" style={{ width: "100%", marginTop: 10, flex: 1 }} onClick={handleClickGoToLogin}>
                        Listo
                    </Button>
                    <Button type="primary" style={{ width: "100%", marginTop: 10, flex: 1 }} onClick={handleClickReenviarCorreo} loading={loading}>
                        Reenviar correo
                    </Button>
                </Row>
                <Row style={{ justifyContent: "center", marginBottom: 0, marginTop: 10 }}>
                    <Typography.Text>
                        Me equivoqué de correo electronico{" "}
                        <Button type="link" onClick={handleClickCambiarCorreo}>
                            (cambiar)
                        </Button>
                    </Typography.Text>
                </Row>
            </Space>
        </motion.div>
    ) : (
        <motion.div
            className={styles.successContainer}
            initial="out"
            animate="in"
            exit="out"
            transition={motionParams.transition}
            variants={motionParams.variants}
        >
            <Row style={{ flexDirection: "column", marginBottom: 5, width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: 5 }}>
                    <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<BiArrowBack />}
                        className={styles.backButton}
                        onClick={handleClickRegresar}
                    />
                    <Typography.Text>Regresar</Typography.Text>
                </div>
            </Row>
            <Row style={{ width: "100%" }}>
                <Form form={form} className={styles.nuevoCorreo} layout="vertical" style={{ width: "100%" }}>
                    <Form.Item
                        label="Correo Eletrónico"
                        name="mail"
                        hasFeedback={true}
                        validateStatus={mailError.status}
                        help={mailError.message}
                        className={styles.mailInput}
                        style={{ flex: 1 }}
                        rules={[
                            { required: true, message: "por favor ingresa tu correo electrónico!" },
                            {
                                type: "email",
                                message: "El correo electrónico no es válido!",
                            },
                        ]}
                    >
                        <Input type="email" onBlur={handleBlurMail} />
                    </Form.Item>
                </Form>
            </Row>
            <Space className="buttons" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Row style={{ gap: 16 }}>
                    <Button
                        type="primary"
                        style={{ width: "100%", marginTop: 10, flex: 1 }}
                        onClick={handleClickGuardarNuevoCorreo}
                        loading={loading}
                    >
                        Guardar nuevo correo
                    </Button>
                </Row>
            </Space>
        </motion.div>
    );
};

export default Registro;
