import { combineReducers } from "redux";
import motionReducer from "./motion.reducer";
import authReducer from "./auth.reducer";
import loadingReducer from "./loading.reducer";
import catalogosReducer from "./catalogos.reducer";
import ConfigReducer from "./config.reducer";
import AppReducer from "./app.reducer";
import socketReducer from "./socket.users.reducer";

export const reducers = combineReducers({
    motion: motionReducer,
    auth: authReducer,
    loading: loadingReducer,
    catalogos: catalogosReducer,
    configuracion: ConfigReducer,
    socketUser: socketReducer,
    app: AppReducer,
});