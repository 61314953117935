export const styles = () => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .ant-space-item": {
            width: "100%",
        },
        "& form": {
            width: "100%",
        },
    },
});
