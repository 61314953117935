export const styles = () => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .ant-space-item": {
            width: "100%",
        },
        "& form": {
            width: "100%",
        },
        "& .ant-table-content": {
            "& .ant-table-row": {
                userSelect: "none"
            }
        }
    },
    switchFormControl: {
        marginBottom: "5px !important",
        "& .ant-form-item-row": {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "row-reverse !important", 
            "& .ant-form-item-control": {
                maxWidth: 50,

            },
            "& .ant-form-item-label": {
                width: "fit-content",
            }
        }
    }
});
