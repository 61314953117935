import React from 'react';
import { Input } from "antd";
import { useState, useEffect } from "react";

// function formatNumber(value) {
//     value += "";
//     const list = value.split(".");
//     const prefix = list[0].charAt(0) === "-" ? "-" : "";
//     let num = prefix ? list[0].slice(1) : list[0];
//     let result = "";
//     while (num.length > 3) {
//         result = `,${num.slice(-3)}${result}`;
//         num = num.slice(0, num.length - 3);
//     }
//     if (num) {
//         result = num + result;
//     }
//     return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
// }

function useForceUpdate() {
    const [, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

const NumericInput = ({ ...props }) => {
    const forceUpdate = useForceUpdate();
    const [value, setValue] = useState(props.initEmpty ? "" : 0);

    const onChange = (e) => {
        const { value } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            props.onChange(value, e);
            setValue(value);
        }
    };

    const onBlur = (e) => {
        const { value, onBlur, onChange } = props;
        let valueTemp = value;
        if (value?.length > 0) {
            if (value.charAt(value.length - 1) === "." || value === "-") {
                valueTemp = value.slice(0, -1);
            }
            onChange(valueTemp.replace(/0*(\d+)/, "$1"), e);
            if (onBlur) {
                onBlur();
            }
        }
    };

    const handleFocus = (event) => {
        event.target.select()
    };

    useEffect(() => {
        forceUpdate();
    }, []);

    useEffect(() => {
        if (props.value) setValue(props.value)
    }, [props.value]);



    return (
        <Input
            {...props}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={props?.maxLength || 25}
            onFocus={props?.onFocus || handleFocus}
        />
    );
};

export default NumericInput;
