import { PaperClipOutlined, PlusOutlined, PoweroffOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Popover, Row, Spin, Timeline, Tooltip, Typography, message } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useApiTicketsSeguimiento } from "../../../hooks/api/tickets_incidencias";
import UsuarioPopoverCard from "../../Usuarios/UsuarioPopoverCard";
import { setPageScrollTop } from "../../../helpers/scroll";
import { IoTicketOutline } from "react-icons/io5";
import { useHistory, Link } from "react-router-dom";
import { TicketStatus } from "../../../@enums/Tickets.enums";

const useStyles = createUseStyles((theme) => ({
    wrapper: {
        width: "100%",
        padding: "20px 20px",
        "& .ant-spin-nested-loading": {
            width: "100%",
        },
        "& .ant-timeline": {
            width: "100%",
            "& .ant-timeline-item": {
                cursor: "pointer",
                userSelect: "none",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                    transform: "scale(1.03)"
                }
            }
        },
    },
    timeLineRow: {
        padding: "0 0 10px",
        transition: "all 0.2s ease-in-out",
        "& .ant-avatar": {
            backgroundColor: theme.primaryColor,
            verticalAlign: "middle",
        }
    },
}));

const SeguimientoRow = ({ data }) => {
    const styles = useStyles();
    const theme = useTheme();
    const [hasFiles, setHasFiles] = useState(false);
    const history = useHistory();

    const handleClickTicketHijo = e => {
        e.stopPropagation();
        setPageScrollTop();
        if (data?.ticket_dividido?.stat_id === TicketStatus.TICKET_LIBRE) {
            message.info("El ticket aún no se ha tomado");
            history.push(`/tickets`);
        } else {
            history.push(`/tickets/${data?.tick_divididoid}`);
        }
    }

    useEffect(() => {
        if (data?.recursos) {
            setHasFiles(data.recursos?.length > 0);
        }
    }, [data])

    return (
        <div className={styles.timeLineRow}>
            <Row style={{ gap: 16, justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", gap: 16, marginLeft: 10 }}>
                    <UsuarioPopoverCard avatarSize={24} popoverPlacement="bottomRight" usu_id={data?.creador?.usu_id} />
                    <div style={{ margin: 0 }}>{data?.tici_nombre}</div>
                </div>
                <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                    {
                        data?.tick_divididoid &&
                        <Popover
                            placement="topRight"
                            content={
                                <div>
                                    <Typography.Title level={5} style={{ margin: 0 }}>Ticket</Typography.Title>
                                    <Button type="link" onClick={handleClickTicketHijo} style={{ margin: 0, padding: 0 }}>{data?.ticket_dividido?.tick_clave}</Button>
                                </div>
                            }
                        >
                            <IoTicketOutline style={{ color: "#ee3311" }} />
                        </Popover>
                    }
                    <StarOutlined style={{ color: data?.tici_importante ? "gold" : null }} />
                    <PaperClipOutlined style={{ color: hasFiles ? theme.terciaryColor : "#000" }} />
                </div>
            </Row>
            <Row style={{ gap: 16, flexWrap: "wrap", justifyContent: "end" }}>
                <Typography.Text type="secondary">{DateTime.fromISO(data?.tici_fecha).toFormat("dd/MM/yyyy hh:mm a")}</Typography.Text>
            </Row>
        </div>
    );
};

const Seguimiento = ({ ticket, setSeguimientoSeleccionado, handleClickNuevoSeguimiento }) => {
    const styles = useStyles();
    const { isLoading, data, refetch: refetchData } = useApiTicketsSeguimiento({
        initialData: [],
    }, ticket?.tick_id);

    const handleClickSeleccionarSeguimiento = (seguimiento) => {
        if (setSeguimientoSeleccionado) setSeguimientoSeleccionado(seguimiento)
        setPageScrollTop()
    }

    useEffect(() => {
        setTimeout(() => {
            if (ticket?.tick_id) refetchData()
        }, 500);
    }, [ticket])


    return (
        <div className={styles.wrapper}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Title level={5}>Seguimiento</Typography.Title>
                {
                    handleClickNuevoSeguimiento &&
                    <Tooltip title="Nueva incidencia" placement="left">
                        <Button type="primary" shape="circle" icon={<PlusOutlined />} size={"small"} onClick={handleClickNuevoSeguimiento} />
                    </Tooltip>
                }
            </Row>
            <Row>
                <Spin spinning={isLoading} style={{ width: "100%" }}>
                    <Row style={{ marginTop: 10 }}>
                        {(data || []).length > 0 ? (
                            <Timeline >
                                {(data || []).map((seguimiento, iSeguimiento) => (
                                    <Timeline.Item
                                        onClick={() => handleClickSeleccionarSeguimiento(seguimiento)}
                                        style={{ padding: 0 }}
                                        key={`${seguimiento.tici_id}_${seguimiento.tick_id}}_seguimiento`}
                                        dot={
                                            <div
                                                style={{
                                                    width: 23,
                                                    height: 23,
                                                    border: "2px solid #1890ff",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "#000",
                                                    fontSize: 10,
                                                }}
                                            >
                                                {data?.length > 0 ? data?.length - iSeguimiento : null}
                                            </div>
                                        }
                                    >
                                        <SeguimientoRow data={seguimiento} />
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        ) : (
                            <Typography.Text>No hay actividad para mostrar</Typography.Text>
                        )}
                    </Row>
                </Spin>
            </Row>
        </div>
    );
};

export default Seguimiento;
