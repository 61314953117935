import React, { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Select, Button, message, Spin} from "antd";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Tabla from "./tabla/tabla";
import { GetDataGraficaACtividades } from "../../hooks/api/grafica_actividades";
import { GetDataGraficaACtividadesByAsesor } from "../../hooks/api/grafica_actividades";
import { GetDataGraficaACtividadesByDates } from "../../hooks/api/grafica_actividades";
import { GetDataGraficaACtividadesByAsesorDate } from "../../hooks/api/grafica_actividades";
import { GetTicketsGraficaGeneral } from "../../hooks/api/tickets_grafica";
import { GetTicketsGraficaUser } from "../../hooks/api/tickets_grafica";
import { GetTicketsGraficaDate } from "../../hooks/api/tickets_grafica";
import { GetTicketsGraficaDateUser } from "../../hooks/api/tickets_grafica";
import { GetAreasDepartamentos } from "../../hooks/api/areas_departamentos";
import { GetTicketsGraficaAreaDep } from "../../hooks/api/tickets_grafica";
import { GetTicketsGraficaDateAreaDep } from "../../hooks/api/tickets_grafica";
import { GetDataGraficaACtividadesByAreaDep } from "../../hooks/api/grafica_actividades";
import { GetDataGraficaACtividadesByAreaDepDate } from "../../hooks/api/grafica_actividades";
import { GetAllUsers } from "../../hooks/api/getAllusuarios";
import { GetAllPuestos } from "../../hooks/api/puestos";
import { calculaTiempoTranscurridoServicio } from "../../helpers/tickets.helper";
import Grafica from "./grafica/grafica";
import { motion } from "framer-motion";
import './styles.css'

const { RangePicker } = DatePicker;

const GraficaActividades = () => {

    const [form] = Form.useForm();

    const {getDataGraficaACtividadesMutation} = GetDataGraficaACtividades();
    const {getDataGraficaACtividadesMutationByAsesor} = GetDataGraficaACtividadesByAsesor();
    const {getDataGraficaACtividadesMutationByDates} = GetDataGraficaACtividadesByDates();
    const {getDataGraficaACtividadesMutationByAsesorDate} = GetDataGraficaACtividadesByAsesorDate();
    const {getTicketsGraficaGeneralMutation} = GetTicketsGraficaGeneral();
    const {getTicketsGraficaUserMutation} = GetTicketsGraficaUser();
    const {getTicketsGraficaDateMutation} = GetTicketsGraficaDate();
    const {getTicketsGraficaDateUserMutation} = GetTicketsGraficaDateUser();
    const {getAreasDepartamentosMutation} = GetAreasDepartamentos();
    const {getDataGraficaACtividadesMutationByAreaDep} = GetDataGraficaACtividadesByAreaDep();
    const {getDataGraficaACtividadesMutationByAreaDepDate} = GetDataGraficaACtividadesByAreaDepDate();
    const {getTicketsGraficaAreaDepMutation} = GetTicketsGraficaAreaDep();
    const {getTicketsGraficaDateAreaDepMutation } = GetTicketsGraficaDateAreaDep();
    const {getAllUsersMutation} = GetAllUsers();
    const {getAllPuestosMutation} = GetAllPuestos();

    const [data, setData] = useState(null);
    const [dataArray, setDataArray] = useState([]);
    const [secondListFilter, setSecondListFilter] = useState(null);
    const [firstFilterSelect, setFirstFilterSelect] = useState(false);
    const [dataSource, setDataSource] = useState(null)
    const [dataSourceArray, setDataSourceArray] = useState([])
    const [dataTitle, setDataTitle] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [loading, setLoading] = useState(true)

    const listFilters = [
        {value:1, label:'Area'},
        {value:2, label:'Departamento'},
        {value:3, label:'Asesor'},

    ]

    useEffect(() => {
        getGraficaGeneral(null, null)
    }, [])


    //DATA GENERAL

    const getGraficaGeneral = (fechaInit, fechaFin) =>{
        const fetchData = async () => {
            try {
                if(fechaInit !== null && fechaFin !== null) {
                    const activitiesData = await getDataDate(fechaInit, fechaFin);
                    const ticketsGeneral = await getTicketsDate(fechaInit, fechaFin);
                    console.log(ticketsGeneral)
                    orderData(activitiesData, ticketsGeneral)
                    setLoading(false)
                }else{
                    const activitiesData = await getActivitiesGeneral();
                    const ticketsGeneral = await getTicketsGeneral();
                    orderData(activitiesData, ticketsGeneral)
                    setLoading(false)
                }
                
            } catch (error) {
                console.log(error);
                setLoading(false)
                message.error('Ocurrio un error, limpie e intente de nuevo')
            }
        };
        
        fetchData();
    }

    const getActivitiesGeneral = () => {
        return new Promise((resolve, reject) => {
            getDataGraficaACtividadesMutation({},{
                onSuccess: (data) => {
                    resolve(data.data);
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
    })};
    const getTicketsGeneral = () => {
        return new Promise((resolve, reject) => {
            getTicketsGraficaGeneralMutation({},{
                onSuccess: (data) => {
                    console.log(data)
                    resolve(data);
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
    })};

    const orderData = (activitiesData, ticketsGeneral) => {
        const tiempos = ticketsGeneral.map(tick => calculaTiempoTranscurridoServicio(tick.incidencias).segundosTranscurridosServicio)
        console.log(tiempos)
        const tiempoTotal = ((tiempos.reduce((total, seconds) => total+seconds, 0))/3600).toFixed(2);
        activitiesData[0].labels.unshift('Tickets')
        activitiesData[0].datasets[0].data.unshift(tiempoTotal)
        setData(activitiesData[0])
        let objetTabla = {
                actividad: "Tickets",
                total: ticketsGeneral.length,
                tiempo: `${tiempoTotal} horas`
        }
        activitiesData[1].unshift(objetTabla)
        setDataSource(activitiesData[1])
    }

    const orderDataArray = (activitiesData, ticketsGeneral, title) => {
        console.log(activitiesData)
        console.log(ticketsGeneral)
        let titles = dataTitle;
        if(activitiesData[1].length > 0 && ticketsGeneral.length >= 0){
            titles.push(title)
            let dataPush = dataArray;
            let sourcePush = dataSourceArray;
            const tiempos = ticketsGeneral.map(tick => calculaTiempoTranscurridoServicio(tick.incidencias).segundosTranscurridosServicio)
            const tiempoTotal = ((tiempos.reduce((total, seconds) => total+seconds, 0))/3600).toFixed(2);
            activitiesData[0].labels.unshift('Tickets')
            activitiesData[0].datasets[0].data.unshift(tiempoTotal)
            dataPush.push(activitiesData[0])
    
            setDataArray(dataPush)
            let objetTabla = {
                    actividad: "Tickets",
                    total: ticketsGeneral.length,
                    tiempo: `${tiempoTotal} horas`
            }
            activitiesData[1].unshift(objetTabla)
            sourcePush.push(activitiesData[1])
            setDataSourceArray(sourcePush)
            setDataTitle(titles)
        }
        else if(activitiesData[1].length === 0 && ticketsGeneral.length >= 0){
            titles.push(title)
            let dataPush = dataArray;
            let sourcePush = dataSourceArray;
            const tiempos = ticketsGeneral.map(tick => calculaTiempoTranscurridoServicio(tick.incidencias).segundosTranscurridosServicio)
            const tiempoTotal = ((tiempos.reduce((total, seconds) => total+seconds, 0))/3600).toFixed(2);
            activitiesData[0].labels.push('Tickets')
            activitiesData[0].datasets[0].data.push(tiempoTotal)
            dataPush.push(activitiesData[0])
    
            setDataArray(dataPush)
            let objetTabla = {
                    actividad: "Tickets",
                    total: ticketsGeneral.length,
                    tiempo: `${tiempoTotal} horas`
            }
            activitiesData[1].push(objetTabla)
            sourcePush.push(activitiesData[1])
            setDataSourceArray(sourcePush)
            setDataTitle(titles)
        }
    }


    //FUNCIONES PARA FILTRAR

    const filterData = () => {
        setLoading(true)
        const values =  {
            firstFilter: form.getFieldValue('firstFilter'),
            secondFilter: form.getFieldValue('secondFilter'),
            dateFilter: form.getFieldValue('dateFilter')
        }
        setIsFilter(true)
        if(values.secondFilter !== undefined && values.dateFilter !== undefined){
            switch (values.firstFilter) {
                case 1:{
                    const fetchData = async () => {
                        try {
                            getGraficaGeneral( values.dateFilter[0], values.dateFilter[1])
                            for(let i = 0; i<values.secondFilter.length; i++){
                                let area = secondListFilter.find(ase => ase.value === values.secondFilter[i]).label;
                                const activitiesData = await getDataAreaDepDate(values.secondFilter[i], values.dateFilter[0], values.dateFilter[1]);
                                const ticketsGeneral = await getTicketsAreaDepDate(values.secondFilter[i], values.dateFilter[0], values.dateFilter[1]);
                                if(activitiesData && ticketsGeneral){
                                    orderDataArray(activitiesData, ticketsGeneral, area)
                                }
                            }
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                            message.error('Ocurrio un error, limpie e intente de nuevo')
                        }
                    };
                    fetchData();
                    break;
                }
                case 2:{
                    const fetchData = async () => {
                        try {
                            for(let i = 0; i<values.secondFilter.length; i++){
                                let area = secondListFilter.find(ase => ase.value === values.secondFilter[i]).label;
                                const activitiesData = await getDataAreaDepDate(values.secondFilter[i], values.dateFilter[0], values.dateFilter[1]);
                                const ticketsGeneral = await getTicketsAreaDepDate(values.secondFilter[i], values.dateFilter[0], values.dateFilter[1]);
                                if(activitiesData && ticketsGeneral){
                                    orderDataArray(activitiesData, ticketsGeneral,area)
                                }
                            }
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                            message.error('Ocurrio un error, limpie e intente de nuevo')
                        }
                    };
                    fetchData();
                    break;
                }
                case 3:{
                    const fetchData = async () => {
                        try {
                            for(let i = 0; i<values.secondFilter.length; i++){
                                let asesor = secondListFilter.find(ase => ase.value === values.secondFilter[i]).label;
                                const activitiesData = await getDataUserDate(values.secondFilter[i], values.dateFilter[0], values.dateFilter[1]);
                                const ticketsGeneral = await getTicketsUserDate(values.secondFilter[i], values.dateFilter[0], values.dateFilter[1]);
                                if(activitiesData && ticketsGeneral){
                                    orderDataArray(activitiesData, ticketsGeneral, asesor)
                                }
                            }
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                            message.error('Ocurrio un error, limpie e intente de nuevo')
                        }
                    };
                    fetchData();
                    break;
                }
                default:
                    break;
            }
        }else if(values.secondFilter !== undefined && values.dateFilter === undefined){
            switch (values.firstFilter) {
                case 1:{
                    const fetchData = async () => {
                        try { 
                            for(let i = 0; i<values.secondFilter.length; i++){
                                let area = secondListFilter.find(ase => ase.value === values.secondFilter[i]).label;
                                const activitiesData = await getDataAreaDep(values.secondFilter[i]);
                                const ticketsGeneral = await getTicketsAreaDep(values.secondFilter[i]);
                                if(activitiesData && ticketsGeneral){
                                    orderDataArray(activitiesData, ticketsGeneral, area)
                                }
                            }
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                            message.error('Ocurrio un error, limpie e intente de nuevo')
                        }
                    };
                    fetchData();
                    break;
                }
                case 2:{
                    const fetchData = async () => {
                        try { 
                            for(let i = 0; i<values.secondFilter.length; i++){
                                let area = secondListFilter.find(ase => ase.value === values.secondFilter[i]).label;
                                const activitiesData = await getDataAreaDep(values.secondFilter[i]);
                                const ticketsGeneral = await getTicketsAreaDep(values.secondFilter[i]);
                                if(activitiesData && ticketsGeneral){
                                    orderDataArray(activitiesData, ticketsGeneral, area)
                                }
                            }
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                            message.error('Ocurrio un error, limpie e intente de nuevo')
                        }
                    };
                    fetchData();
                    break;
                }
                case 3:{
                        const fetchData = async () => {
                            try { 
                                for(let i = 0; i<values.secondFilter.length; i++){
                                    let asesor = secondListFilter.find(ase => ase.value === values.secondFilter[i]).label;
                                    const activitiesData = await getDataUser(values.secondFilter[i]);
                                    const ticketsGeneral = await getTicketsUser(values.secondFilter[i]);
                                    if(activitiesData && ticketsGeneral){
                                        orderDataArray(activitiesData, ticketsGeneral, asesor)
                                    }
                                }
                                setLoading(false)
                            } catch (error) {
                                setLoading(false)
                                message.error('Ocurrio un error, limpie e intente de nuevo')
                            }
                        };
                        fetchData();
                    break;
                }
                default:
                    break;
            }
        }else if(values.secondFilter === undefined && values.dateFilter !== undefined){
            const fetchData = async () => {
                try {
                    getGraficaGeneral( values.dateFilter[0], values.dateFilter[1])
                    const departamentos = await getDepartamentos();
                    for (let i = 0; i < departamentos.length; i++) {
                        console.log(values.dateFilter[0])
                        const graficaGeneralDep = await getDataAreaDepDate(departamentos[i].value, values.dateFilter[0], values.dateFilter[1]);
                        const ticketGeneral = await getTicketsAreaDepDate(departamentos[i].value, values.dateFilter[0], values.dateFilter[1]);
                        if(graficaGeneralDep && ticketGeneral){
                            orderDataArray(graficaGeneralDep, ticketGeneral, departamentos[i].name)
                        }
                        const puestos = await getPuestoByAreaDep(departamentos[i].value);
                        for (let x = 0; x < puestos.length; x++) {
                            const users = await getUserByPuesto(puestos[x].pues_id);
                            for (let j = 0; j < users.length; j++) {
                                const name = `${capitalizeFirstLetter(users[j].usu_nombres)} ${capitalizeFirstLetter(users[j].usu_apaterno)}`
                                const activitiesUserDep = await getDataUserDate(users[j].usu_id, values.dateFilter[0], values.dateFilter[1]);
                                const ticketsUserDep = await getTicketsUserDate(users[j].usu_id, values.dateFilter[0], values.dateFilter[1]);
                                if(activitiesUserDep && ticketsUserDep){
                                    orderDataArray(activitiesUserDep, ticketsUserDep, name)
                                }
                            }
                        }

                    }
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    message.error('Ocurrio un error, limpie e intente de nuevo')
                }
            };
            
            fetchData();
        }else{
            message.error('Seleccione alguna opcion del filtro')
            setIsFilter(false)
        }
    }

    const onChangeFilter = (value) => {
        switch (value) {
            case 1:
                    getAreasDepartamentos(1)
                break;
            case 2:
                    getAreasDepartamentos(2)
                break;
            case 3:
                    getListUsers();
                break;
        
            default:
                break;
        }
    }

    const getListUsers = () => {
        getAllUsersMutation({},{
            onSuccess: (data) => {
                const listUsers = data.map(user => {
                    return {
                        value: user.usu_id,
                        label: `${capitalizeFirstLetter(user.usu_nombres)} ${capitalizeFirstLetter(user.usu_apaterno)}`
                    }
                })
                setSecondListFilter(listUsers)
                setFirstFilterSelect(true)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }
    
    const getAreasDepartamentos = (tipo) => {
        getAreasDepartamentosMutation({},{
            onSuccess: (data) => {
                let filterData = data.filter(ad => ad.ade_tipo === tipo);
                const lisAd = filterData.map(ad => {
                    return {
                        value: ad.ade_id,
                        label: `${capitalizeFirstLetter(ad.ade_nombre)}`
                    }
                })
                setSecondListFilter(lisAd)
                setFirstFilterSelect(true)

            },
            onError: (error) => {
                console.log(error)
            }
        })
        
    }

    const cleanFilter = () => {
            setLoading(true)
            form.resetFields();
            setDataArray([])
            setDataSourceArray([])
            setDataTitle([])
            setIsFilter(false)
            setLoading(false)
    }


    //OBTENCION DE DATOS PARA FILTRO


    //-----USER-----

    const getDataUser = (id) => {
        return new Promise((resolve, reject) => {
            getDataGraficaACtividadesMutationByAsesor({id}, {
                onSuccess: (data) => {
                    resolve(data.data)
                },
                onError: (error) => {
                    reject(error)
                }
            })
        })
    }
    const getTicketsUser = (id) => {
        return new Promise((resolve, reject) => {
            getTicketsGraficaUserMutation({id}, {
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    reject(error)
                }
            })
        })
    }

    //-----DATE------


    const getDataDate = (dateStart, dateEnd) => {   

        let start = dateStart.add(1, 'days').startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let end = dateEnd.endOf('month').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        return new Promise((resolve, reject) => {
            getDataGraficaACtividadesMutationByDates({start, end},{
                onSuccess: (data) => {
                    resolve(data.data)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }
    const getTicketsDate = (dateStart, dateEnd) => {
        let start = dateStart.add(1, 'days').startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let end = dateEnd.endOf('month').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        return new Promise((resolve, reject) => {
            getTicketsGraficaDateMutation({start, end},{
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }

    //-----DATE USER-----

    const getDataUserDate = (id, dateStart, dateEnd) => {

        let start = dateStart.add(1, 'days').startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let end = dateEnd.endOf('month').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        
        return new Promise((resolve, reject) => {
            getDataGraficaACtividadesMutationByAsesorDate({id, start, end},{
                onSuccess: (data) => {
                    resolve(data.data)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }
    const getTicketsUserDate = (id, dateStart, dateEnd) => {

        let start = dateStart.add(1, 'days').startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let end = dateEnd.endOf('month').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        
        return new Promise((resolve, reject) => {
            getTicketsGraficaDateUserMutation({id, start, end},{
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }

    //-----AREADEP-----

    const getDataAreaDep = (id) => {
        return new Promise((resolve, reject) => {
            getDataGraficaACtividadesMutationByAreaDep({id}, {
                onSuccess: (data) => {
                    resolve(data.data)
                },
                onError: (error) => {
                    reject(error)
                }
            })
        })
    }
    const getTicketsAreaDep = (id) => {
        return new Promise((resolve, reject) => {
            getTicketsGraficaAreaDepMutation({id}, {
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    reject(error)
                }
            })
        })
    }

    //-----AREADEP DATE------

    const getDataAreaDepDate = (id, dateStart, dateEnd) => {

        let start = dateStart.add(1, 'days').startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let end = dateEnd.endOf('month').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        
        return new Promise((resolve, reject) => {
            getDataGraficaACtividadesMutationByAreaDepDate({id, start, end},{
                onSuccess: (data) => {
                    resolve(data.data)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }
    const getTicketsAreaDepDate = (id, dateStart, dateEnd) => {

        let start = dateStart.add(1, 'days').startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let end = dateEnd.endOf('month').endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        
        return new Promise((resolve, reject) => {
            getTicketsGraficaDateAreaDepMutation({id, start, end},{
                onSuccess: (data) => {
                    resolve(data)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }

    //-----AREA USUARIOS-----

    const getPuestoByAreaDep = (id) => {
        return new Promise((resolve, reject) => {
            getAllPuestosMutation({},{
                onSuccess: (data) => {
                    const puestos = data.length > 0 ? 
                                        data.filter(puesto => puesto.areas_departamentos.some(ad => ad.ade_id == id))
                                        :0;
                    resolve(puestos)
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
        })
    }
    const getUserByPuesto = (id) => {
        return new Promise((resolve, reject) => {
            getAllUsersMutation({},{
                onSuccess: (data) => {
                    const listUsers = data.filter(usr => usr.pues_id === id);
                    resolve(listUsers)
                },
                onError: (error) => {
                    console.log(error);
                    reject(error);
                }
            })
        })
    }
    const getDepartamentos = () => {
        return new Promise((resolve, reject) => {
            getAreasDepartamentosMutation({},{
                onSuccess: (data) => {
                    let filterData = data.filter(ad => ad.ade_tipo === 2);
                    const lisAd = filterData.map(ad => {
                        return {
                            value: ad.ade_id,
                            name: `${capitalizeFirstLetter(ad.ade_nombre)}`
                        }
                    });
                    resolve(lisAd);
                },
                onError: (error) => {
                    console.log(error);
                    reject(error);
                }
            })
        })
    }


    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <div className="container" >
            <Spin spinning={loading}>
                <PageWrapper title="Grafica Actividades">
                    <Form style={{width:'100%'}} layout="vertical" form={form}>
                        <Row style={{display:'flex', justifyContent:'flex-end'}}>
                            <Col span={4} className="colFilters">
                                <Form.Item name='firstFilter' label='Filtrar por:' style={{width:'100%'}}>
                                    <Select
                                        style={{width:'100%'}}
                                        options={listFilters}
                                        onChange={onChangeFilter}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="colFilters">
                                <Form.Item name='secondFilter' style={{width:'100%'}}>
                                    <Select
                                        mode="multiple"
                                        style={{width:'100%'}}
                                        options={secondListFilter}
                                        onChange={onChangeFilter}
                                        disabled={!firstFilterSelect}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="colFilters">
                                <Form.Item name='dateFilter' label='Rango de fechas' style={{width:'100%'}}>
                                    <RangePicker className="rangeDate" picker="month"/>
                                </Form.Item>
                            </Col>
                            <Col span={2} className="colFilters" >
                                <Form.Item style={{width:'100%'}} >
                                <Button
                                    type='button'  className='btn-Filter' style={{height:'30px'}} onClick={()=>filterData()} disabled={isFilter}>
                                    Filtrar
                                </Button>
                                </Form.Item>
                            </Col>
                            <Col span={2} className="colFilters" >
                                <Form.Item style={{width:'100%'}}>
                                    <motion.button  whileHover={{ scale: 1.05}} whileTap={{ scale: 0.9}}
                                        className='btn-CleanFilter' type="button" onClick={cleanFilter} style={{height:'30px'}}>
                                        Limpiar
                                    </motion.button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {data !== null ? ( 
                        <Row style={{padding:'10px 0px'}}>
                            <Col span={16} style={{padding:10, display:'flex', justifyContent:'flex-start'}}>
                                <Grafica key={'Grafica_general'} data={data} title={'General'}/>
                            </Col>
                            <Col span={8} style={{padding:15}}>
                                <Row style={{width:'100%', marginBottom:15}}>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <span style={{fontWeight:600}}>Total de Actividades</span>
                                    </Col>
                                </Row>
                                <Row style={{width:'100%'}}>
                                    <Col span={24}>
                                        <Tabla key={'Tabla_general'} dataSource={dataSource}/>
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                    ): (
                        <p>No se pudieron recuperar los datos</p>
                    )}
                    {dataArray.length > 0 ? (
                        <>
                            {dataArray.map((data, index) => (
                                <Row key={index} style={{padding:'10px 0px'}}>
                                    <Col span={16} style={{padding:10, display:'flex', justifyContent:'flex-start'}}>
                                        <Grafica key={index} data={data} title={dataTitle[index]}/>
                                    </Col>
                                    <Col span={8} style={{padding:15}}>
                                        <Row style={{width:'100%', marginBottom:15}}>
                                            <Col span={24} style={{textAlign:'center'}}>
                                                <span style={{fontWeight:600}}>Total de Actividades</span>
                                            </Col>
                                        </Row>
                                        <Row style={{width:'100%'}}>
                                            <Col span={24}>
                                                <Tabla key={index} dataSource={dataSourceArray[index]}/>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                </Row>
                            ))}
                        </>
                    ) : null}
                </PageWrapper>
            </Spin>
        </div>
    )

}

export default GraficaActividades;