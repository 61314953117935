import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Form, message, Modal, Row, Select, Space, Tag, TimePicker, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import Elevation from "../../../components/Elevation";
import { useApiContactoHorarios, useContactoHorarioMutation } from "../../../hooks/api/contactos";
import { getKeyZonaHoraria } from "../../../@enums/Horarios.enum";
import moment from "moment";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

const useStyles = createUseStyles({
    contactoHorarios: {
        flex: 1,
        gap: 5,
        width: "100%",
        padding: 15,
        flexDirection: "column",
        "& .ant-form-item": {
            marginBottom: 12,
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            // opacity: 0,
            transition: "all 0.2s ease-in-out",
            // marginRight: -30,
        },
        "&:hover": {
            // "& .section-buttons": {
            //     transform: "scale(1)",
            //     opacity: 1,
            //     marginRight: 0,
            // },
        },
    },
    horariosItems: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 250px))",
        width: "100%",
        columnGap: 10,
        rowGap: 14
    },
    horarioItem: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        // alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        borderRadius: 10,
        background: "#f5f5f5",
        cursor: "default",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.03)"
        }
    },
    modalContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .ant-form-item": {
            flex: 1,
        },
    },
});

/**
 * 
 * @param {Object} props 
 * @param {Object} props.item - objeto del horario del contacto 
 * @param {React.CSSProperties} [props.style] - estilos extra del componente 
 * @param {Boolean} [props.allowDeleteHorario] - bandera que indica si se mostrará el boton para eliminar el horario 
 * @param {Boolean} [props.useApi] - bandera que indica si se usará el api para elminar el horario
 * @param {(horario: Object) => void} [props.onDeleteHorario] - callback que se ejecuta cuando se elimina un horario
 */
export const HorarioItem = ({ item, style, allowDeleteHorario, useApi = true, onDeleteHorario }) => {
    const styles = useStyles();
    /**
     * @type {Object}
     */
    const theme = useTheme();
    const { mutateContactoHorario, isLoadingContactoHorarioMutation, resetContactoHorarioMutation } = useContactoHorarioMutation();
    const queryClient = useQueryClient();

    const getDiaSemana = (dia) => {
        switch (dia) {
            case 1:
                return "Lunes";
            case 2:
                return "Martes";
            case 3:
                return "Miércoles";
            case 4:
                return "Jueves";
            case 5:
                return "Viernes";
            case 6:
                return "Sábado";
            case 7:
                return "Domingo";

            default:
                return "";
        }
    };

    const handleClickEliminarHorario = () => {
        if (useApi) {
            mutateContactoHorario(
                {
                    usho_id: item.usho_id,
                    mutationType: "delete"
                },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            resetContactoHorarioMutation();
                            message.success(res.mensaje);
                        } else {
                            message.error(res?.mensaje || "Hubo un problema al eliminar el horario");
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al eliminar el horario");
                    },
                }
            );
        } else {
            queryClient.setQueryData(["contacto", "horarios"], (oldData) => oldData.filter(h => h.usho_id !== item.usho_id));
            if (onDeleteHorario) onDeleteHorario(item);
        }
    };

    const getHour = (hour) => {
        if (!hour) return "";
        if (typeof hour === "string") return hour.substring(0, 5);
        return hour.format("HH:mm");
    }

    return (
        <div className={styles.horarioItem} style={style}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}  >
                <div style={{ display: "flex", alignItems: "start", flexDirection: "column", flex: 1 }}>
                    <div>
                        <Tag color={theme.primaryColor}>Días</Tag>
                    </div>
                    <div>
                        <Typography.Text>{getDiaSemana(item?.usho_diainicial)}</Typography.Text>
                        <Typography.Text> - </Typography.Text>
                        <Typography.Text>{getDiaSemana(item?.usho_diafinal)}</Typography.Text>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "start", flexDirection: "column", flex: 1 }}>
                    <div>
                        <Tag color={theme.secondaryColor}>Horario</Tag>
                    </div>
                    <div>
                        <Typography.Text>{getHour(item?.usho_horainicial)}</Typography.Text>
                        <Typography.Text> - </Typography.Text>
                        <Typography.Text>{getHour(item?.usho_horafinal)}</Typography.Text>
                    </div>
                </div>
                {
                    allowDeleteHorario &&
                    <div style={{ display: "flex", width: 20, justifyContent: "start", alignItems: "start" }}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<CloseOutlined />}
                            size="small"
                            onClick={handleClickEliminarHorario}
                            loading={isLoadingContactoHorarioMutation}
                        />
                    </div>
                }
            </div>
            <div>

                <div style={{ display: "flex", alignItems: "start", flexDirection: "column", flex: 1 }}>
                    <div>
                        <Tag color={theme.terciaryColor}>Zona horaria</Tag>
                    </div>
                    <div>
                        {
                            getKeyZonaHoraria(item?.usho_usohorario)?.replace("_", " ") || "Sin zona"
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const formInitialValues = {
    usho_diainicial: null,
    usho_diafinal: null,
    usho_horainicial: null,
    usho_horafinal: null,
    usho_usohorario: null,
};

/**
 * @typedef {Object} ContactoHorariosProps
 * @property {Number} [con_id] - id del contacto (solo si el contacto ya se ha creado)
 * @property {(values: Object) => void} [onCreateContactoHorario] - callback que se ejecuta cuando se crea un horario
 * @property {React.CSSProperties} [style] - estilos extra del componente
 * @property {Boolean} [useApi] - bandera que indica si se usará el api para obtener y guardar los horarios
 * @property {(horario: Object) => void} [onDeleteHorario] - callback que se ejecuta cuando se elimina un horario
 */

/**
 * 
 * @param {ContactoHorariosProps} props 
 * @returns 
 */
const ContactoHorarios = ({
    // props for styles
    style,

    // props for manage api services and data
    con_id,
    useApi = true,

    // props for manage data in local state
    onCreateContactoHorario,
    onDeleteHorario
}) => {
    const styles = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [form] = Form.useForm();
    const { mutateContactoHorario, isLoadingContactoHorarioMutation, resetContactoHorarioMutation } = useContactoHorarioMutation();
    const queryClient = useQueryClient();
    const { data: horarios } = useApiContactoHorarios({
        initialData: [],
        enabled: useApi
    }, con_id);

    const closeModal = () => setShowModal(false);

    const handleClickAgregarHorario = () => setShowModal(true);

    const handleGuardarHorario = () => {
        if (useApi) {
            form.validateFields()
                .then((values) => {
                    values.usho_horainicial = values.usho_horainicial.format("HH:mm:ss");
                    values.usho_horafinal = values.usho_horafinal.format("HH:mm:ss");

                    mutateContactoHorario(
                        { ...values, mutationType: "create", usu_id: con_id },
                        {
                            onSuccess: (res) => {
                                if (res?.success) {
                                    closeModal();
                                    resetContactoHorarioMutation();
                                    form.setFieldsValue(formInitialValues);
                                    message.success(res.mensaje);
                                    if (onCreateContactoHorario) onCreateContactoHorario(values);
                                } else {
                                    message.error(res?.mensaje || "Hubo un problema al agregar el horario");
                                }
                            },
                            onError: () => {
                                message.error("Hubo un problema al agregar el horario");
                            },
                        }
                    );
                })
                .catch(() => message.error("Aun hay campos faltantes"));
        } else {
            form.validateFields().then((horario) => {
                horario = { ...horario, usho_id: uuidv4() };
                queryClient.setQueryData(["contacto", "horarios"], (oldData) => [...oldData, horario]);
                if (onCreateContactoHorario) onCreateContactoHorario(horario);
                closeModal();
                form.resetFields();
            }).catch(() => message.error("Aun hay campos faltantes"));
        }
    };

    const handleTimePickerKeyDown = (field, e) => {
        if (e.key === "Tab") {
            let time = moment(e.target.value, "HH:mm");
            form.setFieldValue(field, time);
        }
    };

    const handleDeleteHorario = (horario) => {
        if (!useApi) {
            onDeleteHorario(horario);
        }
    }

    useEffect(() => {
        form.setFieldsValue(formInitialValues);
    }, []);

    return (
        <Elevation className={styles.contactoHorarios} style={style}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Horarios</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={handleClickAgregarHorario}
                    // loading={isLoadingEmpresaMutation}
                    />
                </div>
            </Row>
            <Row className={styles.horariosItems}>
                {horarios?.map((horario) => (
                    <HorarioItem
                        item={horario}
                        key={`${horario.usho_id}_${horario.usho_horainicial}`}
                        allowDeleteHorario={true}
                        useApi={useApi}
                        onDeleteHorario={handleDeleteHorario}
                    />
                ))}
            </Row>

            <Modal title="Agregar Horario" open={showModal} footer={null} onCancel={closeModal} destroyOnClose={true} width={300}>
                <div className={styles.modalContent}>
                    <Form layout="vertical" form={form}>
                        <Row>
                            <Space style={{ flexDirection: "column", width: "100%" }}>
                                <div style={{ width: "100%", display: "flex", gap: 16 }}>
                                    <Form.Item
                                        name="usho_diainicial"
                                        label="Día Inicial"
                                        rules={[{ required: true, message: "El dia inicial es requerido" }]}
                                    >
                                        <Select>
                                            <Select.Option value={1}>Lunes</Select.Option>
                                            <Select.Option value={2}>Martes</Select.Option>
                                            <Select.Option value={3}>Miércoles</Select.Option>
                                            <Select.Option value={4}>Jueves</Select.Option>
                                            <Select.Option value={5}>Viernes</Select.Option>
                                            <Select.Option value={6}>Sábado</Select.Option>
                                            <Select.Option value={7}>Domingo</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="usho_diafinal"
                                        label="Día Final"
                                        rules={[{ required: true, message: "El dia final es requerido" }]}
                                    >
                                        <Select>
                                            <Select.Option value={1}>Lunes</Select.Option>
                                            <Select.Option value={2}>Martes</Select.Option>
                                            <Select.Option value={3}>Miércoles</Select.Option>
                                            <Select.Option value={4}>Jueves</Select.Option>
                                            <Select.Option value={5}>Viernes</Select.Option>
                                            <Select.Option value={6}>Sábado</Select.Option>
                                            <Select.Option value={7}>Domingo</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{ width: "100%", display: "flex", gap: 16 }}>
                                    <Form.Item
                                        name="usho_horainicial"
                                        label="Hora Inicial"
                                        rules={[{ required: true, message: "La hora inicial es requerida" }]}
                                    >
                                        <TimePicker format="HH:mm" placeholder="Seleccionar" showNow={false} onKeyDown={(e) => handleTimePickerKeyDown("usho_horainicial", e)} />
                                    </Form.Item>
                                    <Form.Item
                                        name="usho_horafinal"
                                        label="Hora Final"
                                        rules={[{ required: true, message: "La hora final es requerida" }]}
                                    >
                                        <TimePicker format="HH:mm" placeholder="Seleccionar" showNow={false} onKeyDown={(e) => handleTimePickerKeyDown("usho_horafinal", e)} />
                                    </Form.Item>
                                </div>
                                <div style={{ width: "100%", display: "flex", gap: 16 }}>
                                    <Form.Item
                                        name="usho_usohorario"
                                        label="Uso horario"
                                        rules={[{ required: true, message: "El uso horario es requerido" }]}
                                    >
                                        <Select>
                                            <Select.Option value={"UTC-5"}>Zona Sureste</Select.Option>
                                            <Select.Option value={"UTC-6"}>Zona Centro</Select.Option>
                                            <Select.Option value={"UTC-7"}>Zona Pacífico</Select.Option>
                                            <Select.Option value={"UTC-8"}>Zona Noroeste</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Space>
                        </Row>
                    </Form>
                    <Row style={{ justifyContent: "end" }}>
                        <Button type="primary" onClick={handleGuardarHorario} loading={isLoadingContactoHorarioMutation}>
                            Guardar horario
                        </Button>
                    </Row>
                    {/* 
                        solo agregar campos del apartado de generales 
                        y crear vista a parte para agregar y modifica los demas campos de la empresa
                    */}
                </div>
            </Modal>
        </Elevation>
    );
};

export default ContactoHorarios;
