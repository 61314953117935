const styles = (theme) => ({
    listaEspera: {
        flex: 1,
        // minWidth: 350,
    },
    siguientesList: {
        "& .ant-timeline-item-head-blue": {
            color: "#FC4B6C",
            borderColor: "#FC4B6C",
        },
    },
    draggerWrapper: {
        marginBottom: 10,
        "& span": {
            width: "100%",
            // flex: 1,
            // justifyContent: "flex-start",
            "& .ant-upload-drag": {
                padding: "10px 20px 0",
            },
            "& .ant-upload.ant-upload-select": {
                // display: "flex",
            },
        },
    },
    modalFooter: {
        marginTop: 15,
        borderTop: "1px solid #ddd",
        background: "#fff",
        width: "100%",
        padding: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
    },
    textEditorWrapper: {
        border: "1px solid #eee",
        flex: 1,
        height: "100%",
        "& > .rich-textbox-title": {
            padding: "5px 10px",
            fontSize: 16,
            background: theme.primaryColor,
            color: "#fff",
        },
    },
    newTicketFormSpace: {
        width: "100%",
        alignItems: "start",
        flexWrap: "wrap",
        "& .ant-space-item": {
            flex: 1,
            height: "100%",
            "& .ant-form-item": {
                minWidth: "100px",
            },
        },
    },
    "@media (max-width: 600px)": {
        newTicketFormSpace: {
            "& .ant-space-item": {
                "& .ant-form-item": {
                    minWidth: "300px",
                },
            },
        },
    },
});

export default styles;
