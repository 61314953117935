import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatUrlQuery } from "./utils";

export const useApiUsuario = (options, id) => {
    const api = useApi();
    return useQuery(
        ["usuario", id],
        async () => {
            const { data } = await api.get(`/usuarios/${id}`);
            return data?.data;
        },
        options
    );
};

export const useApiUsuarios = (options, params) => {
    const api = useApi();
    return useQuery(
        ["usuarios"],
        async () => {
            if (params) {
                const { data } = await api.get(`/usuarios?${formatUrlQuery(params)}`);
                return data || [];
            } else {
                const { data } = await api.get(`/usuarios`);
                return data || [];
            }
        },
        options
    );
};

export const useUsuariosMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/usuarios`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.usu_id;
            delete params.usu_id;
            const { data } = await api.put(`/usuarios/${id}`, params, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/puestos/${params.cacu_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateUsuario,
        isLoading: isLoadingUsuarioMutation,
        error: errorUsuarioMutation,
        reset: resetUsuarioMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["usuarios"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateUsuario,
        isLoadingUsuarioMutation,
        errorUsuarioMutation,
        resetUsuarioMutation,
    };
};


// -------------------------- foto del usuario ------------------------------

export const useProfilePhotoMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        // if (params.mutationType === "create") {
        //     delete params.mutationType;
        //     const { data } = await api.post(`/usuarios/${}/profile`, params, {
        //         dataType: "json",
        //         hasFormData: false,
        //         hasCustomUrl: false,
        //     });
        //     return data;
        // }
        if (params.mutationType === "update") {
            delete params.mutationType;
            const { data } = await api.put(`/usuarios/${params.id}/profile_image`, params.data, { hasFormData: true });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/acuerdos-servicio/${params.cacu_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateProfilePhoto,
        isLoading: isLoadingProfilePhotoMutation,
        error: errorProfilePhotoMutation,
        reset: resetProfilePhotoMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["acuerdos-servicio"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateProfilePhoto,
        isLoadingProfilePhotoMutation,
        errorProfilePhotoMutation,
        resetProfilePhotoMutation,
    };
};




// -------------------- domicilios de los usuarios
export const useApiUsuarioDomicilios = (options, id) => {
    const api = useApi();
    return useQuery(
        ["usuarios", "domicilios"],
        async () => {
            const { data } = await api.get(`/usuarios/${id}/domicilios`);
            return data;
        },
        options
    );
};



export const useUsuariosDomiciliosMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/usuarios/${params.id}/domicilios`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.dom_id;
            let usu_id = params.id;
            delete params.id;
            delete params.dom_id;
            const { data } = await api.put(`/usuarios/${usu_id}/domicilios/${id}`, params, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/usuarios/${params.id}/campos_extra/${params.came_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateUsuariosDomicilios,
        isLoading: isLoadingUsuariosDomiciliosMutation,
        error: errorUsuariosDomiciliosMutation,
        reset: resetUsuariosDomiciliosMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["usuarios", "domicilios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateUsuariosDomicilios,
        isLoadingUsuariosDomiciliosMutation,
        errorUsuariosDomiciliosMutation,
        resetUsuariosDomiciliosMutation,
    };
};


//  ----------------- certificaciones del usuario ------------------------

export const useApiUsuariosCertificaciones = (options, id) => {
    const api = useApi();
    return useQuery(
        ["usuarios", id, "certificaciones"],
        async () => {
            const { data } = await api.get(`/usuarios/${id}/certificaciones`);
            return data;
        },
        options
    );
};

export const useUsuarioCertificacionesMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/usuarios/${params.id}/certificaciones`, params.data, { hasFormData: true });
            return data;
        }
        if (params.mutationType === "update") {  
            delete params.mutationType;
            let id = params.id_cert;
            delete params.id_cert;
            const { data } = await api.put(`/usuarios/${params?.id}/certificaciones/${id}`, params.data, { hasFormData: true });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/usuarios/${params.id}/campos_extra/${params.came_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateUsuariosCertificaciones,
        isLoading: isLoadingUsuariosCertificacionesMutation,
        error: errorUsuariosCertificacionesMutation,
        reset: resetUsuariosCertificacionesMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["usuarios", "certificaciones"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateUsuariosCertificaciones,
        isLoadingUsuariosCertificacionesMutation,
        errorUsuariosCertificacionesMutation,
        resetUsuariosCertificacionesMutation,
    };
};

//  ------------------------  contraseña del usuario -------------------------------

export const useSendAuthorizationChangingPasswordCode = () => {
    const api = useApi();

    /**
     * 
     * @param {String} usu_mail - correo del usuario al cual se le va a enviar el codigo
     */
    const sendCode = async (usu_mail) => {
        const response = await api.post(`/usuarios/reestablece-pass/code/send`, { usu_mail }, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(sendCode);

    return {
        sendAuthorizationCode: mutation.mutate,
        isSenddingAuthorizationCode: mutation.isLoading,
        isSenddedAuthorizationCode: mutation.isSuccess,
        isSenddingAuthorizationCodeError: mutation.isError,
    };
};


/**
 * 
 * @typedef ChangingPasswordValidateCodeDto
 * @property {String} usu_mail - id del puesto al que se le va a agregar el area o departamento   
 * @property {String} codigo - el codigo que se ha capturado
 */

export const useValidateChangingPasswordCode = () => {
    const api = useApi();

    /**
     * 
     * @param {ChangingPasswordValidateCodeDto} validatingCodeParams - datos para poder validar codigo del usuario
     */
    const sendCode = async (validatingCodeParams) => {
        const response = await api.post(`/usuarios/reestablece-pass/code/validate`, validatingCodeParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(sendCode);

    return {
        validatePassAuthorizationCode: mutation.mutate,
        isValidatingPassAuthorizationCode: mutation.isLoading,
        isValidatedPassAuthorizationCode: mutation.isSuccess,
        isValidatingPassAuthorizationCodeError: mutation.isError,
    };
};


export const useChangePassword = () => {
    const api = useApi();

    const changePass = async (usu_pass) => {
        const response = await api.put(`/usuarios/reestablece-pass`, { usu_pass }, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(changePass);

    return {
        changePassword: mutation.mutate,
        isChangingPassword: mutation.isLoading,
        isChangedPassword: mutation.isSuccess,
        isChangingPasswordError: mutation.isError,
    };
};