import { NewTicketAgenteDto } from "../../@types/TicketsAgentes.types";
import { TicketReasignationDto } from "../../@types/Tickets.types";
import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatUrlQuery } from "./utils";

export const useTicketMutation = (options) => {
    const api = useApi();

    const createTicket = async (ticket) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/tickets`, ticket, { dataType: "multipart", hasFormData: true, hasCustomUrl: false }).then((response) => {
                    if (response?.data?.success) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }).catch((error) => {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    };

    const {
        mutate: mutateTicket,
        isLoading: isLoadingTicketMutation,
        error: errorTicketMutation,
        reset: resetTicketMutation,
    } = useMutation(createTicket);

    return {
        mutateTicket,
        isLoadingTicketMutation,
        errorTicketMutation,
        resetTicketMutation,
    };
};

export const useTomarTicketMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const tomarTicket = async (ticketId) => {
        const { data } = await api.put(`/tickets/${ticketId}/tomar`, {}, { dataType: "json", hasFormData: false, hasCustomUrl: false });
        return data;
    };

    const {
        mutate: mutateTomarTicket,
        isLoading: isLoadingTomarTicketMutation,
        error: errorTomarTicketMutation,
        reset: resetTomarTicketMutation,
    } = useMutation(tomarTicket, {
        onSuccess: () => {
            queryClient.invalidateQueries("tickets");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateTomarTicket,
        isLoadingTomarTicketMutation,
        errorTomarTicketMutation,
        resetTomarTicketMutation,
    };
};

export const useLiberarTicket = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    /**
     * 
     * @param {Number} tick_id - id del ticket que se va a liberar
     */
    const liberarTicket = async (tick_id) => {
        const { data } = await api.post(`/tickets/liberar`, { tick_id }, { dataType: "json", hasFormData: false, hasCustomUrl: false });
        return data;
    };

    const mutation = useMutation(liberarTicket);

    return {
        liberarTicket: mutation.mutate,
        isLoadingLiberarTicket: mutation.isLoading,
        errorLiberarTicket: mutation.error,
        resetLiberarTicket: mutation.reset,
    };
};


export const useReasignarTicketMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    /**
     * @param {TicketReasignationDto} ticketReasignacionData - objeto de reasignacion de ticket.
     */
    const reasignarTicket = async (ticketReasignacionData) => {
        const { data } = await api.put(
            `/tickets/${ticketReasignacionData.tick_id}/reasignar`,
            ticketReasignacionData,
            { dataType: "json", hasFormData: false, hasCustomUrl: false }
        );
        return data;
    };

    const {
        mutate: mutateReasignarTicket,
        isLoading: isLoadingReasignarTicketMutation,
        error: errorReasignarTicketMutation,
        reset: resetReasignarTicketMutation,
    } = useMutation(reasignarTicket, {
        onSuccess: () => {
            queryClient.invalidateQueries("tickets");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateReasignarTicket,
        isLoadingReasignarTicketMutation,
        errorReasignarTicketMutation,
        resetReasignarTicketMutation,
    };
};

export const useTicketUpdateMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const updateTicket = async (ticket) => {
        const { data } = await api.put(`/tickets/${ticket?.tick_id}`, ticket, { dataType: "json", hasFormData: false, hasCustomUrl: false });
        return data;
    };

    const {
        mutate: mutateTicketUpdate,
        isLoading: isLoadingTicketUpdateMutation,
        error: errorTicketUpdateMutation,
        reset: resetTicketUpdateMutation,
    } = useMutation(updateTicket, {
        onSuccess: () => {
            queryClient.invalidateQueries("tickets");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateTicketUpdate,
        isLoadingTicketUpdateMutation,
        errorTicketUpdateMutation,
        resetTicketUpdateMutation,
    };
};

export const useApiTickets = (options) => {
    const api = useApi();
    return useQuery(
        ["tickets"],
        async () => {
            const { data } = await api.get(`/tickets`);
            return data;
        },
        options
    );
};

export const useApiTicketsStatus = (options, { statusID, params }) => {
    const api = useApi();
    return useQuery(
        ["tickets", "status", statusID],
        async () => {
            console.log(params)
            if (!params?.tipoPosicion) delete params?.tipoPosicion;
            if (params) {
                const { data: resultData } = await api.get(`/tickets/status/${statusID}?${formatUrlQuery(params)}`);
                return resultData || [];
            } else {
                const { data } = await api.get(`/tickets/status/${statusID}`);
                return data || [];
            }
        },
        options
    );
};

export const useApiTicketsId = (options, ticketId) => {
    const api = useApi();
    return useQuery(
        ["tickets", `${ticketId}`],
        async () => {
            const { data } = await api.get(`/tickets/${ticketId}`);
            return data;
        },
        options
    );
};

export const useApiCountTicketsStatus = (options, { statusID, params }) => {
    const api = useApi();
    return useQuery(
        ["tickets", "status", statusID, "count"],
        async () => {
            if (!params?.tipoPosicion) delete params?.tipoPosicion;
            if (params) {
                const { data: resultData } = await api.get(`/tickets/status/${statusID}/count?${formatUrlQuery(params)}`);
                return resultData || [];
            } else {
                const { data } = await api.get(`/tickets/status/${statusID}/count`);
                return data || [];
            }
        },
        options
    );
};

/**
 * 
 * @param {Object} options 
 * @param {Object} [options.initialData] - datos iniciales antes de recibir los de la solicitud
 * @returns 
 */
export const useApiTicketsObservador = (options, { params }) => {
    const api = useApi();
    return useQuery(
        ["tickets", "observador"],
        async () => {
            if (params) {
                const { data: resultData } = await api.get(`/tickets/observacion?${formatUrlQuery(params)}`);
                return resultData || [];
            } else {
                const { data } = await api.get(`/tickets/observacion`);
                return data || [];
            }
        },
        options
    );
};

/**
 * 
 * @param {Object} options 
 * @param {Object} [options.initialData] - datos iniciales antes de recibir los de la solicitud
 * @returns 
 */
export const useApiCountTicketsObservador = (options, { params }) => {
    const api = useApi();
    return useQuery(
        ["tickets", "observador", "count"],
        async () => {
            if (params) {
                const { data: resultData } = await api.get(`/tickets/observacion?${formatUrlQuery(params)}`);
                return resultData || 0;
            } else {
                const { data } = await api.get(`/tickets/observacion?get_count=1`);
                return data || 0;
            }
        },
        options
    );
};

// ******************** servicios de agentes para el ticket ********************************

export const useAgregarTicketAgente = () => {
    const api = useApi();

    /**
     * 
     * @param {NewTicketAgenteDto} agente - id del ticket al que se le va a agregar el agente
     */
    const agregarAgente = async (agente) => {
        const { data } = await api.post(`/tickets/${agente?.tick_id}/agentes`, agente, { dataType: "json", hasFormData: false, hasCustomUrl: false });
        return data;
    };

    const mutation = useMutation(agregarAgente);

    return {
        agregarTicketAgente: mutation.mutate,
        isLoadingAgregarTicketAgente: mutation.isLoading,
        errorAgregarTicketAgente: mutation.error,
        resetAgregarTicketAgente: mutation.reset,
    };
};