import React, {useEffect, useState} from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { Row, Col, Spin } from "antd";
import Elevation from "../../components/Elevation";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import DataTable from "../../components/DataTable";
import { CodigosVigentes } from "../../hooks/api/codigos_vigentes";
import { createUseStyles } from "react-jss";
import { DateTime } from "luxon";

const useStyles = createUseStyles(() => ({
    modalCrearEmpresa: {
        "& .ant-modal-body": {
            padding: 0
        },
        "& .ant-modal-header": {
            borderBottom: 0
        }
    },
    dataTable: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
}));


const CodigosSesion = () => {

    const renderDate = (date) => {
        return DateTime.fromISO(date).toFormat("dd/MM/yyyy hh:mm a");
    };

    const columns = [
        { index: "codi_direccion", title: "Correo del Cliente", width: 200, },
        { index: "codi_codigo", title: "Código", width: 120, allowEditing: false },
        { index: "codi_fechaexpiracion", title: "Fecha de vencimiento", width: 250, render: renderDate},
    ];

    const {codigosVigentesMutation} = CodigosVigentes();

    const styles = useStyles();

    const [codes, setCodes] = useState(null)
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [search, setSearch] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        codigosVigentesMutation({}, {
            onSuccess: (data) => {
                setCodes(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        const intervalId = setInterval(() => getData(), 2000);
        return () => clearInterval(intervalId);
    }, []);

    return(
        <PageWrapper title="Códigos de acceso de nuevos usuarios">
            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando usuarios ..." spinning={codes === null}>
                            <DataTableHeader
                                    key={'table-companies'}
                                    buttons={[]}
                                    dataToFilter={codes}
                                    setFilteredData={setFilteredCodes}
                                    counter={search  ? filteredCodes?.length : codes?.length}
                                    setSearch={setSearch}
                                />
                            {codes !== null && (
                                <DataTable
                                className={styles.dataTable}
                                columns={columns}
                                data={search  ? filteredCodes : codes}
                                scroll={{ x: "100%" }}
                                paginationParams={{
                                    defaultPageSize: 50,
                                    total: codes.length,
                                    showSizeChanger: true,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    position: ["topRight"]
                                }}
                            />
                            )}
                        </Spin>
                    </Elevation>
                </Col>
            </Row>
        </PageWrapper>
    )
}

export default CodigosSesion;