import React from 'react';
import PrivateRoute from './PrivateRoute';
import { createUseStyles, useTheme } from 'react-jss';
import { Breadcrumb, Row, Typography } from 'antd';
import { Switch, Link } from 'react-router-dom';
import Anuncios from '../views/Administracion/Marketing/Anuncios';
import CrearAnuncio from '../views/Administracion/Marketing/Anuncios/CrearAnuncio';
import Administracion from '../views/Administracion';
import Campanias from '../views/Administracion/Marketing/Campanias';
import Campania from '../views/Administracion/Marketing/Campanias/Campania';
import ActividadesCatalogo from '../views/actividadesCatalogo/actividadesCatalogo';
import KnowBaseAdministration from '../views/Administracion/KnowBase';
import ProductosServicios from '../views/Productos-Servicios/productos-servicios';

const useStyles = createUseStyles(theme => ({
    ventasModuleContainer: {
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px 0",
    },
    moduleHeader: {
        padding: "0px 10px 10px 0px",
        display: "flex"
    },
    moduleActions: {
        display: "flex",
        alignItems: "end",
        gap: 10,
        marginLeft: 20
    },
    btnNavigation: {
        boxShadow: "rgba(0, 0, 0, 0.10) 0px 3px 10px 0px",
        minWidth: 80,
        border: "none",
        transition: "all 0.2s ease-in-out",
        fontWeight: 500,
        padding: "5px 10px",
        textAlign: "center",
        cursor: 'pointer',
        borderRadius: 20,
        "&:hover": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        },
        "&.active": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        }
    }
}));

const AdministracionRouter = () => {
    const styles = useStyles();

    return (
        <div className={styles.ventasModuleContainer}>
            <Row style={{ padding: "0px 10px 10px", display: "flex", flexDirection: "column" }}>
                <div style={{marginBottom: 10}}>
                    {/* <Breadcrumb >
                        <Breadcrumb.Item >Home</Breadcrumb.Item>
                        <Breadcrumb.Item >Campañas</Breadcrumb.Item>
                    </Breadcrumb> */}
                </div>
                <div className={styles.moduleHeader}>
                    <Typography.Title level={2} style={{ margin: 0 }}>Administracion</Typography.Title>
                    <div className={styles.moduleActions}>
                        <Link to="/administracion/marketing/campañas" className={`${styles.btnNavigation}`} >Campañas</Link>
                        <Link to="/administracion/knowbase" className={`${styles.btnNavigation}`} >Base de conocimiento</Link>
                        {/* <Link to="/ventas/tickets" className={`${styles.btnNavigation}`}>Tickets</Link>
                    <Link to="/ventas/calendario" className={styles.btnNavigation}>Calendario</Link> */}
                    </div>
                </div>
            </Row>
            <Row style={{ width: "100%" }}>
                <Switch>
                    <PrivateRoute exact path={"/administracion"} key="/administracion">
                        <Administracion />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/administracion/knowbase"} key="/administracion/knowbase">
                        <KnowBaseAdministration />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/administracion/marketing/campañas"} key="/administracion/marketing/campañas">
                        <Campanias />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/administracion/marketing/campañas/:camp_id"} key="/administracion/marketing/campañas/:camp_id">
                        <Campania />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/administracion/marketing/campañas/:camp_id/anuncios"} key="/administracion/marketing/campañas/:camp_id/anuncios">
                        <Anuncios />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/administracion/marketing/campañas/:camp_id/anuncios/crear"} key="/anuncios/crear">
                        <CrearAnuncio />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/administracion/productos-servicios"} key="/productos-servicios">
                        <ProductosServicios />
                    </PrivateRoute>
                    {/* <PrivateRoute exact path={"/ventas/calendario"} key="/ventas/cotizaciones">
                        <VentasCalendario />
                    </PrivateRoute>
                    <PrivateRoute exact path={"/ventas/cotizaciones"} key="/ventas/cotizaciones">
                        <Cotizaciones />
                    </PrivateRoute> */}
                </Switch>
            </Row>
        </div>
    );
}

export default AdministracionRouter;