/**
 * @typedef {Object} TipoNotificacion
 */
export const TipoNotificacion = Object.freeze({
    INFORMACION: 1,
    RECORDATORIO: 2,
    OPERACION_EXITOSA: 3,
    APOYO_REQUERIDO: 4,
    ERROR_EN_OPERACION: 5,
    WARNING: 6
});
