import { Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Elevation from "../../components/Elevation";

const useStyles = createUseStyles((theme) => ({
    card: {
        flex: 1,
        display: "flex",
        // maxWidth: 300,
        width: "100%",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.04)",
            "& .card-ico": {
                color: theme.secondaryColor,
            },
            "& .card-title": {
                color: theme.secondaryColor,
            },
        },
        "& .card-title": {
            transition: "all 0.2s ease-in-out",
        },
        "& .card-ico": {
            padding: "30px 20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.2s ease-in-out",
        },
        "& .card-info": {
            padding: "10px 20px 10px 10px",
        },
        "& .card-description": {
            fontSize: 14,
            color: "#949494",
        },
    },
    svgAnimated: {
        fill: "none",
        "&:hover": {
            fill: ""
        }
    }
}));

const MenuOptionCard = ({ data, className }) => {
    const styles = useStyles();
    const history = useHistory();

    const handleCardClick = () => {
        history.push(data?.redirectTo)
    }

    return (
        <Elevation className={`${styles.card} ${className || ""}`} onClick={handleCardClick}>
            <div className="card-ico">
                {data?.icon}
            </div>
            <div className="card-info">
                <Typography.Title level={3} className="card-title">
                    {data?.title}
                </Typography.Title>
                <Typography.Paragraph className="card-description">{data?.description}</Typography.Paragraph>
            </div>
        </Elevation>
    );
};

export default MenuOptionCard;
