import { DateTime } from "luxon";

// formatos de fechas para el sistema
export const DATE_FORMATS = Object.freeze({
    // DATE
    DATE_LUXON_FORMAT_SLASH_TO_STR: "dd/MM/yyyy",
    DATE_LUXON_FORMAT_TO_STR: "yyyy-MM-dd",
    DATE_MOMENT_FORMAT_TO_STR: "yyyy-MM-DD",
    DATE_MOMENT_FORMAT_SLASH_TO_STR: "DD/MM/yyyy",
    // DATETIME
    DATETIME_LUXON_FORMAT_TO_STR: "yyyy-MM-dd hh:mm:ss",
    DATETIME24_LUXON_FORMAT_TO_STR: "yyyy-MM-dd HH:mm:ss",
});


export const getSystemDateTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

// ******* conversion de fechas ***********

/**
 * @param {String} isoDateStr - string de la fecha en formato iso
 * @returns {DateTime} - objeto datetime con zona actual del sistema 
 */
export const convertToSystemDateTimeZone = 
    (isoDateStr) => DateTime.fromISO(isoDateStr).setZone(getSystemDateTimeZone());