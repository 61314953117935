import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useApiEmpresas } from "../../hooks/api/empresas";
import { useHistory } from "react-router-dom";
import DataTable from "../../components/DataTable";
import CrearEmpresa from "./CrearEmpresa";
import { createUseStyles } from "react-jss";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import { GetEmpresas } from "../../hooks/api/get_empresas";

const useStyles = createUseStyles(() => ({
    modalCrearEmpresa: {
        "& .ant-modal-body": {
            padding: 0
        },
        "& .ant-modal-header": {
            borderBottom: 0
        }
    },
    dataTable: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
}));

const Empresas = () => {

    const {getEmpresasMutation} = GetEmpresas();

    const styles = useStyles();
    const history = useHistory();
    const [filteredEmpresas, setFilteredEmpresas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formEmpresa] = Form.useForm();
    const [empresas, setEmpresas] = useState(null)
    const [spin, setSpin] = useState(true)
    const [search, setSearch] = useState(false)

    const handleClickShowModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        getEmpresas();
        setShowModal(false)
    }


    const getEstatusTemplate = (_, empresa) => {
        return empresa.emp_estatus ? "Activo" : "Inactivo";
    };

    const handleSelectedRow = (empresa) => {
        history.push(`/empresas/${empresa.emp_id}`);
    };


    const columns = [
        { index: "emp_codigo", title: "Código", width: 120, allowEditing: false },
        { index: "emp_razonsocial", title: "Razón Social", width: 200, },
        { index: "emp_alias", title: "Alias", width: 250, },
        { index: "emp_rfc", title: "RFC", width: 200, },
        { index: "vendedor_name", title: "Vendedor", width: 200,},
        { index: "emp_estatus", title: "Estatus", width: 150, render: getEstatusTemplate }
    ];

    useEffect(() => {
        formEmpresa.setFieldsValue({
            emp_codigo: null,
            emp_vendedor: null,
            emp_razonsocial: null,
            emp_nombrecomercial: null,
            emp_alias: null,
            emp_padreid: null,
            emp_rfc: null,
        });
        getEmpresas()
    }, [formEmpresa]);

    const getEmpresas = () => {
        getEmpresasMutation({params: {
            include_seller_data: true
        }}, {
            onSuccess: (data) => {
                data.map((empresa) => {
                        if(empresa?.vendedor){
                            empresa.vendedor_name =  `${empresa.vendedor?.usu_nombres} ${empresa.vendedor?.usu_amaterno} ${empresa.vendedor?.usu_apaterno}`
                        }else{
                            empresa.vendedor_name = 'Sin vendedor'
                        }
                    }
                )
                setEmpresas(data)
                setSpin(false)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    return (
        <PageWrapper title="Empresas">
            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando empresas ..." spinning={spin}>
                            <DataTableHeader
                                key={'table-companies'}
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: handleClickShowModal
                                    }
                                ]}
                                dataToFilter={empresas}
                                setFilteredData={setFilteredEmpresas}
                                counter={search  ? filteredEmpresas?.length : empresas?.length}
                                setSearch={setSearch}
                            />
                            {empresas !== null && (
                                <DataTable
                                className={styles.dataTable}
                                columns={columns}
                                data={search  ? filteredEmpresas : empresas}
                                onRowClick={handleSelectedRow}
                                scroll={{ x: "100%" }}
                                paginationParams={{
                                    defaultPageSize: 50,
                                    total: empresas.length,
                                    showSizeChanger: true,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    position: ["topRight"]
                                }}
                            />
                            )}
                        </Spin>
                    </Elevation>
                </Col>
            </Row>
            <Modal title="Crear Empresa" className={styles.modalCrearEmpresa} open={showModal} footer={null} onCancel={closeModal} destroyOnClose={true} width={"70%"}>
                <CrearEmpresa closeParent={closeModal} />
            </Modal>
        </PageWrapper>
    );
};

export default Empresas;
