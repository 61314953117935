import { Badge, Button, Image, Row, Typography } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import Elevation from "../../../../../components/Elevation";
import { EyeOutlined } from "@ant-design/icons";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        display: "flex",
        minHeight: "calc(100vh - 175px)",
        gap: 10
    },
    datosGeneralesCampania: {
        minWidth: 300,
        maxWidth: 400,
        padding: 10,
        height: "100%",
    },
    anunciosCampania: {
        height: "100%",
        flex: 1,
        padding: 10,
    },
    anuncioItem: {
        perspective: "150rem",
        position: "relative",
        height: 400,
        maxWidth: 350,
        minWidth: 300,
        boxShadow: "none",
        background: "none",
        "&:hover": {
            "& .front": {
                transform: "rotateY(180deg)"
            },
            "& .back": {
                transform: "rotateY(0deg)"
            }
        },
        "& .card-side": {
            transition: "all 0.3s ease",
            backfaceVisibility: "hidden",
            position: "absolute",
            height: 400,
            top: 0,
            left: 0,
            flex: 1,
            background: "#FFF",
            zIndex: 1,
            border: "1px solid #eee",
            cursor: "default",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

            "&.back": {
                transform: "rotateY(-180deg)",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            },
        },
    },
    anuncioBody: {
        padding: "7px 10px",
        position: "relative",
        background: "#fff"
    },
    anuncioActions: {

    },
    customBtn: {
        background: `${theme.secondaryColor}11`,
        color: `${theme.secondaryColor}`,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: `${theme.secondaryColor}22`,
            transform: "scale(1.03)",
        }
    },
}));

const AnuncioItem = () => {
    const styles = useStyles();

    return (
        <div className={styles.anuncioItem}>
            <div className="card-side front">
                <div>
                    <Image
                        width={"100%"}
                        src="https://kronox.com.mx/wp-content/uploads/2019/03/Silder-06-WEB-KK-2019.jpg"
                    />
                </div>
                <div className={styles.anuncioBody}>
                    <Row>
                        <Typography.Text style={{ fontWeight: 500 }}>Anuncio nombre</Typography.Text>
                        <Typography.Text style={{ fontSize: 11, overflowY: "auto" }}>Fugiat eiusmod magna excepteur et dolor est mollit velit incididunt. Magna adipisicing dolor sit id laboris eiusmod consequat est occaecat tempor cillum duis cillum qui. Ut veniam incididunt anim ipsum. Consequat commodo eiusmod consequat Lorem ad proident duis est. Do commodo elit irure velit ex ad nisi sunt. Occaecat do tempor ex mollit laboris nostrud proident sit voluptate sunt anim culpa tempor quis.</Typography.Text>
                    </Row>
                </div>
            </div>
            <div className="card-side back" style={{ padding: 20 }}>
                <Row style={{ alignItems: "center", justifyContent: "space-between", marginBottom: 5 }}>
                    <Typography.Text style={{ fontWeight: 500, fontSize: 15 }}>Anuncio nombre</Typography.Text>
                    <div style={{ paddingRight: 12 }}>
                        {/* <Badge count={20} >
                            <Button className={styles.customBtn} icon={<EyeOutlined />} shape="circle"></Button>
                        </Badge> */}
                    </div>
                </Row>
                <Row>
                    <Typography.Text style={{ fontSize: 11, overflowY: "auto" }}>Fugiat eiusmod magna excepteur et dolor est mollit velit incididunt. Magna adipisicing dolor sit id laboris eiusmod consequat est occaecat tempor cillum duis cillum qui. Ut veniam incididunt anim ipsum. Consequat commodo eiusmod consequat Lorem ad proident duis est. Do commodo elit irure velit ex ad nisi sunt. Occaecat do tempor ex mollit laboris nostrud proident sit voluptate sunt anim culpa tempor quis.</Typography.Text>
                </Row>
                <Row style={{justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                        <div>ENVIADOS</div>
                    </div>
                    <div>
                        
                    </div>
                    <div>

                    </div>
                    {/* <Badge count={20} >
                        <Button className={styles.customBtn} icon={<EyeOutlined />} shape="circle"></Button>
                    </Badge> */}
                </Row>
            </div>
        </div>
    );
}


const Campania = () => {
    const theme = useTheme();
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <div className={styles.datosGeneralesCampania}>
                <Elevation style={{ padding: "20px", borderRadius: 15 }}>
                    {/* <Row>
                        <Typography.Title level={5}>Datos generales</Typography.Title>
                    </Row> */}
                    <Row style={{ flexDirection: "column" }}>
                        <Typography.Title level={4} style={{
                            marginBottom: 2,
                        }}
                        >Campaña de renovacion de licencias contabilidad</Typography.Title>
                        {/* <Typography.Paragraph style={{width: "100%"}}>NOMBRE DE LA CAMPAÑA</Typography.Paragraph> */}
                    </Row>
                    <div style={{ background: theme.primaryColor, width: "13%", height: 5, margin: "7px 0 20px 0" }}></div>
                    <Row style={{ flexDirection: "column" }}>
                        <Typography.Title level={5} style={{ marginBottom: 2 }}>Descripcion</Typography.Title>
                        <Typography.Paragraph style={{ width: "100%" }}>Ex cillum officia est magna commodo irure enim eiusmod fugiat laboris.</Typography.Paragraph>
                    </Row>
                    <Row style={{ flexDirection: "column" }}>
                        <Typography.Title level={5} style={{ marginBottom: 2 }}>Objetivo</Typography.Title>
                        <Typography.Paragraph style={{ width: "100%" }}>Officia commodo pariatur tempor ullamco occaecat nostrud reprehenderit tempor laboris cillum eiusmod aliquip. Laboris sint id nulla nulla excepteur. Laboris cupidatat enim aute labore cupidatat sit ad sit cillum officia magna. Ad ut occaecat commodo excepteur magna dolore quis nulla.</Typography.Paragraph>
                    </Row>
                </Elevation>
            </div>
            <div className={styles.anunciosCampania}>
                <Row style={{ marginBottom: 10 }}>
                    <Typography.Title level={4} >Anuncios y mensajes</Typography.Title>
                </Row>
                <Row style={{ gap: 30 }}>
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                    <AnuncioItem />
                </Row>
            </div>
        </div>
    );
}

export default Campania;