import { Button, Checkbox, Form, Input, message, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { styles } from "./styles";
import { usePuestosMutation } from "../../../hooks/api/puestos";
import { useApiAreasDepartamentos } from "../../../hooks/api/areas_departamentos";
import { TipoAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";

const useStyles = createUseStyles(styles);

const puestoDefault = {
    pues_nombre: null,
    pues_clave: null,
    pues_descripcion: null,
    pues_objetivo: null,
    area: null,
    departamento: null
}

const CrearPuesto = ({ closeModal }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const { isLoadingPuestoMutation, mutatePuesto } = usePuestosMutation();
    const { data: areasDepartamentos } = useApiAreasDepartamentos();
    const [areas, setAreas] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [disableArea, setDisableArea] = useState(true);

    const handleGuardarAreaDeparamento = () => {
        form.validateFields().then((values) => {
            // console.log(values);
            mutatePuesto(
                { ...values, mutationType: "create" },
                {
                    onSuccess: (response) => {
                        if (response.success) {
                            message.success("Puesto guardado exitosamente");
                        } else {
                            message.error(response.mensaje);
                        }
                        closeModal();
                        form.setFieldsValue(puestoDefault)
                    },
                    onError: (error) => {
                        console.log(error);
                        message.error("Ocurrió un problema al registrar el puesto");
                    },
                }
            );
        });
    };

    const handleChangeDepartamento = (ade_id) => {
        // se busca el departamento para saber si  tiene un area relacionada
        let depto = departamentos.find(ade => ade.ade_id === ade_id);
        if (depto && depto?.ade_padreid) {
            // setDisableArea(false);
            form.setFieldValue("area", depto?.ade_padreid);
        } else {
            // setDisableArea(true);
            form.setFieldValue("area", null);
        }
    }

    useEffect(() => {
        form.setFieldsValue(puestoDefault);
    }, [])

    useEffect(() => {
        if (areasDepartamentos) {
            // se separan las areas y los departamentos
            const { areas, departamentos } = areasDepartamentos.reduce((values, ade) => {
                if (ade.ade_tipo === TipoAreaDepartamento.AREA) {
                    values.areas.push(ade);
                } else if (ade.ade_tipo === TipoAreaDepartamento.DEPARTAMENTO) {
                    values.departamentos.push(ade);
                } else {
                    values.otro.push(ade);
                }
                return values
            }, { areas: [], departamentos: [], otro: [] });
            setAreas(areas)
            setDepartamentos(departamentos)
        }
    }, [areasDepartamentos])

    return (
        <Space className={styles.formWrapper} size={10}>
            <Row>
                <Typography.Title level={4}>Crear puesto</Typography.Title>
            </Row>
            <Row>
                <Form form={form} layout="vertical">
                    <Row style={{ gap: 10 }}>
                        <Form.Item style={{ flex: 1 }} name="pues_nombre" label="Nombre" rules={[{ required: true, message: "El nombre es requerido." }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="pues_clave" label="Clave" >
                            <Input />
                        </Form.Item>
                        {/* <Form.Item name="pues_posicionventas" style={{ display: "flex", alignItems: "end" }} valuePropName="checked">
                            <Checkbox>Posición de ventas</Checkbox>
                        </Form.Item> */}
                    </Row>
                    <Row style={{ gap: 10 }}>
                        <Form.Item name="departamento" label="Departamento" style={{ flex: 1 }}>
                            <Select
                                notFoundContent="No hay departamentos para mostrar"
                                onChange={handleChangeDepartamento}
                            >
                                {
                                    departamentos.map((departamento, index) => (
                                        <Select.Option key={index} value={departamento.ade_id}>{departamento.ade_nombre}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="area" label="Área" style={{ flex: 1 }}>
                            <Select
                                notFoundContent="No hay áreas para mostrar"
                                disabled={disableArea}
                            >
                                {
                                    areas.map((area, index) => (
                                        <Select.Option key={index} value={area.ade_id}>{area.ade_nombre}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Row>
                    <Form.Item name="pues_descripcion" label="Descripción" >
                        <Input.TextArea showCount maxLength={255} autoSize />
                    </Form.Item>
                    <Form.Item name="pues_objetivo" label="Objetivo">
                        <Input.TextArea showCount maxLength={255} autoSize />
                    </Form.Item>
                </Form>
            </Row>
            <Row style={{ justifyContent: "end", gap: 10 }}>
                <Button type="secondary" onClick={closeModal}>
                    Cancelar
                </Button>
                <Button type="primary" onClick={handleGuardarAreaDeparamento} loading={isLoadingPuestoMutation}>
                    Guardar
                </Button>
            </Row>
        </Space>
    );
};

export default CrearPuesto;
