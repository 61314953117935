import { Button, Card, DatePicker, Descriptions, Form, Input, message, Modal, Row, Select, Tooltip, Typography, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import Elevation from '../../../components/Elevation';
import certificadoPng from "../../../../images/certificado.png";
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, FileDoneOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { useModal } from '../../../hooks/useModal';
import { useApiSistemas } from '../../../hooks/api/sistemas';
import Dragger from 'antd/lib/upload/Dragger';
import { motion, AnimatePresence } from "framer-motion"
import { useApiUsuariosCertificaciones, useUsuarioCertificacionesMutation } from '../../../hooks/api/usuarios';
import moment from 'moment';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { DATE_FORMATS } from '../../../helpers/dates.helper';
import { DeleteCertifiacion } from '../../../hooks/api/usuarios_certificaciones';
import { useSelector } from 'react-redux';
const { confirm } = Modal;


const useStyles = createUseStyles(theme => ({
    LogoCerftificacionCard: {
        userSelect: "none",
        // width: "30%",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 10px 0px !important",
        transition: "all 0.2s ease-in-out",
        border: `1px solid #eee`,
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.05)",
            border: `1px solid ${theme.secondaryColor}`
        },
        "& .ant-card-body": {
            display: "flex",
            justifyContent: "center"
        }
    },
    draggerContainer: {
        "& > span": {
            width: "100%"
        }
    },
    btn_delete: {
        backgroundColor: 'red',
        border: 'none',
        "&:hover":{
            border: 'none',
            backgroundColor: 'red',
            scale:1.1
        },
        "&:focus":{
            backgroundColor: 'red',
        }
    },
    btn_update: {
        border: 'none',
        "&:hover":{
            border: 'none',
            scale:1.1
        },
    }
}))

const LogroCertificacionCard = ({ index, cert, showPromiseConfirm, showModal, setIsEdit, form, setFileList, setIdEdit }) => {
    const styles = useStyles();
    const tiposCert = ["Certificación", "Acreditación", "Curso", "Diplomado", "Otro"]

    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));

    const fillFormEdit = (certificado) => {
        form.resetFields();
        const initialFormValues = {
            usce_titulo: certificado.usce_titulo,
            usce_descripcion: certificado.usce_descripcion,
            fecha:  moment(certificado.usce_fechaaprobado),
            vigencia: moment(certificado.usce_fechavigencia),
            usce_tipo: certificado.usce_tipo,
            sis_id: certificado.sis_id
        }
        setIdEdit(certificado?.usce_id)
        console.log(certificado)
        let filesExis = certificado?.recursos?.length > 0 ? certificado?.recursos.map(recu => {
            return{
                uid: recu.recu_id,
                name: recu.recu_nombre,
                status: 'done',
                url: `${apiHost}/static/${recu.recu_ruta}`,
            }
        }): [];
        setFileList(filesExis)
        setIsEdit(true);
        form.setFieldsValue(initialFormValues);
        showModal();
    }

    return (
        <motion.div
            layout
            transition={{ duration: 0.3, delay: index * 0.15, type: "spring" }}
            initial={{ opacity: 0, transform: "scale(0.7)" }}
            animate={{ opacity: 1, transform: "scale(1)" }}
            exit={{ opacity: 0, transform: "scale(0.7)" }}

        >
            <Elevation className={styles.LogoCerftificacionCard}>
                <div
                    style={{ display: "flex", alignItems: "start", padding: 20 }}
                >
                    <div style={{ width: 100 }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            {/* <GrCertificate fontSize={80} /> */}
                            <img src={certificadoPng} width={70} height={70} />
                        </div>
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <Row>
                            <Typography.Title level={4} style={{ margin: 0 }}>{cert?.usce_titulo}</Typography.Title>
                        </Row>
                        <Row>
                            <Descriptions
                                labelStyle={{ color: "#1890ff", fontWeight: 600, maxWidth: 80, display: "flex", alignItems: "center" }}
                                contentStyle={{ display: "flex", alignItems: "center", padding: "0 10px" }}
                                size="middle"
                                column={4}
                            >
                                <Descriptions.Item label="Aprobado" span={4}>
                                    {
                                        cert?.usce_fechaaprobado ? DateTime.fromISO(cert.usce_fechaaprobado).toFormat("dd/MM/yyyy") : null
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Vigencia" span={4}>
                                    {
                                        cert?.usce_fechavigencia ? DateTime.fromISO(cert.usce_fechavigencia).toFormat("dd/MM/yyyy") : null
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Tipo" span={4}>
                                    {tiposCert[cert?.usce_tipo - 1]}
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                        <Row style={{ display: "flex", flexDirection: "column" }}>
                            <Typography.Title level={5} style={{ margin: 0 }}>Descripción</Typography.Title>
                            <Typography.Text >{cert?.usce_descripcion ? cert.usce_descripcion : "-"}</Typography.Text>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Button shape='circle' className={styles.btn_delete} onClick={()=>showPromiseConfirm(cert)}><DeleteOutlined  style={{color:'white'}} /></Button>
                        </Row>
                        <Row style={{marginTop:10}}>
                            <Button shape='circle' type="primary" className={styles.btn_update} onClick={()=>fillFormEdit(cert)}><EditOutlined style={{color:'white'}}/></Button>
                        </Row>
                    </div>
                </div>
            </Elevation>
        </motion.div>
    );
}


const Certificaciones = ({ usu }) => {
    const styles = useStyles();
    const [show, showModal, closeModal] = useModal();
    const [form] = Form.useForm();
    const { data: sistemas, isLoading: isLoadingSistemas } = useApiSistemas({ initialData: [] });
    const { mutateUsuariosCertificaciones, isLoadingUsuariosCertificacionesMutation, resetUsuariosCertificacionesMutation } = useUsuarioCertificacionesMutation();
    const [fileList, setFileList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [idEdit, setIdEdit] = useState(null);
    const { data: certificaciones, isLoading: isLoadingCertificaciones, refetch: refetchCertificaciones } = useApiUsuariosCertificaciones({
        initialData: []
    }, usu?.usu_id)
    const initialFormValues = {
        usce_titulo: null,
        usce_descripcion: null,
        fecha: null,
        vigencia: null,
        usce_tipo: null,
        sis_id: null
    }

    const {deleteCertificacionMutation} = DeleteCertifiacion();

    const handleClickAgregarCertificacion = () => {
        form.resetFields();
        setIsEdit(false)
        setFileList([]);
        showModal()
    }

    const handleClickGuardarCertificacion = () => {
        form.validateFields().then(values => {
            let fd = new FormData();

            // se formatean las fechass
            fd.append("usce_fechaaprobado", moment(values.fecha).format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR))
            fd.append("usce_fechavigencia", moment(values.vigencia).format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR))

            delete values.fecha;
            delete values.vigencia;

            // se agregan todos los campos al form data
            for (let key in values) {
                if (values[key])
                    fd.append(key, values[key])
            }

            console.log(fileList)

            // se agregar el archivo al formDatas
            if (fileList.length > 0)
                fd.append("files[]", fileList[0]);

                mutateUsuariosCertificaciones({
                    ...values,
                    mutationType: isEdit ? "update" : "create",
                    id: usu?.usu_id,
                    id_cert: idEdit,
                    data: fd
                }, {
                    onSuccess: (res) => {
                        if (res.success) {
                            refetchCertificaciones();
                            message.success("Se guardo la certificación")
                            closeModal();
                            setIdEdit(null);
                            setIsEdit(false);
                            form.setFieldsValue(initialFormValues)
                            setFileList([])
                            resetUsuariosCertificacionesMutation();
                        } else {
                            message.error("Hubo un problema la crear la certificación")
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema la crear la certificación")
                    }
                })

        }).catch(() => message.error("Hubo un problema la crear la certificación"))
    }

    const handleChangeFileDragger = (info) => {
        setFileList([info.file])
    }

    const renderCertificationCards = useCallback(
        (cert, iCert) => 
            <LogroCertificacionCard cert={cert} key={`logo_certificacion_${iCert}`} index={iCert + 1}  
                                    showPromiseConfirm={showPromiseConfirm} showModal={showModal} setIsEdit={setIsEdit} form={form} 
                                    setFileList={setFileList} setIdEdit={setIdEdit}/>, [certificaciones]
    )

    useEffect(() => {
        form.setFieldsValue(initialFormValues);
        console.log(certificaciones)
    }, [])

    const showPromiseConfirm = (cert) => {
        confirm({
            title: '¿Esta seguro de borrar este registro?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                return new Promise((resolve, reject) => {
                    console.log(cert)
                    deleteCertificacionMutation({usu_id : cert.usu_id, id_cert: cert.usce_id}, {
                        onSuccess: (data) => {
                            message.success('Certificado eliminado exitosamente')
                            refetchCertificaciones();
                            resetUsuariosCertificacionesMutation();
                            resolve(true)
                        },
                        onError: (error) => {
                            console.log(error)
                            message.error('Hubo un error al eliminar el recurso')
                            reject(error)
                        }
                    })
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch();
            },
        });
    };

    return (
        <div>
            <Row style={{ marginBottom: 20, gap: 10, alignItems: "center", marginTop: 15, justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Certificaciones y logros</Typography.Text>
                    <FaStar fontSize={16} color={"gold"} />
                </div>
                <Tooltip title={"Agregar nuevo"} placement={"left"}>
                    <Button shape={"circle"} type="primary" icon={<PlusOutlined />} onClick={handleClickAgregarCertificacion} />
                </Tooltip>
            </Row>
            <Row style={{
                gap: 16,
                display: "grid",
                gridTemplateColumns: "repeat( auto-fit, minmax(320px, 1fr) )"
            }}>
                <AnimatePresence>
                    {
                        certificaciones.map(renderCertificationCards)
                    }
                </AnimatePresence>
            </Row>

            <Modal title="Agregar certificación" open={show} footer={null} onCancel={closeModal} destroyOnClose={true} width="400px">
                <Form form={form} layout="vertical">
                    <Form.Item name="usce_titulo" label="Título" rules={[{ required: true, message: "El titulo es requerido" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="usce_descripcion" label="Descripcion" >
                        <Input.TextArea showCount maxLength={255} />
                    </Form.Item>
                    <Row style={{ gap: 16 }}>
                        <Form.Item name="fecha" label="Fecha" style={{ flex: 1 }} rules={[{ required: true, message: "La fecha de aprovación es requerida" }]}>
                            <DatePicker format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name="vigencia" label="vigencia" style={{ flex: 1 }} rules={[{ required: true, message: "La vigencia es requerida" }]}>
                            <DatePicker format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} style={{ width: "100%" }} />
                        </Form.Item>
                    </Row>
                    <Row style={{ gap: 16 }}>
                        <Form.Item name="usce_tipo" label="tipo" style={{ flex: 1 }} rules={[{ required: true, message: "El tipo es requerido" }]}>
                            <Select>
                                <Select.Option value={1}>Certificación</Select.Option>
                                <Select.Option value={2}>Acreditación</Select.Option>
                                <Select.Option value={3}>Curso</Select.Option>
                                <Select.Option value={4}>Otro</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="sis_id" label="Sistema" style={{ flex: 1 }}>
                            <Select>
                                {
                                    sistemas.map((sis, Isis) => <Select.Option value={sis.sis_id} key={`${sis.sis_id}_${Isis}`}>{sis.sis_nombre}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Row>
                    <Row className={styles.draggerContainer}>
                        <Dragger
                            name='file'
                            multiple={false}
                            onChange={handleChangeFileDragger}
                            style={{ width: "100%" }}
                            beforeUpload={Upload.LIST_IGNORE}
                            showUploadList={false}
                            fileList={fileList}
                        >
                            <AnimatePresence>
                                {
                                    fileList.length === 0 ?
                                        <motion.div
                                            transition={{ duration: 0.3 }}
                                            initial={{ opacity: 0, transform: "scale(1.1)" }}
                                            animate={{ opacity: 1, transform: "scale(1)" }}
                                            exit={{ opacity: 0, transform: "scale(1.1)" }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Clickea o suelta archivos aqui para agregarlos</p>
                                        </motion.div>
                                        :
                                        <motion.div
                                            transition={{ duration: 0.3, delay: 0.3 }}
                                            initial={{ opacity: 0, transform: "scale(1.1)" }}
                                            animate={{ opacity: 1, transform: "scale(1)" }}
                                            exit={{ opacity: 0, transform: "scale(1.1)" }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileDoneOutlined />
                                            </p>
                                            <p className="ant-upload-text">{fileList[0]?.name}</p>
                                        </motion.div>

                                }
                            </AnimatePresence>
                        </Dragger>
                    </Row>
                </Form>
                <Row style={{ justifyContent: "end", marginTop: 20 }}>
                    <Button type="primary" onClick={handleClickGuardarCertificacion}>Guardar</Button>
                </Row>
            </Modal>

        </div >
    );
}

export default Certificaciones;