import { SET_CONFIG } from "../action_types/config.action.types";

const initialState = {
    sideBar: {
        visible: true,
        collapsed: true,
        options: [] // se tiene contemplado para crear un sidebar dinamico
    },
    header: {
        visible: true,
    },
    notificaciones: {
        visible: false
    }
};
const ConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIG: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};

export default ConfigReducer;
