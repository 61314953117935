import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "antd";
import { GetActivitiesCalendar } from "../../hooks/api/getActivities";
import { GetCompanyContact } from "../../hooks/api/getCompanyWhitContact";
import { GetActivitiesCatalogo } from "../../hooks/api/getActivitiesCatalogos";
import Calendar from "./components/Calendar/Calendar";
import ControlEvent from "./components/ControlEvent/controlEvent";
import { PrioridadUsuarioActividades } from "../../@enums/UsuarioActividades.enum";
import './styles.css'
import moment from "moment";


const Actividades = () => {

    const {getActivitiesCalendarMutation} = GetActivitiesCalendar();
    const {getCompanyContact} = GetCompanyContact();
    const {getActivitiesCatalogoMutation} = GetActivitiesCatalogo();
    const [form] = Form.useForm();
    const [eventsData, setEventsData] = useState(null);
    const [infoEvent, setInfoEvent] = useState(null);
    const [selectContact, setSelectContact] = useState(null);
    const [catalogoActList, setCatalogoActList] = useState(null);
    const [catalogoEmpresas, setCatalogoEmpresas] = useState(null);
    const [isEdit, setIsEdit] = useState(true);
    const [segundos, setSegundos] = useState(0);
    const [corriendo, setCorriendo] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            getData();
        }, 5000);
        return () => clearInterval(intervalId );
    }, []);

    const getData = () => {
        const fetchData = async () => {
            try {
                const actCatal = await getActivitiesCat();
                await getActvCalendar(actCatal);
                await getCompanies();

            } catch (error) {
                console.log(error);
            }
        };
        
        fetchData();

    }
    const getActivitiesCat = () => {
        return new Promise((resolve, reject) => {
            getActivitiesCatalogoMutation({}, {
                onSuccess: (data) => {
                    let storage = JSON.parse(sessionStorage.getItem('persist:root'))
                    let user = JSON.parse(storage.auth)
                    let listActFilter;
                    console.log(data)
                    console.log(user.user)
                    if(data !== null){
                        if(user.user.pues_id !== null){
                            listActFilter = data.filter(act => act.puestos.some(pues => pues.pues_id === user.user.pues_id) === true)
                        }else{listActFilter = data}
                        const listActCat = listActFilter.map((act) => {
                            return{
                                value: act.acti_id,
                                label: act.acti_nombre
                            }
                        })
                        listActCat.push({value: 0, label: 'Otro'})
                        setCatalogoActList(listActCat);
                        resolve(listActCat)
                    }
                },
                onError: (error) => {
                    console.log(error )
                    reject(error)
                }
            })
          });
    }

    const getActvCalendar = (act) => {
        return new Promise((resolve, reject) => {
            getActivitiesCalendarMutation({}, {
                onSuccess: (data) => {
                    let storage = JSON.parse(sessionStorage.getItem('persist:root'))
                    let user = JSON.parse(storage.auth)
                    if(data) {
                        let arrayFilter;
                        if(user.user.tiu_id === 3){
                            arrayFilter = data.filter(act => act.usu_id === user.user.usu_id)
                        }else if(user.user.tiu_id === 1){arrayFilter = data}
                        doArrayActivities(arrayFilter, act)
                        resolve(data)
                    }
                },
                onError: (error) => {
                    console.log(error)
                    reject(error)
                }
            })
          });
    }
    const getCompanies = () => {
        return new Promise((resolve, reject) => {
            getCompanyContact({}, {
                onSuccess: (data) => {
                    if(data !== null) {
                        const listCompanies = data.map((company => {
                            return {
                                value: company.emp_id,
                                label: company.emp_razonsocial
                            }
                        }))
                        setCatalogoEmpresas(listCompanies)
                        resolve(listCompanies)
                    }
                },
                onError: (error) => {
                    console.log(error)
                }
            })
          });
    }

    const doArrayActivities = (data, act) => {
        const arrayActivities = data.map((activitie) => { 
            let prom_date = moment(activitie.usac_fechaprogramada).subtract(6, 'hours').toISOString()
            const actName = activitie.acti_id !== null ? act.find(value => value.value === activitie.acti_id)?.label : 'Otro';
            const dateVigente = moment(activitie.usac_fechaprogramada);
            const fechaIni = moment(convertirFecha(activitie.usac_fechaprogramada), 'DD/MM/YYYY')
            const timeInit = moment(obtenerHora(activitie.usac_fechaprogramada), 'HH:mm');
            const fechaFin = moment(convertirFecha(activitie.usac_fechaprogramadafinal), 'DD/MM/YYYY')
            const timeFin = moment(obtenerHora(activitie.usac_fechaprogramadafinal), 'HH:mm');
            return {
                id: activitie.usac_id,
                title: actName,
                start: prom_date.replace(".000Z", "") ,
                status: dateVigente.isBefore(moment()) && activitie.usac_status === 0 ? 3 : activitie.usac_status,
                timeAct: activitie.usac_segundosactiva,
                descripcion: activitie.usac_descripcion,
                observaciones: activitie.usac_observaciones,
                acti_id: activitie.acti_id !== null ? activitie.acti_id : 0,
                contacto: activitie.usu_contactoid,
                company: activitie.emp_id,
                fechaInit: fechaIni,
                horaInit: timeInit,
                fechaFinal: fechaFin,
                horaFinal: timeFin,
                prioridad: 
                    activitie.usac_prioridad === PrioridadUsuarioActividades.BAJA ? {value:0, label:'Baja'} : 
                    activitie.usac_prioridad === PrioridadUsuarioActividades.MEDIA ? {value:1, label:'Media'} :
                    activitie.usac_prioridad === PrioridadUsuarioActividades.ALTA ? {value:2, label:'Alta'} : null
            }
        })
        setEventsData(arrayActivities)
    }

    const getContact = (idAct) => {
        let dataEvent = eventsData.find(event => event.id === idAct);
        console.log(dataEvent);
        if(dataEvent.company !== null){
            getCompanyContact({}, {
                onSuccess: (data) => {
                    const compId =  dataEvent.company.value !== undefined ? dataEvent.company.value : dataEvent.company
                    const company = data.find(emp => emp.emp_id === compId);
                    const contacts = company.usuarios_empresas;
                    
                    if(contacts.length > 0){
                        const dataSelectContact = contacts.map(contact => {
                            return { value: contact.usuario.usu_id, label:`${capitalizeFirstLetter(contact.usuario.usu_nombres)} ${capitalizeFirstLetter(contact.usuario.usu_apaterno)} ` }
                        })
                        setSelectContact(dataSelectContact);
                        const conctId =  dataEvent.contacto.value !== undefined ? dataEvent.contacto.value : dataEvent.contacto
                        dataEvent.company = catalogoEmpresas.find(data => data.value === compId);
                        dataEvent.contacto = dataSelectContact.find(data => data.value === conctId);
                        
                    }
                },
                onError: (error) => {
                    console.log(error)
                }
            })
        }
        setSegundos(dataEvent.timeAct);
        const actId = dataEvent.acti_id.value !== undefined ? dataEvent.acti_id.value : dataEvent.acti_id
        dataEvent.acti_id = catalogoActList.find(data => data.value === actId);
        form.setFieldsValue(dataEvent);
        setInfoEvent(dataEvent)
    }

    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    function convertirFecha(fechaOriginal) {
        const fecha = new Date(fechaOriginal);
        const dia = fecha.getUTCDate().toString().padStart(2, '0');
        const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0'); // Agregar 1 ya que los meses comienzan en 0
        const año = fecha.getUTCFullYear();
      
        return `${dia}/${mes}/${año}`;
    }

    const obtenerHora = (fechaStr) => {
        // Crear un objeto de fecha a partir de la cadena
        const fecha = new Date(fechaStr);
        
        // Obtener la hora y los minutos de la fecha
        const hora = fecha.getUTCHours();
        const minutos = fecha.getUTCMinutes();
    
        // Formatear la hora y minutos en un string en el formato HH:mm
        const horaFormateada = `${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
        return horaFormateada;
    };

    
    return (
        <div className="container">
            {eventsData !== null ? (
                <Row>
                    <Col span={18} style={{padding:'0px 10px'}}>
                        <Calendar key={1} eventsData={eventsData} setEventsData={setEventsData} getContact={getContact} corriendo={corriendo} isEdit={isEdit}/>
                    </Col>
                    <Col span={6}>
                        <ControlEvent data={infoEvent} setData={setInfoEvent} form={form} selectContacts={selectContact} 
                                    listAct={catalogoActList} listCompanies={catalogoEmpresas} 
                                    isEdit={isEdit} setIsEdit={setIsEdit} getData={getData} setContacts={setSelectContact}
                                    segundos={segundos} setSegundos={setSegundos} corriendo={corriendo} setCorriendo={setCorriendo}/>
                    </Col> 
                </Row>
            ):(
                <span>No se pudo mostrar el componente</span>
            )}
        </div>
    )
}

export default Actividades;