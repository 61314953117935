
import { InfoCircleOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Empty, Form, Modal, Row, Tooltip, Typography } from 'antd';
import { createUseStyles, useTheme } from 'react-jss';
import { useModal } from '../../../hooks/useModal';
import CrearEmpresaLicencia from '../../Empresas/Licencias/CrearEmpresaLicencia';
import { useApiEmpresasLicencias } from '../../../hooks/api/empresas';
import { useEffect } from 'react';

const useStyles = createUseStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "0px 10px 20px",
    },
    licenciaItem: {
        boxShadow: "rgba(100, 100, 111, 0.15) 0px 0px 15px 0px",
        padding: "10px 20px",
        borderRadius: 10,
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        // gap: 10,
        flexDirection: "column",
        "& .licencia-title": {
            color: `${theme.secondaryColor}`,
            fontWeight: "bold",
            fontSize: "1.1em",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
        "& .licencia-serie": {
            color: `#696969`,
            fontSize: "0.9em",
        }
    }
}));

const LicenciaItem = ({ licencia }) => {
    const styles = useStyles();

    return (
        <div
            className={styles.licenciaItem}
        >
            <div className='licencia-title'>
                {licencia?.sistema?.sis_nombre || "Sin nombre"}
                <Badge count={licencia?.usli_numusuarios || 0} size='small' color="geekblue">
                    <UserOutlined style={{ fontSize: 18 }} />
                </Badge>
            </div>
            <div className='licencia-serie'>
                {licencia?.usli_numeroserie || "Sin serie"}
            </div>
        </div>
    );
}

const NuevoContactoLicencias = ({ empresa, onLicenciasLoaded }) => {
    const theme = useTheme();
    const styles = useStyles();
    const [form] = Form.useForm();
    const [nuevaLicenciaVisible, showNuevaLicencia, closeNuevaLicencia] = useModal();
    const { data: licencias, isLoading: isLoadingLicencias, refetch: refetchLicencias } = useApiEmpresasLicencias({
        initialData: [],
        id: empresa?.emp_id
    });

    const handleNewLicenseSaved = () => {
        closeNuevaLicencia();
        refetchLicencias();
    }

    useEffect(() => {
        if (empresa?.emp_id) {
            refetchLicencias();
        }
    }, [empresa])

    useEffect(() => {
        if (licencias?.length > 0) {
            onLicenciasLoaded(licencias);
        }
    }, [licencias])

    return (
        <div
            className={styles.container}
        >
            {/* se crea la modal que contendra el formulario de la licencia */}
            <Modal
                open={nuevaLicenciaVisible}
                title="Nueva licencia"
                footer={null}
                onCancel={closeNuevaLicencia}
                destroyOnClose
            >
                <CrearEmpresaLicencia
                    editandoLicencia={null}
                    closeModal={closeNuevaLicencia}
                    empresa={empresa}
                    onSaveNewLicense={handleNewLicenseSaved}
                />
            </Modal>

            <Row justify="space-between" style={{ marginBottom: 5 }}>
                <Typography.Title level={5}>Licencias</Typography.Title>
                {
                    empresa &&
                    <Tooltip title="Agregar licencia" placement='left'>
                        <Button
                            type="primary"
                            onClick={() => { showNuevaLicencia() }}
                            shape='circle'
                            size='small'
                            icon={<PlusOutlined />}
                        />
                    </Tooltip>
                }
            </Row>

            {
                !empresa && (
                    <Empty
                        image={<InfoCircleOutlined style={{ fontSize: 30, color: theme.terciaryColor }} />}
                        imageStyle={{
                            height: 30,
                        }}
                        style={{
                            width: "100%",
                            padding: "20px 0",
                            margin: "10px 0px",
                            border: `1px dashed ${theme.terciaryColor}`,
                            background: `${theme.terciaryColor}11`
                        }}
                        description={
                            <span>
                                Selecciona o crea una empresa para capturar las licencias
                            </span>
                        }
                    >
                        {/* <Button type="primary">Create Now</Button> */}
                    </Empty>
                )
            }
            {
                empresa && (
                    <Row style={{ gap: 15 }}>
                        {
                            licencias?.map(licencia => (
                                <LicenciaItem licencia={licencia} key={`licencia-${licencia.usli_id}`} />
                            ))
                        }
                    </Row>
                )
            }
        </div>
    );
}

export default NuevoContactoLicencias;