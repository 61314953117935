import { Row, Col, Typography, Button, Collapse, Form, Input, Switch, Select, Tag, Pagination, Radio } from "antd";
import { BiBrain } from "react-icons/bi";
import { createUseStyles, useTheme } from "react-jss";
import Elevation from "../../components/Elevation";
import { CloseOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { getRandomTagColor } from "../../helpers/colors/Tags.colors";
import { useKnowBaseCategories, useKnowBaseItems } from "../../hooks/api/knowBase";
import { clearNullProperties } from "../../helpers/form.helper";
import { useApiSistemas } from "../../hooks/api/sistemas";
import DataTable from "../../components/DataTable";
import { useHistory } from "react-router-dom";

const useStyles = createUseStyles({
    container: {
        paddingTop: 10,
        paddingRight: 20,
        paddingLeft: 20,
    },
    pageOptions: {
        userSelect: "none",
        width: "100%",
        "& .ant-collapse-header": {
            padding: "0 !important",
        },
        "& .ant-collapse-header-text > div": {
            padding: 10,
            borderRadius: 7,
        },
        "& .ant-collapse-content": {
            background: "#fff",
        },
        "& .ant-collapse-content-box": {
            padding: 0,
        }
    },
    pageFilters: {
        marginTop: 5,
        background: "#fff",
        padding: 10,
        borderRadius: 7,
        zIndex: 10
    },
    knowledgeBaseItems: {
        display: "flex",
        marginTop: 20,
        flexWrap: "wrap",
        gap: 20,
        marginBottom: 20,
        justifyContent: "center",
    },
    tableContainer: {
        borderRadius: 10,
        padding: "0 20px",
        userSelect: "none",
    }
});

/**
 * knowledge management system (Mini Repository)
 */
const KMS = () => {
    const styles = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const options = useRef([]);
    const [searchParms, setSearchParms] = useState({});
    const { data: KnowBaseItems, refetch: refetchKnowBaseItems, isLoading: isLoadingItems } = useKnowBaseItems({ initialData: [] }, searchParms);
    const { data: knowBaseCategories } = useKnowBaseCategories({ initialData: [] });
    const { data: sistemas } = useApiSistemas({ initialData: [] });
    const [searchType, setSearchType] = useState(1);

    // search forms
    const [quickSearchForm] = Form.useForm();
    const [advancedSearchForm] = Form.useForm();


    const handleClickCrearKnowBaseItem = (e) => {
        e.stopPropagation();
    }

    const getOptionColor = (value) => {
        const option = options.current.find(option => option.value === value);
        return option?.color;
    }

    const handleChangeSearchType = (e) => {
        setSearchType(e.target.value);
        // se limpian los filtros
        quickSearchForm.resetFields();
        advancedSearchForm.resetFields();
        setSearchParms({});
    }

    const searchItems = () => {
        if (searchType === 1) {
            // quick search
            setSearchParms(quickSearchForm.getFieldsValue());
        } else if (searchType === 2) {
            // advanced search
            let params = clearNullProperties(advancedSearchForm.getFieldsValue());
            if (params.categorias) {
                params.categorias = `[${params.categorias}]`;
            }
            setSearchParms(params);
        }
        setTimeout(() => {
            refetchKnowBaseItems();
        }, 200);
    }

    const handleKeyUpSearch = (e) => {
        if (e.key === "Enter" || e.target.value === "") {
            searchItems();
        }
    }

    const handleClickSearch = (e) => {
        e.stopPropagation();
        searchItems();
    }

    const handleChangeCategoryFilter = (values) => {
        // se valida si es un item nuevo para no agregarlo
        advancedSearchForm.setFieldValue("categorias", values.filter(category => typeof category === "number"));
        searchItems();
    }

    const handleChangeSistema = () => {
        searchItems();
    }

    const handleRowClick = (item) => {
        history.push(`/know-base/${item.kbit_id}`);
    }

    useEffect(() => {
        if (knowBaseCategories) {
            options.current = knowBaseCategories.map(category => {
                return {
                    value: category.kbca_id,
                    label: category.kbca_nombre,
                    color: getRandomTagColor()
                }
            });
        }
    }, [knowBaseCategories]);

    return (
        <div className={styles.container}>
            <Row style={{ paddingTop: 20, marginBottom: 10 }}>
                <Col span={24}>
                    <Row style={{ gap: 10 }}>
                        <BiBrain style={{ fontSize: 30, color: theme.colors.secondary }} />
                        <Typography.Title level={3}>Base de conocimiento</Typography.Title>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Collapse
                    className={styles.pageOptions}
                    bordered={false}
                    expandIcon={null}
                    expandIconPosition="end"
                >
                    <Collapse.Panel
                        showArrow={true}
                        key="1"
                        header={
                            <Elevation style={{ width: "100%" }}>
                                <Button
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    onClick={handleClickCrearKnowBaseItem}
                                >
                                    Crear
                                </Button>
                            </Elevation>
                        }
                    >
                        <Elevation className={`${styles.pageFilters}`}>
                            <Col span={24}>
                                <Row style={{ gap: 20, alignItems: "center", marginBottom: 15 }}>
                                    <Typography.Title level={4} style={{ margin: 0 }}>Filtros</Typography.Title>
                                    <div>
                                        <Radio.Group value={searchType} onChange={handleChangeSearchType}>
                                            <Radio value={1}>Busqueda rápida</Radio>
                                            <Radio value={2}>Busqueda avanzada</Radio>
                                        </Radio.Group>
                                    </div>
                                </Row>
                                <Row>
                                    {
                                        searchType === 1 &&
                                        (
                                            <Form
                                                layout="vertical"
                                                form={quickSearchForm}
                                                style={{
                                                    width: "100%"
                                                }}
                                            >
                                                <Form.Item
                                                    name="criteria"
                                                >
                                                    <Input
                                                        placeholder="Buscar en la base de conocimiento"
                                                        // onSearch={onSearch}
                                                        onKeyUp={handleKeyUpSearch}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        )
                                    }
                                    {
                                        searchType === 2 &&
                                        (
                                            <Form
                                                layout="vertical"
                                                style={{
                                                    width: "100%"
                                                }}
                                                form={advancedSearchForm}
                                            >
                                                <Row style={{ gap: 20 }}>
                                                    <Form.Item
                                                        label="Titulo"
                                                        name="titulo"
                                                        style={{ width: 200 }}
                                                    >
                                                        <Input onKeyUp={handleKeyUpSearch} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Sistema"
                                                        name="sistema"
                                                    // style={{ flex: 1 }}
                                                    >
                                                        <Select
                                                            allowClear
                                                            style={{ minWidth: 150 }}
                                                            onChange={handleChangeSistema}
                                                        >
                                                            {
                                                                sistemas?.map(sistema => (
                                                                    <Select.Option key={sistema.sis_id} value={sistema.sis_id}>{sistema.sis_nombre}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Categorías"
                                                        style={{ flex: 1 }}
                                                        name="categorias"
                                                    >
                                                        <Select
                                                            mode="tags"
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            onChange={handleChangeCategoryFilter}
                                                            optionFilterProp="label"
                                                            filterOption={(input, option) => option.label.toLowerCase().includes(input)}
                                                            options={options.current}
                                                            tagRender={({ label, value, closable, onClose }) => (
                                                                <Tag color={getOptionColor(value)} style={{ display: 'flex', alignItems: 'center', margin: "3px 3px" }}>
                                                                    <span>{label}</span>
                                                                    {closable && <CloseOutlined style={{ marginLeft: 3 }} onClick={onClose} />}
                                                                </Tag>
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Row>
                                                <Row style={{ gap: 20 }}>
                                                    <Form.Item
                                                        label="Síntoma"
                                                        style={{ flex: 1 }}
                                                        name="sintomas"
                                                    >
                                                        <Input onKeyUp={handleKeyUpSearch} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Problemática"
                                                        style={{ flex: 1 }}
                                                        name="problematica"
                                                    >
                                                        <Input onKeyUp={handleKeyUpSearch} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ flex: 1 }}
                                                        label="Solucion"
                                                        name="solucion"
                                                    >
                                                        <Input onKeyUp={handleKeyUpSearch} />
                                                    </Form.Item>
                                                </Row>
                                            </Form>
                                        )
                                    }
                                </Row>
                                <Row justify="end">
                                    <Button
                                        type="primary"
                                        style={{ padding: "0 30px" }}
                                        onClick={handleClickSearch}
                                    >
                                        Buscar
                                    </Button>
                                </Row>
                            </Col>
                        </Elevation>
                    </Collapse.Panel>
                </Collapse>
            </Row>
            <Elevation className={styles.tableContainer}>
                <div className={styles.knowledgeBaseItems}>
                    {
                        isLoadingItems && <LoadingOutlined spin style={{ fontSize: 40, color: theme.colors.secondary }} />
                    }
                    <DataTable
                        columns={[
                            {
                                title: "Folio",
                                index: "kbit_id",
                            },
                            {
                                title: "Título",
                                width: 100,
                                index: "kbit_titulo"
                            },
                            {
                                title: "Problemática",
                                width: 150,
                                index: "kbit_problematica",
                            },
                            {
                                title: "Solución",
                                width: 150,
                                index: "kbit_solucion",
                            },
                            {
                                title: "Categorías",
                                render: (_, record) => (
                                    <div style={{ display: "flex", flexDirection: "column", gap: 5, flexWrap: "wrap", margin: "5px 0 5px 0" }}>
                                        {
                                            record?.categories?.slice(0, 2).map((categoria, index) => (
                                                <Tag key={index} style={{
                                                    width: "fit-content",
                                                    wordBreak: "break-word",
                                                }}>
                                                    {categoria?.kbca_nombre}
                                                </Tag>
                                            ))
                                        }
                                    </div>
                                ),
                                width: 150
                            }
                        ]}
                        paginationParams={{
                            position: ["bottomRight", "topRight"],
                        }}
                        onRowClick={handleRowClick}
                        data={KnowBaseItems}
                        scroll={{ x: "100%" }}
                        size={"small"}
                        style={{ width: "100%" }}
                    />
                </div>
            </Elevation>
        </div>
    );
}

export default KMS;