import {
	Button,
	Col,
	Drawer,
	Input,
	Modal,
	Row,
	Select,
	Tabs,
	Tooltip,
	Typography,
	message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import styles from "./styles";
import CustomTabsButtons from "../../../components/Tabs/CustomTabsButtons";
import CrearTicket from "../CrearTicket";
import {
	useApiCountTicketsObservador,
	useApiCountTicketsStatus,
	useApiTicketsObservador,
	useApiTicketsStatus,
	useTicketUpdateMutation,
} from "../../../hooks/api/tickets";
import DatosContacto from "../DatosTicket/DatosContacto";
import Problematica from "../DatosTicket/Problematica";
import NotasTicket from "../DatosTicket/Notas";
import { BiPlus } from "react-icons/bi";
import SolidTabs from "../../../components/Tabs/SolidTabs";
import Observaciones from "../DatosTicket/Observaciones";
import DatosGenerales from "../DatosTicket/DatosGenerales";
import Seguimiento from "../DatosTicket/Seguimiento";
import TicketsLibres from "./Tableros/TicketsLibres";
import TicketsSeguimiento from "./Tableros/TicketsSeguimiento";
import Tablero from "./Tableros/Tablero";
import TicketsFabricante from "./Tableros/TicketsFabricante";
import TicketsCaducados from "./Tableros/TicketsCaducados";
import { DateTime } from "luxon";
import { DATE_FORMATS } from "../../../helpers/dates.helper";
import TicketsObservador from "./Tableros/TicketsObservador";
import { TicketStatus, TicketTipoProceso } from "../../../@enums/Tickets.enums";
import { TipoTicketAgente } from "../../../@enums/TicketsAgentes.enums";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useApiAreasDepartamentos } from "../../../hooks/api/areas_departamentos";

const useStyles = createUseStyles(styles);

const VerTickets = ({ tipoPosicion }) => {
	const theme = useTheme();
	const styles = useStyles();
	const [tab, setTab] = useState("tickets_libres");
	const [usandoTicket, setUsandoTicket] = useState(null);
	const [showModalCrearTicket, setShowModalCrearTicket] = useState(false);
	const [showDatosTickets, setShowDatosTickets] = useState(false);
	const [ticketsFiltrados, setTicketsFiltrados] = useState([]);
	const [checkingTicketStatus, setCheckigTicketStatus] = useState(1);
	const [automaticalyChangeTab, setAutomaticalyChangeTab] = useState(true);
	const searchInputRef = useRef(null);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [idUser, setIdUser] = useState(queryParams.get("idUser"));
	const [idStatus, setIdStatus] = useState(queryParams.get("status"));
	const [page, setPage] = useState(1);
	const [spinning, setSpinning] = useState(false)
	const [pageSize, setPageSize] = useState(8);
	const [fechaInit, setFechaInit] = useState(queryParams.get("fecha_init"));
	const [fechaFinal, setFechaFinal] = useState(queryParams.get("fecha_final"));
	const { isLoading: isLoadingAreasDepartamentos, data: areasDepartamentos } =
		useApiAreasDepartamentos({ refetchOnWindowFocus: false });
	const { mutateTicketUpdate, isLoadingTicketUpdateMutation } =
		useTicketUpdateMutation();

	const { data: numTicketsLibres, refetch: refetchNumTicketsLibres } =
		useApiCountTicketsStatus(
			{
				initiaData: 0,
			},
			{
				statusID: TicketStatus.TICKET_LIBRE,
				params: { tipoPosicion: tipoPosicion || null },
			}
		);
	const { data: numTicketsSeguimiento, refetch: refetchNumTicketsSeguimiento } =
		useApiCountTicketsStatus(
			{
				initiaData: 0,
			},
			{
				statusID: TicketStatus.TICKET_SEGUIMIENTO,
				params: { tipoPosicion: tipoPosicion || null },
			}
		);
	const { data: numTicketsFabricante, refetch: refetchNumTicketsFabricante } =
		useApiCountTicketsStatus(
			{
				initiaData: 0,
			},
			{
				statusID: TicketStatus.ESPERA_FABRICANTE,
				params: { tipoPosicion: tipoPosicion || null },
			}
		);
	const { data: numTicketsCaducados, refetch: refetchNumTicketsCaducados } =
		useApiCountTicketsStatus(
			{
				initiaData: 0,
			},
			{
				statusID: TicketStatus.CADUCADO,
				params: { tipoPosicion: tipoPosicion || null },
			}
		);
	const { data: numTicketsTotales, refetch: refetchNumTotales } =
	useApiCountTicketsStatus(
		{
			initiaData: 0,
		},
		{
			statusID: TicketStatus.TODOS,
			params: { tipoPosicion: tipoPosicion || null },
		}
	);
	const {
		isLoading: isLoadingTickets,
		data: tickets,
		refetch: refetchTickets,
	} = useApiTicketsStatus(
		{
			initiaData: [],
			refetchInterval: 15000,
			refetchIntervalInBackground: true,
		},
		{
			statusID: idStatus !== null ? idStatus : checkingTicketStatus,
			params: {
				page: page,
				pageSize: pageSize,
				tipoPosicion: tipoPosicion || null,
				asesor: idUser !== null ? idUser : "",
				fecha_init: fechaInit !== null ? fechaInit : "",
				fecha_final: fechaFinal !== null ? fechaFinal : "",
			},
		}
	);
	const {
		isLoading: isLoadingTicketsObservador,
		data: ticketsObservador,
		refetch: refetchTicketsObservador,
	} = useApiTicketsObservador(
		{
			initialData: [],
		},
		{
			params: {
				tipoPosicion: tipoPosicion || null,
			},
		}
	);
	const {
		isLoading: isLoadingCountTicketsObservador,
		data: countTicketsObservador,
		refetch: refetchCountTicketsObservador,
	} = useApiCountTicketsObservador(
		{
			initialData: 0,
		},
		{
			params: {
				tipoPosicion: tipoPosicion || null,
				get_count: 1,
			},
		}
	);

	const tabsButtons = [
		{
			key: "tickets",
			text: "Historico",
			borderBottomColor: "#003CA7",
			badgeCount: 0,
			stat_id: TicketStatus.TODOS,
		},
		{
			key: "tickets_libres",
			text: "Tickets",
			borderBottomColor: "#00A757",
			badgeCount: numTicketsLibres?.data || 0,
			stat_id: TicketStatus.TICKET_LIBRE,
		},
		{
			key: "tickets_seguimiento",
			text: "Tickets en seguimiento",
			borderBottomColor: "#329EFF",
			badgeCount: numTicketsSeguimiento?.data || 0,
			stat_id: TicketStatus.TICKET_SEGUIMIENTO,
		},
		{
			key: "tickets_observador",
			text: "Tickets como observador",
			borderBottomColor: "gold",
			badgeCount: countTicketsObservador || 0,
		},
		{
			key: "tickets_fabricante",
			text: "Fabricante",
			borderBottomColor: "#fc6731",
			badgeCount: numTicketsFabricante?.data || 0,
			stat_id: TicketStatus.ESPERA_FABRICANTE,
		},
		{
			key: "tickets_caducados",
			text: "Tickets Caducados",
			borderBottomColor: "#e00000",
			badgeCount: numTicketsCaducados?.data || 0,
			stat_id: TicketStatus.CADUCADO,
		},
	];

	const handleCancelCrearTicket = () => setShowModalCrearTicket(false);

	const onChangeTab = (tabKey) => {
		setTicketsFiltrados([]);
		searchInputRef.current.input.value = "";
		let tab = tabsButtons.find((tab) => tab.key === tabKey);
		setTab(tabKey);
		setCheckigTicketStatus(tab.stat_id);
		setAutomaticalyChangeTab(false);
	};

	const handleClickNuevoTicket = () => setShowModalCrearTicket(true);

	const hideDatosTickets = () => {
		setShowDatosTickets(false);
		setUsandoTicket(null);
	};

	const getNumTickets = () => {
		setTimeout(() => {
			refetchNumTicketsLibres();
			refetchNumTicketsSeguimiento();
			refetchNumTicketsFabricante();
			refetchNumTicketsCaducados();
		}, 1000);
	};

	const buscarTickets = (e) => {
		if (e.target.value === "") {
			setTicketsFiltrados([]);
		} else {
			let value = e.target.value.trim().toLowerCase();
			let tTickets = [...tickets].filter((ticket) => {
				let isMatched = false;
				// se busca por problematica, clave o estatus
				if (
					`${ticket?.tick_sintomas}`.toLowerCase().includes(value) ||
					`${ticket?.tick_clave}`.toLowerCase().includes(value) ||
					`${ticket?.status?.stat_nombre}`.toLowerCase().includes(value)
				) {
					isMatched = true;
				}

				// se busca por el nombre del responsable
				if (ticket?.agentes) {
					let responsable = ticket.agentes.find(
						(agente) =>
							agente.tiag_id === TipoTicketAgente.RESPONSABLE &&
							agente.tica_usumodificaticket
					);
					if (responsable && responsable?.agente) {
						if (
							responsable.agente?.usu_nombres?.toLowerCase()?.includes(value) ||
							responsable.agente?.usu_apaterno
								?.toLowerCase()
								?.includes(value) ||
							responsable.agente?.usu_amaterno?.toLowerCase()?.includes(value)
						)
							isMatched = true;
					}

					let contacto = ticket.agentes.find(
						(agente) => agente.tiag_id === TipoTicketAgente.CONTACTO
					);
					if (contacto && contacto?.agente) {
						if (
							contacto.agente?.usu_nombres?.toLowerCase()?.includes(value) ||
							contacto.agente?.usu_apaterno?.toLowerCase()?.includes(value) ||
							contacto.agente?.usu_amaterno?.toLowerCase()?.includes(value)
						)
							isMatched = true;
					}
				}

				// se busca por nombre del sistema
				if (ticket.sistema_version) {
					if (
						`${ticket.sistema_version.sistema.sis_nombre}`
							.toLowerCase()
							.includes(value)
					)
						isMatched = true;
				}

				if (ticket.licencia) {
					if (
						`${ticket.licencia?.sistema?.sis_nombre}`
							.toLowerCase()
							.includes(value)
					)
						isMatched = true;
				}

				// se busca por nombre de la empresa
				if (ticket?.empresa)
					if (ticket.empresa.emp_razonsocial.toLowerCase().includes(value))
						isMatched = true;

				try {
					// se busca por fecha
					/**
					 * se convierte la fecha a string para validarla
					 * @type {String}
					 */
					let parsedDate = DateTime.fromISO(ticket.tick_fecharegistro).toFormat(
						DATE_FORMATS.DATE_LUXON_FORMAT_SLASH_TO_STR
					);
					if (parsedDate.includes(value)) isMatched = true;
				} catch (error) {
					console.log("date cannot parsed");
				}

				return isMatched;
			});
			setTicketsFiltrados(tTickets);
		}
	};

	const handleChangeTicketDepartment = (value) => {
		// update department of the ticket
		mutateTicketUpdate(
			{
				tick_id: usandoTicket.tick_id,
				ade_id: value,
				tick_tipoproceso: TicketTipoProceso.SIN_PROCESO
			},
			{
				onSuccess: () => {
					refetchTickets();
					setUsandoTicket((prevState) => ({
						...prevState,
						ade_id: value,
					}));
					message.success("Se actualizó el departamento del ticket");
				},
				onError: (error) => {
					console.log(error);
					message.error(
						"Ocurrió un error al actualizar el departamento del ticket"
					);
				},
			}
		);
	};

	useEffect(() => getNumTickets(), [checkingTicketStatus]);

	useEffect(() => {
		if (automaticalyChangeTab)
			if (numTicketsCaducados?.data && numTicketsCaducados.data > 0) {
				setTab("tickets_caducados");
				setCheckigTicketStatus(TicketStatus.CADUCADO);
			} else if (
				numTicketsSeguimiento?.data &&
				numTicketsSeguimiento?.data > 0
			) {
				setTab("tickets_seguimiento");
				setCheckigTicketStatus(TicketStatus.TICKET_SEGUIMIENTO);
			} else {
				setTab("tickets_libres");
				setCheckigTicketStatus(TicketStatus.TICKET_LIBRE);
			}
	}, [numTicketsSeguimiento, numTicketsCaducados, numTicketsLibres]);


	const changePage = (page) =>{
		setPage(page);
		setSpinning(true)
		refetchTickets(page).then(()=>{
			refetchTickets().then(()=>setSpinning(false));
		})
	}

	const changePageSize = (pageSize) => {
		setPageSize(pageSize);
		setSpinning(true)
		refetchTickets(pageSize).then(()=>{
			refetchTickets().then(()=>setSpinning(false));
		})
	}


	useEffect(() => {
		getNumTickets();
	}, [tipoPosicion]);



	return (
		<div>
			<Row
				className={styles.pageWrapper}
				style={{ paddingTop: 20, paddingBottom: 40 }}
			>
				<Col span={24}>
					<Row style={{ justifyContent: "space-between" }}>
						<div style={{ display: "flex", alignItems: "start" }}>
							<div
								style={{
									padding: "3px 10px 3px 0",
									borderRight: "1px solid #eee",
								}}
							>
								<Tooltip title="Nuevo Ticket" placement="bottom">
									<Button
										type="primary"
										shape="circle"
										icon={<BiPlus />}
										style={{ fontSize: 0, marginLeft: 20, marginRight: 10 }}
										onClick={handleClickNuevoTicket}
									/>
								</Tooltip>
							</div>
							<CustomTabsButtons
								tabsButtons={tabsButtons}
								activeKey={tab}
								onChangeTab={onChangeTab}
							/>
						</div>
						<div
							style={{ display: "flex", alignItems: "center", marginRight: 20 }}
						>
							<Input
								placeholder="Buscar ticket"
								style={{ width: 250 }}
								onChange={buscarTickets}
								ref={searchInputRef}
							/>
						</div>
					</Row>
					<Tabs
						defaultActiveKey="tickets"
						className="tab-pane"
						tabBarStyle={{ display: "none" }}
						activeKey={tab}
					>
						<Tabs.TabPane tab="Tab 1" key="tickets">
							<Tablero
								key="historico_tablero"
								setUsandoTicket={setUsandoTicket}
								setShowDatosTickets={setShowDatosTickets}
								tickets={
									ticketsFiltrados.length > 0 ||
									searchInputRef?.current?.input?.value !== ""
										? ticketsFiltrados
										: tickets
										? tickets
										: []
								}
								isLoadingTickets={isLoadingTickets || spinning ? true : false}
								page={page}
								pageSize={pageSize}
								changePageSize={changePageSize}
								changePage={changePage}
								totalTickets={numTicketsTotales?.data}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Tab 2" key="tickets_libres">
							<TicketsLibres
								setUsandoTicket={setUsandoTicket}
								setShowModalCrearTicket={setShowModalCrearTicket}
								setShowDatosTickets={setShowDatosTickets}
								tickets={
									ticketsFiltrados.length > 0 ||
									searchInputRef?.current?.input?.value !== ""
										? ticketsFiltrados
										: tickets
										? tickets
										: []
								}
								isLoadingTickets={isLoadingTickets}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane
							tab="Tickets en seguimiento"
							key="tickets_seguimiento"
						>
							<TicketsSeguimiento
								setUsandoTicket={setUsandoTicket}
								setShowModalCrearTicket={setShowModalCrearTicket}
								setShowDatosTickets={setShowDatosTickets}
								tickets={
									ticketsFiltrados.length > 0 ||
									searchInputRef?.current?.input?.value !== ""
										? ticketsFiltrados
										: tickets
										? tickets
										: []
								}
								isLoadingTickets={isLoadingTickets}
								refetchTickets={refetchTickets}
								refetchNumTicketsCaducados={refetchNumTicketsCaducados}
								refetchNumTicketsSeguimiento={refetchNumTicketsSeguimiento}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane
							tab="Tickets como observador"
							key="tickets_observador"
						>
							<TicketsObservador
								tickets={ticketsObservador}
								isLoadingTickets={isLoadingTicketsObservador}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Fabricante" key="tickets_fabricante">
							<TicketsFabricante
								tickets={
									ticketsFiltrados.length > 0 ||
									searchInputRef?.current?.input?.value !== ""
										? ticketsFiltrados
										: tickets
										? tickets
										: []
								}
								isLoadingTickets={isLoadingTickets}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Caducados" key="tickets_caducados">
							<TicketsCaducados
								tickets={
									ticketsFiltrados.length > 0 ||
									searchInputRef?.current?.input?.value !== ""
										? ticketsFiltrados
										: tickets
										? tickets
										: []
								}
								isLoadingTickets={isLoadingTickets}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Col>
			</Row>

			<Modal
				className={styles.nuevoTicketModal}
				footer={null}
				title="Nuevo Ticket"
				width="800px"
				open={showModalCrearTicket}
				onCancel={handleCancelCrearTicket}
				destroyOnClose={true}
			>
				<CrearTicket closeModal={handleCancelCrearTicket} />
			</Modal>

			<Drawer
				placement="right"
				closable={false}
				onClose={hideDatosTickets}
				open={showDatosTickets}
				className={styles.drawerDatos}
				getContainer={false}
			>
				<Row style={{ flexDirection: "column", padding: "5px 10px" }}>
					<Typography.Title level={4} style={{ marginBottom: 3 }}>
						Ticket{" "}
						<span style={{ color: "#1890FF", marginLeft: 5 }}>
							{usandoTicket?.tick_clave}
						</span>
					</Typography.Title>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: 10,
							marginBottom: 10,
						}}
					>
						<label
							htmlFor="select-ticket-department"
							style={{ color: theme.colors.secondary }}
						>
							Mesa:
						</label>
						<Select
							id="select-ticket-department"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children
									.join("")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							defaultValue={usandoTicket?.ade_id}
							onChange={handleChangeTicketDepartment}
							style={{ width: "100%" }}
						>
							{areasDepartamentos?.map((ade) => (
								<>
									{ade.ade_tipo === 2 && (
										<Select.Option key={ade.ade_nombre} value={ade.ade_id}>
											{ade.ade_nombre}
										</Select.Option>
									)}
								</>
							))}
						</Select>
					</div>
				</Row>
				<Row>
					<Tabs
						defaultActiveKey="1"
						type="card"
						size="small"
						style={{ width: "100%" }}
						className={styles.tabsPanel}
					>
						<Tabs.TabPane tab="Ticket" key="1">
							<SolidTabs
								tabs={[
									{
										key: 1,
										text: "1",
										content: (
											<div>
												<Problematica
													ticket={usandoTicket}
													closeDatosTicket={hideDatosTickets}
												/>
												<DatosContacto
													empresa={usandoTicket?.empresa}
													contacto={
														usandoTicket?.agentes.find(
															(agente) =>
																agente.tipoAgente.tiag_nombre === "Contacto"
														)?.agente
													}
													ticket={usandoTicket}
													setUsandoTicket={setUsandoTicket}
												/>
												<NotasTicket tickId={usandoTicket?.tick_id} />
											</div>
										),
									},
									{
										key: 2,
										text: "2",
										content: (
											<div>
												<Observaciones ticket={usandoTicket} />
												<DatosGenerales
													ticket={usandoTicket}
													setUsandoTicket={setUsandoTicket}
												/>
												<Seguimiento ticketId={usandoTicket?.tick_id} />
												{/* sistema licencia version usuarios */}
											</div>
										),
									},
								]}
								initialActiveKey={1}
							/>
						</Tabs.TabPane>
						{/* <Tabs.TabPane tab="Marketing" key="3">
                            Marketing
                        </Tabs.TabPane> */}
					</Tabs>
				</Row>
			</Drawer>
		</div>
	);
};

export default VerTickets;
