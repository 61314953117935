import React, {useState, useEffect} from "react";
import { Table, Rate} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { EconectionUsuario } from "../../../../@enums/Usuarios.enum";
import './styles.css'
import { useHistory } from "react-router-dom";
import { DATE_FORMATS } from "../../../../helpers/dates.helper";
import { calculaTiempoTranscurridoServicio } from "../../../../helpers/tickets.helper";

const TableDataAsesor = ({dataRaw, allTickets, idDate, ticketsForTime}) => {
    const history = useHistory();
    const [dataSource, setDataSource] = useState(null);
    const {socketUser } = useSelector((state) => ({
        socketUser: state.socketUser.socket,
    }));

    const colums = [
        {
            title: 'Clave',
            dataIndex: 'clave',
            key: 'clave',
            sorter: (a, b) => a.clave.localeCompare(b.clave),
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
        },
        {
            title: 'Calificacion',
            dataIndex: 'grade',
            key: 'grade',
            render: (grade) => (
                <div className="custom-rating">
                  <Rate
                    allowHalf
                    disabled
                    value={grade}
                    style={{ color: 'blue', borderColor: 'blue' }}
                  />
                </div>
              ),
              sorter: (a, b) => a.grade - b.grade,
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: 'Ticket Actual',
            dataIndex: 'actual',
            key: 'actual',
            sorter: (a, b) => a.actual.localeCompare(b.actual),
        },
        {
            title: 'Tiempo en Atención',
            dataIndex: 'time',
            key: 'time',
            render: (text, record) =>
                    record.actual !== 'Libre' ? formatTime(text) : '--:--:--',
        },
        {
            title: 'Tiempo restante',
            dataIndex: 'timeLeft',
            key: 'timeLeft',
            render: (text, record) =>
                    record.actual !== 'Libre' ? formatTime(text) : '--:--:--',
        },
        {
            title: 'Tickets Cerrados',
            dataIndex: 'ticketsClose',
            key: 'ticketsClose',
            sorter: (a, b) => a.ticketsClose - b.ticketsClose,
        },
        {
            title: 'Tickets Cerrados con Éxito',
            dataIndex: 'ticketsCloseExit',
            key: 'ticketsCloseExit',
            sorter: (a, b) => a.ticketsCloseExit - b.ticketsCloseExit,
            onCell: (record) => ({
            onClick: () => {redirect(record.id, 4)},
            }),
        },
        {
            title: 'Tickets Cerrados sin Éxito',
            dataIndex: 'ticketsCloseNoExit',
            key: 'ticketsCloseNoExit',
            sorter: (a, b) => a.ticketsCloseNoExit - b.ticketsCloseNoExit,
            onCell: (record) => ({
                onClick: () => {redirect(record.id, 6)},
            }),
            
        },
        {
            title: 'Tickets Caducados',
            dataIndex: 'ticketsCaducados',
            key: 'ticketsCaducados',
            sorter: (a, b) => a.ticketsCaducados - b.ticketsCaducados,
            onCell: (record) => ({
                onClick: () => {redirect(record.id, 9)},
            }),
        },
        {
            title: 'Tiempo Promedio de Cierre',
            dataIndex: 'timeClose',
            key: 'timeClose',
            sorter: (a, b) => a.timeClose.localeCompare(b.timeClose),
        },
        {
            title: 'Canalizaciones',
            dataIndex: 'canalizaciones',
            key: 'canalizaciones',
            sorter: (a, b) => a.canalizaciones - b.canalizaciones,
        },
        {
            title: 'Canalizaciones Éxitosas',
            dataIndex: 'canalizacionesExitosas',
            key: 'canalizacionesExitosas',
            sorter: (a, b) => a.canalizacionesExitosas - b.canalizacionesExitosas,
        },
        {
            title: 'Canalizaciones sin Éxito',
            dataIndex: 'canalizacionesNoExito',
            key: 'canalizacionesNoExito',
            sorter: (a, b) => a.canalizacionesNoExito - b.canalizacionesNoExito,
        },
        {
            title: 'Colaboraciones',
            dataIndex: 'colabs',
            key: 'colabs',
            sorter: (a, b) => a.colabs - b.colabs,
        },
    ]

    useEffect(() => {
        if(dataRaw !== null && dataRaw !== undefined){
            createDataSource();
        }
    },[])
    
    useEffect(() => {
        const intervalId = setInterval(createDataSource, 1000);
        return () => clearInterval(intervalId);
    }, [dataRaw, allTickets]);

    useEffect(() => {
        if(socketUser){
            socketUser.on("connected-users", (data) => {
                console.log(data)
                sessionStorage.setItem('userConnected', data)
            });
        }
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
          // Actualiza el tiempo solo para las filas con status true
            setDataSource(prevData =>
            prevData.map(item =>
                item.actual !== 'Libre' ? { ...item, time: item.time + 1 } : item
            )
            );
        }, 1000);
    
        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        const intervalId = setInterval(() => {
          // Actualiza el tiempo solo para las filas con status true
        setDataSource(prevData =>
            prevData.map(item =>
                item.actual !== 'Libre'  && item.timeLeft > 0
                ? { ...item, timeLeft: item.timeLeft - 1 }
                : item
            )
        );
        }, 1000);
    
        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
    }, []);


    const formatTime = seconds => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
    
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
        return formattedTime;
    }

    
    const createDataSource = () => {
        const usersConect =JSON.parse(sessionStorage.getItem('userConnected'))
        const dataNew = dataRaw.map((asesor) => {

            const status = usersConect.some(element => element.usu_id === asesor.usu_id && element.status === EconectionUsuario.CONNECTED ) ? 'Conectado' : usersConect.some(element => element.usu_id === asesor.usu_id && element.status === EconectionUsuario.ABSENT ) ? 'Ausente' : 'Desconectado'; 
            
            const arrayCloseSuccess = allTickets.filter((objeto) => {
                return objeto.status.stat_id === 4 && objeto.agentes.some((agente) => agente.tiag_id === 3 && agente.usu_id === asesor.usu_id);
            });
            const arrayCloseNoSuccess = allTickets.filter((objeto) => {
                return objeto.status.stat_id === 6 && objeto.agentes.some((agente) => agente.tiag_id === 3 && agente.usu_id === asesor.usu_id);
            });
            const arrayExpired = allTickets.filter((objeto) => {
                return objeto.status.stat_id === 9 && objeto.agentes.some((agente) => agente.tiag_id === 3 && agente.usu_id === asesor.usu_id);
            });
            const canalizacionesSuccess = allTickets.filter((objeto) => {
                return objeto.tick_tipo === 2 && objeto.status.stat_id === 4 && objeto.agentes.some((agente) => agente.tiag_id === 3 && agente.usu_id === asesor.usu_id);
            });
            const canalizacionesNoSuccess = allTickets.filter((objeto) => {
                return objeto.tick_tipo === 2 && objeto.status.stat_id === 6 && objeto.agentes.some((agente) => agente.tiag_id === 3 && agente.usu_id === asesor.usu_id);
            });

            // console.log(allTickets)

            const arrayProceso = ticketsForTime.filter((objeto) => {
                let ag = objeto.agentes?.filter(agente => agente.tiag_id === 3);
                return (objeto.status.stat_id === 2 || objeto.status.stat_id === 3) && (ag[ag.length-1]?.usu_id === asesor.usu_id);
            });
            console.log(arrayProceso)

            let time = arrayProceso !== undefined && arrayProceso.length > 0 ? 
                        arrayProceso[0].status.stat_id === 2 ? calculaTiempoTranscurridoServicio(arrayProceso[0].incidencias).segundosTranscurridosServicio : '--:--:--' 
                        : '--:--:--'
            
            let timeLeft = arrayProceso !== undefined && arrayProceso.length > 0 ? 
                arrayProceso[0].status.stat_id === 2 ? 
                    (arrayProceso[0].acuerdo_servicio.cacu_minutos*60) - calculaTiempoTranscurridoServicio(arrayProceso[0].incidencias).segundosTranscurridosServicio < 0
                        ? 0 
                        :(arrayProceso[0].acuerdo_servicio.cacu_minutos*60) - calculaTiempoTranscurridoServicio(arrayProceso[0].incidencias).segundosTranscurridosServicio 
                : '--:--:--' 
            : '--:--:--'

            const timeRes = getTimesResolution(arrayCloseSuccess);  


            return(
                {
                    id: asesor.usu_id,
                    clave: asesor.usu_clave !== null ? asesor.usu_clave : 'N/C',
                    nombre: `${capitalizeFirstLetter(asesor.usu_nombres)} ${capitalizeFirstLetter(asesor.usu_apaterno)} ${capitalizeFirstLetter(asesor.usu_amaterno)}`,
                    status: status,
                    actual: arrayProceso !== undefined && arrayProceso.length > 0 ? arrayProceso[0].tick_clave : 'Libre',
                    time:  time,
                    timeLeft: timeLeft,
                    grade: 4,
                    ticketsClose: arrayCloseSuccess.length + arrayCloseNoSuccess.length,
                    ticketsCloseExit: arrayCloseSuccess.length,
                    ticketsCloseNoExit: arrayCloseNoSuccess.length,
                    ticketsCaducados: arrayExpired.length,
                    timeClose: timeRes,
                    canalizaciones: canalizacionesSuccess.length + canalizacionesNoSuccess.length,
                    canalizacionesExitosas: canalizacionesSuccess.length,
                    canalizacionesNoExito: canalizacionesNoSuccess.length,
                    colabs: 1,
                }
            )
        })
        setDataSource(dataNew)
    }

    const getTimesResolution = (data) => {

        let result = ''
    
        if(data.length > 0){
            const tiempos = data.map((ticket) => {
                const data = {
                        timeInit: ticket.incidencias.some(ele => ele.tipi_id === 2) ? ticket.incidencias.find(ele => ele.tipi_id === 2).tici_fecha : null,
                        timeEnd: ticket.incidencias.some(ele => ele.tipi_id === 6) ? ticket.incidencias.find(ele => ele.tipi_id === 6).tici_fecha : null,
                }
                    return data;
            })
            const tiempoResolucion = tiempos.map((objeto) => {
                const fechaRegistro = moment(objeto.timeInit);
                const fechaUltimoStatus = moment(objeto.timeEnd);
                const minutosDiferencia = fechaUltimoStatus.diff(fechaRegistro, 'minutes');
                return minutosDiferencia;
            });
            let promedioRes = (tiempoResolucion.reduce((acumulador, numero) => acumulador + numero, 0))/tiempoResolucion.length;
            if(promedioRes > 59){
                result = `${(promedioRes/60).toFixed(0)} horas` 
            }else{
                result = `${(promedioRes).toFixed(0)} minutos`
            }
        }else{
            result = `0 minutos`;
        }
        return result
    }

    //PRIMER LETRA MAYUSCULA CADENA
    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const redirect = (id, columnID) => {
        let fecha_init;
        let fecha_final;
        if(idDate === 0){
            fecha_init = moment().format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR).toString();
            fecha_final = moment().add(1, 'day').format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR).toString();
        }else if(idDate === 1){
            fecha_init = moment().startOf('week').format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR).toString();
            fecha_final = moment().endOf('week').format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR).toString();
        }else{
            fecha_init = moment().startOf('month').format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR).toString();
            fecha_final = moment().endOf('month').format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR).toString();
        }
        history.push(`/tickets?idUser=${id}&status=${columnID}&fecha_init=${fecha_init}&fecha_final=${fecha_final}`)
    }


    return(
        <>
            {dataSource !== null ? (
                <Table 
                dataSource={dataSource} 
                columns={colums}  
                scroll={{ x: 'max-content' }} 
                pagination={{pageSize:100}}
                className="tableData"/>
            ):
            (<span>No se pudieron cargar los datos</span>)}
        </>
    )
}

export default TableDataAsesor;