import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import Elevation from '../../../components/Elevation';
import { useEmpresasMutation } from '../../../hooks/api/empresas';

const useStyles = createUseStyles({
    container: {
        flex: 1,
        gap: 5,
        width: "100%",
        padding: 15,
        flexDirection: "column",
        height: "100%",
        "& .ant-form-item": {
            marginBottom: 12,
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    }
});

const EmpresaObservaciones = ({ empresa }) => {
    const styles = useStyles();
    const { mutateEmpresa, isLoadingEmpresaMutation, resetEmpresaMutation } = useEmpresasMutation();
    const [form] = Form.useForm();

    const handleClickGuardarObervaciones = () => {
        form.validateFields().then(values => {
            mutateEmpresa(
                { ...values, emp_id: empresa?.emp_id, mutationType: "update" },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            resetEmpresaMutation();
                            message.success("Observaciones actualizadas");
                        } else {
                            message.error("Hubo un problema al actualizar las observaciones");
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al actualizar las observaciones");
                    },
                }
            );
        }).catch(() => {
            message.error("Hubo un problema al actualizar las observaciones");
        })
    }

    useEffect(() => {
        form.setFieldValue("emp_observaciones", empresa?.emp_observaciones);
    }, [empresa])

    return (
        <Elevation className={styles.container}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Observaciones</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    <Button type="primary" shape="circle" size="small" icon={<SaveOutlined />} onClick={handleClickGuardarObervaciones} loading={isLoadingEmpresaMutation} />
                </div>
            </Row>
            <Row style={{ padding: 10 }}>
                <Form form={form} style={{ flex: 1 }}>
                    <Form.Item name="emp_observaciones">
                        <Input.TextArea showCount />
                    </Form.Item>
                </Form>
            </Row>
        </Elevation>
    );
}

export default EmpresaObservaciones;