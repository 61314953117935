import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetProductosServicios = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/productos-servicios`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getProductosServiciosMutation: mutation.mutate,
    };
}
export const PostProductosServicios = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/productos-servicios`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postProductosServiciosMutation: mutation.mutate,
    };
}
export const UpdateProductosServicios = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.put(`/productos-servicios/${params.id}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        updateProductosServiciosMutation: mutation.mutate,
    };
}