import { Button, Col, message, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useApiAreasDepartamentos, useAreasDepartamentosMutation } from "../../hooks/api/areas_departamentos";
import DataTable from "../../components/DataTable";
import { useModal } from "../../hooks/useModal";
import CrearAreaDepartamento from "./CrearAreaDepartamento";
import { DeleteOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import ActualizarAreaDepartamento from "./ActualizarAreaDepartamento";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    table: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
});

const AreasDepartamentos = () => {
    const styles = useStyles();
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [modalVisible, showModal, closeModal] = useModal();
    const [actualizarVisible, showActualizar, closeActualizar] = useModal();
    /** @type {[AreaDepartamentoDto, React.Dispatch<React.SetStateAction<AreaDepartamentoDto>>]} */
    const [selectedAreaDepto, setSelectedAreaDepto] = useState(null);
    const [eliminandoAreaDepto, setEliminandoAreaDepto] = useState(null);
    const [confirmEliminaAdeVisible, showConfirmEliminaAde, closeConfirmEliminaAde] = useModal();
    const { isLoadingAreasDepartamentosMutation, mutateAreasDepartamentos } = useAreasDepartamentosMutation();
    const { isLoading: isLoadingAreasDepartamentos, data: areasDepartamentos } = useApiAreasDepartamentos({
        initialData: [],
        enable: false,
    });
    const [search, setSearch] = useState(false)

    // const handleGridActionBegin = (args) => {
    //     if (args.action === "edit") {
    //         form.validateFields().then((rowValues) => {
    //             delete rowValues.ade_id;
    //             mutateAreasDepartamentos(
    //                 { mutationType: "update", idAreaDepartamento: args.data.ade_id, areaDepartamento: rowValues },
    //                 {
    //                     onSuccess: ({ success, mensaje }) => {
    //                         if (success) {
    //                             message.success(mensaje);
    //                             form.resetFields();
    //                         } else {
    //                             message.error(mensaje);
    //                             form.resetFields();
    //                         }
    //                     },
    //                     onError: (err) => {
    //                         console.log(err);
    //                         message.error("Hubo un problema al editar el  area o departamento");
    //                         form.resetFields();
    //                     },
    //                 }
    //             );
    //         });
    //     } else if (args.requestType === "delete") {
    //         if (args.data.length <= 0) return;
    //         mutateAreasDepartamentos(
    //             { mutationType: "delete", idAreaDepartamento: args.data[0].ade_id, areaDepartamento: null },
    //             {
    //                 onSuccess: ({ success, mensaje }) => {
    //                     if (success) {
    //                         message.success(mensaje);
    //                     } else {
    //                         message.error(mensaje);
    //                     }
    //                 },
    //                 onError: (err) => {
    //                     console.log(err);
    //                     message.error("Hubo un problema al eliminar el  area o departamento");
    //                 },
    //             }
    //         );
    //     }
    // };

    const formatTipo = (ade_tipo) => {
        switch (ade_tipo) {
            case 1:
                return "Area";
            case 2:
                return "Departamento";
            case 3:
                return "Otro";
            default:
                return 0;
        }
    };

    const handleAreaDepartamentoRowClick = (areaDepto) => {
        setSelectedAreaDepto(areaDepto);
        showActualizar();
    }

    const eliminaAreaDepartamento = () => {
        if (!eliminandoAreaDepto) return;
        mutateAreasDepartamentos(
            { mutationType: "delete", idAreaDepartamento: eliminandoAreaDepto.ade_id, areaDepartamento: null },
            {
                onSuccess: ({ success, mensaje }) => {
                    if (success) {
                        message.success(mensaje);
                        setEliminandoAreaDepto(null);
                        closeConfirmEliminaAde();
                    } else {
                        message.error(mensaje);
                    }
                },
                onError: (err) => {
                    console.log(err);
                    message.error("Hubo un problema al eliminar el  area o departamento");
                },
            }
        );
    }

    const showConfirmationEliminar = (e, areaDepto) => {
        e.stopPropagation();
        showConfirmEliminaAde();
        setEliminandoAreaDepto(areaDepto);
    }

    const renderAccionesTabla = (_, areaDepto) => {
        return (
            <div style={{ textAlign: "center" }}>
                <Button icon={<DeleteOutlined />} type="primary" shape="circle" onClick={e => showConfirmationEliminar(e, areaDepto)} />
            </div>
        )
    }

    const columns = [
        { index: "ade_id", title: "ID", width: 70 },
        { index: "ade_nombre", title: "Nombre", width: 200 },
        { index: "ade_tipo", title: "Tipo", width: 250, render: formatTipo },
        { index: "ade_descripcion", title: "Descripción", width: 250 },
        { title: "Acciones", width: 100, render: renderAccionesTabla },
    ];

    return (
        <PageWrapper title="Areas y Departamentos">

            <ConfirmationModal
                icon={<WarningOutlined
                    style={{ color: "gold", fontSize: 20 }} />}
                title="Advertencia"
                description="El área o departamento se borrará permanentemente , ¿Deseas continuar?"
                visible={confirmEliminaAdeVisible}
                close={closeConfirmEliminaAde}
                onOk={eliminaAreaDepartamento}
                onCancel={closeConfirmEliminaAde}
                loading={isLoadingAreasDepartamentosMutation}
            />

            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando areas y departamentos ..." spinning={isLoadingAreasDepartamentos}>
                            <DataTableHeader
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: showModal
                                    }
                                ]}
                                dataToFilter={areasDepartamentos}
                                setFilteredData={setFilteredRecords}
                                counter={search ? filteredRecords?.length : areasDepartamentos?.length}
                                setSearch={setSearch}
                            />
                            <DataTable
                                scroll={{ x: "100%" }}
                                columns={columns}
                                data={search ? filteredRecords : areasDepartamentos}
                                onRowClick={handleAreaDepartamentoRowClick}
                                className={styles.table}
                                paginationParams={{
                                    defaultPageSize: 10,
                                    total: areasDepartamentos.length,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    showSizeChanger: true,
                                    position: ["topRight"],
                                }}
                            />
                        </Spin>
                    </Elevation>
                </Col>
            </Row>

            <Modal
                title="Crear área o departamento"
                open={modalVisible}
                onCancel={closeModal}
                destroyOnClose
                footer={null}
            >
                <CrearAreaDepartamento closeModal={closeModal} />
            </Modal>

            <Modal
                title="Actualizar área o departamento"
                open={actualizarVisible}
                onCancel={closeActualizar}
                destroyOnClose
                footer={null}
            >
                <ActualizarAreaDepartamento closeModal={closeActualizar} areaDepartamento={selectedAreaDepto} />
            </Modal>
        </PageWrapper>
    );
};

export default AreasDepartamentos;
