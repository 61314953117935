import { useEffect, useRef } from "react";
import { LOTTIE_ANIMATIONS } from "../../../animations/lottie";
import Lottie from "lottie-web";
import { Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        flexDirection: "column",
    },
    administrationHomeAnimation: {
        width: "100%",
        maxWidth: 600
    },
    moduleHeader: {
        padding: "0px 10px 10px",
    },
    moduleActions: {
        display: "flex",
        alignItems: "end",
        gap: 10,
        marginLeft: 20
    },
    btnNavigation: {
        boxShadow: "rgba(0, 0, 0, 0.10) 0px 3px 10px 0px",
        minWidth: 80,
        border: "none",
        transition: "all 0.2s ease-in-out",
        fontWeight: 500,
        padding: "5px 10px",
        textAlign: "center",
        cursor: 'pointer',
        borderRadius: 20,
        "&:hover": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        },
        "&.active": {
            background: theme.secondaryColor,
            color: "#FFF",
            transform: "scale(1.05)"
        }
    }
}))

const Administracion = () => {
    const styles = useStyles();
    const animationContainer = useRef();

    useEffect(() => {
        Lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LOTTIE_ANIMATIONS.ADMINISTRATION_CHARTS,
        });
    }, []);

    return (
        <div className={styles.container}>
            {/* <Row className={styles.moduleHeader}>
                <Typography.Title level={2} style={{ margin: 0 }}>Administracion</Typography.Title>
                <div className={styles.moduleActions}>
                    <Link to="/administracion/marketing/campañas" className={`${styles.btnNavigation}`} >Campañas</Link>
                    <Link to="/anuncios/crear" className={`${styles.btnNavigation}`} ></Link>
                </div>
            </Row> */}
            <div className="animate__animated animate__fadeInDown"
                style={{
                    animationDuration: "0.3s",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div ref={animationContainer} className={styles.administrationHomeAnimation} />
            </div>
        </div>
    );
}

export default Administracion;