import { AUTH_LOGIN, AUTH_LOGOUT } from "../action_types/auth.action.types";

const authInitialState = {
    user: null,
    token: null,
};
const AuthReducer = (state = authInitialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN: {
            return {
                user: action.payload.user,
                token: action.payload.token,
            };
        }
        case AUTH_LOGOUT: {
            return {
                user: null,
                token: null,
            };
        }
        default:
            return state;
    }
};

export default AuthReducer;
