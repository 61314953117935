const rgbToHex = function (rgb) {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
};

const fullColorHex = function (r, g, b) {
    var red = rgbToHex(r);
    var green = rgbToHex(g);
    var blue = rgbToHex(b);
    return red + green + blue;
};

export const getRandomHexColor = () => {
    let currentR = Math.floor(Math.random() * (200 - 100)) + 100,
        currentG = Math.floor(Math.random() * (200 - 100)) + 100,
        currentB = Math.floor(Math.random() * (200 - 100)) + 100;
    let color = `#${fullColorHex(currentR, currentG, currentB)}`;
    return color;
};