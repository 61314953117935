import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import { Button, Modal, Row, Spin, Table, Tooltip, Typography, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from "../../../hooks/useModal";
import { useApiEmpresaGiros, useEliminarGiroEmpresa } from "../../../hooks/api/empresas";
import { useEffect, useState } from "react";
import CrearEmpresaGiro from "../Giros/CrearEmpresaGiro";
import { useQueryClient } from "react-query";
import ActualizarEmpresaGiro from "../Giros/ActualizarEmpresaGiro";

const useStyles = createUseStyles(() => ({
    container: {
        padding: 15,
        flex: 1,
        height: "100%",
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    modalContainer: {
        width: "100%",
    }
}));

const EmpresaGiros = ({ empresa }) => {
    const styles = useStyles();
    const [porcentajeDisponible, setPorcentajeDisponible] = useState(100);
    const [modalCrearVisible, showCrear, closeCrear] = useModal();
    const [modalEditarVisible, showEditar, closeEditar] = useModal();
    const [giroSeleccionado, setGiroSeleccionado] = useState(null);
    const { data: giros, isLoading: isLoadingGiros, refetch: refetchGiros } = useApiEmpresaGiros({ initialData: [], id: empresa?.emp_id })
    const { deleteGiroEmpresa, isDeletingGiroEmpresa } = useEliminarGiroEmpresa();

    const eliminarGiro = (giro) => {
        deleteGiroEmpresa({
            giro_id: giro.giro_id,
            emp_id: giro?.emp_id
        }, {
            onSuccess: res => {
                if (res.success) {
                    message.success(res.mensaje);
                    refetchGiros();
                } else {
                    message.error(res.mensaje);
                }
            },
            onError: () => {
                message.error("Hubo un problema al eliminar el giro");
            }
        })
    }

    const renderAccionesTabla = (_, giro) => (
        <div style={{ width: "100%", textAlign: "center" }}>
            <Tooltip title={"Eliminar giro"}>
                <Button
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => eliminarGiro(giro)}
                />
            </Tooltip>
        </div>
    )

    useEffect(() => {
        if (empresa?.emp_id) {
            refetchGiros();
        }
    }, [empresa])

    useEffect(() => {
        if (giros?.length > 0) {
            let porcentajeDisponible = 100 - giros.reduce((porcentaje, giro) => giro.giro_porcentaje + porcentaje, 0);
            if(giroSeleccionado){
                porcentajeDisponible = porcentajeDisponible + giroSeleccionado.giro_porcentaje;
            }
            setPorcentajeDisponible(porcentajeDisponible);
        }
    }, [giros, giroSeleccionado])

    const columns = [
        {
            dataIndex: "giro_nombre",
            title: "Giro"
        },
        {
            dataIndex: "giro_porcentaje",
            title: "Porcentaje",
            render: porcentaje => <div style={{ width: "100%", textAlign: "center" }}>{`${porcentaje}%`}</div>
        },
        {
            title: "Acciones",
            render: renderAccionesTabla
        },
    ];

    return (
        <Elevation className={styles.container}>
            <Spin spinning={isDeletingGiroEmpresa}>
                <Row style={{ justifyContent: "space-between" }}>
                    <Typography.Title level={5}>Giros de la empresa</Typography.Title>
                    <div className="section-buttons" style={{ width: "auto" }}>
                        <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={showCrear} />
                    </div>
                </Row>
                <Row>
                    <Table
                        dataSource={giros}
                        columns={columns}
                        style={{ width: "100%" }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: e => {
                                    setGiroSeleccionado(record)
                                    showEditar();
                                },
                            };
                        }}
                    />
                </Row>

                {/* modal para crear un nuevo giro de la empresa */}
                <Modal title="Agregar giro" open={modalCrearVisible} footer={null} onCancel={closeCrear} destroyOnClose={true} width="700px">
                    <div className={styles.modalContainer}>
                        <CrearEmpresaGiro
                            closeModal={closeCrear}
                            empresa={empresa}
                            porcentajeDisponible={porcentajeDisponible}
                        />
                    </div>
                </Modal>

                <Modal title="Editar giro" open={modalEditarVisible} footer={null} onCancel={closeEditar} destroyOnClose={true} width="700px">
                    <div className={styles.modalContainer}>
                        <ActualizarEmpresaGiro
                            closeModal={closeCrear}
                            empresa={empresa}
                            porcentajeDisponible={porcentajeDisponible}
                            giro={giroSeleccionado}
                        />
                    </div>
                </Modal>
                
            </Spin>
        </Elevation>
    );
}

export default EmpresaGiros;