const styles = {
    topBar: {
        backgroundColor: "#fff",
        width: "100%",
        height: "40px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
    },
    pageWrapper: {
        paddingTop: 10,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: "0 !important",
    },
    titleBullet: {
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        marginRight: 3,
        transition: "0.3s ease-in-out",
    },
    nuevoTicketModal: {
        "& .ant-modal-body": {
            padding: 0,
        },
    },
    drawerDatos: {
        "& .ant-drawer-body": {
            padding: 0,
            diplay: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
        },
        "& .ant-tabs-nav": {
            margin: 0,
        },
    },

};

export default styles;
