import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Avatar, Popover, Rate, Row, Tabs, Typography, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useApiContacto } from "../../hooks/api/contactos";
import { HorarioItem } from "./DatosContacto/ContactoHorarios";
import { TelefonoItem } from "../Tickets/DatosTicket/DatosContacto";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles((theme) => ({
    cardContainer: {
        maxHeight: 400,
        width: 250,
        padding: 10,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "& .ant-avatar": {
            backgroundColor: theme.primaryColor,
            verticalAlign: "middle",
        },
    },
}));

/**
 * 
 * @param {Object} props
 * @param {String} [props.popoverPlacement] - posicion en la que se va a abrir la ventana emergente
 * @param {Number} [props.avatarSize] - tamaño del avatar del contacto
 * @param {Number} [props.con_id] - id del contacto en coaso de que quierann cargar los datos del usuario
 * @returns 
 */
const ClientesPopoverCard = ({ popoverPlacement, avatarSize, con_id }) => {
    const styles = useStyles();
    const { data: contacto, isLoading: isLoadingContacto, refetch: refetchContacto } = useApiContacto(
        {
            initialData: [],
        },
        con_id
    );
    const [color, setColor] = useState(null);
    const [inicialesAvatar, setInicialesAvatar] = useState("");
    const colors = [
        "#FAA22E",
        "#EA651A",
        "#B5421F",
        "#352040",
        "#092F5A",
        "#1F9583",
        "#8DC07E",
        "#CA373C",
        "#49875C",
        "#69677A",
        "#F06859",
        "#D9896D",
        "#538E85",
        "#AEC4B1",
        "#9F655D",
        "#CB9C0B",
        "#B79616",
        "#073D57",
        "#CE0B0B",
        "#6D634C",
        "#272A1E",
        "#B44265",
    ];

    useEffect(() => {
        refetchContacto();
    }, [con_id])

    useEffect(() => {
        if (contacto) {
            let color = colors[Math.floor(Math.random() * colors.length)]
            setColor(color);
            let inicialNombre = contacto?.usu_nombres?.length > 0 ? contacto.usu_nombres[0].toUpperCase() : "";
            let inicialApellido = contacto?.usu_apaterno?.length > 0 ? contacto.usu_apaterno[0].toUpperCase() : "";
            setInicialesAvatar(`${inicialNombre}${inicialApellido}`);
        }
    }, [contacto])

    const content = (
        <div className={styles.cardContainer}>
            <Spin spinning={isLoadingContacto}>
                <Row style={{ justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 5, marginBottom: 10 }}>
                    <Avatar gap={0} size={60} style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", background: color }}>
                        {inicialesAvatar}
                    </Avatar>
                    <Typography.Title level={5} style={{ marginBottom: 0, textAlign: "center" }}>
                        {contacto?.usu_nombres}
                    </Typography.Title>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <PhoneOutlined
                                // style={{ color: "#0FC244" }}
                                style={{ color: "#1890FF" }}
                            />
                            <Typography.Link
                                strong
                                // href={`tel:${contacto?.usu_tel?.replace("(", "").replace(")", "").replace("-", "") || "#"}`}
                                target="_blank"
                            >
                                {contacto?.usu_tel}
                            </Typography.Link>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <MailOutlined style={{ color: "#1890FF" }} />
                            <Typography.Link
                                strong
                                // href={`mailto:${contacto?.usu_mail}`}
                                target="_blank"
                            >
                                {contacto?.usu_mail}
                            </Typography.Link>
                        </div>
                    </div>
                    <Rate disabled defaultValue={5} />
                </Row>
                <Tabs defaultActiveKey="1" centered={true} >
                    <Tabs.TabPane
                        tab={
                            <span>
                                Teléfonos
                            </span>
                        }
                        key="1"
                    >
                        <div style={{ width: "100%" }}>
                            {
                                contacto?.contacto_telefonos?.map((tel, iTel) => (
                                    <div style={{ borderBottom: "1px solid #eee", width: "100%", padding: "5px 10px" }} key={`telefono_${tel.usc_numero}_${iTel}`} >
                                        <TelefonoItem usu_tel={tel?.usc_numero} telefono={tel} key={`tel_${tel?.usc_id}`} />
                                    </div>
                                ))
                            }
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <span>
                                Horarios
                            </span>
                        }
                        key="2"
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column", gap: 10, overflowX: "hidden", padding: 10, maxHeight: "100%" }}
                        >
                            {
                                contacto?.horarios?.map((horario) => (
                                    // contacto?.horarios?.map((horario) => (
                                    <HorarioItem
                                        item={horario}
                                        key={`${horario.usho_id}_${horario.usho_horainicial}`}
                                        style={{
                                            maxWidth: 230,
                                            // transform: "scale(0.9)"
                                        }}
                                        allowDeleteHorario={false}
                                    />
                                ))
                            }
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </Spin>
        </div>
    );

    return (
        <Popover content={content} placement={popoverPlacement || "top"}>
            <div style={{ display: "flex", alignItems: "center", width: "36px" }}>
                <Avatar gap={0} size={avatarSize || 36} style={{ background: color, userSelect: "none", fontSize: avatarSize ? avatarSize * 0.55 : 20 }}>
                    {inicialesAvatar}
                </Avatar>
            </div>
        </Popover>
    );
};

export default ClientesPopoverCard;
