import moment from "moment";
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const PostActividad = ()  => {
    const api = useApi();

    let storage = JSON.parse(sessionStorage.getItem('persist:root'))
    let user = JSON.parse(storage.auth)

    const mutate = async (params) => {
        console.log(params)
        let parameters = params;
        parameters.files = parameters.files === undefined ? [] : parameters.files;
        const formData = new FormData();
        formData.append('acti_nombre', parameters.acti_nombre);
        formData.append('acti_fecha',  moment().format('YYYY-MM-DDTHH:mm:ss.000[Z]'));
        formData.append('acti_descripcion', parameters.acti_descripcion);
        formData.append('usu_id', `${user.user.usu_id}`);
        parameters.files?.fileList?.forEach(file => {
            formData.append('files[]', file.originFileObj !== undefined ? file.originFileObj : file, file.name );
        })
        const data  = await api.post(`/actividades`, formData, {
            dataType: "multipart",
            hasFormData: true,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        postActividadMutation: mutation.mutate,
    };
}