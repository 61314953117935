import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi, { AvailableRequestContentTypes } from "./useApiRequest";

export const useApiSistemasVersiones = (options, sistemaId) => {
    const api = useApi();

    return useQuery(
        ["sistemas", "versiones", sistemaId?.sistema?.sis_id || sistemaId],
        async () => {
            const { data } = await api.get(`/sistemas/${sistemaId?.sistema?.sis_id || sistemaId}/versiones`);
            return data;
        },
        options
    );
};


export const useCreateSistemaVersion = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const createSistema = async (version) => {
        const { data } = await api.post(`/sistemas/versiones`, version, { dataType: AvailableRequestContentTypes.MULTIPART, hasFormData: true });
        return data;
    };

    const {
        mutate: mutateSistemaVersion,
        isLoading: isLoadingSistemaVersionMutation,
        error: errorSistemaVersionMutation,
        reset: resetSistemaVersionMutation,
    } = useMutation(createSistema, {
        onSuccess: () => {
            queryClient.invalidateQueries(["sistemas", "versiones"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateSistemaVersion,
        isLoadingSistemaVersionMutation,
        errorSistemaVersionMutation,
        resetSistemaVersionMutation,
    };
};

export const useSistemaVersionUpdateMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const createSistemaVersion = async (version) => {
        const { data: tData } = await api.put(`/sistemas/versiones/${version?.csiv_id}`, version, { dataType: AvailableRequestContentTypes.JSON });
        return tData;
    };

    const {
        mutate: mutateSistemaVersionUpdate,
        isLoading: isLoadingSistemaVersionUpdateMutation,
        error: errorSistemaVersionUpdateMutation,
        reset: resetSistemaVersionUpdateMutation,
    } = useMutation(createSistemaVersion, {
        onSuccess: () => {
            queryClient.invalidateQueries("sistemas");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateSistemaVersionUpdate,
        isLoadingSistemaVersionUpdateMutation,
        errorSistemaVersionUpdateMutation,
        resetSistemaVersionUpdateMutation,
    };
};

export const useSistemaVersionDeleteMutation = (options, sistemaId) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const deleteSistemaVersion = async (versionId) => {
        const { data: tData } = await api.del(`/sistemas/versiones/${versionId}`);
        return tData;
    };

    const {
        mutate: mutateSistemaVersionDelete,
        isLoading: isLoadingSistemaVersionDeleteMutation,
        error: errorSistemaVersionDeleteMutation,
        reset: resetSistemaVersionDeleteMutation,
    } = useMutation(deleteSistemaVersion, {
        onSuccess: () => {
            queryClient.invalidateQueries(["sistemas", "versiones", sistemaId]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateSistemaVersionDelete,
        isLoadingSistemaVersionDeleteMutation,
        errorSistemaVersionDeleteMutation,
        resetSistemaVersionDeleteMutation,
    };
};



/**
 * 
 * @typedef CopyingSistemaVersion
 * @property {Number} csiv_id - id de la version que se va a copiar
 * @property {Number[]} [sistemas] - ids de los sistemas a los que se va a copiar la version   
 */

export const useSistemaVersionesCopy = () => {
    const api = useApi();

    /**
     * 
     * @param {CopyingSistemaVersion} copyParams - datos necesarios para copiar la version
     */
    const addResource = async (copyParams) => {
        const response = await api.post(`/sistemas/versiones/copy`, copyParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        copySistemaVersion: mutation.mutate,
        isCopyingSistemaVersion: mutation.isLoading,
        isCopiedSistemaVersion: mutation.isSuccess,
        isCopyingSistemaVersionError: mutation.isError,
    };
};
