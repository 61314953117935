import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useApiUsuariosIncidencias = (options) => {
    const api = useApi();
    return useQuery(
        ["usuarios", "incidencias"],
        async () => {
            const { data } = await api.get(`/usuarios/${options?.id}/incidencias`);
            return data;
        },
        options
    );
};

// export const useApiTicketsSeguimiento = (options, id) => {
//     const api = useApi();
//     return useQuery(W
//         ["ticket", "seguimiento"],
//         async () => {
//             const { data } = await api.get(`/tickets/${id}/seguimiento`);
//             return data;
//         },
//         options
//     );
// };
// export const useApiTicketsIncidenciaApoyo = (options, id) => {
//     const api = useApi();
//     return useQuery(
//         ["ticket", "incidencias_apoyo", id],
//         async () => {
//         const { data } = await api.get(`/tickets/${id}/incidencias_apoyo`);
//             return data;
//         },
//         options
//     );
// };

export const useUsuariosIncidenciasMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutateIncidencia = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/usuarios_incidencias`, params?.incidencias || params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     const { data } = await api.put(`/areas-departamentos/${params.idTicketsIncidencias}`, params.areaDepartamento, { dataType: "json" });
        //     return data;
        // }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/areas-departamentos/${params.idTicketsIncidencias}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateUsuariosIncidencias,
        isLoading: isLoadingUsuariosIncidenciasMutation,
        error: errorUsuariosIncidenciasMutation,
        reset: resetUsuariosIncidenciasMutation,
    } = useMutation(mutateIncidencia, {
        onSuccess: () => {
            queryClient.invalidateQueries(["usuarios", "incidencias"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateUsuariosIncidencias,
        isLoadingUsuariosIncidenciasMutation,
        errorUsuariosIncidenciasMutation,
        resetUsuariosIncidenciasMutation,
    };
};
