import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useApiTicketsNotasRapidas = (options) => {
    const api = useApi();
    return useQuery(
        ["ticket", "incidencias"],
        async () => {
            const { data } = await api.get(`/tickets/${options?.id}/notas_rapidas`);
            return data;
        },
        options
    );
};

export const useApiTicketsSeguimiento = (options, id) => {
    const api = useApi();
    return useQuery(
        ["ticket", "seguimiento"],
        async () => {
            const { data } = await api.get(`/tickets/${id}/seguimiento`);
            return data;
        },
        options
    );
};

export const useApiTicketsIncidenciaApoyo = (options, id) => {
    const api = useApi();
    return useQuery(
        ["ticket", "incidencias_apoyo", id],
        async () => {
            const { data } = await api.get(`/tickets/${id}/incidencias_apoyo`);
            return data;
        },
        options
    );
};

export const useApiIncidenciasApoyo = (options) => {
    const api = useApi();
    return useQuery(
        ["tickets_incidencias", "incidencias_apoyo"],
        async () => {
            const { data } = await api.get(`/tickets_incidencias/incidencias_apoyo`);
            return data;
        },
        options
    );
};

export const useTicketsIncidenciasMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const createTicketIncidencia = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const formData = new FormData();
            let isFormData = false;
            if(params?.type === "help"){
                isFormData = true;
                delete params.type;
                if(params?.incidencias ){
                    for (const key in params?.incidencias) {
                        if (Array.isArray(params?.incidencias[key])) {
                            params?.incidencias[key].forEach((element, index) => {
                                formData.append(key, element);
                            });
                        } else {
                            formData.append(key, params?.incidencias[key]);
                        }
                    }
                }else{
                    for (const key in params) {
                        if (Array.isArray(params[key])) {
                            params[key].forEach((element, index) => {
                                formData.append(key, element);
                            });
                        } else {
                            formData.append(key, params[key]);
                        }
                    }
                }
            }else{
                isFormData = false;
            }
            
            const { data } = await api.post(`/tickets/${params.tick_id}/incidencias`, isFormData ? formData : (params?.incidencias || params) , {
                hasFormData: true,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            const { data } = await api.put(`/tickets/${params.tick_id}/incidencias`, params?.incidencias || params, { dataType: "json" });
            return data;
        }
    };

    const {
        mutate: mutateTicketsIncidencias,
        isLoading: isLoadingTicketsIncidenciasMutation,
        error: errorTicketsIncidenciasMutation,
        reset: resetTicketsIncidenciasMutation,
    } = useMutation(createTicketIncidencia, {
        onSuccess: () => {
            queryClient.invalidateQueries(["ticket", "incidencias"]);
            queryClient.invalidateQueries(["ticket", "seguimiento"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateTicketsIncidencias,
        isLoadingTicketsIncidenciasMutation,
        errorTicketsIncidenciasMutation,
        resetTicketsIncidenciasMutation,
    };
};
