import { useState } from "react";

/**
 * 
 * @returns {[Boolean, Function, Function]}
 */
export const useModal = () => {
    const [show, setShow] = useState(false);

    const closeModal = () => {
        setShow(false);
    };
   
    const showModal = () => {
        setShow(true);
    };

    return [show, showModal, closeModal];
};
