import { DeleteOutlined, EditOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Elevation from '../../../components/Elevation';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { useApiEmpresasLicencias, useEmpresaLicenciasMutation } from '../../../hooks/api/empresas';
import { useModal } from "../../../hooks/useModal"
import CrearEmpresaLicencia from '../Licencias/CrearEmpresaLicencia';

const useStyles = createUseStyles({
    container: {
        padding: 15,
        flex: 1,
        height: "100%",
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    modalContainer: {
        "& .ant-form-item": {
            flex: 1
        }
    }
});

const EmpresaLicencias = ({ empresa }) => {
    const styles = useStyles();
    const [show, showModal, closeModal] = useModal()
    const [confirmationVisible, showConfirmation, closeConfirmation] = useModal()
    const { data: licencias, isLoading: isLoadingLicencias, refetch: refetchLicencias } = useApiEmpresasLicencias({ initialData: [], id: empresa?.emp_id })
    const { mutateEmpresaLicencias, resetEmpresaLicenciasMutation } = useEmpresaLicenciasMutation();
    const [loading] = useState(false);
    const [eliminandoLicencia, setEliminandoLicencia] = useState(null);
    const [editandoLicencia, setEditandoLicencia] = useState(null);


    let columns = [
        {
            title: "Sistema",
            render: (_, data) => {
                return data?.sistema?.sis_nombre || ""
            }
        },
        {
            dataIndex: "usli_numeroserie",
            title: "Serie",
        },
        {
            dataIndex: "usli_empresaregistrante",
            title: "Empresa Registrante",
        },
        {
            dataIndex: "usli_tipo",
            title: "Tipo",
            render: (_, data) => {
                switch (data.usli_tipo) {
                    case 1: return "Anual";
                    case 2: return "Tradicional";
                    case 3: return "Demo";
                }
            }
        },
        {
            dataIndex: "usli_numusuarios",
            title: "No. de usuarios",
        },
        {
            title: "Acciones",
            render: (_, licencia) => {
                return <div style={{ display: "flex", gap: 15 }}>
                    <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => handleEditarLicencia(licencia)} />
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => handleEliminarLicencia(licencia)} />
                </div>
            }
        }
    ]

    const closeModalEdit = () => {
        closeModal();
        setEditandoLicencia(null);
    }

    const handleEditarLicencia = (licencia) => {
        let tLicencia = { ...licencia };
        delete tLicencia.sistema;
        showModal();
        setEditandoLicencia(licencia);
    }

    const eliminarLicencia = () => {
        if (eliminandoLicencia) {
            mutateEmpresaLicencias({
                mutationType: "delete",
                emp_id: empresa.emp_id,
                usli_id: eliminandoLicencia.usli_id,
            }, {
                onSuccess: (res) => {
                    if (res?.success) {
                        resetEmpresaLicenciasMutation();
                        message.success(res.mensaje)
                    } else {
                        message.error(res.mensaje);
                    }
                    closeConfirmation();
                },
                onError: () => {
                    message.error("Hubo un problema al eliminar la licencia");
                    closeConfirmation();
                }
            })
        }
    }


    const handleEliminarLicencia = (licencia) => {
        showConfirmation();
        setEliminandoLicencia(licencia);
    }

    useEffect(() => {
        refetchLicencias();
    }, [empresa])

    useEffect(() => {
        if (editandoLicencia) {
            let licencia = licencias.find(lic => lic.usli_id === editandoLicencia.usli_id);
            setEditandoLicencia(licencia || null)
        }
    }, [licencias])

    return (
        <>
            <ConfirmationModal
                icon={<WarningOutlined
                    style={{ color: "gold", fontSize: 20 }} />}
                title="Advertencia"
                description="La licencia se borrará permanentemente , ¿Deseas continuar?"
                visible={confirmationVisible}
                close={closeConfirmation}
                onOk={eliminarLicencia}
                onCancel={closeConfirmation}
                loading={loading}
            />
            <Elevation className={styles.container}>
                <Spin spinning={isLoadingLicencias}>
                    <Row style={{ justifyContent: "space-between" }}>
                        <Typography.Title level={5}>Licencias</Typography.Title>
                        <div className="section-buttons" style={{ width: "auto" }}>
                            <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={showModal} />
                        </div>
                    </Row>
                    <Row>
                        <Table dataSource={licencias} columns={columns} style={{ width: "100%" }} />
                    </Row>

                    {/* modal para crear la licencia de la empresa */}
                    <Modal title="Agregar licencia" open={show} footer={null} onCancel={closeModalEdit} destroyOnClose={true} width="700px">
                        <div className={styles.modalContainer}>
                            <CrearEmpresaLicencia
                                closeModal={closeModal}
                                empresa={empresa}
                                editandoLicencia={editandoLicencia}
                            />
                        </div>
                    </Modal>
                </Spin>

            </Elevation>
        </>
    );
}

export default EmpresaLicencias;