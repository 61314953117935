// @ts-nocheck
import useApi from "./useApiRequest";
import { useMutation } from "react-query";
import { formatUrlQuery } from "./utils";

export const GetEmpresas = ()  => {
    const api = useApi();


    const mutate = async (params) => {
        console.log(params)
        const data  = await api.get(`/empresas?${formatUrlQuery(params.params, true)}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getEmpresasMutation: mutation.mutate,
    };
}