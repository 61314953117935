import { Button, Form, Input, Row, Select, message } from "antd";
import { createUseStyles } from "react-jss";
import NumericInput from "../../../components/form/NumericInput";
import { useCrearGiroEmpresa } from "../../../hooks/api/empresas";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles(() => ({
    container: {
        width: "100%",
        "& .ant-form": {
            width: "100%"
        }
    },
    buttons: {
        justifyContent: "end",
        gap: 10
    }
}));

const CrearEmpresaGiro = ({ closeModal, empresa, porcentajeDisponible }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const { isSavingGiroEmpresa, saveGiroEmpresa } = useCrearGiroEmpresa();

    const minValidator = (_, value) => {
        return new Promise((resolve, reject) => {
            try {
                if (parseInt(value) < 0)
                    reject("el valor no puede ser menor a 0");
                resolve()
            } catch (e) {
                reject("valor no permitido")
            }
        })
    }

    const maxValidator = (_, value) => {
        return new Promise((resolve, reject) => {
            try {
                if (parseInt(value) > porcentajeDisponible) {
                    reject("el valor no puede ser mayor a 100");
                }
                resolve();
            } catch (e) {
                reject("valor no permitido")
            }
        })
    }

    const guardarGiro = () => {
        // se lanza un error si es que no se ha actualizado los datos de la empresa en el componente
        if (!empresa)
            throw Error("No se puede guardar el giro por que no se ha recibido la informacion de la empresa");

        form.validateFields().then(values => {
            // se mandan los datos al server
            values = {
                ...values,
                emp_id: empresa?.emp_id
            };
            saveGiroEmpresa(values, {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje);
                        queryClient.invalidateQueries(["empresas", "giros"]);
                        closeModal();
                    } else {
                        message.error(res.mensaje)
                    }
                }, onError: () => {
                    message.error("Hubo un problema al agregar el giro de la empresa")
                }
            })
        }).catch(() => {
            message.error("Error en el formulario");
        })
    }

    return (
        <div className={styles.container}>
            <Row>
                <Form layout="vertical" form={form}>
                    <Form.Item label="Giro" name="giro_nombre" rules={[{ required: true, message: "El giro es requerido" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div style={{ display: "flex", gap: 15 }}>
                                <span>Porcentaje</span>
                                <div style={{ display: "flex", gap: 5 }}>
                                    (<span style={{ color: "#aaa" }}>Disponible: </span>
                                    <span style={{ color: "#aaa" }}>{porcentajeDisponible}</span>)
                                </div>
                            </div>
                        }
                        name="giro_porcentaje"
                        rules={[
                            { required: true, message: "El giro es requerido" },
                            { validator: minValidator, message: "El mínimo es de 0" },
                            { validator: maxValidator, message: `El máximo es de ${porcentajeDisponible}` }
                        ]}
                    >
                        <NumericInput />
                    </Form.Item>
                </Form>
            </Row>
            <Row className={styles.buttons}>
                <Button type="secondary" onClick={closeModal}>Cancelar</Button>
                <Button type="primary" onClick={guardarGiro} loading={isSavingGiroEmpresa}>Guardar</Button>
            </Row>
        </div>
    );
}

export default CrearEmpresaGiro;