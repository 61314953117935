import { EditOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, DatePicker, Descriptions, Form, Input, message, Rate, Row, Select, Spin, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import Elevation from "../../components/Elevation";
import Coin24 from "../../../images/coin_64x64.png";
import ContactoHorarios from "./DatosContacto/ContactoHorarios";
import ContactoTelefonos from "./DatosContacto/ContactoTelefonos";
import ContactoCamposExtras from "./DatosContacto/ContactoCamposExtras";
import { useApiContacto, useContactoMutation } from "../../hooks/api/contactos";
import { DateTime } from "luxon";
import { useQueryUtils } from "../../hooks/api/utils";
import moment from "moment";
import ContactoEmpresas from "./DatosContacto/ContactoEmpresas";
import { DATE_FORMATS } from "../../helpers/dates.helper";

const useStyles = createUseStyles({
    pageWrapper: {
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 40,
    },

    ultimosMovimientos: {
        flex: 1,
        padding: 10,
    },
    banner: {
        padding: 20,
        marginBottom: 16,
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 16,
        "& #btn-editar": {
            transform: "scale(1.2)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            margin: 0,
        },
        "&:hover": {
            "& #btn-editar": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
    },
    coinWrapper: {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
});

const Contacto = ({ propId, ticket, setUsandoTicket }) => {
    const styles = useStyles();
    let { id } = useParams();
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const { data: contacto, isLoading: isLoadingContacto, refetch: refetchContacto } = useApiContacto({ initialData: {} }, propId || id);
    const { mutateContacto, resetContactoMutation } = useContactoMutation();
    const { invalidateQuery } = useQueryUtils()

    const handleClickEditarDatosGenerales = () => {
        setIsEditing(true)
    }

    const handleClickGuardarInfo = () => {
        form.validateFields().then(values => {
            if (values?.usu_fechanac) {
                values.usu_fechanac = values.usu_fechanac.format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR);
            }

            mutateContacto({
                mutationType: "update",
                usu_id: contacto?.usu_id,
                ...values,
            }, {
                onSuccess: (res) => {
                    if (res?.success) {
                        message.success(res.mensaje);
                        resetContactoMutation();
                        setIsEditing(false)
                        invalidateQuery(["contacto", `${contacto?.usu_id}`])
                        invalidateQuery("tickets")
                        if (setUsandoTicket && ticket)
                            setUsandoTicket({
                                ...ticket,
                                agentes: ticket.agentes.map(tAgente => {
                                    if (tAgente?.agente.usu_id === contacto.usu_id) {
                                        tAgente = {
                                            ...tAgente,
                                            agente: {
                                                ...tAgente.agente,
                                                ...values
                                            }
                                        }
                                    } else {
                                        return tAgente;
                                    }
                                    return tAgente;
                                })
                            })

                    } else {
                        message.error(res.mensaje);
                        setIsEditing(false)
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al actualizar la info del contacto");
                }
            });
        }).catch(() => message.error("Hubo un problema al actualizar la info del contacto"));
    }

    useEffect(() => {
        refetchContacto();
    }, [propId, id])

    useEffect(() => {
        if (isEditing) {
            let usu_fechanac = moment.utc(contacto?.usu_fechanac, DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR);
            form.setFieldsValue({
                usu_nombres: contacto?.usu_nombres,
                usu_apaterno: contacto?.usu_apaterno,
                usu_amaterno: contacto?.usu_amaterno,
                usu_tipo: contacto?.usu_tipo,
                usu_departamento: contacto?.usu_departamento,
                usu_titulo: contacto?.usu_titulo,
                usu_activo: contacto?.usu_activo,
                usu_fechanac: usu_fechanac.isValid() ? usu_fechanac : moment("2000-01-01", DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR),
                usu_genero: contacto?.usu_genero
            })
        }
    }, [contacto, isEditing])

    return (
        <Spin spinning={isLoadingContacto}>
            <Row className={styles.pageWrapper}>
                <Elevation className={styles.banner}>
                    <div style={{ width: 64, height: 64 }}>
                        <Avatar size={64} icon={<UserOutlined />} />
                    </div>
                    <div>
                        <Form form={form}>
                            <div style={{ display: "flex", gap: 16, justifyContent: "space-between", marginBottom: 10 }}>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                                        <Typography.Title level={4} bold style={{ marginBottom: 0 }}>
                                            {!isEditing &&
                                                `${contacto?.usu_nombres} ${contacto?.usu_apaterno} ${contacto?.usu_amaterno}`
                                            }
                                            {isEditing &&
                                                <div style={{ display: "flex", gap: 10 }}>
                                                    <Form.Item name="usu_nombres">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name="usu_apaterno">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name="usu_amaterno">
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                            }
                                        </Typography.Title>
                                        {
                                            !isEditing ?
                                                <Button
                                                    id="btn-editar"
                                                    type="primary"
                                                    shape="circle"
                                                    size="small"
                                                    icon={<EditOutlined />}
                                                    onClick={handleClickEditarDatosGenerales}
                                                //  loading={isLoadingEmpresaMutation}
                                                />
                                                :
                                                <Button
                                                    id="btn-guardar"
                                                    type="primary"
                                                    shape="circle"
                                                    size="small"
                                                    icon={<SaveOutlined />}
                                                    onClick={handleClickGuardarInfo}
                                                //  loading={isLoadingEmpresaMutation}
                                                />
                                        }
                                    </div>
                                    <Rate disabled defaultValue={contacto?.usu_nivel} />
                                </div>
                                <div className={styles.coinWrapper}>
                                    <img src={Coin24} width={26} height={26} alt="koins" />{" "}
                                    <span style={{ fontWeight: 700, marginLeft: 2, fontSize: 16 }}>0</span>
                                </div>
                            </div>

                            <Descriptions title="Generales">
                                <Descriptions.Item label="Correo" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {contacto?.usu_mail}
                                </Descriptions.Item>
                                <Descriptions.Item label="Tipo de contacto" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {!isEditing ? (
                                        contacto?.usu_tipo === 1 ? (
                                            "Administrador"
                                        ) : (
                                            "Usuario"
                                        )
                                    ) : (
                                        <Form.Item name="usu_tipo" style={{ width: "calc(100% - 20px)" }}>
                                            <Select style={{ width: "100%" }}>
                                                <Select.Option value={1}>Administrador</Select.Option>
                                                <Select.Option value={2}>Usuario</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Departamento" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {!isEditing && contacto?.usu_departamento}
                                    {isEditing &&
                                        <Form.Item name="usu_departamento">
                                            <Input />
                                        </Form.Item>}
                                </Descriptions.Item>
                                <Descriptions.Item label="Título" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {!isEditing && contacto?.usu_titulo}
                                    {isEditing &&
                                        <Form.Item name="usu_titulo">
                                            <Input />
                                        </Form.Item>}
                                </Descriptions.Item>
                                <Descriptions.Item label="Estatus" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {!isEditing ? contacto?.usu_activo ? "Activo" : "Inactivo" : null}
                                    {isEditing &&
                                        <Form.Item name="usu_activo" valuePropName="checked">
                                            <Switch />
                                        </Form.Item>}
                                </Descriptions.Item>
                                <Descriptions.Item label="Fecha de nacimiento" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {!isEditing ? contacto?.usu_fechanac ? DateTime.fromISO(contacto.usu_fechanac, {zone: "UTC"}).toFormat("dd/MM/yyyy") : null : null}
                                    {isEditing &&
                                        <Form.Item name="usu_fechanac">
                                            <DatePicker placeholder="Selecciona la fecha" format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} />
                                        </Form.Item>}
                                </Descriptions.Item>
                                <Descriptions.Item label="Genero" labelStyle={{ color: "#1890ff", fontWeight: 600 }}>
                                    {!isEditing ? contacto?.usu_genero === 1 ? "Femenino" : contacto?.usu_genero === 2 ? "Masculino" : null : null}
                                    {isEditing &&
                                        <Form.Item name="usu_genero" style={{ width: 150 }}>
                                            <Select style={{ width: "100%" }}>
                                                <Select.Option value={1}>Femenino</Select.Option>
                                                <Select.Option value={2}>Masculino</Select.Option>
                                            </Select>
                                        </Form.Item>}
                                </Descriptions.Item>
                            </Descriptions>
                        </Form>
                    </div>
                </Elevation>
                <Row style={{ gap: 20, width: "100%" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 20 }}>
                        <Row >
                            <ContactoHorarios con_id={propId || id} />
                        </Row>
                        <Row >
                            <ContactoCamposExtras usu_id={propId || id} />
                        </Row>
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 20 }}>
                        <Row >
                            <ContactoEmpresas contacto={contacto} refetchContacto={refetchContacto} />
                        </Row>
                        <Row >
                            <ContactoTelefonos con_id={propId || id} />
                        </Row>
                    </div>
                </Row>
            </Row>
        </Spin >
    );
};

export default Contacto;
