import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useApiUsuariosNotificaciones = (options, id) => {
    const api = useApi();
    return useQuery(
        ["usuarios", "notificaciones"],
        async () => {
            const { data } = await api.get(`/usuarios/${id}/notificaciones`);
            return data;
        },
        options
    );
};


export const useUsuariosNotificacionesMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            let usu_id = params.id;
            delete params.id;
            const { data } = await api.post(`/usuarios/${usu_id}/notificaciones`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     let id = params.usu_id;
        //     delete params.usu_id;
        //     const { data } = await api.put(`/usuarios/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/usuarios/${params.usu_id}/notificaciones/${params.noti_id}`);
            return data;
        }
    };

    const {
        mutate: mutateUsuariosNotificaciones,
        isLoading: isLoadingUsuariosNotificacionesMutation,
        error: errorUsuariosNotificacionesMutation,
        reset: resetUsuariosNotificacionesMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["usuarios", "notificaciones"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateUsuariosNotificaciones,
        isLoadingUsuariosNotificacionesMutation,
        errorUsuariosNotificacionesMutation,
        resetUsuariosNotificacionesMutation,
    };
};
