import useApi from "./useApiRequest";
import { useMutation } from "react-query";


export const NewUsuarioActividad = ()  => {
    const api = useApi();


    const mutate = async (params) => {
        const data  = await api.post(`/usuarios-actividades`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        newUsuariosActividadMutation: mutation.mutate,
    };
}