import useApi from "./useApiRequest";
import { useMutation, useQuery } from "react-query";
import { formatUrlQuery } from "./utils";

export const useKnowBaseItems = (options, params) => {
    const api = useApi();
    return useQuery(
        ["know-base-items"],
        async () => {
            if (params) {
                let { data } = await api.get(`/know-base-items?${formatUrlQuery(params)}`);
                return data || [];
            } else {
                const { data } = await api.get(`/know-base-items`);
                return data || [];
            }
        },
        options
    );
};

export const useKnowBaseItem = (options, id) => {
    const api = useApi();
    return useQuery(
        ["know-base-item", id],
        async () => {
            const { data } = await api.get(`/know-base-items/${id}`);
            return data;
        },
        options
    );
};

/**
 * 
 * @typedef CreatingKnowBaseItem
 * @property {Number} kbit_sintomas - sintomas del item
 * @property {Number} kbit_problematica - problematica del item
 * @property {Number} kbit_solucion - solucion del item
 * @property {Number[]} categories - ids de las categorias del item
 * @property {Number} [kbit_titulo] - titulo del item   
 * @property {Number} [tick_id] - id del ticket si se relaciona con un ticket
 */

export const useCreateKnowBaseItem = () => {
    const api = useApi();

    /**
     * 
     * @param {CreatingKnowBaseItem} data - datos necesarios para crear el item de la base de conocimiento
     */
    const addResource = async (data) => {
        const response = await api.post(`/know-base-items`, data, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        createKnowBaseItem: mutation.mutate,
        isCreatingKnowBaseItem: mutation.isLoading,
        isCreatedKnowBaseItem: mutation.isSuccess,
        isCreatingKnowBaseItemError: mutation.isError,
    };
};

/**
 * 
 * @typedef UpdatingKnowBaseItem
 * @property {Number} kbit_id - id of the item
 * @property {Number} kbit_titulo - id of the item
 * @property {Number} kbit_sintomas - symptoms of the item
 * @property {Number} kbit_problematica - problematic of the item
 * @property {Number} kbit_solucion - solution of the item
 * @property {Number[]} [categories] - ids  of the categories of the item
 */

export const useUpdateKnowBaseItem = () => {
    const api = useApi();

    /**
     * 
     * @param {UpdatingKnowBaseItem} data - required data for update the know base item 
     */
    const updateResource = async (data) => {
        const response = await api.patch(`/know-base-items/${data.kbit_id}`, data, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(updateResource);

    return {
        updateKnowBaseItem: mutation.mutate,
        isUpdatingKnowBaseItem: mutation.isLoading,
        isUpdatedKnowBaseItem: mutation.isSuccess,
        isUpdatingKnowBaseItemError: mutation.isError,
    };
};

// ------------------- servicios de categorias de items -------------------

export const useKnowBaseCategories = (options) => {
    const api = useApi();
    return useQuery(
        ["know-base-categories"],
        async () => {
            const { data } = await api.get(`/know-base-categories/`);
            return data;
        },
        options
    );
};

/**
 * 
 * @typedef CreateKnowBaseCategoryDto
 * @property {String} kbca_nombre - nombre de la categoria 
 */

export const useCreateNewKnowBaseCategory = () => {
    const api = useApi();

    /**
     * 
     * @param {CreateKnowBaseCategoryDto} data - datos necesarios para crear la categoria de la base de conocimiento7
     */
    const addResource = async (data) => {
        const response = await api.post(`/know-base-categories`, data, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        createKnowBaseCategory: mutation.mutate,
        isCreatingKnowBaseCategory: mutation.isLoading,
        isCreatedKnowBaseCategory: mutation.isSuccess,
        isCreatingKnowBaseCategoryError: mutation.isError,
    };
};