import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import NumericInput from "../../../components/form/NumericInput";
import InputMask from 'react-input-mask';
import { useModal } from "../../../hooks/useModal";
import { useApiContactoCamposExtra, useContactoCamposExtraMutation } from "../../../hooks/api/contactos";

const useStyles = createUseStyles({
    datosExtras: {
        flex: 1,
        padding: 15,
        flexDirection: "column",
        height: "100%",
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
        "& .ant-divider-horizontal": {
            margin: "10px 0",
        },
        "& .data-item": {
            width: "100%",
            display: "flex",
            "& .data-item-label": {
                flex: 0.75,
                color: "#1890ff",
                fontWeight: 500,
            },
            "& .data-item-value": {
                fontWeight: 500,
                flex: 1.25,
            },
        },
    },
    modalContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .ant-form-item": {
            flex: 1,
        },
    },
});

const initialFieldsValues = {
    came_nombre: null,
    came_tipo: 1,
    came_importante: false,
    came_valor: "",
    came_mascara: ""
};

const ContactoCamposExtras = ({ usu_id }) => {
    const styles = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [editingValue, setEditingValue] = useState(null);
    const [tipoCampo, setTipoCampo] = useState(1);
    const [form] =  Form.useForm();
    const [formEditar] =  Form.useForm();

    const {
        data: camposExtra,
        refetch: refetchCamposExtra,
    } = useApiContactoCamposExtra({
        intialData: [],
        id: usu_id,
    });
    const [visibleEditar, showModalEditar, closeModalEditar] = useModal();
    const { mutateContactoCamposExtra, isLoadingContactoCamposExtraMutation, resetContactoCamposExtraMutation } = useContactoCamposExtraMutation();


    const closeModal = () => setShowModal(false);

    const handleShowModal = () => setShowModal(true);

    const getTipoCampo = (tipo) => {
        switch (tipo) {
            case 1:
                return "Boleano";
            case 2:
                return "Númerico";
            case 3:
                return "Texto Libre";
            case 4:
                return "Texto con máscara";
            default:
                return "";
        }
    };

    const handleCrearDatoExtra = () => {
        form.validateFields()
            .then((values) => {
                mutateContactoCamposExtra(
                    {
                        ...values,
                        mutationType: "create",
                        usu_id,
                    },
                    {
                        onSuccess: (res) => {
                            if (res.success) {
                                resetContactoCamposExtraMutation();
                                form.resetFields();
                                form.setFieldsValue(initialFieldsValues);
                                closeModal();
                                message.success(res.mensaje);
                            } else {
                                message.error(res.mensaje || "Hubo un problema al guardar el dato extra");
                            }
                        },
                        onError: () => {
                            message.error("Hubo un problema al guardar el dato extra");
                        },
                    }
                );
            })
            .catch(() => {
                message.error("Aún hay campos faltantes");
            });
    };

    const handleClickDeleteCampo = (came_id) => {
        mutateContactoCamposExtra(
            {
                mutationType: "delete",
                usu_id,
                came_id
            },
            {
                onSuccess: (res) => {
                    if (res.success) {
                        resetContactoCamposExtraMutation();
                        message.success(res.mensaje);
                    } else {
                        message.error(res.mensaje || "Hubo un problema al eliminar el dato extra");
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al eliminar el dato extra");
                },
            }
        );
    };

    useEffect(() => {
        refetchCamposExtra();
    }, [usu_id]);


    const camposExtrasColumns = [
        {
            dataIndex: "came_nombre",
            title: "Nombre del campo",
        },
        {
            dataIndex: "came_tipo",
            title: "Tipo",
            render: (fieldValue) => {
                return getTipoCampo(fieldValue);
            },
        },
        {
            dataIndex: "came_valor",
            title: "Valor del campo",
        },
        {
            dataIndex: "came_fecha",
            title: "Fecha de registro",
            render: (fieldValue) => {
                return DateTime.fromISO(fieldValue).toFormat("dd/MM/yyyy");
            },
        },
        {
            dataIndex: "came_importante",
            title: "Importante",
            render: (importante) => {
                return importante ? "Si" : "No";
            },
        },
        {
            title: "",
            render: (_, row) => {
                return (
                    <Button
                        shape="circle"
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => {
                            setTipoCampo(row.came_tipo);
                            setEditingValue(row)
                            showModalEditar()
                        }}
                        key={`editar-came-${row.came_id}`}
                    />
                );
            },
        },
        {
            title: "",
            render: (_, row) => {
                return (
                    <Button
                        shape="circle"
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => handleClickDeleteCampo(row.came_id)}
                        key={`eliminar-came-${row.came_id}`}
                    />
                );
            },
        },
    ];

    return (
        <Elevation className={styles.datosExtras}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Campos extras </Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={handleShowModal} />
                </div>
            </Row>
            <Row>
                <Table dataSource={camposExtra} columns={camposExtrasColumns} style={{ width: "100%" }} />
            </Row>

            <Modal title="Crear dato extra" open={showModal} footer={null} onCancel={closeModal} destroyOnClose={true} width="500px">
                <div className={styles.modalContent}>
                    <FormCampoExtra values={initialFieldsValues} tipoC={1} form={form}/>
                    <Row style={{ justifyContent: "end" }}>
                        <Button type="primary" onClick={handleCrearDatoExtra} loading={isLoadingContactoCamposExtraMutation}>
                            Guardar dato extra
                        </Button>
                    </Row>
                    {/* 
                        solo agregar campos del apartado de generales 
                        y crear vista a parte para agregar y modifica los demas campos de la empresa
                    */}
                </div>
            </Modal>

            <ModalEditarCampoExtra visible={visibleEditar} closeModal={closeModalEditar} >
                <FormCampoExtra  values={editingValue} tipoC={tipoCampo} form={formEditar} />
            </ModalEditarCampoExtra>
        </Elevation>
    );
};

const FormCampoExtra = ({ values, tipoC, form }) => {
    // const [form] = Form.useForm();
    const [tipoCampo, setTipoCampo] = useState(tipoC);
    const [mascara, setMascara] = useState("");

    const handleChangeTipo = (value) => {
        setTipoCampo(value)
    };

    const onChangeMascara = (e) => {
        setMascara(e.target.value);
    }

    const getInputTipo = () => {
        switch (tipoCampo) {
            case 1:
                return (
                    <Select key={`select-input-campo-extra`}>
                        <Select.Option value="Si">Si</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                    </Select>
                );
            case 2:
                return <NumericInput key={`numeric-input-campo-extra`} />;
            case 3:
                return <Input.TextArea showCount rows={2} key={`text-input-campo-extra`} />;
            case 4:
                return <InputMask
                    alwaysShowMask
                    formatChars={{ "9": "[0-9]", "t": "[0-9\\-]", "?": "[0-9 ]", "a": "[a-z]", "A": "[A-Z]" }}
                    mask={mascara}
                >
                    {(inputProps) => <Input {...inputProps} />}
                </InputMask>
            default:
                return null;
        }
    };

    useEffect(() => {
        if (values) {
            form.setFieldsValue(values);
            setMascara(form.getFieldValue("came_mascara"));
        }
    }, [values])

    return (
        <Form layout="vertical" form={form}>
            <Row>
                <Space style={{ flexDirection: "column", width: "100%" }}>
                    <div style={{ width: "100%", display: "flex", gap: 16 }}>
                        <Form.Item
                            name="came_nombre"
                            label="Nombre del campo"
                            rules={[{ required: true, message: "El nombre es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Tipo" name="came_tipo" rules={[{ required: true, message: "El tipo de dato es requerido" }]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={handleChangeTipo}
                            >
                                <Select.Option value={1}>Boleano (Si o No)</Select.Option>
                                <Select.Option value={2}>Númerico</Select.Option>
                                <Select.Option value={3}>Texto Libre</Select.Option>
                                <Select.Option value={4}>Texto con Mascara</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Importante" name="came_importante">
                            <Switch />
                        </Form.Item>
                    </div>
                    {
                        tipoCampo === 4 && values?.came_mascara === "" && 
                        <div style={{ width: "100%", display: "flex", gap: 16 }}>
                            <Form.Item
                                label="Máscara del texto"
                                name="came_mascara"
                                rules={[{ required: true, message: "El valor es requerido" }]}
                            >
                                <Input onChange={onChangeMascara} />
                            </Form.Item>
                        </div>
                    }
                    <div style={{ width: "100%", display: "flex", gap: 16 }}>
                        <Form.Item
                            label="Valor"
                            name="came_valor"
                            rules={[{ required: true, message: "El valor es requerido" }]}
                        >
                            {getInputTipo()}
                        </Form.Item>
                    </div>
                </Space>
            </Row>
        </Form>
    );
}


const ModalEditarCampoExtra = ({ visible, closeModal, children }) => {
    const styles = useStyles();
    return <Modal title="Modificar campo extra" visible={visible} footer={null} onCancel={closeModal} destroyOnClose={true} width="500px">
        <div className={styles.modalContent}>
            {children}
        </div>
    </Modal>;
}


export default ContactoCamposExtras;
