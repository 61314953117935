import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Row, Select, Space, Spin, Tooltip, Typography } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import { DateTime } from "luxon";
import { AiOutlineFilePdf } from "react-icons/ai";
import { EditOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from "../../../hooks/useModal";
import { useApiSistemas } from "../../../hooks/api/sistemas";
import { useApiSistemasVersiones } from "../../../hooks/api/sistemas_versiones";
import useApi from "../../../hooks/api/useApiRequest";
import { useQueryUtils } from "../../../hooks/api/utils";
import { useApiEmpresasLicencias } from "../../../hooks/api/empresas";
import { useTicketUpdateMutation } from "../../../hooks/api/tickets";
import CrearEmpresaLicencia from "../../Empresas/Licencias/CrearEmpresaLicencia";
import { BsBandaid } from "react-icons/bs";
import { BiErrorAlt } from "react-icons/bi";
import KronAlert from "../../../components/CustomAlerts/KronAlert";


const useStyles = createUseStyles(() => ({
    datosGeneralesWrapper: {
        width: "100%",
        padding: "20px 10px",
        borderBottom: "1px solid #eee",
    },
    btn: {
        color: "white",
        fontSize: 0
    }
}));

const CambiarSistemaTicket = ({ ticket, closeParent }) => {
    const [form] = Form.useForm();
    const [sistemaSeleccionado, setSistemaSeleccionado] = useState(null);
    const { mutateTicketUpdate, isLoadingTicketUpdateMutation } = useTicketUpdateMutation();
    const { data: licencias, isLoading: isLoadingLicencias, refetch: refetchLicencias } = useApiEmpresasLicencias({ initialData: [], id: ticket?.empresa?.emp_id })
    const { data: versiones, refetch: refetchVersiones } = useApiSistemasVersiones({
        initialData: [],
    }, sistemaSeleccionado?.sis_id);

    const handleChangeSistema = (sis_id) => {
        console.log(sis_id)
        setSistemaSeleccionado({ sis_id })
        console.log(sistemaSeleccionado)
        refetchVersiones();
        form.setFieldValue("csiv_id", null);
        console.log(versiones)
    }

    const handleGuardarCambioSistema = () => {
        form.validateFields().then(values => {
            // se busca la licencia
            let licencia = licencias.find(licencia => licencia.usli_id === values.usli_id);
            // se envia la solicitud al servidor para el cambio del sistema en el ticket
            mutateTicketUpdate(
                { tick_id: ticket?.tick_id, ...values, sis_id: licencia.sis_id },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            message.success("Ticket actualizado");
                            if (closeParent) closeParent();
                        } else {
                            message.error("Hubo un problema al cambiar el sistema");
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al cambiar el sistema");
                    },
                }
            );
        }).catch(() => message.error("El sistema es requerido"));
    }

    useEffect(() => {
        console.log(sistemaSeleccionado)
        refetchVersiones();
    }, [sistemaSeleccionado])

    return (
        <Spin spinning={isLoadingLicencias}>
            <div>
                <Row>
                    <Form layout="vertical" style={{ width: "100%" }} form={form}>
                        <Form.Item name="usli_id" label="Sistema" rules={[{ required: true, message: "El sistema es requerido" }]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onSelect={handleChangeSistema}
                                filterOption={(input, option) => option.children.join("").toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    licencias?.map(licencia => (
                                        <Select.Option value={licencia?.sistema?.sis_id} key={`${licencia.sistema.sis_id}`}>{licencia.sistema.sis_nombre}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="csiv_id" label="Versión">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (console.log(option), option.children.join("").toLowerCase().includes(input.toLowerCase()))}
                            >
                                {
                                    versiones?.map(ver => (
                                        <Select.Option value={ver.csiv_id} key={`${ver.csiv_version}_${ver.csiv_id}`}>{ver.csiv_version}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Row>
                <Row style={{ justifyContent: "end" }}>
                    <Button type="primary" onClick={handleGuardarCambioSistema}>
                        Guardar
                    </Button>
                </Row>
            </div>
        </Spin>
    );
}

const DatosGenerales = ({ ticket, setUsandoTicket }) => {
    const theme = useTheme();
    const styles = useStyles();
    const [sistema, setSistema] = useState(null);
    const [show, showModal, closeModal] = useModal();
    const [cambiarSistemaVisible, showCambiarSistema, closeCambiarSistema] = useModal();
    const [form] = Form.useForm();
    const { invalidateQuery } = useQueryUtils();
    const apis = useApi();
    const { data: sistemas } = useApiSistemas({
        initialData: []
    });
    const { data: versiones, refetch: refetchVersiones } = useApiSistemasVersiones({
        initialData: [],
    }, sistema);

    const getTipoLicencia = () => {
        switch (ticket?.licencia?.usli_tipo) {
            case "1":
                return "Anual"
            case "2":
                return "Tradicional"
            case "3":
                return "Demo"
            default:
                return "-";
        }
    }

    const handleClickOpenAsociarSistema = () => {
        showModal();
    }

    const handleGuardarAsociacion = () => {
        form.validateFields().then(values => {
            let contacto = ticket?.agentes?.find(age => age?.tiag_id === 2);
            values = {
                ...values,
                emp_id: ticket.emp_id,
                con_id: contacto.usu_id
            }

            apis.put(`/tickets/${ticket.tick_id}/asociar_sistema`, values, { dataType: "json" }).then(res => {
                if (res?.data?.success) {
                    message.success(res.data.mensaje)
                    form.resetFields();
                    invalidateQuery(["tickets", `${ticket.tick_id}`], false)
                    if (setUsandoTicket) {
                        setUsandoTicket()
                    }
                    closeModal();
                } else {
                    message.error("Hubo al asociar el sistema")
                }
            }).catch(() => {
                message.error("Error al asociar el sistema")
            });

        }).catch(() => {
            message.error("Aún faltan campos")
        })
    }

    useEffect(() => {
        refetchVersiones();
    }, [sistema])

    return (
        <div className={styles.datosGeneralesWrapper}>
            <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                <Typography.Title level={5}>Datos generales</Typography.Title>
                <Space>
                    <Button className={styles.btn} type="primary" shape="circle" icon={<AiOutlineFilePdf fontSize={0} />} />
                    <Button className={styles.btn} type="primary" shape="circle" icon={<PaperClipOutlined fontSize={0} />} />
                </Space>
            </Row>
            <Row >
                {
                    ticket?.sistema_version?.csiv_errores &&
                    <KronAlert
                        text="La version tiene errores"
                        type="warning"
                        style={{ width: "100%", marginBottom: 10 }}
                        rightContent={null}
                    />
                }
            </Row>
            <Space size={16} style={{ display: "flex", flexDirection: "column" }}>
                <Row>
                    <div style={{ display: "flex", flex: 1, alignItems: "end" }}>
                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                            <Typography.Text strong style={{ color: "#1890FF" }}>
                                Sistema {" "}
                                <Button shape="circle" size="small" type="link" icon={<EditOutlined />} onClick={showCambiarSistema} />
                            </Typography.Text>
                            <Typography.Text>{ticket?.licencia?.sistema?.sis_nombre || "-"}</Typography.Text>
                        </div>
                        <Tooltip title="Asociar sistema" placement="bottom">
                            <Button type="primary" onClick={handleClickOpenAsociarSistema} style={{ margin: "0 15px" }} size="small">
                                <PlusOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                        <div>
                            <Typography.Text strong style={{ color: "#1890FF" }}>
                                Versión
                            </Typography.Text>
                        </div>
                        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                            <Typography.Text>{ticket?.sistema_version?.csiv_version || "-"}</Typography.Text>
                            {
                                ticket?.sistema_version?.csiv_errores &&
                                <Tooltip title={"La versión tiene errores"} placement="bottomRight">
                                    <BiErrorAlt style={{ fontSize: 16, color: "#F00" }} />
                                </Tooltip>
                            }
                            {
                                ticket?.sistema_version?.csiv_parche &&
                                <Tooltip title={"La versión tiene parche"} placement="bottomRight">
                                    <BsBandaid style={{ fontSize: 14, color: theme.terciaryColor }} />
                                </Tooltip>
                            }
                        </div>
                    </div>
                </Row>
                <Row>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <Typography.Text strong style={{ color: "#1890FF" }}>
                            No. de Serie
                        </Typography.Text>
                        <Typography.Text>{ticket?.licencia?.usli_numeroserie || "-"}</Typography.Text>
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", flex: 0.5 }}>
                        <Typography.Text strong style={{ color: "#1890FF" }}>
                            Tipo
                        </Typography.Text>
                        <Typography.Text>
                            {getTipoLicencia()}
                        </Typography.Text>
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", flex: 0.5 }}>
                        <Typography.Text strong style={{ color: "#1890FF" }}>
                            No. Usuarios
                        </Typography.Text>
                        <Typography.Text>{ticket?.licencia?.usli_numusuarios || ""}</Typography.Text>
                    </div>
                </Row>
                <Row>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <Typography.Text strong style={{ color: "#1890FF" }}>
                            Fecha vencimiento
                        </Typography.Text>
                        <Typography.Text>{ticket?.licencia?.usli_fechacaducidad ? DateTime.fromISO(ticket?.licencia?.usli_fechacaducidad).toFormat("dd/MM/yyyy") : null}</Typography.Text>
                    </div>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Input.TextArea placeholder="Nota" rows={3} />
                </Row>
            </Space>


            <Modal
                title="Cambiar sistema del ticket"
                open={cambiarSistemaVisible}
                footer={null}
                onCancel={closeCambiarSistema}
                destroyOnClose
            >
                <CambiarSistemaTicket ticket={ticket} closeParent={closeCambiarSistema} />
            </Modal>

            <Modal title="Asociar nuevo sistema" open={show} footer={null} onCancel={closeModal} onOk={closeModal}>
                <CrearEmpresaLicencia
                    closeModal={closeModal}
                    empresa={ticket?.empresa}
                    editandoLicencia={null}
                />

                {/* <Row>
                    <Form layout="vertical" style={{ width: "100%" }} form={form}>
                        <Form.Item name="sis_id" label="Sistema" rules={[{ required: true, message: "El sistema es requerido" }]}>
                            <Select onChange={handleChanegSistema}>
                                {
                                    sistemas?.map(sis => (
                                        <Select.Option value={sis.sis_id} key={`${sis.sis_id}`}>{sis.sis_nombre}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="csiv_id" label="Versión" rules={[{ required: true, message: "La versión es requerida" }]}>
                            <Select>
                                {
                                    versiones?.map(ver => (
                                        <Select.Option value={ver.csiv_id} key={`${ver.csiv_version}_${ver.csiv_id}`}>{ver.csiv_version}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Row>
                <Row style={{ justifyContent: "end" }}>
                    <Button type="primary" onClick={handleGuardarAsociacion}>
                        Guardar
                    </Button>
                </Row> */}
            </Modal>

        </div>
    );
};

export default DatosGenerales;
