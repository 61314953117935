import { Button, DatePicker, Form, Input, InputNumber, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useApiSistemas } from "../../../hooks/api/sistemas";
import { objectToFormData } from "../../../helpers/form.helper";
import { useEmpresaLicenciasMutation } from "../../../hooks/api/empresas";
import { useSelector } from "react-redux";
import moment from "moment";
import { createUseStyles } from "react-jss";
import { FaCodeFork } from "react-icons/fa6"
import Sistemas from "../../Sistemas";
import { useModal } from "../../../hooks/useModal";
import { DateTime } from "luxon";
import { SistemaTipoLicenciamiento } from "../../../@enums/Sistemas.enum";
import { useApiSistemasVersiones } from "../../../hooks/api/sistemas_versiones";
import { LicenciaTipoControlEmpresas } from "../../../@enums/Licencias.enum";
import RecursosManager from "../../Recursos/RecursosManager";
import { useQueryClient } from "react-query";
import { DATE_FORMATS } from "../../../helpers/dates.helper";

const useStyles = createUseStyles(() => ({
    form: {
        width: "100%",
        "& .ant-form-item": {
            flex: 1
        }
    }
}));

const CrearEmpresaLicencia = ({ empresa, editandoLicencia, closeModal, onSaveNewLicense }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const { data: sistemas } = useApiSistemas({ initialData: [] })
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [idSistema, setIdSistema] = useState([]);
    const [sistemasVisible, showSistemas, closeSistemas] = useModal();
    const { momentInsertingFormat, luxonInsertingFormat } = useSelector(state => state.app.formats);
    const { mutateEmpresaLicencias, resetEmpresaLicenciasMutation } = useEmpresaLicenciasMutation();
    const {
        data: sistemasVersiones,
        refetch: refetchSistemasVersiones,
        isLoading: isLoadingSistemasVersiones
    } = useApiSistemasVersiones(
        {
            initialData: [],
        },
        idSistema
    );
    const formInitialData = {
        sis_id: null,
        usli_tipo: null,
        usli_numeroserie: null,
        usli_numusuarios: 1,
        usli_fechainicial: null,
        usli_fechacaducidad: null,
        usli_observaciones: null,
        usli_observacionesservidor: null,
        usli_observacionesinfraestructura: null,
        usli_empresaregistrante: null,
        usli_tipocontrolempresas: null,
        csiv_id: null
    }

    const handleChangeFiles = (files) => {
        setSelectedFiles(files)
    }

    const handleClickGuardarLicencia = () => {
        if (editandoLicencia) {
            actualizarLicencia();
        } else {
            crearLicencia();
        }
    }

    const crearLicencia = () => {
        form.validateFields().then((values) => {
            let tSelectedFiles = [...selectedFiles]
            values.usli_fechainicial = values.usli_fechainicial.format(momentInsertingFormat);
            values.usli_fechacaducidad = values.usli_fechacaducidad.format(momentInsertingFormat);

            let fd = objectToFormData(values, true)
            fd.append("emp_id", empresa.emp_id)
            for (let file of tSelectedFiles) {
                fd.append("files[]", file);
            }

            mutateEmpresaLicencias({
                mutationType: "create",
                emp_id: empresa.emp_id,
                data: fd
            }, {
                onSuccess: (res) => {
                    if (res?.success) {
                        resetEmpresaLicenciasMutation();
                        form.setFieldsValue(formInitialData);
                        closeModal();
                        message.success("Se agregó la licencia")
                        if (onSaveNewLicense) onSaveNewLicense();
                    } else {
                        message.error("Hubo un problema al guardar la licencia");
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al guardar la licencia");
                }
            })
        }).catch((err) => {
            console.log(err)
            message.error("Aún hay cxampos faltantes")
        })
    }

    const actualizarLicencia = () => {
        form.validateFields().then((values) => {
            values.usli_fechainicial = values.usli_fechainicial.format(momentInsertingFormat);
            values.usli_fechacaducidad = values.usli_fechacaducidad.format(momentInsertingFormat);
            let usli_id = editandoLicencia.usli_id;
            delete editandoLicencia.sistema;
            delete editandoLicencia.emp_id;

            mutateEmpresaLicencias({
                mutationType: "update",
                emp_id: empresa.emp_id,
                usli_id,
                data: values
            }, {
                onSuccess: (res) => {
                    if (res?.success) {
                        resetEmpresaLicenciasMutation();
                        message.success("Se actualizó la licencia")
                    } else {
                        message.error("Hubo un problema al actualizar la licencia");
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al actualizar la licencia");
                }
            })
        }).catch((err) => {
            console.log(err)
            message.error("Aún hay cxampos faltantes")
        })
    }

    const handleChangeFechaInicial = (fecha) => {
        let tipoLicenciamiento = form.getFieldValue("usli_tipo");
        // se calcula la fecha de vencimiento en automatico para el tipo de licencia anual
        if (tipoLicenciamiento == SistemaTipoLicenciamiento.ANUAL) {
            let strFecha = fecha.format(momentInsertingFormat);
            let newDate = DateTime.fromISO(`${strFecha}T00:00:00`).plus({ years: 1 }).toFormat(luxonInsertingFormat);
            form.setFieldValue("usli_fechacaducidad", moment(newDate))
        }
    }

    const handleChangeTipoLicenciamiento = () => {
        form.setFieldsValue({
            usli_fechainicial: null,
            usli_fechacaducidad: null,
        });
    }

    const handleChangeSistema = (sis_id) => {
        setIdSistema(sis_id);
        form.setFieldValue("csiv_id", null);
        //se busca el sistema seleccionado
        let sistema = sistemas.find(sis => sis.sis_id === sis_id);
        if (sistema && sistema.sis_tipo)
            form.setFieldValue("usli_tipo", sistema.sis_tipo);
    }

    const handleSaveNewLicenciaFiles = () => {
        queryClient.fetchQuery(["empresas", "licencias"]);
    }

    const handleDeleteLicenciaFile = () => {
        queryClient.fetchQuery(["empresas", "licencias"]);
    }

    useEffect(() => {
        if (!editandoLicencia) {
            form.setFieldsValue(formInitialData)
        } else {
            let tLicencia = { ...editandoLicencia };
            setIdSistema(tLicencia.sis_id)
            form.setFieldsValue({
                ...tLicencia,
                usli_fechainicial: tLicencia.usli_fechainicial ? moment(tLicencia.usli_fechainicial, DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR) : null,
                usli_fechacaducidad: tLicencia.usli_fechacaducidad ? moment(tLicencia.usli_fechacaducidad,DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR) : null,
            });
        }
    }, [editandoLicencia])

    return (
        <div >

            <Modal
                title="Catalogo de sistemas"
                open={sistemasVisible}
                footer={null}
                onCancel={closeSistemas}
                onOk={closeSistemas}
                width={"70%"}
            >
                <Sistemas />
            </Modal>
            <Row style={{ borderBottom: "1px solid #eee", paddingBottom: 10, marginBottom: 10, justifyContent: "end" }}>
                <Button shape="round" type="primary" icon={<FaCodeFork style={{ marginRight: 5 }} />} onClick={showSistemas}>Nuevo sistema o version</Button>
            </Row>
            <Row>
                <Form layout='vertical' form={form} className={styles.form} >
                    <Row style={{ gap: 16 }}>
                        <Form.Item name="sis_id" label="Sistema" rules={[{ required: true, message: "El sistema es requerido" }]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                onChange={handleChangeSistema}
                            >
                                {
                                    sistemas.map((sis, iSis) => <Select.Option value={sis.sis_id} key={`sistema_${iSis}`}>{sis.sis_nombre}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="usli_tipo" label="Tipo" rules={[{ required: true, message: "El tipo es requerido" }]}>
                            <Select
                                onChange={handleChangeTipoLicenciamiento}
                            >
                                <Select.Option value={SistemaTipoLicenciamiento.ANUAL} >Anual</Select.Option>
                                <Select.Option value={SistemaTipoLicenciamiento.TRADICIONAL} >Tradicional</Select.Option>
                                <Select.Option value={SistemaTipoLicenciamiento.DEMO} >Demo</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="usli_tipocontrolempresas" label="Control empresas">
                            <Select>
                                <Select.Option value={LicenciaTipoControlEmpresas.MONO_EMPRESA} >Mono Empresa</Select.Option>
                                <Select.Option value={LicenciaTipoControlEmpresas.MULTI_EMPRESA} >Multi empresa</Select.Option>
                            </Select>
                        </Form.Item>
                    </Row>
                    <Row style={{ gap: 16 }}>
                        <Form.Item name="usli_numeroserie" label="No. de serie" rules={[{ required: true, message: "El no. de serie es requerido" }]} style={{ flex: 1 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="usli_numusuarios" label="No. de usuarios" rules={[{ required: true, message: "El no. de usuarios es requerido" }]} style={{ flex: 0.5 }}>
                            <InputNumber min={1} defaultValue={1} />
                        </Form.Item>
                        <Form.Item name="csiv_id" label="Versión del sistema" style={{ flex: 0.5 }}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                loading={isLoadingSistemasVersiones}
                            >
                                {
                                    sistemasVersiones.map(csiv => <Select.Option value={csiv.csiv_id} key={`sistemaVersion_${csiv.csiv_id}`}>{csiv.csiv_version}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Row>
                    <Row style={{ gap: 16 }}>
                        <Form.Item name="usli_fechainicial" label="Fecha de compra" rules={[{ required: true, message: "La fecha de compra es requerida" }]}>
                            <DatePicker style={{ width: "100%" }} placeholder="" onChange={handleChangeFechaInicial} format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} />
                        </Form.Item>
                        <Form.Item name="usli_fechacaducidad" label="Fecha de vencimiento" rules={[{ required: true, message: "La fecha de vencimiento es requerida" }]}>
                            <DatePicker style={{ width: "100%" }} placeholder="" format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item name="usli_empresaregistrante" label="Empresa registrante" style={{ flex: 1 }}>
                            <Input />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item name="usli_observaciones" label="Observaciones">
                            <Input.TextArea style={{ width: "100%" }} showCount />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item name="usli_observacionesservidor" label="Observaciones del servidor">
                            <Input.TextArea style={{ width: "100%" }} showCount />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item name="usli_observacionesinfraestructura" label="Observaciones de la infraestructura">
                            <Input.TextArea style={{ width: "100%" }} showCount />
                        </Form.Item>
                    </Row>
                    {/* <Row>
                        <Typography.Title level={5}>Archivos</Typography.Title>
                    </Row> */}
                </Form>
                <div style={{ width: "100%" }}>
                    <Row>
                        <RecursosManager
                            catalogoFieldId="usli_id"
                            catalogoId={editandoLicencia?.usli_id}
                            recursos={editandoLicencia?.recursos || []}
                            allowDeleteFile
                            allowAddFiles
                            onChangeFiles={handleChangeFiles}
                            limit={1}
                            allowedExtensions={["pdf", "jpeg", "jpg", "png"]}
                            useApi={!!editandoLicencia}
                            onSaveResources={handleSaveNewLicenciaFiles}
                            onDeleteFile={handleDeleteLicenciaFile}
                        />
                    </Row>
                    <Row style={{ justifyContent: "end", marginTop: 15 }}>
                        <Button type="primary" onClick={handleClickGuardarLicencia}>Guardar licencia</Button>
                    </Row>
                </div>
            </Row>
        </div>
    );
}

export default CrearEmpresaLicencia;