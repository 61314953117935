import { useMutation, useQuery,  } from "react-query";
import useApi, { AvailableRequestContentTypes } from "./useApiRequest";

/**
 * 
 * @param {import("react-query").UseQueryOptions} options - react-query options 
 * @param {Number} id - id of the user
 * @returns 
 */
export const useApiUserChats = (options, id) => {
    const api = useApi();
    return useQuery(
        ["user-chats", id],
        async () => {
            const { data } = await api.get(`/chats/user`);
            return data;
        },
        options
    );
};

/**
 * 
 * @typedef NewChatDto
 * @property {Number[]} user_list - list of users to add to the chat   
 * @property {Number} chat_type - type of chat to create [use enum: {@link EConversationType}]
 */


export const useCreateChat = () => {
    const api = useApi();

    /**
     * 
     * @param {NewChatDto} chatParams - params to create a new chat
     */
    const addResource = async (chatParams) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/chats`, chatParams, { dataType: AvailableRequestContentTypes.JSON, }).then((res) => {
                    if (res?.status === 201) {
                        resolve(res?.data);
                    } else if (res?.response?.status === 400) {
                        reject(res?.response.data);
                    }else if (res?.response?.status === 409) {
                        resolve(res?.response.data);
                    }
                }).catch((error) => {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    };

    const mutation = useMutation(addResource);

    return {
        createChat: mutation.mutate,
        isCreatingChat: mutation.isLoading,
        isChatCreated: mutation.isSuccess,
        isCreatingChatError: mutation.isError,
    };
};