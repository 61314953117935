import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Row, Col } from "antd";
import { EstatusUsuarioActividades } from "../../../../@enums/UsuarioActividades.enum";
import './styles.css'


const Calendar = ({eventsData, setEventsData, getContact, corriendo, isEdit}) => {

    const handleEventClick = (info) => {
      if(corriendo || !isEdit){
        info.jsEvent.preventDefault(); 
      }else{
        getContact(+info.event.id);
      }
    };

    const handleEventDrop = (info) => {
        const updatedEvents = eventsData.map((event) => {
          if (event.id === info.event.id) {
            // Actualiza la fecha de inicio y finalización del evento
            return {
              ...event,
              start: info.event.start.toISOString(),
              end: info.event.end.toISOString(),
            };
          }
          return event;
        });
    
        // Actualiza el estado con los eventos modificados
        setEventsData(updatedEvents);
    };

    const eventContent = (arg) => {
      const textColor = getColorForStatus(arg.event.extendedProps.status);
  
      return (
        <Row style={{backgroundColor:textColor, paddingLeft:5, borderRadius:5}}>
          <Col span={24} style={{marginBottom:-5}}>
            <span style={{ color: "white", width:'100%', fontWeight:'bold' }}>{arg.timeText}</span>
          </Col>
          <Col span={24}>
            <span style={{width:'100%', color: 'white', fontWeight:'bold'}}>{arg.event.title}</span>
          </Col>
        </Row>
      );
    };

    function getColorForStatus(status) {
      switch (status) {
        case EstatusUsuarioActividades.EN_ESPERA:
          return '#007e01'; // Color rojo para eventos pendientes
        case EstatusUsuarioActividades.EN_PROCESO:
          return '#f2a339'; // Color verde para eventos completados
        case EstatusUsuarioActividades.TERMINADO:
          return '#1876d3'; // Color predeterminado para otros estados
        default:
          return '#d31818'; // Color predeterminado para otros estados
      }
    }


    return (
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={eventsData}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            editable={false}
            eventResizableFromStart={true}
            eventDurationEditable={true}
            eventContent={eventContent}
        />
    )
}

export default Calendar;