import { Alert, Button, Checkbox, Divider, Form, Input, Radio, Row, Select, Switch, Tag, Typography, message } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { TicketContext } from "../ticket.context";
import { getRandomTagColor } from "../../../helpers/colors/Tags.colors";
import { CloseOutlined } from "@ant-design/icons";
import { useCreateNewKnowBaseCategory, useKnowBaseCategories, useKnowBaseItem } from "../../../hooks/api/knowBase";
import { createUseStyles } from "react-jss";
import TicketsKnowBaseTab from "./TicketsKnowBaseTab";

const useStyles = createUseStyles({
    categories: {
        "& .ant-select-selection-overflow": {
            padding: "5px 0",
            gap: 8
        }
    },
    category: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        borderRadius: 15,
        padding: "1px 8px"
    }
});

const CierreTicketParams = ({ formRegistroBaseConocimiento: form, closeParent, cerrarTicket }) => {
    const styles = useStyles();
    const ticketContext = useContext(TicketContext);
    const [tipoCierre, setTipoCierre] = useState(1);
    const { data: KnowBaseCategories, refetch: refetchCategories } = useKnowBaseCategories({ initialData: [] });
    const [categories, setCategories] = useState([]);
    const [knowBaseItemSelected, setKnowBaseItemSelected] = useState(null);
    const { data: knowBaseItem, refetch: refetchKnowBaseItem } = useKnowBaseItem({
        initialData: null,
        enabled: !!knowBaseItemSelected?.kbit_id
    }, knowBaseItemSelected?.kbit_id || null);
    const { createKnowBaseCategory } = useCreateNewKnowBaseCategory();

    const getOptionColor = (value) => {
        const category = categories.find(category => category.value === value);
        return category?.color || getRandomTagColor();
    }

    const handleClickAceptarCierreTicket = () => {
        if (tipoCierre === 1) {
            // se cierra el ticket sin documentar
            cerrarTicket();
        } else if (tipoCierre === 2) {
            if (!knowBaseItem) {
                // se cierra el ticket con un nuevo registro de la base de conocimiento
                form.validateFields()
                    .then(() => {
                        cerrarTicket();
                    })
                    .catch(error => {
                        message.error("Error al validar los campos");
                    });
            } else {
                // se cierra el ticket con un registro existente de la base de conocimiento
                cerrarTicket(knowBaseItem.kbit_id);
            }
        }
    }

    const handleChangeCategory = (selectedCategories) => {
        let existingCategories = selectedCategories.filter(cat => typeof cat === "number");
        let newCategories = selectedCategories.filter(cat => typeof cat === "string");
        // se filtran las categorias que no se han creado en la base de conocimiento
        for (let newCategory of newCategories) {
            // se crean las nuevas categorias en la base de conocimiento
            createKnowBaseCategory({
                kbca_nombre: newCategory
            }, {
                onSuccess: (response) => {
                    const newCategory = {
                        value: response.data.kbca_id,
                        label: response.data.kbca_nombre,
                        color: getRandomTagColor()
                    }
                    setCategories([...categories, newCategory]);
                    message.success("Categoría creada");
                    form.setFieldValue("categorias", [...existingCategories, newCategory.value]);

                },
                onError: (error) => {
                    console.log(error);
                    message.error("Error al crear la categoría");
                }
            })
        }
    }

    const handleChangeTipoCierre = (e) => {
        setTipoCierre(e.target.value);
    }

    const handleBlurRecordNumber = (e) => {
        setKnowBaseItemSelected({ kbit_id: e.target.value });
    }

    useEffect(() => {
        if (KnowBaseCategories && KnowBaseCategories.length > 0) {
            const categories = KnowBaseCategories.map(category => {
                return {
                    value: category.kbca_id,
                    label: category.kbca_nombre,
                    color: getRandomTagColor()
                }
            });
            setCategories(categories);
        }
    }, [KnowBaseCategories]);

    useEffect(() => {
        if (ticketContext.ticket) {
            form.setFieldsValue({
                kbit_sintomas: ticketContext.ticket?.tick_sintomas
            });
        }
    }, [ticketContext.ticket]);

    useEffect(() => {
        if (knowBaseItem) {
            // se selecciona el item
            setKnowBaseItemSelected(knowBaseItem);
            // se llenan los campos del formulario
            form.setFieldsValue({
                kbit_titulo: knowBaseItem.kbit_titulo,
                kbit_sintomas: knowBaseItem.kbit_sintomas,
                kbit_problematica: knowBaseItem.kbit_problematica,
                kbit_solucion: knowBaseItem.kbit_solucion,
                categorias: knowBaseItem.categories.map(category => category.kbca_id)
            });
        }
        else {
            // se limpia el formulario
            form.setFieldsValue({
                kbit_titulo: null,
                kbit_sintomas: ticketContext.ticket?.tick_sintomas,
                kbit_problematica: null,
                kbit_solucion: null,
                categorias: []
            });
        }
    }, [knowBaseItem]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
        }}>
            <Row>
                <Alert style={{ fontSize: 14, width: "100%", margin: 0 }} showIcon message={"El ticket se está cerrando. Confirma el cierre para proceder."} />
            </Row>
            <Row style={{ userSelect: "none" }}>
                <Radio.Group
                    onChange={handleChangeTipoCierre}
                    value={tipoCierre}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Radio value={1}>Solo cerrar el ticket</Radio>
                    {/* <Radio value={1}>No documentar base de conocimiento con este ticket</Radio> */}
                    <Radio value={2}>Cerrar ticket y documentar con un registro de la base de conocimiento</Radio>
                    {/* <Radio value={3}>Cerrar ticket y documentar con un registro existente de la base de conocimiento</Radio> */}
                </Radio.Group>
            </Row>
            {
                tipoCierre === 2 &&
                (
                    <>
                        <Divider orientation="left" dashed style={{ margin: "10px 0 5px 0" }}>Datos del registro</Divider>
                        <Row>
                            <Form
                                layout="vertical"
                                form={form}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <Form.Item
                                    label="Folio"
                                    name="kbit_id"
                                >
                                    <Input onBlurCapture={handleBlurRecordNumber} />
                                </Form.Item>
                                <Form.Item
                                    label="Título"
                                    name="kbit_titulo"
                                // rules={[{
                                //     required: true,
                                //     message: "El titulo es requerido"
                                // }]}
                                >
                                    <Input disabled={!!knowBaseItem} />
                                </Form.Item>
                                <Form.Item
                                    label="Síntomas"
                                    name="kbit_sintomas"
                                    rules={[{
                                        required: true,
                                        message: "El sintoma es requerido"
                                    }]}
                                >
                                    <Input.TextArea disabled={!!knowBaseItem} />
                                </Form.Item>
                                <Form.Item
                                    label="Problemática"
                                    name="kbit_problematica"
                                    rules={[{
                                        required: true,
                                        message: "La problemática es requerida"
                                    }]}
                                >
                                    <Input.TextArea disabled={!!knowBaseItem} />
                                </Form.Item>
                                <Form.Item
                                    label="Solución"
                                    name="kbit_solucion"
                                    rules={[{
                                        required: true,
                                        message: "La solución es requerida"
                                    }]}
                                >
                                    <Input.TextArea disabled={!!knowBaseItem} />
                                </Form.Item>
                                <Form.Item
                                    label="Categorías"
                                    name="categorias"
                                >
                                    <Select
                                        disabled={!!knowBaseItem}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.label.toLowerCase().includes(input)}
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        className={styles.categories}
                                        onChange={handleChangeCategory}
                                        options={categories}
                                        tagRender={({ label, value, closable, onClose }) => (
                                            <Tag color={getOptionColor(value)} className={styles.category}>
                                                <span>{label}</span>
                                                {closable && <CloseOutlined style={{ marginLeft: 3 }} onClick={onClose} />}
                                            </Tag>
                                        )}
                                    />
                                </Form.Item>
                            </Form>
                        </Row>
                    </>
                )
            }
            {/* {
                tipoCierre === 3 &&
                (
                    <>
                        <Divider orientation="left" dashed style={{ margin: "10px 0 5px 0" }}>Selección del registro</Divider>
                        <Row>
                            <TicketsKnowBaseTab onSelectKnowBaseItem={handleSelectKnowBaseItem} />
                        </Row>
                    </>
                )
            } */}
            <Row style={{
                gap: 7,
                justifyContent: "end",
                marginTop: 10
            }}>
                <Button type="primary" size="small"
                    onClick={handleClickAceptarCierreTicket}
                >Confirmar</Button>
                <Button
                    size="small"
                    onClick={closeParent}
                >Cancelar</Button>
            </Row>
        </div>
    );
}

export default CierreTicketParams;