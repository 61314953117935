import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi from "./useApiRequest";
import { SistemaDto } from "../../views/Sistemas/dto";

export const useSistemaMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    /**
     * 
     * @param {FormData} sistema 
     */
    const createSistema = async (sistema) => {
        const { data } = await api.post(`/sistemas`, sistema, { dataType: "multipart", hasFormData: true, hasCustomUrl: false });
        return data;
    };

    const {
        mutate: mutateSistema,
        isLoading: isLoadingSistemaMutation,
        error: errorSistemaMutation,
        reset: resetSistemaMutation,
    } = useMutation(createSistema, {
        onSuccess: () => {
            queryClient.invalidateQueries("sistemas");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateSistema,
        isLoadingSistemaMutation,
        errorSistemaMutation,
        resetSistemaMutation,
    };
};

export const useSistemaUpdateMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const updateSistema = async (sistema) => {
        const { data: tData } = await api.put(`/sistemas/${sistema.sis_id}`, sistema, { dataType: "json" });
        return tData;
    };

    const {
        mutate: mutateSistemaUpdate,
        isLoading: isLoadingSistemaUpdateMutation,
        error: errorSistemaUpdateMutation,
        reset: resetSistemaUpdateMutation,
    } = useMutation(updateSistema, {
        onSuccess: () => {
            queryClient.invalidateQueries("sistemas");
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateSistemaUpdate,
        isLoadingSistemaUpdateMutation,
        errorSistemaUpdateMutation,
        resetSistemaUpdateMutation,
    };
};

export const useApiSistemas = (options) => {
    const api = useApi();
    return useQuery(
        ["sistemas"],
        async () => {
            const { data } = await api.get(`/sistemas`);
            return data;
        },
        options
    );
};
