import React from 'react';
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
 

function PrivateRoute({ children, ...rest }) {
    const auth = useSelector((state) => state.auth);
    return (
        <Route
            {...rest}
            render={() =>
                auth.token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            // state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
