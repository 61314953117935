export const tagColors = [
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
    "magenta",
    "pink",
    "yellow"
];


/**
 * Function for get a random color from tagColors
 * @returns {string} A random color from tagColors
 */
export const getRandomTagColor = () => {
    return tagColors[Math.floor(Math.random() * tagColors.length)];
}
