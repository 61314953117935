import { Button, Descriptions, Row, Table, Tooltip, Typography } from "antd";
import { FaCheck } from "react-icons/fa6";
import { useTheme } from "react-jss";

const VisorConstanciaFiscal = ({ constanciaInfo, onSaveDatosConstancia }) => {
    const theme = useTheme();


    const handleSaveDatosConstancia = () => {
        if (onSaveDatosConstancia) onSaveDatosConstancia(constanciaInfo);
    }

    return (
        <div>
            <Row style={{ background: theme.primaryColor, padding: "20px 20px 50px 20px", justifyContent: "space-between", alignItems: "center" }}>
                <Typography.Title level={2} style={{ color: "#FFF" }}>Datos de la constancia fiscal</Typography.Title>
                <Tooltip title="Guardar datos de constancia" placement="left">
                    <Button
                        shape="round"
                        size="large"
                        type="secondary"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        icon={<FaCheck style={{ marginRight: 5 }} />}
                        onClick={handleSaveDatosConstancia}
                    >Aplicar datos</Button>
                </Tooltip>
            </Row>
            <div style={{
                margin: "-40px 15px 10px 15px",
                padding: "20px 20px 40px 20px",
                background: "#FFF",
                borderRadius: 20,
                display: "flex",
                flexDirection: "column",
                gap: 10
            }}
            >
                <Row style={{ marginBottom: 10 }}>
                    <Descriptions title="Datos generales de la empresa" column={2} bordered style={{ width: "100%" }}>
                        <Descriptions.Item label="idCIF">{constanciaInfo.id_cif}</Descriptions.Item>
                        <Descriptions.Item label="RFC">{constanciaInfo.rfc}</Descriptions.Item>
                        <Descriptions.Item label="Razón social" span={2}>{constanciaInfo.razon_social}</Descriptions.Item>
                        <Descriptions.Item label="Nombre comercial" span={2}>{constanciaInfo.nombre_comercial}</Descriptions.Item>
                        <Descriptions.Item label="Régimen capital" span={2}>{constanciaInfo.regimen_capital}</Descriptions.Item>
                        <Descriptions.Item label="Correo electrónico" span={2}>{constanciaInfo.domicilio.correo}</Descriptions.Item>
                        <Descriptions.Item label="Telefono" span={2}>{constanciaInfo.domicilio.telefono}</Descriptions.Item>
                        <Descriptions.Item label="Teléfono móvil" span={2}>{constanciaInfo.domicilio.telefono_movil}</Descriptions.Item>
                    </Descriptions>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Descriptions title="Domicilio" column={4} bordered style={{ width: "100%" }}>
                        <Descriptions.Item label="Calle" span={2}>{constanciaInfo.domicilio.calle}</Descriptions.Item>
                        <Descriptions.Item label="Exteror" span={1}>{constanciaInfo.domicilio.exterior}</Descriptions.Item>
                        <Descriptions.Item label="Interior" span={1}>{constanciaInfo.domicilio.interior}</Descriptions.Item>
                        <Descriptions.Item label="Colonia" span={2}>{constanciaInfo.domicilio.colonia}</Descriptions.Item>
                        <Descriptions.Item label="Localidad" span={2}>{constanciaInfo.domicilio.localidad}</Descriptions.Item>
                        <Descriptions.Item label="Municipio" span={2}>{constanciaInfo.domicilio.municipio}</Descriptions.Item>
                        <Descriptions.Item label="Estado" span={2}>{constanciaInfo.domicilio.estado}</Descriptions.Item>
                        <Descriptions.Item label="Código postal" span={2}>{constanciaInfo.domicilio.codigo_postal}</Descriptions.Item>
                    </Descriptions>
                </Row>
                <Row>
                    <Typography.Title level={5} style={{ fontWeight: 700 }}>Actividades económicas</Typography.Title>
                </Row>
                <Row>
                    <Table
                        style={{ width: "100%" }}
                        dataSource={constanciaInfo.actividades_economicas}
                        pagination={false}
                        bordered
                        columns={[
                            { dataIndex: "nombre", title: "Actividad" },
                            { dataIndex: "porcentaje", title: "Porcentaje", width: 100, align: "center" }
                        ]}
                    />
                </Row>
                <Row>
                    <Typography.Title level={5} style={{ fontWeight: 700 }}>Regímenes</Typography.Title>
                </Row>
                <Row>
                    <Table
                        style={{ width: "100%" }}
                        dataSource={constanciaInfo.regimenes_fiscales}
                        pagination={false}
                        bordered
                        columns={[
                            { dataIndex: "nombre", title: "Actividad" },
                            { dataIndex: "clave", title: "Clave SAT", width: 100, align: "center" }
                        ]}
                    />
                </Row>
            </div>
        </div>
    );
}

export default VisorConstanciaFiscal;