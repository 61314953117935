import { Button, Col, Modal, Popconfirm, Row, Spin, message } from "antd";
import React, { useState } from "react";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useApiAcuerdosServicio, useDeleteAcuerdoServicio } from "../../hooks/api/acuerdos_servicio";
import DataTable from "../../components/DataTable";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from "../../hooks/useModal";
import CrearAcuerdoServicio from "./CrearAcuerdoServicio";
import { AcuerdoServicioDto } from "../../@types/AcuerdosServicio.types";
import ActualizarAcuerdoServicio from "./ActualizarAcuerdoServicio";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import { createUseStyles } from "react-jss";


const useStyles = createUseStyles({
    dataTable: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
});

const AcuerdosServicio = () => {
    const styles = useStyles();
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [modalVisible, showModal, closeModal] = useModal();
    const [editarVisible, showEditar, closeEditar] = useModal();
    const [search, setSearch] = useState(false)
    const { isLoading: isLoadingAcuerdos, data: acuerdos, refetch: refetchAcuerdos } = useApiAcuerdosServicio({
        initialData: [],
    });
    /** @type {[AcuerdoServicioDto, React.Dispatch<React.SetStateAction<AcuerdoServicioDto>>]} */
    const [acuerdoSeleccionado, setAcuerdoSeleccionado] = useState(null);
    const { deleteAcuerdoServicio, isDeletingAcuerdoServicio } = useDeleteAcuerdoServicio();

    const eliminarAcuerdo = (acuerdo) => {
        deleteAcuerdoServicio(acuerdo, {
            onSuccess: res => {
                if (res.success) {
                    message.success(res.mensaje);
                    refetchAcuerdos();
                } else {
                    message.error(res.mensaje);
                }
            },
            onError: () => {
                message.error("Hubo un problema al eliminar el acuerdo de servicio");
            }
        })
    }

    const accionesTabla = (_, acuerdo) => {
        return (
            <div
                style={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
                <Popconfirm
                    title="¿Deseas continuar con la eliminación del acuerdo de servicio?"
                    onConfirm={() => eliminarAcuerdo(acuerdo)}
                    onCancel={null}
                    okText="Si"
                    cancelText="No"
                    placement="left"
                >
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={e => e.stopPropagation()} />
                </Popconfirm>
            </div >
        );
    }

    const handleAcuerdoRowClick = (acuerdo) => {
        setAcuerdoSeleccionado(acuerdo);
        showEditar();
    }

    const columns = [
        { index: "cacu_id", title: "ID", width: 70 },
        { index: "cacu_nombre", title: "Nombre", width: 200 },
        { index: "cacu_minutos", title: "Minutos", width: 200 },
        { index: "cacu_descripcion", title: "Descripcion", width: 250 },
        { title: "Acciones", render: accionesTabla }
    ];

    return (
        <PageWrapper title="Acuerdos de servicio">
            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando acuerdos de servicio ..." spinning={isLoadingAcuerdos}>
                            <DataTableHeader
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: showModal
                                    }
                                ]}
                                dataToFilter={acuerdos}
                                setFilteredData={setFilteredRecords}
                                counter={search ? filteredRecords?.length : acuerdos?.length}
                                setSearch={setSearch}
                            />
                            <DataTable
                                className={styles.dataTable}
                                columns={columns}
                                data={search ? filteredRecords : acuerdos}
                                onRowClick={handleAcuerdoRowClick}
                                paginationParams={{
                                    defaultPageSize: 10,
                                    total: acuerdos.length,
                                    showSizeChanger: true,
                                    position: ["topRight"],
                                }}
                            />
                        </Spin>
                    </Elevation>
                </Col>
            </Row>

            <Modal
                open={modalVisible}
                title="Agregar acuerdo de servicio"
                onCancel={closeModal}
                footer={null}
                destroyOnClose
            >
                <CrearAcuerdoServicio onCancel={closeModal} />
            </Modal>

            <Modal
                open={editarVisible}
                title="Editar acuerdo de servicio"
                onCancel={closeEditar}
                footer={null}
                destroyOnClose
            >
                <ActualizarAcuerdoServicio closeAcuerdoServicio={closeEditar} acuerdoServicio={acuerdoSeleccionado} />
            </Modal>


        </PageWrapper>
    );
};

export default AcuerdosServicio;
