import { Button, Checkbox, Form, Input, Row, Select, Spin, message } from "antd";
import { useApiUsuarios } from "../../../hooks/api/usuarios";
import { useContext, useEffect, useState } from "react";
import { useReasignarTicketMutation } from "../../../hooks/api/tickets";
import { TicketReasignationDto } from "../../../@types/Tickets.types";
import { TicketPrioridades } from "../../../@enums/Tickets.enums";
import { useApiAcuerdosServicio } from "../../../hooks/api/acuerdos_servicio";
import { TicketContext } from "../ticket.context";
import { useHistory } from "react-router-dom";

const ReasignarTicket = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const ticketContext = useContext(TicketContext);
    const { data: usuarios, isLoading: isLoadingUsuarios } = useApiUsuarios();
    const { mutateReasignarTicket, isLoadingReasignarTicketMutation, resetReasignarTicketMutation } = useReasignarTicketMutation();
    const { data: acuerdos } = useApiAcuerdosServicio({ initialData: [] });
    const [observer, setObserver] = useState(false)

    const reasignarTicket = () => {
        // se valida el formulario
        form.validateFields().then((values) => {
            // se preparan los datos de reasignacion
            let data = new TicketReasignationDto();
            data.tick_id = ticketContext.ticket.tick_id;
            data.usu_asignadoid = values.usuario;
            data.motivo = values.motivo;
            data.tick_prioridad = values.tick_prioridad;
            data.cacu_id = values.cacu_id;
            data.set_as_observer = observer;

            // se manda la solicitud de reasignacion
            mutateReasignarTicket(data, {
                onSuccess: res => {
                    if (res.success) {
                        resetReasignarTicketMutation();
                        message.success("Ticket reasignado");
                        ticketContext.setInitialSecondsTime(0);
                        ticketContext.refetchTicket();
                        ticketContext.closeReasignarTicket();
                        history.push("/tickets")
                    } else {
                        message.error("Ocurrio un error al reasignar el ticket");
                    }
                },
                onError: () => {
                    message.error("Ocurrio un error al reasignar el ticket");
                }
            })
        }).catch(() => message.error("Faltan campos obligatorios"))
    }


    useEffect(() => {
        // se selecciona el ultimo usuario responsable
        if (ticketContext.ticket) {
            // se busca el agente responsable
            const responsable = ticketContext.ticket.agentes.find((agente) => agente.tiag_id === 3 && agente.tica_usumodificaticket);
            form.setFieldsValue({
                usuario: responsable?.usu_id,
                tick_prioridad: ticketContext.ticket?.tick_prioridad,
                cacu_id: ticketContext.ticket?.cacu_id
            })
        }
    }, [ticketContext.ticket, form])

    return (
        <div>
            <Spin spinning={isLoadingUsuarios || isLoadingReasignarTicketMutation} tip="Cargando ..." >
                <Form form={form} layout="vertical">
                    <Form.Item name="usuario" label="Nuevo usuario responsable" rules={[{ required: true, message: "El usuario es requerido" }]}>
                        <Select>
                            {
                                usuarios.map((usuario) =>
                                    <Select.Option key={`${usuario.usu_id}`} value={usuario.usu_id}>
                                        {usuario.usu_nombres} {usuario.usu_apaterno} {usuario.usu_amaterno}
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="motivo" label="Motivo" rules={[{ required: true, message: "El motivo es requerido" }]}>
                        <Input.TextArea showCount maxLength={255} />
                    </Form.Item>
                    <Form.Item name="tick_prioridad" label="Prioridad del ticket" rules={[{ required: true, message: "La prioridad es requerida" }]}>
                        <Select style={{ flex: 1 }} >
                            {
                                Object.keys(TicketPrioridades).map(prioridad => <Select.Option value={TicketPrioridades[prioridad]} key={`prioridad_${prioridad}`}>{prioridad}</Select.Option>)

                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="cacu_id" label="Acuerdo de servicio" rules={[{ required: true, message: "El acuerdo es requerido" }]}>
                        <Select style={{ flex: 1 }} >
                            {
                                acuerdos?.map(acuerdo => <Select.Option value={acuerdo.cacu_id} key={`acuerdo_${acuerdo.cacu_id}`}>{acuerdo.cacu_nombre}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="set_as_observer">
                        <Checkbox onChange={()=> setObserver(!observer)}>Agregarme como observador</Checkbox>
                    </Form.Item>
                </Form>
                <Row style={{ justifyContent: "end", gap: 10 }}>
                    <Button type="secondary" onClick={ticketContext.closeReasignarTicket} >Cancelar</Button>
                    <Button type="primary" onClick={reasignarTicket}>Reasignar</Button>
                </Row>
            </Spin>
        </div>
    );
}

export default ReasignarTicket;