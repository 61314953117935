import React, {useEffect, useState} from "react";
import { Row, Col, Form } from "antd";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Tabla from "./components/Tabla/Tabla";
import NewProductoServicio from "./components/NewProductoServicio/newProductoServicio";
import { GetProductosServicios } from "../../hooks/api/productos-servicios";
import './styles.css';

const ProductosServicios = () => {

    const {getProductosServiciosMutation} = GetProductosServicios();

    const [openNew, setOpenNew] = useState(false)
    const [dataSource, setDataSource] = useState(null)
    const [dataFiltered, setDataFiltered] = useState(dataSource)
    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        getProductosServiciosMutation({}, {
            onSuccess: (data) => {
                console.log(data)
                setDataSource(data);
                setDataFiltered(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    return(
        <div className="container" >
            <PageWrapper title="Productos y Servicios">
                <Row style={{padding:'10px 0px'}}>
                    <Col span={24}>
                        <Tabla  key={1} openNew={openNew} setOpenNew={setOpenNew} dataSource={dataSource} 
                                dataFiltered={dataFiltered} setDataFiltered={setDataFiltered}
                                form={form} setIsEdit={setIsEdit} updateTable={getData}/>
                    </Col>
                </Row>
            </PageWrapper>
            <NewProductoServicio    key={2} openNew={openNew} setOpenNew={setOpenNew} 
                                    form={form} updateTable={getData}
                                    isEdit={isEdit} setIsEdit={setIsEdit}/>
        </div>
    )
}

export default ProductosServicios