import { Button, Modal, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
    container: {
        // marginTop: -30,
        flexDirection: "column",
        gap: 10
    }
}));


const ConfirmationModal = ({ loading, icon, title, description, visible, close, onOk, onCancel }) => {
    const styles = useStyles();

    const handleOk = () => {
        if (onOk) onOk();
    }

    const handleCancel = () => {
        if (onCancel) onCancel();
    }

    return (
        <Modal
            centered
            open={visible}
            footer={null}
            onCancel={close}
            width={600}
            destroyOnClose={true}
            closable={false}
        >
            <Row className={styles.container}>
                {
                    !title || title === "" ? null :
                        <div style={{ display: "flex", gap: 10 }}>
                            {
                                icon ? icon : null
                            }
                            <Typography.Title level={4} style={{ margin: 0 }}>{title}</Typography.Title>
                        </div>
                }
                {
                    !description || description === "" ? null :
                        <div style={{ fontWeight: 400 }}>
                            <Typography.Text >{description}</Typography.Text>
                        </div>
                }
                <div style={{ display: "flex", gap: 10, marginTop: 5, justifyContent: "end" }}>
                    <Button type="secondary" shape="round" onClick={handleCancel}>Cancelar</Button>
                    <Button type="primary" shape="round" onClick={handleOk} loading={!!loading}>Aceptar</Button>
                </div>
            </Row>
        </Modal>
    );
}

export default ConfirmationModal;   