import { CameraOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, DatePicker, Descriptions, Form, Input, Rate, Select, Tooltip, Typography, message } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import { useProfilePhotoMutation, useUsuariosMutation } from "../../../../hooks/api/usuarios";
import { useQueryClient } from "react-query";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { DATE_FORMATS } from "../../../../helpers/dates.helper";
import { IMAGES } from "../../../../../images";
import { useApiPuestos } from "../../../../hooks/api/puestos";
import moment from "moment";
import { clearNullProperties } from "../../../../helpers/form.helper";


const useStyles = createUseStyles(theme => ({
    profileCard: {
        width: "100%",
        // maxWidth: 320,
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "center",
        height: "auto",
        "& .profile-card-avatar": {
            width: "170px",
            height: "170px",
            "& .ant-avatar": {
                width: "100% !important",
                height: "100% !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "13px solid #f8f8f8",
                transition: "all 0.2s ease-in-out",
                "& .profile-card-avatar-overlay": {
                    transition: "all 0.2s ease-in-out",
                    color: theme.primaryColor,
                    background: "#FFFFFFBB",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                },
                "&:hover": {
                    transform: "scale(1.05)",
                    "& .profile-card-avatar-overlay": {
                        opacity: 1
                    }
                }
            },
            "&:hover": {
                cursor: "pointer"
            }
        },
        "& .profile-card-body": {
            padding: "15px 15px 15px 15px",
            marginTop: -60,
            minHeight: 150,
            width: "100%",
            background: "#FFF",
            textAlign: "center",
            borderRadius: 15,
            boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
            "& .profile-card-body-header": {
                marginBottom: 40,
                display: "flex",
                justifyContent: "end",
                padding: "0 7px",
                opacity: 0,
                transition: "all 0.4s cubic-bezier(0.150, 0, 0.000, 1.090)",
                transform: "translateY(20px)"
            },
            "&:hover": {
                "& .profile-card-body-header": {
                    opacity: 1,
                    transform: "translateY(0px)"
                },
            }
        },
        transition: "all 0.4s cubic-bezier(0.150, 0, 0.000, 1.090)",
        "&:hover": {
            // transform: "scale(1.02) !important"
        }
    },
    form: {
        "& .ant-form-item": {
            margin: 0
        }
    }
}));


const ProfileCard = ({ usuario, refetchUsuario }) => {
    const theme = useTheme();
    const styles = useStyles();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [isEditing, setIsEditing] = useState(false);
    const profileImageInput = useRef(null);
    const { data: puestos } = useApiPuestos();
    const { mutateProfilePhoto, isLoadingProfilePhotoMutation, resetProfilePhotoMutation } = useProfilePhotoMutation();
    const { mutateUsuario, isLoadingUsuarioMutation, resetUsuarioMutation } = useUsuariosMutation();
    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));
    const optionsSelectEstadoCivil = ["Casado(a)", "Soltero(a)", "Union libre", "Viudo(a)"]
    const optionsSelectGenero = ["Femenino", "Masculino"]
    const optionsSelectEstudios = [
        "Doctorado",
        "Licenciatura",
        "Maestría",
        "Preparatoria o Bachillerato",
        "Primaria",
        "Secundaria",
        "Sin formación",
        "Técnico Superior",
    ]

    const handleChangePhoto = (e) => {
        if (!usuario)
            throw Error("No se puede enviar la foto por que faltan datos del usuario");

        if (e.target.files?.length > 0) {
            let fd = new FormData();
            fd.append("files[]", e.target.files[0]);
            mutateProfilePhoto({ data: fd, id: usuario?.usu_id, mutationType: "update" },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            message.success("Se cargó la imagen correctamente")
                            resetProfilePhotoMutation();
                            // se actualizan los datos del usuario
                            queryClient.invalidateQueries(["usuario"])
                        } else {
                            console.log(res);
                            message.error("Hubo un problema al cambiar la imagen de perfil")
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al cambiar la imagen de perfil")
                    }
                })
        }
    }

    const handleClickSelectImage = () => {
        profileImageInput.current?.click()
    }

    const handleClickToggleEdit = () => {
        setIsEditing(!isEditing);
    }

    const handleClickGuardarUsuario = () => {
        form.validateFields().then((values) => {
            // se limpian las llaves nulas
            values = clearNullProperties(values)
            if (values?.usu_fechanac)
                values.usu_fechanac = values.usu_fechanac.format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR)

            mutateUsuario({
                ...values,
                usu_id: usuario.usu_id,
                mutationType: "update"
            }, {
                onSuccess: (resp) => {
                    if (resp.success) {
                        message.success("Se actualizó el usuario")
                        setIsEditing(false)
                        resetUsuarioMutation();
                        refetchUsuario();
                    } else {
                        message.error("Hubo un problema al actualizar el usuario")
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al actualizar el usuario")
                }
            })
        }).catch(() => {
            message.error("Hubo un problema al actualizar el usuario")
        })
    }

    useEffect(() => {
        if (usuario) {
            form.setFieldsValue({
                usu_rfc: usuario?.usu_rfc,
                usu_curp: usuario?.usu_curp,
                usu_nss: usuario?.usu_nss,
                usu_clave: usuario?.usu_clave,
                usu_fechanac: usuario?.usu_fechanac ? moment(usuario?.usu_fechanac, DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR) : null,
                usu_genero: usuario?.usu_genero,
                usu_estadocivil: usuario?.usu_estadocivil,
                usu_estudios: usuario?.usu_estudios,
                pues_id: usuario?.pues_id,
                usu_carrera: usuario?.usu_carrera,
            })
        }
    }, [usuario])

    return (
        <div className={`${styles.profileCard}`} >
            <Tooltip title="Subir foto de perfil" placement="right">
                <div className="profile-card-avatar" onClick={handleClickSelectImage}>
                    <input type="file" style={{ display: "none" }} onChange={handleChangePhoto} ref={profileImageInput} accept="image/png,image/jpg,image/jpeg,image/webp" />
                    {
                        usuario?.recursos?.length > 0 ?
                            <div className="ant-avatar">
                                <div className="profile-card-avatar-overlay">
                                    <CameraOutlined style={{ fontSize: 40 }} />
                                </div>
                                <img
                                    style={{ borderRadius: "50%", background: "white", objectFit: "cover" }}
                                    width={200}
                                    height={200}
                                    src={`${apiHost}/static/${usuario?.recursos[0].recu_ruta}`}
                                />
                            </div>
                            :
                            <div className="ant-avatar">
                                <div className="profile-card-avatar-overlay">
                                    <CameraOutlined style={{ fontSize: 40 }} />
                                </div>
                                <img
                                    width={"100%"}
                                    height={"100%"}
                                    src={IMAGES.GENERIC_USER}
                                    style={{ background: "white", padding: 37, objectFit: "cover" }}
                                />
                            </div>
                    }
                </div>
            </Tooltip>
            <div className="profile-card-body">
                <div className="profile-card-body-header">
                    <Tooltip title="Editar datos generales" placement="right">
                        <Button
                            type="primary"
                            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                            shape="circle"
                            onClick={isEditing ? handleClickGuardarUsuario : handleClickToggleEdit}
                            size="small"
                        />
                    </Tooltip>
                </div>
                <Typography.Title level={4} style={{ marginBottom: 0 }}>{usuario?.usu_nombres} {usuario?.usu_apaterno} {usuario?.usu_amaterno}</Typography.Title>
                <Typography.Title level={5} style={{ margin: 0, color: theme.primaryColor }}>{usuario?.usu_mail}</Typography.Title>
                <Rate value={5} />
                <div style={{ marginTop: 12 }}>
                    <Form form={form} className={styles.form}>
                        <Descriptions
                            size="middle"
                            column={1}
                            bordered
                            labelStyle={{ width: 110 }}
                        >
                            <Descriptions.Item label={isEditing ? <span><span style={{ color: "#f00" }}>*</span> RFC</span> : "RFC"} >
                                {
                                    !isEditing ? usuario?.usu_rfc :
                                        <Form.Item name="usu_rfc" rules={[{ required: true, message: "El RFC es requerido" }]}>
                                            <Input />
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="CURP" >
                                {
                                    !isEditing ? usuario?.usu_curp :
                                        <Form.Item name="usu_curp">
                                            <Input />
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="NSS" >
                                {
                                    !isEditing ? usuario?.usu_nss :
                                        <Form.Item name="usu_nss">
                                            <Input />
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Clave" >
                                {
                                    !isEditing ? usuario?.usu_clave :
                                        <Form.Item name="usu_clave">
                                            <Input />
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Fecha de nacimiento" >
                                {
                                    !isEditing ?
                                        usuario?.usu_fechanac ? DateTime.fromISO(usuario?.usu_fechanac).toFormat(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR) : null :
                                        <Form.Item name="usu_fechanac" style={{ flex: 1 }} >
                                            <DatePicker format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} style={{ width: "100%", marginRight: 20 }} />
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Genero"  >
                                {
                                    !isEditing ? usuario?.usu_genero ? optionsSelectGenero[usuario?.usu_genero - 1] : null :
                                        < Form.Item name="usu_genero" style={{ flex: 1 }}>
                                            <Select style={{ textAlign: "left" }}>
                                                {
                                                    optionsSelectGenero.map((genero, iGenero) => <Select.Option value={iGenero + 1} key={`${genero}_${iGenero}`}>{genero}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Estado civil" >
                                {
                                    !isEditing ? usuario?.usu_estadocivil ? optionsSelectEstadoCivil[usuario?.usu_estadocivil - 1] : null :
                                        <Form.Item name="usu_estadocivil" style={{ flex: 1 }}>
                                            <Select style={{ textAlign: "left" }}>
                                                {
                                                    optionsSelectEstadoCivil.map((edo, iEdo) => <Select.Option value={iEdo + 1} key={`${edo.replace(" ", "")}_${iEdo}`}>{edo}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Estudios" >
                                {
                                    !isEditing ? usuario?.usu_estudios ? optionsSelectEstudios[usuario?.usu_estudios - 1] : null :
                                        <Form.Item name="usu_estudios" style={{ flex: 1 }}>
                                            <Select style={{ textAlign: "left" }}>
                                                {
                                                    optionsSelectEstudios.map((est, iEst) => <Select.Option value={iEst + 1} key={`${est.replace(" ", "")}_${iEst}`}>{est}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Puesto">
                                {
                                    !isEditing ? usuario?.puesto?.pues_nombre :
                                        <Form.Item name="pues_id" style={{ flex: 1 }}>
                                            <Select style={{ textAlign: "left" }}>
                                                {
                                                    puestos.map((puesto, iPuesto) => <Select.Option value={puesto.pues_id} key={`${puesto.pues_nombre}_${iPuesto}`}>{puesto.pues_nombre}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Carrera">
                                {
                                    !isEditing ? usuario?.usu_carrera :
                                        <Form.Item name="usu_carrera" style={{ flex: 1 }}>
                                            <Input max={150} />
                                        </Form.Item>
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default ProfileCard;