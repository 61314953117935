import { CloseOutlined, FileDoneOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Row, Upload } from 'antd';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AnimatePresence, motion } from "framer-motion"
import { useRef } from 'react';
import { extFileValidate } from '../../helpers/files.helper';


const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        display: "grid"
    },
    fileItem: {
        border: "1px solid #eee",
        padding: 10,
        margin: 5,
        "& .file-item-name": {

        },
        "& .file-item-ico": {
            fontSize: 30,
            color: theme.secondaryColor
        },
    }
}))

const FileItem = ({ info, handleDeleteFile }) => {
    const styles = useStyles();

    return (
        <motion.div
            layout
            className={styles.fileItem}
            transition={{ duration: 0.3, type: "spring" }}
            initial={{ opacity: 0, marginTop: -20 }}
            animate={{ opacity: 1, marginTop: 0 }}
            exit={{ opacity: 0, marginTop: -20 }}
        >
            <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                    shape="circle"
                    type="primary"
                    icon={<CloseOutlined style={{ fontSize: 12 }} />}
                    onClick={handleDeleteFile}
                    style={{ minWidth: 20, width: 20, height: 20 }}
                />
            </div>
            <div className='file-item-ico'>
                <FileDoneOutlined />
            </div>
            <div className='file-item-name'>{info?.name}</div>
        </motion.div>
    );
}


/**
 * 
 * @param {Object} props 
 * @param {Function} [props.onChange] - callback que se ejecuta cuando los archivos del componente han cambiado
 * @param {Number} [props.limit] - limite de archivos que se pueden seleccionar  
 * @param {String[]} [props.allowedExtensions] - extensiones de archivos permitidas
 * @param {Number} [props.maxFileSize] - tamaño máximo en MB por archivo
 */
const FileDropper = ({ onChange, limit, allowedExtensions, maxFileSize }) => {
    const styles = useStyles();
    const [files, setFiles] = useState([])
    const inputRef = useRef(null);

    const isValidFileSize = (fileList) => {
        let isValidSize = true;
        if (maxFileSize) {
            let files = Array.from(fileList);
            for (let file of files) {
                let fileMBSize = Math.ceil(file.size / 1024);
                if (fileMBSize > (maxFileSize*1024)) {
                    message.error(`El tamaño maximo por archivo es de ${maxFileSize}MB`);
                    isValidSize = false;
                }
            }
        }

        return isValidSize;
    }

    const handleRemoveFileItem = (file) => {
        let tFiles = [...files];
        let posFile = files.indexOf(file)

        if (posFile >= 0) {
            tFiles.splice(posFile, 1)
            setFiles(tFiles);
            if (onChange) onChange(tFiles)
        }

        inputRef.current.value = null;
    }

    const handleChangeInputFile = (e) => {
        // se valida el tamaño de los archivos en caso de tener un limite de tamaño
        let isValidSize = isValidFileSize(e.target.files)
        if (!isValidSize) return;

        // se validan las extensiones de los archivos
        let isValidExt = extFileValidate(allowedExtensions, e.target.files);
        if (!isValidExt) return;

        // se valida el numero de archivos que se han agregado
        if ((files.length + e.target.files.length) > limit) {
            message.error({
                content: `Cantidad máxima de archivos: ${limit}`,
                key: "limit-reached"
            });
            return;
        }
        setFiles(files => [...files, ...e.target.files])
        if (onChange) onChange([...files, ...e.target.files])
    }

    const openFileDialog = () => inputRef.current.click()

    const handleDropFiles = (e) => {
         // se valida el tamaño de los archivos en caso de tener un limite de tamaño
         let isValidSize = isValidFileSize(e.nativeEvent.dataTransfer.files)
         if (!isValidSize) return;


        // se validan las extensiones de los archivos
        let isValidExt = extFileValidate(allowedExtensions, e.nativeEvent.dataTransfer.files);
        if (!isValidExt) return;

        // se valida el numero de archivos que se han agregado
        if ((files.length + e.nativeEvent.dataTransfer.files.length) > limit) {
            message.error({
                content: `Cantidad máxima de archivos: ${limit}`,
                key: "limit-reached"
            });
            e.preventDefault();
            return;
        }
        setFiles(files => [...files, ...e.nativeEvent.dataTransfer.files])
        if (onChange) onChange([...files, ...e.nativeEvent.dataTransfer.files])
    }

    return (
        <div className={styles.container}>
            <input type="file" hidden ref={inputRef} multiple={true} onChange={handleChangeInputFile} />
            {
                files?.length > 0 && files?.length < limit &&
                <Row style={{ marginBottom: 10 }}>
                    <Button icon={<PlusOutlined />} onClick={openFileDialog}>Subir más archivos</Button>
                </Row>
            }
            <Upload.Dragger
                name='file'
                multiple={true}
                beforeUpload={Upload.LIST_IGNORE}
                showUploadList={false}
                openFileDialogOnClick={false}
                defaultFileList={files}
                onDrop={handleDropFiles}
            >
                {
                    files?.length <= 0 &&
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text"><Button onClick={openFileDialog}>Haz click</Button>  o suelta archivos para subir</p>
                        <p className="ant-upload-hint">{limit && `Máximo ${limit} archivos`}</p>
                        <p>{
                            allowedExtensions &&
                            <div>
                                <span style={{ color: "#bbb" }}>( {allowedExtensions.map(ext => ext.toUpperCase()).join(", ")} )</span>
                            </div>
                        }</p>
                    </>
                }

                {
                    files?.length > 0 &&
                    <div style={{ display: "grid", gridTemplateColumns: "repeat( auto-fit, minmax(150px, 1fr) )", padding: 10 }}>
                        <AnimatePresence>
                            {
                                files.map((file, iFile) => <FileItem info={file} key={`file_item_${iFile}`} handleDeleteFile={() => handleRemoveFileItem(file)} />)
                            }
                        </AnimatePresence>
                    </div>
                }

            </Upload.Dragger >
        </div >
    );
}

export default FileDropper;