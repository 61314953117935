import { Button, Form, Input, Row, Select, Typography, message } from "antd";
import { useApiPuestos, useDeletePuesto, usePuestosMutation } from "../../../hooks/api/puestos";
import { useEffect, useState } from "react";
import { PuestoDto } from "../../../@types/Puestos.types";
import { useTheme } from "react-jss";

/**
 * Componente de funcionalidad para eliminar un puesto definido por {@link pues_id}
 * @param {Object} props 
 * @param {Number} props.pues_id -  id del puesto que se va a eliminar 
 * @param {Function} [props.closeEliminarPuesto] -  funcion para cerrar el componente padre 
 * @param {Function} [props.onDeletePuesto] -  callback que se ejecuta cuando se ha eliminado el puesto exitosamente
 */
const EliminarPuesto = ({ pues_id, closeEliminarPuesto, onDeletePuesto }) => {
    /**
     * @type {Object}
     */
    const theme = useTheme();
    const [form] = Form.useForm();
    /**
     * @type {[PuestoDto[], React.Dispatch<React.SetStateAction<PuestoDto[]>>]}
     */
    const [puestosDisponibles, setPuestosDisponibles] = useState([]);
    const { data: puestos } = useApiPuestos();
    const { deletePuesto } = useDeletePuesto();

    const eliminarPuesto = () => {
        form.validateFields().then(values => {
            deletePuesto({
                pues_id,
                pues_nuevoid: values.pues_id
            }, {
                onSuccess: (data) => {
                    if (data.success) {
                        message.success("Se ha eliminado el puesto");
                        if (closeEliminarPuesto) closeEliminarPuesto();
                        if (onDeletePuesto) onDeletePuesto();
                    } else {
                        message.error(data.mensaje)
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al eliminar el puesto")
                }
            });
        }).catch(() => message.error("No se ha seleccionado el nuevo puesto"));
    }

    useEffect(() => {
        if (puestos && pues_id) {
            setPuestosDisponibles(puestos.filter(puesto => puesto.pues_id !== pues_id && puesto.pues_activo))
        }
    }, [puestos, pues_id])

    return (
        <div>
            <Row style={{ flexDirection: "column" }}>
                <Typography.Title level={5} style={{ color: theme.secondaryColor, margin: 0 }}>Nota</Typography.Title>
                <Typography.Paragraph>Para poder eliminar el puesto es necesario reasignar al nuevo puesto para los registros relacionados.</Typography.Paragraph>
            </Row>
            <Row>
                <Form form={form} layout="vertical" style={{ width: "100%" }}>
                    <Form.Item style={{ flex: 1 }} name="pues_id" label="Reasignar al puesto" rules={[{ required: true, message: "El nuevo puesto es requerido." }]}>
                        <Select>
                            {
                                puestosDisponibles.map(puesto => <Select.Option value={puesto.pues_id} key={puesto.pues_id}>{puesto.pues_nombre}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Row>
            <Row style={{ justifyContent: "end", gap: 10 }}>
                <Button type="secondary" onClick={() => { if (closeEliminarPuesto) closeEliminarPuesto() }} >Cancelar</Button>
                <Button type="primary" onClick={eliminarPuesto} >Eliminar</Button>
            </Row>
        </div>
    );
}

export default EliminarPuesto;