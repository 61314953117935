import { createUseStyles } from "react-jss";
import { Badge, Col, Dropdown, Modal, Row, Tabs, Typography } from "antd";
import ProfileCard from "./ProfileCard";
import UsuarioDomicilio from "../../../Usuarios/UsuarioPage/UsuarioDomicilio";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useApiUsuario } from "../../../../hooks/api/usuarios";
import { cubicBezier, motion } from "framer-motion"
import EnCasoAccidenteForm from "../../../Usuarios/UsuarioPage/EnCasoAccidenteForm";
import Certificaciones from "../../../Usuarios/UsuarioPage/Certificaciones";
import { GiHamburgerMenu } from "react-icons/gi";
import { useModal } from "../../../../hooks/useModal";
import ChangeUserPassword from "../../ChangeUserPassword";

const useStyles = createUseStyles(theme => ({
    container: {
        padding: "10px 20px 20px 20px",
        overflowY: "auto",
        minHeight: "calc(100vh - 120px)",
    },
    profileHeader: {
        height: 46,
        marginBottom: 18,
        display: "flex",
        gap: 10,
        alignItems: "center",
        justifyContent: "space-between",
        "& .ant-typography": {
            margin: "0 !important"
        },
    },
    dropdown: {
        border: "1px solid #eee",
        display: "flex",
        gap: 6,
        alignItems: "center",
        justifyContent: "center",
        fontSize: 14,
        color: "rgb(47, 84, 235)",
        fontWeight: 500,
        cursor: "pointer",
        background: "#FFF",
        padding: "5px 15px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "20px",
        width: 100
    },
    modalCambiaPassword: {
        "& .ant-modal-content": {
            borderRadius: 15,
            "& .ant-modal-body": {
                padding: "10px 20px"
            }
        }
    },
    dropdownOptions: {
        "& ul": {
            padding: 0,
        },
        "& .ant-dropdown-menu-item": {
            padding: 0,
            "& .dropdown-custom-option": {
                padding: 10
            }
        }
    }
}));


const PerfilDatosGenerales = () => {
    const styles = useStyles();
    const [cambiarContrasenaVisible, showCambiarContrasena, closeCambiarContrasena] = useModal();
    const { user } = useSelector((state) => ({ configuracion: state.configuracion, user: state.auth.user }));
    const { data: usuario, refetch: refetchUsuario } = useApiUsuario({ initialData: {} }, user?.usu_id);

    useEffect(() => {
        refetchUsuario();
    }, [user])

    const items = [
        {
            label: (
                <div onClick={showCambiarContrasena} className="dropdown-custom-option">Cambiar contraseña</div>
            ),
            key: 'cambio_contraseña'
        },
        // { label: 'item 2', key: 'item-2' },
    ];

    return (
        <>
            {/* modal donde se muestra el componente para cambiar la contraseña */}
            <Modal
                open={cambiarContrasenaVisible}
                onCancel={closeCambiarContrasena}
                footer={null}
                width={450}
                className={styles.modalCambiaPassword}
                destroyOnClose
                centered
            >
                <ChangeUserPassword closeParent={closeCambiarContrasena} />
            </Modal>

            <div className={`${styles.container}`}>
                <div className={styles.profileHeader}>
                    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                        <Badge color="geekblue" />
                        <Typography.Title level={4}>Perfil</Typography.Title>{"-"}
                        <Typography.Title level={5} style={{ color: "#2f54eb" }}>Datos generales</Typography.Title>
                    </div>
                    <div style={{ marginRight: 60 }}>
                        <Dropdown overlayClassName={styles.dropdownOptions} menu={{ items }} placement="bottomRight" arrow trigger="click" >
                            <div className={`${styles.dropdown}`}>
                                Menú
                                <GiHamburgerMenu style={{ fontSize: 15, marginTop: 2 }} />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <Row style={{ gap: 20, alignItems: "start" }}>
                    <Col
                        md={{ span: 24 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 5 }}
                        style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 20 }}>
                        <Row style={{ width: "100%" }}>
                            <motion.div
                                style={{ width: "100%" }}
                                initial={{ opacity: 0, filter: "blur(20px)", transform: "translateY(100px)" }}
                                animate={{ opacity: 1, filter: "blur(0px)", transform: "translateY(0px)", }}
                                transition={{ duration: 0.3, delay: 0.15, ease: cubicBezier(0.150, 0, 0.000, 1.090) }}
                            >
                                <ProfileCard usuario={usuario} refetchUsuario={refetchUsuario} />
                            </motion.div>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <motion.div
                                style={{ width: "100%" }}
                                initial={{ opacity: 0, filter: "blur(20px)", transform: "translateY(100px)" }}
                                animate={{ opacity: 1, filter: "blur(0px)", transform: "translateY(0px)", }}
                                transition={{ duration: 0.3, delay: 0.4, ease: cubicBezier(0.150, 0, 0.000, 1.090) }}
                            >
                                <EnCasoAccidenteForm usu={usuario} refetchUsuario={refetchUsuario} />
                            </motion.div>
                        </Row>

                    </Col>
                    <Col
                        md={{ span: 24 }}
                        xl={{ span: 15 }}
                        xxl={{ span: 18 }}
                        style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 20 }}
                    >
                        <Row>
                            <motion.div
                                style={{ width: "100%" }}
                                initial={{ opacity: 0, filter: "blur(20px)", transform: "translateY(100px)" }}
                                animate={{ opacity: 1, filter: "blur(0px)", transform: "translateY(0px)", }}
                                transition={{ duration: 0.3, delay: 0.25, ease: cubicBezier(0.150, 0, 0.000, 1.090) }}
                            >
                                <UsuarioDomicilio usu={usuario} />
                            </motion.div>
                        </Row>
                        <Row>
                            <motion.div
                                style={{ width: "100%" }}
                                initial={{ opacity: 0, filter: "blur(20px)", transform: "translateY(100px)" }}
                                animate={{ opacity: 1, filter: "blur(0px)", transform: "translateY(0px)", }}
                                transition={{ duration: 0.3, delay: 0.4, ease: cubicBezier(0.150, 0, 0.000, 1.090) }}
                            >
                                <div style={{ display: "flex", width: "100%", flex: 1, flexDirection: "column", padding: 20, borderRadius: 15, gap: 15 }}>
                                    <Tabs defaultActiveKey="metricas">
                                        <Tabs.TabPane tab="Métricas" key="metricas">
                                            Métricas
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Certificaciones" key="certificaciones">
                                            <Certificaciones usu={usuario} />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </motion.div>
                        </Row>
                    </Col>
                </Row>
            </div >
        </>
    );
}

export default PerfilDatosGenerales;