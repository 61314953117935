import { TipoTicketAgente } from "../@enums/TicketsAgentes.enums"

export const getResponsableActivo = (agentes, usu_id = null) => {
    return !usu_id ?
        agentes?.find(age => age.tiag_id === TipoTicketAgente.RESPONSABLE && age.tica_usumodificaticket) :
        agentes?.find(age => age.tiag_id === TipoTicketAgente.RESPONSABLE && age.tica_usumodificaticket && age?.usu_id === usu_id)
}

export const getCreadorTicket = (agentes) => {
    return agentes?.find(age => age.tiag_id === TipoTicketAgente.CAPTURISTA)
}

export const getContactoTicket = (agentes) => {
    return agentes?.find(age => age.tiag_id === TipoTicketAgente.CONTACTO)
}


export const getObservadorTicket = (agentes) => {
    return agentes?.find(age => age.tiag_id === TipoTicketAgente.OBSERVADOR)
} 