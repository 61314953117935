import { Button, Col, Form, Input, message, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSistemaUpdateMutation } from "../../../hooks/api/sistemas";
import { styles } from "./styles";
import { SistemaDto } from "../dto";
import NumericInput from "../../../components/form/NumericInput";
import { SistemaClasificacion, SistemaSubClasificacion, SistemaTipoLicenciamiento } from "../../../@enums/Sistemas.enum";
import FileItem from "../../../components/Files/FileItem";
import { useSelector } from "react-redux";
import RecursosManager from "../../Recursos/RecursosManager";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles(styles);

/**
 * 
 * @param {Object} props
 * @param {SistemaDto} props.sistema - objeto del sistema en caso de que se quiera modificar o mostrar en modo edicion
 * @param {Function} props.closeActualizarSistema - funcion para cerrar el contenedor donde se está mostrando el componente de crear  sistema
 * @returns 
 */
const ActualizarSistema = ({ sistema, closeActualizarSistema }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const queryClient  = useQueryClient()
    const { mutateSistemaUpdate, isLoadingSistemaUpdateMutation } = useSistemaUpdateMutation();
    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));

    const actualizarSistema = () => {
        form.validateFields().then(values => {
            values = { sis_id: sistema?.sis_id, ...values };
            mutateSistemaUpdate(values, {
                onSuccess: ({ success, mensaje }) => {
                    if (success) {
                        message.success(mensaje);
                    } else {
                        message.error(mensaje);
                    }
                },
                onError: (err) => {
                    console.log(err);
                    message.error("Hubo un problema al editar el sistema");
                },
            });
        }).catch(() => message.error("Aun hay campos con error"))
    }

    // const handleChangeFiles = () => {
    //     queryClient.invalidateQueries(["sistemas"])
    // }
    
    const handleSaveArchivoSistema = () => {
        queryClient.invalidateQueries(["sistemas"])
    }

    const handleDeleteArchivoSistema = () => {
        queryClient.invalidateQueries(["sistemas"])
    }

    useEffect(() => {
        if (sistema) {
            form.setFieldsValue(sistema);
        }
    }, [sistema])

    return (
        <Space className={styles.formWrapper} size={10}>
            <Row>
                <Typography.Title level={4}>Actualizar sistema</Typography.Title>
            </Row>
            <div>
                <Row>

                    <Form form={form} layout="vertical" style={{ width: "100%" }}>
                        <Row style={{ gap: 20, width: "100%" }}>
                            <Col xs={24} sm={24} md={{ span: 24 }} xl={{ span: 13 }}>
                                <Row>
                                    <Typography.Title level={5}>Datos generales</Typography.Title>
                                </Row>
                                <Row >
                                    <Form.Item name="sis_nombre" label="Nombre del sistema" rules={[{ required: true, message: "El nombre es requerido." }]} style={{ flex: 1 }}>
                                        <Input />
                                    </Form.Item>
                                </Row>
                                <Row style={{ gap: 20 }}>
                                    <Form.Item name="sis_clasificacion" label="Clasificación" style={{ flex: 1 }} >
                                        <Select>
                                            {
                                                Object.keys(SistemaClasificacion).map(k => <Select.Option value={SistemaClasificacion[k]} key={k}>{k}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="sis_subclasificacion" label="SubClasificación" style={{ flex: 1 }} >
                                        <Select>
                                            {
                                                Object.keys(SistemaSubClasificacion).map(k => <Select.Option value={SistemaSubClasificacion[k]} key={k}>{k}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="sis_tipo" label="Tipo licenciamiento" style={{ flex: 1 }} >
                                        <Select>
                                            {
                                                Object.keys(SistemaTipoLicenciamiento).map(k => <Select.Option value={SistemaTipoLicenciamiento[k]} key={k}>{k}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item name="sis_serie" label="Serie" style={{ flex: 1 }}>
                                        <Input />
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Typography.Title level={5}>Precios y costos</Typography.Title>
                                </Row>
                                <Row style={{ gap: 20 }}>
                                    <Form.Item name="sis_precio" label="Precio" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                    <Form.Item name="sis_costo" label="Costo" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                </Row>
                                <Row style={{ gap: 20 }}>
                                    <Form.Item name="sis_precioadicional" label="Precio adicional" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                    <Form.Item name="sis_costoadicional" label="Costo adicional" style={{ flex: 1 }}>
                                        <NumericInput />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col sm={24} xs={24} md={24} xl={{ span: 10 }} style={{}}>
                                <Row>
                                    <Typography.Title level={5}>Anotaciones</Typography.Title>
                                </Row>
                                <Row style={{ gap: 20, flexDirection: "column" }}>
                                    <Form.Item name="sis_descripcion" label="Descripción" style={{ flex: 1 }}>
                                        <Input.TextArea showCount maxLength={255} />
                                    </Form.Item>
                                    <Form.Item name="sis_observaciones" label="Observaciones" style={{ flex: 1 }}>
                                        <Input.TextArea showCount maxLength={255} />
                                    </Form.Item>
                                    <Form.Item name="sis_consideraciones" label="Consideraciones" style={{ flex: 1 }}>
                                        <Input.TextArea showCount maxLength={255} />
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row>
                    <Typography.Title level={5}>Archivos y fotos</Typography.Title>
                </Row>
                <Row style={{ gap: 20, alignItems: "center" }}>
                    {/* {
                        sistema.recursos?.map((recurso, iRec) =>
                            <FileItem
                                key={`${iRec}_${recurso?.recu_id || recurso?.name}_recurso`}
                                type={recurso?.recu_mime || recurso?.type}
                                filename={recurso?.recu_nombre || recurso?.name}
                                src={`${apiHost}/static/${recurso.recu_ruta}`}
                                style={{width: 200}}
                            />)
                    } */}
                     <RecursosManager
                            catalogoFieldId="sis_id"
                            catalogoId={sistema?.sis_id}
                            recursos={sistema?.recursos || []}
                            allowDeleteFile
                            allowAddFiles
                            // onChangeFiles={handleChangeFiles}
                            limit={3}
                            allowedExtensions={["pdf", "jpeg", "jpg", "png"]}
                            onSaveResources={handleSaveArchivoSistema}
                            onDeleteFile={handleDeleteArchivoSistema}
                            useApi
                        />
                </Row>
            </div>
            <Row style={{ justifyContent: "end" }}>
                <Button type="primary" onClick={actualizarSistema} loading={isLoadingSistemaUpdateMutation}>
                    Guardar sistema
                </Button>
            </Row>
        </Space>
    );
};

export default ActualizarSistema;
