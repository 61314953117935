import React, {useState, useEffect} from "react";
import { DatePicker, Row, Col, Form, Select } from "antd";
import CardAnimatedLicences from "./components/cardAnimated/cardAnimated";
import TableLicencias from "./components/table/tableLicencias";
import { GetAllUsers } from "../../hooks/api/getAllusuarios";
import { GetCompanyContact } from "../../hooks/api/getCompanyWhitContact";
import { GetLicences } from "../../hooks/api/getLicencesCompany";
import { GetSystems } from "../../hooks/api/getSistemas";
import { SistemaTipoLicenciamiento } from "../../@enums/Sistemas.enum";
import { motion } from "framer-motion";
import moment from "moment";
import fixtures from "./fixtures";
import './styles.css'

const { RangePicker } = DatePicker;

const MetricasLicencias = () => {

    const [form] = Form.useForm();

    const {getAllUsersMutation} = GetAllUsers();
    const {getCompanyContact} = GetCompanyContact();
    const {getLicences} = GetLicences();
    const {getSystemsMutate} = GetSystems();

    const [columns, setColumns] = useState(fixtures().colums);
    const [dataSource, setDataSource] = useState(null);
    const [dataCard, setDataCard] = useState(null);
    const [tipeLicence, setTipeLicence] = useState(1);

    const optionsTipeLicence = [
        {value: SistemaTipoLicenciamiento.ANUAL, label: 'Anual'},
        {value: SistemaTipoLicenciamiento.TRADICIONAL, label: 'Tradicional'}
    ]

    useEffect(() => {
        getAllData(null, 1);
    }, []);

    const getAllData = (filteredValues, tipe) => {
        console.log(tipeLicence)
        const fetchData = async () => {
            let licencesFiltered = null;
            let licencesTipe = null;
            try {
                const asesoresData = await getAsesores();
                const companiesData = await getCompanies();
                const  licences = await getLicencias();
                licencesTipe = licences.filter(licence => licence.usli_tipo === tipe);
                if(filteredValues !== null){
                    licencesFiltered = licencesTipe.filter(licence => moment(licence.usli_fechacaducidad) >= filteredValues.dateFilter[0] && moment(licence.usli_fechacaducidad) <= filteredValues.dateFilter[1]);
                }else{licencesFiltered = licencesTipe}
                
                if(asesoresData !== undefined && companiesData !== undefined && licences !== undefined){
                    createDataSourceAsesorCompany(asesoresData, companiesData, licencesFiltered)
                }
                const systems = await getSystems();
                if(systems !== undefined){
                    createDataCards(systems, licencesFiltered, asesoresData, companiesData);
                }

            } catch (error) {
                console.log(error);
            }
        };
        
        fetchData();
    }

    const getAsesores = () => {
        return new Promise((resolve, reject) => {
          getAllUsersMutation({}, {
            onSuccess: (data) => {
              resolve(data);
            },
            onError: (error) => {
              console.log(error);
              reject(error);
            }
          });
        });
    };
    const getCompanies = () => {
    return new Promise((resolve, reject) => {
        getCompanyContact({}, {
        onSuccess: (data) => {
            resolve(data);
        },
        onError: (error) => {
            console.log(error);
            reject(error);
        }
        });
    });
    };
    const getLicencias = () => {
        return new Promise((resolve, reject) => {
                getLicences({},{
                    onSuccess: (data) => {
                        console.log(data)
                        resolve(data);
                    }, 
                    onError: (error) => {
                        console.log(error);
                        reject(error);
                    }
                })
        })
    }   
    const createDataSourceAsesorCompany = (asesors, companies, licences) => {
        console.log(asesors)
            if(licences.length>0){
                const dataS = licences.map((licence) => {
                    const company = companies.find(company => company.emp_id === licence.emp_id);
                    const companyResgister = licence.usli_empresaregistrante;
                    const asesor = asesors.find(asesor => asesor.usu_id === company.emp_vendedor)
                    console.log(company)
                    const contact = company.usuarios_empresas[0] !== undefined ? company.usuarios_empresas[0].usuario : 'S/C' ;
                    let price = licence.usli_numusuarios > 1 ? licence.sistema.sis_precio + (licence.sistema.sis_precioadicional * (licence.usli_numusuarios-1)) : licence.sistema.sis_precio;
                    if(licences.filter(lic => lic.usli_empresaregistrante !== null && lic.usli_empresaregistrante === licence.usli_empresaregistrante).length > 1){
                        price -= price *0.15;
                    }
                    if(company.emp_tipo === 3){
                        price -= price *0.2
                    }
                    console.log(companyResgister)
                    return {
                        asesor: asesor !== undefined ? `${capitalizeFirstLetter(asesor.usu_nombres)} ${capitalizeFirstLetter(asesor.usu_apaterno)} ${capitalizeFirstLetter(asesor.usu_amaterno)}` : 'S/A',
                        company: `${company.emp_razonsocial}`,
                        contact: contact !== 'S/C' ? `${capitalizeFirstLetter(contact.usu_nombres)} ${capitalizeFirstLetter(contact.usu_apaterno)} ${capitalizeFirstLetter(contact.usu_amaterno)}` : contact,
                        phone: contact.usu_tel,
                        email: contact.usu_mail,
                        lictype: licence.usli_tipo === SistemaTipoLicenciamiento.ANUAL ? 'Anual' : licence.usli_tipo === SistemaTipoLicenciamiento.TRADICIONAL ? 'Tradicional' : 'Demo',
                        registercomp: companyResgister !== undefined && companyResgister !== '' && companyResgister !== null? companyResgister  : 'S/E',
                        system: licence.sistema.sis_nombre,
                        ns: licence.usli_numeroserie,
                        usernum: licence.usli_numusuarios,
                        activedate: moment(licence.usli_fechainicial).format("DD-MM-YYYY").toString(),
                        finishyear: moment(licence.usli_fechacaducidad).year(),
                        finishmonth: moment(licence.usli_fechacaducidad).month() + 1,
                        finishdate: moment(licence.usli_fechacaducidad).format("DD-MM-YYYY").toString(),
                        price: `$${price.toLocaleString('en-US', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, })}`
                    }
                })
                setDataSource(dataS)
            }else{
                setDataSource(licences)
            }
    }

    const getSystems = () => {
        return new Promise((resolve, reject) => {
            getSystemsMutate({}, {
              onSuccess: (data) => {
                resolve(data);
              },
              onError: (error) => {
                console.log(error);
                reject(error);
              }
            });
          });
    }

    const createDataCards = (systems, licences, asesors, companies) => {

        let arrayCard = [];
        const empresas = companies;
        const systemTotal = [];
        const userTotal = [];

        const dataForSystem = systems.map((system) => {
            const name =  system.sis_nombre;
            let dataResult = null;
            let totalSystem;
            const licencesOfSystem = licences.filter(licence => licence.sis_id === system.sis_id);
            if(licencesOfSystem.length>0){
                dataResult = getDataPrices(licencesOfSystem, licences, empresas);
                totalSystem = getDataPricesNoFormat(licencesOfSystem, licences, empresas);
                systemTotal.push(totalSystem !== undefined ? totalSystem : 0)
            } 
            return[name, dataResult!== null ? dataResult.users: 0, dataResult!== null ? dataResult.price: 0]
        })
        const dataForAsesors = asesors.map((asesor) => {
            const asesorName = `${capitalizeFirstLetter(asesor.usu_nombres)} ${capitalizeFirstLetter(asesor.usu_apaterno)} `
            let dataResult = null;
            let totalAsesor;
            const companyClient = companies.filter(company => company.emp_vendedor === asesor.usu_id)
            let licencesOfSystem = companyClient.map((company) => {
                const licensesFilter = licences.filter(licence => licence.emp_id === company.emp_id);
                return licensesFilter;
            })
            licencesOfSystem = flattenArray(licencesOfSystem);
            if(licencesOfSystem.length>0){
                dataResult = getDataPrices(licencesOfSystem, licences, empresas)
                totalAsesor = getDataPricesNoFormat(licencesOfSystem, licences, empresas)
                userTotal.push(totalAsesor !== undefined ? totalAsesor : 0 )
            }
            return[asesorName, dataResult!== null ? dataResult.users: 0, dataResult!== null ? dataResult.price: 0]
        })

        let totalSystem  = systemTotal.reduce((total, current) => total + current, 0)
        let totalUser  = userTotal.reduce((total, current) => total + current, 0)

        const systemsCard = {
            title: 'Ventas por Sistema',
            headers: ['Sistemas', 'Unidades', 'Monto'],
            data:dataForSystem,
            total: `$${totalSystem.toLocaleString('en-US', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, })}`
        }
        const asesorsCards = {
            title: 'Ventas por Asesor',
            headers: ['Asesor', 'Unidades', 'Monto'],
            data:dataForAsesors,
            total: `$${totalUser.toLocaleString('en-US', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, })}`
        }

        arrayCard.push(systemsCard);
        arrayCard.push(asesorsCards);
        setDataCard(arrayCard);
    }

    const getDataPrices = (licencesOfSystem, licences, companies) => {
        let price = 0;
        let users = 0;
        licencesOfSystem.map((data) => {
            let company = companies.find(company => company.emp_id === data.emp_id);
            const companyRegisterDisc = licences.filter(licence => licence.usli_empresaregistrante !== null && licence.usli_empresaregistrante === data.usli_empresaregistrante).length > 1 ? true : false;
            users += 1;
            let priceParcial = data.sistema.sis_precio + (data.sistema.sis_precioadicional*(data.usli_numusuarios-1));
            
            if(companyRegisterDisc){
                console.log(3)
                priceParcial -= priceParcial*0.15; 
            }
            price = price + priceParcial;

            if(company.emp_tipo === 3){
                price -= price*0.2;
            }
        })
        const result = {
            price: `$${price.toLocaleString('en-US', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, })}`,
            users: users
        }
        return result;
    }
    const getDataPricesNoFormat = (licencesOfSystem, licences, companies) => {
        let price = 0;
        let users = 0;
        licencesOfSystem.map((data) => {
            let company = companies.find(company => company.emp_id === data.emp_id);
            const companyRegisterDisc = licences.filter(licence => licence.usli_empresaregistrante !== null && licence.usli_empresaregistrante === data.usli_empresaregistrante).length > 1 ? true : false;
            users += 1;
            let priceParcial = data.sistema.sis_precio + (data.sistema.sis_precioadicional*(data.usli_numusuarios-1));
            
            if(companyRegisterDisc){
                console.log(3)
                priceParcial -= priceParcial*0.15; 
            }
            price = price + priceParcial;

            if(company.emp_tipo === 3){
                price -= price*0.2;
            }
        })

        return price;
    }

    function flattenArray(arr) {
        return arr.reduce((result, item) => {
          if (Array.isArray(item)) {
            // Si el elemento actual es un array, realiza una llamada recursiva
            result.push(...flattenArray(item));
          } else {
            // Si el elemento no es un array, agrégalo al resultado
            result.push(item);
          }
          return result;
        }, []);
    }

    const changeTipeLicence = (value) => {
        setTipeLicence(value)
        getAllData(null, value)
    }


    //PRIMER LETRA MAYUSCULA CADENA
    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const filterData = (values) => {
        getAllData(values, tipeLicence);
    }
    const clearFilter = () => {
        getAllData(null, tipeLicence);
        form.resetFields();
    }

    return(
        <div className="container">
            {dataCard !== null && (
            <Row>
                <Col span={8} style={{padding:3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Row style={{width:'100%'}}>
                        <Col span={8}><span className="tipe_licence_title">Tipo de Licencia:</span></Col>
                        <Col span={16}>
                            <Select
                                style={{width:'100%'}}
                                defaultValue={1}
                                options={optionsTipeLicence}
                                onChange={changeTipeLicence}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={9} offset={5} style={{padding:8}}>
                    <Form
                    form={form}
                        onFinish={filterData}
                    >
                        <Row>
                            <Col span={18} style={{padding:3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Form.Item name='dateFilter' rules={[{required: true, message: 'Introduzca ambas fechas para filtrar'}]}>
                                    <RangePicker className="rangeDate" picker="month"/>
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{padding:3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Form.Item style={{width:'100%'}}>
                                    <motion.button  whileHover={{ scale: 1.05}} whileTap={{ scale: 0.9}}
                                    type='submit'  className='btn-Filter'>
                                    Filtrar
                                    </motion.button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                   
                </Col>
                <Col span={2} style={{padding:3, display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                    <motion.button  whileHover={{ scale: 1.05}} whileTap={{ scale: 0.9}}
                        className='btn-CleanFilter' onClick={clearFilter}>
                        Limpiar
                    </motion.button>
                </Col>
                    {dataCard.map((data, index) => (
                        <>
                            <Col span={12} style={{padding:8}} key={index}>
                                <CardAnimatedLicences key={index} title={data.title} headers={data.headers} data={data.data}  idData={index} total={data.total}/>
                            </Col>
                        </>
                    ))}
            </Row>
            )}
            <Row>
                <Col span={24}>
                    {
                    dataSource !== null ? (
                        <TableLicencias columns={columns} dataSource={dataSource}/>
                        ):(<span>No existen datos</span>)
                    }
                </Col>
            </Row>
        </div>
    )
}

export default MetricasLicencias;