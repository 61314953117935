import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import reduxConfig from "./app/redux/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Worker } from '@react-pdf-viewer/core';

import LoginRegistro from "./app/views/LoginRegistro";
import GeneraPassword from "./app/views/LoginRegistro/GeneraPassword";
import CambiaPassword from "./app/views/LoginRegistro/CambiaPassword";
import Error404 from "./app/views/Errors/404";
// import SplashScreen from "./SplashScreen";

const theme = {
    colors: {
        primary: "#003CA7",
        secondary: "#1890ff",
        terciary: "#02A6E3",
    },

    // colors
    primaryColor: "#003CA7",
    secondaryColor: "#1890ff",
    terciaryColor: "#02A6E3",

    // color acent
    primaryColorAcent: "#003296",

    //result colors
    success: "#00d100",
    error: "#FF0000",
    danger: "#FF0000",

    resultColors: {
        success: "#00d100",
        error: "#FF0000",
        warning: "#ffe58f",
    },
    btn: {
        color: "#FFFFFF",
        // border: "1px #EC1F24 solid",
        borderRadius: 0,
        "&:hover": {
            // backgroundColor: "#e62e32 !important",
            // border: "1px #EC1F24 solid",
        },
    },

    btnSecondary: {
        color: "#fff",
        backgroundColor: "#4285f4",
        "&:hover": {
            backgroundColor: "#5693f5",
            color: "#fff",
        },
    },
    btnTerciary: {
        color: "#fff",
        backgroundColor: "#02A6E3",
    },
    simpleBtn: {
        padding: "7px 15px",
        height: "auto",
    },
    inputLightBG: {
        backgroundColor: "#f0f0f0",
        color: "#000",
        border: "none",
        borderRadius: 0,
        ":focus": {
            border: "1px red solid",
        },
    },
};

const queryClient = new QueryClient();

// const askDesktopNotificationPermission = () => {
//     function handlePermission(permission) {
//         if (Notification.permission === 'denied' || Notification.permission === 'default') {
//             notificationBtn.style.display = 'block';
//         } else {
//             notificationBtn.style.display = 'none';
//         }
//     }

//     if (!('Notification' in window)) {
//         console.log("Este navegador no admite notificaciones.");
//     } else {
//         if (checkNotificationPromise()) {
//             Notification.requestPermission()
//                 .then((permission) => {
//                     handlePermission(permission);
//                 })
//         } else {
//             Notification.requestPermission(function (permission) {
//                 handlePermission(permission);
//             });
//         }
//     }
// }


// react 18
createRoot(document.getElementById("root")).render(
    // <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Provider store={reduxConfig.store}>
                <PersistGate loading={null} persistor={reduxConfig.persistor}>
                    <Worker workerUrl="https:unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Switch>
                                    {/* rutas no seguras */}
                                    <Route exact path="/" component={LoginRegistro} />
                                    <Route exact path="/registro/genera_pass/:token" component={GeneraPassword} />
                                    <Route exact path="/cambia_pass/:token" component={CambiaPassword} />

                                    {/* rutas de aplicacion */}
                                    <Route path={["/:pageid", "/"]}>
                                        <App />
                                    </Route>

                                    {/* rutas no encontradas */}
                                    <Route component={Error404} />
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </Worker>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    // </React.StrictMode>
)


// react 17
// ReactDOM.render(
//     // <React.StrictMode>
//     <QueryClientProvider client={queryClient}>
//         <ReactQueryDevtools initialIsOpen={false} />
//         <Provider store={reduxConfig.store}>
//             <PersistGate loading={null} persistor={reduxConfig.persistor}>
//                 <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">

//                     <ThemeProvider theme={theme}>
//                         <Router>
//                             <Switch>
//                                 {/* new electron browserwindow modal routes  */}
//                                 {/* <Route exact path="/clientes/crear" component={CrearCliente} /> */}
//                                 <Route exact path="/" component={LoginRegistro} />
//                                 <Route exact path="/registro/genera_pass/:token" component={GeneraPassword} />
//                                 <Route exact path="/cambia_pass/:token" component={CambiaPassword} />

//                                 {/* electron main browserwindow routes  */}
//                                 <Route path={["/:pageid", "/"]}>
//                                     <App />
//                                 </Route>
//                             </Switch>
//                         </Router>
//                     </ThemeProvider>
//                 </Worker>
//             </PersistGate>
//         </Provider>
//     </QueryClientProvider>
//     // </React.StrictMode>
//     ,
//     document.getElementById("root")
// );

reportWebVitals();
