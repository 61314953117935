export class TicketReasignationDto {
    /**
     * @property {Number} tick_id - id del ticket que se va a reasignar
     * @type {Number}
    */
    tick_id
    /** 
     * @property {Number} usu_asignadoid - usuario al que se le va a asignar el ticket
     * @type {Number}
    */
    usu_asignadoid
    /**
     * @property {String} motivo - motivo por el que se esta reasignando el ticket
     * @type {String}
     */
    motivo
    /**
     * @property {Number} tick_prioridad - prioridad del ticket
     * @type {Number}
     */
    tick_prioridad
    /**
     * @property {Number} cacu_id - acuerdo de servicio del ticket
     * @type {Number}
     */
    cacu_id
    /**
     * @property {Boolean} set_as_observer - acuerdo de servicio del ticket
     * @type {Boolean}
     */
    set_as_observer
}