
/**
 * @typedef {Object} TicketPrioridades
 */
export const TicketPrioridades = Object.freeze({
    ALTA: 1,
    MEDIA: 2,
    BAJA: 3
});

/**
 * Se consulta el nombre de la PRIORIDAD DEL TICKET en base a su valor entero del area o 
 * @param {Number} value - valor de la prioridad que se requiere extraer la llave   
 * @returns {String} Nombre de la prioridad
 */
export const getKeyPrioridadTicket = (value) =>
    Object.keys(TicketPrioridades).find(key => TicketPrioridades[key] == value);


/**
 * @typedef {Object} TicketStatus
 */
export const TicketStatus = Object.freeze({
    TODOS: 0,
    TICKET_LIBRE: 1,
    TICKET_SEGUIMIENTO: 2,
    ESPERA_CLIENTE: 3,
    CERRADO_CON_EXITO: 4,
    CERRADO_POR_CLIENTE: 5,
    CERRADO_SIN_EXITO: 6,
    ESPERA_FABRICANTE: 7,
    CADUCADO: 9,
});

/**
 * @typedef {Object} TicketTipoProceso
 */
export const TicketTipoProceso = Object.freeze({
    SIN_PROCESO:0,
    SOPORTE_TECNICO: 1,
    REGISTRO_NUEVO_CLIENTE: 2
});

/**
 * @typedef {Object} TipoTicket
 */
export const TipoTicket = Object.freeze({
    TICKET: 1,
    ACTIVIDAD: 2,
    TAREA: 3,
    OPORTUNIDAD: 4,
});

