import { AUTH_LOGIN, AUTH_LOGOUT } from "../action_types/auth.action.types";

export const loginAction = (authPayload) => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN,
            payload: authPayload,
        });
    };
};

export const logoutAction = () => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGOUT,
        });
    };
};
