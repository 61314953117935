import Lottie from "lottie-web";
import { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { LOTTIE_ANIMATIONS } from "../../../animations/lottie";
import { useRouteMatch } from "react-router-dom";
import VentasTickets from "./VentasTickets";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        // minHeight: "calc(100vh - 200px)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    }
}))

const VentasHome = () => {
    const styles = useStyles();
    const animationContainer = useRef();
    const { path } = useRouteMatch();

    useEffect(() => {
        Lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LOTTIE_ANIMATIONS.CHART_MONEY,
        });
    }, []);

    return (
        <div className={`${styles.container}`}>
            <VentasTickets />
        </div>
    );
}

export default VentasHome;