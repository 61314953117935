/**
 * @typedef {Object} TipoAreaDepartamento
 */
export const TipoAreaDepartamento = Object.freeze({
    AREA: 1,
    DEPARTAMENTO: 2
    // OTRO: 3
});

/**
 * @typedef {Object} TipoPosicionAreaDepartamento
 */
export const TipoPosicionAreaDepartamento = Object.freeze({
    SOPORTE: 1,
    VENTAS: 2,
    DESARROLLO: 3
});

/**
 * Se consulta el nombre del tipo en base a su valor entero del area o 
 * @param {Number} value - valor del tipo que se requiere extraer la llave   
 * @returns {String} Nombre del tipo
 */
export const getKeyTipoAreaDepartamento = (value) =>
    Object.keys(TipoAreaDepartamento).find(key => TipoAreaDepartamento[key] == value) || "OTRO";