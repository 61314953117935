import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, DatePicker, Descriptions, Form, Input, message, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Elevation from '../../../components/Elevation';
import { useUsuariosMutation } from '../../../hooks/api/usuarios';
import { DateTime } from 'luxon';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { DATE_FORMATS } from '../../../helpers/dates.helper';
import { clearNullProperties } from '../../../helpers/form.helper';
import { useApiPuestos } from '../../../hooks/api/puestos';

const useStyles = createUseStyles({
    container: {
        width: "100%",
        padding: 15,
        // borderRadius: 15,
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
    },
});

const DatosGenerales = ({ usu, refetchUsuario }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const { data: puestos } = useApiPuestos();
    const { mutateUsuario, isLoadingUsuarioMutation, resetUsuarioMutation } = useUsuariosMutation();
    const optionsSelectEstadoCivil = ["Casado(a)", "Soltero(a)", "Union libre", "Viudo(a)"]
    const optionsSelectGenero = ["Femenino", "Masculino"]
    const optionsSelectEstudios = [
        "Doctorado",
        "Licenciatura",
        "Maestría",
        "Preparatoria o Bachillerato",
        "Primaria",
        "Secundaria",
        "Sin formación",
        "Técnico Superior",
    ]
    const handleClickModificaUsuario = () => setIsEditing(true)

    const handleClickGuardarUsuario = () => {
        form.validateFields().then((values) => {
            // se limpian las llaves nulas
            values = clearNullProperties(values)
            if (values?.usu_fechanac)
                values.usu_fechanac = values.usu_fechanac.format(DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR)

            mutateUsuario({
                ...values,
                usu_id: usu.usu_id,
                mutationType: "update"
            }, {
                onSuccess: (resp) => {
                    if (resp.success) {
                        message.success("Se actualizó el usuario")
                        setIsEditing(false)
                        resetUsuarioMutation();
                        refetchUsuario();
                    } else {
                        message.error("Hubo un problema al actualizar el usuario")
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al actualizar el usuario")
                }
            })
        }).catch(() => {
            message.error("Hubo un problema al actualizar el usuario")
        })
    }

    useEffect(() => {
        if (usu) {
            form.setFieldsValue({
                usu_rfc: usu?.usu_rfc,
                usu_curp: usu?.usu_curp,
                usu_nss: usu?.usu_nss,
                usu_clave: usu?.usu_clave,
                usu_fechanac: usu?.usu_fechanac ? moment(usu.usu_fechanac, DATE_FORMATS.DATE_MOMENT_FORMAT_TO_STR) : null,
                usu_genero: usu?.usu_genero,
                usu_estadocivil: usu?.usu_estadocivil,
                usu_estudios: usu?.usu_estudios,
                usu_carrera: usu?.usu_carrera
            })
        }
    }, [usu])

    return (
        <Elevation style={{ padding: 20, borderRadius: 15 }} className={styles.container}>
            <Row style={{ marginBottom: 10, gap: 20, justifyContent: "space-between" }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: "bold" }}>Datos generales</Typography.Text>
                <div className="section-buttons" style={{ width: "auto" }}>
                    {!isEditing ? (
                        <Button type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={handleClickModificaUsuario} />
                    ) : (
                        <Button type="primary" shape="circle" size="small" icon={<SaveOutlined />} onClick={handleClickGuardarUsuario} loading={isLoadingUsuarioMutation} />
                    )}
                </div>
            </Row>
            <Form style={{ width: "100%" }} form={form}>
                <Descriptions
                    // labelStyle={{ color: "#1890ff", fontWeight: 600, maxWidth: 80, display: "flex", alignItems: "center" }}
                    // contentStyle={{ display: "flex", alignItems: "center", padding: "0 10px" }}
                    size="middle"
                    column={6}
                    bordered
                >
                    <Descriptions.Item label="RFC" span={3}>
                        {
                            !isEditing ? usu?.usu_rfc :
                                <Form.Item name="usu_rfc">
                                    <Input />
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="CURP" span={3}>
                        {
                            !isEditing ? usu?.usu_curp :
                                <Form.Item name="usu_curp">
                                    <Input />
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="NSS" span={3}>
                        {
                            !isEditing ? usu?.usu_nss :
                                <Form.Item name="usu_nss">
                                    <Input />
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Clave" span={3}>
                        {
                            !isEditing ? usu?.usu_clave :
                                <Form.Item name="usu_clave">
                                    <Input />
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Fecha de nacimiento" span={3}>
                        {
                            !isEditing ?
                                usu?.usu_fechanac ? DateTime.fromISO(usu?.usu_fechanac).toFormat(DATE_FORMATS.DATE_LUXON_FORMAT_SLASH_TO_STR) : null :
                                <Form.Item name="usu_fechanac" style={{ flex: 1 }} >
                                    <DatePicker format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} style={{ width: "100%", marginRight: 20 }} />
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Genero" span={3} >
                        {/* {console.log(usu?.usu_genero)} */}
                        {
                            !isEditing ? usu?.usu_genero ? optionsSelectGenero[usu?.usu_genero - 1] : null :
                                < Form.Item name="usu_genero" style={{ flex: 1 }}>
                                    <Select>
                                        {
                                            optionsSelectGenero.map((genero, iGenero) => <Select.Option value={iGenero + 1} key={`${genero}_${iGenero}`}>{genero}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Estado civil" span={3}>
                        {
                            !isEditing ? usu?.usu_estadocivil ? optionsSelectEstadoCivil[usu?.usu_estadocivil - 1] : null :
                                <Form.Item name="usu_estadocivil" style={{ flex: 1 }}>
                                    <Select>
                                        {
                                            optionsSelectEstadoCivil.map((edo, iEdo) => <Select.Option value={iEdo + 1} key={`${edo.replace(" ", "")}_${iEdo}`}>{edo}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Estudios" span={3}>
                        {
                            !isEditing ? usu?.usu_estudios ? optionsSelectEstudios[usu?.usu_estudios - 1] : null :
                                <Form.Item name="usu_estudios" style={{ flex: 1 }}>
                                    <Select>
                                        {
                                            optionsSelectEstudios.map((est, iEst) => <Select.Option value={iEst + 1} key={`${est.replace(" ", "")}_${iEst}`}>{est}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Puesto" span={6}>
                        {
                            !isEditing ? usu?.puesto?.pues_nombre :
                                <Form.Item name="pues_id" style={{ flex: 1 }}>
                                    <Select>
                                        {
                                            puestos.map((puesto, iPuesto) => <Select.Option value={puesto.pues_id} key={`${puesto.pues_nombre}_${iPuesto}`}>{puesto.pues_nombre}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Carrera" span={6}>
                        {
                            !isEditing ? usu?.usu_carrera :
                                <Form.Item name="usu_carrera" style={{ flex: 1 }}>
                                    <Input max={150} />
                                </Form.Item>
                        }
                    </Descriptions.Item>
                </Descriptions>
            </Form>
        </Elevation >
    );
}

export default DatosGenerales;