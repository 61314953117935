import { Badge, Button, Layout, Menu, Popover, Row, Tag, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import React, { useEffect, useState } from "react";
import logo from "../../../images/logo.png";
import logoNombre from "../../../images/logo_nombre.png";
import { BellFilled, BellOutlined, UserOutlined } from "@ant-design/icons";
import { BiLogOut } from "react-icons/bi";
import { AUTH_LOGOUT } from "../../redux/action_types/auth.action.types";
import { TfiLayoutGrid3Alt } from "react-icons/tfi";
import { useApiUsuariosNotificaciones, useUsuariosNotificacionesMutation } from "../../hooks/api/usuarios_notificaciones";
import { useApiUsuariosRecordatorios, useUsuariosRecordatoriosMutation } from "../../hooks/api/usuarios_recordatorios";
import { useQueryUtils } from "../../hooks/api/utils";
import { DateTime } from "luxon";
import { DATE_FORMATS, convertToSystemDateTimeZone, getSystemDateTimeZone } from "../../helpers/dates.helper";
import { TipoNotificacion } from "../../@enums/UsuariosNotificaciones.enums";
import { TipoRecordatorio } from "../../@enums/UsuariosRecordatorios.enums";
import { NotificacionRecordatorio } from "../../components/Notification/NotificacionRecordatorio";
import NotificacionInfo from "../../components/Notification/NotificacionInfo";
import { usePopover } from "../../hooks/usePopover";
import { setConfig } from "../../redux/actions/config.actions";
import { useHistory } from "react-router-dom";
import { setSocket } from "../../redux/reducers/socket.users.reducer";

const { SubMenu } = Menu;

const useStyles = createUseStyles((theme) => ({
    navbar: {
        // backgroundColor: "#fff",
        backgroundColor: theme.primaryColor,
        // boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 12px",
        // borderBottom: "1px solid rgba(200, 200, 200, 0.5)",
        zIndex: 0,
        padding: 0,
        paddingLeft: 15,
        paddingRight: 15,
        color: "#fff",
        fontSize: 16,
        fontWeight: 500,
        display: "flex",
        // height: 46,
        alignItems: "center",
        justifyContent: "space-between",

        "& .logo": {
            width: 30,
            height: 32,
            // marginRight: 12,
        },
        "& .logoNombre ": {
            width: 150,
            height: 20,
            marginRight: 35,
        },
        "& .ant-menu": {
            background: theme.primaryColor,
            "& .ant-menu-item": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    backgroundColor: theme.primaryColorAcent,
                },
                "& .ant-menu-title-content, .ant-menu-submenu-title .ant-menu-title-content": {
                    display: "none",
                },
            },
            "& .ant-menu-item-selected": {
                backgroundColor: theme.primaryColorAcent,
                "&:hover": {
                    backgroundColor: theme.primaryColorAcent,
                },
            },
        },
    },
    userMenuButton: {
        borderBottom: "none",
        "&:hover::after": {
            borderBottom: "none !important",
        },
        "& .ant-menu-submenu-title": {
            "&:active": {
                backgroundColor: theme.primaryColor,
            },
        },
        "& .anticon": {
            padding: 10,
            borderRadius: "100px",
            backgroundColor: "rgba(255,255,255,0.25)",
            color: "#fff",
        },
    },
    menuOption: {
        "& span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            "& svg": {
                marginRight: 5,
            },
        },
    }
}));


const { Header } = Layout;
const NavBar = () => {
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const { invalidateQuery } = useQueryUtils();
    const [renderNavBar, setRenderNavBar] = useState(false);
    /** @type {[NodeJS.Timer, React.Dispatch<React.SetStateAction<NodeJS.Timer>>]} */
    const [recordatoriosInterval, setRecordatoriosInterval] = useState(null);
    const { token, user, notificacionConfig, socketUser } = useSelector((state) => ({
        token: state.auth.token,
        user: state.auth.user,
        notificacionConfig: state.configuracion.notificaciones,
        socketUser: state.socketUser.socket,
    }));
    const { data: notificaciones } = useApiUsuariosNotificaciones({ initialData: [], refetchInterval: 10 * 1000 }, user?.usu_id);
    const { data: recordatorios } = useApiUsuariosRecordatorios({ initialData: [], refetchInterval: 10 * 1000 }, user?.usu_id)
    const { mutateUsuariosNotificaciones } = useUsuariosNotificacionesMutation();


    const handleClick = (e) => {
        console.log("click ", e);
    };

    const logout = () => {
        if (socketUser) {
            sessionStorage.setItem('userConnected', '')
            socketUser.disconnect(2);
            dispatch(setSocket(null));
        }
        dispatch({ type: AUTH_LOGOUT });
    };

    useEffect(() => {
        setRenderNavBar(!!token);
    }, [token]);

    const registraRecordatorioNotificacionMostrada = (notificacionData = null) => {
        let notificacionesMostradas = localStorage.getItem("nm")

        if (!notificacionesMostradas) {
            let data = []
            if (notificacionData !== null)
                data = [notificacionData]
            localStorage.setItem("nm", JSON.stringify(data))
        } else {
            let data = JSON.parse(localStorage.getItem("nm"))
            if (notificacionData !== null) {
                data = [...data, notificacionData]
                localStorage.setItem("nm", JSON.stringify(data))
            }
        }
    }

    const solicitarPermisosNotificaciones = () => {
        if (!("Notification" in window)) {
            console.log("Browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
    }

    useEffect(() => {
        registraRecordatorioNotificacionMostrada()
        solicitarPermisosNotificaciones();
    }, [])

    useEffect(() => {
        if (notificaciones && notificaciones?.length > 0) {
            let notificacionMensaje = notificaciones.find(notificacion => notificacion.noti_titulo == "Mensaje del cliente")
            if (notificacionMensaje)
                new Notification("Mensajes pendientes", {
                    body: "Tienes tickets en seguimiento con mensajes pendientes",
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAD1klEQVR42t3aW2gUVxzH8c2l0Vgat4JtlWojLSg+9EJpaCnS1CB98CW0pQUhkjyEGDVgiKIoeMGg5kGiUYw3vOMt4AVUNBoRFNQXlUQ0oBGJAS9IfIgajVn/fh8O/A+HbGY22V1m/MEHdGfPYX5hZ87JbCIiEvkY6D+GGMZOQQMmY2wk/UlakU/RiGKUYzUKzOFwFGFcFsrwD85iLSqxBz8jKxRFzCSTsB0daEYfqtCAkjAVycV+tKNNNO04iG9DUcSarAp2+nEJ18JWJAOn4ZZ5FqoiZsLPcQd2noSuiJl0LC6L5kooi5iJM/EnSjAhlEXMtfKvIMFxR/BHEC72EZiFm6JZ53PseHQjhor0FtET+B970Y2BssRjjlG4IZp3+D6lRUQkD/U4g0fwk+MeH8NjcLMydUV0UAVicBPDTTyEnZmDlGiEm03ITm0RHViI6ziPVfgLedZFayfqs8QrzA7SXWuLaDrjlGiAnVuYGrTbb61oLjrHcnBYNDHUYUQQ15GVojlgvR5Fs2haURDkBbFaNHXmtcloh5AeLMEnQV/ZF4imBsV4gRh24MtAbVEYMwGjrP+Pw0hUiqYZb7EbUwK11zJbkSK8wBusRja+QjPOiOYUJgZm02humdOxDy8Rg52l5n0jcU00pYH4nZ3Xv8AC3HV+yjth5xdrTJloelGLaNqLCBcjynAK75zb5QxkOvus286kpc5qLaQbi5Gb8iKoQyvew84TlCPLDCqCnepBipSiQzSPUYmcVBb5Dl3Ox2INPnMGHRVND/Kc43NFU4gxaIGdTsxBTtKLmBem4hkO4Zs410yfaDZ6rCOF5rVsbIZ4FErqNZI7yIDlounDRK8izrFy9MFNFxYhmsLbrxbEc9FsjfO+xU4R9/hveIyB0oN65KeyyDzRvMbXPjaN0+O8ZxyuIl760YQiZCRzQcxBp2hW+Nz9FnvMuQ1euY9lmJSMIjWiuedxHdX6KeIsoL3wkyuoRn5iRfQxaDeEvMc0jxPb4HuLomN+QAcSSSvqMdNvkfWiWRshSSzi/sCa4DcxnMSvfvZaP6EfQlr8fAPlPFSoGsKttAK9g5x8C+ZgvNdHS7fr+uCsDaN9nsge0Swc8heseld7hF34D2MSudjdj1RbIt/WOg8X6ob5DDl/WOuIeQwq5CyiCZ7ACdHs1iPp38b/ji7MR8YQJj0nmuPpL6KLVMkw9jyj8VQ0JyIkML/qeqzOP2IGatABO02hKGKt+jEMlMbQFDET/Y3XcLMoVEXMZAV4CjvTQlfETJiPOxDyAJmhLGLduS7ol6HpKRKIPxpLhg/VaKJ5myrDQQAAAABJRU5ErkJggg==',
                })
        }
    }, [notificaciones])

    useEffect(() => {
        if (recordatorios) {
            clearInterval(recordatoriosInterval)
            setRecordatoriosInterval(null);
        }

        let interval = setInterval(() => {
            if (Array.isArray(recordatorios) && user?.usu_id) {
                let notificacionesMostradas = JSON.parse(localStorage.getItem("nm"))
                for (let recordatorio of recordatorios) {
                    //se transforman las fechas y se preparan los datos para las validaciones
                    let notiMostrada = notificacionesMostradas.find(tRecordatorio => tRecordatorio.reco_id === recordatorio.reco_id);
                    let fechaInicialRecordatorio = convertToSystemDateTimeZone(recordatorio.reco_fechainicial);
                    let fechaFinalRecordatorio = null;
                    if (recordatorio?.reco_fechafinal)
                        fechaFinalRecordatorio = convertToSystemDateTimeZone(recordatorio.reco_fechafinal);

                    // se valida para cada tipo de recordatorio que validacion se tiene que realizar para crear la notificacion
                    let crearNotificacion = false;
                    let now = DateTime.now();
                    if (recordatorio.reco_tipo === TipoRecordatorio.DIA_ESPECIFICO) {
                        if (now >= fechaInicialRecordatorio) crearNotificacion = true;
                    } else if (recordatorio.reco_tipo === TipoRecordatorio.PERIODO) {

                        if (now >= fechaInicialRecordatorio && now <= fechaFinalRecordatorio)
                            // para el recordatorio por periodo solo se valida la hora si es que ya se paso la fecha inicial
                            if (now.minute >= fechaInicialRecordatorio.minute && now.hour >= fechaInicialRecordatorio.hour)
                                crearNotificacion = true;

                    } else if (recordatorio.reco_tipo === TipoRecordatorio.DIARIO) {

                        if (now >= fechaInicialRecordatorio) {
                            // para el recordatorio diario solo se valida la hora si es que ya se ha pasado la fecha inicial
                            if (now.minute >= fechaInicialRecordatorio.minute && now.hour >= fechaInicialRecordatorio.hour)
                                crearNotificacion = true;
                        }
                    }

                    // si no se ha mostrado la noficacion del recordatorio se inicia el proceso para crearla
                    if (!notiMostrada) {
                        // se busca la notificacion ligada al recordatorio para validar que ya se haya lanzado
                        let notificacionMostrada = notificacionesMostradas.find(noti => noti.reco_id === recordatorio.reco_id);
                        let notificacion = notificaciones.find(noti => noti.reco_id === recordatorio.reco_id)
                        if (!notificacionMostrada && !notificacion) {
                            if (crearNotificacion) {
                                // se crea la notificacion del recordatorio
                                mutateUsuariosNotificaciones({
                                    mutationType: "create",
                                    id: user.usu_id,
                                    noti_titulo: recordatorio?.reco_tipo === TipoRecordatorio.DIARIO ? "Recordatorio Diario" : "Recordatorio",
                                    noti_descripcion: recordatorio.reco_descripcion,
                                    noti_tipo: 2,
                                    usu_id: user.usu_id,
                                    reco_id: recordatorio.reco_id
                                }, {
                                    onSuccess: (res) => {
                                        if (res.success) {
                                            new Notification("Recordatorio - Tickets", {
                                                body: recordatorio.reco_descripcion,
                                                icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAD1klEQVR42t3aW2gUVxzH8c2l0Vgat4JtlWojLSg+9EJpaCnS1CB98CW0pQUhkjyEGDVgiKIoeMGg5kGiUYw3vOMt4AVUNBoRFNQXlUQ0oBGJAS9IfIgajVn/fh8O/A+HbGY22V1m/MEHdGfPYX5hZ87JbCIiEvkY6D+GGMZOQQMmY2wk/UlakU/RiGKUYzUKzOFwFGFcFsrwD85iLSqxBz8jKxRFzCSTsB0daEYfqtCAkjAVycV+tKNNNO04iG9DUcSarAp2+nEJ18JWJAOn4ZZ5FqoiZsLPcQd2noSuiJl0LC6L5kooi5iJM/EnSjAhlEXMtfKvIMFxR/BHEC72EZiFm6JZ53PseHQjhor0FtET+B970Y2BssRjjlG4IZp3+D6lRUQkD/U4g0fwk+MeH8NjcLMydUV0UAVicBPDTTyEnZmDlGiEm03ITm0RHViI6ziPVfgLedZFayfqs8QrzA7SXWuLaDrjlGiAnVuYGrTbb61oLjrHcnBYNDHUYUQQ15GVojlgvR5Fs2haURDkBbFaNHXmtcloh5AeLMEnQV/ZF4imBsV4gRh24MtAbVEYMwGjrP+Pw0hUiqYZb7EbUwK11zJbkSK8wBusRja+QjPOiOYUJgZm02humdOxDy8Rg52l5n0jcU00pYH4nZ3Xv8AC3HV+yjth5xdrTJloelGLaNqLCBcjynAK75zb5QxkOvus286kpc5qLaQbi5Gb8iKoQyvew84TlCPLDCqCnepBipSiQzSPUYmcVBb5Dl3Ox2INPnMGHRVND/Kc43NFU4gxaIGdTsxBTtKLmBem4hkO4Zs410yfaDZ6rCOF5rVsbIZ4FErqNZI7yIDlounDRK8izrFy9MFNFxYhmsLbrxbEc9FsjfO+xU4R9/hveIyB0oN65KeyyDzRvMbXPjaN0+O8ZxyuIl760YQiZCRzQcxBp2hW+Nz9FnvMuQ1euY9lmJSMIjWiuedxHdX6KeIsoL3wkyuoRn5iRfQxaDeEvMc0jxPb4HuLomN+QAcSSSvqMdNvkfWiWRshSSzi/sCa4DcxnMSvfvZaP6EfQlr8fAPlPFSoGsKttAK9g5x8C+ZgvNdHS7fr+uCsDaN9nsge0Swc8heseld7hF34D2MSudjdj1RbIt/WOg8X6ob5DDl/WOuIeQwq5CyiCZ7ACdHs1iPp38b/ji7MR8YQJj0nmuPpL6KLVMkw9jyj8VQ0JyIkML/qeqzOP2IGatABO02hKGKt+jEMlMbQFDET/Y3XcLMoVEXMZAV4CjvTQlfETJiPOxDyAJmhLGLduS7ol6HpKRKIPxpLhg/VaKJ5myrDQQAAAABJRU5ErkJggg==',
                                            })
                                            registraRecordatorioNotificacionMostrada({
                                                reco_id: recordatorio.reco_id
                                            });
                                            invalidateQuery(["usuarios", "notificaciones"]);
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
            }

        }, 10 * 1000);
        setRecordatoriosInterval(interval);
    }, [recordatorios, notificaciones])

    const handleClickToggleNotificaciones = (e) => {
        e.stopPropagation();
        dispatch(setConfig({
            notificaciones: {
                visible: !notificacionConfig.visible
            }
        }))
    }

    const gotoPerfil = () => {
        history.push("/perfil");
    }

    const gotoMenuModulos = () => {
        history.push("/home_menu");
    }

    return !renderNavBar ? null : (
        <Header className={styles.navbar}>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Button
                    type="primary" shape="circle" icon={<TfiLayoutGrid3Alt />}
                    style={{ marginRight: 5, display: "flex", alignItems: "center", justifyContent: "center" }}
                    onClick={gotoMenuModulos}
                />
                <img src={logo} alt="logo" className="logo" />
                <img src={logoNombre} alt="logoNombre" className="logoNombre" />
                {/* <span>{appCaptions.navbarTitle}</span> */}
            </div>
            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <Popover
                    content={<div style={{ width: 400, display: "flex", flexDirection: "column", maxHeight: 400, overflowY: "auto" }}>
                        {
                            notificaciones?.map(noti => {
                                if (noti.noti_tipo == TipoNotificacion.RECORDATORIO)
                                    return <NotificacionRecordatorio
                                        key={noti.noti_id}
                                        notificacion={noti}
                                    />
                                else if (noti.noti_tipo == TipoNotificacion.INFORMACION)
                                    return <NotificacionInfo
                                        notificacion={noti}
                                        key={noti.noti_id}
                                    />
                            })
                        }
                    </div>}
                    overlayClassName="notifications-content"
                    title="Notificaciones"
                    open={notificacionConfig.visible}
                    placement="bottomRight"
                >
                    <div style={{ width: 40, display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={handleClickToggleNotificaciones}>
                        <Badge
                            dot={notificaciones?.length > 0}
                            style={{ border: "none", boxShadow: "0 0 0 1px #ff4d4f" }}
                        >
                            {
                                notificaciones?.length > 0 ?
                                    <BellFilled style={{ color: "#fff", fontSize: 20 }} className={notificaciones?.length > 0 ? "jingling" : ""} />
                                    :
                                    <BellOutlined style={{ color: "#fff", fontSize: 20 }} />
                            }
                        </Badge>
                    </div>
                </Popover >
                <Menu onClick={handleClick} selectedKeys={["mail"]} mode="horizontal" style={{ borderBottom: "none" }}>
                    {/* <Menu.Item key="mail" icon={<MailOutlined />} />
                <Menu.Item key="alipay" icon={<UserOutlined />} /> */}
                    {/* <Menu.Item key="app" disabled icon={<AppstoreOutlined />}>
                    Navigation Two
                </Menu.Item> */}
                    <SubMenu key="SubMenu" icon={<UserOutlined />} title="" className={styles.userMenuButton}>
                        <Menu.Item className={styles.menuOption} key="setting:1" onClick={gotoPerfil}>
                            <UserOutlined fontSize={16} />
                            Mi perfil
                        </Menu.Item>
                        <Menu.Item className={styles.menuOption} onClick={logout}>
                            <BiLogOut fontSize={16} />
                            Cerrar sesión
                        </Menu.Item>
                        {/* <Menu.ItemGroup title="Item 1">
                        <Menu.Item key="setting:2">Option 2</Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup title="Item 2">
                        <Menu.Item key="setting:3">Option 3</Menu.Item>
                        <Menu.Item key="setting:4">Option 4</Menu.Item>
                    </Menu.ItemGroup> */}
                    </SubMenu>
                </Menu>
            </div >
            {/* <span>Empresa en uso: aaa010101aaa</span> */}
        </Header >
    );
};

export default NavBar;
