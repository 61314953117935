import { Alert, Button, Form, Input, message, Modal, Popover, Row, Select, Space, Spin, Switch, Tabs, Tag, Typography, Image, Empty, InputNumber } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import SolidTabs from "../../../components/Tabs/SolidTabs";
import DatosContacto from "../DatosTicket/DatosContacto";
import DatosGenerales from "../DatosTicket/DatosGenerales";
import NotasTicket from "../DatosTicket/Notas";
import Observaciones from "../DatosTicket/Observaciones";
import Problematica from "../DatosTicket/Problematica";
import Seguimiento from "../DatosTicket/Seguimiento";
import { useHistory, useParams, Link } from "react-router-dom";
import { useApiTicketsId } from "../../../hooks/api/tickets";
import Timer from "../../../components/Timer";
import { useTicketsIncidenciasMutation } from "../../../hooks/api/tickets_incidencias";
import { useQueryUtils } from "../../../hooks/api/utils";
import { CheckOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { AnimatePresence, motion } from "framer-motion";
import { calculaTiempoAcuerdoTranscurrido, calculaTiempoTranscurridoServicio } from "../../../helpers/tickets.helper";
import useApi, { AvailableRequestContentTypes } from "../../../hooks/api/useApiRequest";
import { DateTime } from "luxon";
import KronAlert from "../../../components/CustomAlerts/KronAlert";
import { AiOutlineFile, AiOutlineFilePdf } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { Viewer } from "@react-pdf-viewer/core";
import { useModal } from "../../../hooks/useModal";
import { useSelector } from "react-redux";
import FileDropper from "../../../components/FileDropper";
import { objectToFormData } from "../../../helpers/form.helper";
import ReasignarTicket from "./ReasignarTicket";
import CapturaDatosFabricante from "./CapturaDatosFabricante";
import { TicketTipoComunicacion } from "../../../@enums/TicketsIncidencias.enums";
import { TicketStatus, TicketTipoProceso } from "../../../@enums/Tickets.enums";
import { TicketContext } from "../ticket.context";
import { TipoTicketAgente } from "../../../@enums/TicketsAgentes.enums";
import { Manager } from "socket.io-client";
import DividirTicketParams from "./DividirTicketParams";
import TicketDatosNuevpCliente from "../TicketDatosNuevoCliente";
import CierreTicketParams from "./CierreTicketParams";
import { useCreateKnowBaseItem } from "../../../hooks/api/knowBase";
import { IoTicketOutline } from "react-icons/io5";
import { BiBrain } from "react-icons/bi";
import TicketsKnowBaseTab from "./TicketsKnowBaseTab";
import { setPageScrollTop } from "../../../helpers/scroll";
import { FaCodeBranch } from "react-icons/fa6";
import Marketing from "../Marketing";
import Agentes from "../Agentes";

const useStyles = createUseStyles(theme => ({
    container: {
        display: "flex",
    },
    ticketInfoSider: {
        width: 500,
        // border: "1px solid #eee",
        overflowX: "hidden",
        overflowY: "auto",
        background: "#fff",
        margin: "10px 15px 10px 0",
        borderRadius: 8,
        height: "fit-content",
        boxShadow: "rgba(100, 100, 111, 0.07) 0px 10px 29px 0px",
    },
    timerAcuerdo: {
        fontSize: 22,
    },
    timerGeneral: {
        fontSize: 16,
        marginRight: 0,
        marginTop: 0,
        color: theme.secondaryColor
    },
    comunicationForm: {
        "& .ant-input-disabled,.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
            backgroundColor: "#fff",
            background: "#fff",
            color: "#888"
        }
    },
    text: {
        width: "100%",
        marginTop: 5,
        height: "120px",
        overflowY: "auto",
    },
    popOverArchivos: {
        "& .ant-popover-inner-content > div": {
            display: "flex",
            gap: 10,
            flexWrap: "wrap"
        }
    },
    popoverContent: {
        width: 400,
        maxWidth: 400,
    },
    fileItem: {
        flex: 1,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #eee",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.03)",
            border: `1px solid ${theme.secondaryColor}`,
        }
    },
    popoverHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px",
        "& .ant-typography": {
            margin: 0,
        }
    },
    tabs: {
        "& .ant-tabs-nav": {
            marginBottom: 20
        },
        "& .ant-tabs-tab": {
            margin: 0
        }
    }
}));

const alertMotionConfig = {
    transition: {
        ease: "backInOut",
        // ease: "anticipate",
        duration: 0.3,
    },
    variants: {
        in: {
            opacity: 1,
            y: 0,
        },
        out: {
            opacity: 0,
            y: "-30px",
        },
    },
}

const ArchivoItem = ({ type, filename, src }) => {
    const styles = useStyles();
    const [show, showModal, closeModal] = useModal();

    const getIco = () => {
        if (type === "image/png" || type === "image/jpeg") {
            return <Image
                width={150}
                src={src}
            />
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return <SiMicrosoftexcel fontSize={32} color="#218450" />
        } else if (type === "application/pdf") {
            return <AiOutlineFilePdf fontSize={32} color="#c20a0a" />
        } else {
            return <AiOutlineFile fontSize={32} color="#888" />
        }
    }

    const getPreviewComponent = () => {
        if (type === "image/png" || type === "image/jpeg") {
            return <Image
                width={150}
                src={src}
            />
        } else if (type === "application/vnd.ms-excel" || type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return <div>Previsualizador no disponible para este tipo de archivo</div>
        } else if (type === "application/pdf") {
            return <Viewer fileUrl={src} />
        } else {
            return <div>Previsualizador no disponible para este tipo de archivo</div>
        }
    }

    const handleClickVisualizaRecurso = () => {
        if (type !== "image/png" && type !== "image/jpeg")
            showModal();
    }

    return <div>
        <div className={styles.fileItem} onClick={handleClickVisualizaRecurso}>
            {getIco()}
            <Typography.Title level={5} style={{ margin: 0 }}>{filename}</Typography.Title>

        </div>
        <Modal footer={null} title={filename} visible={show} onOk={closeModal} onCancel={closeModal}>
            <div
                style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "750px",
                }}
            >
                {
                    getPreviewComponent()
                }
            </div>
        </Modal>
    </div>
}

const TicketPage = () => {
    const theme = useTheme();
    const styles = useStyles();
    const [form] = Form.useForm();
    const [formRegistroBaseConocimiento] = Form.useForm();
    const { id } = useParams();
    const api = useApi();
    const { apiHost, sToken, ticketsQueue, user } = useSelector((state) => ({
        apiHost: state.app.apiHost,
        ticketsQueue: state.app.ticketsQueue,
        sToken: state.auth.token,
        user: state.auth.user
    }));
    const ioManager = useRef(null);
    const socketQueue = useRef(null);
    const history = useHistory();
    const [dividirTicketParams, setDividirTicketParams] = useState({
        mesaservicio: null,
        aniadirobservador: false,
        tick_tipo: 1
    });
    const [isTicketDivided, setIsTicketDivided] = useState(false);
    const [subiendoArchivos, setSubiendoArchivos] = useState(false);
    const [responsableActivo, setResponsableActivo] = useState(null);
    const [timerAcuerdoColor, setTimerAcuerdoColor] = useState(null);
    const tituloInputRef = useRef(null);
    const { invalidateQuery } = useQueryUtils();
    const [incidenciaRecursos, setIncidenciaRecursos] = useState([]);
    const [ticket, setTicket] = useState(null);
    const [seguimientoSeleccionado, setSeguimientoSeleccionado] = useState(null);
    const [initialSecondsTime, setInitialSecondsTime] = useState(null);
    const [servicioInitialSecondsTime, setServicioInitialSecondsTime] = useState(0);
    const [incidenciaAfectaTiempo, setIncidenciaAfectaTiempo] = useState(null);
    const [currentComunicacionOptions, setCurrentComunicacionOptions] = useState([]);
    const [ultimoEstatusComunicacion, setUltimoEstatusComunicacion] = useState(2);
    const [ticketAcuerdoActivo, setTicketAcuerdoActivo] = useState(null);
    const [activeTab, setActiveTab] = useState("ticket_inciencia");
    const [cerrandoTicketVisible, showCerrandoTicket, closeCerrandoTicket] = useModal();
    const [reasignarTicketVisible, showReasignarTicket, closeReasignarTicket] = useModal();
    const [datosFabricanteVisible, showDatosFabricante, closeDatosFabricante] = useModal();
    const [dividirTicketParamsVisible, showDividirTicketParams, closeDividirTicketParams] = useModal();
    const { data: apiTicket, isLoading: isLoadingTicket, status: ticketReqStatus, refetch: refetchTicket } = useApiTicketsId({
        initialData: null,
        refetchInterval: 10000,
        refetchIntervalInBackground: true
    },
        id
    );
    const { isLoadingTicketsIncidenciasMutation, mutateTicketsIncidencias, resetTicketsIncidenciasMutation } = useTicketsIncidenciasMutation({ id });
    const { createKnowBaseItem } = useCreateKnowBaseItem();
    const statusTicketCerrado = useMemo(() => [4, 5, 6], []); // cerrado sin exito , con exito o cerrado por el cliente
    const blockedOptions = [
        {
            value: 3,
            label: "En espera del cliente", // equivalente al de seguimiento
            color: "magenta"
        },
    ];
    const multiOptions = [
        {
            value: 2,
            label: "Abierto", // equivalente al de seguimiento
            color: "magenta"
        },
        {
            value: 3,
            label: "En espera del cliente",
            color: "magenta"
        },
        {
            value: 4,
            label: "Cerrado con éxito",
            color: "green"
        },
        {
            value: 5,
            label: "Cerrado por el cliente",
            color: "cyan"
        },
        {
            value: 6,
            label: "Cerrado sin éxito",
            color: "red"
        },
        {
            value: 7,
            label: "En espera del fabricante",
            color: "volcano"
        }
    ];

    /**
     * Guarda una incidencia del ticket
     * @param {Object} values - valores del formulario para guardar la incidencia 
     * @returns {Promise<boolean>}
     */
    const guardaIncidencia = (values) => {
        return new Promise((resolve, reject) => {
            let params = {
                mutationType: "create",
                tick_id: apiTicket?.tick_id,
                incidencias: {
                    tick_id: apiTicket?.tick_id,
                    tipi_id: 3, // comunicacion
                    tici_observaciones: values.anotaciones,
                    tici_comunicacion: values.comunicacion,
                    tici_nombre: values.titulo,
                    tici_importante: values.importante ? 1 : 0,
                    tici_visiblecliente: values.visible ? 1 : 0,
                    tici_ticketdividido: values.dividir ? 1 : 0,
                    stat_id: ultimoEstatusComunicacion,

                    // parametros de la incidencia para dividir el ticket
                    mesaservicio: dividirTicketParams?.mesaservicio || apiTicket?.ade_id,
                    aniadirobservador: dividirTicketParams?.aniadirobservador,
                    tick_tipo: dividirTicketParams?.tick_tipo
                }
            }

            // se valida si el tipo de comunicacion va por nota
            if (values.comunicacion == TicketTipoComunicacion.NOTA) {
                params.incidencias.tici_visto = 1;
            }

            // se valida si se ha seleccionado un item de la base de conocimiento
            if (values.kbit_id) {
                params.incidencias.kbit_id = values.kbit_id;
            }

            // se preparan los datos de la incidencias de seguimiento
            params.incidencias = objectToFormData(params.incidencias);
            // // se preparan los archivos de la incidencia
            let tFiles = [...incidenciaRecursos];
            for (let file of tFiles) {
                params.incidencias.append("files[]", file, file.originFileObj || file.name);
            }


            mutateTicketsIncidencias(
                params,
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            message.success("Se agregó la incidencia.", 2);
                            resetTicketsIncidenciasMutation();
                            invalidateQuery(["tickets", `${id}`])
                            setIncidenciaRecursos([])
                            // queryClient.invalidateQueries(["ticket", options?.id, "seguimiento"]);
                            nuevoSeguimiento();
                            resolve(true);
                        } else {
                            message.error(res.mensaje);
                            reject(res.mensaje)
                        }
                    },
                    onError: (err) => {
                        if (err?.response?.status && err?.response?.status === 413) {
                            message.error("Límite de tamaño de solicitud excedido");
                            reject("Límite de tamaño de solicitud excedido")
                        } else {
                            message.error("Ocurrio un problema al guardar la incidencia");
                            reject("Ocurrio un problema al guardar la incidencia")
                        }

                    },
                }
            );

        });
    }

    const isValidDatosFabricante = () => {
        let gruposIncidenciasFabricante = [];
        let grupo = [];
        // se generan los grupos de incidencias por fabricante
        apiTicket.incidencias.forEach(incidencia => {
            // se valida que la incidencia tenga un status de espera fabricante
            if (incidencia.stat_id === TicketStatus.ESPERA_FABRICANTE) {
                grupo.push(incidencia);
            } else {
                if (grupo.length > 0) {
                    gruposIncidenciasFabricante.push(grupo);
                    grupo = [];
                }
            }
        });
        // se valida que el ultimo grupo se agregue
        if (grupo.length > 0) {
            gruposIncidenciasFabricante.push(grupo);
        }

        // se valida que haya grupos de incidencias
        if (gruposIncidenciasFabricante.length > 0) {
            // se valida que cada grupo tenga incidencia de asesor y fabricante
            let isValid = true;
            gruposIncidenciasFabricante.forEach(grupo => {
                // se busca la incidencia de asesor
                let incidenciaAsesor = grupo.find(incidencia => incidencia.tici_nombre !== "Asignación de ticket por fabricante");
                // se busca la incidencia de fabricante
                let incidenciaFabricante = grupo.find(incidencia => incidencia.tici_nombre === "Asignación de ticket por fabricante");
                if (!incidenciaAsesor || !incidenciaFabricante) {
                    isValid = false;
                }
            })
            return isValid;
        } else {
            return true;
        }
    }

    const cerrarTicket = async (kbit_id = null) => {
        let incidencia = form.getFieldsValue();
        let datosRegistroBaseConocimiento = formRegistroBaseConocimiento.getFieldsValue();

        // se valida si hay un registro de base de conocimiento seleccionado
        if (kbit_id)
            incidencia.kbit_id = kbit_id;

        try {
            let isIncidenceSaved = await guardaIncidencia(incidencia);
            if (isIncidenceSaved && !kbit_id) {
                // se valida si se va a crear un registro de base de conocimiento
                if (datosRegistroBaseConocimiento?.kbit_sintomas) {
                    // se crea el registro de base de conocimiento
                    createKnowBaseItem(
                        {
                            kbit_sintomas: datosRegistroBaseConocimiento.kbit_sintomas,
                            kbit_solucion: datosRegistroBaseConocimiento.kbit_solucion,
                            kbit_titulo: datosRegistroBaseConocimiento.kbit_titulo,
                            categories: datosRegistroBaseConocimiento.categorias,
                            kbit_problematica: datosRegistroBaseConocimiento.kbit_problematica,
                            tick_id: ticket.tick_id,
                        },
                        {
                            onSuccess: (res) => {
                                if (res.success) {
                                    message.success("Registro de base de conocimiento creado");
                                    // closeCerrandoTicket();
                                    history.push("/tickets");
                                } else {
                                    message.error("Hubo un problema al crear el registro de base de conocimiento");
                                }
                            },
                            onError: (err) => {
                                message.error("Hubo un problema al crear el registro de base de conocimiento");
                            }
                        }
                    );
                } else {
                    message.success("Se ha cerrado el ticket");
                    history.push("/tickets");
                }
            } else if (isIncidenceSaved && kbit_id) {
                // closeCerrandoTicket();
                history.push("/tickets");
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleClickEnviar = () => {
        form.validateFields()
            .then((values) => {
                // se valida si el ticket se esta cerrando
                // [4,5,6] - status que cierran el ticket
                if (statusTicketCerrado.includes(ultimoEstatusComunicacion)) {
                    showCerrandoTicket();
                } else if (ultimoEstatusComunicacion === TicketStatus.ESPERA_FABRICANTE) { // estatus en fabricante
                    guardaIncidencia(values);
                    if (!isValidDatosFabricante()) {
                        showDatosFabricante();
                    }
                } else {
                    guardaIncidencia(values)
                }
            })
            .catch(() => {
                message.error("Aún faltan campos requeridos.");
            });
    };

    const handleChangeComunicacion = (value) => {
        if (value !== 1) {
            setCurrentComunicacionOptions(multiOptions)
        } else {
            setCurrentComunicacionOptions(blockedOptions)
        }
    };

    const handleChangeStatusComunicacion = (value) => {
        setUltimoEstatusComunicacion(value)
    }

    const openMessageTicketCaducado = () => {
        message.info({
            content: "El ticket ha caducado",
            key: "caducaTicket"
        });
    }

    const caducarTicket = (tick_id) => {
        openMessageTicketCaducado()
        api.put(`/tickets/${tick_id}/caducar`, {}, { dataType: "json" }).then((res) => {
            if (res.data.success) {
                refetchTicket();
            }
            // else message.error({
            //     content: "Hubo un problema al caducar el ticket",
            //     key: "caducaTicket"
            // });
        }).catch((err) => {
            console.log(err)
        });
    }

    const handleTicketExceededTime = (ticket) => {
        if (ticket?.stat_id !== 9)
            caducarTicket(ticket.tick_id);
    }

    const getTagStatusColor = (stat_id) => {
        let color = undefined;
        if (stat_id === 9) {
            color = "red"
        } else {
            color = multiOptions.find(status => status.value === stat_id).color
        }
        return color;
    }
    const nuevoSeguimiento = () => {
        form.setFieldsValue(
            {
                visible: true,
                importante: false,
                dividir: false,
                anotaciones: null,
                comunicacion: 5,
                estatus: 2,
                titulo: null,
            }
        )
        setDividirTicketParams({
            mesaservicio: apiTicket.ade_id,
            aniadirobservador: false,
            tick_tipo: 1
        })

        setSeguimientoSeleccionado(null);
        setIncidenciaRecursos([]);
        setTimeout(() => {
            if (tituloInputRef)
                tituloInputRef.current?.focus({
                    cursor: 'start',
                });
        }, 100);
        setUltimoEstatusComunicacion(TicketStatus.TICKET_SEGUIMIENTO);
    }

    const handleClickNuevoSeguimiento = () => {
        nuevoSeguimiento();
        setPageScrollTop();
        setActiveTab("ticket_inciencia");
    }

    const handleChangeFiles = (fileList) => {
        setIncidenciaRecursos([...incidenciaRecursos, ...fileList])
    }

    const popOverFilesContent = (<div className={styles.popoverContent}>
        {
            !subiendoArchivos && incidenciaRecursos.length === 0 &&
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                <Empty description="No hay archivos adjuntos" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
        }
        {
            !subiendoArchivos &&
            <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                {incidenciaRecursos?.map((recurso, iRec) =>
                    <ArchivoItem
                        key={`${iRec}_${recurso?.recu_id || recurso?.name}_recurso`}
                        type={recurso?.recu_mime || recurso?.type}
                        filename={recurso?.recu_nombre || recurso?.name}
                        src={`${apiHost}/static/${recurso.recu_ruta}`} />
                )}
            </div>
        }
        {
            subiendoArchivos &&
            <FileDropper
                onChange={handleChangeFiles}
                limit={5}
                allowedExtensions={["xlsx", "xls", "doc", "docx", "pdf", "jpeg", "jpg", "png", "gif",]}
                maxFileSize={10}
            />
        }
    </div>)

    const subirArchivos = () => {
        if (seguimientoSeleccionado && subiendoArchivos) {
            // se suben los nuevo archivos de la incidencia
            if (incidenciaRecursos.length > 0) {
                let formData = new FormData();
                incidenciaRecursos.forEach((recurso) => {
                    if (!recurso.recu_id) {
                        console.log("subiendo recurso", recurso);
                        formData.append("files[]", recurso, recurso.originFileObj || recurso.name);
                    }
                })
                api.post(`/tickets_incidencias/${seguimientoSeleccionado.tici_id}/recursos`, formData, {
                    dataType: AvailableRequestContentTypes.MULTIPART,
                    hasFormData: true
                }).then((res) => {
                    if (res?.data) {
                        getRecursosIncidencia(seguimientoSeleccionado.tici_id);
                        // se valida que la respuesta haya sido exitosa
                        if (res.data.success) {
                            message.success("Archivos subidos correctamente")
                        } else {
                            message.error("Hubo un problema al subir los archivos")
                        }
                    }
                }).catch((err) => {
                    console.log(err)
                });
            }
        }
        setSubiendoArchivos(!subiendoArchivos)
    }

    const popOverFilesHeader = (<div className={styles.popoverHeader}>
        <Typography.Title level={4}>Archivos adjuntos</Typography.Title>
        <Button icon={subiendoArchivos ? <CheckOutlined /> : <PlusOutlined />} shape="circle" onClick={subirArchivos} />
    </div>)

    const getRecursosIncidencia = (tici_id) => {
        api.get(`/tickets_incidencias/${tici_id}/recursos`).then((res) => {
            if (res?.data) {
                setIncidenciaRecursos(res.data);
            } else {
                setIncidenciaRecursos([]);
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    const handleChangeTimerAcuerdoColor = (color) => {
        setTimerAcuerdoColor(color)
    }

    const handleChangeDividirTicket = (checked) => {
        if (checked) {
            setDividirTicketParams({
                mesaservicio: apiTicket?.ade_id,
                aniadirobservador: false,
                tick_tipo: 1
            })
            showDividirTicketParams();
        } else
            setDividirTicketParams({
                aniadirobservador: false,
                mesaservicio: null,
                tick_tipo: 1
            })
    }

    const handleSetParamsDividirTicket = (data) => {
        setDividirTicketParams(data);
    }

    useEffect(() => {
        if (apiTicket) {
            setTicket(apiTicket);
            let { segundosTranscurridos, ultimaIncidenciaAfectaTiempo } = calculaTiempoAcuerdoTranscurrido(apiTicket.incidencias)
            let { segundosTranscurridosServicio } = calculaTiempoTranscurridoServicio(apiTicket.incidencias)
            setServicioInitialSecondsTime(segundosTranscurridosServicio)
            setInitialSecondsTime(segundosTranscurridos);
            setIncidenciaAfectaTiempo(ultimaIncidenciaAfectaTiempo)

            if (apiTicket?.acuerdos_servicio) {
                // se busca el  acuerdo de servicio  activo
                let acu = apiTicket?.acuerdos_servicio?.find(acu => acu?.tias_activo)
                if (acu) {
                    setTicketAcuerdoActivo(acu.acuerdo_servicio)
                }
            }

            // se busca el responsable activo
            let responsable = apiTicket.agentes?.find(age => age.tiag_id === TipoTicketAgente.RESPONSABLE && age.tica_usumodificaticket);
            setResponsableActivo(responsable || null);

            if (!isValidDatosFabricante()) {
                showDatosFabricante();
            } else {
                closeDatosFabricante();
            }

            if (apiTicket?.tick_clave && !socketQueue.current) {
                ioManager.current = new Manager(`${ticketsQueue}/socket.io/socket.io.js`, {
                    extraHeaders: {
                        authorization: `Bearer ${sToken}`,
                        ticket: apiTicket.tick_clave
                    },
                });
                socketQueue.current = ioManager.current.socket("/");
                socketQueue.current.emit("get-tickets-queue", {});
            }

            // verify if ticket is divided
            if (apiTicket?.tick_padreid) {
                setIsTicketDivided(true);
            } else {
                setIsTicketDivided(false);
            }
        }
    }, [apiTicket, statusTicketCerrado]);

    useEffect(() => {
        setCurrentComunicacionOptions(multiOptions);
        form.setFieldsValue({
            visible: true,
            importante: false,
            dividir: false,
            anotaciones: null,
            comunicacion: 5,
            estatus: 2,
            titulo: null,
        });
        tituloInputRef.current?.focus({
            cursor: 'start',
        });

        return () => {
            if (socketQueue.current) socketQueue.current.disconnect(2);
        }
    }, []);

    useEffect(() => {
        if (seguimientoSeleccionado) {
            form.setFieldsValue({
                visible: seguimientoSeleccionado.tici_visiblecliente === 1,
                importante: seguimientoSeleccionado.tici_importante === 1,
                dividir: seguimientoSeleccionado.tici_ticketdividido === 1,
                anotaciones: seguimientoSeleccionado.tici_observaciones,
                comunicacion: seguimientoSeleccionado.tici_comunicacion,
                estatus: seguimientoSeleccionado.stat_id,
                titulo: seguimientoSeleccionado.tici_nombre,
            });
            getRecursosIncidencia(seguimientoSeleccionado.tici_id);
        }
    }, [seguimientoSeleccionado, form])

    useEffect(() => {
        if (ticketReqStatus === "error") {
            history.push("/tickets");
        }
    }, [ticketReqStatus, history])

    return (
        <TicketContext.Provider value={{
            ticket,
            initialSecondsTime,
            setInitialSecondsTime,
            closeReasignarTicket,
            refetchTicket,
        }}>
            {/* modal de parametros de cierre del ticket */}
            <Modal
                title="Cerrando ticket"
                open={cerrandoTicketVisible}
                // open={true}
                onCancel={closeCerrandoTicket}
                footer={null}
                destroyOnClose
                width={800}
            >
                <CierreTicketParams
                    closeParent={closeCerrandoTicket}
                    cerrarTicket={cerrarTicket}
                    formRegistroBaseConocimiento={formRegistroBaseConocimiento}
                />
            </Modal>
            {/* modal reasignar ticket start */}
            <Modal
                title="Reasignar ticket"
                open={reasignarTicketVisible}
                onCancel={closeReasignarTicket}
                footer={null}
                destroyOnClose
            >
                <ReasignarTicket />
            </Modal>
            {/* modal reasignar ticket end */}
            {/* modal captura datos fabricante start */}
            <Modal
                title="Capturar datos de fabricante"
                open={datosFabricanteVisible}
                // onCancel={closeDatosFabricante}
                footer={null}
                destroyOnClose
                closable={false}
            >
                <CapturaDatosFabricante closeDatosFabricante={closeDatosFabricante} ticket={ticket} refetchTicket={refetchTicket} />
            </Modal>
            {/* modal dividir ticket start */}
            <Modal
                title="Parámetros para dividir ticket"
                open={dividirTicketParamsVisible}
                onCancel={closeDividirTicketParams}
                footer={null}
                destroyOnClose
                closable={false}
                maskClosable={false}
            >
                <DividirTicketParams closeParent={closeDividirTicketParams} onDataCached={handleSetParamsDividirTicket} defaultParams={dividirTicketParams} />
            </Modal>
            {/* modal dividir ticket end */}
            {/* modal captura datos fabricante start */}
            <div className={styles.container}>
                <div style={{ width: "100%" }}>
                    <Row style={{ justifyContent: "flex-end" }}>
                        <AnimatePresence  >
                            {
                                statusTicketCerrado.includes(apiTicket?.stat_id) &&
                                <motion.div style={{ width: "100%", padding: "20px 40px 0px 0px" }} {...alertMotionConfig} >
                                    <Alert message="El ticket está cerrado" type="info" showIcon style={{ width: "100%", margin: 20 }} />
                                </motion.div>
                            }
                            {
                                apiTicket?.stat_id === 9 &&
                                <motion.div style={{ width: "100%", padding: "20px 40px 0px 0px" }} {...alertMotionConfig} >
                                    <KronAlert
                                        text="El ticket ha caducado"
                                        type="error"
                                        style={{ width: "100%", margin: 20 }}
                                        rightContent={<div>
                                            {
                                                !ticket?.tick_bloqueado ||
                                                (responsableActivo && responsableActivo?.usu_id === user?.usu_id) &&
                                                <Button type="primary" size="small" onClick={showReasignarTicket}>Reasignar</Button>
                                            }
                                        </div>}
                                    />
                                </motion.div>
                            }
                            {
                                incidenciaAfectaTiempo && incidenciaAfectaTiempo?.stat_id !== 2 && apiTicket?.stat_id !== 2 && apiTicket?.stat_id !== 9 &&
                                <motion.div style={{ width: "100%", padding: "20px 20px 0px 0px", display: "flex", justifyContent: "end" }} {...alertMotionConfig} >
                                    <Tag color={getTagStatusColor(incidenciaAfectaTiempo?.stat_id)} style={{ fontSize: 16, padding: 16 }}>{ticket.status.stat_nombre}</Tag>
                                </motion.div>
                            }
                        </AnimatePresence>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "end", paddingRight: 20 }}>
                            {!statusTicketCerrado.includes(apiTicket?.stat_id) && incidenciaAfectaTiempo?.stat_id === 2 && initialSecondsTime > 0 && (
                                <Timer
                                    initialSecondsTime={initialSecondsTime}
                                    className={styles.timerAcuerdo}
                                    blinkMinutesTime={ticketAcuerdoActivo?.cacu_minutos - 5}
                                    timeLimit={ticketAcuerdoActivo?.cacu_minutos * 60}
                                    onTimeLimitExceeded={() => handleTicketExceededTime(ticket)}
                                    countTimeOverlimit={true}
                                    changeColor
                                    onChange={handleChangeTimerAcuerdoColor}
                                    renderLeftContent={() => (
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginLeft: 10 }}>
                                            <Tag color={timerAcuerdoColor}>Acuerdo de servicio</Tag>
                                        </div>
                                    )}
                                    timerContext={TicketContext}
                                />
                            )}
                            {!statusTicketCerrado.includes(apiTicket?.stat_id) && incidenciaAfectaTiempo?.stat_id === 2 && (
                                <div>
                                    <Timer
                                        initialSecondsTime={servicioInitialSecondsTime}
                                        className={styles.timerGeneral}
                                        countTimeOverlimit={true}
                                        renderLeftContent={() => (
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
                                                <Tag color={theme.secondaryColor} style={{ fontSize: "8pt" }}>Servicio</Tag>
                                            </div>
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </Row>
                    <Row>
                        <div style={{
                            border: "1px solid rgba(100, 100, 111, 0.07)",
                            borderRadius: 10,
                            width: "100%",
                            margin: "10px 20px",
                            padding: 20,
                            background: "#FFF",
                            boxShadow: "rgba(100, 100, 111, 0.07) 0px 10px 29px 0px"
                        }}
                        >
                            <Tabs activeKey={activeTab} type="card" className={styles.tabs} onChange={(tab) => setActiveTab(tab)}>
                                <Tabs.TabPane tab={
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 7, padding: "0 10px" }}>
                                        <IoTicketOutline />
                                        Ticket incidencia
                                    </div>
                                } key="ticket_inciencia" >
                                    <Spin spinning={isLoadingTicketsIncidenciasMutation}>
                                        {
                                            seguimientoSeleccionado &&
                                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 10, borderBottom: "1px solid #eee", paddingBottom: 10 }}>
                                                <div style={{ display: "flex", gap: 5 }}>
                                                    <Tag style={{ padding: "5px 10px", borderRadius: 20 }} color="blue">
                                                        <span style={{ fontWeight: "bold" }}>Fecha de registro: </span>
                                                        {DateTime.fromISO(seguimientoSeleccionado?.tici_fecha).plus({ hours: 1 }).toFormat("dd/MM/yyyy HH:mm:ss")}
                                                    </Tag>
                                                    <Popover placement="bottomLeft" title={popOverFilesHeader} content={popOverFilesContent} trigger="click" overlayClassName={styles.popOverArchivos}>
                                                        <Button size="middle" type="primary" shape="circle" icon={<PaperClipOutlined />} />
                                                    </Popover>
                                                </div>
                                                <Button size="middle" type="primary" onClick={handleClickNuevoSeguimiento}>
                                                    Crear nuevo
                                                </Button>
                                            </div>
                                        }

                                        <Space size={20} style={{ width: "100%", flexDirection: "column" }}>
                                            <Form style={{ width: "100%" }} form={form} disabled={!!seguimientoSeleccionado || statusTicketCerrado.includes(apiTicket?.stat_id) || (ticket?.tick_bloqueado && responsableActivo?.usu_id !== user?.usu_id)} className={styles.comunicationForm}>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Space size={20} style={{ width: "100%" }}>
                                                        <Form.Item
                                                            label="Comunicación"
                                                            name="comunicacion"
                                                            rules={[{ required: true, message: "El campo comunicación es requerido" }]}
                                                        >
                                                            <Select
                                                                placeholder
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                style={{ width: "100%" }}
                                                                onChange={handleChangeComunicacion}
                                                            >
                                                                <Select.Option value={1}>Nota</Select.Option>
                                                                <Select.Option value={2}>Correo Entrante</Select.Option>
                                                                <Select.Option value={3}>Correo Saliente</Select.Option>
                                                                <Select.Option value={4}>Llamada Entrante</Select.Option>
                                                                <Select.Option value={5}>Llamada Saliente</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Título"
                                                            name="titulo"
                                                            rules={[
                                                                { required: true, message: "El campo Título es requerido" },
                                                                { max: 255,  message: "Máximo 255 caracteres"}
                                                            ]}
                                                        >
                                                            <Input tabIndex={1} ref={tituloInputRef} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Estatus"
                                                            name="estatus"
                                                            rules={[{ required: true, message: "El campo estatus es requerido" }]}
                                                        >
                                                            <Select
                                                                placeholder
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                style={{ width: "100%" }}
                                                                disabled={form.getFieldValue("comunicacion") === 1}
                                                                onChange={handleChangeStatusComunicacion}
                                                            >
                                                                {currentComunicacionOptions.map((opt) => (
                                                                    <Select.Option value={opt.value} key={`comunicacion_option_${opt.value}_${opt.label}`}>{opt.label}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Space>
                                                </Row>
                                                <Row>
                                                    <Form.Item
                                                        name="anotaciones"
                                                        style={{ width: "100%" }}
                                                        rules={[{ required: true, message: "El campo Anotaciones es requerido" }]}
                                                    >
                                                        <Input.TextArea
                                                            placeholder="Anotaciones"
                                                            rows={3}
                                                            style={{ border: "1px solid #ddd", width: "100%" }}
                                                            showCount
                                                            tabIndex={2}
                                                        />
                                                    </Form.Item>
                                                </Row>
                                                <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                                                    <div style={{ gap: 16, display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                        <Form.Item valuePropName="checked" label="Visible" name="visible">
                                                            <Switch />
                                                        </Form.Item>
                                                        <Form.Item valuePropName="checked" label="Importante" name="importante">
                                                            <Switch />
                                                        </Form.Item>
                                                        <Form.Item valuePropName="checked" label="Dividir" name="dividir">
                                                            <Switch onChange={handleChangeDividirTicket} />
                                                        </Form.Item>
                                                        {
                                                            !seguimientoSeleccionado &&
                                                            <Popover placement="bottomLeft" title={popOverFilesHeader} content={popOverFilesContent} trigger="click" overlayClassName={styles.popOverArchivos} key="popover-adjuntos">
                                                                <Button type="dashed" style={{ alignSelf: "start", marginLeft: 15 }} icon={<PaperClipOutlined />} >Adjuntos</Button>
                                                            </Popover>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            !seguimientoSeleccionado &&
                                                            <Button size="large" type="primary" onClick={handleClickEnviar} key="btn-enviar-seguimiento">
                                                                Enviar
                                                            </Button>
                                                        }
                                                    </div>
                                                </Row>
                                            </Form>
                                        </Space>
                                    </Spin>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 7, padding: "0 10px", margin: 0 }}>
                                        <BiBrain style={{ fontSize: 15 }} />
                                        Base de conocimiento
                                    </div>
                                } key="knowbase-items">
                                    <TicketsKnowBaseTab redirectToItemPage={true} />
                                </Tabs.TabPane>
                            </Tabs>



                        </div>
                    </Row>
                </div>

                <div className={styles.ticketInfoSider}>
                    <Spin spinning={isLoadingTicket}>
                        <Row style={{ justifyContent: "space-between", alignItems: "center", padding: "10px 10px 10px 0" }}>
                            <div
                                style={{ display: "flex", alignItems: "center", padding: "5px 10px", gap: 5 }}
                            >
                                <Typography.Title level={4} style={{ margin: 0 }}>
                                    Ticket
                                </Typography.Title>
                                <Typography.Title level={4} style={{ color: "#1890FF", margin: 0 }}>
                                    {ticket?.tick_clave}
                                </Typography.Title>
                                {
                                    isTicketDivided &&
                                    <Popover
                                        placement="bottomRight"
                                        content={
                                            <div 
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography.Text style={{ fontWeight: "bold" }}>Ticket padre</Typography.Text>
                                                <Link 
                                                    to={`/tickets/${ticket?.ticket_padre?.tick_id}`}
                                                >{ticket?.ticket_padre?.tick_clave}</Link>
                                            </div>
                                        }
                                    >
                                        <FaCodeBranch
                                            style={{
                                                transform: "scale(-1, 1) rotate(180deg)",
                                                fontSize: 16,
                                                color: "#ee331199",
                                            }}
                                        />
                                    </Popover>
                                }
                            </div>
                            {
                                (
                                    ticket?.stat_id === TicketStatus.ESPERA_FABRICANTE ||
                                    ticket?.stat_id === TicketStatus.ESPERA_CLIENTE ||
                                    ticket?.stat_id === TicketStatus.TICKET_SEGUIMIENTO
                                ) &&
                                (responsableActivo && responsableActivo?.usu_id === user?.usu_id)
                                && !ticket?.tick_bloqueado &&
                                <Button type="primary" onClick={showReasignarTicket} key={"btn-show-reasignar-ticket"}>Reasignar</Button>
                            }
                        </Row>
                        <Row>
                            <Tabs defaultActiveKey="1" type="card" size="small" style={{ width: "100%" }} className={styles.tabsPanel}>
                                <Tabs.TabPane tab="Ticket" key="1">
                                    <SolidTabs
                                        tabs={[
                                            {
                                                key: 1,
                                                text: "1",
                                                content: (
                                                    <div>
                                                        <Problematica
                                                            ticket={ticket}
                                                        />
                                                        <DatosContacto
                                                            empresa={ticket?.empresa}
                                                            contacto={
                                                                ticket?.agentes.find((agente) => agente.tipoAgente.tiag_nombre === "Contacto")?.agente
                                                            }
                                                            ticket={ticket}
                                                        />
                                                        <NotasTicket tickId={ticket?.tick_id} />
                                                    </div>
                                                ),
                                            },
                                            {
                                                key: 2,
                                                text: "2",
                                                content: (
                                                    <div>
                                                        <Observaciones ticket={ticket} />
                                                        <DatosGenerales ticket={ticket} />
                                                        <Seguimiento
                                                            ticket={ticket}
                                                            setSeguimientoSeleccionado={setSeguimientoSeleccionado}
                                                            handleClickNuevoSeguimiento={handleClickNuevoSeguimiento}
                                                        />
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        initialActiveKey={2}
                                    />
                                </Tabs.TabPane>
                                {
                                    ticket?.tick_tipoproceso === TicketTipoProceso.REGISTRO_NUEVO_CLIENTE &&
                                    <Tabs.TabPane
                                        tab="Cliente"
                                        key="2"
                                    >
                                        <TicketDatosNuevpCliente ticket={ticket} />
                                    </Tabs.TabPane>
                                }
                                <Tabs.TabPane tab="Marketing" key="3">
                                    <Marketing  key={'Marketing_section'} ticket={ticket}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Agentes" key="4">
                                    <Agentes  key={'Agentes_section'} agentes={ticket?.agentes} idVendedor={ticket?.empresa?.emp_vendedor}/>
                                </Tabs.TabPane>
                            </Tabs>
                        </Row>
                    </Spin>
                </div>
            </div>
        </TicketContext.Provider>
    );
};

export default TicketPage;
