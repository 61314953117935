import { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { CheckCircleOutlined, CloseCircleFilled, WarningFilled, WarningOutlined } from "@ant-design/icons";


const KronAlert = ({ icon, type, text, style, rightContent }) => {
    const theme = useTheme();
    const [currentType, setCurrentType] = useState(null);
    const alertTypes = [
        {
            type: "error",
            background: `${theme.resultColors.error}22`,
            borderColor: `${theme.resultColors.error}33`,
            color: "#000",
            icon: <CloseCircleFilled style={{ color: theme.resultColors.error }} />
        },
        {
            type: "success",
            background: `${theme.resultColors.success}22`,
            borderColor: `${theme.resultColors.success}33`,
            color: "#009400",
            icon: <CheckCircleOutlined style={{ color: theme.resultColors.success }} />
        },
        {
            type: "warning",
            background: `${theme.resultColors.warning}66`,
            borderColor: `${theme.resultColors.warning}`,
            color: "#000",
            icon: <WarningFilled style={{ color: "#faad14" }} />
        },
        // {
        //     type: "info",
        //     background: `${theme.resultColors.info}22`,
        //     borderColor: `${theme.resultColors.info}33`,
        //     color: "#000",
        //     icon: <CloseCircleFilled style={{ color: theme.resultColors.info }} />
        // },
    ]

    useEffect(() => {
        setCurrentType(alertTypes.find(t => type === t.type))
    }, [])

    return (
        <div style={{
            ...style,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "7px 10px",
            background: currentType?.background,
            color: currentType?.color,
            borderRadius: 5,
            border: `1px solid ${currentType?.borderColor}`
        }}>
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                {
                    icon ? icon : currentType?.icon
                }
                <span>{text}</span>
            </div>
            {
                rightContent
            }
        </div>
    );
}

export default KronAlert;