import { Button, Form, Input, InputNumber, message, Row, Select, Spin } from "antd";
import { useState } from "react";
import { CiBadgeDollar } from "react-icons/ci"
import { createUseStyles } from "react-jss";
import useApi from "../../hooks/api/useApiRequest";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

const useStyles = createUseStyles({
    form: {
        "& .ant-form-item": {
            margin: 0
        }
    }
});

const initialValues = {
    sistema: null,
    tipoCotizacion: null,
    numeroSerie: null,
    cantidadUsuarios: 1
}


const Cotizador = () => {
    const styles = useStyles();
    const api = useApi();
    const [form] = Form.useForm();
    const [sistemas, setSistemas] = useState([]);
    const [acciones, setAcciones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState("");
    const accionesLicencia = {
        tradicional: [
            "ACTUALIZACIÓN",
            // "CAMBIO A LICENCIAMIENTO ANUAL"
        ],
        anual: [
            // "PAQUETE", 
            // "CAMBIAR CARACTERÍSTICAS", 
            "RENOVACIÓN"
        ]
    }
    const opcionesSistemas = {
        tradicional: [
            'CONTABILIDAD',
            'BANCOS',
            'COMERCIAL PREMIUM',
            'FACTURA ELECTRÓNICA',
            'NÓMINAS',
            'TIMBRES',
            'PUNTO DE VENTA',
            'CFDi Facturación en línea +',

        ],
        anual: [
            'BANCOS, LIC. ANUAL',
            'COMERCIAL PREMIUM, LIC. ANUAL',
            'COMERCIAL PRO, LIC. ANUAL',
            'COMERCIAL START, LIC. ANUAL',
            'CONTABILIDAD, LIC. ANUAL',
            // 'CONTPAQi® COBRA CORPORATIVO, LIC. ANUAL',
            // 'CONTPAQi® COBRA EMPRESARIAL, LIC. ANUAL',
            // 'CONTPAQi® COBRA EQUIPOS, LIC. ANUAL',
            // 'CONTPAQi® COBRA INICIAL, LIC. ANUAL',
            // 'CONTPAQi® CONTABILIZA CORPORATIVO, LIC. ANUAL',
            // 'CONTPAQi® CONTABILIZA EMPRESARIAL, LIC. ANUAL',
            // 'CONTPAQi® CONTABILIZA EQUIPOS, LIC. ANUAL',
            // 'CONTPAQi® CONTABILIZA INICIAL, LIC. ANUAL',
            // 'CONTPAQi® DECIDE DESPACHOS, LIC. ANUAL',
            // 'CONTPAQi® DECIDE EQUIPOS, LIC. ANUAL',
            // 'CONTPAQi® DECIDE GRUPOS, LIC. ANUAL',
            // 'CONTPAQi® DECIDE PLUS, LIC. ANUAL',
            // 'CONTPAQi® EVALÚA035, LIC. ANUAL',
            // 'CONTPAQi® PERSONIA CORPORATIVO, LIC. ANUAL',
            // 'CONTPAQi® PERSONIA EMPRESARIAL, LIC. ANUAL',
            // 'CONTPAQi® PERSONIA EQUIPOS, LIC. ANUAL',
            // 'CONTPAQi® PERSONIA INICIAL, LIC. ANUAL',
            // 'CONTPAQi® RESPALDOS, LIC. ANUAL',
            // 'CONTPAQi® VENDE CORPORATIVO, LIC. ANUAL',
            // 'CONTPAQi® VENDE EMPRESARIAL, LIC. ANUAL',
            // 'CONTPAQi® VENDE EQUIPOS, LIC. ANUAL',
            // 'CONTPAQi® VENDE INICIAL, LIC. ANUAL',
            // 'CONTPAQi® VIÁTICOS CORPORATIVO, LIC. ANUAL',
            // 'CONTPAQi® VIÁTICOS EMPRESARIAL, LIC. ANUAL',
            // 'CONTPAQi® VIÁTICOS EQUIPOS, LIC. ANUAL',
            // 'CONTPAQi® VIÁTICOS INICIAL, LIC. ANUAL',
            // 'CONTPAQi® WOPEN PLUS, LIC. ANUAL',
            // 'CONTPAQi® WOPEN POS PLUS, LIC. ANUAL',
            // 'ESCRITORIO VIRTUAL CONTPAQi® DUO, LIC. ANUAL',
            // 'ESCRITORIO VIRTUAL CONTPAQi® PLUS, LIC. ANUAL',
            // 'ESCRITORIO VIRTUAL CONTPAQi® XPLUS, LIC. ANUAL',
            'FACTURA ELECTRÓNICA, LIC. ANUAL',
            // 'KURSA DISTRIBUIDOR, LIC. ANUAL',
            // 'KURSA USUARIO FINAL, LIC. ANUAL',
            'NÓMINAS, LIC. ANUAL',
            'PRODUCCIÓN, LIC. ANUAL',
            'XML en línea+, LIC. ANUAL'
        ],
        mensuales: [
            'BANCOS, LIC. MENSUAL',
            'COMERCIAL PREMIUM, LIC. MENSUAL',
            'CONTABILIDAD, LIC. MENSUAL',
            'ESCRITORIO VIRTUAL CONTPAQi® DUO, LIC. MENSUAL',
            'ESCRITORIO VIRTUAL CONTPAQi® PLUS, LIC. MENSUAL',
            'ESCRITORIO VIRTUAL CONTPAQi® XPLUS, LIC. MENSUAL',
            'NÓMINAS, LIC. MENSUAL'
        ]
    };

    const handleChangeTipo = (tipoLicencia) => {
        setSistemas(opcionesSistemas[tipoLicencia]);
        setAcciones(accionesLicencia[tipoLicencia]);
        form.setFieldsValue(initialValues);
    }

    const cotizar = () => {
        form.validateFields().then(values => {
            setLoading(true)
            api.post("/licencias/cotizar", values, { dataType: "json" })
                .then(res => {
                    setResult(JSON.stringify(res.data, null, 2));
                    setLoading(false)
                })
                .catch(err => {
                    message.error("Error al cotizar la licencia");
                    console.log(err);
                    setLoading(false)
                });
        }).catch(() => message.error("Error en el formulario"))
    }

    return (
        <Spin tip="Cotizando ..." spinning={loading} >
            <Row>
                <Form className={styles.form} form={form} style={{ width: "100%", display: "flex", gap: 20 }} layout="vertical">
                    <Form.Item name="tipo" label="Tipo de licencia" style={{ width: 200 }} rules={[{ required: true, message: "El tipo es requerido" }]}>
                        <Select onChange={handleChangeTipo}>
                            <Select.Option value="anual">Anual</Select.Option>
                            <Select.Option value="tradicional">Tradicional</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sistema" label="Sistema" style={{ width: 250 }} rules={[{ required: true, message: "El sistema es requerido" }]}>
                        <Select>
                            {
                                sistemas.map(sistema => <Select.Option key={`${sistema}`} value={sistema}>{sistema}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="tipoCotizacion" label="Acción" style={{ width: 150 }} rules={[{ required: true, message: "La accion es requerida" }]}>
                        <Select>
                            {
                                acciones.map(accion => <Select.Option key={`${accion}`} value={accion}>{accion}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="numeroSerie" label="Número de serie" style={{ width: 200 }} rules={[{ required: true, message: "El no. de serie es requerido" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item initialValue={1} name="cantidadUsuarios" label="Número de usuarios" >
                        <InputNumber min={1} />
                    </Form.Item>
                    <Button
                        size="large"
                        type="primary"
                        shape="round"
                        icon={<CiBadgeDollar fontSize={20} style={{ strokeWidth: 0.8 }} />}
                        style={{ display: "flex", alignItems: "center", gap: 5, alignSelf: "end" }}
                        onClick={cotizar}
                    >Cotizar</Button>
                </Form>
            </Row>
            <Row>
                <div style={{
                    padding: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    background: "#eee",
                    marginTop: 20,
                    width: "100%"
                }}>Result</div>
                <Highlight {...defaultProps} code={result} language="jsx" theme={theme}>
                    {({ className, style, tokens, getLineProps, getTokenProps }) => (
                        <pre className={className} style={{...style,  padding: 10, width: "100%"}}>
                            {tokens.map((line, i) => (
                                <div key={`hh-token-${i}`} {...getLineProps({ line, key: i })}>
                                    {line.map((token, key) => (
                                        <span key={`hh-line-${i}`} {...getTokenProps({ token, key })} />
                                    ))}
                                </div>
                            ))}
                        </pre>
                    )}
                </Highlight>
            </Row>
        </Spin>
    );
}

export default Cotizador;