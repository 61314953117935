import { AcuerdoServicioDto, UpdateAcuerdoServicioDto } from "../../@types/AcuerdosServicio.types";
import useApi, { AvailableRequestContentTypes } from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useApiAcuerdosServicio = (options) => {
    const api = useApi();
    return useQuery(
        ["acuerdos-servicio"],
        async () => {
            const { data } = await api.get(`/acuerdos-servicio`);
            return data;
        },
        options
    );
};

export const useAcuerdosServicioMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/acuerdos-servicio`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        // if (params.mutationType === "update") {
        //     delete params.mutationType;
        //     delete params.cacu_fecha;
        //     let id = params.cacu_id;
        //     delete params.cacu_id;
        //     const { data } = await api.put(`/acuerdos-servicio/${id}`, params, { dataType: "json" });
        //     return data;
        // }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/acuerdos-servicio/${params.cacu_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateAcuerdoServicio,
        isLoading: isLoadingAcuerdoServicioMutation,
        error: errorAcuerdoServicioMutation,
        reset: resetAcuerdoServicioMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["acuerdos-servicio"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateAcuerdoServicio,
        isLoadingAcuerdoServicioMutation,
        errorAcuerdoServicioMutation,
        resetAcuerdoServicioMutation,
    };
};

export const useUpdateAcuerdoServicio = () => {
    const api = useApi();

    /**
     * 
     * @param {UpdateAcuerdoServicioDto} acuerdo - datos necesarios para actualizar acuerdo de servicio
     */
    const updateAcuerdoServicio = async (acuerdo) => {
        const response = await api.put(`/acuerdos-servicio/${acuerdo.cacu_id}`, acuerdo, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(updateAcuerdoServicio);

    return {
        updateAcuerdoServicio: mutation.mutate,
        isUpdatingAcuerdoServicio: mutation.isLoading,
        isUpdatedAcuerdoServicio: mutation.isSuccess,
        isUpdatingAcuerdoServicioError: mutation.isError,
    };
};


/**
 * 
 * @typedef DeletingAcuerdoServicio
 * @property {Number} cacu_id  - id del acuerdo de servicio que se va a eliminar  
 */

export const useDeleteAcuerdoServicio = () => {
    const api = useApi();

    /**
     * 
     * @param {DeletingAcuerdoServicio} deleteParams - datos necesarios para eliminar el acuerdo de servicio
     */
    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/acuerdos-servicio/${deleteParams.cacu_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteAcuerdoServicio: mutation.mutate,
        isDeletingAcuerdoServicio: mutation.isLoading,
        isDeletedAcuerdoServicio: mutation.isSuccess,
        isDeletingAcuerdoServicioError: mutation.isError,
    };
};