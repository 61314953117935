import React, {useState, useEffect} from "react";
import { Table, Row, Col, Input } from "antd";
import fixtures from "../../Licencias/fixtures";
import { motion } from "framer-motion";
import './styles.css'

const TablaData = ({colums, datasource}) => {

    const [data, setData] = useState(datasource);
    const [dataFiltered, setDataFiltered] = useState(datasource)
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setData(datasource)
        setDataFiltered(datasource)
    }, [datasource]);
    
    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = data.filter(item =>
          Object.values(item).some(val => val.toString().toLowerCase().includes(value.toLowerCase())
          )
        );
        setDataFiltered(filtered);
    };

    return(
        <>
            {dataFiltered !== null && dataFiltered !== undefined ? (
                <>
                    <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]}}
                    style={{marginTop:10}}
                    >
                        <Row style={{marginBottom:18}}>
                            <Col span={12}>
                                <span className="textRegisters">{dataFiltered.length} Registro(s)</span>
                            </Col>
                            <Col span={7} offset={5}>
                                <Input  placeholder="Buscar"  className="search" onChange={e => handleSearch(e.target.value)} />
                            </Col>
                        </Row>
                        <Table 
                        dataSource={dataFiltered} 
                        columns={colums}  
                        scroll={{ x: 'max-content' }} 
                        pagination={{pageSize:100, showSizeChanger:true, pageSizeOptions:[20,50,100,200] }}  
                        className="tableData"/>

                    </motion.div>
                </>
            ):(<span>No existen datos</span>)}
        </>
    )
}

export default TablaData;