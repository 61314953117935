
const columns = [
    {
        title: 'Asesor',
        dataIndex: 'asesor',
        key: 'asesor',
        sorter: (a, b) => a.asesor.localeCompare(b.asesor),
    },
    {
        title: 'Empresa',
        dataIndex: 'company',
        key: 'company',
        sorter: (a, b) => a.company.localeCompare(b.company),
    },
    {
        title: 'RFC',
        dataIndex: 'rfc',
        key: 'rfc',
        sorter: (a, b) => a.rfc.localeCompare(b.rfc),
    },
    {
        title: 'Contacto',
        dataIndex: 'contact',
        key: 'contact',
        sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
        title: 'Telefono',
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: 'Tipo',
        dataIndex: 'tipe',
        key: 'tipe',
        sorter: (a, b) => a.tipe.localeCompare(b.tipe),
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
        title: 'Póliza Activa en basea sus horas libres',
        dataIndex: 'policy',
        key: 'policy',
        sorter: (a, b) => a.policy.localeCompare(b.policy),
    },
]
const dataSource = [
    {
        asesor: 'Asesor de Prueba',
        company: 'Compañia de Prueba',
        rfc: 'XXFFXX000',
        contact: 'Contacto de Prueba',
        phone: '9613146842',
        email: 'correoPrueba@gmail.com',
        tipe: 'Anual',
        status: 'Activo',
        policy: '10:35'
    }
]


const fixtures = () => {
    let fixture = {};
   
    fixture = {
        columns,
        dataSource,
    };
  
    return fixture;
  };
  
  export default fixtures;