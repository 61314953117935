import React, { useState, useEffect } from "react";
import { GetTickets } from "../../hooks/api/getAllTickets";
import { GetAllUsers } from "../../hooks/api/getAllusuarios";
import CardAnimated from "../../components/cardAnimated/cardAnimated";
import TableDataAsesor from "./Components/TableAsesorMetricas/TableAsesor";
import moment from "moment";
import { Row, Col, Table } from "antd";
import { motion } from "framer-motion";
import fixtures from "./fixtures";
import './styles.css'


const Metricas = () => {

    const { getTicketsMutation } = GetTickets();
    const { getAllUsersMutation } = GetAllUsers();

    const [dataCards, setDataCards] = useState(null);
    const [dataCardsTime, setDataCardsTime] = useState(0);
    const [asesores, setAsesores] = useState(null);
    const [allTickets, setAllTickets] = useState(null);
    const [arrayBtns, setArrayBtns] = useState(fixtures().buttonsDate);
    const [allTicketNoFilter, setAllTicketNoFilter] = useState(null)

    const today = moment();
    const month = moment().month();
    const day = today.date();
    const week = moment().week();

    useEffect(() => {
        getData(dataCardsTime)
    }, [dataCardsTime])

    useEffect(() => {
        const intervalId = setInterval(() => getData(dataCardsTime), 5000);
        return () => clearInterval(intervalId);
    }, [dataCardsTime]);

    const getData = (id) => {
        if (dataCardsTime !== null) {
            let today = moment()
            let filter = dataCardsTime === 2 ? today.startOf('month') : 
                            dataCardsTime === 1 ? today.startOf('week') : today;
            let date = {fecha_init: filter.format('yyyy-MM-DD').toString()}
            getTicketsMutation(date, {
                onSuccess: (data) => {
                    if (data.length > 0) {
                        setAllTickets(data)
                        setAllTicketNoFilter(data)
                        filtrarDataForDate(data, id);
                    }
                },
                onError: (error) => {
                    console.log(error)
                }
            })
            getAllUsersMutation({}, {
                onSuccess: (data) => {
                    if (data.length > 0) {
                        setAsesores(data)
                    }
                },
                onError: (error) => {
                    console.log(error)
                }
            })
        }
    }

    const filtrarDataForDate = (data, id) => {

        let dataFiltered = data;
        let procesoTotal = '';

        const dataFilterMonth = data.filter((objeto) => {
            const fechaRegistro = moment(objeto.tick_fecharegistro);
            return fechaRegistro.month() === month;
        });

        const monthClose = dataFilterMonth.filter((ticket, index) => {
            if (ticket.status && ticket.status.stat_id === 4) {
                return ticket;
            }
        })

        if (id === 0) {
            procesoTotal = 'del Día'
        } else if (id === 1) {
            procesoTotal = 'de la Semana'
        } else if (id === 2) {
            procesoTotal = 'del Mes'
        }
        setAllTickets(dataFiltered)


        //CARD PROCESOS


        const promedio = (dataFilterMonth.length / day).toFixed(0);

        const taken = dataFiltered.filter((objeto) => {
            return objeto.status && objeto.status.stat_id === 2;
        });
        const waiting = dataFiltered.filter((objeto) => {
            return objeto.status && objeto.status.stat_id === 1;
        });

        //CARD CIERRES

        const closeSuccess = dataFiltered.filter((objeto) => {
            return objeto.status && objeto.status.stat_id === 4;
        });
        const closeUnsuccess = dataFiltered.filter((objeto) => {
            return objeto.status && objeto.status.stat_id === 6;
        });
        const expired = dataFiltered.filter((objeto) => {
            return objeto.status && objeto.status.stat_id === 9;
        });
        const alreadyTaken = dataFiltered.filter((objeto) => {
            return objeto.status && objeto.status.stat_id > 1;
        });

        //CARD TIEMPOS

        const promedioFirstContactMonth = getTimesFirstContact(monthClose);

        const promedioFirstContactDay = getTimesFirstContact(alreadyTaken);

        const promedioRes = getTimesResolution(closeSuccess);


        const dataForCards = [
            {
                title: 'Tiempos',
                data: [
                    {
                        value: 1,
                        title: 'Promedio de Primer Contacto del Mes',
                        data: promedioFirstContactMonth
                    },
                    {
                        value: 2,
                        title: 'Promedio de Primer Contacto del Día',
                        data: promedioFirstContactDay
                    },
                    {
                        value: 2,
                        title: 'Promedio de Resolución',
                        data: promedioRes
                    }
                ]
            },
            {
                title: 'Procesos de Tickets',
                data: [
                    {
                        value: 1,
                        title: `Total ${procesoTotal}`,
                        data: dataFiltered.length
                    },
                    {
                        value: 2,
                        title: 'Promedio del Mes',
                        data: promedio
                    },
                    {
                        value: 3,
                        title: 'En Revisión',
                        data: taken.length
                    },
                    {
                        value: 4,
                        title: 'En espera',
                        data: waiting.length
                    },
                ]
            },
            {
                title: 'Cierres de Tickets',
                data: [
                    {
                        value: 1,
                        title: 'Totales',
                        data: closeSuccess.length + closeUnsuccess.length + expired.length
                    },
                    {
                        value: 2,
                        title: 'Cerrados con Éxito',
                        data: closeSuccess.length
                    },
                    {
                        value: 3,
                        title: 'Cerrados sin Éxito',
                        data: closeUnsuccess.length
                    },
                    {
                        value: 4,
                        title: 'Caducados',
                        data: expired.length
                    }
                ]
            }
        ]
        if (id === 2) {
            dataForCards[0].data.splice(1, 1);
        }
        setDataCards(dataForCards)
    }

    const getTimesResolution = (data) => {

        let result = ''

        if (data.length > 0) {
            const tiempos = data.map((ticket) => {
                const data = {
                    timeInit: ticket.incidencias?.some(ele => ele.tipi_id === 2) ? ticket.incidencias.find(ele => ele.tipi_id === 2).tici_fecha : null,
                    timeEnd: ticket.incidencias?.some(ele => ele.tipi_id === 6) ? ticket.incidencias.find(ele => ele.tipi_id === 6).tici_fecha : null,
                }
                return data;
            })
            const tiempoResolucion = tiempos.map((objeto) => {
                const fechaRegistro = moment(objeto.timeInit);
                const fechaUltimoStatus = moment(objeto.timeEnd);
                const minutosDiferencia = fechaUltimoStatus.diff(fechaRegistro, 'minutes');
                return minutosDiferencia;
            });
            let promedioRes = (tiempoResolucion.reduce((acumulador, numero) => acumulador + numero, 0)) / tiempoResolucion.length;
            if (promedioRes > 59) {
                result = `${(promedioRes / 60).toFixed(0)} horas`
            } else {
                result = `${(promedioRes).toFixed(0)} minutos`
            }
        } else {
            result = `0 minutos`;
        }
        return result
    }

    const getTimesFirstContact = (data) => {

        let result = ''

        if (data.length > 0) {
            const tiempos_ext = data.map((ticket) => {
                if(ticket.tick_fecharegistro && ticket.incidencias?.some(ele => ele.tipi_id === 2)){
                    const data = {
                        timeInit: ticket.tick_fecharegistro,
                        timeEnd: ticket.incidencias?.some(ele => ele.tipi_id === 2) ? ticket.incidencias.find(ele => ele.tipi_id === 2).tici_fecha : null,
                    }
                    return data;
                }
            })
            const tiempos = tiempos_ext.filter(time => time !== undefined);
            const tiempoResolucion = tiempos.map((objeto) => {
                const fechaRegistro = moment(objeto.timeInit);
                const fechaUltimoStatus = moment(objeto.timeEnd);
                const minutosDiferencia = fechaUltimoStatus.diff(fechaRegistro, 'minutes');
                return minutosDiferencia;
            });
            let promedioRes = (tiempoResolucion.reduce((acumulador, numero) => acumulador + numero, 0)) / tiempoResolucion.length;
            if (promedioRes > 59) {
                result = `${(promedioRes / 60).toFixed(0)} horas`
            } else {
                result = `${(promedioRes).toFixed(0)} minutos`
            }
        } else {
            result = `0 minutos`;
        }
        return result
    }

    const clickBTn = (id) => {
        getData(id)
        setDataCardsTime(id)
    }


    return (
        <div className="container">
            {dataCards !== null ? (
                <>
                    <Row style={{ marginTop: -20, marginBottom: 20 }}>
                        <Col span={18}></Col>
                        {
                            arrayBtns.map((btn, index) => (
                                <Col key={index} span={2}>
                                    <motion.button
                                        initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{ duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}
                                        className={btn.id === dataCardsTime ? 'btnTimeLapsActive' : 'btnTimeLaps'}
                                        onClick={() => clickBTn(btn.id)}
                                    >
                                        {btn.text}
                                    </motion.button>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row style={{ marginTop: -15 }}>
                        {dataCards.length > 0 && dataCards.map((data, index) => (
                            <Col span={8} key={index} style={{ padding: 10 }}>
                                <CardAnimated title={data.title} data={data.data} />
                            </Col>
                        ))}
                    </Row>
                </>
            ) : (<span>No Existen Datos</span>)}
            <Row>
                <Col span={24}>
                    {asesores !== null && allTickets !== null && (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}
                        >
                            <TableDataAsesor    key={1} dataRaw={asesores} allTickets={allTickets} 
                                                idDate={dataCardsTime} ticketsForTime={allTicketNoFilter}/>
                        </motion.div>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default Metricas;