import { Button, Input, Row, Col } from "antd";

const DataTableHeader = ({
    buttons = [],
    dataToFilter,
    setFilteredData,
    counter,
    setSearch
}) => {

    const handleSearch = (event) => {
        const filteredData = dataToFilter.filter((item) => {
            return Object.keys(item).some((key) => {
                return String(item[key])
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());
            });
        });
        event.target.value.toLowerCase() !== '' ? setSearch(true) : setSearch(false)
        setFilteredData(filteredData);
    };

    return (
        <Row style={{ padding: 10, border: "1px solid #eee", display: "flex", alignItems: "center", justifyContent:'center' }}>
            <Col span={2}>
                {
                    buttons.map((button, index) => {
                        return (
                            <Button
                                key={index}
                                type="primary"
                                shape="circle"
                                icon={button.icon}
                                size={"small"}
                                onClick={button.onClick}
                            />
                        )
                    })
                }
            </Col>
            <Col span={3} offset={14} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <span className="textRegisters">{counter} Registro(s)</span>
            </Col>
            <Col span={5}>
                <Input
                    type="text"
                    placeholder="Buscar ..."
                    onChange={handleSearch}
                    style={{
                        padding: 5,
                        width: '100%',
                    }}
                />
            </Col>
        </Row>
    )
}

export default DataTableHeader;