import { ChatItem } from "react-chat-elements";
import { IMAGES } from '../../../images';
import { chatListStyles } from "./styles";
import { createUseStyles } from "react-jss";
import { Typography } from "antd";
import { useApiUserChats } from "../../hooks/api/chats";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const useStyles = createUseStyles(chatListStyles);

/**
 * @typedef {Object} ChatListProps
 * @property {(conv_id: Number) => void} onSelectChatItem
 */

/**
 * 
 * @param {ChatListProps} props
 */

const ChatList = ({ onSelectChatItem }) => {
    const styles = useStyles();
    const [chats, setChats] = useState([]);
    const { user, apiResources } = useSelector(state => ({ user: state.auth.user, apiResources: state.app.apiResources }));
    const { data: apiChats, isLoading: isLoadingChats } = useApiUserChats({
        initialData: [],
        enabled: !!user?.usu_id,
    }, user?.usu_id);

    const handleClickChatItem = (conv_id) => {
        if (onSelectChatItem) {
            onSelectChatItem(conv_id);
        }
    }

    useEffect(() => {
        if (apiChats) {
            //remove current user from chats
            setChats(
                apiChats.map(chat => {
                    chat.user_list = chat.user_list.filter(u => u.usu_id !== user.usu_id)
                    return chat;
                })
            )
        }
    }, [apiChats])

    return (
        <div className={styles.container}>
            <div
                style={{
                    padding: 10,
                    borderBottom: "1px solid #E0E0E0",
                }}
            >
                <Typography.Title level={5} style={{ margin: 0 }}>
                    Chats
                </Typography.Title>
            </div>
            <div
                style={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 100px)",
                }}
            >
                {
                    chats.map(chat =>
                        <ChatItem
                            key={chat.conv_id}
                            id={chat.conv_id}
                            avatar={chat?.photo ? `${apiResources}/${chat.photo}` : IMAGES.GENERIC_USER}
                            alt={`${chat.user_list[0]?.usu_nombres} ${chat.user_list[0]?.usu_apaterno} ${chat.user_list[0]?.usu_amaterno}`}
                            title={`${chat.user_list[0]?.usu_nombres} ${chat.user_list[0]?.usu_apaterno} ${chat.user_list[0]?.usu_amaterno}`}
                            // subtitle={chat.lastMessage}
                            // date={new Date()}
                            unread={0}
                            className={styles.chatItem}
                            onClick={() => handleClickChatItem(chat)}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default ChatList;