// @ts-nocheck
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CodigosVigentes = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/codigos_vigentes`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        codigosVigentesMutation: mutation.mutate,
    };
}