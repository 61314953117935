import useApi from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useApiAreasDepartamentos = (options) => {
    const api = useApi();
    return useQuery(
        ["areas-departamentos"],
        async () => {
            const { data } = await api.get(`/areas-departamentos`);
            return data;
        },
        options
    );
};

export const useAreasDepartamentosMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const createAreaDepartamento = async (params) => {
        if (params.mutationType === "create") {
            const { data } = await api.post(`/areas-departamentos`, params.areaDepartamento, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            const { data } = await api.put(`/areas-departamentos/${params.idAreaDepartamento}`, params.areaDepartamento, { dataType: "json" });
            return data;
        }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/areas-departamentos/${params.idAreaDepartamento}`);
            return data;
        }
    };

    const {
        mutate: mutateAreasDepartamentos,
        isLoading: isLoadingAreasDepartamentosMutation,
        error: errorAreasDepartamentosMutation,
        reset: resetAreasDepartamentosMutation,
    } = useMutation(createAreaDepartamento, {
        onSuccess: () => {
            queryClient.invalidateQueries(["areas-departamentos"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateAreasDepartamentos,
        isLoadingAreasDepartamentosMutation,
        errorAreasDepartamentosMutation,
        resetAreasDepartamentosMutation,
    };
};


export const GetAreasDepartamentos = ()  => {
    const api = useApi()

    const mutate = async () => {
        const data  = await api.get(`/areas-departamentos`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getAreasDepartamentosMutation: mutation.mutate,
    };
}
