
/**
 * @class
 */
export class AcuerdoServicioDto {
    /**
    * @property {Number} cacu_id - id del acuerdo de servicio
    * @type {Number}
    */
    cacu_id = null
    /**
    * @property {String} cacu_nombre - nombre del acuerdo de servicio
    * @type {String}
    */
    cacu_nombre = null
    /**
    * @property {Number} cacu_minutos - minutos limite del acuerdo de servicio
    * @type {Number}
    */
    cacu_minutos = null
    /**
    * @property {Number} cacu_descripcion - descripcion del acuerdo de servicio
    * @type {Number}
    */
    cacu_descripcion = null
    /**
    * @property {String} cacu_fecha - fecha utc del acuerdo del serivicio
    * @type {String}
    */
    cacu_fecha = null
    /**
    * @property {Boolean} cacu_activo - bandera que indica si el acuerdo de servicio está activo o no
    * @type {Boolean}
    */
    cacu_activo = false
}


/**
 * @class
 */
export class UpdateAcuerdoServicioDto {
    /**
    * @property {Number} cacu_id - id del acuerdo de servicio
    * @type {Number}
    */
    cacu_id = null
    /**
    * @property {String} cacu_nombre - nombre del acuerdo de servicio
    * @type {String}
    */
    cacu_nombre = null
    /**
    * @property {Number} cacu_minutos - minutos limite del acuerdo de servicio
    * @type {Number}
    */
    cacu_minutos = null
    /**
    * @property {Number} cacu_descripcion - descripcion del acuerdo de servicio
    * @type {Number}
    */
    cacu_descripcion = null

    
}