import React, {useState, useEffect} from 'react';
import { Table } from "antd";
import { createUseStyles } from 'react-jss'; 

const useStyles = createUseStyles({
    nestedTable: {
        margin: 20,
        '& .ant-table': {
            margin: "0 !important"
        },
        '& table':{
            border: "1px solid #eee",
            width: "100%",
            
        },
        '& .ant-table-thead th.ant-table-column-has-sorters:hover': {
            background: 'rgba(54, 162, 235, 0.7)',
            color: '#fefefe'
        },
        '& table th': {
            background: '#1890FF',
            '& div': {
                color: '#fff'

            }
        },
        '& table tbody tr td': {
            background: '#fff !important',
            '& span': {
                color: '#fff'

            }
        }
    }
});


const NestedTable = ({columns, nestedData}) => {
    const styles = useStyles();
    const [nestedColumns, setNestedColumns] = useState([]);
    const ignoreProspColumn = ["filterDropdown", "filterIcon", "onFilter", "onFilterDropdownVisibleChange", "render", "sorter", "sortDirections", "showSorterTooltip"];

    const getNestedColumns = () => {
        ignoreProspColumn.map( ignore => {
            for(let column of columns){
                delete column[ignore]
            }
        })

        setNestedColumns(columns)
    }

    useEffect(() => {
        getNestedColumns();
    },[]);

    return (
        <Table className={styles.nestedTable} columns={nestedColumns} dataSource={nestedData} pagination={false} />
    );
}
 
export default NestedTable;