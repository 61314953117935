import { createUseStyles } from "react-jss";
import ChatList from "./ChatList";
import { Avatar, Navbar } from "react-chat-elements";
import { chatContainerStyles } from "./styles";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { IMAGES } from "../../../images";
import { Button, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import Lottie from "lottie-web";
import { LOTTIE_ANIMATIONS } from "../../../animations/lottie";
import Chat from "./Chat";
import { useSelector } from "react-redux";

const useStyles = createUseStyles(chatContainerStyles);

/**
 * @typedef {Object} AppChatProps
 * @property {Function} closeParent - function to close the parent component
 * @property {Number} [conv_id] - initial conversation id
 
 */

/**
 * 
 * @param {AppChatProps} props 
 */
const AppChat = ({ closeParent, conv_id }) => {
    const styles = useStyles();
    const animationContainer = useRef();
    console.log(conv_id);
    const [chatInUse, setChatInUse] = useState(conv_id || null);
    const { apiResources } = useSelector(state => state.app);

    const onSelectChatItem = (chat) => {
        setChatInUse(chat)
    }

    useEffect(() => {
        Lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LOTTIE_ANIMATIONS.CHAT_CONVERSATION,
        });
    }, []);

    return (
        <div className={styles.chatContainer}>
            <div className={styles.chatListContainer}>
                <ChatList
                    onSelectChatItem={onSelectChatItem}
                />
            </div>
            <div className={styles.chatMessagesContainer}>
                <Navbar
                    className={styles.navbar}
                    left={
                        <div className={styles.navbarUser}>
                            {
                                chatInUse &&
                                <>
                                    <Avatar
                                        src={chatInUse?.photo ? `${apiResources}/${chatInUse?.photo}` : IMAGES.GENERIC_USER}
                                        size="small"
                                        type="circle flexible"
                                    />
                                    <Typography.Title level={5} style={{ margin: 0, color: "#FFF" }}>
                                        {chatInUse?.user_list[0]?.usu_nombres} {chatInUse?.user_list[0]?.usu_apaterno} {chatInUse?.user_list[0]?.usu_amaterno}
                                    </Typography.Title>
                                </>
                            }
                        </div>
                    }
                    right={
                        <div>
                            <Button type="primary" shape="circle" icon={<MoreOutlined />} />
                            {
                                closeParent &&
                                <Button onClick={closeParent} type="primary" shape="circle" icon={<CloseOutlined />} />
                            }
                        </div>
                    }
                />
                {
                    chatInUse && <Chat conv_id={chatInUse?.conv_id} />
                }
                {
                    !chatInUse &&
                    <div className={styles.chatMessagesEmpty}>
                        <div ref={animationContainer} style={{ position: "absolute", margin: -20, zIndex: 0, opacity: 0.8, width: 250 }}></div>
                    </div>
                }
            </div>
        </div>
    );
}

export default AppChat;