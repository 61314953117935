import { Button, Form, Row, Select, message } from "antd";
import { createUseStyles } from "react-jss";
import { useCrearEmpresaregimen } from "../../../hooks/api/empresas";
import { useQueryClient } from "react-query";
import { useApiRegimenesFiscales } from "../../../hooks/api/regimenes_fiscales";
import { useEffect, useState } from "react";

const useStyles = createUseStyles(() => ({
    container: {
        width: "100%",
        "& .ant-form": {
            width: "100%"
        }
    },
    buttons: {
        justifyContent: "end",
        gap: 10
    }
}));

const CrearEmpresaRegimen = ({ closeModal, empresa, empresaRegimenes }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const { data: apiRegimenes } = useApiRegimenesFiscales({ initialData: [] });
    const [regimenes, setRegimenes] = useState([]);
    const { saveEmpresaRegimen, isSavingEmpresaRegimen } = useCrearEmpresaregimen();

    const guardarRegimen = () => {
        form.validateFields().then(values => {
            // se envian los datos al servidor
            saveEmpresaRegimen({
                emp_id: empresa?.emp_id,
                refi_id: values?.refi_id
            }, {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje);
                        queryClient.invalidateQueries(["empresas", "regimenes"]);
                        if (closeModal) closeModal();
                    } else {
                        message.error(res.mensaje)
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al agregar el régimen")
                }
            })
        }).catch(() => message.error("Hubo un problema al guardar el régimen"))
    }

    useEffect(() => {
        if (apiRegimenes && apiRegimenes?.length > 0) {
            // se filtran los regimenes que ya tiene la empresa y solo se muestran los que no tiene  asignados 
            setRegimenes(
                apiRegimenes.filter(regimen => {
                    let exists = empresaRegimenes.find(erfi => erfi?.regimen?.refi_id === regimen?.refi_id)
                    return !exists;
                })
            )
        }
    }, [apiRegimenes, empresaRegimenes])

    return (
        <div className={styles.container}>
            <Row>
                <Form layout="vertical" form={form}>
                    <Form.Item label="Régimen fiscal" name="refi_id" rules={[{ required: true, message: "El régimen es requerido" }]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.join(" ").toLowerCase().includes(input.toLowerCase())}
                        >
                            {
                                regimenes.map(refi => <Select.Option key={`regimen_${refi.refi_id}`} value={refi?.refi_id}>{refi.refi_clave}{" - "}{refi.refi_nombre}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Row>
            <Row className={styles.buttons}>
                <Button type="secondary" onClick={closeModal}>Cancelar</Button>
                <Button type="primary" onClick={guardarRegimen} loading={isSavingEmpresaRegimen}>Guardar</Button>
            </Row>
        </div>
    );
}

export default CrearEmpresaRegimen;