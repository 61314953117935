import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Form, Input, message, Row, Select, Space, Steps, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TbHandStop } from 'react-icons/tb';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Elevation from '../../../components/Elevation';
import { useTicketsIncidenciasMutation } from '../../../hooks/api/tickets_incidencias';
import { useQueryUtils } from '../../../hooks/api/utils';

const useStyles = createUseStyles(theme => ({
    userCard: {
        position: "relative",
        border: `1px solid #eee`,
        transition: "all 0.2s ease-in-out",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.03)",
            border: `1px solid ${theme.success}`
        },
        "& .user-card-inner": {
            maxWidth: 250,
            padding: 20,
            display: "flex",
            gap: 10,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
        },
        "& .user-card-overlay": {
            transition: "all 0.1s ease-in-out",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.8)",
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }
    },
    selectedUserCard: {
        transform: "scale(1.03)",
        border: `1px solid ${theme.success}`
    },
    stepsHeader: {
        padding: "20px 20px 0 20px",
        boxShadow: "0px -1px 0 0 #e8e8e8 inset",
        "& .ant-steps-item-icon": {
            width: 26,
            height: 26
        }
    }
}));


const UserCard = ({ usuario, onSelectCard, selected }) => {
    const styles = useStyles();
    const [isSelected, setIsSelected] = useState(false);

    const handleClickUsuario = () => {
        if (onSelectCard) onSelectCard(!isSelected, usuario);
        setIsSelected(!isSelected)
    }

    return (
        <div className={`${styles.userCard} ${isSelected || selected ? styles.selectedUserCard : ""}`} onClick={handleClickUsuario}>
            <div className="user-card-overlay" style={{
                opacity: isSelected || selected ? 1 : 0,
                transform: isSelected || selected ? "translateY(0)" : "translateY(-10px)"
            }}><CheckCircleOutlined style={{ fontSize: 40, color: "#00d100" }} /></div>
            <Elevation className="user-card-inner">
                <Avatar size={64} icon={<UserOutlined />} />
                <Typography.Title level={4} style={{ textAlign: "center" }}>{usuario.usu_nombres} {usuario.usu_apaterno} {usuario.usu_amaterno}</Typography.Title>
            </Elevation>
        </div>
    );
}


const PedirApoyo = ({ incidenciasApoyo, handleClickShowModalCalificarCompañeros, ticket, closeModal, refetchIncidenciasApoyo, apiUsuarios }) => {
    const styles = useStyles();
    const [step, setStep] = useState(0);
    const [usuarios, setUsuarios] = useState([]);
    const [tUsuarios, setTUsuarios] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { mutateTicketsIncidencias } = useTicketsIncidenciasMutation();
    const { invalidateQuery } = useQueryUtils()
    const user = useSelector(state => state.auth.user);
    const [formProblematica] = Form.useForm();

    const handleChangeBuscarAsesor = (e) => {
        console.log(e.target.value);
        if (e.target.value !== "") {
            let userFilter = usuarios.filter(usuario =>
                usuario.usu_nombres.toLowerCase().trim().includes(e.target.value) ||
                usuario.usu_apaterno.toLowerCase().trim().includes(e.target.value) ||
                usuario.usu_amaterno.toLowerCase().trim().includes(e.target.value)
            )
            setTUsuarios(userFilter);
        }
    }

    const handleChangeSelccionarTodos = (checked) => {
        setAllSelected(checked)
        console.log(usuarios)
    }

    const handleSelectCardUsuario = (isSelected, usuario) => {
        if (isSelected) {
            setSelectedUsers([...selectedUsers, usuario]);
        } else {
            setSelectedUsers(selectedUsers.filter((usr) => usr.usu_id !== usuario.usu_id));
        }
    }

    const handleClickPedirAPoyo = () => {
        formProblematica.validateFields().then(values => {
            if (!allSelected && selectedUsers.length === 0) {
                message.error("Por favor selecciona un asesor para pedir apoyo");
            } else {
                
                let ids_user_selected = allSelected ? usuarios.map(usr => {return usr.usu_id}) : selectedUsers.map(usr => {return usr.usu_id})

                let incidenciasData = {
                    mutationType: "create",
                    type: "help",
                    tick_id: ticket?.tick_id,
                    tici_observaciones: values.problematica,
                    stat_id: ticket?.stat_id,
                    usu_asignadoid: ids_user_selected,
                    tipi_id: allSelected ? 5 : 4
                };
                console.log(incidenciasData)
                mutateTicketsIncidencias(incidenciasData,
                    {
                        onSuccess: (res) => {
                            if (res.success) {
                                message.success("Apoyo solicitado");
                                setAllSelected(false);
                                setSelectedUsers([]);
                                closeModal();
                                refetchIncidenciasApoyo();
                                setStep(0);
                                formProblematica.resetFields();
                                setTUsuarios([])
                                invalidateQuery(["tickets_incidencias", "incidencias_apoyo"])
                            } else {
                                message.error("Hubo un problema al pedir apoyo con el ticket");
                            }
                        },
                        onError: () => {
                            message.error("Hubo un problema al pedir apoyo con el ticket");
                        },
                    }
                );
            }
        }).catch(() => {
            message.error("Faltan campos requeridos");
        });

    }

    const handleClickSiguiente = () => {
        if (step == 0) {
            if (!allSelected && selectedUsers.length === 0) {
                message.error("Por favor selecciona un asesor para proseguir");
            } else {
                setStep(step + 1)
            }
        } else if (step == 1) {
            setStep(step + 1)
        }
    }

    const handleClickAtras = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    }



    useEffect(() => {
        if (apiUsuarios?.length > 0) {
            setUsuarios(apiUsuarios.filter(usr => usr.usu_id !== user.usu_id))
        }
        if (selectedUsers.length === apiUsuarios.length) { setAllSelected(true) } else { setAllSelected(false) }
    }, [selectedUsers, apiUsuarios])

    const steps = [
        {
            title: 'Seleccionar compañeros',
        },
        {
            title: 'Problemática',
        }
    ];

    return (
        <div style={{ display: "flex", gap: 25, flexDirection: "column" }}>
            <Steps

                current={step}
                className={styles.stepsHeader}
                // labelPlacement="vertical"
                type="navigation"
                size="small">
                {steps.map((item, iStep) => (
                    <Steps.Step key={item.title} title={item.title}
                    // onStepClick={() =>  setStep(iStep)}
                    />
                ))}
            </Steps>
            {
                step == 0 &&
                <div style={{ padding: "0 20px" }}>
                    {
                        incidenciasApoyo?.length > 0 &&
                        <Row >
                            <Alert
                                message="Solicitud de apoyo en curso"
                                description="Actualmente ya estas pidiendo apoyo para este ticket"
                                type="info"
                                showIcon
                                style={{ width: "100%" }}
                                action={
                                    <Space direction="vertical" style={{ alignItems: "end" }}>
                                        <Button type="primary" size="large" onClick={handleClickShowModalCalificarCompañeros}>
                                            Terminar solicitud
                                        </Button>
                                    </Space>
                                }
                            />
                        </Row>
                    }
                    <Row style={{ marginBottom: 30, flexDirection: "row", alignItems: "end", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", gap: 16, flexDirection: "row", alignItems: "end" }}>
                            <Input placeholder="Buscar asesor" size="large" onChange={handleChangeBuscarAsesor} style={{ width: 250 }} disabled={allSelected} />
                            <div style={{ display: "flex", gap: 10 }}>
                                <Switch onChange={handleChangeSelccionarTodos} checked={allSelected} />
                                <Typography.Text>Seleccionar todos</Typography.Text>
                            </div>
                        </div>
                        {/* {
                            // !(incidenciasApoyo?.length > 0) &&
                            <Button onClick={handleClickPedirAPoyo} type="primary" size="large" style={{ display: "flex", alignItems: "center" }} ><TbHandStop style={{ marginRight: 7, fontSize: 16 }} />Pedir apoyo</Button>
                        } */}
                    </Row>
                    <Row style={{ gap: 20 }}>
                        {
                            (tUsuarios.length > 0 ? tUsuarios : usuarios).map(usuario => <UserCard key={`${usuario.usu_id}_${usuario.usu_nombres}`} usuario={usuario} selected={allSelected} onSelectCard={handleSelectCardUsuario} />)
                        }
                    </Row>
                </div>
            }
            {
                step == 1 &&
                <div style={{ padding: "0 20px" }}>
                    <Row>
                        <Form layout='vertical' style={{ width: "100%" }} form={formProblematica}>
                            {/* <Form.Item name="tipo_comunicacion" label="Tipo de comunicación" rules={[{ required: true, message: "El tipo de comunicación es requerido" }]}>
                               <Select>
                                    <Select.Option value={1}>Nota</Select.Option>
                                    <Select.Option value={1}>Seguimiento</Select.Option>
                                    <Select.Option value={1}>Nota</Select.Option>
                               </Select>
                            </Form.Item> */}
                            <Form.Item name="problematica" label="Motivo" rules={[{ required: true, message: "El motivo es requerido" }]}>
                                <Input.TextArea showCount />
                            </Form.Item>
                        </Form>
                    </Row>
                </div>
            }
            <Row style={{ borderTop: "1px solid #eee", padding: 10 }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "end", gap: 10 }}>
                    <Button type="secondary" onClick={handleClickAtras} disabled={step === 0}>Atrás</Button>
                    {
                        step == 1 &&
                        <Button onClick={handleClickPedirAPoyo} type="primary" style={{ display: "flex", alignItems: "center" }} ><TbHandStop style={{ marginRight: 7, fontSize: 16 }} />Pedir apoyo</Button>

                    }
                    {
                        step == 0 &&
                        <Button type="primary" onClick={handleClickSiguiente} disabled={incidenciasApoyo?.length > 0}>Siguiente</Button>

                    }
                </div>
            </Row>
        </div>
    );
}

export default PedirApoyo;