import { Button, Col, Image, message, Modal, Rate, Row, Space, Spin, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import EnCasoAccidenteForm from './EnCasoAccidenteForm';
import UsuarioDomicilio from './UsuarioDomicilio';
import DatosGenerales from './DatosGenerales';
import { useParams } from 'react-router-dom';
import { useApiUsuario, useProfilePhotoMutation } from '../../../hooks/api/usuarios';
import Certificaciones from './Certificaciones';
import NoUserImage from "../../../../images/user.png"
import { useModal } from '../../../hooks/useModal';
import { CloseOutlined } from '@ant-design/icons';
import { BsImage } from "react-icons/bs"
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",

    },
    backBanner: {
        position: 'absolute',
        top: 64,
        width: "calc(100% - 60px)",
        height: "150px",
        background: theme.primaryColor,
        // background: "linear-gradient(to right, #8e2de2, #4a00e0)"
        // background: "linear-gradient(to bottom, rgba(74, 0, 224, 1), #fff)"
        // background: "linear-gradient(to right,  #141e30, #243b55)"
        // background: "linear-gradient(to right, #0575e6, #021b79)"
        // background: "linear-gradient(to right, #525252, #3d72b4)"
        // background: "linear-gradient(to right,  #2b32b2, #1488cc )"
    },
    backBannerfade: {
        position: 'absolute',
        top: 0,
        width: "100%",
        height: "150px",
    },
    userPhoto: {
        "& .ant-image-mask": {
            borderRadius: "50%",
        }
    },
    LogoCerftificacionCard: {
        userSelect: "none",
        // width: "30%",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 10px 0px !important",
        transition: "all 0.2s ease-in-out",
        border: `1px solid #eee`,
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.05)",
            border: `1px solid ${theme.secondaryColor}`
        },
        "& .ant-card-body": {
            display: "flex",
            justifyContent: "center"
        }
    },
    userImageMask: {
        display: "none",
        width: "100%",
        height: "100%",
        background: "red"
    },
    imageWrapper: {
        borderRadius: "50%",
        boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 20px 13px",
        cursor: "pointer",
        "& img": {
            transition: "all 0.2s ease-in-out",
        },
        "&:hover": {
            "& img": {
                transform: "scale(1.05)"
            },
        }
    },
    modalChangePhoto: {

    }
}))


const UsuarioPage = () => {
    const styles = useStyles()
    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));
    const { id } = useParams();
    const [show, showModal, closeModal] = useModal();
    const [profileImage, setProfileImage] = useState(null)
    const profileImageInput = useRef(null);
    const { data: usuario, refetch: refetchUsuario } = useApiUsuario({ initialData: {} }, id)
    const { mutateProfilePhoto, isLoadingProfilePhotoMutation, resetProfilePhotoMutation } = useProfilePhotoMutation();

    const handleChangePhoto = (e) => {
        if (e.target.files?.length > 0) {
            let fd = new FormData();
            fd.append("files[]", e.target.files[0]);
            mutateProfilePhoto({ data: fd, id, mutationType: "update" },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            message.success("Se cargó la imagen correctamente")
                            resetProfilePhotoMutation();
                            refetchUsuario();
                        } else {
                            console.log(res);
                            message.error("Hubo un problema al cambiar la imagen de perfil")
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema al cambiar la imagen de perfil")
                    }
                })
        }
    }

    useEffect(() => {
        if (usuario?.recursos?.length > 0) {
            setProfileImage(usuario?.recursos[0]?.recu_ruta)
        }
    }, [usuario])

    const handleClickSelectImage = () => {
        profileImageInput.current?.click()
    }

    return (
        <div className={styles.container}>
            <div className={styles.backBanner}></div>
            <Row style={{ marginBottom: 0 }}>
                <Col md={{ span: 24 }} lg={{ span: 9 }} xl={{ span: 9 }} xxl={{ span: 8 }} style={{ padding: "40px 20px 0 20px", width: "100%" }} >
                    <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
                        <Row style={{ justifyContent: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                <div className={styles.imageWrapper} onClick={showModal} >
                                    {
                                        usuario?.recursos?.length > 0 ?
                                            <img
                                                style={{ borderRadius: "50%", background: "white", objectFit: "cover" }}
                                                width={200}
                                                height={200}
                                                src={`${apiHost}/static/${profileImage}`}
                                            />
                                            :
                                            <img
                                                style={{ borderRadius: "50%", background: "white", padding: 45, objectFit: "cover" }}
                                                width={200}
                                                height={200}
                                                src={NoUserImage}
                                            />
                                    }
                                </div>
                                <Typography.Title level={3} style={{ margin: 0, textAlign: "center" }}>{usuario?.usu_nombres} {usuario?.usu_apaterno} {usuario?.usu_amaterno}</Typography.Title>
                                <Typography.Title level={4} style={{ margin: 0, color: "#1890ff" }}>{usuario?.usu_mail}</Typography.Title>
                                <Rate value={4} />
                            </div>
                        </Row>
                        <Row style={{ marginTop: 0, borderRadius: 15, flexDirection: "row" }}>
                            <EnCasoAccidenteForm usu={usuario} refetchUsuario={refetchUsuario} />
                        </Row>
                    </div>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 15 }} xl={{ span: 15 }} xxl={{ span: 16 }} style={{ padding: "60px 20px 0 20px" }} >
                    <Row style={{ gap: 20 }}>
                        <DatosGenerales usu={usuario} refetchUsuario={refetchUsuario} />
                        <UsuarioDomicilio usu={usuario} />
                    </Row>
                </Col>
            </Row>
            <Row >
                <div style={{ display: "flex", flex: 1, gap: 20, flexDirection: "column", width: "100%", padding: "0px 20px 0 20px" }}>
                    <Row style={{ gap: 20 }}>
                    </Row>
                    <Row>
                        <div style={{ display: "flex", width: "100%", flex: 1, flexDirection: "column", padding: 20, borderRadius: 15, gap: 15 }}>
                            <Tabs defaultActiveKey="metricas">
                                <Tabs.TabPane tab="Métricas" key="metricas">
                                    Métricas
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Certificaciones" key="certificaciones">
                                    <Certificaciones usu={usuario} />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </Row>

                </div>
            </Row>

            <Modal className={styles.modalChangePhoto} open={show} footer={null} onCancel={closeModal} destroyOnClose={true} width="400px">
                <input type="file" style={{ display: "none" }} onChange={handleChangePhoto} ref={profileImageInput} accept="image/png,image/jpg,image/jpeg,image/webp" />
                <Row style={{ justifyContent: "center" }} onClick={showModal}>
                    <Spin style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 20px 13px" }} spinning={isLoadingProfilePhotoMutation}>
                        {
                            usuario?.recursos?.length > 0 ?
                                <img
                                    style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 20px 13px", objectFit: "cover" }}
                                    width={200}
                                    height={200}
                                    src={`${apiHost}/static/${profileImage}`}
                                />
                                :
                                <img
                                    style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 20px 13px", background: "white", padding: 45, objectFit: "cover" }}
                                    width={200}
                                    height={200}
                                    src={NoUserImage}
                                />
                        }
                    </Spin>
                </Row>
                <Row style={{ marginTop: 25, justifyContent: "center" }}>
                    <Space>
                        <Button type="primary" icon={<BsImage fontSize={16} />} size="large" style={{ display: "flex", alignItems: "center", gap: 8 }} onClick={handleClickSelectImage}>
                            Subir nueva imagen
                        </Button>
                        <Button icon={<CloseOutlined />} size="large" onClick={closeModal}>
                            Cerrar
                        </Button>
                    </Space>
                </Row>
            </Modal>
        </div>
    );
}

export default UsuarioPage;