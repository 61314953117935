import { Alert, Descriptions, Row, Spin, Steps, Typography } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import NuevoClienteSeleccionEmpresa from "./SeleccionEmpresa";
import { useEffect, useState } from "react";
import { useApiContacto } from "../../../hooks/api/contactos";
import NuevoContactoLicencias from "./NuevoContactoLicencias";
import NuevoContactoResumen from "./NuevoContactoResumen";
import { useQueryClient } from "react-query";

const useStyles = createUseStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "0 10px",
    },
    steps: {
        "& .ant-steps-item": {
            cursor: "pointer",
            "&:not(.ant-steps-item-active)": {
                borderBottom: "1px dashed #cccccc"
            }
        }
    },
    step: {

    }
}));

const TicketDatosNuevoCliente = ({ ticket }) => {
    const styles = useStyles();
    const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
    const [licencias, setLicencias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [onChangeEmpresaHasRedirected, setOnChangeEmpresaHasRedirected] = useState(false);
    const { data: contacto, isLoading: isLoadingContacto, refetch: refetchContacto } = useApiContacto(
        { initialData: {}, cacheTime: 30 * 1000 },
        ticket?.creador?.usu_id
    );

    const handleChangeStep = (step) => {
        setCurrentStep(step);
    }

    const handleCaptureEmpresa = (empresa) => {
        if (!empresa) return;
        // setEmpresaSeleccionada(empresa);
        if (!onChangeEmpresaHasRedirected) {
            setCurrentStep(1);
            setOnChangeEmpresaHasRedirected(true);
        }
        refetchContacto();
    }

    const onLicenciasLoaded = (licencias) => {
        setLicencias(licencias);
    }

    useEffect(() => {
        if (contacto?.empresas?.length > 0) {
            setEmpresaSeleccionada(contacto?.empresas[0]);
            setLoading(false);
        } else {
            setLoading(false);
            setEmpresaSeleccionada(null);
        }
    }, [contacto?.empresas])

    return (
        <div
            className={styles.container}
        >
            <Alert message="Registro de nuevo contacto" type="info" showIcon />
            <Row>
                <Steps
                    current={currentStep}
                    // labelPlacement="vertical"
                    className={styles.steps}
                    // style={{ borderBottom: "1px dashed #cccccc" }}
                    size="small"
                    type="navigation"
                    items={[
                        {
                            title: <div className={styles.step} onClick={() => handleChangeStep(0)}>Empresa</div>,
                            status: empresaSeleccionada ? "finish" : "process",

                        },
                        {
                            title: <div className={styles.step} onClick={() => handleChangeStep(1)}>Licencias</div>,
                            status: licencias.length > 0 ? "finish" : "process",
                        },
                        {
                            title: <div className={styles.step} onClick={() => handleChangeStep(2)}>Aprobación</div>,
                            status: empresaSeleccionada?.emp_aprobada && contacto?.usu_aprobado ? "finish" : "process",
                        },
                    ]}
                />
            </Row>
            {
                currentStep === 0 && (
                    <Spin spinning={loading || isLoadingContacto} >
                        <Row style={{ borderBottom: "1px dashed #cccccc", paddingBottom: 14 }}>
                            <Typography.Title level={5}>Datos del contacto</Typography.Title>
                            <Descriptions bordered column={1} style={{ width: "100%" }}>
                                <Descriptions.Item label="Nombre">{contacto?.usu_nombres} {contacto?.usu_apaterno} {contacto?.usu_amaterno}</Descriptions.Item>
                                <Descriptions.Item label="Correo">{contacto?.usu_mail}</Descriptions.Item>
                                <Descriptions.Item label="Celular">{contacto?.usu_tel}</Descriptions.Item>
                            </Descriptions>
                        </Row>
                        <Row style={{ borderBottom: "1px dashed #cccccc", paddingBottom: 14 }}>
                            {
                                !loading && (
                                    <NuevoClienteSeleccionEmpresa
                                        onCaptureEmpresa={handleCaptureEmpresa}
                                        contactoId={ticket?.creador?.usu_id}
                                        empresaSeleccionada={empresaSeleccionada}
                                        refetchContacto={refetchContacto}
                                    />
                                )
                            }
                        </Row>
                    </Spin>
                )
            }
            {
                currentStep === 1 && (
                    <Row>
                        <NuevoContactoLicencias empresa={empresaSeleccionada} onLicenciasLoaded={onLicenciasLoaded} />
                    </Row>
                )
            }
            {
                currentStep === 2 && (
                    <Row>
                        <NuevoContactoResumen
                            contacto={contacto}
                            empresa={empresaSeleccionada}
                            licencias={licencias}
                            allowApprove={empresaSeleccionada && licencias.length > 0}
                        />
                    </Row>
                )
            }
        </div >
    );
}

export default TicketDatosNuevoCliente;