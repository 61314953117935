import { TipoPosicionAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";
import VerTickets from "../../Tickets/VerTickets";

const DesarrolloTickets = () => {

    return (
        <VerTickets key={'desarrollo'} tipoPosicion={TipoPosicionAreaDepartamento.DESARROLLO}/>
    );
}

export default DesarrolloTickets;