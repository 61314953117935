import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Rate, Row, Select, Switch, Typography } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { BiBuildings } from "react-icons/bi";
import { createUseStyles } from "react-jss";
import Elevation from "../../../components/Elevation";
import { useApiCorporativos, useApiEmpresas, useEmpresasMutation } from "../../../hooks/api/empresas";
import { useApiUsuarios } from "../../../hooks/api/usuarios";
import { TipoPosicionAreaDepartamento } from "../../../@enums/AreasDepartamentos.enums";

const useStyles = createUseStyles({
    generalesSection: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
        },
        "& #empresa-vector": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            fontSize: 32,
            width: 60,
            height: 60,
            borderRadius: "50%",
            backgroundColor: "#1890ff",
            color: "#fff",
            marginBottom: 5,
        },
        "& .ant-divider-horizontal": {
            margin: "10px 0",
        },
        "& .data-item": {
            display: "flex",
            "& .data-item-label": {
                display: "flex",
                alignItems: "center",
                flex: 0.75,
                color: "#1890ff",
                fontWeight: 500,
            },
            "& .data-item-value": {
                display: "flex",
                alignItems: "center",
                fontWeight: 500,
                flex: 1.25,
                "& .ant-row": {
                    margin: 0,
                },
                "& .ant-form-item": {
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                },
            },
        },
    },
    formItem: {
        "& .ant-form-item-row": {
            width: "100%"
        }
    }
});

const EmpresaCard = ({ empresa, refetch }) => {
    const styles = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const [empresas, setEmpresas] = useState([]);
    const [origen, setOrigen] = useState(null);
    const { data: tEmpresas } = useApiCorporativos();
    const { data: usuarios } = useApiUsuarios({ initialData: [] }, { puestoId: TipoPosicionAreaDepartamento.VENTAS });
    const [vendedor, setVendedor] = useState(null);
    const { mutateEmpresa, isLoadingEmpresaMutation, resetEmpresaMutation } = useEmpresasMutation();

    const getTipoEmpresa = () => {
        switch (empresa?.emp_tipo) {
            case 1:
                return "Empresa";
            case 2:
                return "Corporativo";
            case 3:
                return "Distriuidor";
            case 4:
                return "Prospecto";
            default:
                return "";
        }
    };

    const getTipoOrigen = () => {
        switch (empresa?.emp_origen) {
            case 1:
                return 'Mesas';
            case 2:
                return 'Redes sociales';
            case 3:
                return 'Página web';
            case 4:
                return 'Campaña publicitaria (mailing)';
            case 5:
                return 'Referido';
            case 6:
                return 'Ningúno';
            case 7:
                return 'Otro';
            default:
                return "Ningúno";
        }
    };

    const handleClickEditar = () => {
        setIsEditing(true);
    };

    const handleChangeOrigen = (value) => {
        setOrigen(value);
    };

    const handleClickGuardarDatos = () => {
        form.validateFields()
            .then((values) => {
                mutateEmpresa(
                    { ...values, emp_id: empresa?.emp_id, mutationType: "update" },
                    {
                        onSuccess: (res) => {
                            if (res?.success) {
                                resetEmpresaMutation();
                                setIsEditing(false);
                                refetch()
                                console.log(empresa);
                                setVendedor(usuarios.find(usu => usu.usu_id === empresa?.emp_vendedor));
                                message.success("Datos generales actualizados");
                            } else {
                                message.error("Error al actualizar los datos generales");
                            }
                        },
                        onError: () => {
                            message.error("Error al actualizar los datos generales");
                        },
                    }
                );
            })
            .catch(() => {
                message.error("Faltan campos por capturar");
            });
    };

    useEffect(() => {
        if (empresa) {
            form.setFieldsValue({ ...empresa });
            if (empresa?.emp_vendedor) {
                let vendedor = usuarios.find(usu => usu.usu_id === empresa?.emp_vendedor);
                setVendedor(vendedor || null)
            } else {
                setVendedor(null);
            }

            if (empresa?.emp_origen) {
                setOrigen(empresa?.emp_origen);
            }
        }
    }, [empresa]);

    useEffect(() => {
        if (tEmpresas?.length > 0)
            setEmpresas(tEmpresas.filter((emp) => emp.emp_id !== empresa?.emp_id));

    }, [tEmpresas, empresa]);

    return (
        <Elevation className={styles.generalesSection}>
            <div className="section-buttons">
                {isEditing ? (
                    <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        loading={isLoadingEmpresaMutation}
                        icon={<SaveOutlined />}
                        onClick={handleClickGuardarDatos}
                    />
                ) : (
                    <Button type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={handleClickEditar} />
                )}
            </div>
            <div id="empresa-vector">
                <BiBuildings />
            </div>
            <Form layout="vertical" form={form} style={{ width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Typography.Title level={3} style={{ margin: 0, width: "100%", textAlign: "center" }}>
                        {!isEditing ? (
                            empresa?.emp_razonsocial
                        ) : (
                            <Form.Item name="emp_razonsocial" rules={[{ required: true, message: "La razón social es requerida" }]}>
                                <Input />
                            </Form.Item>
                        )}
                    </Typography.Title>
                    <Typography.Title level={4} style={{ margin: 0, color: "#1890ff", width: "100%", textAlign: "center" }}>
                        {!isEditing ? (
                            empresa?.emp_rfc
                        ) : (
                            <Form.Item name="emp_rfc" rules={[{ required: true, message: "El rfc no puede ser vacio" }]}>
                                <Input />
                            </Form.Item>
                        )}
                    </Typography.Title>
                    <Rate disabled />
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Nombre Comercial</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_nombrecomercial
                            ) : (
                                <Form.Item name="emp_nombrecomercial">
                                    <Input />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">idCIF</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_idcif
                            ) : (
                                <Form.Item name="emp_idcif">
                                    <Input />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Alias</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_alias
                            ) : (
                                <Form.Item name="emp_alias">
                                    <Input />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Código</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_codigo
                            ) : (
                                <Form.Item name="emp_codigo">
                                    <Input />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Fecha de registro</div>
                        <div className="data-item-value">
                            {!isEditing
                                ? empresa?.emp_fecharegistro
                                    ? DateTime.fromISO(empresa.emp_fecharegistro).toFormat("dd/MM/yyyy")
                                    : null
                                : null}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Corporativo</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_padreid ? (
                                    empresa?.corporativo?.emp_razonsocial
                                ) : (
                                    "No asignado"
                                )
                            ) : (
                                <Form.Item name="emp_padreid" className={styles.formItem}>
                                    <Select
                                        disabled={empresa?.emp_tipo === 2}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        style={{ width: "100%" }}
                                    >
                                        {empresas.map((emp) => (
                                            <Select.Option value={emp.emp_id} key={`${emp.emp_id}_${emp.emp_razonsocial}`}>{emp.emp_razonsocial}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Sector</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_sector
                            ) : (
                                <Form.Item name="emp_sector">
                                    <Input />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Antigüedad</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_antiguedad
                            ) : (
                                <Form.Item name="emp_antiguedad">
                                    <Input />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Estatus</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                empresa?.emp_estatus ? (
                                    "Activo"
                                ) : (
                                    "Inactivo"
                                )
                            ) : (
                                <Form.Item name="emp_estatus">
                                    <Switch checked={empresa?.emp_estatus} />
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Origen</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                getTipoOrigen()
                            ) : (
                                <Form.Item name="emp_origen" className={styles.formItem} >
                                    <Select onChange={handleChangeOrigen}>
                                        <Select.Option value={1}>Mesas</Select.Option>
                                        <Select.Option value={2}>Redes sociales</Select.Option>
                                        <Select.Option value={3}>Página web</Select.Option>
                                        <Select.Option value={4}>Campaña publicitaria (mailing)</Select.Option>
                                        <Select.Option value={5}>Referido</Select.Option>
                                        <Select.Option value={6}>Ningúno</Select.Option>
                                        <Select.Option value={7}>Otro</Select.Option>
                                    </Select>
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    {
                        origen === 1 &&
                        <>
                            <Divider />
                            <div className="data-item">
                                <div className="data-item-label">Mesas detalle</div>
                                <div className="data-item-value">
                                    {
                                        isEditing && origen === 1 ? (
                                            <Form.Item
                                                name="emp_mesasdetalle"
                                                className={styles.formItem}
                                                rules={[{ required: true, message: "El detalle de mesas es requerido" }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        ) : null
                                    }
                                    {
                                        !isEditing && origen === 1 ? empresa?.emp_mesasdetalle : null
                                    }
                                </div>
                            </div>
                        </>
                    }
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Tipo</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                getTipoEmpresa()
                            ) : (
                                <Form.Item name="emp_tipo" className={styles.formItem}>
                                    <Select>
                                        <Select.Option value={1}>Empresa</Select.Option>
                                        <Select.Option value={2}>Corporativo</Select.Option>
                                        <Select.Option value={3}>Distribuidor</Select.Option>
                                        <Select.Option value={4}>Prospecto</Select.Option>
                                    </Select>
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Saldo</div>
                        <div className="data-item-value">{!isEditing ? (empresa?.emp_saldo ? empresa?.emp_saldo : "$ 0.00") : null}</div>
                    </div>
                    <Divider />
                    <div className="data-item">
                        <div className="data-item-label">Vendedorsdfs</div>
                        <div className="data-item-value">
                            {!isEditing ? (
                                vendedor ? (
                                    `${vendedor?.usu_nombres} ${vendedor?.usu_apaterno} ${vendedor?.usu_amaterno}`
                                ) : (
                                    "No asignado"
                                )
                            ) : (
                                <Form.Item name="emp_vendedor" className={styles.formItem}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {usuarios.map((usu) => (
                                            <Select.Option value={usu.usu_id} key={`${usu.usu_id}_${usu.usu_nombres}`}>
                                                {usu.usu_nombres} {usu.usu_apaterno} {usu.usu_amaterno}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </div>
                    </div>
                    {isEditing ? (
                        <Row style={{ justifyContent: "end", marginTop: 16 }}>
                            <Button type="primary" onClick={handleClickGuardarDatos} loading={isLoadingEmpresaMutation}>
                                Guardar
                            </Button>
                        </Row>
                    ) : null}
                </div>
            </Form>
        </Elevation>
    );
};

export default EmpresaCard;
