/**
 * 
 * @param {*} theme 
 * @returns {import("react-jss").Styles}
 */
export const chatContainerStyles = theme => ({
    chatContainer: {
        minHeight: "inherit",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#fff",

    },
    chatListContainer: {
        width: "100%",
        minHeight: "inherit",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRight: "1px solid #ddd",
        flex: 0.3,
    },
    chatMessagesContainer: {
        flex: 0.7,
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
    },
    messageLeft: {
    },
    messageRight: {
        "& .rce-mbox": {
            backgroundColor: theme.colors.primary,
            color: "#fff",
        },
        "& .rce-mbox-right-notch": {
            borderColor: theme.colors.primary,
            color: theme.colors.primary,
            fill: theme.colors.primary,
        },
    },
    navbar: {
        background: `${theme.colors.primary} !important`,
    },
    navbarUser: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
    },
    navbarButtons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
    },
    messageList: {
        padding: 30,
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        height: "calc(100vh - 120px)",
    },
    chatMessagesEmpty: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 15,
    },
})

/**
 * 
 * @param {*} theme 
 * @returns {import("react-jss").Styles}
 */
export const chatListStyles = theme => ({
    chatItem: {
        "& .rce-avatar": {
            background: "#fff !important"
        },
        "& .rce-citem-body--top-title": {
            fontSize: 13,
        }
    }
})

/**
 * 
 * @param {*} theme 
 * @returns {import("react-jss").Styles}
 */
export const chatStyles = theme => ({
    chat: {
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 120px)",
        display: "flex",
        flexDirection: "column",
        "& .conversation-container": {
            flex: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            padding: 30,
        },
        "& .conversation-input-wrapper": {
            padding: "20px 10px",
            justifySelf: "flex-end",
            display: "flex",
            gap: 20,
            "& .ant-btn": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    background: "#ddd",
                }
            }
        }
    }
});