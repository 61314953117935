
/**
 * @typedef {Object} SistemaClasificacion
 */
export const SistemaClasificacion = Object.freeze({
    NINGUNO: 0,
    ESCRITORIO: 1,
    NUBE: 2
});

/**
 * @readonly
 * @enum {number}
 */
export const SistemaSubClasificacion = Object.freeze({
    NINGUNO: 0,
    CONTABLE: 1,
    COMERCIAL: 2,
    RECURSOS_HUMANOS: 3,
    PRODUCTIVIDAD: 4,
    SEGURIDAD: 5
});

/**
 * @typedef {Object} SistemaTipoLicenciamiento
 */
export const SistemaTipoLicenciamiento = Object.freeze({
    ANUAL: 1,
    TRADICIONAL: 2,
    DEMO: 3
});

/**
 * Se consulta el nombre de la clasificacion en base a su valor entero del area o 
 * @param {Number} value - valor de la clasificacion que se requiere extraer la llave   
 * @returns {String} Nombre de la clasificación
 */
export const getKeySistemaClasificacion = (value) =>
    Object.keys(SistemaClasificacion).find(key => SistemaClasificacion[key] == value);

/**
* Se consulta el nombre de la sub clasificacion en base a su valor entero del area o 
* @param {Number} value - valor de la sub clasificacion que se requiere extraer la llave   
* @returns {String} Nombre de la sub clasificación
*/
export const getKeySistemaSubClasificacion = (value) =>
    Object.keys(SistemaSubClasificacion).find(key => SistemaSubClasificacion[key] == value);


/**
* Se consulta el nombre del tipo de licencia en base a su valor entero del area o 
* @param {Number} value - valor del tipo de licencia que se requiere extraer la llave   
* @returns {String} Nombre del tipo de licencia
*/
export const getKeySistemaTipoLicencia = (value) =>
    Object.keys(SistemaTipoLicenciamiento).find(key => SistemaTipoLicenciamiento[key] == value);