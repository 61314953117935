const initialState = {};

const catalogosReducer = (state = initialState, action) => {
    switch (action.type) {
        case "catalogoProductosCargado": {
            return {
                ...state,
                productos: action.payload,
            };
        }
        case "catalogoMaterialesPeligrososCargado": {
            return {
                ...state,
                materialesPeligrosos: action.payload,
            };
        }
        default:
            return state;
    }
};

export const getCatalogoProductos = async (dispatch) => {
    const productos = await window.api.catalogos.consultar("productos");
    dispatch({ type: "catalogoProductosCargado", payload: productos });
}

export const getCatalogoMaterialesPeligrosos = async (dispatch) => {
    const materialesPeligrosos = await window.api.catalogos.consultar("materiales_peligrosos");
    dispatch({ type: "catalogoMaterialesPeligrososCargado", payload: materialesPeligrosos });
}

export default catalogosReducer;
