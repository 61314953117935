import React, { useEffect, useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Col, Checkbox, Row, Button } from "antd";
import { createUseStyles } from "react-jss";
import { DeleteOutlined, ArrowRightOutlined } from "@ant-design/icons";

const useStyles = createUseStyles({
    transferListHeader: {
        border: "1px solid #eee",
        padding: 7,
        paddingLeft: 20,
    },
    transferList: {
        border: "1px solid #eee",
        borderTop: "none",
        listStyleType: "none",
        paddingInlineStart: 20,
        padding: 15,
        maxHeight: 200,
        height: "100%",
        overflow: "auto ",
    },
    draggableItem: {
        userSelect: "none",
        zIndex: "99999999 !important",
        listStyleType: "none",
        minHeight: 30,
        display: "flex",
        justifyContent: "space-between",
    },
    "body .list-draggable-element": {
        position: "relative !important",
        display: "flex !important",
        backgroundColor: "#fff !important",
        width: "100%",
        height: "30px !important",
        zIndex: "99999999 !important",
    },
    tranferButtons: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    deleteButton: {
        zIndex: 999999,
        "& span": {
            zIndex: 0,

        }
    }
});

const CustomTransfer = (props) => {
    const styles = useStyles();
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedAvailableItems, setSelectedAvailableItems] = useState([]);
    const [addedItems, setAddedItems] = useState([]);

    const getInitialData = () => {
        if (props?.dataSource !== undefined)
            setAvailableItems(
                props?.dataSource.filter((item) => {
                    if (props?.selectedItems)
                        for (let selectedItem of props.selectedItems) {
                            if (item.dataIndex === selectedItem.dataIndex) return false;
                        }
                    return true;
                })
            );
        setAddedItems(props?.selectedItems);
    };

    useEffect(() => {
        getInitialData();
    }, [props?.dataSource]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let newItems = arrayMove(addedItems, oldIndex, newIndex);
        setAddedItems(newItems);
        props.onChangeTransfer(newItems);
    };

    const handleOnChangeSelectedAvailable = (e, value) => {
        if (e.target.checked) {
            setSelectedAvailableItems([...selectedAvailableItems, value]);
        }
    };

    const clearCheckBox = () => {
        let items = document.querySelectorAll(".available-item input");
        items.forEach((checkbox) => {
            if (checkbox.checked) checkbox.click();
            checkbox.checked = false;
        });
    };

    const tranferToAdded = () => {
        // item remove of available items
        let newItemsToAdd = [];
        let newAvailableItems = availableItems.filter((available) => {
            let isSelected = false;
            for (let selected of selectedAvailableItems) {
                if (selected.dataIndex === available.dataIndex) {
                    isSelected = true;
                    break;
                }
            }
            if (isSelected) newItemsToAdd = [...newItemsToAdd, available];
            return !isSelected;
        });
        clearCheckBox();
        setAvailableItems(newAvailableItems);
        setSelectedAvailableItems([]);

        // put the items
        setAddedItems([...addedItems, ...newItemsToAdd]);
        props.onChangeTransfer([...addedItems, ...newItemsToAdd]);

    };

    const tranferToAvailable = (item) => {
        let newAddedItems = addedItems.filter((added) => {
            if (added.dataIndex === item) {
                let itemToAvailable = props?.dataSource.find(tempItem => tempItem.dataIndex === item)
                setAvailableItems([...availableItems, itemToAvailable])
                return false
            }

            return true;
        });
        setAddedItems(newAddedItems)
        props.onChangeTransfer(newAddedItems);
    };

    const SortableItem = sortableElement(({ value }) => {
        let { objValue } = value;
        return (
            <li className={styles.draggableItem}>
                {props.render(objValue)}
                <Button className={styles.deleteButton} type="text" onClick={() => tranferToAvailable(objValue.dataIndex)}>
                    <DeleteOutlined />
                </Button>
            </li>
        );
    });

    const SortableContainer = sortableContainer(({ children }) => {
        return <ul className={styles.transferList}>{children}</ul>;
    });

    return (
        <div>
            <Col span={10}>
                <Row className={styles.transferListHeader}>Columnas disponibles</Row>
                <ul className={styles.transferList}>
                    {availableItems.map((value, iValue) => (
                        <li style={{}} className={styles.draggableItem} key={`${value}__${iValue}`}>
                            <Checkbox className={"available-item"} onChange={(e) => handleOnChangeSelectedAvailable(e, value)}>
                                {props.render(value)}
                            </Checkbox>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col span={4}>
                <div className={styles.tranferButtons}>
                    <Button
                        disabled={selectedAvailableItems.length === 0}
                        style={{ marginBottom: 15 }}
                        type="primary"
                        shape="circle"
                        icon={<ArrowRightOutlined />}
                        onClick={tranferToAdded}
                    />
                    {/* <Button
                        disabled={selectedAddedItems.length === 0}
                        type="primary"
                        shape="circle"
                        icon={<ArrowLeftOutlined />}
                        onClick={tranferToAdded}
                    /> */}
                </div>
            </Col>
            <Col span={10}>
                <Row className={styles.transferListHeader}>Columnas añadidas</Row>
                <SortableContainer distance={1} onSortEnd={onSortEnd}>
                    {addedItems.map((objValue, index) => (
                        <SortableItem key={`item-${index}`} index={index} value={{ objValue, index }} />
                    ))}
                </SortableContainer>
            </Col>
        </div>
    );
};

export default CustomTransfer;
