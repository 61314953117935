/**
 * 
 * @param {Object} theme 
 * @returns {import("react-jss").Styles}
 */
export const knowBaseAdministrationStyles = theme => ({
    itemContainer: {
        padding: 20,
        borderRadius: 8,
    }
});