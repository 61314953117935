
import { useMutation } from "react-query";
import useApi, { AvailableRequestContentTypes } from "./useApiRequest";

/**
 * 
 * @typedef AddingTicketAcuerdoServicio
 * @property {Number} tick_id - id del ticket al que se le esta agregando el acuerdo de servicio   
 * @property {Number} cacu_id - id del acuerdo de servicio 
 * @property {Number} tias_motivo - motivo del por que se esta cambiando el acuerdo de servicio 
 */

export const useGuardaNuevoUsoAcuerdoServicio = () => {
    const api = useApi();

    /**
     * 
     * @param {AddingTicketAcuerdoServicio} addingParams - datos para agregar el nuevo uso de acuerdo de servicio
     */
    const addResource = async (addingParams) => {
        const response = await api.post(`/tickets/${addingParams.tick_id}/acuerdos-servicio`, addingParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        saveNewUsoAcuerdoServicio: mutation.mutate,
        isSavingUsoAcuerdoServicio: mutation.isLoading,
        isSavedUsoAcuerdoServicio: mutation.isSuccess,
        isSavingUsoAcuerdoServicioError: mutation.isError,
    };
};