import React, { useState } from "react";
import { motion } from "framer-motion";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { Button, Form, Input, message, Row, Space, Typography } from "antd";
import { BiArrowBack } from "react-icons/bi";
import useApi from "../../hooks/api/useApiRequest";

const useStyles = createUseStyles({
    successContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .buttons .ant-space-item": {
            flex: 1,
        },
    },
    backButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        marginRight: 8,
    },
    mailInput: {
        "& input": {
            "&:focus": {
                boxShadow: "none !important",
            },
            borderBottom: "none !important",
            border: "none !important",
        },
        "& .ant-input-affix-wrapper": {
            boxShadow: "none !important",
        },
    },
    nuevoCorreo: {
        marginTop: 5,
        "&  .ant-row": {
            marginBottom: 10,
        },
    },
});

const RecuperarContrasena = ({ setVisibleForm }) => {
    const motionParams = useSelector((state) => state.motion);
    const [form] = Form.useForm();
    const styles = useStyles();
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [mailError] = useState({
        message: null,
        status: null,
    });

    const handleClickRecuperarContrasena = () => {
        setLoading(true);
        form.validateFields()
            .then(({ mail }) => {
                api.noSessionPost(
                    "/cp",
                    { mail },
                    {
                        dataType: "json",
                        hasFormData: false,
                        hasCustomUrl: false,
                    }
                )
                    .then((res) => {
                        if (res?.data?.success) {
                            message.success(res.data.mensaje);
                            setVisibleForm("login")
                        } else {
                            message.error("Hay un problema con el correo ");
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        message.error("Hubo un problema al enviar el correo de recuperación.");
                        setLoading(false);
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleClickRegresar = () => {
        setVisibleForm("login");
    };

    return (
        <motion.div
            className={styles.successContainer}
            initial="out"
            animate="in"
            exit="out"
            transition={motionParams.transition}
            variants={motionParams.variants}
        >
            <Row style={{ flexDirection: "column", marginBottom: 5, width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: 5 }}>
                    <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<BiArrowBack />}
                        className={styles.backButton}
                        onClick={handleClickRegresar}
                    />
                    <Typography.Text>Regresar</Typography.Text>
                </div>
                <Typography.Title level={4} style={{ marginTop: 10 }}>
                    Recuperación de contraseña
                </Typography.Title>
            </Row>
            <Row style={{ width: "100%" }}>
                <Form form={form} className={styles.nuevoCorreo} layout="vertical" style={{ width: "100%" }}>
                    <Form.Item
                        label="Correo Eletrónico"
                        name="mail"
                        hasFeedback={true}
                        validateStatus={mailError.status}
                        help={mailError.message}
                        className={styles.mailInput}
                        style={{ flex: 1 }}
                        rules={[
                            { required: true, message: "por favor ingresa tu correo electrónico!" },
                            {
                                type: "email",
                                message: "El correo electrónico no es válido!",
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                </Form>
            </Row>
            <Space className="buttons" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Row style={{ gap: 16 }}>
                    <Button
                        type="primary"
                        style={{ width: "100%", marginTop: 10, flex: 1 }}
                        onClick={handleClickRecuperarContrasena}
                        loading={loading}
                    >
                        Enviar
                    </Button>
                </Row>
            </Space>
        </motion.div>
    );
};

export default RecuperarContrasena;
