import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { motion, LayoutGroup, AnimatePresence } from "framer-motion"
import { Badge, List, Popover, Typography } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import Chat from './Chat';


const useStyles = createUseStyles(() => ({
    chatContainer: {
        // background: "#f00",
        width: "calc(100% - 64px)",
        height: 20,
        position: "fixed",
        // padding: "10px 0",
        bottom: 0,
        display: "flex",
        // paddingBottom: 40,
        "& .chat-bubble": {
            width: 60,
            height: 60,
            background: "#003CA7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            marginTop: -70
            // "&:hover": {
            //     transform: "scale(1.05)",
            //     // boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
            //     // boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
            // }
        }
    },
    popoverAvailableChats: {
        "& .ant-popover-inner-content": {
            maxHeight: 400,
            overflowY: "auto",
            overflowX: "hidden",
            padding: 0
        },
        "& .chat-item": {
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
                background: "rgba(220,220,220, 0.3)",
                transform: "scale(1.04)"
            }
        }
    },
    oppenedChatContainer: {
        display: "flex",
        justifyContent: "end",
        flex: 1,
        // background: "#f0f",
        gap: 20,

    },
}));

const Chats = ({ chats, listChatTitle }) => {
    const styles = useStyles();
    const [showChatList, setShowChatList] = useState(false);
    const [oppenedChats, setOppenedChats] = useState([]);

    const chatsList = (
        <div>
            <List
                style={{ border: "none" }}
                // header={<div>Header</div>}
                // footer={<div>Footer</div>}
                bordered
                dataSource={chats}
                renderItem={chat => {
                    let contacto = chat.extraData?.ticket?.agentes.find(age => age.tiag_id === 2);
                    let isOpennedChat = oppenedChats.find(tChat => tChat.chatId === chat.chatId)
                    return isOpennedChat ? null : (
                        <motion.div layout onClick={() => handleClickChatItemList(chat)}
                            initial={{
                                opacity: 0, transform: "scale(0)",
                            }}
                            animate={{ opacity: 1, transform: "scale(1)" }}
                            exit={{ marginBottom: -180 }}
                        >
                            <List.Item className="chat-item" style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }} >
                                <Typography.Text style={{ color: "#1890ff" }}>{chat.extraData?.ticket?.tick_clave}</Typography.Text>
                                <Typography.Text >{contacto?.agente?.usu_nombres}</Typography.Text>
                            </List.Item>
                        </motion.div>
                    )
                }}
            />
        </div>
    )

    const handleClickChatItemList = (chat) => {
        let isOpennedChat = oppenedChats.find(tChat => tChat.chatId === chat.chatId)
        if (isOpennedChat) {
            setOppenedChats(oppenedChats.filter(tChat => tChat.chatId !== chat.chatId));
        } else {
            setShowChatList(false)
            setTimeout(() => setOppenedChats([...oppenedChats, chat]), 300)
        }
    }

    const handleClickToggleChatList = () => setShowChatList(!showChatList)



    return (<div className={styles.chatContainer}>
        <LayoutGroup>
            <div className={styles.oppenedChatContainer} >
                <AnimatePresence>
                    {
                        chats?.length > 0 && chats?.map(chat => {
                            let isOpennedChat = oppenedChats.find(tChat => tChat.chatId === chat.chatId)
                            return isOpennedChat ?
                                <Chat
                                    chat={chat}
                                    // layoutId={`chat-${chat.extraData?.ticket?.tick_clave}`}
                                    style={{marginTop: -430}}
                                    key={`chat-${chat.extraData?.ticket?.tick_clave}`}
                                    onCloseChatConversation={handleClickChatItemList}
                                />
                                : null
                        })
                    }
                </AnimatePresence>
            </div>
            <div style={{
                display: "flex",
                height: "100%",
                width: 100,
                justifyContent: "end",
                alignItems: "center",
                // background: "#f00",
                paddingBottom: 30,
                paddingRight: 10,
                userSelect: "none"
            }}>
                <motion.div
                    className="box"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                // style={{margin: "20px 0"}}
                >
                    <Popover
                        placement="topRight"
                        title={listChatTitle || "Chats activos"}
                        content={chatsList}
                        // trigger="click"
                        open={showChatList}
                        style={{ padding: 0 }}
                        overlayClassName={styles.popoverAvailableChats}
                    >
                        <div className="chat-bubble" onClick={handleClickToggleChatList}>
                            <Badge
                                // size='small'
                                count={chats?.length - oppenedChats.length}
                                style={{ border: "none", boxShadow: "none", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MessageOutlined style={{ fontSize: 20, color: "#fff" }} />
                            </Badge>
                        </div>
                    </Popover>
                </motion.div>
            </div>
        </LayoutGroup>
    </div>);
}

export default Chats;