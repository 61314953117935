// @ts-nocheck
import useApi from "./useApiRequest";
import { useMutation } from "react-query";
import { formatUrlQuery } from "./utils";

export const GetTickets = ()  => {
    const api = useApi();


    /**
     * 
     * @param {MutatingTicket} params el tipo {@link MutatingTicket} se utilizará para las acciones create y update
     * returns 
    */

    const mutate = async (params) => {
        const data  = await api.get(`/tickets?${formatUrlQuery(params)}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTicketsMutation: mutation.mutate,
    };
}