import { List, Avatar, Button, Carousel, DatePicker, Divider, Form, Input, message, Modal, Popover, Rate, Row, Select, Space, TimePicker, Tooltip, Typography, Collapse, Spin, Descriptions, Table } from "antd";
import { ClockCircleOutlined, ContactsTwoTone, EditOutlined, MailOutlined, MobileTwoTone, PhoneTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import Coin24 from "../../../../images/coin_24x24.png";
import { useTicketsIncidenciasMutation } from "../../../hooks/api/tickets_incidencias";
import { useModal } from "../../../hooks/useModal";
import Contacto from "../../Contactos/Contacto";
import { useUsuariosRecordatoriosMutation } from "../../../hooks/api/usuarios_recordatorios";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useQueryUtils } from "../../../hooks/api/utils";
import Empresa from "../../Empresas/Empresa";
import { TipoContactoMarcacion } from "../../../@enums/Contactos.enums";
import { BsWhatsapp } from "react-icons/bs";
import { BiBuildings } from "react-icons/bi";
import { IMAGES } from "../../../../images";
import { useApiContactoHorarios, useApiContactoTelefonos, useApiContactos } from "../../../hooks/api/contactos";
import { HorarioItem } from "../../Contactos/DatosContacto/ContactoHorarios";
import { DATE_FORMATS, getSystemDateTimeZone } from "../../../helpers/dates.helper";
import ClientesPopoverCard from "../../Contactos/ClientesPopoverCard";

const useStyles = createUseStyles((theme) => ({
    datosWrapper: {
        width: "100%",
        display: "flex",
        borderBottom: "1px solid #eee",
        "& .custom-tab": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 34,
            fontSize: 16,
            flex: 1,
            transition: "all 0.15s ease-in-out",
            "&.selected": {
                backgroundColor: "#1890FF",
                color: "#fff",
            },
            "&:hover": {
                backgroundColor: "#339cff",
                color: "#fff",
                cursor: "pointer",
            },
        },
        "& .ant-carousel": {
            width: "100%",
        },
    },
    datos: {
        padding: 10,
    },
    carouselPanel: {
        background: "#fff",
        padding: "25px 10px",
    },
    carouselDots: {
        bottom: "-5px !important",
        "& li button": {
            background: `${theme.primaryColor} !important`,
        },
    },
    contactoWrapper: {
        "& .ant-avatar": {
            backgroundColor: theme.primaryColor,
            verticalAlign: "middle",
        },
    },
    coinWrapper: {
        display: "flex",
    },
    textarea: {
        width: "100%",
        marginTop: 5,
    },
    telefonosOverlay: {
        "& .ant-popover-inner-content": {
            margin: 0,
            width: "100%",
            padding: 0,
            maxHeight: 250,
            overflowY: "auto"
        }
    },
    userList: {
        width: "100%",
        // border: "1px solid #eee",
        // borderRadius: 10
    },
    userListItem: {
        cursor: "pointer",
        padding: 0,
        transition: "all 0.2s ease-in-out",
        "&:first-child": {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
        },
        "&:last-child": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10
        },
        "&:hover": {
            // backgroundColor: `${theme.terciaryColor}11`
            backgroundColor: `#f7f7f7`,
            // backgroundColor: `${theme.primaryColor}`,
            // transform: "scale(1.01)",
            "& .ant-list-item-meta-title": {
                // color: "#fff",
            }
        },
        "& .ant-list-item-meta": {
            alignItems: "center"
        },
        "& .ant-list-item-meta-title": {
            margin: 0
        }
    },
    contactosCollapse: {
        width: "100%",
        border: "none",
        backgroundColor: "#FFF",
        "& .ant-collapse-item": {
            borderBottom: "none",
            "& .ant-collapse-header": {
                padding: "0 !important",

            },
            "& .ant-collapse-content": {
                border: "none"
            },
            "& .ant-collapse-content-active": {
                marginTop: -20,
                paddingTop: 20,
                border: "1px solid #eee",
                borderTop: "none",
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                zIndex: 0
            }
        }
    },
    modalContactosEmpresa: {
        "& .ant-modal-header": {
            border: "none"
        },
    }
}));



/**
 * 
 * @param {Object} props 
 * @param {import("../../../@types/Contactos.types").UsuarioTelefonoDto} props.telefono - objeto de telefono
 * @param {Object} props.usu_tel - telefono en string 
 */
export const TelefonoItem = ({ telefono, usu_tel }) => {
    const theme = useTheme();
    const [linkTelefono, setLinkTelefono] = useState("#");
    const [icoTelefono, setIcoTelefono] = useState(null);

    const getLinkTelefono = () => {
        if (usu_tel) {
            setLinkTelefono(`tel:${usu_tel?.replace("(", "").replace(")", "").replace("-", "") || "#"}`);
            setIcoTelefono(<MobileTwoTone />);
        } else if ((usu_tel === "" || !usu_tel) && telefono?.usc_tipo === TipoContactoMarcacion.FIJO) {
            setLinkTelefono(`tel:${telefono?.usc_numero?.replace("(", "").replace(")", "").replace("-", "").replace("+", "") || "#"}`)
            setIcoTelefono(<PhoneTwoTone />);
        } else if ((usu_tel === "" || !usu_tel) && telefono?.usc_tipo === TipoContactoMarcacion.CELULAR && !telefono?.usc_usawhatsapp) {
            setLinkTelefono(`tel:${telefono?.usc_numero?.replace("(", "").replace(")", "").replace("-", "").replace("+", "") || "#"}`)
            setIcoTelefono(<MobileTwoTone />);
        } else if (telefono?.usc_usawhatsapp) {
            setLinkTelefono(`https://api.whatsapp.com/send?phone=${telefono?.usc_numero?.replace("(", "").replace(")", "").replace("-", "").replace("+", "")}`)
            setIcoTelefono(<BsWhatsapp style={{ color: theme.secondaryColor }} />);
        }
    }

    useEffect(() => {
        getLinkTelefono();
    }, [telefono, usu_tel])

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {icoTelefono}
            <Typography.Link
                strong
                href={linkTelefono}
                target="_blank"
            >
                {
                    (usu_tel === "" || !usu_tel) &&
                    <>{telefono?.usc_numero}</>
                }
                {usu_tel !== "" && usu_tel && <>{usu_tel || ""}</>}
                {
                    telefono?.usc_extension ? `  (ext: ${telefono?.usc_extension})` : null
                }
            </Typography.Link>
        </div>
    );
}

const ListaContactos = ({ empresa }) => {
    const theme = useTheme();
    const styles = useStyles();
    const { data: contactos, refetch: refetchContactosEmpresa } = useApiContactos({ initialData: [] }, { rwc: 1, emp_id: empresa?.emp_id });
    const [contactoIdSeleccionado, setContactoIdSeleccionado] = useState(null);
    const { data: telefonos, refetch: refetchTelefonos, isLoading: isLoadingTelefonos } = useApiContactoTelefonos({ initialData: [] }, contactoIdSeleccionado);
    const { data: horarios, refetch: refetchHorarios, isLoading: isLoadingHorarios } = useApiContactoHorarios({ initialData: [] }, contactoIdSeleccionado);


    const telefonosColumns = [
        {
            dataIndex: "usc_numero",
            title: "Número",
        },
        {
            dataIndex: "usc_tipo",
            title: "Tipo",
            render: (value) => {
                switch (value) {
                    case 1:
                        return "Celular";
                    case 2:
                        return "Fijo";
                    default:
                        return "";
                }
            },
        },
        {
            dataIndex: "usc_usawhatsapp",
            title: "Usa Whatsapp",
            render: (value) => {
                return value ? "Si" : "No";
            },
        },
        {
            dataIndex: "usc_extension",
            title: "Extensión",
        }
    ];


    const renderListUsuarioItem = (usuario) => {
        return (
            <Collapse.Panel
                style={{ marginBottom: 10, padding: 0, }}
                key={usuario.usu_id}
                showArrow={false}
                header={<List.Item.Meta
                    style={{
                        borderRadius: 50,
                        padding: "7px 10px",
                        alignItems: "center",
                        userSelect: "none",
                        boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
                        // border: "1px solid #eee",
                    }}
                    avatar={<Avatar src={IMAGES.GENERIC_USER} />}
                    title={`${usuario.usu_nombres} ${usuario.usu_apaterno} ${usuario.usu_amaterno}`}
                />}
            >
                <Spin spinning={isLoadingHorarios || isLoadingTelefonos}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 10 }}>
                        <Row>
                            <Descriptions title="Datos generales" bordered column={2} style={{ width: "100%" }}>
                                <Descriptions.Item label="Correo" span={2}>{usuario.usu_mail}</Descriptions.Item>
                                <Descriptions.Item label="Puesto">{usuario?.puesto?.pues_nombre || "Sin puesto"}</Descriptions.Item>
                            </Descriptions>
                        </Row>
                        <Row>
                            <Typography.Title level={5}>Teléfonos</Typography.Title>
                        </Row>
                        <Row>
                            <Table columns={telefonosColumns} dataSource={telefonos} style={{ width: "100%" }} />
                        </Row>
                        <Row>
                            <Typography.Title level={5}>Horarios</Typography.Title>
                        </Row>
                        <Row style={{ gap: 10 }}>
                            {horarios?.map((horario) => (
                                <HorarioItem
                                    item={horario}
                                    key={`${horario.usho_id}_${horario.usho_horainicial}`}
                                    style={{
                                        maxWidth: 230,
                                        // transform: "scale(0.9)"
                                    }}
                                    allowDeleteHorario={false}
                                />
                            ))}
                        </Row>
                    </div>
                </Spin>
            </Collapse.Panel>
        )
    }

    const handleChangeActivePanel = (panel) => {
        setContactoIdSeleccionado(panel)
    }

    useEffect(() => {
        if (contactoIdSeleccionado) {
            refetchHorarios();
            refetchTelefonos();
        }
    }, [contactoIdSeleccionado])

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <Row>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        background: theme.primaryColor,
                        padding: 10,
                        paddingBottom: 50,
                    }}
                >
                    {/* <Typography.Title level={5} style={{ color: "#FFF", marginBottom: 0, fontWeight: 700 }}>Empresa</Typography.Title> */}
                    <Typography.Text style={{ fontSize: 20, color: "#FFF", fontWeight: 600 }}>{empresa?.emp_razonsocial || "-"}</Typography.Text>
                    <Typography.Text style={{ fontSize: 12, color: "#FFF" }}>{empresa?.emp_rfc || "-"}</Typography.Text>
                </div>
            </Row>
            <Row style={{ margin: "-40px 15px 20px 15px", background: "#FFF", borderRadius: 20 }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", padding: 20 }}>
                    <Typography.Title level={5} style={{ margin: 0, color: "" }}>Contactos</Typography.Title>
                </div>
                <Collapse
                    accordion
                    style={{ padding: "0 10px" }}
                    className={styles.contactosCollapse}
                    onChange={handleChangeActivePanel}
                >
                    {
                        contactos.map((contacto) => renderListUsuarioItem(contacto))
                    }
                </Collapse>
            </Row>
        </div>
    );
}


const DatosContacto = ({ empresa, contacto, ticket, setUsandoTicket }) => {
    const styles = useStyles();
    const user = useSelector(state => state.auth.user);
    const { mutateUsuariosRecordatorios, isLoadingUsuariosRecordatoriosMutation, resetUsuariosRecordatoriosMutation } = useUsuariosRecordatoriosMutation();
    const { mutateTicketsIncidencias, resetTicketsIncidenciasMutation } = useTicketsIncidenciasMutation();
    const { invalidateQuery } = useQueryUtils();
    const [form] = Form.useForm();
    const [show, showModal, closeModal] = useModal();
    const [showNuevoRecordatorio, showModalNuevoRecordatorio, closeModalNuevoRecordatorio] = useModal();
    const [editarClienteVisible, showEditarCliente, closeEditarCliente] = useModal();
    const [listaContactosVisible, showListaContactos, closeListaContactos] = useModal();
    const [tipoRecordatorio, setTipoRecordatorio] = useState(1);
    const [formRecordatorio] = Form.useForm();
    const [empresaDomicilioPrincipal, setEmpresaDomicilipoPrincipal] = useState(null);

    const handleKeyDownNotaRapida = (e) => {
        if (!e.shiftKey && e.code === "Enter") {
            e.preventDefault();
            form.validateFields()
                .then((values) => {
                    mutateTicketsIncidencias(
                        {
                            mutationType: "create",
                            tick_id: ticket?.tick_id,
                            tipi_id: 1, // nota rapida
                            tici_observaciones: values.nota,
                        },
                        {
                            onSuccess: (res) => {
                                if (res.success) {
                                    message.success("Se creó la nota rápida.");
                                    resetTicketsIncidenciasMutation();
                                    form.resetFields();
                                    invalidateQuery(["tickets", ticket.tick_id])
                                } else {
                                    message.error(res.mensaje);
                                }
                            },
                            onError: () => {
                                message.error("Ocurrio un problema al guardar la incidencia");
                            },
                        }
                    );
                })
                .catch(() => {
                    message.error("La nota rápida no puede estar vacía");
                });
        }
    };

    const handleClickGuardarRecordatorio = () => {
        formRecordatorio.validateFields().then(values => {
            let { fechaInicial, horaInicial, descripcion: reco_descripcion } = values;

            let recordatorio = {};
            horaInicial = horaInicial.format("HH:mm");

            let fechaFinal = null;
            if (tipoRecordatorio === 2) {
                fechaFinal = fechaInicial[1].format(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR);
                recordatorio.reco_fechafinal = `${fechaFinal}T${horaInicial}:00`;
                fechaInicial = fechaInicial[0].format(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR);
            } else if (tipoRecordatorio === 3) {
                fechaInicial = DateTime.now().toFormat(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR);
            } else {
                fechaInicial = fechaInicial.format(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR);
            }
            recordatorio = {
                ...recordatorio,
                mutationType: "create",
                reco_fechainicial: `${fechaInicial}T${horaInicial}:00`,
                usu_id: user.usu_id,
                reco_tipo: tipoRecordatorio,
                reco_descripcion,
                timezone: getSystemDateTimeZone()
            }
            mutateUsuariosRecordatorios(recordatorio, {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success("Recordatorio guardado");
                        resetUsuariosRecordatoriosMutation();
                        formRecordatorio.setFieldsValue({
                            fechaInicial: null,
                            horaInicial: null,
                            descripcion: null
                        })
                        closeModalNuevoRecordatorio();
                    } else {
                        message.error("Hubo un problema al guardar el recordatorio")
                    }
                }, onError: () => {
                    message.error("Hubo un problema al guardar el recordatorio")
                }
            });
        }).catch((err) => {
            console.log(err);
            message.error("Aún faltan campos requeridos")
        })
    }

    const handleClickEditarContacto = () => {
        showModal();
    }

    const handleClickShowNuevoRecordatorio = () => {
        showModalNuevoRecordatorio();
    }

    const handleChangeTipoRecordatorio = (tipoRecordatorio) => {
        setTipoRecordatorio(tipoRecordatorio);
    }

    const telefonosPopoverContent = () => {
        let telefonosTemp = contacto.telefonos;

        if (!contacto?.usu_tel && telefonosTemp.length > 1)
            telefonosTemp = telefonosTemp.slice(1);

        return (
            <div style={{ width: "100%" }}>
                {
                    telefonosTemp.map((telefono, iTelefono) => <div style={{ borderBottom: "1px solid #eee", width: "100%", padding: "5px 10px" }} key={`telefono_${telefono.usc_numero}_${iTelefono}`} >
                        <TelefonoItem usu_tel={null} telefono={telefono} />
                    </div>)
                }
            </div>
        )
    }

    useEffect(() => {
        formRecordatorio.setFieldsValue({
            fechaInicial: null,
            horaInicial: null,
            descripcion: null
        })
    }, []);

    useEffect(() => {
        if (empresa) {
            // se busca el domicilio principal de la empresa
            let domTemp = empresa?.domicilios?.find(domicilio => domicilio.dom_principal);
            if (domTemp) setEmpresaDomicilipoPrincipal(domTemp)
        }
    }, [empresa]);

    return (
        <div className={styles.datosWrapper}>

            <Modal title="Editar contacto" open={show} onCancel={closeModal} width="80%" footer={
                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                    <Button type="primary" onClick={closeModal}>Cerrar</Button>
                </div>
            }>
                <Contacto propId={contacto?.usu_id} setUsandoTicket={setUsandoTicket} ticket={ticket} />
            </Modal>

            <Modal title="Editar Cliente" open={editarClienteVisible} onCancel={closeEditarCliente} width="80%" footer={
                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                    <Button type="primary" onClick={closeEditarCliente}>Cerrar</Button>
                </div>
            }>
                <Empresa emp_id={empresa?.emp_id} />
            </Modal>

            <Modal
                footer={null}
                width={650}
                title="Lista de contactos de la empresa"
                open={listaContactosVisible}
                onCancel={closeListaContactos}
                bodyStyle={{ maxHeight: 500, overflowY: "auto", padding: 0 }}
                className={styles.modalContactosEmpresa}
                destroyOnClose
            >
                <ListaContactos empresa={empresa} />
            </Modal>

            <Modal title="Nuevo Recordatorio" open={showNuevoRecordatorio} onCancel={closeModalNuevoRecordatorio} width={500} footer={
                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                    <Button type="primary" onClick={handleClickGuardarRecordatorio} loading={isLoadingUsuariosRecordatoriosMutation}>Guardar recordatorio</Button>
                    <Button onClick={closeModalNuevoRecordatorio}>Cancelar</Button>
                </div>
            }>
                <Row style={{ flexDirection: "column", gap: 16 }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", width: "100%" }}>
                        <Typography.Text>Tipo de recordatorio</Typography.Text>
                        <Select style={{ flex: 1, }} defaultValue={1} onChange={handleChangeTipoRecordatorio}>
                            <Select.Option value={1}>Día especifico</Select.Option>
                            <Select.Option value={2}>Periodo</Select.Option>
                            <Select.Option value={3}>Diario</Select.Option>
                        </Select>
                    </div>
                    {tipoRecordatorio === 1 ?
                        <Form layout="vertical" form={formRecordatorio} style={{ width: "100%" }}>
                            <Row style={{ gap: 16 }}>
                                <Form.Item name="fechaInicial" label="Fecha" style={{ flex: 1 }} rules={[{ required: true, message: "La fecha es requerida" }]}>
                                    <DatePicker style={{ width: "100%" }} format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} placeholder="Selecciona la fecha" />
                                </Form.Item>
                                <Form.Item name="horaInicial" label="Hora" style={{ flex: 1 }} rules={[{ required: true, message: "La hora es requerida" }]}>
                                    <TimePicker style={{ width: "100%" }} format="HH:mm" showNow={false} placeholder="Selecciona la hora" />
                                </Form.Item>
                            </Row>
                            <Form.Item name="descripcion" label="¿Qué te recordaremos?" style={{ flex: 1 }} rules={[{ required: true, message: "La descripcion es requerida" }]}>
                                <Input.TextArea style={{ width: "100%" }} showCount={true} rows={3} />
                            </Form.Item>
                        </Form>
                        : null
                    }
                    {tipoRecordatorio === 2 ?
                        <Form layout="vertical" form={formRecordatorio}>
                            <Form.Item name="fechaInicial" label="Fecha" rules={[{ required: true, message: "El periodo es requerida" }]}>
                                <DatePicker.RangePicker style={{ width: "100%" }} format={DATE_FORMATS.DATE_MOMENT_FORMAT_SLASH_TO_STR} placeholder={["Fecha inicial", "Fecha final"]} />
                            </Form.Item>
                            <Form.Item name="horaInicial" label="Hora" style={{ flex: 1 }} rules={[{ required: true, message: "La hora es requerida" }]}>
                                <TimePicker style={{ width: "100%" }} format="HH:mm" showNow={false} placeholder="Selecciona la hora" />
                            </Form.Item>
                            <Form.Item name="descripcion" label="¿Qué te recordaremos?" style={{ flex: 1 }} rules={[{ required: true, message: "La descripcion es requerida" }]}>
                                <Input.TextArea style={{ width: "100%" }} showCount={true} rows={3} />
                            </Form.Item>
                        </Form>
                        : null
                    }
                    {tipoRecordatorio === 3 ?
                        <Form layout="vertical" form={formRecordatorio}>
                            <Form.Item name="horaInicial" label="Hora" style={{ flex: 1 }} rules={[{ required: true, message: "La hora es requerida" }]}>
                                <TimePicker style={{ width: "100%" }} format="HH:mm" showNow={false} placeholder="Selecciona la hora" />
                            </Form.Item>
                            <Form.Item name="descripcion" label="¿Qué te recordaremos?" style={{ flex: 1 }} rules={[{ required: true, message: "La descripcion es requerida" }]}>
                                <Input.TextArea style={{ width: "100%" }} showCount={true} rows={3} />
                            </Form.Item>
                        </Form>
                        : null
                    }
                </Row>
            </Modal>

            <Carousel
                // afterChange={onChange}
                dots={{ className: styles.carouselDots }}
            >
                <div className={`${styles.carouselPanel} ${styles.contactoWrapper}`}>
                    <Row style={{ justifyContent: "space-between", marginBottom: 10 }}>
                        <ClientesPopoverCard popoverPlacement={"left"} con_id={contacto?.usu_id} />
                        <div style={{ flex: 1, display: "flex", marginLeft: 10, flexDirection: "column" }}>
                            <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <Rate defaultValue={5} />
                                <Space>
                                    <Button size="small" type="primary" shape="circle" icon={<ClockCircleOutlined />} onClick={handleClickShowNuevoRecordatorio} />
                                    <div className={styles.coinWrapper}>
                                        <img src={Coin24} width={20} height={20} alt="koins" />{" "}
                                        <span style={{ fontWeight: 800, marginLeft: 2 }}>0</span>
                                    </div>
                                </Space>
                            </Row>
                            <Row style={{ marginTop: 3 }}>
                                <Typography.Text>{empresa?.emp_razonsocial}</Typography.Text>
                            </Row>
                        </div>
                    </Row>
                    <Row style={{ margin: "5px 10px" }}>
                        <Space style={{ width: "100%", flexDirection: "column" }}>
                            <Row style={{ justifyContent: "space-between" }}>
                                <div>
                                    <Typography.Text strong>
                                        {contacto?.usu_nombres} {contacto?.usu_apaterno} {contacto?.usu_amaterno}
                                    </Typography.Text>
                                    <Button type="link" onClick={handleClickEditarContacto} icon={<EditOutlined />} style={{ textDecoration: "none" }} />
                                </div>
                                <Popover content={telefonosPopoverContent} title="Teléfonos" placement="left" overlayClassName={styles.telefonosOverlay} overlayInnerStyle={{ margin: 0, width: "100%" }}>
                                    {
                                        contacto?.telefonos?.length > 0 &&
                                        <TelefonoItem usu_tel={contacto?.usu_tel} telefono={contacto?.telefonos[0]} />
                                    }
                                    {
                                        contacto?.telefonos?.length === 0 &&
                                        <TelefonoItem usu_tel={contacto?.usu_tel} telefono={null} />
                                    }
                                </Popover>
                            </Row>
                            {/* <Row style={{ justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <PhoneOutlined style={{ color: "#1890FF" }} />
                                    <Typography.Link strong href="#" target="_blank">
                                        (442)577-2565
                                    </Typography.Link>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <PhoneOutlined style={{ color: "#1890FF" }} />
                                    <Typography.Link strong href="#" target="_blank">
                                        (442)577-2565
                                    </Typography.Link>
                                </div>
                            </Row> */}
                            <Row style={{ justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <MailOutlined style={{ color: "#1890FF" }} />
                                    <Typography.Link strong href={`mailto:${contacto?.usu_mail}`} target="_blank">
                                        {contacto?.usu_mail}
                                    </Typography.Link>
                                </div>
                            </Row>
                            <Row>
                                <Form form={form} style={{ width: "100%" }}>
                                    <Form.Item name="nota" rules={[{ required: true, message: "La nota es requerida" }]}>
                                        <Input.TextArea
                                            showCount
                                            className={styles.textarea}
                                            rows={3}
                                            placeholder="Nota rápida"
                                            onKeyDown={handleKeyDownNotaRapida}
                                        />
                                    </Form.Item>
                                </Form>
                            </Row>
                        </Space>
                    </Row>
                </div>
                <div className={styles.carouselPanel}>
                    <Row style={{ marginBottom: 15, justifyContent: "space-between" }}>
                        <Typography.Title level={5}>Empresa</Typography.Title>
                        <Button size="small" shape="circle" type="primary" icon={<EditOutlined />} style={{ marginRight: 10 }} onClick={showEditarCliente} />
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "flex-start", color: "#1890FF" }}>
                            <BiBuildings fontSize={42} />
                        </div>
                        <div style={{ flex: 1, marginLeft: 10, display: "flex", flexDirection: "column" }}>
                            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography.Text strong>{empresa?.emp_rfc || "-"}</Typography.Text>
                                    <Typography.Text>{empresa?.emp_razonsocial || "-"}</Typography.Text>
                                </div>
                                <div style={{ paddingRight: 20 }}>
                                    <Tooltip title="Ver lista de contactos" placement="left" >
                                        <Button type="text" style={{ width: "fit-content", height: "auto", paddingTop: 5 }} onClick={showListaContactos}>
                                            <ContactsTwoTone style={{ fontSize: 30 }} />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Row>
                            <Divider style={{ margin: "10px 0" }} />
                            {
                                empresa?.emp_padreid &&
                                <>
                                    <Typography.Text style={{ color: "#1890FF" }}>Corporativo</Typography.Text>
                                    <Typography.Text style={{ display: "flex", gap: 5 }}>
                                        <div style={{ fontWeight: 600 }}>{empresa?.corporativo?.emp_rfc}</div>{" - "}
                                        {empresa?.corporativo?.emp_razonsocial}
                                    </Typography.Text>
                                </>
                            }
                            <Typography.Text style={{ color: "#1890FF" }}>Dirección</Typography.Text>
                            <Typography.Text>
                                {empresaDomicilioPrincipal?.dom_calle} {" "}
                                ext. {empresaDomicilioPrincipal?.dom_exterior} {" "}
                                {empresaDomicilioPrincipal?.dom_interior ? `int. ${empresaDomicilioPrincipal?.dom_interior}, ` : " "}
                                {empresaDomicilioPrincipal?.dom_cp}, {empresaDomicilioPrincipal?.dom_colonia}{", "}
                                {empresaDomicilioPrincipal?.dom_municipio}{", "}{empresaDomicilioPrincipal?.dom_estado}
                            </Typography.Text>
                        </div>
                    </Row>
                </div>
            </Carousel>

        </div>
    );
};

export default DatosContacto;
