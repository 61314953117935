import { Row, Typography, Col, Button, Result } from "antd";
import { motion } from "framer-motion";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import { useEffect } from "react";
import { setConfig } from "../../redux/actions/config.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = createUseStyles({
    mainContainer: {
        minHeight: "100vh"
    }
});

const Error404 = () => {
    const styles = useStyles();
    const { motionVariants, configuracion } = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setConfig({
            sideBar: {
                ...configuracion.sideBar,
                visible: false
            }
        }));
    }, [])

    return (
        <motion.div initial="out" animate="in" exit="out" delay={200} variants={motionVariants}>

            <Row align="middle" className={styles.mainContainer}>
                <Col span={24}>
                    <Row>
                        <Result
                            style={{ width: "100%" }}
                            status="404"
                            title="404 - Recurso no encontrado"
                            subTitle="Lo sentimos pero no pudimos encontrar lo que estas buscando."
                            extra={<Button type="primary" shape="round" onClick={() => history.push("/home_menu")}>Ir al menú de inicio</Button>}
                        />
                    </Row>
                    {/* <Row justify="center" align="middle">
                        <Typography.Title>Módulo no disponible</Typography.Title>
                    </Row>
                    <Row justify="center" align="middle">
                        <Link to="/home_menu">Ir al menu de inicio</Link>
                    </Row> */}
                </Col>
            </Row>
        </motion.div>
    );
};

export default Error404;
