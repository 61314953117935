import React, {useEffect, useState} from "react";
import { createUseStyles } from "react-jss";
import { Row, Col } from "antd";
import { TicketStatus } from "../../../@enums/Tickets.enums";
import { TipoIncidencia } from "../../../@enums/TicketsIncidencias.enums";

const useStyles = createUseStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "15px 10px",
    },
    title_time: {
        marginLeft: 5,
        color: '#696969',
        fontWeight: 500,
        fontSize: 16
    },
    text_time: {
        marginLeft: 5,
        color: `${theme.secondaryColor}`,
        fontWeight: 500,
        fontSize: 14
    },
    label_time: {
        marginLeft: 5,
        color: '#696969',
        fontSize: 14
    }
}));

const Marketing = ({ticket}) => {

    const styles = useStyles();
    const [contacto, setContacto] = useState(null);
    const [servicio, setServicio] = useState(null);

    useEffect(() => {
        let ticket_tomado = ticket?.incidencias?.find(inc => inc?.tipi_id === TipoIncidencia.TICKET_TOMADO)?.tici_fecha;
        let ticket_cerrado = ticket?.status?.stat_id > TicketStatus.ESPERA_CLIENTE && ticket?.status?.stat_id < TicketStatus.ESPERA_FABRICANTE ? true : false;
        if(ticket_tomado){
            let tiempo_creado = ticket.tick_fecharegistro;
            calculateTime(tiempo_creado, ticket_tomado, setContacto)
        }
        if(ticket_cerrado){
            let time_close = ticket?.incidencias?.find(inc => inc?.status?.stat_id > TicketStatus.ESPERA_CLIENTE && ticket?.status?.stat_id < TicketStatus.ESPERA_FABRICANTE)?.tici_fecha;
            calculateTime(ticket_tomado, time_close, setServicio)
        }
    }, [])

    const calculateTime = (date_1, date_2, setData) => {
        const fechaInicio = new Date(date_1).getTime();
        const fechaFin = new Date(date_2).getTime();
        const diferenciaEnMilisegundos = fechaFin - fechaInicio;

        const segundos = Math.floor((diferenciaEnMilisegundos / 1000) % 60);
        const minutos = Math.floor((diferenciaEnMilisegundos / (1000 * 60)) % 60);
        const horas = Math.floor((diferenciaEnMilisegundos / (1000 * 60 * 60)) % 24);
        const dias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

        let resultado = '';
    
        if (dias > 0) {
            resultado += `${dias}d : `;
        }
    
        if (horas > 0) {
            resultado += `${horas}h : `;
        }

        resultado += `${minutos}m : ${segundos}s`;

        setData(resultado)
    }

    return(
        <div
            className={styles.container}
        >
            <Row>
                <Col span={24}>
                    <span className={styles.title_time}>Tiempos</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span className={styles.label_time}>Primer contacto:</span>
                    {contacto !== null ? (
                        <span className={styles.text_time}>{contacto}</span>
                    ): (
                        <span className={styles.text_time}>Registro pendiente</span>
                    )}
                </Col>
            </Row>
            <Row style={{marginBottom:10}}>
                <Col span={24}>
                    <span className={styles.label_time}>Tiempo de servicio:</span>
                    {servicio !== null ? (
                        <span className={styles.text_time}>{servicio}</span>
                    ): (
                        <span className={styles.text_time}>Registro pendiente</span>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default Marketing;