import React, {useState, useEffect} from "react";
import TablaData from "./tablaData/tablaData";
import { GetCompanyContact } from "../../hooks/api/getCompanyWhitContact";
import { GetAllUsers } from "../../hooks/api/getAllusuarios";
import { Row, Col } from "antd";
import fixtures from "./fixtures";
import './styles.css';

const CarteraClientes = () => {

    const {getAllUsersMutation} = GetAllUsers();
    const {getCompanyContact} = GetCompanyContact();

    const [columns, setColumns] = useState(fixtures().columns)
    const [dataSource, setDataSource] = useState(null)

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const fetchData = async () => {
            try {
                const asesoresData =  await getAsesores();
                const companiesData = await getCompanies();
                if(companiesData !== undefined){
                    getDataSource(asesoresData, companiesData)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }

    const getAsesores = () => {
        return new Promise((resolve, reject) => {
          getAllUsersMutation({}, {
            onSuccess: (data) => {
              resolve(data);
            },
            onError: (error) => {
              console.log(error);
              reject(error);
            }
          });
        });
    };
    const getCompanies = () => {
        return new Promise((resolve, reject) => {
            getCompanyContact({}, {
            onSuccess: (data) => {
                resolve(data);
            },
            onError: (error) => {
                console.log(error);
                reject(error);
            }
            });
        });
    };
    const getDataSource = (asesores, companies) => {
        let  dataTable;
        if(companies.length > 0) {
            dataTable = companies.map((company) => {
                let asesor = asesores.find(asesor => asesor.usu_id === company.emp_vendedor);
                let contacto;
                if(company.usuarios_empresas.some(user => user.use_principal === true)){
                    contacto = company.usuarios_empresas.find(user => user.use_principal === true);
                }else{
                    contacto = company.usuarios_empresas[0]
                }
                return {
                    asesor: asesor !== undefined ? `${capitalizeFirstLetter(asesor.usu_nombres)} ${capitalizeFirstLetter(asesor.usu_apaterno)} ` : 'S/A',
                    company: company.emp_razonsocial,
                    rfc: company.emp_rfc,
                    contact: contacto !== undefined ? `${capitalizeFirstLetter(contacto.usuario.usu_nombres)} ${capitalizeFirstLetter(contacto.usuario.usu_apaterno)} ` : 'S/C',
                    phone: contacto !== undefined ? contacto.usuario.usu_tel : '',
                    email: contacto !== undefined ? contacto.usuario.usu_mail : '',
                    tipe: 'Anual',
                    status: company.emp_estatus === 1 ? 'Activo':'Inactivo',
                    policy: '10:35'
                }
            })
        }else{
            dataTable = []
        }

        setDataSource(dataTable)
    }
    
    
    function capitalizeFirstLetter(str) {
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    return(
        <div className="container">
            <Row>
                <Col span={24}>
                    {
                    dataSource !== null ? (
                        <TablaData colums={columns} datasource={dataSource}/>
                        ):(<span>No existen datos</span>)
                    }
                </Col>
            </Row>
        </div>
    )
}

export default CarteraClientes