import { useState } from "react";

const useTabs = (props) => {
    const [activeKey, setActiveKey] = useState(props?.initialActiveKey || null);
    const [tabContent, setTabContent] = useState(null);

    return { activeKey, setActiveKey, tabContent, setTabContent };
};

export default useTabs;
