import axios from "axios";
import reduxConfig from "../redux/store";

/**
 * Nota: no toma en cuenta archivos
 * 
 * @param {Object} obj - objeto de datos que se va a transformar en {FormData} 
 * @param {Boolean} [delNulls=false] - indica si se van a conservar los valores nulos o no  
 * @returns {FormData} Retorna un form data con los datos especificados
 */
export const objectToFormData = (obj, delNulls = false) => {
    var form_data = new FormData();
    for (var key in obj) {
        if (delNulls && !!obj[key])
            form_data.append(key, obj[key]);
        else if (!delNulls)
            form_data.append(key, obj[key]);
    }
    return form_data;
};

/**
 * Nota: solo se toma en cuenta el primer nivel , no se aplica a sub objetos
 * 
 * @param {Object} obj - objeto de valores que se va a limpiar
 * @returns {Object} Retorna el mismo objeto sin propiedades nulas
 */
export const clearNullProperties = (obj) => {
    for (var key in obj) {
        if (!obj[key])
            delete obj[key];
    }
    return obj;
}


export const checkMail = (mail) => {
    return new Promise((resolve, reject) => {
        const apiHost = reduxConfig.store.getState().app.apiHost;
        axios
            .get(`${apiHost}/registro/mailcheck/${mail}`)
            .then((response) => {
                if (response.data.data) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                reject(err);
                console.error(err);
            });
    })
};