import { useSelector } from "react-redux";
import { useUsuariosNotificacionesMutation } from "../../hooks/api/usuarios_notificaciones";
import { NotificationItem } from "./NotificationItem";
import { Row, Typography } from "antd";
import { useHistory } from "react-router-dom";

/**
 * 
 * @param {Object} props
 * @param {Object} props.notificacion - objeto de la notificacion de recordatorio
 * @returns 
 */
const NotificacionInfo = ({ notificacion }) => {
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    const { mutateUsuariosNotificaciones } = useUsuariosNotificacionesMutation();

    const handleClickEliminarNotificación = (id) => {
        mutateUsuariosNotificaciones({
            mutationType: "delete",
            usu_id: user.usu_id,
            noti_id: id
        })
    }

    const getBotones = (notificacion) => {
        return [
            {
                type: "primary",
                text: "OK",
                onClick: (e) => {
                    e.stopPropagation();
                    handleClickEliminarNotificación(notificacion.noti_id);
                }
            }
        ];
    }

    const handleClickNotificacion = () => {
        if (notificacion?.noti_url) {
            history.push(notificacion?.noti_url)
        }
    }

    return (
        <NotificationItem
            key={`${notificacion.noti_titulo}-${notificacion.noti_id}`}
            type={notificacion.noti_tipo}
            notificacion={notificacion}
            buttons={getBotones(notificacion)}
            onClick={handleClickNotificacion}
        >
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <Row style={{ flexDirection: "column" }}>
                    <div dangerouslySetInnerHTML={{ __html: notificacion?.noti_descripcion }} />
                </Row>
            </div>
        </NotificationItem>
    );
}

export default NotificacionInfo;