import { useSelector } from "react-redux";
import { useUsuariosNotificacionesMutation } from "../../hooks/api/usuarios_notificaciones";
import { useUsuariosRecordatoriosMutation } from "../../hooks/api/usuarios_recordatorios";
import { NotificationItem } from "./NotificationItem";
import { useQueryUtils } from "../../hooks/api/utils";
import { TipoNotificacion } from "../../@enums/UsuariosNotificaciones.enums";
import { TipoRecordatorio } from "../../@enums/UsuariosRecordatorios.enums";
import { Row, Tag, Typography } from "antd";
import { useTheme } from "react-jss";
import { DATE_FORMATS, convertToSystemDateTimeZone } from "../../helpers/dates.helper";

/**
 * 
 * @param {Object} props
 * @param {Object} props.notificacion - objeto de la notificacion de recordatorio
 * @returns 
 */
export const NotificacionRecordatorio = ({ notificacion }) => {
    const { user } = useSelector((state) => state.auth);
    const theme = useTheme();
    const { mutateUsuariosRecordatorios } = useUsuariosRecordatoriosMutation();
    const { mutateUsuariosNotificaciones } = useUsuariosNotificacionesMutation();
    const { invalidateQuery } = useQueryUtils();


    const eliminarRecordatorio = (recordatorio) => {
        mutateUsuariosRecordatorios({
            mutationType: "delete",
            usu_id: user.usu_id,
            reco_id: recordatorio.reco_id
        })
        invalidateQuery(["usuarios", "recordatorios"])
    }

    const handleClickEliminarNotificación = (id) => {
        mutateUsuariosNotificaciones({
            mutationType: "delete",
            usu_id: user.usu_id,
            noti_id: id
        })
    }

    const getBotonesRecordatorios = (notificacion) => {
        if (notificacion.noti_tipo === TipoNotificacion.RECORDATORIO) {
            if (notificacion.recordatorio.reco_tipo === TipoRecordatorio.DIA_ESPECIFICO) {
                return [
                    {
                        type: "primary",
                        text: "OK",
                        onClick: () => {
                            handleClickEliminarNotificación(notificacion.noti_id);
                            eliminarRecordatorio(notificacion.recordatorio)
                        }
                    }
                ];
            } if (notificacion.recordatorio.reco_tipo === TipoRecordatorio.PERIODO || notificacion.recordatorio.reco_tipo === TipoRecordatorio.DIARIO) {
                return [
                    {
                        type: "link",
                        text: "Eliminar recordatorio",
                        onClick: () => {
                            handleClickEliminarNotificación(notificacion.noti_id);
                            eliminarRecordatorio(notificacion.recordatorio)
                        }
                    },
                    {
                        type: "primary",
                        text: "OK",
                        onClick: () => {
                            handleClickEliminarNotificación(notificacion.noti_id);
                        }
                    }
                ];
            }
        }
    }

    return (
        <NotificationItem
            key={`${notificacion.noti_titulo}-${notificacion.noti_id}`}
            type={notificacion.noti_tipo}
            notificacion={notificacion}
            buttons={getBotonesRecordatorios(notificacion)} >
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <Row style={{ justifyContent: "space-between", }}>
                    <Typography.Text strong style={{ color: theme.secondaryColor }}>
                        Detalle
                    </Typography.Text>
                    <div>
                        <Tag color="blue" style={{ width: "fit-content", borderRadius: 15, fontSize: 10, margin: 0 }}>{convertToSystemDateTimeZone(notificacion?.recordatorio?.reco_fechainicial)?.toFormat(DATE_FORMATS.DATETIME_LUXON_FORMAT_TO_STR)}</Tag>
                        {
                            notificacion?.recordatorio?.reco_tipo === 2 ? ` - ` : null
                        }
                        {
                            notificacion?.recordatorio?.reco_tipo === 2 &&
                            <Tag color="blue" style={{ width: "fit-content", borderRadius: 15, fontSize: 10, margin: 0 }}>{convertToSystemDateTimeZone(notificacion?.recordatorio?.reco_fechafinal)?.toFormat(DATE_FORMATS.DATETIME_LUXON_FORMAT_TO_STR)}</Tag>
                        }
                    </div>
                </Row>
                <Row style={{ flexDirection: "column" }}>
                    <Typography.Text>{notificacion.noti_descripcion}</Typography.Text>
                </Row>
                <Row>
                </Row>
            </div>
        </NotificationItem>
    );
}