import { Button, Form, Input, message, Row, Space, Typography, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { styles } from "./styles";
import { useSistemaVersionUpdateMutation } from "../../../hooks/api/sistemas_versiones";
import RecursosManager from "../../Recursos/RecursosManager";
import { useQueryClient } from "react-query";
import { CopyOutlined } from "@ant-design/icons";
import CopiarVersion from "./CopiarVersion";

const useStyles = createUseStyles(styles);

const ActualizarSistemaVersion = ({ versionEnUso: version, closeModal, sistemas }) => {
    const styles = useStyles();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [isCopying, setIsCopying] = useState(false); // flag for show copy version component 
    const { mutateSistemaVersionUpdate } = useSistemaVersionUpdateMutation();

    const handleGuardarSistemaVersion = () => {
        if (version) {
            form.validateFields()
                .then((values) => {
                    // actualizar la version
                    mutateSistemaVersionUpdate({ ...values, csiv_id: version?.csiv_id }, {
                        onSuccess: res => {
                            if (res.success) {
                                message.success(res.mensaje);
                            } else {
                                message.error(res.mensaje)
                            }
                        },
                        onError: () => {
                            message.error("Hubo un problema al actualizar la version")
                        }
                    })
                })
                .catch((err) => {
                    console.log(err);
                    message.error("El nombre del sistema es requerido");
                });
        } else throw Error("datos de la versión del sistema es requeriod");
    };

    const handleSaveNewLicenciaFiles = () => {
        queryClient.refetchQueries(["sistemas", "versiones", version?.sis_id]);
    }

    const handleDeleteLicenciaFile = () => {
        queryClient.refetchQueries(["sistemas", "versiones", version?.sis_id]);
    }

    const handleCopyVersion = () => {
        setIsCopying(true);
    }

    useEffect(() => {
        if (version) {
            form.setFieldsValue(version);
        }
    }, [version]);


    return (
        <Space className={styles.formWrapper} size={10}>
            {
                isCopying ? <CopiarVersion version={version} sistemas={sistemas} closeModal={closeModal} /> :
                    <>
                        <Row justify={"end"}>
                            {/* <Typography.Title level={5}>Actualizar Versión</Typography.Title> */}
                            <Button
                                type="primary"
                                shape="round"
                                icon={<CopyOutlined />}
                                style={{
                                    borderRadius: 20,
                                }}
                                onClick={handleCopyVersion}
                            >
                                Copiar versión
                            </Button>
                        </Row>
                        <Row>
                            <Form form={form} layout="vertical">
                                <Form.Item name="csiv_version" label="Versión del sistema" rules={[{ required: true, message: "La versión es requerida." }]}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name="csiv_descripcion" label="Descripción de la versión">
                                    <Input.TextArea showCount maxLength={150} autoSize />
                                </Form.Item>
                                <Form.Item name="csiv_observaciones" label="Observaciones de la versión">
                                    <Input.TextArea showCount maxLength={300} autoSize />
                                </Form.Item>
                                <Form.Item name="csiv_errores" label="La versión tiene errores" valuePropName="checked" className={styles.switchFormControl}>
                                    <Switch />
                                </Form.Item>
                                <Form.Item name="csiv_parche" label="La versión necesita parche" valuePropName="checked" className={styles.switchFormControl}>
                                    <Switch />
                                </Form.Item>
                            </Form>
                        </Row>
                        <Row style={{ justifyContent: "end", gap: 10 }}>
                            <Button type="secondary" onClick={closeModal}>
                                Cancelar
                            </Button>
                            <Button type="primary" onClick={handleGuardarSistemaVersion} loading={false}>
                                Guardar datos generales
                            </Button>
                        </Row>
                        <Row>
                            <RecursosManager
                                catalogoFieldId="csiv_id"
                                catalogoId={version?.csiv_id}
                                recursos={version?.recursos || []}
                                allowDeleteFile
                                allowAddFiles
                                // onChangeFiles={handleChangeFiles}
                                limit={1}
                                allowedExtensions={["pdf"]}
                                useApi={true}
                                onSaveResources={handleSaveNewLicenciaFiles}
                                onDeleteFile={handleDeleteLicenciaFile}
                            />
                        </Row>
                    </>
            }
        </Space>
    );
};

export default ActualizarSistemaVersion;
