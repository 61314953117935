// @ts-nocheck
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetDataGraficaACtividades = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/graficas-actividades`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDataGraficaACtividadesMutation: mutation.mutate,
    };
}
export const GetDataGraficaACtividadesByAsesor = ()  => {
    const api = useApi();

    const mutate = async (id) => {
        console.log(id)
        const data  = await api.get(`/graficas-actividades/asesor/${id.id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDataGraficaACtividadesMutationByAsesor: mutation.mutate,
    };
}
export const GetDataGraficaACtividadesByAreaDep = ()  => {
    const api = useApi();

    const mutate = async (id) => {
        console.log(id)
        const data  = await api.get(`/graficas-actividades/area_dep/${id.id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDataGraficaACtividadesMutationByAreaDep: mutation.mutate,
    };
}
export const GetDataGraficaACtividadesByDates = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/graficas-actividades/fechas/${params.start}?end=${params.end}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDataGraficaACtividadesMutationByDates: mutation.mutate,
    };
}
export const GetDataGraficaACtividadesByAsesorDate = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/graficas-actividades/asesor/${params.id}?start=${params.start}&end=${params.end}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDataGraficaACtividadesMutationByAsesorDate: mutation.mutate,
    };
}
export const GetDataGraficaACtividadesByAreaDepDate = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/graficas-actividades/area_dep/${params.id}?start=${params.start}&end=${params.end}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDataGraficaACtividadesMutationByAreaDepDate: mutation.mutate,
    };
}