import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Row, Select, Spin, Table, Tooltip, Typography } from 'antd';
import { createUseStyles } from 'react-jss';
import Elevation from '../../../components/Elevation';
import { useModal } from '../../../hooks/useModal';
import { useHistory } from "react-router-dom"
import { useApiEmpresas, useEmpresasMutation, useUpdateEmpresa } from '../../../hooks/api/empresas';
import { useQueryClient } from 'react-query';

const useStyles = createUseStyles({
    container: {
        padding: 15,
        flex: 1,
        height: "100%",
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    modalContainer: {
        "& .ant-form-item": {
            flex: 1
        }
    }
});

const EmpresasCorporativo = ({ corporativo }) => {
    const styles = useStyles();
    const [visible, showModal, closeModal] = useModal();
    const history = useHistory();
    const { data: empresas } = useApiEmpresas({ initialData: [], params: { only_corporativo_available: true } });
    const { mutateEmpresa } = useEmpresasMutation();
    const { isUpdatingEmpresa, updateEmpresa } = useUpdateEmpresa();
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const agregarEmpresa = () => {
        form.validateFields()
            .then((values) => {
                mutateEmpresa(
                    { ...values, mutationType: "update", emp_padreid: corporativo?.emp_id },
                    {
                        onSuccess: (res) => {
                            if (res?.success) {
                                message.success(res.mensaje);
                            } else {
                                message.error("hubo un problema al relacionar la empresa");
                            }
                            closeModal()
                        },
                        onError: () => {
                            message.error("hubo un problema al relacionar la empresa");
                            closeModal()
                        },
                    }
                );
            })
            .catch(() => {
                message.error("hubo un problema al relacionar la empresa");
            });
    }

    const goToEmpresa = (id) => {
        history.push(`/empresas/${id}`);
    }

    const eliminarRelacionEmpresaCorporativo = (empresa) => {
        updateEmpresa(
            { emp_padreid: null, emp_id: empresa.emp_id },
            {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje);
                        queryClient.invalidateQueries(["empresas", "corporativos"]);
                        queryClient.invalidateQueries(["empresa"]);
                        queryClient.invalidateQueries(["empresas"]);
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al quitar la empresa del corporativo");
                }
            });
    }

    const showAgregarEmpresa = () => {
        form.resetFields();
        showModal();
    }

    let columns = [
        {
            dataIndex: "emp_rfc",
            title: "RFC",
            render: (rfc, empresa) => <Button type="link" onClick={() => goToEmpresa(empresa?.emp_id)}>{rfc}</Button>
        },
        {
            dataIndex: "emp_razonsocial",
            title: "Razón social"
        },
        {
            dataIndex: "emp_nombrecomercial",
            title: "Nombre comercial",
        },
        {
            title: "Acciones",
            render: (_, empresa) => {
                return <div style={{ display: "flex", gap: 15 }}>
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => eliminarRelacionEmpresaCorporativo(empresa)} />
                </div>
            }
        }
    ]

    return (
        <>
            {/* <ConfirmationModal
                icon={<WarningOutlined
                     style={{ color: "gold", fontSize: 20 }} />}
                title="Advertencia"
                description="La licencia se borrará permanentemente , ¿Deseas continuar?"
                visible={confirmationVisible}
                close={closeConfirmation}
                onOk={eliminarLicencia}
                onCancel={closeConfirmation}
                loading={loading}
            /> */}

            <Elevation className={styles.container}>

                <Spin spinning={isUpdatingEmpresa}>
                    <Row style={{ justifyContent: "space-between" }}>
                        <Typography.Title level={5}>Empresas del corporativo</Typography.Title>
                        <div className="section-buttons" style={{ width: "auto" }}>
                            <Tooltip title="Agregar empresa" placement='left' >
                                <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={showAgregarEmpresa} />
                            </Tooltip>
                        </div>
                    </Row>
                    <Row>
                        <Table dataSource={corporativo?.empresas_hijas || []} columns={columns} style={{ width: "100%" }} />
                    </Row>


                    {/* modal para crear la licencia de la empresa */}
                    <Modal title="Agregar empresa al corporativo" open={visible} footer={null} onCancel={closeModal} destroyOnClose width="350px">
                        <div className={styles.modalContainer}>
                            <Form layout='vertical' form={form} >
                                <Form.Item name="emp_id" label="Empresa" rules={[{ required: true, message: "La empresa es requerida" }]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.join("").toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {
                                            empresas.map((emp) => <Select.Option value={emp.emp_id} key={`empresa_${emp.emp_id}`}>{emp.emp_rfc} - {emp.emp_razonsocial}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                            <Row style={{ justifyContent: "end", marginTop: 15 }}>
                                <Button type="primary" onClick={agregarEmpresa}>Agregar empresa</Button>
                            </Row>
                        </div>
                    </Modal>

                </Spin>

            </Elevation>
        </>
    );
}

export default EmpresasCorporativo;