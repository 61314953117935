
import { Button, Col, Modal, Row, Spin, Tooltip, Typography, message } from "antd";
import React, { useEffect, useState, version } from "react";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useApiSistemas } from "../../hooks/api/sistemas";
import { VscVersions } from "react-icons/vsc";
import { useApiSistemasVersiones, useSistemaVersionDeleteMutation } from "../../hooks/api/sistemas_versiones";
import DataTable, { DataTableSortOrder } from "../../components/DataTable";
import { useModal } from "../../hooks/useModal";
import { CheckCircleOutlined, CheckOutlined, DeleteOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import CrearSistemaVersion from "./CrearSistemaVersion";
import { getKeySistemaClasificacion, getKeySistemaSubClasificacion } from "../../@enums/Sistemas.enum";
import CrearSistema from "./CrearSistema";
import ActualizarSistema from "./ActualizarSistema";
import { createUseStyles, useTheme } from "react-jss";
import ActualizarSistemaVersion from "./ActualizarSistemaVersion";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { useQueryClient } from "react-query";
import DataTableHeader from "../../components/DataTable/DataTableHeader";

const useStyles = createUseStyles({
    table: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
});

const Sistemas = () => {
    const styles = useStyles();
    const theme = useTheme();
    const queryClient = useQueryClient();
    const [ShowModalVersiones, setShowModalVersiones] = useState(false);
    const [sistemasModalVisible, showSistemasModal, closeSistemasModal] = useModal();
    const [actualizarSistemaModalVisible, showActualizarSistemaModal, closeActualizarSistemaModal] = useModal();
    const [crearVersionModalVisible, showCrearVersionModal, closeCrearVersionModal] = useModal();
    const [editarVersionModal, showEditarVersion, closeEditarVersion] = useModal();
    const [eliminarVersionConfirmationVisible, showEliminarVersionConfirmation, closeEliminarVersionConfirmation] = useModal();
    const [sistemaEnUso, setSistemaEnUso] = useState(null);
    const [versionEnUso, setVersionEnUso] = useState(null);
    const [filteredSystems, setFilteredSystems] = useState([]);
    const [search, setSearch] = useState(false)
    const { mutateSistemaVersionDelete, isLoadingSistemaVersionDeleteMutation } = useSistemaVersionDeleteMutation();

    const { isLoading: isLoadingSistemas, data: sistemas } = useApiSistemas({
        initialData: [],
    });
    const {
        data: sistemasVersiones,
        refetch: refetchSistemasVersiones,
        isLoading: isLoadingSistemasVersiones
    } = useApiSistemasVersiones(
        {
            initialData: [],
        },
        sistemaEnUso?.sis_id
    );
    const handleClickVerVersiones = (data) => {
        refetchSistemasVersiones(null);
        setSistemaEnUso(data);
        setShowModalVersiones(true);
    };

    const buttonColumnTemplate = (sistema) => {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Tooltip title="Ver versiones" placement="left">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<VscVersions fontSize={0} />}
                        style={{ fontSize: 0, margin: 0 }}
                        onClick={e => {
                            handleClickVerVersiones(sistema)
                            e.stopPropagation();
                        }}
                    />
                </Tooltip>
            </div>
        );
    };

    const closeModal = () => {
        setShowModalVersiones(false);
        setSistemaEnUso(null);
        setVersionEnUso(null);
    };

    const handleRowSistemaClick = (sistema) => {
        setSistemaEnUso(sistema);
        showActualizarSistemaModal();
    }

    const renderCheckErroresSistemaVersion = (csiv_errores) => {
        return csiv_errores ?
            <div style={{ textAlign: "center" }}>
                <CheckCircleOutlined style={{ color: "#F00", fontSize: 18 }} />
            </div>
            : null;
    }

    const renderCheckParcheSistemaVersion = (csiv_errores) => {
        return csiv_errores ?
            <div style={{ textAlign: "center" }}>
                <CheckCircleOutlined style={{ color: theme.terciaryColor, fontSize: 18 }} />
            </div>
            : null;
    }

    const handleClickVersion = (version) => {
        setVersionEnUso(version);
        showEditarVersion();
    }

    const handleClickEliminarVersion = (e, version) => {
        e.stopPropagation();
        setVersionEnUso(version);
        showEliminarVersionConfirmation();
    }

    const renderEliminarVersionBtn = (_, version) => {
        return (
            <div>
                <Tooltip title="Eliminar versión">
                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={e => handleClickEliminarVersion(e, version)} />
                </Tooltip>
            </div>
        );
    }

    const eliminarVersion = () => {
        if (versionEnUso) {
            // se manda la soliciitud al server
            mutateSistemaVersionDelete(versionEnUso?.csiv_id, {
                onSuccess: res => {
                    if (res.success) {
                        message.success(res.mensaje)
                        // se invalidan los datos de las versiones q    ue se tiene actualmente en el estado
                        refetchSistemasVersiones();
                        closeEliminarVersionConfirmation();
                    } else {
                        message.error(res.mensaje)
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al eliminar la versión")
                }
            })
        } else throw Error("datos de la version requeridos para eliminar el registro.");
    }

    useEffect(() => {
        if (versionEnUso && sistemasVersiones) {
            let ver = sistemasVersiones.find(sv => sv.csiv_id === versionEnUso?.csiv_id);
            if (ver)
                setVersionEnUso(ver)
        }
    }, [sistemasVersiones, versionEnUso])

    useEffect(() => {
        if (sistemas && sistemaEnUso) {
            setSistemaEnUso(
                sistemas.find(sis => sis.sis_id === sistemaEnUso.sis_id) || null
            );
        }
    }, [sistemas])

    /**
     * @type {Array<import("../../components/DataTable").DataTableColumn>}
     */
    const sistemaColumns = [
        { index: "sis_id", title: "ID", width: 70 },
        { index: "sis_nombre", title: "Nombre", width: 200, validationRules: { required: true } },
        { index: "sis_clasificacion", title: "Clasificación", width: 100, render: (clasificacion) => getKeySistemaClasificacion(clasificacion) },
        { index: "sis_subclasificacion", title: "Sub clasificación", width: 150, render: (subClasificacion) => getKeySistemaSubClasificacion(subClasificacion) },
        { index: "sis_descripcion", title: "Descripcion", width: 250 },
        { index: "sis_precio", title: "Precio", width: 150, render: (precio) => precio ? `$${precio}` : "" },
        { index: "sis_costo", title: "Costo", width: 150, render: (costo) => costo ? `$${costo}` : "" },
        { title: "Versiones", width: 60, fixed: 'right', render: buttonColumnTemplate }
    ];

    /**
     * @type {Array<import("../../components/DataTable").DataTableColumn>}
     */
    const versionesColumns = [
        { index: "csiv_version", title: "Version", width: 70, defaultSortOrder: DataTableSortOrder.ASCEND },
        { index: "csiv_descripcion", title: "Descripción", width: 200 },
        { index: "csiv_observaciones", title: "Observaciones", width: 200 },
        { index: "csiv_errores", title: "Errores", width: 70, render: renderCheckErroresSistemaVersion, fixed: 'right' },
        { index: "csiv_parche", title: "Parche", width: 70, render: renderCheckParcheSistemaVersion, fixed: 'right' },
        { title: "Eliminar", width: 70, render: renderEliminarVersionBtn, fixed: 'right' },
    ]

    return (
        <PageWrapper title="Sistemas">
            <Row style={{ marginTop: 10 }} gutter={16}>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando sistemas ..." spinning={isLoadingSistemas}>
                            <DataTableHeader
                                key={'table-systems'}
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: showSistemasModal
                                    }
                                ]}
                                dataToFilter={sistemas}
                                setFilteredData={setFilteredSystems}
                                counter={search ? filteredSystems?.length : sistemas?.length}
                                setSearch={setSearch}
                            />
                            <DataTable
                                scroll={{ x: "100%" }}
                                columns={sistemaColumns}
                                data={search ? filteredSystems : sistemas}
                                onRowClick={handleRowSistemaClick}
                                className={styles.table}
                                paginationParams={{
                                    defaultPageSize: 10,
                                    total: sistemas.length,
                                    showSizeChanger: true,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    position: ["topRight"],
                                }}
                            />
                        </Spin>
                    </Elevation>
                </Col>
            </Row>


            <ConfirmationModal
                icon={<WarningOutlined
                    style={{ color: "gold", fontSize: 20 }} />}
                title="Advertencia"
                description="La versión del sistema se borrará permanentemente , ¿Deseas continuar?"
                visible={eliminarVersionConfirmationVisible}
                close={closeEliminarVersionConfirmation}
                onOk={eliminarVersion}
                onCancel={closeEliminarVersionConfirmation}
                loading={isLoadingSistemaVersionDeleteMutation}
            />

            <Modal
                title="Crear sistema"
                open={sistemasModalVisible}
                onCancel={closeSistemasModal}
                destroyOnClose
                footer={null}
                width="60%"
            >
                <CrearSistema closeCrearSistema={closeSistemasModal} />
            </Modal>

            <Modal
                title="Actualizar datos del sistema"
                open={actualizarSistemaModalVisible}
                onCancel={closeActualizarSistemaModal}
                destroyOnClose
                footer={null}
                width="60%"
            >
                <ActualizarSistema sistema={sistemaEnUso} closeActualizarSistema={closeActualizarSistemaModal} />
            </Modal>


            <Modal
                title="Crear versión del sistema"
                open={crearVersionModalVisible}
                onCancel={closeCrearVersionModal}
                destroyOnClose
                footer={null}
            >
                <CrearSistemaVersion sistemaEnUso={sistemaEnUso} closeModal={closeCrearVersionModal} />
            </Modal>


            <Modal
                title="Editar versión del sistema"
                open={editarVersionModal}
                onCancel={closeEditarVersion}
                destroyOnClose
                footer={null}
            >
                <ActualizarSistemaVersion versionEnUso={versionEnUso} closeModal={closeEditarVersion} sistemas={sistemas} />
            </Modal>

            <Modal title="Ver Versiones" open={ShowModalVersiones} footer={null} onCancel={closeModal} destroyOnClose={true} width="700px">
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Row>
                        <Col>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Sistema
                            </Typography.Title>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                <span style={{ color: "#1890ff" }}>{sistemaEnUso?.sis_nombre}</span>
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Versiones
                        </Typography.Title>
                    </Row>
                    <Spin tip="Cargando versiones ..." spinning={isLoadingSistemasVersiones}>
                        <div style={{ padding: 10, border: "1px solid #eee" }}>
                            <Button type="primary" shape="circle" icon={<PlusOutlined />} size={"small"} onClick={showCrearVersionModal} />
                        </div>
                        <DataTable
                            data={sistemasVersiones}
                            columns={versionesColumns}
                            scroll={{ x: { width: "100%" } }}
                            onRowClick={handleClickVersion}
                        />
                    </Spin>
                </div>
            </Modal>
        </PageWrapper>
    );
};

export default Sistemas;
