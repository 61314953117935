import { Button, Divider, Empty, Image, Modal, Popover, Radio, Row, Tag, Tooltip, Typography, message } from "antd";
import FileDropper from "../../components/FileDropper";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { useDeleteRecurso, useGuardaRecursos } from "../../hooks/api/recursos";
import ArchivoItem from "./ArchivoItem";
import { ETipoRecurso } from "../../@enums/Recursos.enums";
import UrlItem from "./UrlItem";
import FileManager from "./FileManager";
import { usePopover } from "../../hooks/usePopover";
import UrlManager from "./UrlManager";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        border: "1px solid #eee",
        borderRadius: 10
    },
    containerHeader: {
        padding: 10,
        width: "100%",
        borderBottom: "1px solid #eee",
        justifyContent: "space-between",
        alignItems: "center",

    },
    tagTipoRecurso: {
        cursor: "pointer",
        userSelect: "none"
    },
    urlsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        width: "100%"
    }
}));

const RecursoItem = ({
    recurso,
    allowDeleteFile,
    useApi,
    eliminarRecurso
}) => {
    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));

    return (
        <div>
            {
                recurso.recu_tipo === ETipoRecurso.ARCHIVO &&
                <ArchivoItem
                    recurso={recurso}
                    src={`${apiHost}/static/${recurso.recu_ruta}`}
                    allowDeleteFile={allowDeleteFile && useApi}
                    onDeleteFile={eliminarRecurso}
                />
            }
        </div>
    );
}

/**
 * 
 * @param {Object} props 
 * @param {Boolean} [props.isEdittingFiles] - bandera que indica si se estan editando los archivos
 * @param {Object[]} [props.recursos] - recurosos que se van a estar manejando 
 * @param {Number} [props.catalogoId] - valor o id del catalogo que se van a estar manejando  (solo si {@link props.catalogoFieldId} esta presente)
 * @param {String} [props.catalogoFieldId] - campo del id del catalogo que se va a estar manejando para el recurso (solo si {@link props.catalogoId} esta presente)
 * @param {Boolean} [props.allowDeleteFile] - bandera que indica si estara disponible para el usuario el boton para eliminar el recurso
 * @param {Boolean} [props.allowAddFiles] - bandera que indica si estara disponible para el usuario el boton para agregar el recurso
 * @param {Boolean} [props.useApi] - bandera que indica si estará enviando archivos al servidor
 * @param {Function} [props.onDeleteFile] - callback que se ejecuta cuando se elimina un archivo en la api
 * @param {Function} [props.onSaveResources] - callback que se ejecuta cuando se guardan nuevos recursos en la api
 * 
 * @param {Function} [props.onChangeFiles] - callback que se ejecuta cuando los archivos del componente han cambiado (campo para {@link FileDropper})
 * @param {Number} [props.limit] - limite de archivos que se pueden seleccionar  (campo para {@link FileDropper})
 * @param {String[]} [props.allowedExtensions] - extensiones de archivos permitidas (campo para {@link FileDropper})
 * @param {Number} [props.maxFileMBSize] - tamaño máximo en MB por archivo  (campo para {@link FileDropper})
 */
const RecursosManager = ({
    // api
    catalogoId,
    catalogoFieldId,
    allowDeleteFile,
    allowAddFiles,
    recursos,
    useApi,
    onDeleteFile,
    onSaveResources,

    // local
    onChangeFiles,
    limit,
    allowedExtensions,
    maxFileMBSize
}) => {
    const styles = useStyles();
    const [isEdittingResources, setIsEdittingResources] = useState(false);
    const [tRecursos, setTRecursos] = useState({
        files: [],
        urls: []
    });
    /** @type {[Number, React.Dispatch<React.SetStateAction<Number>>]} */
    const [resourceType, setResourceType] = useState(ETipoRecurso.ARCHIVO);
    const [newFiles, setNewFiles] = useState([]);
    const { deleteRecurso, isDeletingRecurso } = useDeleteRecurso();
    const { isSavingRecursos, saveRecursos } = useGuardaRecursos();
    const [resourceTypeOptionsVisible, showResourceTypeOptions, closeResourceTypeOptions] = usePopover();
    const { apiHost } = useSelector((state) => ({
        apiHost: state.app.apiHost,
    }));

    const eliminarRecurso = (recurso) => {
        // validar si se esta usando la accion de eliminar el recurso en la api
        if (useApi && allowDeleteFile) {
            // llamar servicio para eliminar el recurso por id 
            deleteRecurso({ recu_id: recurso.recu_id }, {
                onSuccess: (res) => {
                    if (res.success) {
                        message.success(res.mensaje);
                        if (onDeleteFile) onDeleteFile(recurso);

                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("El recurso no se pudo eliminar");
                }
            });
        }
    }

    const saveFiles = () => {
        if (catalogoFieldId != "" && catalogoFieldId && catalogoId) {
            // se envian los archivos al servidor en caso de que este habilitado el uso de la api
            if (useApi) {
                let fd = new FormData();
                // se agregan los datos extra de los archivos
                fd.append(catalogoFieldId, `${catalogoId}`)

                // se preparan los archivos
                for (let file of newFiles) {
                    fd.append("files[]", file);
                }
                saveRecursos(fd, {
                    onSuccess: (res) => {
                        if (res.success) {
                            message.success(res.mensaje)
                            if (onSaveResources) onSaveResources(fd);
                        } else {
                            message.error(res.mensaje)
                        }
                    },
                    onError: () => {
                        message.error("Hubo un problema la guardar los recursos")
                    }
                })
            }
        }
    }

    const handleClickEditFiles = () => {
        if (isEdittingResources) {
            // se valida el tipo de recurso que se va a agregar
            if (resourceType === ETipoRecurso.ARCHIVO) {
                // se guardan los archivos
                saveFiles();
            }
            setIsEdittingResources(false);
        } else {
            // throw Error("No se puede agregar archivos al registro por que faltan props requeridas de manejador de archivos (RecursosManager)[catalogoFieldId, catalogoId]");
            setIsEdittingResources(true);
        }
    }

    const handleClickResourceType = () => {
        if (!resourceTypeOptionsVisible)
            showResourceTypeOptions();
        else
            closeResourceTypeOptions();
    }

    const handleChangeResourceType = (e) => {
        setResourceType(e.target.value);
        closeResourceTypeOptions();
    }

    const handleChangeFiles = (files) => {
        if (onChangeFiles) onChangeFiles(files);
        setNewFiles(files);
    }

    const popOverResourceTypes = (
        <Radio.Group value={resourceType} style={{ display: "flex", flexDirection: "column", gap: 10 }} onChange={handleChangeResourceType} >
            <Radio value={ETipoRecurso.ARCHIVO}>Archivo</Radio>
            <Radio value={ETipoRecurso.URL}>URL</Radio>
        </Radio.Group>
    );

    useEffect(() => {
        if (recursos) {
            setTRecursos({
                files: recursos.filter(r => r.recu_tipo === ETipoRecurso.ARCHIVO),
                urls: recursos.filter(r => r.recu_tipo === ETipoRecurso.URL)
            })
        }
    }, [recursos]);

    return (
        <div className={styles.container}>
            <Row className={styles.containerHeader}>
                <Typography.Title level={5} style={{ margin: 0 }}>Recursos</Typography.Title>
                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                    {
                        isEdittingResources && (
                            <Popover open={resourceTypeOptionsVisible} content={popOverResourceTypes} title="Tipo" placement="bottomRight">
                                <Tag color="blue" style={{ margin: 0 }} onClick={handleClickResourceType} className={styles.tagTipoRecurso}>
                                    {
                                        resourceType === ETipoRecurso.ARCHIVO && "Archivo"
                                    }
                                    {
                                        // @ts-ignore
                                        resourceType === ETipoRecurso.URL && "URL"
                                    }
                                </Tag>
                            </Popover>
                        )
                    }
                    {
                        allowAddFiles &&
                        // <Tooltip title={isEdittingResources ? "¡Listo!" : "Agregar recursos"} placement="bottomRight">
                        <Button shape="circle" type="dashed" icon={isEdittingResources ? <CheckOutlined /> : <PlusOutlined />} onClick={handleClickEditFiles} />
                        // </Tooltip>
                    }
                </div>
            </Row>
            <Row>
                {
                    !isEdittingResources && tRecursos.length === 0 &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                        <Empty description="No hay recursos adjuntos" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                }
                {
                    isEdittingResources && resourceType === ETipoRecurso.ARCHIVO &&
                    <FileManager
                        allowedExtensions={allowedExtensions}
                        limit={limit}
                        maxFileMBSize={maxFileMBSize}
                        onChangeFiles={onChangeFiles}
                    />
                }
                {
                    isEdittingResources && resourceType === ETipoRecurso.URL &&
                    <UrlManager
                        items={tRecursos.urls}
                        catalogoId={catalogoId}
                        catalogoFieldId={catalogoFieldId}
                        onSaveUrl={onSaveResources}
                    />
                }
                {
                    !isEdittingResources &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1
                    }}>
                        {
                            tRecursos.files.length > 0 &&
                            <div style={{ display: "flex", gap: 10, flexWrap: "wrap", padding: 10 }}>
                                {tRecursos?.files?.map((recurso, iRec) =>
                                    <RecursoItem
                                        key={`${iRec}_${recurso?.recu_id || recurso?.name}_recurso`}
                                        recurso={recurso}
                                        allowDeleteFile={allowDeleteFile}
                                        useApi={useApi}
                                        eliminarRecurso={eliminarRecurso}
                                    />
                                )}
                            </div>
                        }
                        {
                            tRecursos.urls.length > 0 &&
                            <div style={{ display: "flex", gap: 10, flexWrap: "wrap", padding: 10 }}>
                                <Divider orientation="left" style={{ margin: "5px 0" }}>URLs</Divider>
                                <div className={styles.urlsContainer}>
                                    {tRecursos?.urls?.map((recurso, iRec) =>
                                        <UrlItem
                                            key={`${iRec}_${recurso?.recu_id || recurso?.name}_recurso`}
                                            recurso={recurso}
                                            onDeleteUrl={eliminarRecurso}
                                        />
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                }
            </Row>
        </div>
    );
}

export default RecursosManager;