import React from "react";
import { motion } from "framer-motion";
import { Row, Col } from "antd";
import './styles.css'

const CardAnimated = ({title, data}) => {


    return(
        <motion.div
            className="cardDataMetrica"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01]}}
        >
           <Row>
                <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <span className="titleCardMetrica">{title}</span>
                </Col>
           </Row>
           {data !== undefined ?(
                <Row>
                    {data.length > 0 ? (
                        <Col span={24}>
                            {data.map((item, index) => (
                                <Row key={index} style={{borderBottom:'1px solid #406dbd'}}>
                                    <Col span={18} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                    <span className="dataCardMetrica">{item.title}</span>
                                    </Col>
                                    <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <span className="dataCardMetrica" style={{color:'#406dbd'}}>{item.data}</span>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    ):(<span>No existen Datos</span>)}
                    
                </Row>
           ):(<span>No existen Datos</span>)}
           
        </motion.div>
    )

}


export default CardAnimated;