import { Button, Form, Row, Select, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { useSistemaVersionesCopy } from "../../../hooks/api/sistemas_versiones";

const CopiarVersion = ({ version, sistemas, closeModal }) => {
    const theme = useTheme();
    const [form] = Form.useForm();
    const [sistemasDisponibles, setSistemasDisponibles] = useState([]);
    const { copySistemaVersion, isCopyingSistemaVersion } = useSistemaVersionesCopy();

    const handleCopyVersion = () => {
        form.validateFields().then(({ sistemas }) => {
            copySistemaVersion(
                {
                    csiv_id: version.csiv_id,
                    sistemas
                },
                {
                    onSuccess: res => {
                        if (res?.success) {
                            message.success(res.mensaje);
                            closeModal();
                        } else {
                            message.error(res.mensaje);
                        }
                        form.resetFields();
                    },
                    onError: () => {
                        form.resetFields();
                    }
                }
            );
        })
    }

    useEffect(() => {
        setSistemasDisponibles(sistemas.filter(sistema => sistema.sis_id !== version?.sis_id));
    }, [sistemas]);

    return (
        <div>
            <Row>
                <Typography.Title level={5}>Copiando version <span style={{ color: theme.colors.secondary }}>{version?.csiv_version}</span></Typography.Title>
            </Row>
            <Row>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        name="sistemas"
                        label="Sistemas disponibles"
                        rules={[{ required: true, message: "Selecciona 1 o màs sistemas." }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {sistemasDisponibles.map(sistema => <Select.Option key={sistema.sis_id} value={sistema.sis_id}>{sistema.sis_nombre}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Row>
            <Row
                style={{
                    gap: 10,
                    justifyContent: "end",
                    alignItems: "center"
                }}
            >
                <Button
                    shape="round"
                // onClick={}
                >
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    shape="round"
                    onClick={handleCopyVersion}
                    loading={isCopyingSistemaVersion}
                >
                    Copiar versión
                </Button>
            </Row>
        </div>
    );
}

export default CopiarVersion;