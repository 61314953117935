import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Spin, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import Lottie from "lottie-web";
import { LOTTIE_ANIMATIONS } from "../../../animations/lottie";
import { useChangePassword, useSendAuthorizationChangingPasswordCode, useValidateChangingPasswordCode } from "../../hooks/api/usuarios";
import { useSelector } from "react-redux";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10
    },
    codeForm: {
        margin: "15px 0",
        display: "flex",
        gap: 20,
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    codeCharInput: {
        width: "fit-content",
        "& .ant-input-status-error": {
            border: `1px solid #ff4d4f !important`,
        },
        "& .ant-input": {
            width: 40,
            borderRadius: 10,
            fontSize: 28,
            fontWeight: 500,
            border: `1px solid #f3f3f3`,
            background: "#f3f3f3",
            textAlign: "center",
            caretColor: "transparent",
            "&:focus": {
                transform: "scale(1.1)",
                border: `1px solid ${theme.secondaryColor} !important`,
                background: `${theme.secondaryColor} !important`,
                color: "#FFF"
            }
        },
    },
    passwordRequirementsList: {
        "& .password-requirements-list-item": {
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 10
        }
    }
}));

const SendAuthorizationCode = ({ setCodeSended, setIsLoading }) => {
    const theme = useTheme();
    const { sendAuthorizationCode, isSenddingAuthorizationCode } = useSendAuthorizationChangingPasswordCode();
    const { user } = useSelector(state => state.auth);

    const handleClickEnviarCodigoAutorizacion = () => {
        // se manda la solicitud de envio de correo de codigo de autorizacion
        sendAuthorizationCode(user.usu_mail, {
            onSuccess: res => {
                if (res.success) {
                    setCodeSended(true)
                    setIsLoading(false)
                } else {
                    message.error(res.mensaje);
                }
            },
            onError: () => {
                message.error("Ocurrió un problema al enviar el correo")
            }
        })
    }

    useEffect(() => {
        setIsLoading(isSenddingAuthorizationCode)
    }, [setIsLoading, isSenddingAuthorizationCode])

    return (
        <div>
            <Row style={{ justifyContent: "center" }}>
                {/* <Row> */}
                <Typography.Title level={3} style={{ marginBottom: 10 }}>Cambiar contraseña</Typography.Title>
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <Typography.Text style={{ textAlign: "center", marginBottom: 20 }}>
                    Para poder cambiar tu contraseña, te enviaremos un código de autorización a tu correo para prodecer.
                </Typography.Text>
                <Button
                    type="primary"
                    onClick={handleClickEnviarCodigoAutorizacion}
                    style={{ background: theme.secondaryColor, border: "none", flex: 1 }}
                >
                    Enviar código de verificación
                </Button>
            </Row>
        </div>
    );
}

const VerifyCode = ({ setIsLoading, setIsValidCode }) => {
    const styles = useStyles();
    const theme = useTheme();
    const [form] = Form.useForm();
    const codeLength = 6;
    const { validatePassAuthorizationCode } = useValidateChangingPasswordCode();
    const { sendAuthorizationCode, isSenddingAuthorizationCode } = useSendAuthorizationChangingPasswordCode();
    const { user } = useSelector(state => state.auth);


    const handleClickVerrificarCodigo = () => {

        form.validateFields().then(values => {
            // se valida el codigo a enviar
            let codigo = Object.keys(values).reduce((codigo, kCharCode) => {
                codigo += values[kCharCode]
                return codigo;
            }, "");
            validatePassAuthorizationCode({ codigo, usu_mail: user.usu_mail }, {
                onSuccess: res => {
                    if (res.success) {
                        setIsValidCode(true);
                        setIsLoading(false);
                    } else {
                        message.error(res.mensaje);
                    }
                },
                onError: () => {
                    message.error("Ocurrió un problema al validar el código")
                }
            })
        }).catch(() => message.error("El código no es válido"))
    }

    const validateNumber = (value) => {
        value = value.trim();
        return /[0-9]/.test(value) && value !== "";
    }

    const handleInputCodeFocus = (e) => {
        e.target.value = "";
        form.setFieldValue(e.target.id, "")
    }

    const onKeyDownCharInput = (e) => {
        if (validateNumber(e.key) || e?.key === "Backspace") {
            let inputs = document.querySelectorAll(".code-char-input");
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].id === e.target.id) {
                    setTimeout(() => {
                        inputs[i + (e?.key === "Backspace" ? -1 : 1)]?.focus()
                    }, 10)
                }
            }
        }
    }

    const renderInputs = () => {
        let inputs = [];
        for (let i = 0; i < codeLength; i++)
            inputs.push(
                <Form.Item name={`c${i}`} className={styles.codeCharInput} rules={[{ required: true, message: "" }]} key={`c${i}`}>
                    <Input
                        placeholder=""
                        tabIndex={i + 1}
                        maxLength={1}
                        autoFocus={i === 0}
                        onFocus={handleInputCodeFocus}
                        onKeyDown={onKeyDownCharInput}
                        className="code-char-input"
                    />
                </Form.Item>
            )
        return inputs;
    }

    const handleClickReenviarCodigo = () => {
        setIsLoading(true)
        sendAuthorizationCode(user.usu_mail, {
            onSuccess: res => {
                if (res.success) {
                    setIsLoading(false);
                    message.success("Código enviado");
                } else {
                    message.error(res.mensaje);
                    setIsLoading(false)
                }
            },
            onError: () => {
                message.error("Ocurrió un problema al enviar el correo")
                setIsLoading(false)
            }
        })
    }

    return (
        <div className="fade-down">
            <Row style={{ justifyContent: "center" }}>
                <Typography.Title level={3} style={{ marginBottom: 5 }}>Validando código</Typography.Title>
            </Row>
            <Row style={{ justifyContent: "center", flexDirection: "column" }}>
                <Row style={{ justifyContent: "center" }}>
                    <Typography.Text style={{ textAlign: "center", marginBottom: 10 }}>
                        Para proceder, ingresa el código que te hemos enviado. ({<Button type="link" style={{ margin: 0, padding: "0 2px" }} onClick={handleClickReenviarCodigo}>Reenviar</Button>})
                    </Typography.Text>
                </Row>
                <Row>
                    <Form form={form} className={styles.codeForm}>
                        {
                            renderInputs()
                        }
                    </Form>
                </Row>
                <Row style={{ gap: 20 }}>
                    <Button
                        type="primary"
                        onClick={handleClickVerrificarCodigo}
                        style={{ background: theme.secondaryColor, border: "none", flex: 1 }}
                    >
                        Validar código
                    </Button>
                </Row>
            </Row>
        </div>
    );
}


const CaptureNewPasswordForm = ({ setPasswordSaved, setIsLoading }) => {
    const styles = useStyles();
    const theme = useTheme();
    const [form] = Form.useForm();
    const [isValidMayus, setIsValidMayus] = useState(false);
    const [isValidMinus, setIsValidMinus] = useState(false);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [isValidSpecialChar, setIsValidSpecialChar] = useState(false);
    const [isValidCharLength, setIsValidLength] = useState(false);
    const [password, setPassword] = useState("");
    const { changePassword } = useChangePassword();

    const validateMayus = (value) => {
        if (/[A-Z]/.test(value))
            setIsValidMayus(true);
        else
            setIsValidMayus(false);
    }

    const validateMinus = (value) => {
        if (/[a-z]/.test(value))
            setIsValidMinus(true);
        else
            setIsValidMinus(false);
    }

    const validateNumber = (value) => {
        if (/[0-9]/.test(value))
            setIsValidNumber(true);
        else
            setIsValidNumber(false);
    }

    const validateSpecialChar = (value) => {
        if (/[$&+,:;=?@#|'<>.^*()%!-/¡_]/.test(value))
            setIsValidSpecialChar(true);
        else
            setIsValidSpecialChar(false);
    }

    const validateLength = (value) => {
        if (value?.length >= 8)
            setIsValidLength(true);
        else
            setIsValidLength(false);
    }

    const handlePasswordChange = (e) => {
        let value = e.target.value;
        validateMayus(value);
        validateMinus(value);
        validateNumber(value);
        validateSpecialChar(value);
        validateLength(value);
        setPassword(value);
    }

    const handlePasswordConfirmationValidator = (_, value) => {
        return new Promise((resolve, reject) => {
            if (value !== password) {
                reject("La contraseña no coincide");
            } else {
                resolve(true);
            }
        })
    }

    const handleSavePassword = () => {
        // se valida que la contraseña cumpla con los requisitos
        form.validateFields().then(values => {
            if (isValidMayus && isValidMinus && isValidNumber && isValidCharLength && isValidSpecialChar) {
                setIsLoading(true)
                console.log(values.password)
                // se envia la contraseña al servidor
                changePassword(values.password, {
                    onSuccess: res => {
                        if (res.success) {
                            setPasswordSaved(true);
                            setIsLoading(false);
                        } else {
                            message.error(res.mensaje);
                            setIsLoading(false)
                        }
                    },
                    onError: () => {
                        message.error("Ocurrió un problema al cambiar la contraseña")
                        setIsLoading(false)
                    }
                })
            } else {
                message.error("La contraseña no cumple con los requisitos")
            }
        }).catch(() => message.error("La contraseña no cumple con los requisitos"));
    }

    return (
        <div className="fade-down">
            <Row style={{ justifyContent: "center" }}>
                {/* <Row> */}
                <Typography.Title level={3} style={{ marginBottom: 10 }}>Ingresa tu nueva contraseña</Typography.Title>
            </Row>
            <Row style={{ justifyContent: "center", flexDirection: "column" }}>
                <Row >
                    <Typography.Text style={{ textAlign: "center", }}>
                        La contraseña debe de contener al menos:
                    </Typography.Text>
                </Row>
                <Row style={{ margin: "15px 0" }}>
                    <div className={styles.passwordRequirementsList}>
                        <div className="password-requirements-list-item">
                            {
                                isValidMayus ?
                                    <CheckOutlined style={{ fontSize: 16, color: theme.success }} className="fade-short-right" /> :
                                    <CloseOutlined style={{ fontSize: 16, color: theme.error }} className="fade-short-right" />
                            }
                            1 letra mayúscula
                        </div>
                        <div className="password-requirements-list-item">
                            {
                                isValidMinus ?
                                    <CheckOutlined style={{ fontSize: 16, color: theme.success }} className="fade-short-right" /> :
                                    <CloseOutlined style={{ fontSize: 16, color: theme.error }} className="fade-short-right" />
                            }
                            1 letra minúscula
                        </div>
                        <div className="password-requirements-list-item">
                            {
                                isValidNumber ?
                                    <CheckOutlined style={{ fontSize: 16, color: theme.success }} className="fade-short-right" /> :
                                    <CloseOutlined style={{ fontSize: 16, color: theme.error }} className="fade-short-right" />
                            }
                            1 número
                        </div>
                        <div className="password-requirements-list-item">
                            {
                                isValidSpecialChar ?
                                    <CheckOutlined style={{ fontSize: 16, color: theme.success }} className="fade-short-right" /> :
                                    <CloseOutlined style={{ fontSize: 16, color: theme.error }} className="fade-short-right" />
                            }
                            1 caracter especial
                        </div>
                        <div className="password-requirements-list-item">
                            {
                                isValidCharLength ?
                                    <CheckOutlined style={{ fontSize: 16, color: theme.success }} className="fade-short-right" /> :
                                    <CloseOutlined style={{ fontSize: 16, color: theme.error }} className="fade-short-right" />
                            }
                            Longitud mínima de 8 caracteres
                        </div>
                    </div>
                </Row>
                <Row style={{ margin: "0 10px 10px 10px" }}>
                    <Form layout="vertical" style={{ width: "100%" }} form={form}>
                        <Form.Item label="Nueva contraseña" name="password" rules={[{ required: true, message: "La contraseña es requerida" }]}>
                            <Input.Password onChange={handlePasswordChange} />
                        </Form.Item>
                        <Form.Item
                            label="Confirma la contraseña"
                            name="password-confirm"
                            rules={[
                                { validator: handlePasswordConfirmationValidator, message: "La contraseña no coincide" },
                                { required: true, message: "Se requiere la confirmación" }
                            ]}>
                            <Input.Password />
                        </Form.Item>
                    </Form>
                </Row>
                <Row>
                    <Button
                        type="primary"
                        onClick={handleSavePassword}
                        style={{ background: theme.secondaryColor, border: "none", flex: 1 }}
                    >
                        Cambiar contraseña
                    </Button>
                </Row>
            </Row>
        </div>
    );
}



const SuccessProcess = ({ closeParent }) => {
    const theme = useTheme();
    const animationContainer = useRef();

    const handleClickTodoListo = () => {
        if(closeParent) closeParent();
    }

    useEffect(() => {
        Lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LOTTIE_ANIMATIONS.CONFETI,
        });
    }, [])

    return (
        <div style={{ height: 200 }}>
            <div ref={animationContainer} style={{ position: "absolute", margin: -20, zIndex: 0, opacity: 0.8 }}></div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", gap: 10, zIndex: 1 }}>
                <Row style={{ justifyContent: "center" }}>
                    {/* <Row> */}
                    <Typography.Title level={3} style={{ marginBottom: 10 }}>Proceso exitoso</Typography.Title>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Typography.Text style={{ textAlign: "center", marginBottom: 20, padding: "0 20px" }}>
                        Tu contraseña se ha cambiado exitosamente, ya puedes iniciar sesion con la nueva contraseña.
                    </Typography.Text>
                    <Button
                        type="primary"
                        style={{ background: theme.success, border: "none", marginTop: 10, width: 120 }}
                        onClick={handleClickTodoListo}
                    >
                        ¡Todo listo!
                    </Button>
                </Row>
            </div>
        </div>
    );
}

const ChangeUserPassword = ({ closeParent }) => {
    const styles = useStyles();
    const [codeSended, setCodeSended] = useState(false);
    const [isValidCode, setIsValidCode] = useState(false);
    const [passwordSaved, setPasswordSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className={styles.container}>
            <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                {
                    !codeSended && !isValidCode && <SendAuthorizationCode setCodeSended={setCodeSended} setIsLoading={setIsLoading} />
                }
                {
                    codeSended && !isValidCode && <VerifyCode setIsLoading={setIsLoading} setIsValidCode={setIsValidCode} />
                }
                {
                    isValidCode && !passwordSaved && <CaptureNewPasswordForm setPasswordSaved={setPasswordSaved} setIsLoading={setIsLoading} />
                }
                {
                    passwordSaved && <SuccessProcess closeParent={closeParent} />
                }
            </Spin>
        </div>
    );
}

export default ChangeUserPassword;