import { Button, DatePicker, Divider, Form, Input, message, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSistemaMutation } from "../../../hooks/api/sistemas";
import { styles } from "./styles";
import { useUsuariosMutation } from "../../../hooks/api/usuarios";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";



const useStyles = createUseStyles(styles);

const CrearUsuario = ({ closeModal }) => {
    const styles = useStyles();
    const history =  useHistory();
    const { apiHost } = useSelector((state) => state.app);
    const [formGenerales] = Form.useForm();
    const [mailError, setMailError] = useState({
        message: null,
        status: null,
    });
    const { isLoadingSistemaMutation, mutateSistema } = useSistemaMutation();
    const { mutateUsuario, isLoadingUsuarioMutation, resetUsuarioMutation } = useUsuariosMutation();


    const checkMail = () => {
        return new Promise((resolve, reject) => {
            formGenerales.validateFields(["usu_mail"])
                .then((values) => {
                    // validar el mail del usuario
                    axios
                        .get(`${apiHost}/registro/mailcheck/${values.usu_mail}`)
                        .then((response) => {
                            if (response.data.success) {
                                resolve(true);
                                setMailError({
                                    message: "El correo electrónico ya existe",
                                    status: "error",
                                });
                            } else {
                                resolve(false);
                                setMailError({
                                    message: null,
                                    status: null,
                                });
                            }
                        })
                        .catch((err) => {
                            reject(err);
                            console.error(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                    console.error(err);
                });
        });
    };


    const handleBlurMail = () => {
        checkMail()
    }

    useEffect(() => {
        formGenerales.setFieldsValue({
            usu_nombres: null,
            usu_apaterno: null,
            usu_amaterno: null,
            usu_mail: null,
            usu_pass: null
        });
    }, []);


    const handleGuardarUsuario = async () => {
        try {
            let generales = await formGenerales.validateFields();
            // generales.usu_fechanac = generales?.usu_fechanac?.format(DATE_FORMATS.DATE_LUXON_FORMAT_TO_STR) || null;
            let datos = { usuario: { ...generales, usu_tel: "", usu_activo: 1 }, mutationType: "create" };
            mutateUsuario(datos, {
                onSuccess: (res) => {
                    if (res?.success) {
                        formGenerales.resetFields();
                        closeModal();
                        resetUsuarioMutation();
                        message.success(res.mensaje);
                        history.push(`/usuarios/${res.data}`)
                    } else {
                        message.error("Hubo un problema al crear el usuario");
                    }
                },
                onError: () => {
                    message.error("Hubo un problema al crear el usuario");
                },
            });
        } catch (error) {
            message.error("Aún hay campos faltantes");
        }
    };

    return (
        <Space className={styles.formWrapper} size={10}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Form layout="vertical" form={formGenerales}>
                    <Row style={{ gap: 16, flexDirection: "column" }}>
                        <Form.Item
                            label="Correo electrónico"
                            name="usu_mail"
                            validateStatus={mailError.status}
                            help={mailError.message}
                            rules={[
                                { required: true, message: "por favor ingresa el correo electrónico!" },
                                {
                                    type: "email",
                                    message: "El correo electrónico no es válido!",
                                },
                            ]}
                        >
                            <Input type="email" onBlur={handleBlurMail} />
                        </Form.Item>
                        <Form.Item
                            label="Contraseña"
                            name="usu_pass"
                            rules={[
                                { required: true, message: "La contraseña es requerida" },
                                // { min: 8, message: "La contraseña debe contener al menos 8 caracteres" },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="Nombre" name="usu_nombres" rules={[{ required: true, message: "El nombre es requerido" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Apellido paterno"
                            name="usu_apaterno"
                            rules={[{ required: true, message: "El apellido paterno es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Apellido materno"
                            name="usu_amaterno"
                            rules={[{ required: true, message: "El apellido materno es requerido" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Row>
                    {/* <Row>
                        <Space>
                            <Form.Item label="Clave" name="usu_clave">
                                <Input />
                            </Form.Item>
                        </Space>
                    </Row> */}

                </Form>
                <Row style={{ justifyContent: "end" }}>
                    <Button type="primary" onClick={handleGuardarUsuario} loading={isLoadingUsuarioMutation}>
                        Guardar usuario
                    </Button>
                </Row>
            </div>
        </Space>
    );
};

export default CrearUsuario;
