import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AnimatePresence, motion } from "framer-motion"
import { useSelector } from 'react-redux';
import PerfilDatosGenerales from '../views/Usuario/Perfil/PerfilDatosGenerales/PerfilDatosGenerales';

const PerfilRoutes = () => {
    const motionParams = useSelector((state) => state.motion); 

    return (
        <Switch>
            <AnimatePresence key="tickets-router-animated">
                <PrivateRoute exact path={"/perfil"} key="/perfil">
                    <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                        <PerfilDatosGenerales />
                    </motion.div>
                </PrivateRoute>
                {/* <PrivateRoute exact path={"/tickets/:id"} key="/tickets/:id">
                    <motion.div initial="out" animate="in" exit="out" transition={motionParams.transition} variants={motionParams.variants}>
                        <TicketPage />
                    </motion.div>
                </PrivateRoute> */}
            </AnimatePresence>

        </Switch>
    );
}

export default PerfilRoutes;