const motionInitialState = {
    transition: {
        ease: "backInOut",
        // ease: "anticipate",
        duration: 0.3,
    },
    variants: {
        in: {
            opacity: 1,
            x: 0,
        },
        out: {
            opacity: 0,
            x: "-50px",
        },
    },
};

const motionReducer = (state = motionInitialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default motionReducer;
