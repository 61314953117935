import { Button, Col, Form, Input, Modal, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useHistory } from "react-router-dom";
import { useApiContactos } from "../../hooks/api/contactos";
import DataTable from "../../components/DataTable";
import { PlusOutlined } from "@ant-design/icons";
import { useModal } from "../../hooks/useModal";
import NuevoContacto from "./NuevoContacto";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import { GetContactos } from "../../hooks/api/contactos";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    table: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
});

const Contactos = () => {

    const {getContactosMutation} = GetContactos();

    const styles = useStyles();
    const history = useHistory();
    const [formEmpresa] = Form.useForm();
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [contactos, setContactos] = useState(null)
    const [spin, setSpin] = useState(true)
    const [nuevoContactoVisible, showNuevoContacto, closeNuevoContacto] = useModal();
    const [search, setSearch] = useState(false)

    const getEstatusTemplate = (_, data) => {
        return data.usu_activo === 1 ? "Activo" : "Inactivo";
    };

    const handleSelectedRow = (contacto) => {
        history.push(`/contactos/${contacto.usu_id}`);
    };

    const columns = [
        {
            index: "name_complete",
            title: "Nombre",
            width: 250,
        },
        {
            index: "usu_mail",
            title: "Correo",
            width: 200,
        },
        {
            index: "usu_tel",
            title: "Teléfono",
            width: 150,
        },
        {
            index: "usu_activo",
            title: "Estatus",
            width: 150,
            render: (usu_activo) => usu_activo === true ? <p>Activo</p> : <p>Inactivo</p>
        }
    ];

    const handleClickShowModal = () => {
        // se abre la modal para capturar los datos del nuevo contacto
        showNuevoContacto();
    }

    useEffect(() => {
        formEmpresa.setFieldsValue({
            emp_codigo: null,
            emp_vendedor: null,
            emp_razonsocial: null,
            emp_nombrecomercial: null,
            emp_alias: null,
            emp_padreid: null,
            emp_rfc: null,
        });
        createData()
    }, []);

    const createData = () => {
        getContactosMutation({},{
            onSuccess: (data) => {
                data.map((contacto => {
                    contacto.name_complete = `${contacto?.usu_nombres} ${contacto?.usu_amaterno} ${contacto?.usu_apaterno}`
                }))
                setContactos(data)
                setSpin(false)
            },
            onError: (error) => {
                console.log(error)
            }
        })
        
    }

    return (
        <PageWrapper title="Contactos">

            <Modal
                title="Crear nuevo contacto"
                open={nuevoContactoVisible}
                onCancel={closeNuevoContacto}
                destroyOnClose
                footer={null}
                width={600}
            >
                <NuevoContacto />
            </Modal>

            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando contactos ..." spinning={spin}>
                            <DataTableHeader
                                key={'table-contact'}
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: handleClickShowModal
                                    }
                                ]}
                                dataToFilter={contactos}
                                setFilteredData={setFilteredContacts}
                                counter={search ? filteredContacts?.length : contactos?.length}
                                setSearch={setSearch}
                            />
                            { contactos !== null && (
                                <DataTable
                                className={styles.table}
                                columns={columns}
                                data={search ? filteredContacts : contactos}
                                onRowClick={handleSelectedRow}
                                paginationParams={{
                                    defaultPageSize: 10,
                                    total: contactos.length,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    showSizeChanger: true,
                                    position: ["topRight"]
                                }}
                            />
                            )}
                        </Spin>
                    </Elevation>
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default Contactos;
