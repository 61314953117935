
/**
 * @typedef {Object} EtipoUsuario
 */
export const EtipoUsuario = Object.freeze({
    SUPERUSUARIO: 1,
    CLIENTE: 2,
    ADMINISTRACION: 3,
});

/**
 * @typedef {Object} EconectionUsuario
 */
export const EconectionUsuario = Object.freeze({
    CONNECTED : 1,
    ABSENT : 2,
});
