import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import useTabs from "../../hooks/useTabs";

const useStyles = createUseStyles(() => ({
    tabsPanel: {
        "& .custom-tab": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 34,
            fontSize: 11,
            flex: 1,
            transition: "all 0.15s ease-in-out",
            "&.selected": {
                backgroundColor: "#1890FF",
                color: "#fff",
            },
            "&:hover": {
                backgroundColor: "#339cff",
                color: "#fff",
                cursor: "pointer",
            },
        },
    },
}));

const SolidTabs = ({ tabs, initialActiveKey }) => {
    const styles = useStyles();
    const { activeKey, setActiveKey, tabContent, setTabContent } = useTabs();

    const handleChangePanel = (e) => {
        setActiveKey(parseInt(e.target.getAttribute("tab")));
    };

    useEffect(() => {
        setActiveKey(initialActiveKey || null);
    }, [initialActiveKey]);

    useEffect(() => {
        let tTab = tabs.find((tab) => tab.key === activeKey);
        if (tTab) setTabContent(tTab.content);
    }, [activeKey, tabs, setTabContent]);

    return (
        <div>
            <div className={styles.tabsPanel} style={{ display: "flex", borderBottom: "1px solid #eee" }}>
                {(tabs || []).map((tab) => (
                    <div key={`solid-tab-${tab.key}`} className={`custom-tab ${activeKey === tab.key ? "selected" : ""}`} tab={tab.key} onClick={handleChangePanel}>
                        {tab.text}
                    </div>
                ))}
            </div>
            <div style={{ width: "100%" }}>{tabContent}</div>
        </div>
    );
};

export default SolidTabs;
