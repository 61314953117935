/**
 * indica los tipos de recursos que se pueden guardar
 * @readonly
 * @enum {Number}
 */
export const ETipoRecurso = Object.freeze({
    ARCHIVO: 1,
    URL: 2,
    IMAGEN: 3,
    VIDEO: 4
});
