"use strict"
import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Elevation from "../../components/Elevation";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useApiPuestos } from "../../hooks/api/puestos";
import DataTable from "../../components/DataTable";
import CrearPuesto from "./CrearPuesto";
import { useModal } from "../../hooks/useModal";
import { PlusOutlined } from "@ant-design/icons";
import VerPuesto from "./VerPuesto";
import { PuestoDto } from "../../@types/Puestos.types";
import DataTableHeader from "../../components/DataTable/DataTableHeader";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    table: {
        "& .ant-pagination": {
            paddingRight: 20
        }
    }
});

/**
 * Componente principal para listar y mostrar los puestos
 */
const Puestos = () => {
    const styles = useStyles();
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [modalVisible, showModal, closeModal] = useModal();
    const [verPuestoVisible, showVerPuesto, closeVerPuesto] = useModal();
    const [search, setSearch] = useState(false)
    /**
     * @type {[PuestoDto, React.Dispatch<React.SetStateAction<PuestoDto>>]}
     */
    const [selectedPuesto, setSelectedPuesto] = useState(null);
    const { isLoading: isLoadingPuestos, data: puestos, refetch: refetchPuestos } = useApiPuestos({
        initialData: [],
    });

    const columns = [
        { index: "pues_id", title: "ID", width: 70 },
        { index: "pues_nombre", title: "Nombre", width: 200 },
        { index: "pues_clave", title: "Clave", width: 200 },
        { index: "pues_descripcion", title: "Descripcion", width: 250 },
        { index: "pues_objetivo", title: "Objetivo", width: 250 }
    ];

    /**
     * 
     * @param {PuestoDto} puesto - puesto seleccionado 
     */
    const onSelectPuesto = (puesto) => {
        setSelectedPuesto(puesto);
        showVerPuesto();
    }

    const cerrarPuesto = () => {
        closeVerPuesto();
        refetchPuestos();
        setSelectedPuesto(null);
    }

    useEffect(() => {
        if (selectedPuesto) {
            setSelectedPuesto(puestos.find(puesto => puesto.pues_id === selectedPuesto.pues_id));
        }
    }, [puestos, selectedPuesto])


    return (
        <PageWrapper title="Puestos">
            <Row style={{ marginTop: 10 }} gutter={16}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Elevation>
                        <Spin tip="Cargando Puestos ..." spinning={isLoadingPuestos}>
                            <DataTableHeader
                                key={'table-positions'}
                                buttons={[
                                    {
                                        icon: <PlusOutlined />,
                                        onClick: showModal
                                    }
                                ]}
                                dataToFilter={puestos}
                                setFilteredData={setFilteredRecords}
                                counter={search ? filteredRecords?.length : puestos?.length}
                                setSearch={setSearch}
                            />
                            <DataTable
                                className={styles.table}
                                columns={columns}
                                data={search ? filteredRecords : puestos}
                                onRowClick={onSelectPuesto}
                                scroll={{ x: "100%" }}
                                paginationParams={{
                                    defaultPageSize: 10,
                                    total: puestos.length,
                                    showSizeChanger: true,
                                    pageSizeOptions: [50, 100, 200, 500, 1000],
                                    position: ["topRight"],
                                }}
                            />
                        </Spin>
                    </Elevation>
                </Col>
            </Row>

            <Modal
                title="Crear Puesto"
                open={modalVisible}
                onCancel={() => closeModal()}
                footer={null}
                width={600}
                destroyOnClose
            >
                <CrearPuesto closeModal={closeModal} />
            </Modal>

            <Modal
                title="Ver Puesto"
                open={verPuestoVisible}
                onCancel={() => closeVerPuesto()}
                footer={null}
                width={600}
                destroyOnClose
            >
                <VerPuesto puesto={selectedPuesto} closeVerPuesto={cerrarPuesto} />
            </Modal>
        </PageWrapper>
    );
};

export default Puestos;
