import React, { useEffect, useState } from "react";
import { Table, Button, Space, Input, Row, Tag, Col, Tooltip, Pagination } from "antd";
import { SearchOutlined, SettingOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import { useDrop } from "react-dnd";
import DraggableColumnHeader from "./DraggableColumnHeader";
import { getRandomHexColor } from "../../../helpers/colors";
import { ItemTypes } from "./DnDItemTypes";
import NestedTable from "./NestedTable";
import TableConfigModal from "./TableConfigModal/index";
import { useForceUpdate } from "../../../hooks/useForceUpdate";

const useStyles = createUseStyles((theme) => ({
    droppableColumnSorting: {
        padding: 15,
        minHeight: 50,
        width: "100%",
        backgroundColor: theme.primaryColor,
        marginBottom: 0,
        color: "#FFF",
    },
    columnOptionsCollapse: {
        width: "100%",
        "& .ant-collapse": {
            width: "100%",
        },
    },
    dataTable: {
        "& .ant-table-expanded-row td": {
            background: "rgba(240,240,240,0.6)",
        },
    },
    btnTableConfig: {
        backgroundColor: "rgba(255,255,255,0.2)",
        color: "#FFF",
        border: "none",
        "&:hover": {
            border: "none",
            backgroundColor: "rgba(255,255,255,0.4)",
            color: "#FFF",
            borderColor: "#fff",
        },
        "&:focus": {
            border: "none",
            backgroundColor: "rgba(255,255,255,0.4)",
            color: "#FFF",
            borderColor: "#fff",
        },
    },
    "table .ant-table-column-sorter-up.active, table  .ant-table-column-sorter-down.active": {
        color: "rgba(236, 31, 36, 1) !important",
    },
    columnWrapperContent: {
        display: "flex",
        justifyContent: "center",
    },
    editBtn: {
        ...theme.btn,
        backgroundColor: "white",
        border: "none",
        color: theme.primaryColor,
        fontSize: "20px",
        textAlign: "center",
        padding: "5px 25px",
        // width: "100",
        alignSel: "center",
        height: "auto",
        "&:hover": {
            color: theme.primaryColor,
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
            transform: "scale(1.05)",
        },
    },
}));

const DataTableDraggableHeader = (props) => {
    const [tableOptions, setTableOptions] = useState({
        searchText: "",
        searchedColumn: "",
    });
    const [columns, setColumns] = useState(props.columns);
    const [droppedColumns, setDroppedColumns] = useState([]);
    const [configTableVisible, setConfigTableVisible] = useState(false);
    const [tempData, setTempData] = useState([]);
    const styles = useStyles();
    const forceUpdate = useForceUpdate();
    let searchInput = null;

    const [, addColumnToGroupRef] = useDrop({
        accept: ItemTypes.COLUMNHEADER,
        collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    });

    const getActionColumns = () => {
        let columns = [];

        if (props.config?.viewBtn.show) {
            columns = [
                ...columns,
                {
                    title: "Ver Todo",
                    index: "Ver",
                    render: (text, record) => (
                        <div className={styles.columnWrapperContent}>
                            <Tooltip placement="left" title={"Ver registro"}>
                                <Button className={styles.editBtn} onClick={() => props.config?.viewBtn.onClick(record)}>
                                    <EyeOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    ),
                },
            ];
        }

        if (props.config?.editBtn.show) {
            columns = [
                ...columns,
                {
                    title: "Editar",
                    index: "Editar",
                    render: (text, record) => (
                        <div className={styles.columnWrapperContent}>
                            <Tooltip placement="left" title={"Editar registro"}>
                                <Button className={styles.editBtn} onClick={() => props.config?.editBtn.onClick(record)}>
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    ),
                },
            ];
        }

        if (props.config?.deleteBtn.show) {
            columns = [
                ...columns,
                {
                    title: "Eliminar",
                    index: "Eliminar",
                    render: (text, record) => (
                        <div className={styles.columnWrapperContent}>
                            <Tooltip placement="left" title={"Eliminar registro"}>
                                <Button className={styles.editBtn} onClick={() => props.config?.deleteBtn.onClick(record)}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    ),
                },
            ];
        }

        return columns;
    };

    const getColumnSearchProps = (dataIndex, columnTitle) => {
        let tempColumnConfig = {}

        let colTemp = props.columns.find(col => col.index === dataIndex);

        if (colTemp?.enableSearch) {
            tempColumnConfig = {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={(node) => {
                                searchInput = node;
                            }}
                            placeholder={`${columnTitle}`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ marginBottom: 8, display: "block" }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Buscar
                            </Button>
                            <Button
                                onClick={() => {
                                    setSelectedKeys([""]);
                                    confirm();
                                    handleReset(clearFilters);
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Limpiar
                            </Button>
                        </Space>
                    </div>
                ),
            }
        }

        return {
            ...tempColumnConfig,
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.select(), 100);
                }
            }
        }
    };

    const setDataToColumnTemplate = (columnIndex, colTitle, options = null) => {
        let colTemplate = {
            title: () => (
                <DraggableColumnHeader title={colTitle} index={columnIndex} onDropColumnHeader={onDropColumnHeader}>
                    {colTitle}
                </DraggableColumnHeader>
            ),
            titleString: colTitle,
            dataIndex: columnIndex,
            key: columnIndex,
            ellipsis: true,
        };

        if (columnIndex !== "Editar" && columnIndex !== "Eliminar" && columnIndex !== "Ver") {
            colTemplate = {
                ...colTemplate,
                ...getColumnSearchProps(columnIndex, colTitle),
                sorter: (a, b) => {
                    if (!isNaN(Number(a[columnIndex]))) {
                        return a[columnIndex] - b[columnIndex];
                    }

                    var nameA = replaceSymbols(a[columnIndex]?.toUpperCase() || "");
                    var nameB = replaceSymbols(b[columnIndex]?.toUpperCase() || "");
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                },
                sortDirections: ["descend", "ascend"],
                showSorterTooltip: false,
            };
        }

        if (props.config?.editBtn || props.config?.deleteBtn) {
            colTemplate = {
                ...colTemplate,
                ...options,
            };
        }

        // se setea la propiedad render de la columna
        if (!colTemplate?.render)
            colTemplate = {
                ...colTemplate,
                render: options.render
            }

        // se setea la propiedad width de la columna
        if (!colTemplate?.width)
            colTemplate = {
                ...colTemplate,
                width: options.width
            }

        // se setea la propiedad fixed de la columna
        if (!colTemplate?.fixed)
            colTemplate = {
                ...colTemplate,
                fixed: options.fixed
            }

        // se setea la propiedad defaultSortOrder de la columna
        if (!colTemplate?.defaultSortOrder)
            colTemplate = {
                ...colTemplate,
                defaultSortOrder: options.defaultSortOrder
            }


        return colTemplate;
    };

    const setCustomColumns = (columns, colKey) => {
        columns = [...columns, ...getActionColumns()];

        setColumns(
            columns.map((column) => {
                let options = {
                    // onCell: column?.onCell,
                    render: column.render,
                    width: column.width || 50,
                    fixed: column.fixed || false,
                    defaultSortOrder: column.defaultSortOrder || null,
                };
                return setDataToColumnTemplate(column[colKey], column.title, options);
            })
        );
    };

    function containsColumn(obj) {
        var i;
        for (i = 0; i < droppedColumns.length; i++) {
            if (droppedColumns[i].index === obj.index) {
                return true;
            }
        }
        return false;
    }

    const onDropColumnHeader = (column) => {
        if (!containsColumn(column)) setDroppedColumns([...droppedColumns, { ...column, color: getRandomHexColor() }]);
    };

    const removeDroppedColumn = (columnIndex, e) => {
        e.preventDefault();
        setDroppedColumns(
            droppedColumns.filter((column) => {
                if (column.index !== columnIndex) return column;
            })
        );
    };

    const replaceSymbols = (text) => {
        return text.replace(/[^\w\s]/gi, "");
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        if (props?.onSearch) {
            props.onSearch(selectedKeys, dataIndex).then(() => {
                setTableOptions({
                    ...tableOptions,
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                });
            });
        } else {
            setTableOptions({
                ...tableOptions,
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
            });
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setTableOptions({ ...tableOptions, searchText: "" });
    };

    useEffect(() => {
        forceUpdate();
    }, [tableOptions.searchText, props.data]);

    useEffect(() => {
        let columns = props?.columns;

        columns = [...columns, ...getActionColumns()];
        setColumns(
            columns.map((column) => {
                let options = {
                    render: column.render,
                    width: column.width || 50,
                    fixed: column.fixed || false,
                    defaultSortOrder: column.defaultSortOrder || null,
                };
                return setDataToColumnTemplate(column.index, column.title, options);
            })
        );
    }, [props.data, props.columns]);

    const openTableConfig = () => {
        setConfigTableVisible(true);
    };

    const closeTableConfig = () => {
        setConfigTableVisible(false);
    };

    const dataIsNested = (groupingData, groupedData) => {
        let groupedDataFormated = JSON.stringify(groupedData);
        for (let record of groupingData) {
            if (JSON.stringify(record.groupedData) === groupedDataFormated) return true;
        }
        return false;
    };

    const getNestedDataTest = () => {
        let groupingData = [];
        let groupedData = [];
        props.data?.map((currentRecord) => {
            groupedData = props.data?.filter((record) => {
                for (let index = 0; index <= droppedColumns.length; index++) {
                    let currentCol = droppedColumns[index]?.index;
                    if (record[currentCol] !== currentRecord[currentCol]) {
                        return false;
                    }
                }
                return true;
            });

            if (!dataIsNested(groupingData, groupedData)) groupingData = [...groupingData, { ...currentRecord, groupedData }];
        });
        setTempData(groupingData);
    };

    useEffect(() => {
        getNestedDataTest();
    }, [droppedColumns]);

    const onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
        props?.changePageSize(pageSize)
    };
    const changePage = (page) => {
        props?.changePage(page)
    };



    return (
        <div style={props?.style}>
            <TableConfigModal
                setCustomColumns={setCustomColumns}
                mappedColumns={props.mappedColumns}
                columnas={columns}
                setColumns={setColumns}
                showConfig={configTableVisible}
                closeTableConfig={closeTableConfig}
            />

            {!props.draggableHeader ? null : (
                <Row className={styles.droppableColumnSorting} ref={addColumnToGroupRef} align="middle">
                    <Col span={20}>
                        {droppedColumns.length === 0 ? (
                            <span
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                Arrastra columnas para agrupar
                            </span>
                        ) : (
                            droppedColumns.map((column) => {
                                return (
                                    <Tag
                                        closable
                                        color={column.color}
                                        onClose={(e) => {
                                            removeDroppedColumn(column.index, e);
                                        }}
                                        key={`${column.index}_${column.title}`}
                                    >
                                        {column.title}
                                    </Tag>
                                );
                            })
                        )}
                    </Col>
                    <Col span={!props.draggableHeader ? 24 : 4}>
                        <Row justify="end">
                            <Button shape="circle" icon={<SettingOutlined />} onClick={openTableConfig} className={styles.btnTableConfig} />
                        </Row>
                    </Col>
                </Row>
            )}
            <Row>
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: e => {
                                if (props?.onRowClick) props?.onRowClick(record, rowIndex, e)
                            },
                        };
                    }}
                    style={{ width: "100%" }}
                    size={props?.size || "default"}
                    className={`${styles.dataTable}${props?.className ? ` ${props.className}` : ""}`}
                    columns={columns}
                    dataSource={droppedColumns.length === 0 ? props.data : tempData}
                    pagination={props?.showPagination === false ? false : props?.paginationParams}
                    scroll={props?.scroll}
                    expandable={
                        props.expandable
                            ? props.expandable
                            : droppedColumns.length === 0
                                ? {}
                                : {
                                    expandedRowRender: (record) => {
                                        if (record?.groupedData.length > 1) return <NestedTable nestedData={record?.groupedData} columns={columns} />;
                                    },
                                    rowExpandable: (record) => {
                                        return record?.groupedData.length > 1;
                                    },
                                }
                    }
                // onChange={handleChange}
                />
                {!props?.showPagination && (
                    <Row style={{width:'100%'}}>
                        <Col span={24} style={{display:'flex', justifyContent:'right', marginTop:10, paddingRight:8}}>
                            <Pagination current={props?.page} showSizeChanger  onChange={changePage} onShowSizeChange={onShowSizeChange} defaultPageSize={props?.pageSize} total={props?.totalTickets}/>
                        </Col>
                    </Row>
                )}
            </Row>
        </div>
    );
};

export default DataTableDraggableHeader;
