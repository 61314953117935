
/**
 * @typedef {Object} TipoTicketAgente
 */
export const TipoTicketAgente = Object.freeze({
    CAPTURISTA: 1,
    CONTACTO: 2,
    RESPONSABLE: 3,
    OBSERVADOR: 4,
    AYUDANTE: 5,
    VENDEDOR:6
});

/**
 * Se consulta el nombre del tipo en base a su valor entero del area o 
 * @param {Number} value - valor del tipo que se requiere extraer la llave   
 * @returns {String} Nombre del tipo
 */
export const getKeyTipoTicketAgente = (value) =>
    Object.keys(TipoTicketAgente).find(key => TipoTicketAgente[key] == value);