import { Button, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import Elevation from "../../../../components/Elevation";
import DataTable from "../../../../components/DataTable";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = createUseStyles(theme => ({
    container: {
        width: "100%",
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        padding: "0",
        flexDirection: "column",
        animationDuration: "0.3s",
        "& .ventas-home-animation": {
            width: "100%",
            maxWidth: 400
        }
    },
    tableContainer: {
        borderRadius: 20,
        "& .ant-pagination": {
            paddingRight: 25
        }
    },
    itemsHeader: {
        padding: "10px 15px"
    },
    itemsHeaderBtn: {
        background: `${theme.secondaryColor}11`,
        color: `${theme.secondaryColor}`,
        border: "none",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            background: `${theme.secondaryColor}22`,
            transform: "scale(1.03)",
        }
    },
    table: {
        width: "100%",
        flex: 1
    },
    campaniaItem: {

    }
}));

const Campanias = () => {
    const styles = useStyles();
    const history = useHistory();
    const columns = [
        { index: "camp_id", title: "ID" },
        { index: "camp_nombre", title: "Nombre" },
        { index: "camp_descripcion", title: "Descripcion" },
        { index: "camp_objetivo", title: "Objetivo" },
    ];
    const mockData = [
        {
            "camp_id": 1,
            "camp_nombre": "Campaña 1",
            "camp_descripcion": "Esta es la descripción de la Campaña 1.",
            "camp_objetivo": "Promover un nuevo producto."
        },
        {
            "camp_id": 2,
            "camp_nombre": "Campaña 2",
            "camp_descripcion": "Descripción de la Campaña 2.",
            "camp_objetivo": "Incrementar la conciencia de marca."
        },
        {
            "camp_id": 3,
            "camp_nombre": "Campaña 3",
            "camp_descripcion": "Campaña dirigida a un público específico.",
            "camp_objetivo": "Generar conversiones."
        }
    ];

    const gotoDetallesCampaña = () => {
        history.push("/administracion/marketing/campañas/1");
    };

    return (
        <div className={`${styles.container} animate__animated animate__fadeInDown`}>
            <Row>
                <Elevation style={{ width: "100%", display: "flex", flexDirection: "column", margin: "10px 20px 20px 20px" }}>
                    <div className={styles.itemsHeader}>
                        <Button
                            shape="round"
                            icon={<AiOutlinePlus style={{ marginRight: 5 }} />}
                            className={styles.itemsHeaderBtn}
                        >
                            Crear nueva campaña
                        </Button>
                    </div>
                    <div className={styles.tableContainer}>
                        <DataTable
                            data={mockData}
                            columns={columns}
                            scroll={{ x: "100%" }}
                            onRowClick={gotoDetallesCampaña}
                        />
                    </div>
                </Elevation>
            </Row>
        </div>
    );
}

export default Campanias;