import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const DeletePuestoActividad = () => {
    const api = useApi();

    const mutate = async (id) => {
        console.log(id)
        const data  = await api.del(`/puestos-actividades/${id}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        deletePuestoActividadMutation: mutation.mutate,
    };
};